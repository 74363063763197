import { useEffect, useState } from 'react'
import { sendGet, sendPut } from '../../hooks/use-fetch'
import { CircularProgress } from '@mui/material'
import { type postError } from '../../core/types'
import { AcceptDenyModal } from '../../Components/Modal/AcceptDenyModal'
import { toast } from 'react-toastify'
import { Modal } from '../../Components/Modal'
import { AccessRequestEdit } from './AccessRequestEdit'

export interface AccessRequestReviewProps {
  accessRequestID: number
  open: boolean
  onClose: (updated: boolean) => void
}

export interface AccessRequestData {
  accessRequestID: number
  requestingUserName: string
  childName: string
  requestDate: Date
  requestingOrganizationName: string
  requestedServiceName: string
  requestedProgramName: string
  organizationAddress: string | null
  organizationCity: string | null
  organizationState: string | null
  organizationZipCode: string | null
  organizationPhone: string | null
  explanation: string
  requestedOrganizationName: string
  isApproved: boolean | null
  responseNotes: string | null
  requestStatus: string | null
}

export function AccessRequestReview (props: AccessRequestReviewProps): JSX.Element {
  const [accessRequest, setAccessRequest] = useState<AccessRequestData>()
  const [loaded, setLoaded] = useState(false)
  const [errors, setErrors] = useState<postError[] | undefined>(undefined)
  const [responseNotes, setResponseNotes] = useState<string | null>('')

  useEffect(() => {
    async function getData (): Promise<void> {
      const request = await sendGet(`/AccessRequest/AccessRequestReview?id=${props.accessRequestID}`, {})
      const data: AccessRequestData = request.response
      setAccessRequest(data)
      setLoaded(true)
    }
    void getData()
  }, [props.open])

  const handleClose = (): void => {
    props.onClose(false)
  }

  const handleApprove = async (): Promise<void> => {
    await ApplyStatus(true)
  }

  const handleReject = async (): Promise<void> => {
    await ApplyStatus(false)
  }

  const handleNotesChange = (e: any): void => {
    setResponseNotes(e.target.value)
  }

  async function ApplyStatus (isApproved: boolean): Promise<void> {
    setErrors(undefined)
    const submitData: { id: number, isApproved: boolean, notes: string | null } = { id: props.accessRequestID, isApproved, notes: responseNotes }
    const result = await sendPut('/AccessRequest/SetApprovalStatus', submitData)
    const rsp: { newRecordID: number, success: boolean, errors: postError[] } = result.response

    if (rsp.errors.length > 0) {
      setErrors(rsp.errors)
    } else if (rsp.success) {
      if (isApproved) {
        toast.success('Access request was approved')
      } else {
        toast.success('Access request was denied')
      }
      props.onClose(true)
    }
  }

  if (!loaded) return <CircularProgress role='loading' />
  else if (accessRequest == null) return <div>Record not found</div>
  else {
    const content = <AccessRequestEdit
      accessRequest={accessRequest}
      errors={errors}
      setAccessRequest={handleNotesChange}
    />

    if (accessRequest.isApproved != null) {
      return <Modal
        open={props.open}
        confirmationContent={content}
        confirmButtonText={'Close'}
        hideCancelButton={true}
        title={'Review Request'}
        onClose={handleClose}
        onConfirm={handleClose}
        maxWidth={'sm'}
        data-testid='referral-details-modal'
      />
    } else {
      return <AcceptDenyModal
        open={props.open}
        title={'Review Request'}
        dialogContent={content}
        acceptButtonText={'Approve'}
        denyButtonText={'Deny'}
        onClose={handleClose}
        onAccept={handleApprove}
        onDeny={handleReject}
        maxWidth={'sm'}
        data-testid='referral-details-modal'
      />
    }
  }
}
