/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Button } from '@mui/material'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import AlertMessage from '../../../Components/AlertMessage'
import { type childServiceSettings, useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { AccountType, type TransferReferralRequest, type service } from '../../../core/types'
import { toast } from 'react-toastify'
import { ChildServiceSelection } from './ChildServiceSelection'
import { type PriorityProgram } from '../ReferralAcceptModal'

interface AddChildServiceProps {
  serviceID?: number
  endpoint: string
  serviceProviderEndpoint: string
  canSkip: boolean
  onSkip: () => void
}

export function AddChildService (props: AddChildServiceProps): JSX.Element {
  const data = useChildContext()
  const account = useAccountId()
  const accountType = account?.type ?? ''
  const caregiverNames = data.caregivers.map(c => c.caregiver.firstName + ' ' + c.caregiver.lastName)
  let warningPhraseing = ''

  switch (caregiverNames.length) {
    case 2:
      warningPhraseing = `${caregiverNames[0]} and ${caregiverNames[1]} both currently have`
      break
    case 1:
      warningPhraseing = `${caregiverNames[0]} currently has`
      break
    case 0:
    default:
      warningPhraseing = 'All caregivers currently have'
  }

  const dispatch = useChildUpdateDispatch()
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const [spId, setSpId] = useState<number | null>((accountType === AccountType.SERVICE_PROVIDER || accountType === AccountType.INTAKE_USER) ? account?.id ?? 0 : data.serviceSettings.systemServiceProviderId)
  const [facilityList, setFacilityList] = useState<Array<{ id: number, name: string }>>([])
  const [serviceList, setServiceList] = useState<Array<{ id: number, name: string, s3Key: string, inactive: boolean, serviceId: number }>>([])
  const [programList, setProgramList] = useState<Array<{ id: number, name: string, allowConcurrentEnrollment: boolean, requiresIncomeCalculation: boolean, programId: number }>>([])
  const [providerList, setProviderList] = useState<Array<{ id: number, name: string, isIntake: boolean }> | null>(null)
  const [priorityPrograms, setPriorityPrograms] = useState<PriorityProgram[]>()
  const [hasAccessRequest, setHasAccessRequest] = useState(false)
  const isdId = data.manualDistrict?.isdId ?? data.isdID ?? 0

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (spId === null || data.serviceSettings.systemServiceProviderId === null) {
        dispatch({
          type: 'service',
          serviceSettings: {
            ...data.serviceSettings,
            systemServiceProviderId: spId,
            systemService: {
              id: props.serviceID
            } as service
          }
        })
      }
      if (data.serviceSettings.systemProgram?.id != null && facilityList.length === 0) {
        await fetchFacilities(data.serviceSettings.systemProgram.id)
      }

      if (props.endpoint === 'ChildDetails') {
        const { response: providersResponse } = await sendGet(`/${props.endpoint}/GetAllProviders?isdId=${isdId}`, {})
        if (providersResponse !== undefined) {
          const newProviders = providersResponse
            .map((p: { systemServiceProviderID: number, name: string }) => ({ id: p.systemServiceProviderID, name: p.name }))
          setProviderList(newProviders)
        }
      }

      const { response: rsp2 } = await sendGet(`/${props.endpoint}/GetPriority?isdId=${isdId}`, {})
      if (rsp2 !== undefined && rsp2 !== null) {
        setPriorityPrograms(rsp2)
      } else { setPriorityPrograms(undefined) }

      if (spId != null) {
        const { response } = await sendGet(`/${props.serviceProviderEndpoint}/GetProviderServices?id=${spId}`, {})
        if (response !== undefined && response.length > 0) {
          const rsp: Array<{ id: number, name: string, s3Key: string, inactive: boolean, serviceId: number }> = response
          if (props.serviceID !== undefined) {
            const thisservice = rsp.find(s => s.id === props.serviceID) ?? ({} as { id: number, name: string, s3Key: string, inactive: boolean, serviceId: number })
            setServiceList([thisservice])
          } else {
            setServiceList(rsp)
          }
        }
        const { response: rsp } = await sendGet(`/${props.serviceProviderEndpoint}/GetProviderPrograms?id=${spId}`, {})
        if (rsp !== undefined && rsp.length > 0) {
          setProgramList(rsp)
        }
      }
    }
    if (spId !== 0) { void fetchData() }
  }, [spId, isdId, accountType])

  const fetchPriorityProviders = async (isdId: number, programId: string): Promise<Array<{ id: number, name: string, districtDefaults: number[] }>> => {
    const { response: rsp3 } = await sendGet(`/CareNetworkEdit/GetPriorityProviders?isdId=${isdId}&programId=${programId}`, {})
    if (rsp3 !== undefined) {
      return (rsp3)
    } else {
      return ([])
    }
  }

  const fetchFacilities = async (programId: number | null): Promise<void> => {
    setShowWarning(programId !== null)
    if (programId !== null) {
      const { response } = await sendGet(`/${props.serviceProviderEndpoint}/GetProviderFacilities?isdid=${isdId}&spId=${spId}&pId=${programId}`, {})
      if (response !== undefined && response.length > 0) {
        setFacilityList(response)
      } else {
        setFacilityList([])
      }
    }
  }

  const handleServiceChange = (id: string): void => {
    const intVal = parseInt(id)
    if (data.serviceSettings.systemService?.id !== intVal) {
      dispatch({
        type: 'service',
        serviceSettings: {
          ...data.serviceSettings,
          systemService: {
            id: intVal,
            inactive: false,
            name: '',
            description: '',
            s3Key: ''
          },
          systemProgram: null,
          systemServiceProviderId: spId
        }
      })
    }
  }

  const handleProgramChange = async (id: string): Promise<void> => {
    const intVal = parseInt(id)
    let isValid: boolean = true
    let matchedId: number | null = null
    const newSettings: childServiceSettings = { ...data.serviceSettings }

    const selectedProgram = programList.find(p => p.id === intVal)
    newSettings.systemProgram = {
      id: intVal,
      description: '',
      name: selectedProgram?.name ?? '',
      inactive: false,
      fundingSourceID: 0,
      stateCode: '',
      requiresIncomeCalculation: selectedProgram?.requiresIncomeCalculation ?? false
    }

    if (data.serviceSettings.systemProgram?.id !== newSettings.systemProgram?.id) {
      if (data.isLoadedFromExistingChild && !(selectedProgram?.allowConcurrentEnrollment ?? true)) {
        const matchedProgram = data.activeServices.find(s =>
          s.systemProgramID === selectedProgram?.id &&
          s.systemServiceID === data.serviceSettings.systemService?.id)
        if (matchedProgram != null) {
          const { response } = await sendGet(`/AccessRequest/HasAccessRequest?systemServiceProviderID=${data.serviceSettings.systemServiceProviderId}&systemProgramID=${intVal}&systemServiceID=${data.serviceSettings.systemService?.id}&childid=${data.child.id}`, {})
          setHasAccessRequest(response)
          if (response === true) {
            toast.error('There is already a transfer request for this child to your service provider.')
          } else {
            toast.error('This child is already referred/enrolled in this program, please request a transfer')
          }
          matchedId = matchedProgram.referralId ?? matchedProgram.enrollmentId ?? null
          isValid = false
        }
      }

      newSettings.priorityProviderId = null
      const newSettingsModified = await CheckPriority(newSettings, priorityPrograms ?? [])

      newSettingsModified.desiredSchedule = null
      newSettingsModified.facilityId = null
      newSettingsModified.timeFrame = null
      newSettingsModified.systemServiceProviderId = spId
      dispatch({
        type: 'service',
        serviceSettings: newSettingsModified
      })
      void fetchFacilities(intVal)
      dispatch({ type: 'serviceIsValid', isValid, referralId: matchedId })
    }
  }

  const CheckPriority = async (newSettings: childServiceSettings, prioPrograms: PriorityProgram[]): Promise<childServiceSettings> => {
    if ((newSettings.systemProgram?.requiresIncomeCalculation ?? false)) {
      const caregivers = data.caregivers.map(c => c.caregiver)
      const total = caregivers.reduce((a, b) => a + (b.incomes?.reduce((c, d) => c + d.annualIncome, 0) ?? 0), 0)
      const householdMembers = caregivers.reduce((a, b) => a + (b.incomes?.reduce((c, d) => c + d.memberCount, 0) ?? 0), 0)
      const birthDate = new Date(data.child.dateOfBirth?.toString() ?? '') ?? new Date()

      let enrollmentDate = new Date()
      if (newSettings.timeFrame === 'UpcomingSchoolYear') {
        enrollmentDate.setFullYear(enrollmentDate.getFullYear() + 1)
      } else if (newSettings.timeFrame === 'SpecificDate') {
        enrollmentDate = newSettings.customDate ?? new Date()
      }
      const { response: incomeResponse } = await sendGet(`/ChildDetails/CalculateIncomeFPL?memberCount=${householdMembers}` +
        `&income=${total}&birthDate=${birthDate.toLocaleDateString()}&programId=${newSettings.systemProgram?.id}&isdid=${isdId}&enrollmentDate=${enrollmentDate.toLocaleDateString()}`, {})

      const fplForHousehold = incomeResponse.householdFPL

      const incomePercent = incomeResponse.incomePercent
      if (incomePercent > incomeResponse.povertyPercentCutOff) {
        toast.warning('The income for this household is above the poverty percent threshold that has been set for this year. ' +
          'Creating this referral will result in an income approval taking place prior to being sent to the provider.')
      }
      let priorityFound = false
      let prioId = ''
      const selectedProgram = programList.find(p => p.id === newSettings.systemProgram?.id)
      let prioProviders: Array<{ id: number, name: string, districtDefaults: number[] }> = []
      if (prioPrograms != null && (prioPrograms.length > 0)) {
        prioPrograms.forEach(prog => {
          const childAge = incomeResponse.age >= 4 ? 4 : incomeResponse.age
          if (!priorityFound && prog.id !== selectedProgram?.id &&
            incomeResponse.incomePercent <= prog.cutoffPercent &&
            childAge === prog.ageRange) {
            prioId = prog.id.toString()
            priorityFound = true
          }
        })
        prioProviders = await fetchPriorityProviders(isdId, prioId)
      }
      newSettings.income = total
      if (newSettings.priorityProviderId == null) {
        const defaultProvider = prioProviders.find(p => p.districtDefaults.some(d => d === data.locations[0].location.districtID))
        newSettings.priorityProviderId = prioProviders.length === 1 ? prioProviders[0].id : (defaultProvider?.id ?? null)
      }
      newSettings.povertyLevel = fplForHousehold
      newSettings.povertyPercent = incomePercent
      newSettings.povertyPercentCutOff = incomeResponse.povertyPercentCutOff
      newSettings.memberCount = householdMembers
      newSettings.priorityNotes = null
      newSettings.priorityFound = priorityFound
    }
    return newSettings
  }

  const handleChange = async (e: childServiceSettings): Promise<void> => {
    let newSettings = e
    if (e.systemProgram !== null && e.systemProgram.id !== data.serviceSettings.systemProgram?.id) {
      newSettings = await CheckPriority(e, priorityPrograms ?? [])
    }
    dispatch({ type: 'service', serviceSettings: { ...newSettings } })
  }

  const handleProviderChange = async (id: string): Promise<void> => {
    const intVal = parseInt(id)

    setSpId(intVal)
    dispatch({
      type: 'service',
      serviceSettings: {
        ...data.serviceSettings,
        systemService: null,
        systemProgram: null,
        systemServiceProviderId: intVal
      }
    })
  }

  const handleTransferRequestChange = (transferRequest: TransferReferralRequest): void => {
    dispatch({ type: 'transferRequestChange', data: transferRequest })
  }

  return <Box width={'100%'}>
    <ChildServiceSelection
      status='Pending'
      onServiceProviderChange={handleProviderChange}
      providerList={providerList}
      serviceList={serviceList}
      programList={programList}
      onServiceChange={handleServiceChange}
      onProgramChange={handleProgramChange}
      serviceSettings={data.serviceSettings}
      setServiceSettings={handleChange}
      facilityList={facilityList}
      isNew={true}
      showIncome={data.serviceSettings.systemProgram?.requiresIncomeCalculation ?? false}
      requestTransfer={data.isLoadedFromExistingChild && !data.serviceIsValid}
      hasAccessRequest={hasAccessRequest}
      childId={data.child.id}
      transferRequest={data.transferRequest}
      setTransferRequest={handleTransferRequestChange}
    />
    {!data.isLoadedFromExistingChild && showWarning && (
      <AlertMessage
        message={`This program requires all household income to be provided, documented, verified, and approved before an enrollment can be made.  ${warningPhraseing} unverified income. ` +
        'The referral can be made now but the provider will be responsible for verifying this information.'}
        type='warning'
        icon={<></>}
      />
    )}

    {props.canSkip && (
      <Button variant='contained' color='secondary' fullWidth onClick={props.onSkip} className='mt-10'>Skip This Step</Button>
    )}
  </Box>
}
