import { useEffect, useState } from 'react'
import { Modal } from '../../../Components/Modal'
import { SelectWithLabel, type SelectOption } from '../../../Components/SelectWithLabel'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { sendGet } from '../../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { Status, type lookup, type serviceProvider, type ISDProgramOption, type ISDServiceOption } from '../../../core/types'
import { LabelWithToolTip } from '../../../Components/Label/Index'
import { OnOffSwitch } from '../../../Components/Switch/OnOffSwitch'
import { RemovableAddSelect } from '../../../Components/MultiAddSelect/RemovableAddSelect'
import { AddUserTemplate } from '../../../Components/MultiAddSelect/AddUserTemplate'
import { DupeModal } from './DupeModal'
import { Button } from '@mui/material'
import { useAuth } from '../../../hooks/use-auth'
import { toast } from 'react-toastify'
import { generatePath } from 'react-router'
import { CAREGIVER_PORTAL } from '../../Routes'

export interface ServiceProviderProps {
  item: serviceProvider
  open: boolean
  onClose: () => void
  onSubmit: (newRowData: serviceProvider, userData: userType) => void
  errors?: string[] | undefined
  programs: ISDProgramOption[]
  services: ISDServiceOption[]
}

export interface userType {
  firstName: string
  lastName: string
  email: string
}

export function ServiceProviderEdit (props: ServiceProviderProps): JSX.Element {
  const [userOptions, setUserOptions] = useState<lookup[]>([{ id: -1, name: 'Add New User' }])
  const [serviceProvider, setServiceProvider] = useState<serviceProvider>(props.item)
  const [newUserData, setNewUserData] = useState<userType>({ firstName: '', lastName: '', email: '' })
  const [showDupeModal, setShowDupeModal] = useState<boolean>(false)
  const [dupeData, setDupeData] = useState<serviceProvider | null>(null)
  const [serviceList, setServiceList] = useState<ISDServiceOption[]>([])
  const [programList, setProgramList] = useState<ISDProgramOption[]>([])

  const auth = useAuth()

  const handleChange = (e: any): void => {
    setServiceProvider({ ...serviceProvider, [e.target.name]: e.target.value })
  }

  const handleNameChange = (e: any): void => {
    const val: string = e.target.value
    setServiceProvider({ ...serviceProvider, [e.target.name]: val })
  }

  const checkDupe = async (): Promise<void> => {
    const { response: rsp3 } = await sendGet(`/ISDProviderManagement/FindServiceProviderByName?name=${serviceProvider.name}&isdid=${(props.item.isdid).toString()}`, {})
    if (rsp3 !== 0 && rsp3 !== null) {
      setShowDupeModal(true)
      setDupeData(rsp3)
    } else {
      setDupeData(null)
    }
  }

  const handleDupeAccept = (): void => {
    setShowDupeModal(false)
    if (dupeData !== null) {
      const tempSp = { ...dupeData, systemServiceProviderID: dupeData?.id, programList: dupeData.programList ?? [], serviceList: dupeData.serviceList ?? [] }
      setServiceProvider(tempSp)
    }
  }

  const handleDateChange = (name: string, newDate: Date | null): void => {
    setServiceProvider({ ...serviceProvider, [name]: newDate })
  }

  const addTemplate =
    <AddUserTemplate
      userData={newUserData}
      handleChange={(data) => {
        setNewUserData(data)
      }}
    />

  useEffect(() => {
    const loadLookupData = async (): Promise<void> => {
      const sspId: string = (serviceProvider.systemServiceProviderID ?? 0).toString()
      const { response: rsp3, error: error3 } = await sendGet(`/ServiceProviderSettings/GetProviderStaff?systemSpID=${sspId}`, {})
      const usrs: Array<{ id: number, firstName: string, lastName: string }> = rsp3
      const usrList = usrs.map(u => {
        return { id: u.id, name: `${u.firstName} ${u.lastName}` }
      })
      if (error3[0] === '') { setUserOptions([...usrList, { id: -1, name: 'Add New User' }]) }

      intakeChanged(serviceProvider.isIntake)
    }
    void loadLookupData()
  }, [])

  const handleUserChange = (e: any, value: {
    id: string | number
    name: string
  } | null): void => {
    let id = value?.id ?? ''
    if (typeof (id) === 'string') { id = parseInt(id) }
    setServiceProvider({ ...serviceProvider, userID: id })
  }

  const intakeChanged = (checked: boolean): void => {
    const tmpProvider = { ...serviceProvider, isIntake: checked }

    if (checked) {
      setServiceList(props.services)
      setProgramList(props.programs)
    } else {
      setServiceList(props.services.filter(s => !s.isIntake))
      setProgramList(props.programs.filter(p => !p.isIntake))
      tmpProvider.serviceList = tmpProvider.serviceList.filter(s => !s.isIntake)
      tmpProvider.programList = tmpProvider.programList.filter(p => !p.isIntake)
    }
    setServiceProvider(tmpProvider)
  }

  const serviceChanged = async (serviceOption: ISDServiceOption, checked: boolean): Promise<void> => {
    const tempIDs = [...serviceProvider.serviceList]
    if (checked) {
      tempIDs.push(serviceOption)
    } else {
      const { response: rsp } = await sendGet(`/ISDProviderManagement/IsServiceInUse?isdServiceID=${serviceOption.id}&isdServiceProviderID=${serviceProvider.id}`, {})
      if (rsp === true) {
        toast.error('Service is in use and cannot be removed')
        return
      }
      const index = tempIDs.findIndex((s) => s.id === serviceOption.id)
      if (index > -1) {
        tempIDs.splice(index, 1)
      }
    }
    setServiceProvider({ ...serviceProvider, serviceList: tempIDs })
  }

  const programChanged = async (programOption: ISDProgramOption, checked: boolean): Promise<void> => {
    const tempIDs = [...serviceProvider.programList]
    if (checked) {
      tempIDs.push(programOption)
    } else {
      const { response: rsp } = await sendGet(`/ISDProviderManagement/IsProgramInUse?isdProgramID=${programOption.id}&isdServiceProviderID=${serviceProvider.id}`, {})
      if (rsp === true) {
        toast.error('Program is in use and cannot be removed')
        return
      }
      const index = tempIDs.findIndex((s) => s.id === programOption.id)
      if (index > -1) {
        tempIDs.splice(index, 1)
      }
    }
    setServiceProvider({ ...serviceProvider, programList: tempIDs })
  }

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>Provider Details</span>
  </div>
  const statusOptions: Array<SelectOption<Status>> = [
    { id: Status.ACTIVE, name: Status.ACTIVE },
    { id: Status.INACTIVE, name: Status.INACTIVE }
  ]

  const handleClose = (): void => {
    props.onClose()
  }

  const closeDupeModal = (): void => {
    setShowDupeModal(false)
  }

  const handleSubmit = (): void => {
    props.onSubmit(serviceProvider, newUserData)
  }

  const handleCopyCaregiverPortalLink = (e: any): void => {
    const copyLink = async (): Promise<void> => {
      var path = generatePath(CAREGIVER_PORTAL, { isdId: props.item.isdid.toString(), isdServiceProviderId: props.item.id.toString() })
      var link = window.location.origin + path
      await navigator.clipboard.writeText(link)
    }
    void copyLink()
  }

  const content =
    <>
      <DupeModal
        data={dupeData}
        onCancel={closeDupeModal}
        onConfirm={handleDupeAccept}
        open={showDupeModal}
      />
      {
        props.errors?.map((err) => (
          <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
            {err}
          </li>
        ))
      }
      <table role='presentation' className='display-grid'>
        <tbody>
          {serviceProvider.id !== 0 &&
            <tr>
              <td colSpan={2}>
                <Button
                  variant="contained"
                  onClick={async () => { await auth.impersonateProvider(serviceProvider.id) }}
                  data-testid='impersonate'
                >
                  Impersonate
                </Button>
                <Button style={{ marginLeft: '5px' }} variant="contained" onClick={handleCopyCaregiverPortalLink}>Copy Caregiver Portal Link</Button>
              </td>
            </tr>
          }
          <tr>
            <td>
              <TextFieldWithLabel
                name="name"
                label="Name"
                value={serviceProvider.name ?? ''}
                onChange={handleNameChange}
                onBlur={checkDupe}
                disabled = {serviceProvider.id !== 0}
                required={true}
                dataTestId='name'
              />
            </td>
            <td>
              <TextFieldWithLabel
                name="eemCode"
                label="EEM Code"
                value={serviceProvider.eemCode ?? ''}
                onChange={handleChange}
                disabled={serviceProvider.id !== 0}
                dataTestId='eemCode'
              />
            </td>
          </tr>
          <tr>
            <td>
              <DatePickerWithLabel
                name="onboardDate"
                label="Onboarded Date"
                onChange={(newValue) => { handleDateChange('onboardDate', newValue) }}
                value={serviceProvider.onboardDate}
                disabled={serviceProvider.id !== 0}
                dataTestId='onboardDate'
              />
            </td>
            <td>
              <SelectWithLabel
                name="status"
                label="Status"
                value={serviceProvider.status ?? ''}
                options={statusOptions}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>
              {serviceProvider.s3Key !== null && serviceProvider.s3Key !== '' && (
                <img
                  src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${serviceProvider.s3Key}`}
                  alt='logo'
                  data-testid={'logo-' + (serviceProvider.s3Key)}
                  style={{ maxWidth: '100px' }}
                />)}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <RemovableAddSelect
                label='Primary Contact'
                onChange={handleUserChange}
                name='adminUserId'
                options={userOptions}
                value={serviceProvider.userID ?? ''}
                hideDeleteBtn={true}
                onDeleteBtnClick={(e: any) => { }}
                addNewFragment={addTemplate}
              />
            </td>
          </tr>
          <tr>
            <td>Allow UIC Lookup</td>
            <td>
              <OnOffSwitch
                size='medium'
                value={serviceProvider.allowUICLookup}
                key={'allowUICLookup'}
                checked={serviceProvider.allowUICLookup}
                onChange={(event: any, checked: boolean) => { setServiceProvider({ ...serviceProvider, allowUICLookup: checked }) }}
              />
            </td>
          </tr>

          <tr>
            <td>Intake Provider</td>
            <td>
              <OnOffSwitch
                size='medium'
                value={serviceProvider.isIntake}
                checked={serviceProvider.isIntake}
                onChange={(e, checked) => { intakeChanged(checked) }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <LabelWithToolTip
                labelText="Services Offered"
              />
            </td>
            <td>
              <LabelWithToolTip
                labelText="Programs Offered"
              />
            </td>
          </tr>
          <tr>
            <td className='align-top'>
              <table>
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Enabled?</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceList.map(t => {
                    return <tr key={t.id}>
                      <td>
                        {t.name}
                      </td>
                      <td>
                        <OnOffSwitch
                          size='medium'
                          name={t.name}
                          value={t.id}
                          checked={serviceProvider.serviceList?.find(s => s.id === t.id) !== undefined}
                          onChange={async (e, checked) => { await serviceChanged(t, checked) }}
                          data-testid={t.name}
                        />
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </td>
            <td className='align-top'>
              <table>
                <thead>
                  <tr>
                    <th>Program Name</th>
                    <th>Enabled?</th>
                  </tr>
                </thead>
                <tbody>
                  {programList.map(t => {
                    return <tr key={t.id}>
                      <td>
                        {t.name}
                      </td>
                      <td>
                        <OnOffSwitch
                          size='medium'
                          name={t.name}
                          value={t.id}
                          checked={serviceProvider.programList?.find(p => p.id === t.id) !== undefined}
                          onChange={async (e, checked) => { await programChanged(t, checked) }}
                          data-testid={t.name}
                        />
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>

  return <Modal
    open={props.open}
    titleContent={titleContent}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
