import { useEffect, useState } from 'react'
import { DocumentList } from '../DocumentList'
import { CaregiverDisplay } from './CaregiverDisplay'
import { AccountType, type ChildCaregiver } from '../../core/types'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { Modal } from '../Modal'
import { sendGet } from '../../hooks/use-fetch'

export interface ChildCaregiverProps {
  data: ChildCaregiver
  studentName: string
  onCaregiverEdit?: (caregiverId: number) => void
  onCaregiverDelete?: (caregiverId: number) => void
  hideDocumentLink?: boolean
}

export function CaregiverDetails (props: ChildCaregiverProps): JSX.Element {
  const account = useAccountId()
  const [showDocument, setShowDocuments] = useState(false)
  const [isdId, setIsdId] = useState(0)
  const yearOptions: number[] = []

  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 6; i <= currentYear + 1; i++) {
    yearOptions.push(i)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (account == null) {
        return
      }

      let userISDId = 0
      if (account.type === AccountType.ISD) {
        userISDId = account.id
      } else {
        const { response: isdRsp } = await sendGet('/ChildDetails/GetISDFromUser', {})
        userISDId = isdRsp
      }

      setIsdId(userISDId)
    }

    void loadData()
  }, [account])

  const handleEdit = (): void => {
    if (props.onCaregiverEdit !== undefined) props.onCaregiverEdit(props.data.id)
  }

  const handleDelete = (): void => {
    if (props.onCaregiverDelete !== undefined) props.onCaregiverDelete(props.data.id)
  }

  return <>
    <CaregiverDisplay
      hideDocumentLink={props.hideDocumentLink}
      relationshipName={props.data.relationshipName}
      onEdit={handleEdit}
      onDocumentClick={() => { setShowDocuments(true) }}
      accessType={props.data.accessType}
      isLivingWithStudent={props.data.isLivingWithStudent}
      studentName={props.studentName}
      caregiverName={`${props.data.firstName} ${props.data.lastName}`}
      onDelete={handleDelete}
    />

    <Modal onClose={() => { setShowDocuments(false) }}
      onConfirm={() => { setShowDocuments(false) }}
      open={showDocument}
      confirmationContent={<DocumentList
        isdId={isdId}
        editable={true}
        canVerify={true}
        showDisclaimer={false}
        childID={null}
        caregiverID={props.data.id}
        years={yearOptions}
      />}
      confirmButtonText='Close'
      hideCancelButton={true}
    />
  </>
}
