/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { type carenetworkService } from '../../../core/types'
import { CareNetworkServiceDetails } from './CareNetworkServiceDetails'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { ServiceCard } from '../../../Components/Services/ServiceCard'
import { NotAllowed } from '../../../Routes/ProtectedRoute'
import { TransferService } from '../../../Components/Services/TransferService'
import { toast } from 'react-toastify'
import { type CancelRequestModel, CareNetworkServiceExit } from './CareNetworkServiceModals/CareNetworkServiceExit'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { type childServiceSettings } from '../../../ContextProviders/AddChildProvider'
import AlertMessage from '../../../Components/AlertMessage'
import { ReferralDetail } from '../../Referral/ReferralDetail'
import { EnrollmentDetails } from '../../ServiceProvider/Enrollments/EnrollmentDetails'

enum ServiceStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  All = 'All'
}

const statusOptions = [
  { id: ServiceStatus.Active, name: ServiceStatus.Active },
  { id: ServiceStatus.Inactive, name: ServiceStatus.Inactive },
  { id: ServiceStatus.All, name: ServiceStatus.All }
]

export interface CareNetworkServicesProps {
  childID: number
  childName?: string
  districtId: number
  isdId: number
}

export function CareNetworkServices (props: CareNetworkServicesProps): JSX.Element {
  const [displayStatus, setDisplayStatus] = useState<{ open: boolean, isNew: boolean, type: string }>({ open: false, isNew: false, type: '' })
  const [services, setServices] = useState<carenetworkService[]>([])
  const [refresh, setRefresh] = useState(new Date())
  const [unauthorized, setUnauthorized] = useState(false)
  const [showCancelRequestModal, setShowCancelRequestModal] = useState<boolean>(false)
  const [showTransferRequestModal, setShowTransferRequestModal] = useState<boolean>(false)
  const [showReleaseRequestModal, setShowReleaseRequestModal] = useState<boolean>(false)
  const [cancelReasons, setCancelReasons] = useState<Array<{ id: number, name: string }>>([])
  const [selectedStatus, setSelectedStatus] = useState<ServiceStatus>(ServiceStatus.Active)

  useEffect(() => {
    async function fetchExitReasons (): Promise<void> {
      const { response } = await sendGet('/Enrollment/GetExitReasons', {})
      if (response !== null) { setCancelReasons(response) }
    }
    void fetchExitReasons()
  }, [])

  const handleCancelConfirm = async (cancelRequest: CancelRequestModel): Promise<void> => {
    if (cancelRequest !== undefined && cancelRequest.reasonId > 0 && cancelRequest.note !== '') {
      const { response } = await sendPost('/Enrollment/CancelServiceRequest', cancelRequest)
      if (response !== undefined) {
        setShowCancelRequestModal(false)
        setDisplayStatus({ open: false, isNew: false, type: '' })
        setRefresh(new Date())
        toast.success('Service request was cancelled')
      }
    } else {
      toast.warning('Please fill out required fields')
    }
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [selectedService, setSelectedService] = useState<carenetworkService>({} as carenetworkService)

  const addClick = (): void => {
    setDisplayStatus({ open: true, isNew: true, type: 'referral' })
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setSelectedService({ status: 'Pending' } as carenetworkService)
  }

  const handleSubmit = (): void => {
    setDisplayStatus({ open: false, isNew: false, type: '' })
    setRefresh(new Date())
  }

  useEffect(() => {
    async function getServices (): Promise<void> {
      const { response, success, status } = await sendGet(`/ChildDetails/GetServices?id=${props.childID}&isdid=${props.isdId}&status=${selectedStatus}&includeReferral=true`, {})
      if (success) {
        setServices(response.response)
      } else {
        if (status === 401) { setUnauthorized(true) }
      }
    }
    void getServices()
  }, [refresh, selectedStatus])

  const viewService = (service: carenetworkService): void => {
    setSelectedService(service)
    setDisplayStatus({ open: true, isNew: false, type: service.type })
  }

  const releaseService = async (service: childServiceSettings | undefined): Promise<void> => {
    if (service == null) { return }
    const data: {
      serviceSettings: childServiceSettings
      childID: number
    } = {
      serviceSettings: service,
      childID: props.childID
    }
    const { response } = await sendPut('/CareNetworkEdit/ReleaseReferral', data)
    if (response.newRecordID > 0) {
      setShowReleaseRequestModal(false)
      setRefresh(new Date())
      toast.success('The referral has been released to the provider')
    } else {
      const errors: Array<{ error: string }> = response.errors
      errors.forEach(e => {
        toast.error(e.error)
      })
    }
  }

  const beginCancelService = (service: carenetworkService): void => {
    setShowTransferRequestModal(false)
    setSelectedService(service)
    setShowCancelRequestModal(true)
  }

  const beginReleaseReferral = async (service: carenetworkService): Promise<void> => {
    setSelectedService(service)
    setShowReleaseRequestModal(true)
  }

  const beginTransferService = (service: carenetworkService): void => {
    setSelectedService(service)
    setShowTransferRequestModal(true)
  }

  if (unauthorized) return <NotAllowed />

  return <Box>
    <Box className='d-flex f-justify-content-space-between'>
      <SelectWithLabel
        label='Status'
        name='status'
        onChange={(e) => { setSelectedStatus(e.target.value as ServiceStatus) }}
        options={statusOptions}
        value={selectedStatus}
        width='200px'
      />
      <Box className='d-flex f-direction-column'>
        <Button variant='contained' color='secondary' className='mt-10' data-testid='add-service'
          onClick={addClick}
        >Add Service</Button>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {services.map((service) => (
        <ServiceCard
          key={service.referralId}
          service={service}
          childId={props.childID}
          onClick={viewService}
          onCancel={beginCancelService}
          onRelease={beginReleaseReferral}
          onTransfer={beginTransferService}
        />
      ))}
    </Box>

    { displayStatus.open && displayStatus.isNew && <CareNetworkServiceDetails
      isdid={props.isdId}
      childID={props.childID}
      districtId={props.districtId}
      open={displayStatus.open}
      onSubmit={handleSubmit}
      onClose={() => { setDisplayStatus({ isNew: false, open: false, type: '' }) }}
      careNetworkServices={services}
      service={selectedService}
      isNew={displayStatus.isNew}
      onExit={() => { beginCancelService(selectedService) }}
      childName={props.childName}
    /> }
    {displayStatus.open && !displayStatus.isNew && displayStatus.type === 'enrollment' && <EnrollmentDetails
      hideIncomeReapply={true}
      enrollmentID={selectedService.enrollmentID ?? 0}
      childID={props.childID}
      open={displayStatus.open}
      onClose={() => { setDisplayStatus({ isNew: false, open: false, type: '' }) }}

    />}
    {displayStatus.open && !displayStatus.isNew && displayStatus.type !== 'enrollment' && <ReferralDetail
      hideIncomeReapply={true}
      childID={props.childID}
      referralID={selectedService.referralId}
      serviceID={selectedService.systemServiceID}
      onRefresh={handleSubmit}
      manualReferEnabled={false}
      hideAccept={true}
      hideDeny={true}
      open={displayStatus.open}
      onClose={() => { setDisplayStatus({ isNew: false, open: false, type: '' }) }}
    />}

    {showCancelRequestModal && <CareNetworkServiceExit
      service={selectedService}
      cancelReasons={cancelReasons}
      onConfirm={handleCancelConfirm}
      onClose={() => { setShowCancelRequestModal(false) }}
      open={showCancelRequestModal}
    />}

    {showReleaseRequestModal && (
      <CareNetworkServiceDetails
        isdid={props.isdId}
        childID={props.childID}
        districtId={props.districtId}
        open={showReleaseRequestModal}
        onClose={() => { setShowReleaseRequestModal(false) }}
        careNetworkServices={services}
        service={selectedService}
        isNew={false}
        onSubmit={async (settings: childServiceSettings | undefined) => { await releaseService(settings) }}
        childName={props.childName}
        onExit={() => { }}
        overrideSubmit={true}
        errorContent={selectedService.queueOrder !== Math.min(...services.map(s => s.queueOrder))
          ? <AlertMessage message={'This is not the service that was requested first. Are you sure you want to release this referral first?'} type={'warning'} />
          : <></>}
      />
    )
    }

    {showTransferRequestModal && <TransferService
      open={showTransferRequestModal}
      onClose={() => { setShowTransferRequestModal(false) }}
      onSubmit={() => { setRefresh(new Date()) }}
      service={selectedService}
      referralId={selectedService.referralId}
      referralChildId={props.childID}
    />}
  </Box>
}
