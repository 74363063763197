export function BackArrow (): JSX.Element {
  return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_878_5865)">
      <path d="M6.5625 14.0625H25.3125C25.5611 14.0625 25.7996 14.1613 25.9754 14.3371C26.1512 14.5129 26.25 14.7514 26.25 15C26.25 15.2486 26.1512 15.4871 25.9754 15.6629C25.7996 15.8387 25.5611 15.9375 25.3125 15.9375H6.5625C6.31386 15.9375 6.0754 15.8387 5.89959 15.6629C5.72377 15.4871 5.625 15.2486 5.625 15C5.625 14.7514 5.72377 14.5129 5.89959 14.3371C6.0754 14.1613 6.31386 14.0625 6.5625 14.0625Z" fill="#0E8476"/>
      <mask id="path-3-inside-1_878_5865" fill="white">
        <path d="M6.95035 15.0002L14.726 22.774C14.902 22.95 15.0009 23.1888 15.0009 23.4377C15.0009 23.6867 14.902 23.9254 14.726 24.1015C14.5499 24.2775 14.3112 24.3764 14.0622 24.3764C13.8133 24.3764 13.5745 24.2775 13.3985 24.1015L4.96097 15.664C4.87367 15.5769 4.8044 15.4734 4.75714 15.3595C4.70987 15.2456 4.68555 15.1235 4.68555 15.0002C4.68555 14.8769 4.70987 14.7548 4.75714 14.6409C4.8044 14.527 4.87367 14.4235 4.96097 14.3365L13.3985 5.89896C13.5745 5.72292 13.8133 5.62402 14.0622 5.62402C14.3112 5.62402 14.5499 5.72292 14.726 5.89896C14.902 6.07499 15.0009 6.31375 15.0009 6.56271C15.0009 6.81166 14.902 7.05042 14.726 7.22646L6.95035 15.0002Z"/>
      </mask>
      <path d="M6.95035 15.0002L14.726 22.774C14.902 22.95 15.0009 23.1888 15.0009 23.4377C15.0009 23.6867 14.902 23.9254 14.726 24.1015C14.5499 24.2775 14.3112 24.3764 14.0622 24.3764C13.8133 24.3764 13.5745 24.2775 13.3985 24.1015L4.96097 15.664C4.87367 15.5769 4.8044 15.4734 4.75714 15.3595C4.70987 15.2456 4.68555 15.1235 4.68555 15.0002C4.68555 14.8769 4.70987 14.7548 4.75714 14.6409C4.8044 14.527 4.87367 14.4235 4.96097 14.3365L13.3985 5.89896C13.5745 5.72292 13.8133 5.62402 14.0622 5.62402C14.3112 5.62402 14.5499 5.72292 14.726 5.89896C14.902 6.07499 15.0009 6.31375 15.0009 6.56271C15.0009 6.81166 14.902 7.05042 14.726 7.22646L6.95035 15.0002Z" fill="#0E8476" stroke="#0E8476" strokeWidth="10" mask="url(#path-3-inside-1_878_5865)"/>
    </g>
  </svg>
}
