import { useState, useEffect } from 'react'
import { TabDisplay } from '../../../Components/TabDisplay'
import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from '@mui/material'
import { ChildDetails } from './ChildDetails'
import { useNavigate, useParams } from 'react-router-dom'
import { sendGet } from '../../../hooks/use-fetch'
import { CommunicationList } from './CommunicationList'
import { NetworkMemberDetails } from './NetworkMemberDetails'
import { CareNetworkServices } from './CareNetworkServices'
import { type PersonBasicInfo, AccountType } from '../../../core/types'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'
import { usePermissions } from '../../../hooks/use-permissions'
import { CARENETWORK_MANAGEMENT } from '../../Routes'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { ChildHistoryDisplay } from './ChildHistoryDisplay'
import { ChildAndGuardianDocumentList } from '../../../Components/DocumentList/ChildAndGuardianDocumentList'

export function CareNetworkDetail (): JSX.Element {
  const nav = useNavigate()
  const perms = usePermissions()
  const [loaded, setLoaded] = useState(false)
  const [child, setChild] = useState<PersonBasicInfo>()
  const [value, setValue] = useState(0)
  const { id } = useParams()
  const account = useAccountId()
  const accountid = account?.id ?? 0
  const isdid = account?.type === 'Intake' ? child?.isdId ?? accountid : accountid
  const [refreshTime, setRefreshTime] = useState(new Date())

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (id !== undefined && accountid !== 0) {
        const isdString = account?.type === AccountType.ISD ? `&isdid=${accountid}` : ''
        const { response: details } = await sendGet(`/ChildDetails/BasicInfo?id=${id}${isdString}`, {})
        if (details !== undefined) {
          setChild(details.response)
        }
        setLoaded(true)
      }
    }

    void fetchData()
  }, [id, accountid, refreshTime])

  function onUpdate (): void {
    setRefreshTime(new Date())
  }

  if (!loaded) return <CircularProgress role='loading' />

  return <Box>
    <Typography variant="h3" data-test-page-header>Details - {child?.firstName} {child?.lastName}</Typography>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb">
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(CARENETWORK_MANAGEMENT) }} >
        Care Networks
      </Link>
    </Breadcrumbs>

    <Tabs value={value}
      data-test-tabs
      onChange={(e, val) => { setValue(val) }}
      aria-label="Care Network Detail Tabs"
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label="Details" value={0} data-test-details />
      <Tab label="Care Network" value={1} data-testid="careNetwork" data-test-care-network />
      <Tab label="Documents" value={2} data-test-details />
      <Tab label="Services" value={3} data-test-services />
      {perms.CareNetwork_Communication && (
        <Tab label="Communication" value={4} data-test-communication />
      )}
      <Tab label="History" value={5} data-test-history />
    </Tabs>
    <TabDisplay
      index={0}
      value={value}
    >
      <ChildDetails childID={Number(id)} onUpdate={onUpdate} />
    </TabDisplay>
    <TabDisplay
      index={1}
      value={value}
    >
      <NetworkMemberDetails
        childName={String(child !== undefined ? `${child?.firstName} ${child?.lastName}` : '')}
        childID={id !== undefined ? parseInt(id) : 0}
      />
    </TabDisplay>
    <TabDisplay
      index={2}
      value={value}
    >
      <ChildAndGuardianDocumentList
        maxWidth='500px'
        childID={id !== undefined ? parseInt(id) : 0}
        editable={perms.CareNetwork_Documentation_Edit ?? false}
        isdID={isdid}
      />
    </TabDisplay>
    <TabDisplay
      index={3}
      value={value}
    >
      <CareNetworkServices isdId={isdid} childName={`${child?.firstName ?? ''} ${child?.lastName ?? ''}`}
        childID={id !== undefined ? parseInt(id) : 0}
        districtId={child?.districtId ?? 0}

      />
    </TabDisplay>
    <TabDisplay
      index={4}
      value={value}
    >
      <ErrorProvider>
        <CommunicationList childId={Number(id)} />
      </ErrorProvider>
    </TabDisplay>
    <TabDisplay
      index={5}
      value={value}
    >
      <ChildHistoryDisplay childId={Number(id)} accountId={isdid} />
    </TabDisplay>
  </Box>
}
