import { Avatar, Box, FormLabel, type SxProps, Tooltip, tooltipClasses } from '@mui/material'
import { CheckBoxWithLabel } from '../CheckBox'
import { sendGet } from '../../hooks/use-fetch'
import { useEffect, useState } from 'react'
import { common } from '@mui/material/colors'
import { type InterestFormLabel } from '../../core/types'

interface Props {
  name: string
  labelName: string
  isdId: string | number
  defaultLabelText?: string
  defaultTooltipText?: string
  sx?: SxProps
  disabled?: boolean
  value: boolean
  setValue: (newVal: boolean) => void
  fullWidth?: boolean
  flex?: boolean
  block?: boolean
  edge: 'end' | 'start'
  textClassName?: string
  buttonClassName?: string
  checkboxClassName?: string
  dataTestId?: string
}

export function CheckBoxWithCustomLabel (props: Props): JSX.Element {
  const color = '#4591bb'
  const [open, setOpen] = useState(false)
  const [tooltipText, setTooltipText] = useState<string>('')
  const [labelText, setLabelText] = useState<string>('')

  const handleTooltipClose = (): void => {
    setOpen(false)
  }

  const handleTooltipOpen = (): void => {
    setOpen(true)
  }

  useEffect(() => {
    async function fetchValues (): Promise<void> {
      const { response }: { response: InterestFormLabel } = await sendGet(`/Label/GetDetails?isdId=${props.isdId}&name=${props.labelName}`, {})

      let tooltip = props.defaultTooltipText ?? ''
      let label = props.defaultLabelText ?? ''
      if (response != null) {
        if (response.hoverText != null && response.hoverText.trim() !== '') {
          tooltip = response.hoverText
        }
        if (response.labelText != null && response.labelText.trim() !== '') {
          label = response.labelText
        }
      }
      setTooltipText(tooltip)
      setLabelText(label)
    }
    void fetchValues()
  }, [props.labelName])

  let tooltip
  if (tooltipText != null && tooltipText !== '') {
    tooltip =
      <Tooltip
        title={tooltipText}
        placement='top'
        onClose={handleTooltipClose}
        open={open}
        arrow
        PopperProps={{
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: color
            }
          }
        }}
      >
        <Box>
          <Avatar onClick={handleTooltipOpen} sx={{ bgcolor: common.black, width: '18px', height: '18px', fontSize: '15px', cursor: 'pointer', ml: '5px' }}>i</Avatar>
        </Box>
      </Tooltip>
  }

  const labelContent = <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <FormLabel
        data-testid={`custom-label-${props.labelName}`}
        htmlFor={props.labelName}
        sx={{
          color: 'black',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
          fontWeight: 'inherit',
          ...props.sx
        }}
      >
        {labelText}
      </FormLabel>
    </Box>
  </Box>

  return <CheckBoxWithLabel
    labelContent={labelContent}
    unclickableContent={tooltip}
    name={props.name}
    disabled={props.disabled}
    value={props.value}
    setValue={props.setValue}
    fullWidth={props.fullWidth}
    flex={props.flex}
    block={props.block}
    edge={props.edge}
    textClassName={props.textClassName}
    buttonClassName={props.buttonClassName}
    checkboxClassName={props.checkboxClassName}
    dataTestId={props.dataTestId}
  />
}
