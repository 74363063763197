import { Chip } from '@mui/material'
import { SmallCard } from '../../../../Components/Cards/Small'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

export function AllocationCard (props: { allocationType: Array<{ key: string, count: number }> }): JSX.Element {
  const badges = props.allocationType.map((at) => {
    const colorItem = at.key.length % 3
    let indicatorClass = 'cyan-'
    if (colorItem === 1) {
      indicatorClass = 'purple-'
    } else if (colorItem === 2) {
      indicatorClass = 'red-'
    }
    return <Chip key={at.key + indicatorClass + 'allocationicon'} icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={at.count} size='small' className={indicatorClass + 'indicator'} />
  })

  return (<SmallCard
    header={'Allocation by Type'}
    totalValue={props.allocationType.reduce((a, b) => a + b.count, 0)}
    badges={<>{badges}</>} onClick={() => {}}
  />)
}
