import { Box, CircularProgress, Switch } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { type PaginationProps, usePagination } from '../../hooks/use-pagination'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { toast } from 'react-toastify'
import S3Image from '../../Components/S3Image/index'

export interface DocumentProps {
  refreshTabs: () => void
}

const nameColumn = new Column('name', 'Name')
const documentColumn = new Column('documentTarget', 'Document Target')
const enabledColumn = new Column('switch', 'Document Availability', ColumnType.CUSTOM)
const iconColumn = new Column('image', 'Icon', ColumnType.CUSTOM)
iconColumn.customCellGenerator = row => {
  if (row.s3Key != null) {
    const imageurl: string = row.imageFile.toString()

    return <S3Image imageKey={imageurl} className="tbl-row-img" style={{ maxWidth: '100px' }} alt='logo' data-testid={'logo-' + imageurl} />
  } else {
    return <div></div>
  }
}
const columns = [nameColumn, iconColumn, documentColumn, enabledColumn]

export function DocumentList (props: DocumentProps): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<RowData[]>([])
  const [filterDate, setFilterDate] = useState(new Date())
  const isdID = useAccountId()?.id ?? 0

  enabledColumn.customCellGenerator = row => {
    return <Switch checked={row.enabled} onChange={(e: any, checked: boolean) => { handleEnableChange(row, checked) }} />
  }

  const submitEnableChanged = async (updateData: object, checked: boolean): Promise<void> => {
    await sendPost('/ISDDocument/UpdateDocumentType', updateData)
    if (checked) {
      toast.success('This document is now available to be collected for your ISD')
    } else {
      toast.success('This document is now unavailable to be collected for your ISD')
    }
    setFilterDate(new Date())
    props.refreshTabs()
  }

  const handleEnableChange = (data: RowData, checked: boolean): void => {
    const isddocument = { documentTypeId: data.id, enable: checked, isdid: isdID }
    void submitEnableChanged(isddocument, checked)
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: userInfos } = await sendGet(`/ISDDocument/GetAllDocumentsTypes?isdId=${isdID}`, {})
      try {
        const data = userInfos
        setRows(data)
      } catch (e) {
        setRows([])
      }
      setLoading(false)
    }
    void fetchData()
  }, [filterDate])

  const paginationProps: PaginationProps<RowData> = {
    rows,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  if (loading) { return <CircularProgress /> } else {
    return <Box>
      <DataTable
        page={pagination.page}
        name='fileTypeTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        initialColumnSorts={[new ColumnSort('name')]}
      />
    </Box>
  }
}
