import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { Box, Button, Grid, Typography } from '@mui/material'
import { type postError, type ChildEditValues, type lookup } from '../../../core/types'
import S3Image from '../../../Components/S3Image'
import CancelIcon from '@mui/icons-material/Cancel'
import FilePicker from '../../../Components/FilePicker'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { type IdentityResponseItem, UICLookupModal } from '../../../Components/ChildDetails/UICLookupModal'

export interface ChildEditLayoutProps {
  errors?: postError[] | undefined
  child: ChildEditValues
  isUnborn: boolean
  handleChange: (child: ChildEditValues) => void
  file: File | null
  setFile: (file: File | null) => void
  languages: lookup[]
  authStatuses: lookup[]
}

const genderOptions: Array<{ id: string, name: string }> = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' }
]

export function ChildEditLayout (props: ChildEditLayoutProps): JSX.Element {
  const [allowUICLookup, setAllowUICLookup] = useState<boolean>(false)

  const handleDOBChange = (newDate: Date | null): void => {
    props.handleChange({ ...props.child, dateOfBirth: newDate })
  }

  const handleDueDateChange = (newDate: Date | null): void => {
    props.handleChange({ ...props.child, dueDate: newDate })
  }

  const handleLanguageChange = (e: any): void => {
    props.handleChange({ ...props.child, languageID: e.target.value })
  }

  const handleGenderChange = (e: any): void => {
    props.handleChange({ ...props.child, birthGender: e.target.value })
  }

  const handleAuthStatusChange = (e: any): void => {
    props.handleChange({ ...props.child, authorizationStatusID: e.target.value })
  }

  const handleChange = (e: any): void => {
    props.handleChange({ ...props.child, [e.target.name]: e.target.value })
  }

  const removeLogo = (): void => {
    props.handleChange({ ...props.child, image: null })
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet('/ChildDetails/AllowUICLookup', {})
      setAllowUICLookup(response)
    }
    void fetchData()
  }, [])

  const uploadFile = (files: FileList | null): void => {
    if (files !== null) {
      props.setFile(files[0])
    }
  }

  const dispatch = useChildUpdateDispatch()
  const [showUICBanner, setShowUICBanner] = useState(false)

  const lookupUIC = async (): Promise<void> => {
    const { response } = await sendPost('/ChildDetails/ValidateChild', props.child)
    const errors: postError[] = response as postError[]
    const messageError = errors.filter(f => f.group !== 'Child')
    if (messageError.length > 0) {
      toast.error(messageError[0].error)
    }
    const requiredValueErrors = errors.filter(f => f.group === 'Child')
    if (requiredValueErrors.length > 0) {
      toast.error('Please fill out all required fields')
    }
    dispatch({ type: 'errors', errors })
    if (errors.length === 0) {
      setShowUICBanner(true)
    }
  }

  const childMatched = (uic: string | null, apiResponse: IdentityResponseItem | null, updateValues: boolean): void => {
    setShowUICBanner(false)
    if (uic !== null) {
      const editChild = { ...props.child }
      editChild.uic = uic
      if (updateValues && apiResponse !== null) {
        editChild.dateOfBirth = apiResponse.birthDate
        editChild.firstName = apiResponse.firstName
        editChild.middleName = apiResponse.middleName
        editChild.lastName = apiResponse.lastSurname
        editChild.suffix = apiResponse.generationCodeSuffix
        editChild.birthGender = apiResponse.sexType
      }
      props.handleChange(editChild)
      setNoUIC(false)
    }
  }

  const firstNameError = (props.errors?.filter(f => f.field === 'FirstName').length ?? 0) > 0
  const lastNameError = (props.errors?.filter(f => f.field === 'LastName').length ?? 0) > 0
  const dobError = (props.errors?.filter(f => f.field === 'DateOfBirth').length ?? 0) > 0
  const dueDateError = (props.errors?.filter(f => f.field === 'DueDate').length ?? 0) > 0
  const genderError = (props.errors?.filter(f => f.field === 'BirthGender').length ?? 0) > 0
  const [noUIC, setNoUIC] = useState(props.child.uic === null || props.child.uic === '')
  const content =
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldWithLabel
            label='First Name'
            name='firstName'
            maxLength={50}
            value={props.child.firstName ?? ''}
            onChange={handleChange}
            className='mr-5'
            required={!props.isUnborn}
            showRequiredText={firstNameError}
            data-testid="firstName"
            error={firstNameError}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWithLabel
            label='Last Name'
            data-testid='lastName'
            name='lastName'
            maxLength={50}
            value={props.child.lastName ?? ''}
            onChange={handleChange}
            required={true}
            showRequiredText={lastNameError}
            error={lastNameError}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWithLabel
            label='Middle Name'
            name='middleName'
            maxLength={50}
            value={props.child.middleName ?? ''}
            onChange={handleChange}
            className='mr-5'
            dataTestId='middleName'
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWithLabel
            label='Suffix'
            name='suffix'
            maxLength={50}
            value={props.child.suffix ?? ''}
            onChange={handleChange}
            dataTestId='suffix'
          />
        </Grid>
        {props.isUnborn &&
          <Grid item xs={6}>
            <DatePickerWithLabel
              label='Due Date'
              onChange={handleDueDateChange}
              value={props.child.dueDate ?? null}
              name='dueDate'
              dataTestId="dueDate"
              required={true}
              showRequiredText={dueDateError}
              error={dueDateError}
            />
          </Grid>
        }
        {!props.isUnborn &&
          <>
            <Grid item xs={6}>
              <DatePickerWithLabel
                label='Date of Birth'
                onChange={handleDOBChange}
                value={props.child.dateOfBirth ?? null}
                name='dateofBirth'
                dataTestId="dateOfBirth"
                required={true}
                showRequiredText={dobError}
                error={dobError}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithLabel
                name="birthGender"
                label="Assigned Gender at Birth"
                data-testid="birthGender"
                value={props.child.birthGender ?? ''}
                options={genderOptions}
                onChange={handleGenderChange}
                required={true}
                showRequiredText={genderError}
                error={genderError}
                maxWidth='100%'
                width='100%'
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithLabel
                name="primaryLanguage"
                label="Primary Language (if not English)"
                width="97%"
                required={false}
                includeNoneOption={true}
                value={props.child.languageID ?? undefined}
                options={props.languages}
                onChange={handleLanguageChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
                <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
                  {props.file != null && <Box>
                    <Typography>Uploading {props.file.name}</Typography>
                  </Box>}
                  <FilePicker
                    label='Child Pic'
                    name='file'
                    onSelect={(e: any) => {
                      uploadFile(e.target.files)
                    }}
                  />
                  {props.child.image !== null && props.child.image !== undefined && props.child.image !== '' && (<>
                    <S3Image imageKey={props.child.image} />
                    <Button onClick={removeLogo} data-testid='remove-logo' sx={{ paddingLeft: 0, verticalAlign: 'top', minWidth: '45px' }}><CancelIcon sx={{ color: 'black' }} /></Button>
                  </>)}
                </Box>
              </Box>
            </Grid>
          </>
        }
        <Grid item xs={12}>
          <SelectWithLabel
            name="authorizationStatusID"
            label="Authorization Status"
            width="97%"
            required={false}
            includeNoneOption={true}
            value={props.child.authorizationStatusID ?? undefined}
            options={props.authStatuses}
            onChange={handleAuthStatusChange}
          />
        </Grid>
        {allowUICLookup && !props.isUnborn &&
          <>
            <Grid item xs={2}>
              <TextWithLabel
                label='UIC'
                name='uic'
                testId='uic'
                value={noUIC ? '(none)' : props.child.uic}
              />
            </Grid>
            <Grid item xs={6} marginTop='10px'>
              {noUIC && <Button onClick={lookupUIC} variant='outlined' color='inherit'>Lookup UIC </Button>}
            </Grid>
          </>
        }
      </Grid>

      {showUICBanner && <UICLookupModal onClose={childMatched} child={props.child} />}
    </>

  return content
}
