import { Box, Button, Grid, Link, Typography } from '@mui/material'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { type PortalFacilityHours, type EnrollmentDetails, type PortalServiceProviderSearchResult } from '../../../../core/types'
import { useParams } from 'react-router'
import { sendGet } from '../../../../hooks/use-fetch'
import { Autocomplete } from '../../../../Components/AutoComplete'
import { SelectableRow } from '../SelectableRow'
import { MapModule, useBingMaps } from '../../../../hooks/use-bingMaps'
import { FormatTimeAs12Hour } from '../../../../core/Utilities'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface ServiceProviderOption {
  id: number
  name: string
  subtext: string
  distance: string
  times: PortalFacilityHours[]
}

interface ServiceProviderSearchProps {
  caregiverForm: EnrollmentDetails
  handleStep: (backwards: boolean) => void
}

export function ServiceProviderSearch (props: ServiceProviderSearchProps): JSX.Element {
  const { isLoaded, getLocationFromAddress, distanceBetweenPoints } = useBingMaps({ modules: [MapModule.SpatialMath] })
  const dispatch = useCaregiverWizardUpdateDispatch()
  const { isdId, isdServiceProviderId } = useParams()
  const [providers, setProviders] = useState<ServiceProviderOption[]>([])
  const [selectedProviders, setSelectedProviders] = useState<ServiceProviderOption[]>([])
  const [refreshToken, setRefreshToken] = useState(0)

  const caregiverForm = props.caregiverForm

  useScrollTop()

  const fetchData = async (): Promise<void> => {
    const currentService = caregiverForm.currentChild.requestedServices.find(s => s.isCurrentlyUpdating)
    const systemServiceId = currentService?.id
    if (systemServiceId == null) {
      return
    }

    const { response, success } = await sendGet(`/CaregiverPortal/GetAllProviders/${isdId ?? 0}/ForService/${systemServiceId}/ISDServiceProvider/${isdServiceProviderId ?? 0}`, {})
    if (success && window.Microsoft !== undefined && isLoaded) {
      const childLocation = await childMapLocation()

      const providerOptions = [] as ServiceProviderOption[]
      for (const provider of response as PortalServiceProviderSearchResult[]) {
        const distance = await distanceBetween(childLocation, provider)
        const sundayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Sunday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Sunday' }
        const mondayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Monday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Monday' }
        const tuesdayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Tuesday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Tuesday' }
        const wednesdayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Wednesday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Wednesday' }
        const thursdayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Thursday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Thursday' }
        const fridayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Friday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Friday' }
        const saturdayHours = provider.facilityHours.find(h => h.dayOfWeek === 'Saturday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Saturday' }
        const times = [sundayHours, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours] as PortalFacilityHours[]

        providerOptions.push({
          id: provider.facilityID,
          name: `${provider.serviceProviderName} - ${provider.facilityName}`,
          subtext: distance,
          distance,
          times
        })
      }

      setProviders(providerOptions)

      const tempProviders = [] as ServiceProviderOption[]
      if (currentService != null) {
        for (const provider of providerOptions) {
          if (currentService.facilityIds.includes(provider.id)) {
            tempProviders.push(provider)
          }
        }
      }
      setSelectedProviders(tempProviders)
    }
  }

  useEffect(() => {
    void fetchData()
  }, [isdId, isLoaded, caregiverForm.currentChild.requestedServices, caregiverForm.currentChild.location])

  const childMapLocation = async (): Promise<Microsoft.Maps.Location | null> => {
    const child = caregiverForm.currentChild
    let childLocation = child.location
    if (child.livesWithFirstChild) {
      childLocation = caregiverForm.children[0].location
    }

    return await getLocationFromAddress({
      address: childLocation?.address,
      city: childLocation?.city,
      state: childLocation?.state,
      zipCode: childLocation?.zipCode
    })
  }

  const distanceBetween = async (childLocation: Microsoft.Maps.Location | null, provider: PortalServiceProviderSearchResult): Promise<string> => {
    const providerLocation = await getLocationFromAddress({
      latitude: provider.latitude,
      longitude: provider.longitude,
      address: provider.address,
      city: provider.city,
      state: provider.state,
      zipCode: provider.zipCode
    })

    if (childLocation == null || providerLocation == null) {
      return 'Unknown distance'
    }

    const milesTo = distanceBetweenPoints(childLocation, providerLocation)

    return milesTo.toFixed(1) + ' miles away'
  }

  const handleSelect = (e: any, value: { id: string | number, name: string } | null): void => {
    const provider = providers.find(p => p.id === value?.id)
    if (provider == null) {
      return
    }

    const tempProviders = [...selectedProviders]
    const existingProviderIndex = tempProviders.findIndex(p => p.id === provider.id)
    if (existingProviderIndex === -1) {
      tempProviders.push(provider)
      setSelectedProviders(tempProviders)
      setRefreshToken(refreshToken + 1)
    }
  }

  const handleUnselect = (id: number): void => {
    const tempProviders = [...selectedProviders]
    const existingProviderIndex = tempProviders.findIndex(p => p.id === id)
    if (existingProviderIndex !== -1) {
      tempProviders.splice(existingProviderIndex, 1)
      setSelectedProviders(tempProviders)
      setRefreshToken(refreshToken + 1)
    }
  }

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 3, currentSubStep: 3 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    if (selectedProviders.length === 0) {
      toast.error('Please select a provider to continue')
      return
    }

    const tempForm = { ...caregiverForm, currentSubStep: 3 }
    const tempChild = { ...tempForm.currentChild }
    const currentService = tempChild.requestedServices.find(s => s.isCurrentlyUpdating)
    if (currentService != null) {
      currentService.facilityIds = selectedProviders.map(p => p.id)
    }
    const existingChildIndex = tempForm.children.findIndex(c => c.isCurrentlyUpdating)
    if (existingChildIndex !== -1) {
      tempChild.isCurrentlyUpdating = false
      const service = tempChild.requestedServices.find(s => s.isCurrentlyUpdating)
      if (service != null) {
        service.isCurrentlyUpdating = false
      }
      tempForm.children[existingChildIndex] = tempChild
    }
    const initialForm = initialEnrollmentDetails()
    tempForm.currentChild = initialForm.currentChild

    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleMapClick = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentSubStep: 2 }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  return <>
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography variant='h5' sx={{ mb: '30px' }} component='div'>
          <CustomLabelWithToolTip
            name='IF_Service_Search_Intro'
            isdId={isdId ?? 0}
            defaultLabelText='Great news! Where would you like to go?'
          />
        </Typography>
        <Typography variant='subtitle1' sx={{ mb: '10px' }} component='div'>
          Want to browse providers in the area? Go <Link sx={{ cursor: 'pointer' }} onClick={handleMapClick} data-testid='browse-providers'>here</Link> instead.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          key={refreshToken}
          width='100%'
          label='Service Provider'
          name='serviceProvider'
          onChange={handleSelect}
          options={providers}
          value={0}
          placeholder="Search providers..."
          noBottomPadding
          dataTestId='service-provider'
        />
      </Grid>

      {selectedProviders.length > 0 &&
        <Grid item xs={12}>
          {selectedProviders.map((provider) => {
            return <SelectableRow
              key={provider.id}
              content={<Box>
                {provider.name}
                {provider.times.map((time) => {
                  return <Box key={time.dayOfWeek}>
                    <Typography variant='body1' sx={{ mt: '10px', display: 'flex', justifyContent: 'space-between', width: '250px' }} component='div'>
                      <Box>{time.dayOfWeek}</Box>
                      <Box sx={{ width: '135px' }}>{time.openTime !== '' ? `${FormatTimeAs12Hour(time.openTime)} - ${FormatTimeAs12Hour(time.closeTime)}` : 'Closed'}</Box>
                    </Typography>
                  </Box>
                })}
                <Typography variant='body1' sx={{ mt: '10px' }} component='div'>
                  {provider.distance}
                </Typography>
              </Box>
              }
              id={provider.id}
              selectedId={provider.id}
              setSelected={handleUnselect}
            />
          })}
        </Grid>
      }

      <Grid item xs={6} sx={{ mb: '20px', mt: '20px' }}>
        <Button
          name='backButton'
          className='back-button'
          data-testid='back-button'
          onClick={handleBack}
          variant='outlined'
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ mb: '20px', mt: '20px' }}>
        <Button
          name='continueButton'
          className='footer-button'
          data-testid='continue-button'
          onClick={handleContinue}
          variant='contained'
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  </>
}
