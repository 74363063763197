import { Box, type InputProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import Input from './input'
import { LabelWithToolTip } from '../Label/Index'
interface Props {
  label: string
  name: string
  onChange: (e: any) => void
  value: unknown
  required?: boolean
  disabled?: boolean
  multiline?: boolean
  toolTip?: string
  dataTestId?: string
}

export function TextAreaWithLabel (props: Props): JSX.Element {
  const isDisabled = props.disabled ?? false
  const ip: InputProps = {}
  if (isDisabled) {
    ip.readOnly = true
    ip.disableUnderline = true
  }
  return (
    <Box
      data-testid={props.dataTestId}
      sx={{
        pt: '8px',
        flexDirection: 'column',
        display: 'flex',
        maxWidth: 'lg',
        mr: 2
      }}
    >
      <LabelWithToolTip
        required={props.required}
        name={props.name}
        labelText={props.label}
        toolTipText={props.toolTip}
        sx={{ color: 'black' }}
      />
      <Box>
        {isDisabled && (
          <Typography>
            <>{props.value}</>
          </Typography>
        )}
        {!isDisabled && (
          <Input
            multiline
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          />
        )}
      </Box>
    </Box>
  )
}
