/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Badge, Box, CircularProgress, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { TabDisplay } from '../../Components/TabDisplay'
import { useState } from 'react'
import { UserProfileDisplay } from './UserProfileDisplay'
import { useAuth } from '../../hooks/use-auth'
import EditIcon from '@mui/icons-material/Edit'
import S3Avatar from '../../Components/S3Image/S3Avatar'
import { Modal } from '../../Components/Modal'
import { sendPostFormData } from '../../hooks/use-fetch'
import { UserOrgainizations } from './UserOrganizations'
import { UserSubscriptions } from './UserSubscriptions'
import { ImageCropper } from '../../Components/ImageCropper/ImageCropper'

export function UserProfile (): JSX.Element {
  const auth = useAuth()
  const [currentTab, setCurrentTab] = useState(1)
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const handleImageUploadOpen = (): void => {
    setImageUploadOpen(true)
  }

  const handleImageUploadClose = (): void => {
    setImageUploadOpen(false)
  }
  const uploadImage = async (): Promise<void> => {
    const userUpdateUrl = '/User/UpdateProfileImage'
    const fileData = new FormData()
    fileData.append('id', auth.user?.id.toString() ?? '0')
    if (croppedImage !== null) {
      const blobMaybe = await fetch(croppedImage)
      const blob = await blobMaybe.blob()
      fileData.append('file', blob)
    } else {
      return
    }
    await sendPostFormData(userUpdateUrl, fileData)
    setImageUploadOpen(false)
    await auth.setCurrentUser()
  }

  if (auth.user === null) return <CircularProgress />
  const userName = auth.user?.firstName + ' ' + auth.user?.lastName
  return <Box>
    <Grid container columns={12}>
      <Grid item sx={{ display: 'flex' }}>
        <Typography sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center' }} variant='h3'>My Profile- {userName}</Typography>
      </Grid>
      <Grid item sm={2} md={2}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <IconButton
              sx = {{
                backgroundColor: 'darkgray',
                ':hover': { backgroundColor: 'lightgray' }
              }}
              onClick={handleImageUploadOpen}
            ><EditIcon /></IconButton>
      }
        >
          <S3Avatar imageKey={auth.user?.imageKey ?? ''} alt={userName} />
        </Badge>
      </Grid>
    </Grid>
    <Modal
      onClose={handleImageUploadClose}
      onConfirm={uploadImage}
      open={imageUploadOpen}
      title='Upload Image'
      confirmationContent={<>
        <ImageCropper
          setCroppedImage={setCroppedImage}
        />

      </>}
    />
    <Tabs value={currentTab}
      data-test-tabs
      onChange={(e, val) => { setCurrentTab(val) }}
      aria-label="User Profile Tabs"
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label="Details" value={1} />
      <Tab label="Organizations" value={2} />
      <Tab label="Subscriptions" value={3} />
    </Tabs>
    <TabDisplay value={currentTab} index={1}>
      <UserProfileDisplay auth={auth}/>
    </TabDisplay>
    <TabDisplay value={currentTab} index={2}>
      <UserOrgainizations {...auth} />
    </TabDisplay>
    <TabDisplay value={currentTab} index={3}>
      <UserSubscriptions {...auth} />
    </TabDisplay>

  </Box>
}
