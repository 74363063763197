import { Box, CircularProgress } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { type ISD } from '../../core/types'
import { useEffect, useState } from 'react'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { sendGet } from '../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../Components/DatePicker'
import { TextFieldWithLabel } from '../../Components/TextField'
import { AddUserTemplate } from '../../Components/MultiAddSelect/AddUserTemplate'
import { RemovableAddSelect } from '../../Components/MultiAddSelect/RemovableAddSelect'

interface UserType {
  firstName: string
  lastName: string
  email: string
}

export interface ISDEditProps {
  open: boolean
  isNew: boolean
  onClose: () => void
  onSubmit: (newValue: ISD, newUserData: userData) => void
  isd: ISD
  errors?: string[] | undefined
}

export interface userData {
  firstName: string
  lastName: string
  email: string
}

export function ISDEdit (props: ISDEditProps): JSX.Element {
  const [isd, setISD] = useState(props.isd)
  const [userOptions, setUserOptions] = useState<Array<{ id: number, name: string }>>([{ id: -1, name: 'Add New User' }])
  const [newUserData, setNewUserData] = useState<UserType>({ firstName: '', lastName: '', email: '' })

  const title = (props.isNew ? 'Add ISD' : 'Edit ISD')

  const handleClose = (): void => {
    props.onClose()
  }

  const handleISDChange = async (e: any): Promise<void> => {
    const idString: string = e.target.value
    const { response: rsp } = await sendGet(`/ISD/Get?id=${idString}`, {})
    setISD({ ...rsp, onboardDate: isd.onboardDate })
  }

  const handleChange = (e: any): void => {
    setISD({ ...isd, [e.target.name]: e.target.value })
  }

  const handleUserChange = (e: any, value: {
    id: string | number
    name: string
  } | null): void => {
    let id = value?.id ?? ''
    if (typeof (id) === 'string') { id = parseInt(id) }
    setISD({ ...isd, adminUserId: id })
  }

  const handleDateChange = (name: string, newDate: Date | null): void => {
    setISD({ ...isd, [name]: newDate })
  }

  const handleSubmit = (): void => {
    props.onSubmit(isd, newUserData)
    // props.onClose()
  }

  const [isdList, setISDList] = useState<Array<{ id: number, name: string }>>([])
  const [statusList, setStatusList] = useState<Array<{ id: string, name: string }>>([])
  const [loaded, setLoaded] = useState(false)

  const addTemplate =
    <AddUserTemplate
      userData={newUserData}
      handleChange={(data) => {
        setNewUserData(data)
      }}
    />

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/ISD/GetLookup?sort=true&queryStringFilter=Status%3DNotEnrolled', {})
      if (error[0] === '') { setISDList(rsp) }
      const { response: rsp2, error: error2 } = await sendGet(`/ISD/GetISDUsers?isdid=${props.isd.id}`, {})
      const usrs: Array<{ id: number, firstName: string, lastName: string }> = rsp2
      const usrList = usrs.map(u => {
        return { id: u.id, name: `${u.firstName} ${u.lastName}` }
      })
      if (error2[0] === '') { setUserOptions([...usrList, { id: -1, name: 'Add New User' }]) }
      const { response: statusResponse, error: e2 } = await sendGet('/Utilities/EnumList?enumName=ISDStatus', {})
      let results: Array<{
        id: string
        name: string
      }> = statusResponse as Array<{
        id: string
        name: string
      }>
      if (!props.isNew) { results = results.filter(f => f.id !== 'NotEnrolled') }
      if (e2[0] === '') { setStatusList(results) }

      setLoaded(true)
    }
    void loadData()
  }, [props.isd])

  if (!loaded) return <CircularProgress role='loading' />

  const content = <Box>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    <Box sx={{ boxSizing: 'border-box', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Box component={'table'} sx={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              {!props.isNew && (
                <TextFieldWithLabel
                  label='ISD'
                  name='name'
                  value={isd.name}
                  disabled
                  onChange={() => {}}
                  dataTestId='isd'
                />
              )}
              {props.isNew && (
                <SelectWithLabel
                  label='ISD'
                  name='isd'
                  onChange={handleISDChange}
                  width='250px'
                  value={isd.id !== 0 ? isd.id : ''}
                  options={isdList}
                />
              )}
            </td>
            <td>
              <TextFieldWithLabel
                label='EEM Code'
                name='eemCode'
                disabled
                onChange={() => {}}
                value={isd.id.toString() + '000'}
                dataTestId='eemCode'
              />
            </td>
          </tr>
          <tr>
            <td>
              <DatePickerWithLabel
                label='Onboard Date'
                onChange={(newValue) => { handleDateChange('onboardDate', newValue) }}
                name='onboardDate'
                value={isd.onboardDate}
                dataTestId='onboardDate'
              />
            </td>
            <td>
              <SelectWithLabel
                label='Status'
                name='status'
                onChange={handleChange}
                width='200px'
                value={isd.status}
                options={statusList}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <RemovableAddSelect
                label={'Primary Contact'}
                onChange={handleUserChange}
                key={'adminUser'}
                name={'adminUserId'}
                options={userOptions}
                value={ isd.adminUserId ?? ''}
                hideDeleteBtn={true}
                onDeleteBtnClick={(e: any) => { }}
                addNewFragment={addTemplate}
              />
            </td>
          </tr>
        </tbody>
      </Box>
    </Box>
  </Box>
  return <Modal
    maxWidth='sm'
    fullWidth
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
