import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { type ChildEditValues, type postError } from '../../../core/types'
import ProgressBar from '../../../Components/ProgressBar'
import { AddChildRace } from './AddChildRace'
import { AddChildBasicInfo } from './AddChildBasicInfo'
import { ChildBornOrNot } from './ChildBornOrNot'
import { AddChildCareNetwork } from './AddChildCareNetwork'
import { AddChildAddress } from './AddChildAddress'
import { StepsModal } from '../../../Components/Modal/StepsModal'
import { SlideTab } from '../../../Components/TabDisplay/SlideTab'
import { type newChildRecord, useChildContext, useChildUpdateDispatch, type childServiceSettings } from '../../../ContextProviders/AddChildProvider'
import { ChildUpdateProvider } from '../../../ContextProviders/AddChildProvider'
import { AddChildService } from './AddChildService'
import { AddChildReview } from './AddChildReview'
import { sendGet, sendPost, sendPostFormData, sendPut } from '../../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { AddChildDocuments } from './AddChildDocuments'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export interface AddChildModalProps {
  endpoint: string
  serviceProviderEndpoint: string
  serviceID?: number
  open: boolean
  onClose: () => void
  onSubmit: (childID: number, referralID: number) => void
  canSkipService?: boolean
}

export function AddChildModal (props: AddChildModalProps): JSX.Element {
  return <ChildUpdateProvider>
    <AddChildModalInterior {...props}/>
  </ChildUpdateProvider>
}

export function AddChildModalInterior (props: AddChildModalProps): JSX.Element {
  const data: newChildRecord = useChildContext()
  const account = useAccountId()
  const totalSteps = data.isLoadedFromExistingChild ? 2 : 8
  const dispatch = useChildUpdateDispatch()

  useEffect(() => {
    dispatch({ type: 'clear' })
  }, [props.open])
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const isEndStep = data.step === totalSteps
  const validateStep = async (): Promise<boolean> => {
    switch (data.step) {
      case 2:
        return await VerifyBasicInfo()
      case 3:
        return await VerifyAddresses()
      case 4:
        return await VerifyRace()
      case 5:
        return await VerifyNetwork()
      case 6:
      {
        if (data.isLoadedFromExistingChild && !data.serviceIsValid) {
          return await VerifyTransfer()
        } else { return await VerifyService() }
      }
      default:
        return true
    }
  }

  const VerifyRace = async (): Promise<boolean> => {
    const referral = {
      child: data.child
    }
    const { response } = await sendPost(`/${props.endpoint}/ValidateRace`, referral)
    const errors: postError[] = response as postError[]
    if (errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        toast.error(errors[i].error)
      }
    }

    dispatch({ type: 'errors', errors })
    return errors.length === 0
  }

  const VerifyBasicInfo = async (): Promise<boolean> => {
    const { response } = await sendPost(`/${props.endpoint}/ValidateChild`, data.child)
    const errors: postError[] = response as postError[]
    const messageError = errors.filter(f => f.group !== 'Child')
    if (messageError.length > 0) {
      toast.error(messageError[0].error)
    }
    const requiredValueErrors = errors.filter(f => f.group === 'Child')
    if (requiredValueErrors.length > 0) {
      toast.error('Please fill out all required fields')
    }
    dispatch({ type: 'errors', errors })
    return errors.length === 0
  }

  const VerifyAddresses = async (): Promise<boolean> => {
    const referral = {
      locations: [data.locations[0].location]
    }
    const { response } = await sendPost(`/${props.endpoint}/ValidateLocation`, referral)
    const errors: postError[] = response as postError[]
    const primaryError = errors.filter(f => f.field === 'Primary')
    if (primaryError.length > 0) { toast.error(primaryError[0].error) }
    const oneLocError = errors.filter(f => f.field === 'Location')
    if (oneLocError.length > 0) { toast.error(oneLocError[0].error) }
    if (errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        toast.error(errors[i].error)
      }
      return false
    }
    const loc = referral.locations[0]
    const { response: rsp2 } = await sendGet(`/ReferralEdit/GetLocationISD?address=${loc.address}` +
        `&city=${loc.city}&state=${loc.state}&zipCode=${loc.zipCode}`, {})
    let isdId = data.manualDistrict?.isdId ?? null
    if (rsp2.errorMessage != null) {
      toast.error(rsp2.errorMessage)
      isdId = null // if we find a district and we dont have access to said district, do not continue, even if they previously selected a manual district
      dispatch({ type: 'district', manualDistrict: null, isdID: null })
    } else {
      if (rsp2.id != null && rsp2.id !== 0) {
        referral.locations[0].districtID = rsp2.id
        isdId = rsp2.isdID // if we find district information, use it and clear any manual setting, allowing continue
        dispatch({ type: 'district', manualDistrict: null, isdID: rsp2.isdID })
      } else if (data.manualDistrict?.isdId == null) {
        isdId = null // if we dont find district information, and we have not set up the manual district selection, dont continue
        dispatch({ type: 'district', manualDistrict: { id: 0, isdId: 0 }, isdID: null })
      }
      // otherwise, we use the manually selected district id, so long as its non zero
    }

    if (errors !== null && errors.length === 0 && (isdId != null && isdId !== 0)) {
      const { response: isdResp } = await sendGet(`/ISDDetails/GetISDConfig?id=${isdId}`, {})
      data.child.captureIEP = isdResp.captureIEP
      data.child.captureIFSP = isdResp.captureIFSP
      data.child.captureEOIS = isdResp.captureEOIS
      const tempArray = [...data.locations]
      tempArray[0].location = referral.locations[0]
      dispatch({ type: 'locations', locations: tempArray })

      return true
    } else {
      return false
    }
  }
  const VerifyTransfer = async (): Promise<boolean> => {
    let success = true
    if (data.transferRequest.transferReason == null || data.transferRequest.transferReason === '') {
      success = false
      toast.error('Transfer reason is required')
    }
    if (data.transferRequest.note == null || data.transferRequest.note === '') {
      success = false
      toast.error('Notes are required')
    }
    return success
  }
  const VerifyService = async (): Promise<boolean> => {
    const referral = {
      serviceSettings: data.serviceSettings
    }
    const { response } = await sendPost(`/${props.endpoint}/ValidateService`, referral)
    const errors: postError[] = response as postError[]
    dispatch({ type: 'errors', errors })
    errors.forEach(e => {
      toast.error(e.error)
    })
    return errors.length === 0
  }

  const VerifyNetwork = async (): Promise<boolean> => {
    const referral = {
      siblings: data.siblings.map(s => s.sibling),
      caregivers: data.caregivers.map(c => c.caregiver)
    }
    const { response } = await sendPost(`/${props.endpoint}/ValidateCaregiver`, referral)
    const errors: postError[] = response as postError[]
    const caregiverError = errors.filter(f => f.field === 'Caregiver')
    if (caregiverError.length > 0) { toast.error(caregiverError[0].error) }
    dispatch({ type: 'errors', errors })
    errors.forEach(e => {
      toast.error(e.error)
    })
    return errors.length === 0
  }
  const [backwards, setBackwards] = useState<boolean>(false)

  const requestTransfer = async (): Promise<void> => {
    if (data.transferRequest !== undefined) {
      const request = {
        accountId: account?.id,
        accountType: account?.type,
        childId: data.child.id,
        notes: data.transferRequest.note,
        childNotTiedToISD: false,
        systemServiceID: data.serviceSettings.systemService?.id,
        systemProgramID: data.serviceSettings.systemProgram?.id

      }
      const { response, success } = await sendPut('/ChildDetails/RequestAccess', request)
      if (response !== undefined) {
        if (!success) {
          if (response.status === 401) {
            toast.error('unauth')
          } else { toast.error(response.errors) }
        } else {
          toast.success('Transfer request submitted')
          props.onSubmit(0, 0)
        }
      }
    }
  }

  const handleStep = async (): Promise<void> => {
    const isValid = await validateStep()
    const nextStep = data.step + 1
    if (isValid) {
      if (data.isLoadedFromExistingChild && data.step === 6) {
        if (data.matchedReferralId != null) { await requestTransfer() } else { await handleSubmit() }
        return
      }
      if (isEndStep) {
        await handleSubmit()
        return
      }
      dispatch({ type: 'step', step: nextStep })
      setBackwards(false)
    }
  }

  const handleSkipService = (): void => {
    const nextStep = 7
    dispatch({
      type: 'service',
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      serviceSettings: {} as childServiceSettings
    })
    dispatch({ type: 'step', step: nextStep })
    setBackwards(false)
  }

  const handleBack = (): void => {
    let nextStep = data.step - 1
    if (data.isLoadedFromExistingChild && data.step === 6) {
      nextStep = 1
      dispatch({ type: 'unSelectDuplicate' })
    }
    dispatch({ type: 'step', step: nextStep })

    setBackwards(true)
  }

  const answeredIfBorn = (): void => {
    dispatch({ type: 'step', step: 2 })
  }

  const handleSubmit = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      const referral = {
        child: data.child,
        locations: data.locations.map(l => l.location),
        siblings: data.siblings.map(s => s.sibling),
        caregivers: data.caregivers.map(c => c.caregiver),
        serviceSettings: data.serviceSettings,
        districtSettings: data.manualDistrict,
        isdid: account?.id ?? null
      }
      if (data.isLoadedFromExistingChild) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        referral.child = { id: data.child.id } as ChildEditValues
      }
      const endpoint = data.isLoadedFromExistingChild ? 'CreateServiceReferral' : 'CreateReferral'
      const { response } = await sendPost(`/${props.endpoint}/${endpoint}`, referral)
      const rsp = response as { referralId: number, childId: number, caregiverIds: number[], siblingIds: number[], errors: postError[], success: boolean }

      if (rsp?.success) {
        // Handle documents here after we get the record ids
        data.childDocuments.forEach(cd => {
          cd.documents.forEach(doc => {
            if (doc.file !== null && doc.file !== undefined) {
              const fileData = new FormData()
              fileData.append('childID', response.childId)
              fileData.append('documentTypeID', cd.documentTypeID.toString())
              fileData.append('verified', doc.verified.toString())
              fileData.append('verifiedDate', doc.verifiedDate?.toString() ?? '')
              fileData.append('verifiedBy', doc.verifiedBy?.toString() ?? '')
              fileData.append('verificationDetails', doc.verificationDetails?.toString() ?? '')
              fileData.append('file', doc.file)
              void sendPostFormData('/Document/UploadFile', fileData)
            }
          })
        })
        data.caregivers.forEach(cg => {
          cg.caregiver.documents?.forEach(dt => {
            dt.documents.forEach(doc => {
              if (doc.file !== null && doc.file !== undefined) {
                const caregiverFileData = new FormData()
                caregiverFileData.append('caregiverID', response.caregiverIds[cg.index])
                caregiverFileData.append('documentTypeId', dt.documentTypeID.toString())
                caregiverFileData.append('verified', doc.verified.toString())
                caregiverFileData.append('verifiedDate', doc.verifiedDate?.toString() ?? '')
                caregiverFileData.append('verifiedBy', doc.verifiedBy?.toString() ?? '')
                caregiverFileData.append('verificationDetails', doc.verificationDetails?.toString() ?? '')
                caregiverFileData.append('file', doc.file)
                caregiverFileData.append('incomeYear', doc.incomeYear?.toString() ?? '')
                void sendPostFormData('/Document/UploadFile', caregiverFileData)
              }
            })
          })
        })

        props.onSubmit(rsp.childId, rsp.referralId)
      } else {
        rsp?.errors?.forEach(e => {
          toast.error(e.error)
        })
      }
      setIsRequestLoading(false)
    }
  }

  useEffect(() => {
    dispatch({ type: 'step', step: 1 })
    setBackwards(false)
  }, [props.open])

  const title = <Box width='100%'>
    <Grid container sx={{ overflowX: 'hidden' }}>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>Add Child Referral</Typography>
      </Grid>
      <Grid item xs={12}>
        <ProgressBar
          totalSteps={totalSteps}
          currentStep ={data.isLoadedFromExistingChild ? 2 : data.step}
        />
      </Grid>
    </Grid>
  </Box>

  const display = isRequestLoading
    ? <CircularProgress />
    : <Box width='100%'>
      <Grid container sx={{ overflowX: 'hidden' }}>
        <SlideTab index={1} value={data.step} backwards={backwards}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <ChildBornOrNot onAnswered={answeredIfBorn } />
          </Grid>
        </SlideTab>
        <SlideTab index={2} backwards={backwards} value={data.step}>
          <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <AddChildBasicInfo />
          </Grid>
        </SlideTab>
        <SlideTab index={3} backwards={backwards} value={data.step}>
          <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <AddChildAddress />
          </Grid>
        </SlideTab>
        <SlideTab index={4} value={data.step} backwards={backwards} >
          <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <AddChildRace />
          </Grid>
        </SlideTab>
        <SlideTab index={5} backwards={backwards} value={data.step}>
          <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <AddChildCareNetwork />
          </Grid>
        </SlideTab>
        <SlideTab index={6} backwards={backwards} value={data.step}>
          <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <AddChildService
              serviceID={props.serviceID}
              endpoint={props.endpoint}
              serviceProviderEndpoint={props.serviceProviderEndpoint}
              canSkip={props.canSkipService ?? false}
              onSkip={handleSkipService}
            />
          </Grid>
        </SlideTab>
        <SlideTab index={7} backwards={backwards} value={data.step}>
          <AddChildDocuments />
        </SlideTab>
        <SlideTab index={8} backwards={backwards} value={data.step}>
          <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
            <AddChildReview />
          </Grid>
        </SlideTab>
      </Grid>
    </Box>

  let nextButtonText = 'Next'
  if (isEndStep || data.isLoadedFromExistingChild) { nextButtonText = 'Submit' }
  if (data.isLoadedFromExistingChild && !data.serviceIsValid) { nextButtonText = 'Request Transfer' }
  let buttonColor: 'success' | 'secondary' = 'secondary'
  if (isEndStep || data.isLoadedFromExistingChild) { buttonColor = 'success' }
  return <StepsModal
    maxWidth='sm'
    fullWidth={true}
    titleContent={title}
    onClose={props.onClose}
    open={props.open}
    dialogContent={display}
    onBack={handleBack}
    onNext={handleStep}
    hideBackButton={data.step === 1}
    isNextDisabled={data.step === 1} // Next is disabled on the first step because the Yes/No prompt advances it
    backButtonText='Back'
    nextButtonText={nextButtonText}
    cancelButtonText='Cancel'
    nextButtonColor={buttonColor}
  />
}
