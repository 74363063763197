import { Box } from '@mui/material'
import { RadiusCard } from '../Cards/Radius/RadiusCard'
import { CARENETWORK_MANAGEMENT_DETAILS } from '../../Pages/Routes'
import { generatePath, useNavigate } from 'react-router'

export interface SiblingProps {
  id: number
  firstName: string
  lastName: string
  relationshipName: string
  isLivingWithStudent: boolean
  isUnborn: boolean
  studentName: string
  onEdit: (childId: number) => void
  onDelete: (childId: number) => void
}

export function SiblingDetails (props: SiblingProps): JSX.Element {
  const nav = useNavigate()

  const handleEdit = (): void => {
    props.onEdit(props.id)
  }

  const handleView = (): void => {
    if (props.id !== undefined) {
      const editChild = generatePath(CARENETWORK_MANAGEMENT_DETAILS, { id: String(props.id) })
      nav(editChild)
      window.location.reload()
    }
  }

  const handleDelete = (): void => {
    props.onDelete(props.id)
  }

  const getCardContent = (): any => {
    return <>
      <table role={'presentation'} data-testid="sibling-card">
        <tbody>
          <tr>
            <td>
              <Box>{props.relationshipName}</Box>
            </td>
          </tr>
          <tr>
            <td>
              {props.isLivingWithStudent ? `Lives with ${props.studentName}` : `Does not live with ${props.studentName}`}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  }

  return <>
    <Box sx={{ width: '100%;' }}>
      <RadiusCard
        onViewMenu={handleView}
        onModalConfirm={handleDelete}
        showViewProfile={true}
        onEditMenu={handleEdit}
        confirmationMessage={'Are you sure you want to remove this sibling relationship from this child?  This will not delete the record for the sibling, only the relationship to this child.'}
        showMenu={true}
        header={`${(props.isUnborn ? '(unborn)' : props.firstName)} ${props.lastName}`}
        viewProfileconfirmationMessage={'This will navigate to another child\'s record. Continue?'}
        content={getCardContent()}
        fullWidth={false}
      />
    </Box>
  </>
}
