import { type UserFunction, type User } from '../core/types'

const userFunctions = (user: User): UserFunction[] => {
  return user?.userAuthorizedFunctions?.map((fn) => fn.authorizedFunction) ?? []
}

export const PermissionsService = {
  CaregiverPortal: (user: User) => userFunctions(user).includes('CaregiverPortal'),
  CaregiverPortal_Write: (user: User) => userFunctions(user).includes('CaregiverPortal_Write'),
  CaregiverPortal_Enrollments: (user: User) => userFunctions(user).includes('CaregiverPortal_Enrollments'),
  CaregiverPortal_Enrollments_Write: (user: User) => userFunctions(user).includes('CaregiverPortal_Enrollments_Write'),
  CaregiverPortal_Details: (user: User) => userFunctions(user).includes('CaregiverPortal_Details'),
  CaregiverPortal_Details_Write: (user: User) => userFunctions(user).includes('CaregiverPortal_Details_Write'),
  CareNetwork: (user: User) => userFunctions(user).includes('CareNetwork'),
  CareNetwork_Caregiver: (user: User) => userFunctions(user).includes('CareNetwork_Caregiver'),
  CareNetwork_Caregiver_Edit: (user: User) => userFunctions(user).includes('CareNetwork_Caregiver_Edit'),
  CareNetwork_NetworkMembers: (user: User) => userFunctions(user).includes('CareNetwork_NetworkMembers'),
  CareNetwork_NetworkMembers_Edit: (user: User) => userFunctions(user).includes('CareNetwork_NetworkMembers_Edit'),
  CareNetwork_Documentation: (user: User) => userFunctions(user).includes('CareNetwork_Documentation'),
  CareNetwork_Documentation_Edit: (user: User) => userFunctions(user).includes('CareNetwork_Documentation_Edit'),
  CareNetwork_IncomeRequest: (user: User) => userFunctions(user).includes('CareNetwork_IncomeRequest'),
  CareNetwork_IncomeRequest_Edit: (user: User) => userFunctions(user).includes('CareNetwork_IncomeRequest_Edit'),
  CareNetwork_SensitiveDetails: (user: User) => userFunctions(user).includes('CareNetwork_SensitiveDetails'),
  CareNetwork_SensitiveDetails_Edit: (user: User) => userFunctions(user).includes('CareNetwork_SensitiveDetails_Edit'),
  CareNetwork_Enrollment: (user: User) => userFunctions(user).includes('CareNetwork_Enrollment'),
  CareNetwork_Enrollment_Edit: (user: User) => userFunctions(user).includes('CareNetwork_Enrollment_Edit'),
  CareNetwork_Communication: (user: User) => userFunctions(user).includes('CareNetwork_Communication'),
  CareNetwork_Communication_Edit: (user: User) => userFunctions(user).includes('CareNetwork_Communication_Edit'),
  Enrollment: (user: User) => userFunctions(user).includes('Enrollment'),
  Enrollment_Edit: (user: User) => userFunctions(user).includes('Enrollment_Edit'),
  Enrollment_Documentation: (user: User) => userFunctions(user).includes('Enrollment_Documentation'),
  Enrollment_Communication: (user: User) => userFunctions(user).includes('Enrollment_Communication'),
  Enrollment_Documentation_Edit: (user: User) => userFunctions(user).includes('Enrollment_Documentation_Edit'),
  Enrollment_Communication_Edit: (user: User) => userFunctions(user).includes('Enrollment_Communication_Edit'),
  Reports: (user: User) => userFunctions(user).includes('Reports'),
  Users: (user: User) => userFunctions(user).includes('Users'),
  Users_Edit: (user: User) => userFunctions(user).includes('Users_Edit'),
  Impersonation: (user: User) => userFunctions(user).includes('Impersonation'),
  ServiceProviders: (user: User) => userFunctions(user).includes('ServiceProviders'),
  ServiceProviders_Edit: (user: User) => userFunctions(user).includes('ServiceProviders_Edit'),
  ISDs: (user: User) => userFunctions(user).includes('ISDs'),
  ISDs_Edit: (user: User) => userFunctions(user).includes('ISDs_Edit'),
  ISDs_Approvals: (user: User) => userFunctions(user).includes('ISDs_Approvals'),
  ISDs_Approvals_Edit: (user: User) => userFunctions(user).includes('ISDs_Approvals_Edit'),
  MiECCAdmin: (user: User) => userFunctions(user).includes('MiECCAdmin'),
  CareNetwork_MergeRecords: (user: User) => userFunctions(user).includes('CareNetwork_MergeRecords'),
  WaiverApprovals: (user: User) => userFunctions(user).includes('WaiverApprovals')
}
