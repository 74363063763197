import { Box, Chip, Grid, Typography } from '@mui/material'
import { RadiusCard } from '../Cards/Radius/RadiusCard'
import S3Image from '../S3Image'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

export interface ServiceSummaryCardProps {
  serviceName: string
  programName: string | null
  serviceLogo: string | null
  serviceStatus: string
  serviceProviderName: string
  serviceProviderLogo: string | null
  referralTimeFrame: string
  buildingName: string
  blockName: string
  sessionName: string
}

export function ServiceSummaryCard (props: ServiceSummaryCardProps): JSX.Element {
  const iconColor = '#4DA4A4 !important'
  const serviceLogo = props.serviceLogo !== null && props.serviceLogo !== undefined ? <S3Image imageKey={props.serviceLogo} /> : <></>
  return (<>
    <Typography variant='h6' fontWeight={'bold'}>Service Details</Typography>
    <Grid container width={'100%'} >
      <Grid item alignItems={'left'} width={'50%'}>
        <Box sx={{ width: '80%' }}><RadiusCard marginTop='0px' icon={serviceLogo} header={props.serviceName} content={<></>} /></Box>
      </Grid>
      <Grid item width={'50%'} alignItems={'right'}>
        <Box display={'-webkit-inline-box'}><Typography fontWeight={'bold'} >{props.serviceProviderName}</Typography> <Chip icon={<FiberManualRecordIcon sx={{ color: iconColor }} />} label={props.serviceStatus} size="small"
          sx={{ color: iconColor, backgroundColor: 'rgba(77, 164, 164, 0.1)' }}
        /></Box>
        <Box>
          {props.buildingName}
        </Box>

        <Box display={'-webkit-inline-box'}>
          {props.blockName}
          <Chip
            icon={<FiberManualRecordIcon sx={{ color: iconColor }} />}
            label={props.programName ?? 'Missing Program'}
            size="small"
            sx={{ color: iconColor, backgroundColor: 'rgba(77, 164, 164, 0.1)' }}
          />
        </Box>
        <Box>
          {props.sessionName}
        </Box>
        {props.serviceProviderLogo !== null && props.serviceProviderLogo !== undefined && (
          <Box>
            <S3Image imageKey={props.serviceProviderLogo} />
          </Box>
        )}
      </Grid>
    </Grid>
  </>
  )
}
