import { Box, Button, Chip, CircularProgress, Grid } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { sendGet, sendPost, sendPut } from '../../hooks/use-fetch'
import { type program } from '../../core/types'
import { ProgramDetails } from './ProgramDetails'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { TextFieldWithLabel } from '../../Components/TextField'

const nameColumn = new Column('name', 'Name')
const inactiveColumn = new Column('inactive', 'In Use?', ColumnType.CUSTOM)
const fundingSourceColumn = new Column('fundingSourceID', 'Funding Source', ColumnType.CUSTOM)
const requiresIncomeCalcColumn = new Column('requiresIncomeCalculation', 'Requires Income Calculation', ColumnType.CUSTOM)
requiresIncomeCalcColumn.customCellGenerator = row => {
  const required: boolean = row.requiresIncomeCalculation
  let color = '#4DA4A4'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  if (!required) {
    color = '#D4316C'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ '&&': { color } }} />} label={required ? 'Required' : 'Not Required'}
    size="small" sx={{
      '&&': {
        color,
        backgroundColor
      }
    }}
  />
}

const columns = [nameColumn, inactiveColumn, fundingSourceColumn, requiresIncomeCalcColumn]
inactiveColumn.customCellGenerator = row => {
  let color = '#4DA4A4'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  if (row?.inactive as boolean) {
    color = '#D4316C'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ '&&': { color } }} />} label={row?.inactive as boolean ? 'Inactive' : 'Active'}
    size="small" sx={{
      '&&': {
        color,
        backgroundColor

      }
    }}
  />
}

export function SystemProgramsList (): JSX.Element {
  const [fundingSources, setFundingSources] = useState<Array<{ id: number, name: string }>>([])
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [systemPrograms, setSystemPrograms] = useState<program[]>([])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/SystemProgramMaintenance/GetAll', {})
      if (error[0] === '' && rsp !== null) {
        setSystemPrograms(rsp)
      }
    }
    void loadData()
  }, [refreshTime])

  const paginationProps: PaginationProps<RowData> = {
    rows: systemPrograms
  }

  const pagination = usePagination(paginationProps)
  const initialProgram: program = { id: 0, fundingSourceID: 0, inactive: false, name: '', stateCode: '', description: '', requiresIncomeCalculation: false }
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [selectedProgram, setSelectedProgram] = useState<program>(initialProgram)
  const [open, setOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState(null)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/FundingSource/GetLookup', {})
      if (error[0] === '' && rsp !== null) {
        setFundingSources(rsp)
      }
      setLoading(false)
    }
    void loadData()
  }, [])

  useEffect(() => {
    const fieldArray: SearchCriteria[] = []
    if (searchValue !== null) {
      fieldArray.push({
        field: 'name',
        value: searchValue
      })
    }
    pagination.setSearchFields(fieldArray)
  }, [searchValue])

  fundingSourceColumn.customCellGenerator = row => {
    let color = 'yellow'
    const title = fundingSources[row.fundingSourceID - 1]?.name
    switch (row.fundingSourceID) {
      case 1:
        color = 'purple'
        break
      case 2:
        color = 'red'
        break
      case 3:
        color = 'green'
        break
      case 4:
        color = 'blue'
        break
      default:
        color = 'black'
    }

    return <Chip icon={<FiberManualRecordIcon sx={{ '&&': { color } }} />} label={title}
      size='small' sx={{
        '&&': {
          color,
          backgroundColor: 'transparent'
        }
      }}
    />
  }

  const addClick = (): void => {
    setSelectedProgram(initialProgram)
    setOpen(true)
    setIsNew(true)
  }
  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const selectedProgram: program = {
      id: row.id,
      fundingSourceID: row.fundingSourceID,
      inactive: false,
      name: row.name,
      stateCode: row.stateCode,
      description: row.description,
      requiresIncomeCalculation: row.requiresIncomeCalculation ?? false
    }
    setSelectedProgram(selectedProgram)
    setOpen(true)
    setIsNew(false)
  }

  const handleSubmit = async (programData: program): Promise<void> => {
    if (!loading) {
      setLoading(true)

      setErrors(undefined)
      let err: string[] | null = null
      let suc = false
      const url = `/SystemProgramMaintenance/${isNew ? 'Insert' : 'Update'}`

      if (isNew) {
        const { error, success } = await sendPost(url, programData)

        err = error
        suc = success
      } else {
        const { error, success } = await sendPut(url, programData)
        err = error
        suc = success
      }
      let errors: string = ''
      if (suc) {
        setOpen(false)
        setRefreshTime(new Date())
      } else {
        errors = err?.toString() ?? ''
        setErrors([errors])
      }
      setLoading(false)
    }
  }

  if (loading) return <CircularProgress />
  return <Box>
    <Grid container columns={2} display='flex' width='100%'>
      <Grid item>
        <TextFieldWithLabel
          label='Name'
          onChange={(e) => { setSearchValue(e.target.value) }}
          name='name'
          maxWidth='200px'
          value={searchValue}
          data-test-name-search
        />
      </Grid>
      <Grid item marginLeft='auto'>
        <Button
          data-test-add-program
          color='secondary'
          variant='contained'
          onClick={addClick}
        >Add Program</Button>
      </Grid>
    </Grid>

    <DataTable
      page={pagination.page}

      name='programTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows ?? []}
      totalRecords={pagination.recordCount}
      loading={false}
      onSortChange={(col, sorts) => {
        pagination.onSortChange(col, sorts[0].order)
      }}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      onRowClick={rowClick}
    />
    {open && (
      <ProgramDetails
        onClose={() => { setOpen(false) }}
        onSubmit={handleSubmit}
        open={open}
        program={selectedProgram}
        errors={errors}

      />

    )}
  </Box>
}
