import { Box } from '@mui/material'
import { TextFieldWithLabel } from '../TextField'

interface addUserProps {
  userData: { firstName: string, lastName: string, email: string }
  handleChange: (data: { firstName: string, lastName: string, email: string }) => void
}

export function AddUserTemplate (props: addUserProps): JSX.Element {
  const handleChange = (e: any): void => {
    const newData = { ...props.userData, [e.target.name]: e.target.value }

    props.handleChange(newData)
  }
  return (
    <Box component={'div'} sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      <Box component={'div'} sx={{ width: '50%' }}>
        <TextFieldWithLabel
          label='First Name'
          name='firstName'
          value={props.userData.firstName}
          onChange={handleChange}
          dataTestId='firstName'
        />
      </Box>
      <Box component={'div'} sx={{ width: '50%' }}>
        <TextFieldWithLabel
          label='Last Name'
          name='lastName'
          value={props.userData.lastName}
          onChange={handleChange}
          dataTestId='lastName'
        />
      </Box>
      <Box component={'div'} sx={{ width: '50%' }}>
        <TextFieldWithLabel
          label='Email'
          name='email'
          value={props.userData.email}
          onChange={handleChange}
          dataTestId='email'
        />
      </Box>
    </Box>
  )
}
