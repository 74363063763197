import { Box } from '@mui/material'
import DragDropFiles from '../DragAndDrop'
import Cropper, { type Area } from 'react-easy-crop'
import { useState, useEffect } from 'react'
import getCroppedImg from './croppedImage'

interface ImageCropperProps {
  setCroppedImage: (img: string | null) => void
}
export function ImageCropper (props: ImageCropperProps): JSX.Element {
  const [preview, setPreview] = useState<string>()
  const [files, setFiles] = useState<FileList | null>(null)

  useEffect(() => {
    // create the preview
    let objectUrl = ''
    if (files !== undefined && files !== null && files.length > 0) {
      objectUrl = URL.createObjectURL(files[0])
      setPreview(objectUrl)
    } else { setPreview(undefined) }

    // free memory when ever this component is unmounted
    return () => { URL.revokeObjectURL(objectUrl) }
  }, [files])
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const onCropComplete = async (croppedArea: Area, cap: Area): Promise<void> => {
    try {
      const crpImage = await getCroppedImg(
        preview ?? 'none',
        cap,
        0
      )
      props.setCroppedImage(crpImage)
    } catch (e) {
    }
  }

  return <>
    {preview == null && (

      <DragDropFiles files={files} setFiles={setFiles} instructions='+ Add Image' fileType='image/*' removable={false} />
    )}
    {preview != null && (
      <Box sx={{ position: 'relative', height: '500px' }}>
        <Cropper
          image={preview ?? 'none'}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
    )}

  </>
}
