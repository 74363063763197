import { Box, Typography } from '@mui/material'
import { RadiusCard } from '../Radius/RadiusCard'
import { ChildEdit } from '../../../Pages/CareNetworkManagement/CareNetworkDetail/ChildEdit'
import { type ChildEditValues, type ChildSiblings } from '../../../core/types'

interface SiblingCardDisplayProps {
  index: number
  edit: boolean
  sibling: ChildSiblings
  originalChildName: string
  onSubmit: (index: number, child: ChildEditValues) => void
  setEdit: (index: number, edit: boolean) => void
  onDelete: (index: number) => void
}

export function SiblingCardDisplay (props: SiblingCardDisplayProps): JSX.Element {
  const handleClose = (): void => {
    props.setEdit(props.index, false)
  }

  const onEdit = (): void => {
    props.setEdit(props.index, true)
  }

  const fullName = `${props.sibling.isUnborn ? '(unborn)' : props.sibling.firstName} ${props.sibling.lastName}`
  const originalChild = props.originalChildName

  const content = <Box>
    <Typography variant='body1' fontWeight={'bold'}>{fullName}</Typography>
    {props.sibling.isLivingWithStudent
      ? `Lives with ${originalChild}`
      : `Does not live with ${originalChild}`}</Box>

  const handleSubmit = (child: ChildEditValues): void => {
    props.onSubmit(props.index, child)
  }

  return <Box data-testid="sibling-card">
    <RadiusCard
      onViewMenu={() => {}}
      showViewProfile={false}
      onEditMenu={onEdit}
      showMenu={true}
      header={''}
      content={content}
      fullWidth={true}
      onModalConfirm={() => { props.onDelete(props.index) }}
    />
    <ChildEdit
      isSibling={true}
      isUnborn={false}
      child={props.sibling}
      onSubmit={handleSubmit}
      open={props.edit}
      errors={[]}
      onClose={handleClose}
      siblingName={originalChild}
    />
  </Box>
}
