import { Button, Grid, Breadcrumbs, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { AgencyStatsOptions } from './AgencyStatsOptions'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { AccountType, type ActiveAccount } from '../../core/types'

export function ReportList (): JSX.Element {
  const [caption, setCaption] = useState<string>('Reports')
  const [showList, setShowList] = useState(true)
  const [showAgencyStatsOptions, setShowAgencyStatsOptions] = useState(false)
  const accountId: ActiveAccount | undefined = useAccountId()
  const isdUser: boolean = (accountId !== undefined && accountId.type === AccountType.ISD)

  const agencyStatsReport = async (): Promise<void> => {
    setShowList(false)
    setShowAgencyStatsOptions(true)
    setCaption('Agency Stats Report')
  }

  const backToList = (): void => {
    setShowList(true)
    setShowAgencyStatsOptions(false)
    setCaption('Reports')
  }

  return <>
    <Typography variant="h3">{caption}</Typography>
    {!showList && <Breadcrumbs maxItems={1} aria-label="breadcrumb">
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={backToList} >
        Return
      </Link>
    </Breadcrumbs>
    }
    {showList && <>
      {isdUser && <Grid container spacing={2} className='mt-10'>
        <Grid item>
          <Button color='secondary' variant='contained' onClick={agencyStatsReport}>Agency Stats</Button>
        </Grid>
      </Grid>
      }
    </>
    }
    {showAgencyStatsOptions && <AgencyStatsOptions />}
  </>
}
