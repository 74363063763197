import { type DialogProps, Slide } from '@mui/material'
import { type TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'
import { Modal } from '.'

export type BannerModalProps = DialogProps & {
  content: JSX.Element
  onClose: () => void
  onConfirm: () => void
  title: string
  confirmButtonText?: string
  confirmButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  cancelButtonText?: string
  hideXtoClose?: boolean
  hideCancelButton?: boolean
  isUpdateDisabled?: boolean
  className?: string
  buttonClassName?: string
  dataTestId?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  noButtons?: boolean
}

const Transition = forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" {...props} />
})

export const BannerModal = (props: BannerModalProps): JSX.Element => {
  return (
    <Modal
      maxWidth={ props.maxWidth ?? 'sm'}
      className={'Nested ' + (props.className ?? '')}
      title={props.title}
      sx={{
        position: 'absolute',
        bottom: 0
      }}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      open={props.open}
      hideXtoClose={props.hideXtoClose}
      TransitionComponent={Transition}
      confirmationContent={props.content}
      confirmButtonText={props.confirmButtonText}
      confirmButtonColor={props.confirmButtonColor}
      buttonClassName={props.buttonClassName}
      cancelButtonText={props.cancelButtonText}
      isUpdateDisabled={props.isUpdateDisabled}
      data-testid={props.dataTestId}
      noButtons={props.noButtons}
      hideCancelButton={props.hideCancelButton}
    />
  )
}
