
interface jpgProps {
  fill?: string
}

export function PersonIcon (props: jpgProps): JSX.Element {
  const fill = props.fill ?? 'black'
  return <>
    <svg width="100" height="100" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M100 100C118.417 100 133.333 85.0835 133.333 66.6668C133.333 48.2502 118.417 33.3335 100 33.3335C81.5834 33.3335 66.6667 48.2502 66.6667 66.6668C66.6667 85.0835 81.5834 100 100 100ZM100 116.667C77.75 116.667 33.3334 127.833 33.3334 150V166.667H166.667V150C166.667 127.833 122.25 116.667 100 116.667Z"/>
    </svg>
  </>
}
