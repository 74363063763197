import { useState, useRef, Fragment } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { type ChildSiblings, type ChildCaregiver, type AccessControlModel, CaregiverAccessType, SecurityLevel } from '../../core/types'
import { OnOffSwitch } from '../Switch/OnOffSwitch'
import { CaregiverDetails } from './Index'
import { SiblingDetails } from '../SiblingDetails/Index'
import { LargeCard } from '../Cards/Large'
import { CaregiverAddEdit } from '../../Pages/CareNetworkManagement/CareNetworkDetail/CaregiverAddEdit'
import { SiblingAdd } from '../../Pages/CareNetworkManagement/CareNetworkDetail/ChildSiblingsAdd'

export interface NetworkMembersDisplayProps {
  childID: number
  childName: string
  childCaregivers: ChildCaregiver[]
  childSiblings: ChildSiblings[]
  captureIEP: boolean
  captureIFSP: boolean
  captureEOIS: boolean
  canViewEligibilityFactors: boolean
  setChildCaregivers: (caregivers: ChildCaregiver[]) => void
  canAddMembers: boolean
  onAddCaregiver: (caregiver: ChildCaregiver, match: boolean) => Promise<boolean>
  onSiblingSave: (sibling: ChildSiblings) => Promise<boolean>
  onDeleteSibling: (id: number) => Promise<void>
  saveAccessControl: (access: AccessControlModel) => void
  siblingErrors: string[] | undefined
  onCaregiverDelete: (id: number) => Promise<void>
  onCaregiverEdit: (id: number) => Promise<ChildCaregiver | undefined>
}

export function NetworkMembersDisplay (props: NetworkMembersDisplayProps): JSX.Element {
  const [editingSiblingId, setEditingSiblingId] = useState<number | null>(null)
  const [selectedCaregiver, setSelectedCaregiver] = useState<ChildCaregiver | null>(null)
  const addUnbornSibling = useRef(false)

  const initialCaregiver: ChildCaregiver = {
    childId: props.childID,
    createAccount: false,
    preferredContactMethod: null,
    accessType: '',
    dateOfBirth: null,
    educationLevelID: null,
    email: '',
    firstName: '',
    id: 0,
    isLivingWithStudent: false,
    isFinancialResponsible: false,
    languageID: null,
    lastName: '',
    relationshipID: null,
    relationshipName: '',
    childSiblings: [],
    phone: null,
    address: null,
    city: null,
    state: null,
    apartment: null,
    zipCode: null,
    newChildSiblings: [],
    securityLevel: SecurityLevel.NONE
  }

  const EnabledCaregiverTypes = [CaregiverAccessType.CAREGIVER.toString(), CaregiverAccessType.PRIMARYCAREGIVER.toString()]

  const handleCaregiverEdit = async (caregiver: ChildCaregiver): Promise<void> => {
    const cg: ChildCaregiver = await props.onCaregiverEdit(caregiver.id) ?? caregiver
    setSelectedCaregiver(cg)
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, caregiverId: number, securityLevel: SecurityLevel): void => {
    const accessControl: AccessControlModel = { childId: props.childID, caregiverId, securityLevel: checked ? securityLevel : SecurityLevel.NONE }
    const index = props.childCaregivers.findIndex(c => c.id === caregiverId)
    const caregiverList: ChildCaregiver[] = props.childCaregivers.slice()
    caregiverList[index].securityLevel = checked ? securityLevel : SecurityLevel.NONE
    props.saveAccessControl(accessControl)
    props.setChildCaregivers(caregiverList)
  }

  const handleSiblingAdd = (unborn: boolean): void => {
    addUnbornSibling.current = unborn
    setEditingSiblingId(0)
  }

  const handleSiblingEdit = (siblingId: number): void => {
    setEditingSiblingId(siblingId)
  }

  const handleSiblingDelete = async (siblingId: number): Promise<void> => {
    await props.onDeleteSibling(siblingId)
    setEditingSiblingId(null)
  }

  const handleCaregiverSave = async (caregiverData: ChildCaregiver, match: boolean): Promise<void> => {
    const success = await props.onAddCaregiver(caregiverData, match)
    if (success) {
      setSelectedCaregiver(null)
    }
  }

  const onSiblingSubmit = async (siblingData: ChildSiblings): Promise<void> => {
    const success = await props.onSiblingSave(siblingData)
    if (success) { setEditingSiblingId(null) }
  }

  return <>
    <Box sx={{ width: '100%;' }}>
      <Grid container columns={12} spacing={1}>
        <Grid item xs={6} >
          <LargeCard className='Borderless' header={'Caregivers'} content={
            <>
              {props.childCaregivers?.map((caregiver: ChildCaregiver) => {
                return (
                  <CaregiverDetails
                    key={caregiver.id}
                    data={caregiver}
                    studentName={props.childName}
                    onCaregiverEdit={async () => { await handleCaregiverEdit(caregiver) }}
                    onCaregiverDelete={props.onCaregiverDelete}
                  />
                )
              })}
              {props.canAddMembers && < Box className='d-flex f-justify-content-center'>
                <Button
                  data-testid="addcaregiver"
                  variant='text'
                  sx={{ color: '#595959', textTransform: 'none' }}
                  onClick={async () => { await handleCaregiverEdit(initialCaregiver) }}
                >Add Another Caregiver +</Button>
              </Box>}
            </>

          } fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <LargeCard header={'Caregiver Portal Access Control'} content={
            <>
              <Box>
                <Grid container columns = {12} alignItems={'center'}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}><Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>
                    View</Typography></Grid>
                  <Grid item xs={4}><Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>
                    Modify</Typography></Grid>
                  { props.childCaregivers?.map((caregiver: ChildCaregiver) => {
                    return <Fragment key={caregiver.id}>
                      <Grid key={`${caregiver.id}Name`} item xs={4}>{caregiver.firstName} {caregiver.lastName}</Grid>
                      <Grid key={`${caregiver.id}View`} item xs={4} sx={{ display: 'block' }}>
                        <OnOffSwitch
                          data-testid={`view-${caregiver.firstName}`}
                          checked={caregiver.securityLevel === SecurityLevel.MODIFY || caregiver.securityLevel === SecurityLevel.VIEW}
                          onChange={(e: any, checked: boolean) => { onChange(e, checked, caregiver.id, SecurityLevel.VIEW) } }
                          disabled={!EnabledCaregiverTypes.includes(caregiver.accessType)}
                        />
                      </Grid>
                      <Grid key={`${caregiver.id}Modify`} item xs={4} sx={{ display: 'block' }}>
                        <OnOffSwitch
                          data-testid={`edit-${caregiver.firstName}`}
                          checked={caregiver.securityLevel === SecurityLevel.MODIFY}
                          onChange={(e: any, checked: boolean) => { onChange(e, checked, caregiver.id, SecurityLevel.MODIFY) } }
                          disabled={!EnabledCaregiverTypes.includes(caregiver.accessType)}
                        />
                      </Grid>
                    </Fragment>
                  })}
                </Grid>
              </Box>

            </>} className='Borderless' fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <LargeCard header={'Siblings'} className='Borderless' content={
            <>
              {props.childSiblings?.map((child: ChildSiblings) => (
                <SiblingDetails
                  key={child.id}
                  studentName={props.childName}
                  firstName={child.firstName}
                  lastName={child.lastName}
                  relationshipName={child.relationshipName}
                  id={child.id}
                  isLivingWithStudent={child.isLivingWithStudent}
                  isUnborn={child.isUnborn}
                  onEdit={handleSiblingEdit}
                  onDelete={handleSiblingDelete}
                />
              ))}
              {props.canAddMembers && <Box className='d-flex f-justify-content-center'>
                <Button
                  data-testid="addsibling"
                  variant='text'
                  sx={{ color: '#595959', textTransform: 'none' }}
                  onClick={() => { handleSiblingAdd(false) }}
                >Add Sibling +</Button>
                <Button
                  data-testid="addunborn"
                  variant='text'
                  sx={{ color: '#595959', textTransform: 'none' }}
                  onClick={() => { handleSiblingAdd(true) }}
                >Add Unborn Sibling +</Button>
              </Box> }
            </>
          } fullWidth
          />
        </Grid>
      </Grid>
    </Box>

    {selectedCaregiver != null && <CaregiverAddEdit
      childID={props.childID}
      caregiverSearchEndpoint='/CareGiverSearch/Search'
      onClose={() => { setSelectedCaregiver(null) }}
      open={selectedCaregiver != null}
      onSubmit={handleCaregiverSave}
      isLoadedRecord={(selectedCaregiver?.id ?? 0) > 0}
      caregiver={selectedCaregiver}
      childName={props.childName}
    />
    }
    {editingSiblingId != null && <SiblingAdd
      onClose={() => { setEditingSiblingId(null) }}
      open={ true }
      onSubmit={onSiblingSubmit}
      errors={props.siblingErrors}
      childId={editingSiblingId ?? 0}
      canViewEligibilityFactors={props.canViewEligibilityFactors}
      childName={props.childName}
      captureIEP={props.captureIEP}
      captureIFSP={props.captureIFSP}
      captureEOIS={props.captureEOIS}
      siblingId={props.childID}
      addUnborn={addUnbornSibling.current}
    />
    }
  </>
}
