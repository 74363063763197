import { Grid } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import DraftsIcon from '@mui/icons-material/Drafts'
import { type notificationEvent } from '../../Pages/CareNetworkManagement/CareNetworkDetail/ChildHistoryDisplay'
import RadioAccordion from '../Accordian/RadioAccordion'

interface NotificationProps {
  event: notificationEvent
}
export function NotificationTemplate (props: NotificationProps): JSX.Element {
  const icon = props.event.viewed ? <DraftsIcon /> : <EmailIcon />
  const date = new Date(props.event.eventDate)
  const fontWeight = props.event.viewed ? 'normal' : 'bold'

  const body = <Grid container columns={1} sx={{ mb: 1 }}>
    <Grid item xs={1}>
      {props.event.childName}
    </Grid>
    <Grid item xs={1}>
      {date.toLocaleString()}
    </Grid>
    <Grid item xs={1}>
      {props.event.userName}
    </Grid>
    <Grid item xs={1}>
      <div dangerouslySetInnerHTML={{ __html: props.event.description }}/>
    </Grid>

  </Grid>

  return (

    <RadioAccordion
      key={props.event.id}
      titleFontWeight={fontWeight}
      radioValue={props.event.id.toString()}
      body={body}
      className='historyAccordion'
      icon={icon}
      title={`${props.event.message} - ${date.toLocaleString()}`}
      width='100%'
      sx={{
        borderColor: 'lightGray',
        mb: '25px',
        ':first-of-type': {
          borderRadius: '32px'
        },
        ':last-of-type': {
          borderRadius: '32px'
        },
        '&.Mui-expanded:last-of-type': {
          mb: '25px'
        }
      }}
    />
  )
}
