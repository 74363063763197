import { Box, Grid } from '@mui/material'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { type PortalLocation } from '../../../core/types'
import { MapStateName } from '../../../Components/Cards/LocationCard/mapStateName'
import { MapModule, useBingMaps } from '../../../hooks/use-bingMaps'
import { CustomLabelWithToolTip } from '../../../Components/CustomLabel/Index'

export interface ValidationState {
  address: { isValid: boolean, isDirty: boolean }
  city: { isValid: boolean, isDirty: boolean }
  state: { isValid: boolean, isDirty: boolean }
  zipCode: { isValid: boolean, isDirty: boolean }
}

interface LocationDetailsProps {
  location: PortalLocation
  validationState: ValidationState
  allFieldsOptional?: boolean
  childLocation: boolean
  isdId?: string | number
  onLocationChange: (location: PortalLocation) => void
  handleLocationFieldChange: (e: any) => void
  validateField: (e: any) => void
}

export function LocationDetails (props: LocationDetailsProps): JSX.Element {
  const location = props.location
  const validationState = props.validationState
  const allFieldsOptional = props.allFieldsOptional ?? false

  const handleLocationBlur = (e: any): void => {
    if (e.target.name !== 'apartment') {
      props.validateField(e)
    }
    try {
      validateLocation(location)
    } catch {}
  }

  const handlePlaceSelected = (place: Microsoft.Maps.ISuggestionResult): void => {
    const tempLocation: PortalLocation = {
      address: place.address.addressLine,
      city: place.address.locality,
      apartment: '',
      state: MapStateName(place.address.adminDistrict),
      zipCode: place.address.postalCode
    }
    props.onLocationChange(tempLocation)
  }

  const { validateLocation } = useBingMaps({
    modules: [MapModule.AutoSuggest],
    selectFunction: handlePlaceSelected,
    inputID: 'address'
  })

  const handleLocationFieldChange = (e: any): void => {
    props.validateField(e)
    props.handleLocationFieldChange(e)
  }

  const streetAddressLabel = props.childLocation
    ? <CustomLabelWithToolTip
        name='IF_Child_Address'
        isdId={props.isdId ?? 0}
        defaultLabelText={'Child\'s Home Address'}
    />
    : <CustomLabelWithToolTip
        name='IF_Caregiver_Address'
        isdId={props.isdId ?? 0}
        defaultLabelText={'Caregiver\'s Home Address'}
    />

  return <>
    <Grid item xs={12}>
      <TextFieldWithLabel
        ariaRole='combobox'
        ariaExpanded={false}
        ariaControls='as_containerSearch_address'
        name='address'
        autoComplete={'off'}
        data-testid='address'
        value={location.address}
        showRequiredText={allFieldsOptional || (validationState.address.isDirty && !validationState.address.isValid)}
        required={!allFieldsOptional && validationState.address.isDirty && !validationState.address.isValid}
        error={!allFieldsOptional && validationState.address.isDirty && !validationState.address.isValid}
        onBlur={handleLocationBlur}
        onChange={handleLocationFieldChange}
        className='pt-0'
        textFieldClassName='pb-0'
        labelContent={streetAddressLabel}
        maxLength={50}
      />
      <Box id='searchBoxContainer'></Box>
    </Grid>
    <Grid item xs={12}>
      <TextFieldWithLabel
        label='Apartment/Suite #'
        name='apartment'
        data-testid='apartment'
        value={location.apartment}
        showRequiredText={true}
        required={false}
        onBlur={handleLocationBlur}
        onChange={handleLocationFieldChange}
        className='pt-0'
        textFieldClassName='pb-0'
        maxLength={50}
      />
    </Grid>
    <Grid item xs={12}>
      <TextFieldWithLabel
        label='City'
        name='city'
        data-testid='city'
        value={location.city}
        showRequiredText={allFieldsOptional || (validationState.city.isDirty && !validationState.city.isValid)}
        required={!allFieldsOptional && validationState.city.isDirty && !validationState.city.isValid}
        error={!allFieldsOptional && validationState.city.isDirty && !validationState.city.isValid}
        onBlur={handleLocationBlur}
        onChange={handleLocationFieldChange}
        className='pt-0'
        textFieldClassName='pb-0'
        maxLength={50}
      />
    </Grid>
    <Grid item xs={6}>
      <TextFieldWithLabel
        label='State'
        name='state'
        data-testid='state'
        value={location.state}
        showRequiredText={allFieldsOptional || (validationState.state.isDirty && !validationState.state.isValid)}
        required={!allFieldsOptional && validationState.state.isDirty && !validationState.state.isValid}
        error={!allFieldsOptional && validationState.state.isDirty && !validationState.state.isValid}
        onBlur={handleLocationBlur}
        onChange={handleLocationFieldChange}
        className='pt-0'
        textFieldClassName='pb-0'
        maxLength={2}
      />
    </Grid>
    <Grid item xs={6}>
      <TextFieldWithLabel
        label='ZIP'
        name='zipCode'
        data-testid='zipCode'
        value={location.zipCode}
        showRequiredText={allFieldsOptional || (validationState.zipCode.isDirty && !validationState.zipCode.isValid)}
        required={!allFieldsOptional && validationState.zipCode.isDirty && !validationState.zipCode.isValid}
        error={!allFieldsOptional && validationState.zipCode.isDirty && !validationState.zipCode.isValid}
        onBlur={handleLocationBlur}
        onChange={handleLocationFieldChange}
        className='pt-0'
        textFieldClassName='pb-0'
        maxLength={5}
      />
    </Grid>
  </>
}
