import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { LargeCard } from '../../../Components/Cards/Large'
import FamilyIcon from '@mui/icons-material/FamilyRestroom'
import ParentIcon from '@mui/icons-material/Woman'
import StudentsPng from '../../../Assets/Images/students.png'
import { useAuth } from '../../../hooks/use-auth'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'
import { deleteSession, initialEnrollmentDetails, saveSession, useCaregiverWizardContext, useCaregiverWizardUpdateDispatch } from '../../../ContextProviders/CaregiverWizard'
import { Modal } from '../../../Components/Modal'
import { sendGet } from '../../../hooks/use-fetch'
import { type ISDSettings } from '../../../core/types'
import '../CaregiverPortal.css'
import { HandshakeIcon } from '../../../Assets/SVGs/handshake'
import { CAREGIVER_ENROLLMENTS } from '../../Routes'
import { CustomLabelWithToolTip } from '../../../Components/CustomLabel/Index'

interface ISDSettingsResponse {
  response: ISDSettings
}

export function CaregiverPortalHomePage (): JSX.Element {
  const { isdId, isdServiceProviderId } = useParams()
  const { login, user } = useAuth()
  const nav = useNavigate()
  const dispatch = useCaregiverWizardUpdateDispatch()
  const caregiverForm = useCaregiverWizardContext()
  const [selectedCard, setSelectedCard] = useState('')
  const [isRestoreSessionModalOpen, setIsRestoreSessionModalOpen] = useState(false)
  const [isExternalModalOpen, setIsExternalModalOpen] = useState(false)
  const [adultServicesName, setAdultServicesName] = useState<string | undefined>()
  const [adultServicesLink, setAdultServicesLink] = useState<string | undefined>()
  const [showServicesForMyChild, setShowServicesForMyChild] = useState(false)
  const [showServicesForMyself, setShowServicesForMyself] = useState(false)
  const [showCreateReferral, setShowCreateReferral] = useState(false)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response }: ISDSettingsResponse = await sendGet(`/CaregiverPortal/GetISDSettings?id=${isdId ?? 0}`, {})

      if (response !== null) {
        setAdultServicesName(response.adultServicesName)
        setAdultServicesLink(response.adultServicesLink)
        setShowServicesForMyChild(response.showServicesForMyChild)
        setShowServicesForMyself(response.showServicesForMyself)
        setShowCreateReferral(response.showCreateReferral)
      }
    }

    void loadData()
  }, [])

  useEffect(() => {
    if (caregiverForm?.isNewSession === false) {
      setIsRestoreSessionModalOpen(true)
    }
  }, [caregiverForm])

  const handleUnbornClick = async (isCaregiverOfChild: boolean, unbornChild: boolean): Promise<void> => {
    const tempForm = initialEnrollmentDetails()
    if (isCaregiverOfChild) {
      tempForm.currentSubStep = 2
    }
    tempForm.isCaregiverOfChild = isCaregiverOfChild
    tempForm.unbornChild = unbornChild
    tempForm.isNewSession = false
    dispatch({ type: 'form', form: tempForm })
    await saveSession(tempForm)
    const enrollmentRoute = generatePath(CAREGIVER_ENROLLMENTS, { isdId: isdId ?? '', isdServiceProviderId: isdServiceProviderId ?? '' })
    nav(enrollmentRoute)
  }

  const restoreSession = (): void => {
    setIsRestoreSessionModalOpen(false)
    const enrollmentRoute = generatePath(CAREGIVER_ENROLLMENTS, { isdId: isdId ?? '', isdServiceProviderId: isdServiceProviderId ?? '' })
    nav(enrollmentRoute)
  }

  const handleCancel = (): void => {
    deleteSession()
    dispatch({ type: 'form', form: null })
    setIsRestoreSessionModalOpen(false)
  }

  return <>
    <img src={StudentsPng} alt='students' className='students-image' />
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' sx={{ textAlign: 'center', marginBottom: '30px' }}>How can we help you today?</Typography>
        </Grid>

        {!showServicesForMyChild && !showServicesForMyself && !showCreateReferral && <Grid item xs={12}>
          <Typography variant='h5' sx={{ textAlign: 'center' }}>
            This ISD does not currently have any services available. Please contact your ISD for a proper link.
          </Typography>
        </Grid>}

        {showServicesForMyChild && <>
          <Grid item xs={12}>
            <LargeCard
              fullWidth={true}
              data-testid='services-for-my-child-card'
              className={'services-option-card' + (selectedCard === 'my child' ? ' selected' : '')}
              onClick={() => { setSelectedCard('my child') }}
              content={
                <Box className='d-flex'>
                  <FamilyIcon className='card-icon' />
                  <Box sx={{ marginLeft: '10px', marginRight: '15px' }}>
                    <Typography variant="h5" className='card-subheader'>Services for My Child</Typography>
                    <Box sx={{ marginTop: '10px' }}>
                      <CustomLabelWithToolTip
                        name='IF_Home_Child_Services'
                        isdId={isdId ?? 0}
                        defaultLabelText='We will guide you through a process that will put you in touch with the services and programs that best meet the needs of your child (preschool, childcare, basic needs, home visiting, etc.).'
                      />
                    </Box>
                  </Box>
                </Box>
              }
            />
          </Grid>
          {selectedCard === 'my child' && <>
            <Grid item xs={12}>
              <Typography variant='h5'>Is this an unborn child?</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" className='wide-button' data-testid='handle-unborn-yes' onClick={async () => { await handleUnbornClick(true, true) }}>Yes</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" className='wide-button' data-testid='handle-unborn-no' onClick={async () => { await handleUnbornClick(true, false) }}>No</Button>
            </Grid>
          </>}
        </>}

        {showServicesForMyself && adultServicesLink != null && adultServicesLink !== '' && <Grid item xs={12}>
          <LargeCard
            fullWidth={true}
            data-testid='services-for-myself-card'
            className='services-option-card'
            onClick={() => { setIsExternalModalOpen(true) }}
            content={
              <Box className='d-flex'>
                <ParentIcon className='card-icon' />
                <Box sx={{ marginLeft: '10px', marginRight: '15px' }}>
                  <Typography variant="h5" className='card-subheader'>Services for Myself</Typography>
                  <Box sx={{ marginTop: '10px' }}>We will take you to some external resources.</Box>
                </Box>
              </Box>
            }
          />
        </Grid>}

        {showCreateReferral && <>
          <Grid item xs={12}>
            <LargeCard
              fullWidth={true}
              data-testid='create-referral-card'
              className={'services-option-card' + (selectedCard === 'other child' ? ' selected' : '')}
              onClick={() => { setSelectedCard('other child') }}
              content={
                <Box className='d-flex'>
                  <HandshakeIcon className='card-icon' fill='rgb(114, 42, 130)' />
                  <Box sx={{ marginLeft: '10px', marginRight: '15px' }}>
                    <Typography variant="h5" className='card-subheader'>Create a Referral</Typography>
                    <Box sx={{ marginTop: '10px' }}>
                      <CustomLabelWithToolTip
                        name='IF_Home_Create_Referral'
                        isdId={isdId ?? 0}
                        defaultLabelText='If you are a service provider who would like to create a referral on behalf of a family, click here to get started.'
                      />
                    </Box>
                  </Box>
                </Box>
              }
            />
          </Grid>
          {selectedCard === 'other child' && <>
            <Grid item xs={12}>
              <Typography variant='h5'>Is this an unborn child?</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" className='wide-button' data-testid='handle-unborn-yes' onClick={async () => { await handleUnbornClick(false, true) }}>Yes</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" className='wide-button' data-testid='handle-unborn-no' onClick={async () => { await handleUnbornClick(false, false) }}>No</Button>
            </Grid>
          </>}
        </>}

        {user == null && <Grid item xs={12}>
          <Typography variant='h5' sx={{ textAlign: 'center', marginTop: '30px' }}>Returning user?</Typography>
          <Button
            className='footer-button'
            onClick={login}
            variant="contained"
            autoCapitalize="false"
          >
            Login
          </Button>
        </Grid>}
      </Grid>
    </Container>

    <Modal
      onClose={() => { setIsRestoreSessionModalOpen(false) }}
      open={isRestoreSessionModalOpen}
      title={'Restore Session'}
      confirmationContent={<Box>Would you like to restore your previous session?</Box>}
      confirmButtonText={'Yes, Restore'}
      cancelButtonText='No, I will start over'
      maxWidth={'xs'}
      data-testid='session-restore-modal'
      onConfirm={restoreSession}
      onCancel={handleCancel}
    />

    <Modal
      onClose={() => { setIsExternalModalOpen(false) }}
      open={isExternalModalOpen}
      title={'External Redirect'}
      confirmationContent={<Box>This action will take you away from MiECC and to {adultServicesName}. Do you wish to continue?</Box>}
      confirmButtonText={'Yes, Continue'}
      cancelButtonText='No, Stay Here'
      maxWidth={'sm'}
      data-testid='external-services-modal'
      onConfirm={() => { window.location.href = adultServicesLink ?? '' }}
    />
  </>
}
