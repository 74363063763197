import { FormControl } from '@mui/material'
import { CheckListItem } from '../CheckList/CheckListItem'

interface Props {
  label?: string
  labelContent?: JSX.Element
  unclickableContent?: JSX.Element
  name: string
  disabled?: boolean
  value: boolean
  setValue: (newVal: boolean) => void
  fullWidth?: boolean
  flex?: boolean
  block?: boolean
  edge: 'end' | 'start'
  textClassName?: string
  buttonClassName?: string
  checkboxClassName?: string
  dataTestId?: string
}

export function CheckBoxWithLabel (props: Props): JSX.Element {
  return (
    <FormControl variant="filled" fullWidth={props.fullWidth} sx={{ mt: 1, display: (props.block ?? false) ? 'block' : 'flex' }}>
      <CheckListItem
        dataTestId={props.dataTestId}
        edge={props.edge}
        key={props.label}
        flex={props.flex}
        disabled={props.disabled}
        name={props.name}
        checked={props.value}
        onClick={() => { props.setValue(!props.value) }}
        text={props.label}
        labelContent={props.labelContent}
        unclickableContent={props.unclickableContent}
        textClassName={props.textClassName}
        buttonClassName={props.buttonClassName}
        checkboxClassName={props.checkboxClassName}
      />
    </FormControl>
  )
}
