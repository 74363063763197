import { Box } from '@mui/material'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { TextFieldWithLabel } from '../../Components/TextField'

export interface AccessRequestApprovalSearchCriteria {
  approvalStatus: string
  childName: string
  userName: string
}

export function AccessRequestSearchOptions (props: { current: AccessRequestApprovalSearchCriteria, onChange: (newSearch: AccessRequestApprovalSearchCriteria) => void }): JSX.Element {
  const statusOptions: Array<{ id: string, name: string }> = [
    { id: 'P', name: 'Pending' },
    { id: 'Y', name: 'Approved' },
    { id: 'N', name: 'Denied' }
  ]

  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }

  return (<Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
    <TextFieldWithLabel
      label='Requested By'
      onChange={handleChange}
      name='userName'
      maxWidth='205px'
      value={props.current.userName}
      data-test-name-search
    />
    <TextFieldWithLabel
      label='Child Name'
      onChange={handleChange}
      name='childName'
      maxWidth='205px'
      value={props.current.childName}
      data-test-name-search
    />
    <SelectWithLabel
      name='approvalStatus'
      label='Status'
      width='125px'
      value={props.current.approvalStatus}
      options={statusOptions}
      onChange={handleChange}
    />
  </Box>
  )
}
