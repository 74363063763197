import { Box, Button, Grid, Typography } from '@mui/material'
import { type PortalChildDetails, type EnrollmentDetails, type service } from '../../../../core/types'
import { LargeCard } from '../../../../Components/Cards/Large'
import { useEffect, useState } from 'react'
import S3Image from '../../../../Components/S3Image'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { toast } from 'react-toastify'
import { sendGet } from '../../../../hooks/use-fetch'
import { useParams } from 'react-router'
import { SelectableRow } from '../SelectableRow'
import { childAge, childName } from '../../PortalHelpers'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface ServiceSelectionProps {
  caregiverForm: EnrollmentDetails
  handleStep: (backwards: boolean) => void
}

export function ServiceSelection (props: ServiceSelectionProps): JSX.Element {
  const dispatch = useCaregiverWizardUpdateDispatch()
  const { isdId } = useParams()
  const [availableServices, setAvailableServices] = useState<service[]>([])
  const [selectedService, setSelectedService] = useState(0)
  const [selectedChildIndex, setSelectedChildIndex] = useState<number | null>(null)

  useScrollTop()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet(`/ISDServices/GetCurrentEnabledServices?ISDID=${isdId ?? 0}`, {})
      setAvailableServices(response)
    }

    const resetCurrentChild = async (): Promise<void> => {
      const initialForm = initialEnrollmentDetails()
      const tempForm = { ...props.caregiverForm, currentChild: initialForm.currentChild }
      await saveSession(tempForm)
      dispatch({ type: 'form', form: tempForm })
    }

    if (props.caregiverForm.currentStep === 3) {
      void resetCurrentChild()
      void fetchData()
    }
  }, [])

  const caregiverForm = props.caregiverForm
  const children = caregiverForm.children
  const hasAnyOtherServices = children.some(child => child.requestedServices.length > 0)

  const getIcon = (s3Key: string | null): any => {
    if (s3Key !== null) {
      return <S3Image
        imageKey={s3Key}
        alt='logo'
        className='card-icon'
      />
    } else { return <></> }
  }

  const unemptyServices = (): service[] => {
    return availableServices.filter(service => eligibleChildren(service.id).length > 0)
  }

  const eligibleChildren = (serviceId: number): PortalChildDetails[] => {
    return children.filter(child => !child.requestedServices.map(s => s.id).includes(serviceId))
  }

  const handleBack = async (): Promise<void> => {
    let step = 2
    let subStep = 2
    let backwards = true
    if (hasAnyOtherServices) {
      step = 4
      subStep = 3
      backwards = false
    }
    const tempForm = { ...caregiverForm, currentStep: step, currentSubStep: subStep }
    await saveSession(tempForm)
    props.handleStep(backwards)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentSubStep: 2 }

    if (selectedService === 0) {
      toast.error('Please select a service to continue')
      return
    }
    if (selectedChildIndex == null) {
      toast.error('Please select a child to continue')
      return
    }

    updateCurrentChild(selectedChildIndex, tempForm)
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
    props.handleStep(false)
  }

  const indexOfChild = (child: PortalChildDetails): number => {
    return children.findIndex(c => c === child)
  }

  const updateCurrentChild = (childIndex: number, tempForm: EnrollmentDetails): void => {
    const existingChild = tempForm.children[childIndex]
    existingChild.isCurrentlyUpdating = true
    // Deep copy the requested services array so that we only update the current child, not the one in children
    const tempChild = { ...existingChild, requestedServices: [...existingChild.requestedServices] }
    tempChild.requestedServices.push({
      id: selectedService,
      timeFrame: null,
      startDate: null,
      desiredSchedule: null,
      facilityIds: [],
      reachOut: false,
      isCurrentlyUpdating: true
    })
    tempForm.currentChild = tempChild
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ mb: '30px' }} component='div' data-testid="service-selection-prompt">
        <CustomLabelWithToolTip
          name='IF_Service_Intro'
          isdId={isdId ?? 0}
          defaultLabelText='Thank you! Here are some services and resources that may be available to you and your children.'
        />
      </Typography>
      <Typography variant='subtitle1' sx={{ mb: '30px' }} component='div'>
        <CustomLabelWithToolTip
          name='IF_Service_Subtext'
          isdId={isdId ?? 0}
          defaultLabelText='If your children need multiple or different services, you will have the ability to add that later. Select a service below to get started.'
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      {unemptyServices().map(service =>
        <Box key={service.id}>
          <LargeCard
            fullWidth={true}
            data-testid={'service-card-' + service.id.toString()}
            className={'services-option-card' + (selectedService === service.id ? ' selected' : '')}
            onClick={() => { setSelectedService(service.id); setSelectedChildIndex(null) }}
            content={
              <Box className='d-flex'>
                {getIcon(service.s3Key)}
                <Box sx={{ marginLeft: '10px', marginRight: '25px' }}>
                  <Typography variant="h5" className='card-subheader' >{service.name}</Typography>
                  <Box sx={{ marginTop: '10px' }}>{service.description}</Box>
                </Box>
              </Box>
            }
          />
          {selectedService === service.id && <Box className='children-list-container'>
            {eligibleChildren(service.id).map(child => {
              return <SelectableRow
                key={childName(child) + (child.dateOfBirth?.toString() ?? '') + (child.dueDate?.toString() ?? '')}
                text={`${childName(child)} ${childAge(child)}`}
                id={indexOfChild(child)}
                selectedId={selectedChildIndex}
                setSelected={setSelectedChildIndex}
              />
            })}
          </Box>}
        </Box>
      )}
      {unemptyServices().length === 0 && <>
        {!hasAnyOtherServices && <Typography variant='h6' component='div' data-testid='no-services'>
          This ISD does not currently have any services available. Please contact your ISD to request services.
        </Typography>}
        {hasAnyOtherServices && <Typography variant='h6' component='div' data-testid='no-services'>
          There are not any additional services available. Please press continue to proceed.
        </Typography>}
      </>}
    </Grid>

    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='continueButton'
        className='footer-button'
        data-testid='continue-button'
        onClick={handleContinue}
        variant='contained'
      >
        Continue
      </Button>
    </Grid>
  </Grid>
}
