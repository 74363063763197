import { useEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { IsdDocumentTypeStatus, type postError, type isdProgram, type ageSetting, type lookup } from '../../core/types'
import { Modal } from '../../Components/Modal'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { SelectWithLabel, type SelectOption } from '../../Components/SelectWithLabel'
import { SwitchWithLabel } from '../../Components/Switch/SwitchWithLabel'
import { ErrorProvider, useErrorDispatch } from '../../ContextProviders/ErrorProvider'
import { toast } from 'react-toastify'
import { ProgramAgeSetting } from './ProgramAgeSetting'
import { BannerModal } from '../../Components/Modal/BannerModal'

export interface ProgramSettingProps {
  open: boolean
  onClose: () => void
  isdID: number
  program: isdProgram
  errors?: string[] | undefined
}

export function ProgramSettings (props: ProgramSettingProps): JSX.Element {
  const [isdPrograms, setISDPrograms] = useState<isdProgram[]>([])
  const [isdProgramData, setISDProgramData] = useState<isdProgram>()
  const [fileTypes, setFileTypes] = useState<lookup[]>([])
  const [selectedFileTypeIDs, setSelectedFileTypeIDs] = useState<Array<{ id: number, status: string }>>([])
  const [ageSettings, setAgeSettings] = useState<ageSetting[]>(props.program.ageSettingList)
  const [loaded, setLoaded] = useState(false)
  const [updateDisabled, setUpdateDisabled] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [originalCapacityLimit, setOriginalCapacityLimit] = useState(false)

  const recordNumber = useRef(0)
  const dispatch = useErrorDispatch()

  const fileTypeOptions: Array<SelectOption<IsdDocumentTypeStatus>> = [
    { id: IsdDocumentTypeStatus.REQUIRED, name: IsdDocumentTypeStatus.REQUIRED },
    { id: IsdDocumentTypeStatus.NOTREQUIRED, name: IsdDocumentTypeStatus.NOTREQUIRED }
  ]

  useEffect(() => {
    const loadFormData = async (): Promise<void> => {
      const { response, error } = await sendGet(`/ISDDocument/GetIsdDocuments?isdid=${props.isdID}`, {})
      if (error[0] === '') {
        setFileTypes(response)
      }

      const { response: rsp2, error: error2 } = await sendGet(`/ISDPrograms/GetISDProgramDocument?ISDID=${props.isdID}&programID=${props.program.id}`, {})
      if (error2[0] === '') {
        const list: Array<{ id: number, status: string }> =
          rsp2.map((x: { documentID: number, status: string }) => {
            return { id: x.documentID, status: x.status }
          })
        setSelectedFileTypeIDs(list)
      }

      const { response: rsp3, error: error3 } = await sendGet(`/ISDPrograms/GetByProgram?isdID=${props.isdID}&programID=${props.program.id}`, {})
      if (error3[0] === '') {
        setISDProgramData(rsp3)
        setAgeSettings(rsp3.ageSettingList)
        setOriginalCapacityLimit(rsp3.noCapacityLimit)
      }

      const { response: rsp4, error: error4 } = await sendGet(`/ISDPrograms/GetPrograms?ISDID=${props.isdID}`, {})
      if (error4[0] === '') {
        setISDPrograms(rsp4)
      }

      setLoaded(true)
    }
    void loadFormData()
  }, [])

  const handleClose = (): void => {
    props.onClose()
  }

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    if (isdProgramData != null) {
      setISDProgramData({ ...isdProgramData, [event.target.name]: checked })
    }
  }

  const handleChange = (event: any, documentId: number): void => {
    const tempIds = selectedFileTypeIDs.slice()
    if (selectedFileTypeIDs !== undefined) {
      const selecteddocument = tempIds.find(x => x.id === documentId)
      if (selecteddocument !== undefined) {
        selecteddocument.status = event.target.value
      } else {
        const doc = fileTypes.find(x => x.id === documentId)
        if (doc !== undefined) {
          tempIds.push({ id: doc.id, status: event.target.value })
        }
      }

      setSelectedFileTypeIDs(tempIds)
      setLoaded(true)
    }
  }

  const getDocumentTypeStatus = (documentId: number): string => {
    if (selectedFileTypeIDs !== undefined) {
      const selecteddocument = selectedFileTypeIDs.find(x => x.id === documentId)
      if (selecteddocument !== undefined) return selecteddocument.status ?? IsdDocumentTypeStatus.NOTREQUIRED
    }
    return IsdDocumentTypeStatus.NOTREQUIRED
  }

  const addAgeSetting = (): void => {
    recordNumber.current++
    // a negative id indicates a new record
    const newElement: ageSetting = { id: recordNumber.current * -1, allowForAge: 0, fplIncomeThreshhold: null, requiresApproval: false, maxEnrollmentAllocationPercent: null }
    setAgeSettings([...ageSettings, newElement])
  }

  const ageSettingChanged = (ageSetting: ageSetting): void => {
    const index = ageSettings.findIndex(x => x.id === ageSetting.id)
    const newArr = [...ageSettings]
    newArr[index] = ageSetting
    setAgeSettings(newArr)
  }

  const removeAgeSetting = (id: number): void => {
    const newAgeSettings = ageSettings.filter(x => x.id !== id)
    setAgeSettings(newAgeSettings)
  }

  const handleIntakeChange = async (checked: boolean): Promise<void> => {
    if (isdProgramData == null) {
      return
    }

    const tmpProgramData = { ...isdProgramData }

    if (checked) {
      const { response } = await sendGet(`/ISDPrograms/IsIntakeProgramInUse?isdProgramID=${props.program.id}`, {})
      if (response === true) {
        toast.error('Only intake providers can use this program and currently non-intake providers are assigned this program. Please remove the Program from them prior to making this change.')
        setUpdateDisabled(true)
      } else {
        toast.warning('Only intake providers will be able to utilize this program.')
        tmpProgramData.requiresIncomeCalculation = false
        tmpProgramData.priorityProgram = false
        setUpdateDisabled(false)
      }
    } else {
      toast.warning('This program will now be available to any provider.')
      setUpdateDisabled(false)
    }

    tmpProgramData.isIntake = checked
    setISDProgramData(tmpProgramData)
  }

  const handleCapacityChange = (checked: boolean): void => {
    if (isdProgramData == null) {
      return
    }

    const tmpProgramData = { ...isdProgramData, noCapacityLimit: checked }
    setISDProgramData(tmpProgramData)
  }

  const handleSubmit = async (): Promise<void> => {
    if (isdProgramData == null) {
      return
    }

    if (!originalCapacityLimit && isdProgramData.noCapacityLimit) {
      setShowConfirmModal(true)
      return
    }

    await submit()
  }

  const submit = async (): Promise<void> => {
    if (isdProgramData == null) {
      return
    }

    const { success } = await sendPost('/ISDPrograms/UpdateISDDocumentTypes', {
      isdID: props.isdID,
      isdProgramID: props.program.id,
      fileTypeIDs: selectedFileTypeIDs
    })
    if (success) {
      isdProgramData.ageSettingList = ageSettings
      const { response } = await sendPost('/ISDPrograms/Update', isdProgramData)
      const resp: { success: boolean, errors: postError[], newRecordID: number } = response
      if (resp.success) {
        dispatch({ errors: [], type: 'clear' })
        props.onClose()
        toast.success('Program settings saved')
      } else {
        resp.errors.filter(e => e.field == null).forEach(e => {
          toast.error(e.error)
        })
        dispatch({ errors: resp.errors, type: 'update' })
      }
    }
  }

  const titleContent = <div className="d-flex f-align-items-center">
    <span>Program Settings - {props.program.name}</span>
  </div>

  if (!loaded) {
    return <CircularProgress role='loading' />
  } else {
    const content =
      <ErrorProvider>
        <Box>
          <SwitchWithLabel
            onChange={async (event, checked) => { await handleIntakeChange(checked) }}
            label='Intake Program'
            name='isIntake'
            checked={isdProgramData?.isIntake ?? false}
          />

          <SwitchWithLabel
            onChange={(event, checked) => { handleCapacityChange(checked) }}
            label='No Capacity Limit'
            name='noCapacityLimit'
            checked={isdProgramData?.noCapacityLimit ?? false}
            disabled={isdProgramData?.hasEnrollments}
          />

          {isdProgramData?.isIntake !== true &&
            <SwitchWithLabel
              onChange={handleCheckChange}
              label='Requires Income Calculation'
              name='requiresIncomeCalculation'
              checked={isdProgramData?.requiresIncomeCalculation ?? false}
              dataTestId="requiresIncomeCalculation"
            />}

          {(isdProgramData?.requiresIncomeCalculation ?? false) &&
          (!isdPrograms.some(p => p.priorityProgram) || isdPrograms.some(p => p.priorityProgram && p.id === props.program.id)) &&
            <SwitchWithLabel
              onChange={handleCheckChange}
              label='Priority Program'
              name='priorityProgram'
              checked={isdProgramData?.priorityProgram ?? false}
            />
          }

          <Button
            data-testid="addcaregiver"
            variant='text'
            sx={{ color: '#595959', textTransform: 'none' }}
            onClick={addAgeSetting}
          >Add Age Setting +</Button>

          <table className='centered-cells'>
            <thead>
              <tr>
                <th>Allow For Age</th>
                {(isdProgramData?.requiresIncomeCalculation ?? false) && <>
                  <th>FPL Income Threshold %</th>
                  <th>Requires Approval</th>
                  <th>Max Enrollment Allocation %</th>
                </>
                }
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {ageSettings.map((x) => {
                return <ProgramAgeSetting
                  key={x.id}
                  record={x}
                  onDelete={() => { removeAgeSetting(x.id) }}
                  onChange={ageSettingChanged}
                  showCalculationOptions={isdProgramData?.requiresIncomeCalculation ?? false}
                />
              })
              }
            </tbody>
          </table>

          <SwitchWithLabel
            onChange={handleCheckChange}
            label='Allow Concurrent Enrollment'
            name='allowConcurrentEnrollment'
            checked={isdProgramData?.allowConcurrentEnrollment ?? false}
            dataTestId='allowConcurrentEnrollment'
          />

          <table>
            <thead>
              <tr>
                <th>Document Name</th>
                <th>Require</th>
              </tr>
            </thead>
            <tbody>
              {fileTypes.map(t => {
                return <tr key={t.id}>
                  <td>
                    {t.name}
                  </td>
                  <td>
                    <SelectWithLabel
                      name="documentTypeStatus"
                      label=""
                      width="100%"
                      value={getDocumentTypeStatus(t.id)}
                      options={fileTypeOptions}
                      onChange={(evt) => { handleChange(evt, t.id) }}
                    />
                  </td>
                </tr>
              })
              }
            </tbody>
          </table>

          <BannerModal
            open={showConfirmModal}
            title='Warning'
            onClose={() => { setShowConfirmModal(false) }}
            onConfirm={submit}
            confirmButtonText='Yes, Confirm'
            cancelButtonText='No, Cancel'
            content={<>Once a program is set to no capacity limit and enrollments are made, this can never be reversed. Are you sure you want to continue?</>}
          />
        </Box>
      </ErrorProvider>

    return <Modal
      open={props.open}
      titleContent={titleContent}
      confirmationContent={content}
      onClose={handleClose}
      onConfirm={handleSubmit}
      maxWidth="sm"
      isUpdateDisabled={updateDisabled}
    />
  }
}
