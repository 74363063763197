import { useEffect, useState } from 'react'
import { BannerModal } from '../Modal/BannerModal'
import { type lookup } from '../../core/types'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { Box, Button, Container } from '@mui/material'

export interface SelectProgramModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (selectedProgram: lookup) => void
  referralId: number
  isdServiceProviderId: number
}

export function SelectProgramModal (props: SelectProgramModalProps): JSX.Element {
  const [availablePrograms, setAvailablePrograms] = useState<lookup[]>([])
  const [selectedProgram, setSelectedProgram] = useState<lookup | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet(`/ReferralSearch/GetAvailablePrograms?isdServiceProviderID=${props.isdServiceProviderId}`, {})
      setAvailablePrograms(response)
    }
    void fetchData()
  }, [])

  const handleSubmit = async (): Promise<void> => {
    if (selectedProgram === null) {
      toast.error('You must make a selection to continue')
      return
    }

    await sendPost('/ReferralEdit/SetProgram', {
      referralId: props.referralId,
      programID: selectedProgram.id
    })
    props.onConfirm(selectedProgram)
  }

  const content = <Container>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '5px' }}>
      {availablePrograms.map((program) => {
        return <Button
          key={program.id}
          variant="outlined"
          className={'wide-button' + (selectedProgram?.id === program.id ? ' selected' : '')}
          onClick={() => { setSelectedProgram(program) }}
          data-testid='update-email'
        >
          {program.name}
        </Button>
      })}
    </Box>
  </Container>

  return <BannerModal
    open={props.open}
    title='Select Program'
    onClose={props.onClose}
    onConfirm={handleSubmit}
    cancelButtonText='Cancel'
    confirmButtonText='Select'
    content={content}
    className='Nested Referral'
    buttonClassName='modal-button'
  />
}
