import { Tab, Tabs, Typography, Box, Breadcrumbs, Link } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TabDisplay } from '../../../Components/TabDisplay'
import { CapacityWaitlist } from './CapacityWaitlist'
import { Exitedlist } from './ExitedList'
import { ProgramHoldList } from './ProgramHoldList'
import { ENROLLMENT_MANAGEMENT } from '../../Routes'
import { sendGet } from '../../../hooks/use-fetch'
import { type sessionBlock, type program, EnrollmentStatus } from '../../../core/types'
import { EnrolledList } from './EnrolledList'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export function EnrollmentTabs (): JSX.Element {
  const [enrollments, setEnrollments] = useState<Array<{ id: number, name: string, status: string }>>([])
  const [sessionBlockList, setSessionBlockList] = useState<sessionBlock[]>([])
  const [programs, setPrograms] = useState<program[]>([])
  const [sessionList, setSessionList] = useState<Array<{ id: number, name: string }>>([])
  const [facilityList, setFacilityList] = useState<Array<{ id: number, name: string }>>([])
  const [exitReasons, setExitReasons] = useState<Array<{ id: number, name: string }>>([])
  const [refresh, setRefresh] = useState(Date.now())
  const { serviceId } = useParams()
  const accountID = useAccountId()?.id ?? 0

  useEffect(() => {
    async function fetchDataforFilter (): Promise<void> {
      if (accountID === 0) return

      const { response: sessions } = await sendGet(`/SessionList/GetBasicSessionList?id=${accountID}`, {})
      if (sessionBlockList !== null) {
        setSessionList(sessions)
      }
      const { response } = await sendGet(`/ServiceProviderDetails/GetProviderPrograms?id=${accountID}`, {})
      if (response !== null) {
        setPrograms(response)
      }
      const { response: facilities } = await sendGet(`/FacilityDetails/GetAllForProvider?systemServiceProviderID=${accountID}`, {})
      if (facilities !== null) {
        setFacilityList(facilities)
      }
      const { response: exitReasons } = await sendGet('/Enrollment/GetExitReasons', {})
      if (exitReasons !== null) {
        setExitReasons(exitReasons)
      }
    }
    void fetchDataforFilter()
  }, [accountID])

  const [value, valueChange] = useState(0)
  const nav = useNavigate()
  const [enrollmentName, setEnrollmentName] = useState('')

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (serviceId === undefined || accountID === 0) return

      const serviceResp = await sendGet(`/SessionList/GetService?serviceId=${serviceId ?? ''}`, {})
      if (serviceResp.success) {
        setEnrollmentName(serviceResp.response.name)
      }
      const { response: sessionBlockList } = await sendGet(`/SessionBlock/GetAll?serviceId=${serviceId ?? ''}&spId=${accountID}`, {})
      if (sessionBlockList !== null) {
        setSessionBlockList(sessionBlockList)
      }
      const baseSearchString = `/Enrollment/SearchEnrollments?systemServiceID=${serviceId ?? 0}&spId=${accountID}`
      const { response: enrollmentResponse } = await sendGet(baseSearchString, {})
      if (enrollmentResponse !== null) {
        setEnrollments(enrollmentResponse)
      }
    }
    void getData()
  }, [serviceId, accountID, refresh])

  const allPending = [EnrollmentStatus.Pending.toString(), EnrollmentStatus.EnrollPendingApproval.toString(), EnrollmentStatus.ProgramHoldPendingApproval.toString(), EnrollmentStatus.ApprovalDenied.toString()]
  const holdCount = enrollments.filter(e => allPending.includes(e.status)).length
  const exitedCount = enrollments.filter(e => e.status === EnrollmentStatus.Exited).length
  const enrolledCount = enrollments.filter(e => e.status === EnrollmentStatus.Enrolled).length
  const waitlistCount = enrollments.filter(e => e.status === EnrollmentStatus.Waitlist).length

  return <Box>
    <Typography variant="h3" data-test-page-header>{enrollmentName} Enrollments</Typography>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb">
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(ENROLLMENT_MANAGEMENT) }} >
        Enrollments
      </Link>
    </Breadcrumbs>

    <Tabs value={value}
      data-test-tabs
      onChange={(e, val) => { valueChange(val) }}
      aria-label="Enrollment Tabs"
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label={`Pending (${holdCount})`} value={0} data-test-pending />
      <Tab label={`Waitlist (${waitlistCount})`} value={1} data-test-waitlist />
      <Tab label={`Enrolled (${enrolledCount})`} value={2} data-test-enrolled />
      <Tab label={`Exited (${exitedCount})`} value={3} data-test-exited/>
    </Tabs>
    <ErrorProvider>
      <>
        <TabDisplay
          index={0}
          value={value}
        >
          <ProgramHoldList
            enrollmentStatus={EnrollmentStatus.Pending}
            facilityList={facilityList}
            programs={programs}
            sessionBlockList={sessionBlockList}
            sessionList={sessionList}
            exitReasons={exitReasons}
            onEnrollmentChanged={() => { setRefresh(Date.now()) }}
          />
        </TabDisplay>
        <TabDisplay
          index={1}
          value={value}
        >
          <CapacityWaitlist
            enrollmentStatus={EnrollmentStatus.Waitlist}
            facilityList={facilityList}
            programs={programs}
            sessionBlockList={sessionBlockList}
            sessionList={sessionList}
            exitReasons={exitReasons}
            onEnrollmentChanged={() => { setRefresh(Date.now()) }}
          />
        </TabDisplay>
        <TabDisplay
          index={2}
          value={value}
        >
          <EnrolledList
            enrollmentStatus={EnrollmentStatus.Enrolled}
            facilityList={facilityList}
            programs={programs}
            sessionBlockList={sessionBlockList}
            sessionList={sessionList}
            exitReasons={exitReasons}
            onEnrollmentChanged={() => { setRefresh(Date.now()) }}
          />
        </TabDisplay>
        <TabDisplay
          index={3}
          value={value}
        >
          <Exitedlist
            enrollmentStatus={EnrollmentStatus.Exited}
            facilityList={facilityList}
            programs={programs}
            sessionBlockList={sessionBlockList}
            sessionList={sessionList}
            exitReasons={exitReasons}
            onEnrollmentChanged={() => { setRefresh(Date.now()) }}
          />
        </TabDisplay>
      </>
    </ErrorProvider>
  </Box>
}
