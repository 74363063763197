import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { sendGet, sendPut, sendPostFormData } from '../../hooks/use-fetch'
import SimpleAccordion from '../../Components/Accordian'
import S3Image from '../../Components/S3Image'
import FilePicker from '../../Components/FilePicker'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { FormatCurrency, DownloadDocument } from '../../core/Utilities'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { type ReferralDocumentModel, type DocumentVerifyModel } from '../../core/types'
import { ReferralDocumentSelection } from './ReferralDocumentSelection'
import { DocumentDisplay } from '../../Components/DocumentList/DocumentDisplay'
import { DocumentVerify } from '../../Components/DocumentList/DocumentVerify'
import { toast } from 'react-toastify'

export interface ReferralDocumentProps {
  referralID: number
  childID: number
  readOnly: boolean
  showChildDocsIfNoneOnReferral: boolean
  dataChanged: () => void
  years: number[]
}

export interface ReferralDocumentTypeModel {
  documentTypeID: number
  documentTypeName: string
  documentDescription: string | null
  imageFile: string | null
  incomeRelated: boolean
  verifiedIncomeTotal: number | null
  caregiverRelated: boolean
  documentsUploaded: number
  required: boolean
  documents: ReferralDocumentModel[]
}

export function ReferralDocuments (props: ReferralDocumentProps): JSX.Element {
  const [docTypes, setDocTypes] = useState<ReferralDocumentTypeModel[]>([])
  const [showSelectDocuments, setShowSelectDocuments] = useState<boolean>(false)
  const [showUploadAndVerify, setShowUploadAndVerify] = useState<boolean>(false)
  const [selectedDocType, setSelectedDocType] = useState<ReferralDocumentTypeModel | null>(null)
  const [refreshDate, setRefreshDate] = useState<Date>(new Date())
  const selectedDocuments = useRef<DocumentVerifyModel[]>([])
  const uploadFile = useRef<File | null>(null)
  const uploadFileName = useRef<string>('')
  const [documentVerify, setDocumentVerify] = useState<DocumentVerifyModel | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: rsp1, success: suc1 } = await sendGet(`/ReferralEdit/ReferralDocuments?referralID=${props.referralID}&showChildDocsIfNoneOnReferral=${props.showChildDocsIfNoneOnReferral ? 'true' : 'false'}`, {})
      if (suc1) {
        setDocTypes(rsp1 as ReferralDocumentTypeModel[])
      }
    }
    void fetchData()
  }, [refreshDate])

  const displayClass = (docType: ReferralDocumentTypeModel): string => {
    if (docType.documents.length === 0) {
      if (docType.required) {
        return 'error-fill'
      } else {
        return 'warning-fill'
      }
    } else {
      return ''
    }
  }

  const showDocumentSelection = (docType: ReferralDocumentTypeModel): void => {
    setSelectedDocType(docType)
    setShowSelectDocuments(true)
  }

  const saveDocuments = async (): Promise<void> => {
    const documents = selectedDocuments.current

    const { response } = await sendPut(`/Document/ApplyDocumentsToReferral/${props.referralID}`, documents)
    if (response as boolean) {
      setShowSelectDocuments(false)
      setRefreshDate(new Date())
      props.dataChanged()
    }
  }

  const uploadAndVerify = async (docType: ReferralDocumentTypeModel, file: File, fileName: string): Promise<void> => {
    setSelectedDocType(docType)
    uploadFile.current = file
    uploadFileName.current = fileName
    setShowUploadAndVerify(true)
  }

  const removeDocument = async (documentId: number): Promise<void> => {
    const { response } = await sendPut(`/ReferralEdit/RemoveDocument?referralID=${props.referralID}&documentID=${documentId}`, [])
    if (response as boolean) {
      setRefreshDate(new Date())
      props.dataChanged()
    }
  }

  const downloadDocument = async (documentId: number): Promise<boolean> => {
    return DownloadDocument(documentId)
  }

  const saveUploadedDocument = async (): Promise<void> => {
    if (selectedDocType !== null && uploadFile.current !== null && documentVerify !== null) {
      const fileData = new FormData()
      fileData.append('referralID', props.referralID.toString())
      fileData.append('documentTypeID', selectedDocType.documentTypeID.toString())
      fileData.append('verificationStatus', JSON.stringify(documentVerify))
      fileData.append('file', uploadFile.current)

      const { response } = await sendPostFormData('/Document/UploadDocumentForReferral', fileData)
      if (response.success as boolean) {
        setShowUploadAndVerify(false)
        setRefreshDate(new Date())
        props.dataChanged()
      } else {
        response.errors.forEach((error: any) => {
          toast.error(error.error)
        })
      }
    }
  }

  const documentSection = (docType: ReferralDocumentTypeModel): JSX.Element => {
    return <>
      <Box>
        {docType.incomeRelated && <TextWithLabel label='Total Verified Income' name='incomeTotal' value={FormatCurrency(docType.verifiedIncomeTotal)} inline={true} />}
        {!props.readOnly && <>
          <FilePicker
            label='Upload a file'
            name='file'
            onSelect={async (e: any): Promise<void> => { await uploadAndVerify(docType, e.target.files[0], e.target.files[0].name) }}
          />
          <Button variant='outlined' color='inherit' className='ml-10' data-testid='selectExisting' onClick={() => { showDocumentSelection(docType) }}> Select Existing</Button>
        </>
        }
      </Box >
      <Box sx={{ fontWeight: 400, fontSize: '0.75em' }}>
        {docType.documents.map((d, i) => {
          return <DocumentDisplay
            key={i}
            document={d}
            canVerify={false}
            canEdit={!props.readOnly}
            onVerifyChanged={() => { }}
            deleteOptionText='Remove'
            onDelete={async (): Promise<void> => { await removeDocument(d.id) }}
            onDownload={async (): Promise<void> => { await downloadDocument(d.id) }}
          />
        })}
      </Box>
    </>
  }

  return <>
    <div className='mt-5'>
      {docTypes.length === 0 && <div className='text-center mb-5'>No document types have been configured for this service or program</div>}
      {docTypes.map((d, i) => {
        return <SimpleAccordion
          dataTestid={`documentType${d.documentTypeID}Accordian`}
          title={d.documentTypeName}
          tag={`${d.documentsUploaded} uploaded - ${d.documents.filter(d => d.isLinked).length} selected`}
          key={d.documentTypeID.toString() + 'accordian'}
          width={'100%'}
          icon={d.imageFile !== null ? <S3Image imageKey={d.imageFile} /> : <></>}
          summary={d.documentDescription ?? ''}
          className={displayClass(d)}
          body={documentSection(d)}
          sx={{
            borderRadius: '32px !important',
            border: '1px solid lightgray',
            mb: '15px',
            ':first-of-type': {
              borderRadius: '16px'
            },
            ':last-of-type': {
              borderRadius: '16px'
            },
            '&.Mui-expanded:last-of-type': {
              mb: '25px'
            }
          }}
        />
      })
      }
    </div>
    {showSelectDocuments && selectedDocType !== null && <BannerModal
      open={true}
      dataTestId='selectDocumentsBanner'
      title={`Available ${selectedDocType?.documentTypeName ?? ''} Documents`}
      onConfirm={saveDocuments}
      confirmButtonText='Save'
      onClose={() => { setShowSelectDocuments(false) }}
      hideXtoClose={true}
      className='Nested Referral'
      content={<ReferralDocumentSelection
        referralID={props.referralID}
        documentTypeID={selectedDocType.documentTypeID}
        incomeRelated={selectedDocType.incomeRelated}
        onInclude={(list: DocumentVerifyModel[]) => { selectedDocuments.current = list }}
        years={props.years}
      />}
    />
    }
    {showUploadAndVerify && selectedDocType !== null && <BannerModal
      open={true}
      dataTestId='uploadDocumentBanner'
      title={`Add ${selectedDocType.documentTypeName} Document`}
      onConfirm={saveUploadedDocument}
      confirmButtonText='Verify & Include'
      onClose={() => { setShowUploadAndVerify(false) }}
      hideXtoClose={true}
      className='Nested Referral'
      content={<div className='pl-25 pr-20 pb-10'>
        <Typography variant='h6' className='pb-10'>{uploadFileName.current}</Typography>
        <DocumentVerify documentTypeID={selectedDocType.documentTypeID} childID={props.childID} togglingVerificationStatus={false} onChange={setDocumentVerify} years={props.years} />
      </div>}
    />
    }
  </>
}
