/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BannerModal } from '../../Components/Modal/BannerModal'
import Box from '@mui/material/Box'
import { TextFieldWithLabel } from '../../Components/TextField'
import { useState, type ChangeEvent } from 'react'

interface ReferralHouseholdMemberModalProps {
  open: boolean
  householdMemberCount: number | null
  onSubmit: (newHouseholdMemberCount: number | null) => void
  onClose: () => void
}

export function ReferralHouseholdMembersModal (props: ReferralHouseholdMemberModalProps): JSX.Element {
  const [memberCount, setMemberCount] = useState(props.householdMemberCount)

  const handleNumberChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    let value = e.target.value
    value = value.replace(',', '').replace('$', '')
    if (!isNaN(Number(value))) {
      setMemberCount(Number(value))
    }
  }

  const content = <Box sx={{ p: '0 20px' }}>
    <TextFieldWithLabel
      label='Number of Household Members'
      name='memberCount'
      onChange={handleNumberChange}
      value={memberCount}
      dataTestId='memberCount'
    />
  </Box>

  const handleSubmit = async (): Promise<void> => {
    props.onSubmit(memberCount)
  }

  return <BannerModal
    className='Nested Referral'
    maxWidth='sm'
    open={props.open}
    content={content}
    onClose={props.onClose}
    onConfirm={handleSubmit}
    title='Edit Household Members'
    cancelButtonText='Discard Changes'
    confirmButtonText='Update'
  />
}
