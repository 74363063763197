import { Box, Grid, Typography } from '@mui/material'
import { type AuthType } from '../../hooks/use-auth'
import { ServiceProviderCardDisplay } from '../../Components/Cards/ServiceProviderCard/ServiceProviderCardDisplay'
import { IsdCardDisplay } from '../../Components/Cards/IsdCard/IsdCardDisplay'

export function UserOrgainizations (props: AuthType): JSX.Element {
  return <Box>
    {(props.user?.serviceProviders.length ?? 0) > 0 && (<>
      <Typography variant="h3">Service Providers</Typography>
      <Grid container spacing={1}>
        {props.user?.serviceProviders.map((sp) => {
          return <Grid item key={sp.id}>
            <ServiceProviderCardDisplay
              serviceProvider={sp}
            />
          </Grid>
        })}
      </Grid>
    </>
    )}
    {(props.user?.isDs.length ?? 0) > 0 && (<>
      <Typography variant="h3">ISDs</Typography>
      <Grid container spacing={1} >
        {props.user?.isDs.map((isd) => {
          return <Grid item key={isd.id}>
            <IsdCardDisplay
              isd={isd}
            />
          </Grid>
        })}
      </Grid>
    </>
    )}

  </Box>
}
