import { Box } from '@mui/material'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { type SelectOption, SelectWithLabel } from '../../../Components/SelectWithLabel'
import { NoteSource } from '../../../core/types'
import { SpaceBeforeCapitals } from '../../../core/Utilities'

export interface CommunicationSearchCriteria {
  startDate: Date | null
  endDate: Date | null
  noteType: NoteSource | null
}

export function CommunicationSearch (props: { current: CommunicationSearchCriteria, onChange: (newSearch: CommunicationSearchCriteria) => void }): JSX.Element {
  const noteTypeOptions: Array<SelectOption<NoteSource>> = []
  for (const noteType in NoteSource) {
    // loop thru the enum values
    noteTypeOptions.push({ id: noteType as NoteSource, name: SpaceBeforeCapitals(noteType) })
  }

  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }
  const handleDateChange = (valueName: string, newDate: Date | null): void => {
    props.onChange({ ...props.current, [valueName]: newDate })
  }

  return <Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
    <Box>
      <DatePickerWithLabel
        value={props.current.startDate}
        onChange={(newValue) => { handleDateChange('startDate', newValue) }}
        name={'startDate'}
        label={'Start Date'}
        required={false}
        FormControlProps={{ className: 'width-175px' }}
        dataTestId='startDate'
      />
    </Box>
    <Box>
      <DatePickerWithLabel
        value={props.current.endDate}
        onChange={(newValue) => { handleDateChange('endDate', newValue) }}
        name={'endDate'}
        label={'End Date'}
        required={false}
        FormControlProps={{ className: 'width-175px' }}
        dataTestId='endDate'
      />
    </Box>
    <Box>
      <SelectWithLabel
        label='Communication Type'
        name='noteType'
        includeNoneOption={true}
        onChange={handleChange}
        options={noteTypeOptions}
        value={props.current.noteType ?? ''}
        width='200px'
      />
    </Box>
  </Box>
}
