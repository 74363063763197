import { Box, TableCell, TableHead, TableSortLabel, type SortDirection } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import type * as React from 'react'
import { SortOrder, type Column, type ColumnSort } from './DataTable.model'

export interface DataTableProps {
  columns: Column[]
  loading?: boolean
  sortable?: boolean
  columnSorts?: ColumnSort[]
  hasCheckbox?: boolean
  allChecked?: boolean
  onSortHeaderClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: Column) => void
  onHeaderCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function DataTableHead (props: DataTableProps): JSX.Element {
  const columns = props.columns != null ? props.columns : []
  const loading = props.loading != null ? props.loading : false
  const sortable = props.sortable != null ? props.sortable : true
  const columnSorts = props.columnSorts != null ? props.columnSorts : []
  const hasCheckbox = props.hasCheckbox != null ? props.hasCheckbox : true
  const allChecked = props.allChecked != null ? props.allChecked : false
  const { onSortHeaderClick, onHeaderCheckboxChange } = props

  const _onSortHeaderClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: Column): void => {
    if (onSortHeaderClick != null) {
      onSortHeaderClick(event, column)
    }
  }

  return (
    <TableHead>
      <TableRow>
        {
          hasCheckbox
            ? <TableCell padding="checkbox">
              <Checkbox
                key="checkbox"
                color="primary"
                checked={allChecked}
                onChange={onHeaderCheckboxChange}
                disabled={loading}
              />
            </TableCell>
            : null
        }
        {
          columns.map(column => {
            if (!column.visibility) {
              return null
            } else {
              const isSortable = sortable && column.sortable
              const columnSort = columnSorts.find(v => v.key === column.key)
              const sortOrder = isSortable && columnSort != null ? columnSort.order : null
              const sortDirectionForCell = (sortOrder != null ? sortOrder.toLowerCase() : false) as SortDirection
              const sortDirectionForLabel = (sortOrder != null ? sortOrder.toLowerCase() : 'asc') as 'asc' | 'desc'

              return <TableCell key={column.key} sortDirection={sortDirectionForCell}>
                {
                  isSortable
                    ? <TableSortLabel data-testid={column.label} active={sortOrder != null} direction={sortDirectionForLabel} onClick={event => { _onSortHeaderClick(event, column) }}>
                      {column.label}
                      {
                        sortOrder != null
                          ? <Box component='span' sx={visuallyHidden}>
                            {sortOrder === SortOrder.ASC ? 'sorted ascending' : 'sorted descending'}
                          </Box>
                          : null
                      }
                    </TableSortLabel>
                    : column.label
                }
              </TableCell>
            }
          })
        }
      </TableRow>
    </TableHead>
  )
}
