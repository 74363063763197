
interface jpgProps {
  fill?: string
}

export function DetailsIcon (props: jpgProps): JSX.Element {
  const stroke = props.fill ?? '#000000'
  const fill = 'clear'
  return <>
    <svg width="100" height="100" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M25 6H120C130.526 6 139 14.474 139 25V120C139 130.526 130.526 139 120 139H25C14.474 139 6 130.526 6 120V25C6 14.474 14.474 6 25 6Z" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M53.5 44C50.9804 44 48.5641 42.9991 46.7825 41.2175C45.0009 39.4359 44 37.0196 44 34.5C44 31.9804 45.0009 29.5641 46.7825 27.7825C48.5641 26.0009 50.9804 25 53.5 25C54.7476 25 55.9829 25.2457 57.1355 25.7231C58.2881 26.2006 59.3354 26.9003 60.2175 27.7825C61.0997 28.6646 61.7994 29.7119 62.2769 30.8645C62.7543 32.0171 63 33.2524 63 34.5C63 35.7476 62.7543 36.9829 62.2769 38.1355C61.7994 39.2881 61.0997 40.3354 60.2175 41.2175C59.3354 42.0997 58.2881 42.7994 57.1355 43.2769C55.9829 43.7543 54.7476 44 53.5 44Z" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M34.5 34.5H44" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M63 34.5004L110.5 34.5" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M110.5 72.5H101" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M91.5 82C94.0196 82 96.4359 80.9991 98.2175 79.2175C99.9991 77.4359 101 75.0196 101 72.5C101 69.9804 99.9991 67.5641 98.2175 65.7825C96.4359 64.0009 94.0196 63 91.5 63C88.9804 63 86.5641 64.0009 84.7825 65.7825C83.0009 67.5641 82 69.9804 82 72.5C82 75.0196 83.0009 77.4359 84.7825 79.2175C86.5641 80.9991 88.9804 82 91.5 82Z" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M82 72.5004L34.5 72.5" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M34.5 110.5H44" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M53.5 120C50.9804 120 48.5641 118.999 46.7825 117.218C45.0009 115.436 44 113.02 44 110.5C44 107.98 45.0009 105.564 46.7825 103.782C48.5641 102.001 50.9804 101 53.5 101C54.7476 101 55.9829 101.246 57.1355 101.723C58.2881 102.201 59.3354 102.9 60.2175 103.782C61.0997 104.665 61.7994 105.712 62.2769 106.865C62.7543 108.017 63 109.252 63 110.5C63 111.748 62.7543 112.983 62.2769 114.135C61.7994 115.288 61.0997 116.335 60.2175 117.218C59.3354 118.1 58.2881 118.799 57.1355 119.277C55.9829 119.754 54.7476 120 53.5 120Z" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill={fill} strokeOpacity={1} stroke={stroke} d="M63 110.5L110.5 110.5" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>
}
// stroke="#722A82"
