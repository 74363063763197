import { Button } from '@mui/material'
import { FormatDateValue } from '../../../core/Utilities'
import { type CaregiverMemberDetails } from './CaregiverDisplay'
import { useState } from 'react'
import { sendPut } from '../../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { type AddressChangeType, ChangeAddress, type MemberType } from './ChangeAddress'
import { type location } from '../../../core/types'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { generatePath, useNavigate } from 'react-router'
import { CAREGIVER_PORTAL_MEMBERS_EDIT_CAREGIVER } from '../../Routes'

export interface CaregiverDetailsProps {
  caregiver: CaregiverMemberDetails
  updateData: () => void
}

export function CaregiverDetails (props: CaregiverDetailsProps): JSX.Element {
  const [showAddressModal, setShowAddressModal] = useState(false)
  const nav = useNavigate()

  function editGuardian (): void {
    const route = generatePath(CAREGIVER_PORTAL_MEMBERS_EDIT_CAREGIVER, { caregiverId: props.caregiver.id.toString() })
    nav(route)
  }

  function editAddress (): void {
    setShowAddressModal(true)
  }

  const handleAddressSubmit = async (location: location, changeType: AddressChangeType, selectedMembers: Array<{ id: number, type: MemberType }>): Promise<void> => {
    const data = { personID: props.caregiver.id, changeType, address: location.address, apartment: location.apartment, city: location.city, state: location.state, zipCode: location.zipCode, selectedMembers }
    const { success } = await sendPut('/CaregiverPortal/UpdateCaregiverAddress', data)
    if (success) {
      setShowAddressModal(false)
      toast.success('The Address change has been saved')
      props.updateData()
    } else {
      toast.error('Failed to save address changes')
    }
  }

  const addressDisplay = <>
    <div>{props.caregiver.location?.address} </div> <div>{props.caregiver.location?.city} {props.caregiver.location?.state} {props.caregiver.location?.zipCode}</div>
  </>

  return <>
    <TextWithLabel label='Name' name='name' value={props.caregiver.firstName + ' ' + props.caregiver.lastName} />
    <TextWithLabel label='Date of Birth' name='dob' value={FormatDateValue(props.caregiver.dateOfBirth)} />
    <TextWithLabel label='Primary Phone' name='primaryPhone' value={props.caregiver.primaryPhone} />
    <TextWithLabel label='Email' name='email' value={props.caregiver.email} />
    <TextWithLabel label='Preferred Contact Method' name='preferredContactMethod' value={props.caregiver.preferredContactMethod} />
    <TextWithLabel label='Highest Level of Education' name='highestEducationLevel' value={props.caregiver.highestEducationLevel} />
    <TextWithLabel label='Primary Language (if not English)' name='primaryLanguage' value={props.caregiver.primaryLanguage} />
    <TextWithLabel label='Address' name='address' content={addressDisplay} />

    <Button
      variant='contained'
      className='footer-button'
      sx={{ mt: '20px' }}
      name='editDetails'
      onClick={editGuardian}
    >
      Edit Details
    </Button>
    <Button
      variant='contained'
      className='footer-button'
      sx={{ mt: '10px' }}
      name='changeAddress'
      onClick={editAddress}
    >
      Change Address
    </Button>

    {
      showAddressModal && (
        <ChangeAddress
          open={showAddressModal}
          onSubmit={handleAddressSubmit}
          onClose={() => { setShowAddressModal(false) }}
        />
      )
    }
  </>
}
