import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import { Autocomplete } from '../AutoComplete'

interface RemovableSelectProps {
  label?: string
  name: string
  value: number | string
  hideDeleteBtn?: boolean
  options: Array<{ id: number, name: string }>
  onChange: (e: any, value: { id: string | number, name: string } | null) => void
  onDeleteBtnClick?: (e: any) => void
  addNewFragment: JSX.Element
}

export function RemovableAddSelect (props: RemovableSelectProps): JSX.Element {
  return (
    <div>
      <div className="d-flex f-justify-content-space-between f-align-items-center mb-5">
        <Box sx={{ flexGrow: '1' }}>
          <Autocomplete
            width='100%'
            label={props.label ?? ''}
            name={props.name}
            onChange={props.onChange}
            options={props.options}
            value={props.value}
            dataTestId={props.name}
          />
        </Box>
        {props.hideDeleteBtn === true
          ? null
          : <IconButton component="label"
              onClick={(e: any) => {
                if (props.onDeleteBtnClick != null) {
                  props.onDeleteBtnClick(e)
                }
              }}
          >
            <DeleteIcon />
          </IconButton>
        }
      </div>
      {props.value === -1 && (
        props.addNewFragment
      )}
    </div>
  )
}
