/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { type Dispatch, createContext, useReducer, useContext } from 'react'
import {
  type program, type ChildCaregiver, type ChildSiblings, type location,
  type postError, type service, type ChildEditValues,
  type race, type DocumentTypeRequirement, type eligibilityFactor,
  type TransferReferralRequest
} from '../core/types'
import { type documentTypeModel } from '../Components/DocumentList'
import { type ChildSearchResult } from '../Components/ChildDetails/DuplicateList'

export interface newChildRecord {
  child: ChildEditValues
  childDocuments: documentTypeModel[]
  locations: Array<{ index: number, location: location, edit: boolean, isNew: boolean }>
  siblings: Array<{ index: number, sibling: ChildSiblings, edit: boolean, isNew: boolean, isLoadedRecord: boolean }>
  caregivers: Array<{ index: number, caregiver: ChildCaregiver, edit: boolean, isNew: boolean, isLoadedRecord: boolean }>
  serviceSettings: childServiceSettings
  errors: postError[]
  step: number
  locationIndex: number
  siblingIndex: number
  caregiverIndex: number
  manualDistrict: { id: number, isdId: number } | null
  isdID?: number | null
  isLoadedFromExistingChild: boolean
  activeServices: Array<{ systemServiceProviderID: number, systemProgramID: number, systemServiceID: number, referralId?: number, enrollmentId?: number }>
  serviceIsValid: boolean
  matchedReferralId: number | null
  transferRequest: TransferReferralRequest
}

export type recordUpdate = locationUpdate | childUpdate | errorUpdate | clearUpdate | districtUpdate
| indexUpdate | siblingUpdate | caregiverUpdate | serviceUpdate | matchSiblingsUpdate | stepUpdate
| childDocumentsUpdate | caregiverDocumentsUpdate | setDocumentsUpdate | loadDuplicateUpdate
| serviceIsValidUpdate | transferRequestChange | unSelectDuplicateUpdate

export interface transferRequestChange {
  type: 'transferRequestChange'
  data: TransferReferralRequest
}
export interface locationUpdate {
  type: 'locations'
  locations: Array<{ index: number, location: location, edit: boolean, isNew: boolean }>
}

export interface matchSiblingsUpdate {
  type: 'matchSiblings'
  siblings: ChildEditValues[]
}

export interface districtUpdate {
  type: 'district'
  manualDistrict: { id: number, isdId: number } | null
  isdID?: number | null
}

export interface siblingUpdate {
  type: 'siblings'
  siblings: Array<{ index: number, sibling: ChildSiblings, edit: boolean, isNew: boolean, isLoadedRecord: boolean }>
}

export interface caregiverUpdate {
  type: 'caregivers'
  caregivers: Array<{ index: number, caregiver: ChildCaregiver, edit: boolean, isNew: boolean, isLoadedRecord: boolean }>
}

export interface childUpdate {
  type: 'child'
  child: ChildEditValues
}

export interface errorUpdate {
  type: 'errors'
  errors: postError[]
}

export interface clearUpdate {
  type: 'clear'
}
export interface unSelectDuplicateUpdate {
  type: 'unSelectDuplicate'
}

export interface indexUpdate {
  type: 'index'
  field: 'locations' | 'siblings' | 'caregivers'
}

export interface serviceUpdate {
  type: 'service'
  serviceSettings: childServiceSettings
}

export interface setDocumentsUpdate {
  type: 'setDocumentTypes'
  documentsTypes: documentTypeModel[]
}

export interface childDocumentsUpdate {
  type: 'updateChildDocuments'
  documents: documentTypeModel[]
}

export interface caregiverDocumentsUpdate {
  type: 'updateCaregiverDocuments'
  index: number
  documents: documentTypeModel[]
}
export interface loadDuplicateUpdate {
  type: 'loadDuplicate'
  data: ChildSearchResult
  child: ChildEditValues
}
export interface stepUpdate {
  type: 'step'
  step: number
}
export interface serviceIsValidUpdate {
  type: 'serviceIsValid'
  isValid: boolean
  referralId: number | null
}

export interface childServiceSettings {
  referralId: number | null
  timeFrame: string | null
  desiredSchedule: string | null
  systemService: service | null
  systemProgram: program | null
  sessionBlockId: number | null | undefined
  sessionBlockName: string | null | undefined
  facilityName: string | null
  facilityId: number | null
  income: number | null
  incomeVerified: boolean | null
  povertyLevel: number | null
  povertyPercent: number | null
  povertyPercentCutOff: number | null
  memberCount: number | null
  systemServiceProviderId: number | null
  systemServiceProviderName: string | null
  isdId: number | null
  districtId: number | null
  customDate?: Date | null
  priorityProviderId?: number | null
  priorityNotes?: string | null
  status: string
  priorityFound?: boolean | null
  notes?: string | null
}

const raceList: race[] = []
const efList: eligibilityFactor[] = []
const locList: location[] = []
const docList: DocumentTypeRequirement[] = []
const initialLocation: location = { isPrimary: true } as location
const initialChild: newChildRecord = {
  child: {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    birthGender: '',
    uic: '',
    childRaceList: raceList,
    documentTypeRequirements: docList,
    childLocations: locList,
    eligibilityFactorList: efList,
    canEditEligibilityFactors: false,
    otherEligibilityFactor: null,
    hasIEP: false,
    hasIFSP: false,
    hasEOIS: false
  } as ChildEditValues,
  childDocuments: [],
  locations: [{ edit: true, index: 0, location: { ...initialLocation }, isNew: true }],
  siblings: [],
  caregivers: [],
  errors: [],
  step: 1,
  isLoadedFromExistingChild: false,
  locationIndex: 1,
  siblingIndex: 0,
  caregiverIndex: 0,
  manualDistrict: null,
  activeServices: [],
  serviceSettings: {
    status: '',
    desiredSchedule: null,
    systemProgram: null,
    systemService: null,
    sessionBlockId: null,
    sessionBlockName: null,
    timeFrame: null,
    facilityId: null,
    income: null,
    incomeVerified: null,
    povertyLevel: null,
    povertyPercent: null,
    povertyPercentCutOff: null,
    memberCount: null,
    systemServiceProviderId: null,
    isdId: null,
    districtId: null,
    referralId: null,
    facilityName: null,
    systemServiceProviderName: null
  },
  serviceIsValid: true,
  matchedReferralId: null,
  transferRequest: {} as TransferReferralRequest
}

export const ChildContext = createContext<newChildRecord >(initialChild)
export const ChildDispatchContext = createContext<Dispatch<recordUpdate>>(() => null)

export function useChildContext (): newChildRecord {
  const ctx = useContext(ChildContext)
  return ctx
}

export function useChildUpdateDispatch (): Dispatch<recordUpdate> {
  return useContext(ChildDispatchContext)
}

export function ChildUpdateProvider (props: { children: JSX.Element }): JSX.Element {
  const [child, dispatch] = useReducer(childReducer, initialChild)
  return (
    <ChildContext.Provider value={child}>
      <ChildDispatchContext.Provider value={dispatch}>
        {props.children}
      </ChildDispatchContext.Provider>
    </ChildContext.Provider>
  )
}

function childReducer (newChild: newChildRecord, action: recordUpdate): newChildRecord {
  const record = newChild ?? { ...initialChild }
  switch (action.type) {
    case 'step': {
      return { ...record, step: action.step }
    }
    case 'serviceIsValid': {
      return { ...record, serviceIsValid: action.isValid, matchedReferralId: action.referralId }
    }
    case 'transferRequestChange': {
      return { ...record, transferRequest: action.data }
    }

    case 'loadDuplicate': {
      return {
        ...record,
        child: { ...action.child, birthGender: action.child.birthGender.charAt(0) },
        activeServices: action.data.childServices,
        isdID: action.data.isdID,
        isLoadedFromExistingChild: true,
        step: 6

      }
    }
    case 'child': {
      return { ...record, child: action.child }
    }
    case 'locations': {
      return { ...record, locations: action.locations }
    }
    case 'siblings': {
      return { ...record, siblings: action.siblings }
    }
    case 'matchSiblings': {
      const siblings = record.siblings.splice(0) ?? []
      let index = record.siblingIndex
      action.siblings.forEach(child => {
        if (siblings.find(v => v.sibling.id === child.id) != null) return
        const locations: location[] = child.childLocations ?? []
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if (locations.length === 0) locations.push({} as location)
        const newSibling = {
          index,
          sibling: {
            ...child,
            isLivingWithStudent: false,
            relationshipName: '',
            childLocations: locations
          } as ChildSiblings,
          edit: false,
          isNew: false,
          isLoadedRecord: true
        }
        siblings.push(newSibling)
        index++
      })
      return { ...record, siblings, siblingIndex: index }
    }
    case 'caregivers': {
      return { ...record, caregivers: action.caregivers }
    }
    case 'errors': {
      return { ...record, errors: action.errors }
    }
    case 'unSelectDuplicate':{
      return { ...record, isLoadedFromExistingChild: false, serviceSettings: {} as childServiceSettings }
    }
    case 'clear': {
      return { ...initialChild, locations: [{ edit: true, index: 0, location: { ...initialLocation }, isNew: true }] }
    }
    case 'service':{
      return { ...record, serviceSettings: action.serviceSettings }
    }
    case 'index': {
      switch (action.field) {
        case 'locations': {
          return { ...record, locationIndex: record.locationIndex + 1 }
        }
        case 'siblings': {
          return { ...record, siblingIndex: record.siblingIndex + 1 }
        }
        case 'caregivers':
        default: {
          return { ...record, caregiverIndex: record.caregiverIndex + 1 }
        }
      }
    }
    case 'district': {
      return { ...record, manualDistrict: action.manualDistrict, isdID: action.isdID }
    }
    case 'setDocumentTypes': {
      const docs = action.documentsTypes
      const newDocs: documentTypeModel[] = []
      docs.filter(d => d.documentTarget !== 'Adult').forEach(element => {
        const elm = { ...element }
        elm.documents = []
        newDocs.push(elm)
      })
      const cgCopies = [...record.caregivers]
      const cgDocs = docs.filter(d => d.documentTarget !== 'Child')

      cgCopies.forEach(careGiver => {
        const dtm = [...cgDocs]
        dtm.forEach(d => {
          d.documents = []
        })
        careGiver.caregiver.documents = dtm
      })
      return { ...record, childDocuments: newDocs, caregivers: cgCopies }
    }
    case 'updateChildDocuments': {
      const docs = [...action.documents]
      return { ...record, childDocuments: docs }
    }
    case 'updateCaregiverDocuments': {
      const docs = action.documents
      const index = action.index
      const cgCopies = [...record.caregivers]
      const careGiver = cgCopies[index]
      careGiver.caregiver.documents = docs
      return { ...record, caregivers: cgCopies } }
  }
}
