import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { sendGet } from '../../hooks/use-fetch'
import { TabDisplay } from '../../Components/TabDisplay'
import { ReferralFilterType, ReferralList } from './ReferralList'
import { type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { REFERRAL_MANAGEMENT } from '../Routes'

export function ReferalTabs (): JSX.Element {
  const { serviceId } = useParams()
  const nav = useNavigate()
  const [serviceName, setServiceName] = useState('')
  const [serviceDisabled, setServiceDisabled] = useState(false)
  const [directReferrals, setDirectReferrals] = useState<RowData[]>([])
  const [sentReferrals, setSentReferrals] = useState<RowData[]>([])
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState(0)
  const serviceProviderId = useAccountId()?.id

  const loadData = async (): Promise<void> => {
    if (serviceId === undefined || serviceProviderId === undefined) {
      return
    }

    const { response: serviceResp } = await sendGet(`/ServiceProviderDetails/GetService?spID=${serviceProviderId}&systemServiceId=${serviceId}`, {})

    if (serviceResp !== null) {
      setServiceName(serviceResp.name)
      setServiceDisabled(serviceResp.inactive)
    }

    const { response: serviceData } = await sendGet(`/ReferralSearch/ReferralsByService?serviceID=${serviceId}&providerId=${serviceProviderId}`, {})
    try {
      setDirectReferrals(serviceData)
    } catch (e) {
      setDirectReferrals([])
    }

    const { response: sentData } = await sendGet(`/ReferralSearch/DeniedReferralsByService?serviceID=${serviceId}&providerId=${serviceProviderId}`, {})
    try {
      setSentReferrals(sentData)
    } catch (e) {
      setSentReferrals([])
    }

    setLoading(false)
  }

  useEffect(() => {
    void loadData()
  }, [serviceId, serviceProviderId])

  if (loading) return <CircularProgress />

  return <Box>
    <Typography variant="h3">{serviceName} Referrals</Typography>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb">
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(REFERRAL_MANAGEMENT) }} >
        Referrals
      </Link>
    </Breadcrumbs>

    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
      <Tabs value={value} onChange={(e, val) => { setValue(val) }}>
        <Tab data-testid={'directTab'} label={`Direct (${directReferrals.length})`} value={0} />
        <Tab label={`Sent (${sentReferrals.length})`} value={1} />
      </Tabs>
    </Box>
    <TabDisplay index={0} value={value}>
      <ReferralList showAddButton={!serviceDisabled} rows={directReferrals} loading={loading} serviceID={serviceId} serviceProviderId={serviceProviderId} filterType={ReferralFilterType.Direct} onRefresh={loadData} />
    </TabDisplay>
    <TabDisplay index={1} value={value}>
      <ReferralList showAddButton={!serviceDisabled} rows={sentReferrals} loading={loading} serviceID={serviceId} serviceProviderId={serviceProviderId} filterType={ReferralFilterType.Sent} onRefresh={loadData} />
    </TabDisplay>
  </Box>
}
