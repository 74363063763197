import { Box, Button, CircularProgress } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { Column, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { type federalPovertyLevel } from '../../core/types'
import { sendGet, sendPost, sendPut } from '../../hooks/use-fetch'
import { usePagination, type PaginationProps } from '../../hooks/use-pagination'
import { FederalPovertyLevelDetails } from './FederalPovertyLevelDetails'

const yearColumn = new Column('year', 'Year')
const baselineColumn = new Column('baselineAmount', 'FPL Guideline Baseline')
const perPersonColumn = new Column('increasePerPerson', 'Increase Per Person')
const columns = [yearColumn, baselineColumn, perPersonColumn]

export function FederalPovertyLevelList (): JSX.Element {
  const [refreshTime, setRefreshTime] = useState(new Date())
  const initialFPL: federalPovertyLevel = { id: 0, year: new Date().getFullYear(), baselineAmount: null, increasePerPerson: null }
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [selectedFPL, setSelectedFPL] = useState<federalPovertyLevel | null>()
  const [open, setOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [fplList, setFPLList] = useState<federalPovertyLevel[]>([])
  const [loading, setLoading] = useState(true)

  const paginationProps: PaginationProps<RowData> = {
    rows: fplList
  }
  const pagination = usePagination(paginationProps)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: fplResponse } = await sendGet('/FederalPovertyLevel/GetAll', {})
      if (fplResponse !== null) {
        setFPLList(fplResponse)
      }
      setLoading(false)
    }
    void loadData()
  }, [refreshTime])

  const addClick = (): void => {
    setErrors(undefined)
    setSelectedFPL(initialFPL)
    setOpen(true)
    setIsNew(true)
  }
  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const selectedYear: federalPovertyLevel = {
      id: row.id,
      year: row.year,
      baselineAmount: row.baselineAmount,
      increasePerPerson: row.increasePerPerson
    }
    setErrors(undefined)
    setSelectedFPL(selectedYear)
    setOpen(true)
    setIsNew(false)
  }

  const handleSubmit = async (dateRangeData: federalPovertyLevel): Promise<void> => {
    setErrors(undefined)
    let rsp: { errors: string[], success: boolean, response: any }
    const url = `/FederalPovertyLevel/${isNew ? 'Insert' : 'Update'}`

    if (isNew) {
      const { response } = await sendPost(url, dateRangeData)
      rsp = response
    } else {
      const { response } = await sendPut(url, dateRangeData)
      rsp = response
    }
    if (rsp.success) {
      setOpen(false)
      setRefreshTime(new Date())
    } else {
      setErrors(rsp.errors)
    }
  }

  if (loading) return <CircularProgress />
  return <Box>
    <Box justifyContent='flex-end' display='flex' width='100%' >
      <Button color='secondary' variant='contained' onClick={addClick} data-test-add-fpl>Add Federal Poverty Level Values</Button>
    </Box>

    <DataTable
      page={pagination.page}

      data-test-table
      name='fplTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows ?? []}
      totalRecords={pagination.recordCount}
      loading={loading}
      onSortChange={(col, sorts) => {
        pagination.onSortChange(col, sorts[0]?.order)
      }}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      onRowClick={rowClick}
    />
    {open && selectedFPL != null && (
      <FederalPovertyLevelDetails
        onClose={() => { setOpen(false) }}
        onSubmit={handleSubmit}
        open={open}
        fpl={selectedFPL}
        errors={errors}
      />
    )
    }
  </Box>
}
