import { type EnrollmentDetails } from '../../../../core/types'
import { DataReconcile } from './DataReconcile'
import { FinalSummary } from './FinalSummary'

export interface FinalSummaryStepProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function FinalSummaryStep (props: FinalSummaryStepProps): JSX.Element {
  const caregiverForm = props.caregiverForm

  if (caregiverForm.currentSubStep === 1) {
    return <FinalSummary caregiverForm={caregiverForm} handleStep={props.handleStep} />
  } else if (caregiverForm.currentSubStep === 2) {
    return <DataReconcile caregiverForm={caregiverForm} handleStep={props.handleStep} />
  }

  return <></>
}
