import {
  MenuItem,
  Menu,
  Box,
  Button
} from '@mui/material'
import { type BoxProps } from '@mui/material/Box'
import { useState } from 'react'

export interface DropdownOption {
  name: string
  onClick?: () => void
  onClickAsync?: () => Promise<void>
}

type Props = BoxProps & {
  options: DropdownOption[]
  selectComponent: JSX.Element
  onOpenMenu?: () => void
}

export function Dropdown (props: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const { options, selectComponent, onOpenMenu, ...other } = props

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
    if (onOpenMenu != null) {
      onOpenMenu()
    }
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleOptionClick = async (option: DropdownOption): Promise<void> => {
    if (option.onClick != null) {
      option.onClick()
      handleClose()
    } else if (option.onClickAsync != null) {
      await option.onClickAsync()
      handleClose()
    }
  }

  return (
    <Box {...other}>
      <Button style={{ color: 'black', textTransform: 'none' }} onClick={handleClick} data-testid='dropdown-container'>
        {selectComponent}
      </Button>
      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {options.map((opt) => (
          <MenuItem key={opt.name} onClick={async () => { await handleOptionClick(opt) }} data-testid={'dropdown-option-' + opt.name}>
            {opt.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
