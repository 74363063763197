import { Box } from '@mui/material'
import { Modal } from '../../../Components/Modal'
import { type CommunicationSummary } from './CommunicationList'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { FormatDateTimeValue, SpaceBeforeCapitals } from '../../../core/Utilities'

export interface OtherNoteDisplayProps {
  summary: CommunicationSummary
  openModal: boolean
  onClose: () => void
}

export function OtherNoteDisplay (props: OtherNoteDisplayProps): JSX.Element {
  const typeName: string = SpaceBeforeCapitals(props.summary.type)
  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>{typeName}</span>
  </div>

  const content = <Box>
    <TextWithLabel
      label='Note'
      name='Note'
      value={props.summary.note}
    />
    <TextWithLabel
      label='Entered On'
      name='enteredOn'
      value={FormatDateTimeValue(props.summary.enteredOn)}
    />
    <TextWithLabel
      label='Entered By'
      name='enteredBy'
      value={props.summary.userName}
    />
    {props.summary.service !== null && <TextWithLabel
      label='Service'
      name='service'
      value={props.summary.service}
    />
    }
    {props.summary.serviceProvider !== null && <TextWithLabel
      label='Service Provider'
      name='serviceProvider'
      value={props.summary.serviceProvider}
    />
    }
    {props.summary.timeFrame !== null && <TextWithLabel
      label='Time Frame'
      name='timeFrame'
      value={props.summary.timeFrame}
    />
    }
    {props.summary.program !== null && <TextWithLabel
      label='Program'
      name='program'
      value={props.summary.program}
    />
    }
  </Box>

  return <Modal
    open={props.openModal}
    hideCancelButton={true}
    confirmButtonText='Close'
    titleContent={titleContent}
    confirmationContent={content}
    onClose={props.onClose}
    onConfirm={props.onClose}
  />
}
