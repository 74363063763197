import { Box, Typography } from '@mui/material'
import { CheckBoxWithLabel } from '../CheckBox'

interface ChildAdditionalInfoListProps {
  values: AdditionalValues
  setAdditionalValues: (values: AdditionalValues) => void
}

export interface AdditionalValues {
  hasSNAPBenefits: boolean
  isFosterChild: boolean
  isOnCashAssistance: boolean
  isOnSSI: boolean
}

export function ChildAdditionalInfoList (props: ChildAdditionalInfoListProps): JSX.Element {
  const valueChanged = (field: string, value: boolean): void => {
    props.setAdditionalValues({ ...props.values, [field]: value })
  }

  return <Box display={'block'}>
    <Typography className="info-box-header">Additional Values</Typography>

    <CheckBoxWithLabel label='SNAP Benefits' name='hasSNAPBenefits' edge='start' value={props.values.hasSNAPBenefits ?? false} setValue={(val) => { valueChanged('hasSNAPBenefits', val) }} />
    <CheckBoxWithLabel label='Foster Child' name='isFosterChild' edge='start' value={props.values.isFosterChild ?? false} setValue={(val) => { valueChanged('isFosterChild', val) }} />
    <CheckBoxWithLabel label='Cash Assistance' name='isOnCashAssistance' edge='start' value={props.values.isOnCashAssistance ?? false} setValue={(val) => { valueChanged('isOnCashAssistance', val) }} />
    <CheckBoxWithLabel label='SSI (Supplemental Security Income)' name='isOnSSI' edge='start' value={props.values.isOnSSI ?? false} setValue={(val) => { valueChanged('isOnSSI', val) }} />
  </Box>
}
