import { useEffect, useState } from 'react'
import { type PortalChildSummary, type PortalCaregiverSummary } from '../../../core/types'
import { sendGet } from '../../../hooks/use-fetch'
import { LargeCard } from '../../../Components/Cards/Large'
import { Box, Button, Container, Typography } from '@mui/material'
import { childAge, childName } from '../PortalHelpers'
import { BackArrow } from '../../../Assets/SVGs/back-arrow'
import { generatePath, useNavigate } from 'react-router'
import { CAREGIVER_PORTAL_DASHBOARD, CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS, CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS, CAREGIVER_PORTAL_MEMBERS_EDIT_CAREGIVER, CAREGIVER_PORTAL_MEMBERS_EDIT_CHILD } from '../../Routes'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

export function CaregiverMembers (): JSX.Element {
  const nav = useNavigate()
  const [children, setChildren] = useState<PortalChildSummary[]>([])
  const [caregivers, setCaregivers] = useState<PortalCaregiverSummary[]>([])

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const { response, success } = await sendGet('/CaregiverPortal/GetRelatedMembers', {})
      if (success) {
        setChildren(response.children)
        setCaregivers(response.caregivers)
      }
    }

    void getData()
  }, [])

  const addChild = (): void => {
    const route = generatePath(CAREGIVER_PORTAL_MEMBERS_EDIT_CHILD, { childId: 'new' })
    nav(route)
  }

  const addCaregiver = (): void => {
    const route = generatePath(CAREGIVER_PORTAL_MEMBERS_EDIT_CAREGIVER, { caregiverId: 'new' })
    nav(route)
  }

  const viewChild = (childId: string): void => {
    const route = generatePath(CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS, { childId })
    nav(route)
  }

  const viewCaregiver = (caregiverId: string): void => {
    const route = generatePath(CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS, { caregiverId })
    nav(route)
  }

  return <Container sx={{ mb: '30px' }}>
    <Box sx={{ mt: '30px', mb: '30px', display: 'flex' }}>
      <Box onClick={() => { nav(CAREGIVER_PORTAL_DASHBOARD) }} sx={{ cursor: 'pointer' }}>
        <BackArrow />
      </Box>
      <Typography variant='h5' sx={{ fontWeight: 600, ml: '5px' }} component='div'>
        Members
      </Typography>
    </Box>

    {children.map((child) => {
      return <LargeCard
        key={child.id}
        fullWidth={true}
        className='member-card'
        content={<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Box sx={{ fontWeight: '600' }}>{childName(child)}</Box>
            <Box>{childAge(child)}</Box>
          </Box>
          <KeyboardArrowRightIcon sx={{ width: '50px', height: '50px', cursor: 'pointer' }} onClick={() => { viewChild(child.id.toString()) }} />
        </Box>}
      />
    })}

    <Button
      variant='contained'
      className='footer-button'
      sx={{ mt: '20px' }}
      name='addChildButton'
      onClick={addChild}
    >
      Add Child
    </Button>

    {caregivers.map((caregiver) => {
      return <LargeCard
        key={caregiver.id}
        fullWidth={true}
        className='member-card'
        content={<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Box sx={{ fontWeight: '600' }}>{`${caregiver.firstName} ${caregiver.lastName}`}</Box>
            <Box>{caregiver.phoneNumber}</Box>
            <Box>{caregiver.email}</Box>
          </Box>
          <KeyboardArrowRightIcon sx={{ width: '50px', height: '50px', cursor: 'pointer' }} onClick={() => { viewCaregiver(caregiver.id.toString()) }} />
        </Box>}
      />
    })}

    <Button
      variant='contained'
      className='footer-button'
      sx={{ mt: '20px' }}
      name='addCaregiverButton'
      onClick={addCaregiver}
    >
      Add Caregiver
    </Button>
  </Container>
}
