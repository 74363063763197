import { Box, Switch } from '@mui/material'

export interface functionType {
  nodeName: string
  readPermissionName?: string
  writePermissionName?: string
  readImplicit: boolean
  writeImplicit: boolean
  subPermissions: functionType[]
}

interface Props {
  optionsList: functionType[]
  selectedValues: string[]
  headers: JSX.Element[]
  setSelectedValues: (newValue: string[]) => void
  forceReadOnly?: boolean
}

export function HierarchyCheckList (props: Props): JSX.Element {
  const overall: JSX.Element[] = []

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    let newVal: string[] = []
    if (checked) {
      newVal.push(...props.selectedValues, e.target.name)
      const namePieces = e.target.name.split('_')
      if (namePieces[namePieces.length - 1] === 'Write' || namePieces[namePieces.length - 1] === 'Edit') {
        namePieces.pop()
        newVal.push(namePieces.join('_'))
      }
    } else {
      newVal = props.selectedValues.filter(v => !v.startsWith(e.target.name))
    }
    props.setSelectedValues(newVal)
  }

  if (props.optionsList !== null) { generateRow(props.optionsList, 0) }

  return <table width={ '100%' }>
    <thead>
      <tr>
        {props.headers.map(h => {
          return <td key={h.props.labelText}>{h}</td>
        })}
      </tr>
    </thead>

    <tbody>
      {overall.map(f => { return f })}
    </tbody>
  </table>

  function generateRow (funcs: functionType[], indents: number): void {
    if (funcs !== null && funcs !== undefined && funcs.length > 0) {
      funcs.forEach(e => {
        const readExists = e.readPermissionName !== null
        const writeExists = e.writePermissionName !== null
        const key = readExists ? e.readPermissionName : e.writePermissionName
        const forceRead = props.forceReadOnly === true

        const readButton = <td>
          {readExists && (
            <Switch
              key={e.readPermissionName}
              name={e.readPermissionName}
              disabled={e.readImplicit || forceRead}
              checked={props.selectedValues.find(v => v === e.readPermissionName) !== undefined}
              onChange={handleChange}
            />
          )}
        </td>

        const writeButton = <td>
          {writeExists && (
            <Switch
              key={e.writePermissionName}
              name={e.writePermissionName}
              disabled={e.writeImplicit || forceRead}
              checked={props.selectedValues.find(v => v === e.writePermissionName) !== undefined}
              onChange={handleChange}
            />
          )}
        </td>
        overall.push(<tr key={(key?.toString() ?? '') + 'row'} data-testid={e.nodeName + 'CheckBoxes'}>
          <Box component={'td'} sx={{ pl: 4 * indents }}>{e.nodeName}</Box>
          {readButton}
          {writeButton}
        </tr>)
        generateRow(e.subPermissions, indents + 1)
      })
    }
  }
}
