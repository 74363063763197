import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { generatePath, useParams } from 'react-router'
import { SessionHeader } from './SessionHeader'
import { useEffect, useState } from 'react'
import { TabDisplay } from '../../../Components/TabDisplay'
import {
  SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION,
  SERVICE_PROVIDER_ADMIN_SERVICES,
  SERVICE_PROVIDER_ADMIN_SESSION_BLOCK
} from '../../Routes'
import { sendGet } from '../../../hooks/use-fetch'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'
import { SessionDetailsDisplay } from './SessionDetailsDisplay'
import { SessionEnrollmentList } from './SessionEnrollmentList'

export function SessionTabs (): JSX.Element {
  const { blockId, serviceId, sessionId } = useParams()
  const [serviceName, setServiceName] = useState('')
  const [sessionName, setSessionName] = useState<string>('')
  const [sessionBlockName, setSessionBlockName] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [value, valueChange] = useState(0)
  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (sessionId !== 'new') {
        const resp = await sendGet(`/SessionList/Get?sessionId=${sessionId ?? ''}`, {})
        if (resp.success) {
          setSessionName(resp.response.name)
        }
      }

      const serviceResp = await sendGet(`/SessionList/GetService?serviceId=${serviceId ?? ''}`, {})

      if (serviceResp.success) {
        setServiceName(serviceResp.response.name)
      }

      const blockresp = await sendGet(`/SessionList/GetBlock?blockId=${blockId ?? ''}`, {})
      if (blockresp.success) {
        setSessionBlockName(blockresp.response.name)
      }
      setIsLoaded(true)
    }
    void loadData()
  }, [blockId, serviceId, sessionId])

  const breadCrumbs = [
    {
      path: generatePath(SERVICE_PROVIDER_ADMIN_SERVICES, {
      }),
      text: 'Services'
    },
    {
      path: generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK, {
        serviceId: serviceId ?? ''
      }),
      text: serviceName
    },
    {
      path: generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION, {
        serviceId: serviceId ?? '',
        blockId: blockId ?? ''
      }),
      text: sessionBlockName
    }

  ]
  if (!isLoaded) return <CircularProgress />
  return <Box sx={{ width: '100%' }}>
    <ErrorProvider>
      <>
        <SessionHeader
          breadCrumbs={breadCrumbs}
          cards={<></>}
          title={sessionName ?? ''}
        />

        <Tabs value={value} onChange={(e, val) => { valueChange(val) }} aria-label="Session Tabs" data-testid="tabsHeading"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Details" value={0}/>
          <Tab label="Enrollments" value={1}/>
        </Tabs>
        <TabDisplay index={0} value={value}>
          <SessionDetailsDisplay/>
        </TabDisplay>
        <TabDisplay index={1} value={value}>
          <SessionEnrollmentList sessionId={Number(sessionId ?? 0)} />
        </TabDisplay>
      </>
    </ErrorProvider>
  </Box>
}
