import { Box, Button, CircularProgress, Chip } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { FileTypeDetails } from './FileTypeDetails'
import { type fileType } from '../../core/types'
import { sendGet, sendPost, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import { type PaginationProps, usePagination } from '../../hooks/use-pagination'
import { FormatBoolean } from '../../core/Utilities'

const nameColumn = new Column('name', 'Name')
const documentColumn = new Column('documentTarget', 'Document Target')
const statusColumn = new Column('inactive', 'In Use', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  let color = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  let label = 'Active'
  if (row?.inactive as boolean) {
    color = '#D4316C !important'
    label = 'Inactive'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={label} size="small" sx={{ color, backgroundColor }} />
}
const iconColumn = new Column('image', 'Icon', ColumnType.CUSTOM)
iconColumn.customCellGenerator = row => {
  if (row.s3Key != null) {
    const imageurl: string = row.s3Key.toString()

    return <div><img src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${imageurl}`} className="tbl-row-img"
      style={{ maxWidth: '100px' }}
      alt='logo'
      data-testid={'logo-' + imageurl}
    /></div>
  } else {
    return <div></div>
  }
}
const incomeColumn = new Column('incomeRelated', 'Income Related', ColumnType.CUSTOM)
incomeColumn.customCellGenerator = row => {
  return <div>{FormatBoolean(row.incomeRelated)}</div>
}

const columns = [nameColumn, statusColumn, iconColumn, documentColumn, incomeColumn]

export function FileTypeList (): JSX.Element {
  const [filterDate, setFilterDate] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const userUrl = '/DocumentType'
      const { response: userInfos } = await sendGet(userUrl + '/GetAll', {})
      try {
        const data = userInfos
        setRows(data)
      } catch (e) {
        setRows([])
      }
      setLoading(false)
    }
    void fetchData()
  }, [filterDate])

  const paginationProps: PaginationProps<RowData> = {
    rows,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)
  const initialFileType: fileType = { id: 0, name: '', inactive: false, documentTarget: '', s3Key: null, incomeRelated: false }
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [selectedFileType, setSelectedFileType] = useState<fileType>(initialFileType)
  const [open, setOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)

  const addClick = (): void => {
    const selectedFileType: fileType = {
      id: 0, name: '', inactive: false, documentTarget: '', s3Key: null, incomeRelated: false
    }
    setSelectedFileType(selectedFileType)
    setOpen(true)
    setIsNew(true)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const selectedFileType: fileType = {
      id: row.id,
      name: row.name,
      inactive: row.inactive,
      documentTarget: row.documentTarget,
      description: row.description,
      s3Key: row.s3Key,
      incomeRelated: row.incomeRelated
    }
    setSelectedFileType(selectedFileType)
    setOpen(true)
    setIsNew(false)
  }

  const handleSubmit = async (fileTypeData: fileType, file: File | null): Promise<void> => {
    if (!loading) {
      setLoading(true)

      setErrors(undefined)
      let recordID
      let err: string[] | null = null
      let suc = false
      const url = `/DocumentType/${isNew ? 'Insert' : 'Update'}`

      if (isNew) {
        const { response, error, success } = await sendPost(url, fileTypeData)

        recordID = response
        err = error
        suc = success
      } else {
        const { error, success } = await sendPut(url, fileTypeData)
        recordID = fileTypeData.id
        err = error
        suc = success
      }

      if (suc && file != null) {
        const fileUrl = '/DocumentType/UploadFile'
        const fileData = new FormData()
        fileData.append('id', recordID)
        fileData.append('file', file)
        const { error, success } = await sendPostFormData(fileUrl, fileData)
        err = error
        suc = success
      }
      if (suc) {
        setFilterDate(new Date())
        setOpen(false)
      } else {
        setLoading(false)
        if (err.length > 0) { setErrors(err) }
      }
    }
  }

  if (loading) { return <CircularProgress role='loading' /> } else {
    return <Box>
      <Box justifyContent='flex-end' display='flex' width='100%' >
        <Button color='secondary' variant='contained' onClick={addClick} data-testid='add-document-type'>Add Document Type</Button>
      </Box>

      <DataTable
        page={pagination.page}
        name='fileTypeTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
        initialColumnSorts={[new ColumnSort('name')]}
      />
      {open && (
        <FileTypeDetails
          onClose={() => { setOpen(false) }}
          onSubmit={handleSubmit}
          open={open}
          fileType={selectedFileType}
          errors={errors}
        />
      )}
    </Box>
  }
}
