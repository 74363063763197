import { type ChangeEvent, useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { Box, Button, CircularProgress } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { type PaginationProps, usePagination } from '../../hooks/use-pagination'
import { Column, ColumnType } from '../../Components/Table/DataTable/DataTable.model'
import { FormatDateTimeValue } from '../../core/Utilities'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { TextAreaWithLabel } from '../../Components/TextArea'

export interface ReferralNotesProps {
  referralID: number
  isNotesPermission: boolean
  isEditable: boolean
}

interface ReferralNote {
  id: number
  referralID: number
  note: string
  createdDate: Date
  userID: number
  userName: string
  isReminder: boolean
}

export function ReferralNotes (props: ReferralNotesProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const [notes, setNotes] = useState<ReferralNote[]>([])
  const [showNoteEdit, setShowNoteEdit] = useState(false)
  const [editingNote, setEditingNote] = useState<ReferralNote | undefined>()
  const [refreshTime, setRefreshTime] = useState(new Date())

  useEffect(() => {
    async function getData (): Promise<void> {
      const request = await sendGet(`/ReferralEdit/ReferralNotes?referralID=${props.referralID}`, {})
      const referralNotes: ReferralNote[] = request.response
      setNotes(referralNotes)

      setLoaded(true)
    }
    void getData()
  }, [refreshTime])

  const showNoteAddBox = (): void => {
    const record: ReferralNote = {
      id: 0,
      referralID: props.referralID,
      note: '',
      createdDate: new Date(),
      userID: 0,
      userName: '',
      isReminder: false
    }
    setEditingNote(record)
    setShowNoteEdit(true)
  }

  const handleNoteAdd = async (): Promise<void> => {
    if (editingNote !== undefined) {
      const { response } = await sendPost('/ReferralEdit/AddNote', editingNote)
      if (response !== undefined) {
        setRefreshTime(new Date())
        setShowNoteEdit(false)
      }
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (editingNote !== undefined) {
      setEditingNote({ ...editingNote, [e.target.name]: e.target.value })
    }
  }

  const getEditNoteContent = (): any => {
    if (editingNote === undefined) {
      return <></>
    } else {
      return <Box sx={{ margin: '20px' }}>
        <TextAreaWithLabel
          name="Note"
          label="Note"
          data-testid="Note"
          value={editingNote.note}
          onChange={handleChange}
          dataTestId='note'
        />
      </Box>
    }
  }

  const noteColumn = new Column('note', 'Note', ColumnType.CUSTOM)
  noteColumn.customCellGenerator = row => {
    if (row.isReminder === true) {
      return <div>Reminder for {row.userName} on {FormatDateTimeValue(row.reminderDate)} </div>
    } else {
      return <div>{row.note}</div>
    }
  }

  const aboutColumn = new Column('createdDate', 'About', ColumnType.CUSTOM)
  aboutColumn.customCellGenerator = row => {
    return <div>Added by {row.userName} on {FormatDateTimeValue(row.createdDate)} </div>
  }

  const columns = [noteColumn, aboutColumn]

  const paginationProps: PaginationProps<ReferralNote> = {
    rows: notes,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  if (!loaded) return <CircularProgress role='loading' />

  if (!props.isNotesPermission) {
    return <div>You do not have access to this feature</div>
  }

  return <>
    {props.isEditable && <Box >
      <Button color='secondary' variant='contained' onClick={showNoteAddBox} data-testid='add-note'>Add Note</Button>
    </Box>
    }
    <DataTable
      page={pagination.page}
      name='referralNoteTable'
      data-testid='referralNoteTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows}
      rowClickable={false}
      sortable={false}
      totalRecords={pagination.recordCount}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    />

    <BannerModal
      className='Nested Referral'
      maxWidth='sm'
      open={showNoteEdit}
      content={getEditNoteContent()}
      onClose={() => { setShowNoteEdit(false) }}
      onConfirm={handleNoteAdd}
      title='New Referral Note'
      cancelButtonText='Cancel'
      confirmButtonText='Save'
    />
  </>
}
