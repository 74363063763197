import { Button, CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { ReferralSearch, type ReferralSearchCriteria } from './ReferralSearch'
import { FormatDateValue } from '../../core/Utilities'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { SentSearch, type SentSearchCriteria } from './SentSearch'
import { ReferralDetail } from './ReferralDetail'
import { AddChildModal } from './AddChildModal/AddChildModal'
import { ErrorProvider } from '../../ContextProviders/ErrorProvider'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import S3Image from '../../Components/S3Image'

const nameColumn = new Column('childName', 'Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = row => {
  if (row.S3Key != null) {
    return <div>
      <S3Image
        imageKey={row.s3Key}
        alt='child image'
        style={{ maxWidth: '100px' }}
      />
      <span>{row.childName}</span>
    </div>
  } else {
    return <div>{row.childName}</div>
  }
}

const age1Column = new Column('age', 'Age')
const age2Column = new Column('ageOn91', 'Age on 9/1')

const dobColumn = new Column('dateOfBirth', 'DOB', ColumnType.CUSTOM)
dobColumn.customCellGenerator = row => {
  return <div>{FormatDateValue(row.dateOfBirth)} </div>
}

const genderColumn = new Column('gender', 'Gender')
const timeFrameColumn = new Column('timeFrameDisplay', 'Time Frame', ColumnType.CUSTOM)
timeFrameColumn.customCellGenerator = row => {
  return <div>{row.timeFrameDisplay === 'Enter a Specific date' ? FormatDateValue(row.timeFrameCustomDate) : row.timeFrameDisplay} </div>
}
const residenceColumn = new Column('residence', 'Residence')

const povertyPercentColumn = new Column('povertyPercent', 'Poverty %', ColumnType.CUSTOM)
povertyPercentColumn.customCellGenerator = row => {
  return <div>{row.povertyPercent == null ? '' : `${String(row.povertyPercent)}%`} </div>
}
const facilityColumn = new Column('requestedFacility', 'Facility')
const programColumn = new Column('programName', 'Program', ColumnType.CUSTOM)
programColumn.customCellGenerator = row => {
  return <div>{row.programName != null ? row.programName : 'Program Missing'} </div>
}
const viewedColumn = new Column('beenViewed', 'Been Viewed')
viewedColumn.visibility = false

const recentActionColumn = new Column('actionInLast30Days', 'Recent Action')
recentActionColumn.visibility = false

const columns = [nameColumn, age1Column, age2Column, dobColumn, timeFrameColumn, genderColumn,
  residenceColumn, facilityColumn, povertyPercentColumn, programColumn, viewedColumn, recentActionColumn]

export enum ReferralFilterType {
  Direct,
  Sent
}

export interface ReferralListProps {
  serviceID: string | undefined
  serviceProviderId: number | undefined
  loading: boolean
  filterType: ReferralFilterType
  rows: RowData[]
  showAddButton?: boolean | true
  onRefresh: () => void
}

export function ReferralList (props: ReferralListProps): JSX.Element {
  const [directSearchData, setDirectSearchData] = useState<ReferralSearchCriteria>({
    timeFrameDisplay: '',
    status: '',
    childName: '',
    facility: '',
    serviceID: Number(props.serviceID),
    serviceProviderId: Number(props.serviceProviderId)
  })
  const [sentSearchData, setSendSearchData] = useState<SentSearchCriteria>({ timeFrameDisplay: '', actionInLast30Days: false })
  const [selectedReferralID, setSelectedReferralID] = useState<number | null>(null)
  const [manualReferEnabled, setManualReferEnabled] = useState<boolean>(false)
  const [selectedReferralChildId, setSelectedReferralChildId] = useState<number>(0)
  const [open, setOpen] = useState(false)
  const [showAddChild, setShowAddChild] = useState(false)
  const { serviceId } = useParams()

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedReferralID(row.id)
    setSelectedReferralChildId(row.childID)
    setManualReferEnabled(row.selectProviderOnReferralDecline)
    setOpen(true)
  }

  const paginationProps: PaginationProps<RowData> = {
    rows: props.rows,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  if (props.loading) return <CircularProgress />

  const handleDirectSearch = (newdata: ReferralSearchCriteria): void => {
    setDirectSearchData(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.childName !== '') {
      fieldArray.push({
        field: 'childName',
        value: newdata.childName
      })
    }
    if (newdata.timeFrameDisplay !== '' && newdata.timeFrameDisplay !== 'both') {
      fieldArray.push({
        field: 'timeFrameDisplay',
        value: newdata.timeFrameDisplay
      })
    }
    if (newdata.status !== '' && newdata.status !== 'both') {
      fieldArray.push({
        field: 'status',
        value: newdata.status === 'inProgress' ? 'Pending' : 'Waiting'
      })
    }
    if (newdata.facility !== '' && newdata.facility !== 'showAll') {
      fieldArray.push({
        field: 'requestedFacility',
        value: newdata.facility.replaceAll(' ', '')
      })
    }
    pagination.setSearchFields(fieldArray)
  }

  const handleSentSearch = (newdata: SentSearchCriteria): void => {
    setSendSearchData(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.timeFrameDisplay !== '' && newdata.timeFrameDisplay !== 'both') {
      fieldArray.push({
        field: 'timeFrameDisplay',
        value: newdata.timeFrameDisplay
      })
    }
    if (newdata.actionInLast30Days) {
      fieldArray.push({
        field: 'actionInLast30Days',
        value: 'true'
      })
    }
    pagination.setSearchFields(fieldArray)
  }

  if (props.loading) return <CircularProgress />
  const addButton = <Button data-testid="addReferralButton" variant='contained' color='secondary' onClick={() => { setShowAddChild(true) }}>Add Child Referral</Button>

  return (<>
    {props.filterType === ReferralFilterType.Direct && (props.showAddButton ?? true) && <ReferralSearch current={directSearchData} onChange={handleDirectSearch} addButton={addButton}/>}
    {props.filterType === ReferralFilterType.Direct && !(props.showAddButton ?? true) && <ReferralSearch current={directSearchData} onChange={handleDirectSearch} />}
    {props.filterType === ReferralFilterType.Sent && <SentSearch current={sentSearchData} onChange={handleSentSearch} />}
    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          page={pagination.page}
          name='referralTable'
          data-testid='referralTable'
          columns={columns}
          rows={pagination.internalRows}
          totalRecords={pagination.recordCount}
          loading={props.loading}
          onSortChange={(col, sorts) => {
            pagination.onSortChange(col, sorts[0].order)
          }}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          onRowClick={rowClick}
          initialColumnSorts={[new ColumnSort('childName')]}
          hasCheckbox={false}
        />
      </Box>

      <AddChildModal
        serviceID={Number(serviceId)}
        endpoint='ReferralEdit'
        serviceProviderEndpoint='ServiceProviderDetails'
        open={showAddChild}
        onClose={() => { setShowAddChild(false) }}
        onSubmit={(childID: number, referralID: number) => {
          if (childID === 0 && referralID === 0) {
            toast.success('Transfer request submitted.')
          } else if (referralID > 0) {
            toast.success('A referral was created and sent to the provider.')
          } else {
            toast.error('An error occurred while creating the referral.')
          }

          setShowAddChild(false)
          props.onRefresh()
        }}
      />

      {open && selectedReferralID !== null &&
        <ErrorProvider>
          <ReferralDetail
            onClose={() => { setOpen(false) }}
            onRefresh={props.onRefresh}
            open={open}
            referralID={selectedReferralID}
            manualReferEnabled={manualReferEnabled}
            childID={selectedReferralChildId}
            serviceID={Number(serviceId)}
            hideAccept={props.filterType === ReferralFilterType.Sent}
            hideDeny={props.filterType === ReferralFilterType.Sent}
          />
        </ErrorProvider>
      }
    </div>
  </>)
}
