import { Grid } from '@mui/material'
import { TextFieldWithLabel } from '../../Components/TextField'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { sendGet } from '../../hooks/use-fetch'
import { useEffect, useState } from 'react'

export interface ReferralSearchCriteria {
  timeFrameDisplay: string
  status: string
  childName: string
  facility: string
  serviceID: number
  serviceProviderId: number
}

export function ReferralSearch (props: { current: ReferralSearchCriteria, onChange: (newSearch: ReferralSearchCriteria) => void, addButton?: JSX.Element }): JSX.Element {
  const [facilityOptions, setFacilityOptions] = useState([])

  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }

  const timeFrameOptions: Array<{ id: string, name: string }> = [
    { id: 'immediate', name: 'Immediate' },
    { id: 'upcomingSchoolYear', name: 'Upcoming School Year' },
    { id: 'both', name: 'Both' }
  ]

  const statusOptions: Array<{ id: string, name: string }> = [
    { id: 'new', name: 'New' },
    { id: 'inProgress', name: 'In Progress' },
    { id: 'both', name: 'Both' }
  ]

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet(`/ReferralSearch/GetFacilityOptions?serviceID=${props.current.serviceID}&serviceProviderId=${props.current.serviceProviderId}`, {})
      if (response !== undefined && response.length > 0) {
        response.push({ id: 'showAll', name: 'Show All' })
        setFacilityOptions(response)
      }
    }
    void fetchData()
  }, [props.current.serviceID])

  return (<Grid item sm={8} md={10} lg={11} container spacing={1} rowGap={0} rowSpacing={-1} >
    <Grid item>
      <SelectWithLabel
        name="timeFrameDisplay"
        label="Time Frame"
        width='200px'
        value={props.current.timeFrameDisplay}
        options={timeFrameOptions}
        onChange={handleChange}
      />
    </Grid>
    <Grid item>
      <SelectWithLabel
        name="status"
        label="Status"
        width='150px'
        value={props.current.status}
        options={statusOptions}
        onChange={handleChange}
      />
    </Grid>
    <Grid item>
      <TextFieldWithLabel
        label='Child Name'
        onChange={handleChange}
        name='childName'
        maxWidth='205px'
        value={props.current.childName}
        data-test-name-search
      />
    </Grid>
    <Grid item>
      <SelectWithLabel
        name="facility"
        label="Facility"
        width='200px'
        value={props.current.facility}
        options={facilityOptions}
        onChange={handleChange}
      />
    </Grid>
    <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
      {props.addButton}
    </Grid>
  </Grid>
  )
}
