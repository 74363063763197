import { Box } from '@mui/material'
import AlertMessage from '../AlertMessage'
import { DocumentAccordion } from './DocumentAccordion'
import { type documentEditModel, type documentTypeModel } from '.'
import { type income } from '../../core/types'

interface DocumentListProps {
  documentTypes: documentTypeModel[]
  incomeRecords: income[] | null
  editable: boolean
  showDisclaimer: boolean
  canVerify: boolean
  onEdit?: (item: documentEditModel) => Promise<void>
  onDelete: (id: number, documentTypeID: number, index: number) => Promise<boolean>
  onVerify: (documentTypeID: number, index: number, verified: boolean) => Promise<boolean>
  onUpload: (documentTypeID: number, file: File, year: number | null) => Promise<boolean>
  onDownload?: (id: number) => Promise<boolean>
}

export function DocumentListEdit (props: DocumentListProps): JSX.Element {
  const documentList = props.documentTypes

  return <Box width={'100%'} marginTop={ '10px' }>
    {props.showDisclaimer && documentList.length > 0 && (
      <AlertMessage type={'warning'} message={'Please help keep personal information safe and secure by redacting SSNs and other identifying information'} dismissible={true} />
    )}

    {documentList.map((d, i) => {
      const handleDelete = async (id: number, index: number): Promise<void> => {
        await props.onDelete(id, d.documentTypeID, index)
      }

      const handleEdit = async (item: documentEditModel | null): Promise<void> => {
        if (item !== null && props.onEdit !== undefined) {
          await props.onEdit(item)
        }
      }

      const handleVerifiedChanged = async (index: number, verified: boolean): Promise<void> => {
        await props.onVerify(d.documentTypeID, index, verified)
      }

      const handleFileUploaded = async (file: File, year: number | null): Promise<void> => {
        await props.onUpload(d.documentTypeID, file, year)
      }

      return <Box sx={{ marginBottom: '20px', marginTop: '20px' }} key={i}>
        <DocumentAccordion
          documentType={d}
          editable={props.editable}
          canVerify={props.canVerify}
          onDelete={async (id, index) => { await handleDelete(id, index) }}
          onEdit={props.onEdit === undefined ? undefined : async (d) => { await handleEdit(d) }}
          onVerifiedChanged={handleVerifiedChanged}
          uploadDocument={handleFileUploaded}
          downloadDocument={props.onDownload}
          incomeRecords={(d.incomeRelated ? props.incomeRecords : null)}
        />
      </Box>
    })}
    {documentList.length === 0 && <AlertMessage type={'error'} message={'No document types are configured for this ISD'} dismissible={false} />}
  </Box>
}
