interface jpgProps {
  fill?: string
  size?: number
}

export function WarningCircle (props: jpgProps): JSX.Element {
  const fill = props.fill ?? 'inherit'
  const size = props.size ?? 24
  return <svg width={size} height={size} viewBox='0 0 24 24' fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
  </svg>
}
