import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Chip, CircularProgress, Button, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useState, useEffect } from 'react'
import userIcon from '../../Assets/Icons/el_adult.png'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { Role, type postError, type userAuthorizedFunction } from '../../core/types'
import { sendGet, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import { UserManagementDetail } from './UserManagementDetail'
import { UserManagementSearch, type UserSearchCriteria } from './UserManagementSearch'
import './index.css'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { toast } from 'react-toastify'
import { useErrorDispatch } from '../../ContextProviders/ErrorProvider'
import { useAuth } from '../../hooks/use-auth'
import { usePermissions } from '../../hooks/use-permissions'

export interface UserInfo extends RowData {
  id: number
  firstName: string
  lastName: string
  fullName: string
  isDs: Array<{ id: number, name: string }>
  securityLevel: Role
  serviceProviders: Array<{ id: number, name: string }>
  inactive: boolean
  email: string
  userAuthorizedFunctions: userAuthorizedFunction[]
}

const nameColumn = new Column('firstName', 'User Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = row => {
  const imagePath: string = row.imageKey
  const src = imagePath != null ? `${process.env.REACT_APP_SERVER_URL ?? ''}/File/${imagePath}` : userIcon

  return <div className="d-flex f-align-items-center">
    <img src={src} className="tbl-row-img"
      style={{ maxWidth: '100px' }}
      alt='logo'
      data-testid={'logo-' + imagePath}

    />
    <span>{row.firstName} {row.lastName}</span>
  </div>
}

const isdColumn = new Column('isd', 'Organization(s)', ColumnType.CUSTOM)
isdColumn.customCellGenerator = row => {
  const dat = row as UserInfo
  if (dat.isDs != null && dat.isDs.length > 0) {
    return <div className="d-flex f-align-items-center">
      <span>{dat.isDs?.map(i => {
        return <p key={i.id}>{i.name}</p>
      })}</span>
    </div>
  } else if (dat.serviceProviders != null && dat.serviceProviders.length > 0) {
    return <div className="d-flex f-align-items-center">
      <span>{dat.serviceProviders?.map(i => {
        return <p key={i.id}>{i.name}</p>
      })}</span>
    </div>
  } else {
    return <></>
  }
}

isdColumn.sortable = false

const roleColumn = new Column('securityLevel', 'Role', ColumnType.CUSTOM)
roleColumn.customCellGenerator = row => {
  let color = '#FF0000'
  let backgroundColor = 'rgba(255, 0, 0, 0.1)'
  if (row.securityLevel === Role.ISD_USER) {
    color = '#4DA4A4'
    backgroundColor = 'rgba(77, 164, 164, 0.1)'
  } else if (row.securityLevel === Role.SERVICE_PROVIDER_USER) {
    color = '#9D25C8'
    backgroundColor = 'rgba(157, 37, 200, 0.1)'
  } else if (row.securityLevel === Role.CARE_GIVER) {
    color = '#FFA500'
    backgroundColor = 'rgba(255, 165, 0, 0.1)'
  }
  return <Chip label={row.securityLevel} size="small" sx={{ color, backgroundColor }} />
}

const statusColumn = new Column('inactive', 'Status', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  let color = '#D4316C !important'
  let backgroundColor = 'rgba(212, 49, 108, 0.1)'
  let text = 'Deactivated'
  if (row.inactive == null || row.inactive === false) {
    color = '#4DA4A4 !important'
    backgroundColor = 'rgba(77, 164, 164, 0.1)'
    text = 'Active'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={text} size="small" sx={{ color, backgroundColor }} />
}

const emailColumn = new Column('email', 'Email')

const columns = [nameColumn, isdColumn, roleColumn, statusColumn, emailColumn]

export function UserManagement (): JSX.Element {
  const [searchData, setSearchData] = useState<UserSearchCriteria>({ email: '', isds: '', name: '', serviceProviders: '', securityLevel: null, inactive: 'false', showCaregiver: false })
  const dispatch = useErrorDispatch()
  const auth = useAuth()
  const userPermissions = usePermissions()
  const [isdOptions, setIsdOptions] = useState<Array<{ id: number, name: string }>>([])
  const [spOptions, setSpOptions] = useState<Array<{ id: number, name: string }>>([])
  const [ipOptions, setIpOptions] = useState<Array<{ id: number, name: string }>>([])
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(false)

  const handleSearch = (newdata: UserSearchCriteria): void => {
    setSearchData(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.isds !== '') {
      fieldArray.push({
        field: 'isDs.id',
        value: newdata.isds
      })
    }
    if (newdata.email !== '') {
      fieldArray.push({
        field: 'email',
        value: newdata.email
      })
    }
    if (newdata.name !== '') {
      fieldArray.push({
        field: 'fullName',
        value: newdata.name
      })
    }
    if (newdata.serviceProviders !== '') {
      fieldArray.push({
        field: 'serviceProviders.id',
        value: newdata.serviceProviders
      })
    }
    if (newdata.inactive != null && newdata.inactive !== '') {
      fieldArray.push({
        field: 'inactive',
        value: newdata.inactive.toString()
      })
    }
    if (newdata.showCaregiver.toString() !== 'true') {
      fieldArray.push({
        field: 'isCaregiver',
        value: 'false'
      })
    }
    pagination.setSearchFields(fieldArray)
  }

  useEffect(() => {
    const loadISDData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/ISD/GetLookup', {})
      if (error[0] === '') { setIsdOptions(rsp) }

      const { response: rsp2, error: error2 } = await sendGet('/User/GetAllServiceProviders', {})
      if (error2[0] === '') { setSpOptions(rsp2) }

      const { response: rsp3, error: error3 } = await sendGet('/User/GetIntakeProviders', {})
      if (error3[0] === '') {
        const sps = rsp3.map((i: { id: number, name: string }) => { return { id: i.id, name: i.name } })
        setIpOptions(sps)
      }
    }
    void loadISDData()
  }, [])

  const [selectedRowData, setSelectedRowData] = useState<RowData | null>(null)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)

  const newClick = (): void => {
    const newUser: UserInfo = {
      email: '',
      firstName: '',
      fullName: '',
      id: 0,
      inactive: false,
      isDs: [],
      lastName: '',
      securityLevel: Role.CARE_GIVER,
      serviceProviders: [],
      userAuthorizedFunctions: [],
      phoneNumber: ''
    }
    setSelectedRowData(newUser)
    setIsDetailModalOpen(true)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedRowData(row)
    setIsDetailModalOpen(true)
  }

  const [refreshTime, setRefreshTime] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: userInfos } = await sendGet('/User/GetAllUsers', {})
      try {
        const data = userInfos
        setRows(data)
      } catch (e) {
        setRows([])
      }
      setLoading(false)
    }
    void fetchData()
  }, [refreshTime])

  const paginationProps: PaginationProps<RowData> = {
    rows,
    initialFilter: [{ field: 'isCaregiver', value: 'false' }, { field: 'inactive', value: 'false' }],
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  if (loading) return <CircularProgress />

  const handleClose = (): void => {
    setIsDetailModalOpen(false)
    setSelectedRowData(null)
    dispatch({ errors: [], type: 'clear' })
  }

  const handleSubmit = (newRowData: RowData, file: File | undefined): void => {
    const updateUser = async (): Promise<void> => {
      if (isRequestInProgress) return
      setIsRequestInProgress(true)
      const { response } = await sendPut('/User/UpdateUser', newRowData)

      const resp: { success: boolean, errors: postError[], newRecordID: number } = response
      if (resp.success) {
        toast.success(newRowData?.id === 0 ? 'User Created' : 'User updated')
        setSelectedRowData({ ...newRowData, id: resp.newRecordID })
        if (file != null) {
          const fileData = new FormData()
          fileData.append('id', resp.newRecordID.toString())
          fileData.append('file', file)
          const { success, error } = await sendPostFormData('/User/UploadLogo', fileData)
          if (success) {
            setIsDetailModalOpen(false)
            setSelectedRowData(null)
            setRefreshTime(new Date())
          } else {
            const errorMapped: postError[] = error.map(e => { return { field: '', error: e } })
            dispatch({ errors: errorMapped, type: 'update' })
          }
        } else if (file === undefined) {
          setIsDetailModalOpen(false)
          setSelectedRowData(null)
          setRefreshTime(new Date())
        }
      } else {
        dispatch({ errors: resp.errors, type: 'update' })
      }
      setIsRequestInProgress(false)
    }
    void updateUser()
  }

  return (<>
    <Typography variant="h3" data-test-page-header>User Management</Typography>
    <Grid container spacing={2}>
      <Grid item sm={8} md={10} lg={11} container spacing={1} rowSpacing={-1} >
        <UserManagementSearch current={searchData} onChange={handleSearch} />
      </Grid>
      <Grid item sm={4} md={2} lg={1} sx={{ alignSelf: 'center', marginLeft: 'auto' }}>
        <Button variant='contained' color='secondary' onClick={newClick} data-testid='addUser'>Add User</Button>
      </Grid>
    </Grid>
    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          name='userTable'
          columns={columns}
          rows={pagination.internalRows}
          totalRecords={pagination.recordCount}
          loading={loading}
          page={pagination.page}
          onSortChange={(col, sorts) => {
            pagination.onSortChange(col, sorts[0].order)
          }}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          hasCheckbox={false}
          onRowClick={rowClick}
        />
      </Box>
    </div>
    {isDetailModalOpen && selectedRowData != null && (
      <UserManagementDetail
        auth={auth}
        impersonation={userPermissions.Impersonation}
        ipOptions={ipOptions}
        isdOptions={isdOptions}
        spOptions={spOptions}
        open={isDetailModalOpen}
        item={selectedRowData}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    )}
  </>)
}
