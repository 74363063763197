import { Container, Tab, Tabs } from '@mui/material'
import { Modal } from '../../../Components/Modal'
import { TabDisplay } from '../../../Components/TabDisplay'
import { useEffect, useState } from 'react'
import { InterestFormEditableText } from './InterestFormEditableText'
import { BannerModal } from '../../../Components/Modal/BannerModal'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { type InterestFormLabel } from '../../../core/types'
import { toast } from 'react-toastify'

interface InterestFormField {
  header: string
  labelKey: string
  defaultValue: string
  previousValue: string
  newValue: string
}

interface InterestFormStep {
  name: string
  fields: InterestFormField[]
}

export interface InterestFormLanguageModalProps {
  isdid: number
  open: boolean
  onClose: () => void
}

export function InterestFormLanguageModal (props: InterestFormLanguageModalProps): JSX.Element {
  const [interestFormSteps, setInterestFormSteps] = useState<InterestFormStep[]>([])
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [nextStepIndex, setNextStepIndex] = useState(0)
  const [isDirty, setIsDirty] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showExitWarningModal, setShowExitWarningModal] = useState(false)

  const setupFormSteps = async (): Promise<void> => {
    const { response: existingLabels }: { response: InterestFormLabel[] } = await sendGet(`/Label/GetAllLabels?isdId=${props.isdid}`, {})

    const steps: InterestFormStep[] = [{
      name: 'Home Page',
      fields: [{
        header: 'Subtext under "Services for my child" button',
        labelKey: 'IF_Home_Child_Services',
        defaultValue: 'We will guide you through a process that will put you in touch with the services and programs that best meet the needs of your child (preschool, childcare, basic needs, home visiting, etc.).',
        previousValue: existingLabels.find((label) => label.name === 'IF_Home_Child_Services')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Subtext under "Create a Referral" button',
        labelKey: 'IF_Home_Create_Referral',
        defaultValue: 'If you are a service provider who would like to create a referral on behalf of a family, click here to get started.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Home_Create_Referral')?.labelText ?? '',
        newValue: ''
      }]
    },
    {
      name: 'Step 1',
      fields: [{
        header: 'Child Form Introduction: First Child',
        labelKey: 'IF_Child_Intro',
        defaultValue: 'First, we need to know a little about the child to help us find the best programs and services.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Intro')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Child Form Introduction: Next Child',
        labelKey: 'IF_Child_Intro_Next',
        defaultValue: 'Tell us a little about your next child.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Intro_Next')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Child Form Introduction Subtext',
        labelKey: 'IF_Child_Subtext',
        defaultValue: 'If you have more than one child needing early childhood services, you\'ll have the option to add them later.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Subtext')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Date of Birth Label',
        labelKey: 'IF_Child_DOB',
        defaultValue: 'Date of Birth',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_DOB')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Gender Label',
        labelKey: 'IF_Child_Gender',
        defaultValue: 'Gender',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Gender')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Has an Individualized Education Plan (IEP) Label',
        labelKey: 'IF_Child_IEP',
        defaultValue: 'Has an Individualized Education Plan (IEP)',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_IEP')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Has an Individualized Family Service Plan (IFSP) Label',
        labelKey: 'IF_Child_IFSP',
        defaultValue: 'Has an Individualized Family Service Plan (IFSP)',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_IFSP')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Has an Early On Intervening Service Plan (EOIS) Label',
        labelKey: 'IF_Child_EOIS',
        defaultValue: 'Has an Early On Intervening Service Plan (EOIS)',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_EOIS')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Foster Child Label',
        labelKey: 'IF_Child_Foster',
        defaultValue: 'Foster Child',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Foster')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Currently Experiencing Homelessness Label',
        labelKey: 'IF_Child_Homeless',
        defaultValue: 'Currently Experiencing Homelessness',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Homeless')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'SNAP Benefits (food assistance) Label',
        labelKey: 'IF_Child_SNAP',
        defaultValue: 'SNAP Benefits (food assistance)',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_SNAP')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Street Address Label',
        labelKey: 'IF_Child_Address',
        defaultValue: 'Child\'s Home Address',
        previousValue: existingLabels.find((label) => label.name === 'IF_Child_Address')?.labelText ?? '',
        newValue: ''
      }]
    },
    {
      name: 'Step 2',
      fields: [{
        header: 'Caregiver Collection Intro',
        labelKey: 'IF_Caregiver_Intro',
        defaultValue: 'Next, we need to know a little about the household members to see if they qualify for any additional assistance.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Intro')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Care Giver Collection Subtext',
        labelKey: 'IF_Caregiver_Intro_Next',
        defaultValue: 'Great! Please let us know the following information.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Intro_Next')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'No Email Warning',
        labelKey: 'IF_Caregiver_No_Email_Notice',
        defaultValue: 'This should only be checked if this individual does not have access to email. Email is our primary method of contacting families.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_No_Email_Notice')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Income Documentation Notice',
        labelKey: 'IF_Caregiver_Documentation_Notice',
        defaultValue: 'Income documentation may be required later in the process. The best documentation of income is the latest tax return. If this is not available, 4 weeks of pay stubs or a W-2 form may be used until a tax return is received.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Documentation_Notice')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Is Caregiver / Guardian Label',
        labelKey: 'IF_Caregiver_Guardian',
        defaultValue: 'Is Caregiver / Guardian',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Guardian')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Preferred Contact Method Label',
        labelKey: 'IF_Caregiver_Contact_Method',
        defaultValue: 'Preferred Contact Method',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Contact_Method')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Lives at a Different Address than the Child Label',
        labelKey: 'IF_Caregiver_Different_Address',
        defaultValue: 'Lives at a Different Address than the Child',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Different_Address')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Caregiver\'s Home Address Label',
        labelKey: 'IF_Caregiver_Address',
        defaultValue: 'Caregiver\'s Home Address',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Address')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Total Number of Household Members label',
        labelKey: 'IF_Caregiver_Household_Members',
        defaultValue: 'Total Number of Household Members',
        previousValue: existingLabels.find((label) => label.name === 'IF_Caregiver_Household_Members')?.labelText ?? '',
        newValue: ''
      }]
    },
    {
      name: 'Step 3',
      fields: [{
        header: 'Service Intro',
        labelKey: 'IF_Service_Intro',
        defaultValue: 'Thank you! Here are some services and resources that may be available to you and your children.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Intro')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Service Intro Subtext',
        labelKey: 'IF_Service_Subtext',
        defaultValue: 'If your children need multiple or different services, you will have the ability to add that later. Select a service below to get started.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Subtext')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Service Date Intro',
        labelKey: 'IF_Service_Date_Intro',
        defaultValue: 'When are you looking to start this service?',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Date_Intro')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Service Date Subtext',
        labelKey: 'IF_Service_Date_Subtext',
        defaultValue: 'If your children need multiple or different services, you will have the ability to add that later.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Date_Subtext')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Service Time Intro',
        labelKey: 'IF_Service_Time_Intro',
        defaultValue: 'What time of day do you desire this service?',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Time_Intro')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Service Selection Intro',
        labelKey: 'IF_Service_Select_Intro',
        defaultValue: 'Thank you! Which of the following best describes how you would like to proceed?',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Select_Intro')?.labelText ?? '',
        newValue: ''
      }]
    },
    {
      name: 'Step 4',
      fields: [{
        header: 'Service Browse Intro',
        labelKey: 'IF_Service_Browse_Intro',
        defaultValue: 'Awesome! Here is a list of providers in your area. Select any that you may be interested in learning more about.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Browse_Intro')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Additional Service Browse Intro',
        labelKey: 'IF_Service_Browse_Additional',
        defaultValue: 'Are there additional services that you need at this time?',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Browse_Additional')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Service Search Intro',
        labelKey: 'IF_Service_Search_Intro',
        defaultValue: 'Great news! Where would you like to go?',
        previousValue: existingLabels.find((label) => label.name === 'IF_Service_Search_Intro')?.labelText ?? '',
        newValue: ''
      }]
    },
    {
      name: 'Step 5',
      fields: [{
        header: 'Account Mismatch Warning',
        labelKey: 'IF_Account_Warning',
        defaultValue: 'The email address associated with your account does not match any of the entered caregivers. You can either update your email address or edit the caregivers to match. If you update your email address, you will be required to log in again.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Account_Warning')?.labelText ?? '',
        newValue: ''
      }]
    },
    {
      name: 'Step 6',
      fields: [{
        header: 'Final Summary Rank',
        labelKey: 'IF_Final_Summary_Rank',
        defaultValue: 'Due to high demand, we would like you to rank your selected service providers by preference. This will ensure that your interest form will be seen and evaluated by your most desired providers first.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Final_Summary_Rank')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Authorization Text',
        labelKey: 'IF_Authorization_Text',
        defaultValue: 'By submitting this application, I acknowledge that the information given within will be shared with (the intake provider) agencies to support my family needs. By typing my name below, it serves as my electronic signature and permission.',
        previousValue: existingLabels.find((label) => label.name === 'IF_Authorization_Text')?.labelText ?? '',
        newValue: ''
      },
      {
        header: 'Non-Discrimination Disclaimer',
        labelKey: 'IF_NonDiscriminationDisclaimer',
        defaultValue: 'We do not discriminate on the basis of race, color, religion, sex, national origin, age, height, weight, marital status, handicap, disability, or limited English proficiency in any of our programs or activities.',
        previousValue: existingLabels.find((label) => label.name === 'IF_NonDiscriminationDisclaimer')?.labelText ?? '',
        newValue: ''
      }
      ]
    },
    {
      name: 'Post Submit',
      fields: [{
        header: 'Post Submit Greeting',
        labelKey: 'IF_Post_Submit_Greeting',
        defaultValue: 'While you wait to hear back, you can complete the rest of your MiECC profile by adding additional dependents, other guardians who can act on their behalf, or any missing information and documentation not included in this interest form. Having an up-to-date profile can help us identify missing services or resources that you may find helpful in the future. Thank you again!',
        previousValue: existingLabels.find((label) => label.name === 'IF_Post_Submit_Greeting')?.labelText ?? '',
        newValue: ''
      }]
    }]

    // Initialize all newValues to previousValues so we don't have to duplicate the finds above.
    steps.forEach((step) => {
      step.fields.forEach((field) => {
        field.newValue = field.previousValue
      })
    })

    setInterestFormSteps(steps)
  }

  useEffect(() => {
    void setupFormSteps()
  }, [])

  const handleClose = (): void => {
    if (isDirty) {
      setShowExitWarningModal(true)
      return
    }
    setCurrentStepIndex(0)
    props.onClose()
  }

  const handleExitConfirm = (): void => {
    setIsDirty(false)
    clearUnsavedChanges()
    setShowExitWarningModal(false)
    setCurrentStepIndex(0)
    props.onClose()
  }

  const handleSave = async (): Promise<void> => {
    const newSteps = [...interestFormSteps]
    const newStep = { ...newSteps[currentStepIndex] }
    newStep.fields.forEach((field) => {
      field.previousValue = field.newValue
    })
    newSteps[currentStepIndex] = newStep
    setInterestFormSteps(newSteps)
    await sendPost(`/Label/UpdateLabels?isdId=${props.isdid}`, interestFormSteps[currentStepIndex].fields.map((field) => {
      return {
        name: field.labelKey,
        labelText: field.newValue
      }
    }))

    setIsDirty(false)
    toast.success(`Changes for ${interestFormSteps[currentStepIndex].name} were saved.`)
  }

  const handleTabChange = (newValue: number): void => {
    if (isDirty) {
      setNextStepIndex(newValue)
      setShowWarningModal(true)
      return
    }
    setCurrentStepIndex(newValue)
  }

  const clearUnsavedChanges = (): void => {
    const newSteps = [...interestFormSteps]
    const newStep = { ...newSteps[currentStepIndex] }
    newStep.fields.forEach((field) => {
      field.newValue = field.previousValue
    })
    newSteps[currentStepIndex] = newStep
    setInterestFormSteps(newSteps)
  }

  const handleTabChangeConfirm = (): void => {
    clearUnsavedChanges()
    setShowWarningModal(false)
    setCurrentStepIndex(nextStepIndex)
    setIsDirty(false)
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>, fieldIndex: number): void => {
    const newSteps = [...interestFormSteps]
    const newStep = { ...newSteps[currentStepIndex] }
    const newField = { ...newStep.fields[fieldIndex] }
    if (newField.previousValue !== event.target.value) {
      newField.newValue = event.target.value
      newStep.fields[fieldIndex] = newField
      newSteps[currentStepIndex] = newStep
      setInterestFormSteps(newSteps)
      setIsDirty(true)
    }
  }

  const content = <>
    <Tabs value={currentStepIndex} onChange={(e, val) => { handleTabChange(val) }}>
      {interestFormSteps.map((step, index) => {
        return <Tab
          key={index}
          data-testid={`step-${step.name}`}
          label={step.name}
          value={index}
          sx={{ textTransform: 'none', fontSize: '16px', flexGrow: 1 }}
        />
      })}
    </Tabs>
    <Container>
      {interestFormSteps.map((step, index) => {
        return <TabDisplay key={index} index={index} value={currentStepIndex}>
          {step.fields.map((field, index) => {
            return <InterestFormEditableText
              key={index}
              header={field.header}
              labelKey={field.labelKey}
              defaultValue={field.defaultValue}
              newValue={field.newValue}
              onChange={(e) => { handleTextChange(e, index) }}
            />
          })}
        </TabDisplay>
      })}
    </Container>

    <BannerModal
      open={showWarningModal}
      title='Unsaved Changes'
      content={<>You have unsaved changes, do you wish to continue?</>}
      maxWidth='md'
      cancelButtonText='No, Cancel'
      confirmButtonText='Yes, Discard My Changes'
      buttonClassName='modal-button'
      onClose={() => { setShowWarningModal(false) }}
      onConfirm={handleTabChangeConfirm}
    />

    <BannerModal
      open={showExitWarningModal}
      title='Unsaved Changes'
      content={<>You have unsaved changes, do you wish to continue?</>}
      maxWidth='md'
      cancelButtonText='No, Cancel'
      confirmButtonText='Yes, Discard My Changes'
      buttonClassName='modal-button'
      onClose={() => { setShowExitWarningModal(false) }}
      onConfirm={handleExitConfirm}
    />
  </>

  if (interestFormSteps.length === 0) return <></>

  return <Modal
    open={props.open}
    title='Interest Form Language Customization'
    confirmationContent={content}
    maxWidth='md'
    cancelButtonText='Close'
    confirmButtonText={`Save ${interestFormSteps[currentStepIndex].name}`}
    buttonClassName='modal-button'
    onClose={handleClose}
    onConfirm={handleSave}
  />
}
