import { Box, Grid, Typography } from '@mui/material'
import { TextAreaWithLabel } from '../../../Components/TextArea'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { SelectWithLabel, type SelectOption } from '../../../Components/SelectWithLabel'
import { type caregiver, type contactmethod, type CareNetworkCommunication, NeedsMetStatus, NoteSource, type lookup } from '../../../core/types'
import { useState, useEffect } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { SpaceBeforeCapitals } from '../../../core/Utilities'
import { BannerModal } from '../../../Components/Modal/BannerModal'

export interface CommunicationDetailProps {
  open: boolean
  selectedCommunication: CareNetworkCommunication
  isNew: boolean
  userOptions: lookup[]
  onClose: () => void
  onSubmit: (communication: CareNetworkCommunication) => void
}

export function CommunicationDetail (props: CommunicationDetailProps): JSX.Element {
  const needMetOptions: Array<SelectOption<NeedsMetStatus>> = [
    { id: NeedsMetStatus.YES, name: NeedsMetStatus.YES },
    { id: NeedsMetStatus.NO, name: NeedsMetStatus.NO },
    { id: NeedsMetStatus.PREFERNOTTOSAY, name: 'Prefer not to say' }
  ]
  const [selectedCareGiver, setSelectedCareGiver] = useState<caregiver | null>(null)
  const [contactMethods, setContactMethods] = useState<contactmethod[]>([])
  const [communication, setCommunication] = useState<CareNetworkCommunication>(props.selectedCommunication)
  const [caregivers, setCaregivers] = useState<caregiver[]>([])
  const [caregiverOptions, setCaregiverOptions] = useState<lookup[]>([])

  const handleContactChange = async (e: any): Promise<void> => {
    displayCaregiverInfo(Number(e.target.value))
    setCommunication({ ...communication, caregiverID: e.target.value })
  }

  const displayCaregiverInfo = (id: number | null): void => {
    if (id === null) {
      setSelectedCareGiver(null)
    } else {
      const caregiver = caregivers.find(x => x.id === id)
      if (caregiver !== undefined) {
        setSelectedCareGiver(caregiver)
      }
    }
  }

  const handleChange = (e: any): void => {
    setCommunication({ ...communication, [e.target.name]: e.target.value })
  }
  const handleContactMethodChange = async (e: any): Promise<void> => {
    setCommunication({ ...communication, contactMethodID: Number(e.target.value) })
  }
  const handleUserReminderChange = async (e: any): Promise<void> => {
    setCommunication({ ...communication, userID: Number(e.target.value) })
  }
  const handleDateChange = (name: string, newDate: Date | null): void => {
    setCommunication({ ...communication, [name]: newDate })
  }
  const handleSubmit = (): void => {
    props.onSubmit(communication)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response } = await sendGet('/ContactMethod/GetLookup?sort=true', {})
      setContactMethods(response)

      const { response: careGivers } = await sendGet(`/CareGiverSearch/CaregiversByChild?childId=${String(communication.childID)}`, {})
      if (careGivers !== null) {
        const options: lookup[] = []
        setCaregivers(careGivers)
        careGivers.forEach((item: { id: number, firstName: string, lastName: string }) => {
          options.push({ id: item.id, name: `${item.firstName} ${item.lastName}` })
        })
        setCaregiverOptions(options)
      }
    }
    void loadData()
  }, [])

  useEffect(() => {
    displayCaregiverInfo(communication.caregiverID)
  }, [caregiverOptions])

  const reminderContent =
    <Box>
      <SelectWithLabel
        label='Send reminder to'
        name='reminderUserId'
        onChange={handleUserReminderChange}
        options={props.userOptions}
        value={communication?.userID ?? ''}
        width='200px'
      />
      <DatePickerWithLabel
        label='Reminder Date'
        name='reminderDate'
        onChange={(newValue) => { handleDateChange('reminderDate', newValue) }}
        value={communication?.reminderDate ?? null}
        dataTestId='reminderDate'
      />
    </Box>

  const contactLogContent = <Box role="contact">
    <SelectWithLabel
      label='Needs met'
      name='needMet'
      onChange={handleChange}
      options={needMetOptions}
      value={communication.needMet ?? ''}
      width='200px'
    />
    <SelectWithLabel
      label='Addressed most important matter'
      name='addressedWhatMatteredMost'
      onChange={handleChange}
      options={needMetOptions}
      value={communication.addressedWhatMatteredMost ?? ''}
      width='200px'
    />
    <TextAreaWithLabel
      name="reasonNotMet"
      label="Reason not met"
      value={communication.reasonNotMet}
      onChange={handleChange}
      dataTestId='reasonNotMet'
    />
    <SelectWithLabel
      label='Contact Method'
      name='contactMethod'
      onChange={handleContactMethodChange}
      options={contactMethods}
      value={communication.contactMethodID ?? ''}
      width='200px'
    />
  </Box>

  const content = <Box sx={{ margin: '20px' }}>
    <Grid container rowSpacing={1}>
      {communication.type !== NoteSource.Note && <Grid item sm={12} md={6}>
        <SelectWithLabel
          label='Contact'
          name='caregiver'
          onChange={handleContactChange}
          options={caregiverOptions}
          value={communication.caregiverID ?? ''}
          width='200px'
        />
      </Grid>
      }
      <Grid item sm={12} md={6}>
        <Box sx={{ pt: 1 }}>
          <DatePickerWithLabel
            label='Date'
            name='date'
            onChange={(newValue) => { handleDateChange('enteredOn', newValue) }}
            value={communication.enteredOn ?? null}
            noPaddingTop={true}
            dataTestId='date'
          />
        </Box>
      </Grid>

      {communication.type !== NoteSource.Note && <Grid item sm={12} md={12}>
        <Typography variant="subtitle2">{selectedCareGiver?.email}</Typography>
        {(selectedCareGiver?.primaryPhone != null && selectedCareGiver.primaryPhone !== '') && <Typography variant="subtitle2"> primary: {selectedCareGiver.primaryPhone}</Typography>}
        {(selectedCareGiver?.secondaryPhone != null && selectedCareGiver.secondaryPhone !== '') && <Typography variant="subtitle2">secondary: {selectedCareGiver.secondaryPhone}</Typography>}
      </Grid>
      }
      <Grid item sm={12} md={12}>
        <TextAreaWithLabel
          name="note"
          label="Notes"
          value={props.isNew ? '' : communication?.note}
          onChange={handleChange}
          dataTestId='notes'
        />
      </Grid>
      <Grid item sm={6} md={6}>
        {communication.type === NoteSource.ContactLog && contactLogContent}
        {communication.type === NoteSource.Reminder && reminderContent}
      </Grid>
      <Grid item sm={6} md={6}>
      </Grid>

    </Grid>
  </Box>
  return <BannerModal
    className='Nested Referral'
    maxWidth='sm'
    open={props.open}
    title={SpaceBeforeCapitals(communication.type) + ' Detail' }
    content={content}
    onClose={props.onClose}
    onConfirm={handleSubmit}
  />
}
