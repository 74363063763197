import { Button, Grid, Typography } from '@mui/material'
import { type PortalCaregiverDetails, type EnrollmentDetails } from '../../../../core/types'
import { useAuth } from '../../../../hooks/use-auth'
import { useEffect, useState } from 'react'
import { saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { toast } from 'react-toastify'
import { sendGet, sendPost } from '../../../../hooks/use-fetch'
import { TextFieldWithLabel } from '../../../../Components/TextField'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useParams } from 'react-router'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

export interface AccountCreationStepProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function AccountCreationStep (props: AccountCreationStepProps): JSX.Element {
  const { isdId } = useParams()
  const dispatch = useCaregiverWizardUpdateDispatch()
  const auth = useAuth()
  const [updateEmail, setUpdateEmail] = useState<boolean>(false)
  const [newEmail, setNewEmail] = useState<string>('')

  const caregiverForm = props.caregiverForm

  useScrollTop()

  useEffect(() => {
    if (sessionStorage.getItem('newEmail') != null) {
      void saveNewEmail()
      return
    }

    if (!auth.loading && auth.user == null) {
      // If the user is not logged in, go back to the previous step.
      void handleBack()
    }

    if (auth.user != null) {
      const caregiver = caregiverForm.caregivers.find(c => c.email.toLowerCase() === auth.user?.email.toLowerCase())
      if (caregiver != null) {
        void sendPost('/Account/UpdateAccount', {
          firstName: caregiver.firstName,
          lastName: caregiver.lastName,
          email: caregiver.email
        })
        void sendPost('/CaregiverPortal/CreateUser', {
          firstName: caregiver.firstName,
          lastName: caregiver.lastName,
          email: caregiver.email,
          externalLogin: auth.user.externalLogin
        })

        const tempForm = { ...caregiverForm, currentStep: 6, currentSubStep: 1 }
        void saveSession(tempForm)
        props.handleStep(false)

        // Refresh the page so we get the updated logged in user
        window.location.reload()
      }
    }
  }, [auth.loading, auth.user])

  const saveNewEmail = async (): Promise<void> => {
    const savedEmail = sessionStorage.getItem('newEmail') ?? ''
    const oldEmail = sessionStorage.getItem('oldEmail') ?? ''
    sessionStorage.removeItem('newEmail')
    sessionStorage.removeItem('oldEmail')

    const caregiver = caregiverForm.caregivers.find(c => c.email.toLowerCase() === savedEmail.toLowerCase())

    const user = {
      firstName: caregiver?.firstName,
      lastName: caregiver?.lastName,
      email: caregiver?.email,
      oldEmail
    }
    await sendPost('/Account/UpdateAccount', user)
    await sendPost('/CaregiverPortal/CreateUser', user)

    auth.login()
  }

  const goBackToCaregiverSummary = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 2, currentSubStep: 2 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  const validateEmail = async (caregiver?: PortalCaregiverDetails): Promise<boolean> => {
    if (newEmail === '') {
      toast.error('Please enter a new email address.')
      return false
    }
    if (caregiver == null) {
      toast.error('The new email address does not match any of the entered caregivers.')
      return false
    }
    const { response: doesAccountAlreadyExist } = await sendGet(`/Account/DoesUserExist?email=${newEmail}`, {})
    if (doesAccountAlreadyExist as boolean) {
      toast.error('An account already exists with the new email address. Please sign in to that account or use a different email address.')
      return false
    }

    return true
  }

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 4, currentSubStep: 3 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    if (!updateEmail) {
      await goBackToCaregiverSummary()
      return
    }

    const caregiver = caregiverForm.caregivers.find(c => c.email.toLowerCase() === newEmail.toLowerCase())
    const isValid = await validateEmail(caregiver)
    if (!isValid) {
      return
    }

    // The user needs to be logged out before their email gets updated.
    // This updates the email after they return to this page.
    // The user will be redirected back to this page after logging out.
    sessionStorage.setItem('newEmail', newEmail)
    sessionStorage.setItem('oldEmail', auth.user?.email ?? '')
    auth.logout()
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">
        <CustomLabelWithToolTip
          name='IF_Account_Warning'
          isdId={isdId ?? 0}
          defaultLabelText='The email address associated with your account does not match any of the entered caregivers. You can either update your email address or edit the caregivers to match. If you update your email address, you will be required to log in again.'
        />
      </Typography>
      <Typography variant="h6" sx={{ mt: '30px' }}>
        Your email address: {auth.user?.email ?? ''}
      </Typography>
    </Grid>

    <Grid item xs={6}>
      <Button variant="outlined" className={'wide-button' + (updateEmail ? ' selected' : '')} onClick={() => { setUpdateEmail(true) }} data-testid='update-email'>Update Email</Button>
    </Grid>
    <Grid item xs={6}>
      <Button variant="outlined" className={'wide-button' + (updateEmail ? '' : ' selected')} onClick={() => { setUpdateEmail(false) }}>Edit Caregivers</Button>
    </Grid>
    {updateEmail &&
      <Grid item xs={12}>
        <TextFieldWithLabel
          label='New Email Address'
          name='email'
          maxLength={50}
          data-testid='email'
          value={newEmail}
          onChange={(e) => { setNewEmail(e.target.value) }}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
    }

    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='continueButton'
        className='footer-button'
        data-testid='continue-button'
        onClick={handleContinue}
        variant='contained'
      >
        Continue
      </Button>
    </Grid>
  </Grid>
}
