import { Chip } from '@mui/material'
import { SmallCard } from '../../../../Components/Cards/Small'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

export function EnrollmentCard (props: { maxCapacity: number, enrollments: Array<{ key: string, count: number }> }): JSX.Element {
  const badges = props.enrollments.map((enrollment) => {
    const colorItem = enrollment.key.length % 3
    let indicatorClass = 'cyan-'
    if (colorItem === 1) {
      indicatorClass = 'purple-'
    } else if (colorItem === 2) {
      indicatorClass = 'red-'
    }
    return <Chip key={enrollment.key + indicatorClass} icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={enrollment.count} size='small' className={indicatorClass + 'indicator'} />
  })

  return (<SmallCard
    header={'Enrollments by Type'}
    totalValue={props.maxCapacity}
    currentValue={props.maxCapacity - props.enrollments.reduce((a, b) => a + b.count, 0)}
    badges={<>{badges}</>}
    onClick={() => {}}
  />)
}
