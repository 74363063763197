import { AddChildDetails } from './AddChildDetails'
import { type EnrollmentDetails } from '../../../../core/types'
import { ProviderSelection } from './ProviderSelection'
import { ChildrenSummary } from './ChildrenSummary'

export interface ChildDetailsStepProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function ChildDetailsStep (props: ChildDetailsStepProps): JSX.Element {
  const caregiverForm = props.caregiverForm

  if (caregiverForm.currentSubStep === 1) {
    return <ProviderSelection caregiverForm={caregiverForm} />
  } else if (caregiverForm.currentSubStep === 2) {
    return <AddChildDetails caregiverForm={caregiverForm} />
  } else if (caregiverForm.currentSubStep === 3) {
    return <ChildrenSummary caregiverForm={caregiverForm} handleStep={props.handleStep} />
  }

  return <></>
}
