import { Avatar, Box, FormLabel, type SxProps, Tooltip, tooltipClasses } from '@mui/material'
import { common } from '@mui/material/colors'
import { useState } from 'react'

interface LabelProps {
  required?: boolean
  name?: string
  isInline?: boolean
  maxWidth?: string
  labelText?: string
  labelContent?: JSX.Element
  toolTipText?: string
  sx?: SxProps
  showRequiredText?: boolean
}

export const LabelWithToolTip = (props: LabelProps): JSX.Element => {
  const color = '#4591bb'
  const [open, setOpen] = useState(false)
  const handleTooltipClose = (): void => {
    setOpen(false)
  }
  const handleTooltipOpen = (): void => {
    setOpen(true)
  }

  const toolTip =
    <Tooltip
      title={props.toolTipText}
      placement='top-start'
      onClose={handleTooltipClose}
      open={open}
      arrow
      PopperProps={{
        disablePortal: true,
        sx: {
          [`& .${tooltipClasses.arrow}`]: {
            color
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: color
          }
        },
        popperOptions: {
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8
              }
            }

          ]
        }
      }}
    >
      <div>
        <Avatar onClick={handleTooltipOpen} sx={{ bgcolor: common.black, width: '18px', height: '18px', fontSize: '15px' }}>i</Avatar>
      </div>
    </Tooltip>

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <FormLabel
          required={props.required === true && props.showRequiredText !== true}
          htmlFor={props.name}
          sx={{
            whiteSpace: 'break-spaces',
            alignSelf: props.isInline === true ? 'center' : '',
            color: 'black',
            maxWidth: props.maxWidth,
            overflowWrap: 'break-word',
            ...props.sx
          }}
        >
          {props.labelContent ?? props.labelText}
        </FormLabel>
        {(props.toolTipText !== undefined) && (toolTip)}
      </Box>
      {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
      {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
    </Box>
  )
}
