import { useEffect, useState } from 'react'
import { Modal } from '../../Components/Modal'
import { CircularProgress, Box, Typography } from '@mui/material'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { SelectWithLabel, type SelectOption } from '../../Components/SelectWithLabel'
import { IsdDocumentTypeStatus, type lookup } from '../../core/types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { TextAreaWithLabel } from '../../Components/TextArea'
import { toast } from 'react-toastify'
import { SwitchWithLabel } from '../../Components/Switch/SwitchWithLabel'

export interface ServiceDetailProps {
  open: boolean
  onClose: () => void
  onSubmit: (description: string, isIntake: boolean, excludeFromPortal: boolean) => void
  isdServiceID: number
  isdID: number
  description: string
  isIntake: boolean
  excludeFromPortal: boolean
}

export interface DocumentSettings {
  id: number
  isdServiceID: number
  documentID: number
  status: string
}

export function ServiceDetail (props: ServiceDetailProps): JSX.Element {
  const [defaultCurrentSettings, setDefaultCurrentSettings] = useState<DocumentSettings[]>([])
  const [currentSettings, setCurrentSettings] = useState<DocumentSettings[]>([])
  const [documentTypes, setDocumentTypes] = useState<lookup[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [description, setDescription] = useState(props.description)
  const [isIntake, setIsIntake] = useState(props.isIntake)
  const [updateDisabled, setUpdateDisabled] = useState(false)
  const [excludeFromPortal, setExcludeFromPortal] = useState(props.excludeFromPortal)

  const handleChange = (event: any, documentId: number): void => {
    const newSettings = currentSettings.slice()
    const selecteddocument = newSettings.find(x => x.documentID === documentId)
    if (selecteddocument !== undefined) {
      selecteddocument.status = event.target.value
    } else {
      newSettings.push({ documentID: documentId, id: 0, isdServiceID: props.isdServiceID, status: event.target.value })
    }
    setCurrentSettings(newSettings)
    setLoaded(true)
  }

  const handleIntakeChange = async (checked: boolean): Promise<void> => {
    if (checked) {
      const { response } = await sendGet(`/ISDServices/IsIntakeServiceInUse?isdServiceID=${props.isdServiceID}`, {})
      if (response === true) {
        toast.error('Only intake providers can use this service and currently non-intake providers are assigned this service. Please remove the service from them prior to making this change.')
        setUpdateDisabled(true)
      } else {
        toast.warning('Only intake providers will be able to utilize this service.')
        setUpdateDisabled(false)
      }
    } else {
      toast.warning('This service will now be available to any provider.')
      setUpdateDisabled(false)
    }

    setIsIntake(checked)
  }

  const showConfirmation = (): boolean => {
    let areRecordsModified = false
    if (currentSettings.length !== defaultCurrentSettings.length) {
      areRecordsModified = true
    } else {
      for (const item of defaultCurrentSettings) {
        const selectedDocument = currentSettings.find(x => x.documentID === item.documentID)
        if (selectedDocument?.status !== item.status) {
          areRecordsModified = true
          break
        }
      }
    }
    return areRecordsModified
  }

  const submit = async (): Promise<void> => {
    const { response } = await sendPost('/ISDServices/UpdateService', { iSDServiceID: props.isdServiceID, description, isIntake, excludeFromPortal })
    if (response.success !== true) {
      toast.error(response.error[0])
      return
    }
    await sendPost('/ISDServices/UpdateServiceDocuments', { newDocs: currentSettings })
    props.onSubmit(description, isIntake, excludeFromPortal)
  }

  const handleSubmit = async (): Promise<void> => {
    if (showConfirmation()) {
      setShowConfirmationModal(true)
    } else {
      await submit()
    }
  }

  const handleModalClose = (): void => {
    setShowConfirmationModal(false)
  }

  const getISDDocumentTypeStatus = (documentId: number): string => {
    if (currentSettings !== undefined) {
      const selectedDocument = currentSettings.find(x => x.documentID === documentId)
      if (selectedDocument !== undefined) {
        return selectedDocument.status ?? IsdDocumentTypeStatus.NOTREQUIRED
      }
    }
    return IsdDocumentTypeStatus.NOTREQUIRED
  }

  const documentTypeOptions: Array<SelectOption<IsdDocumentTypeStatus>> = [
    { id: IsdDocumentTypeStatus.REQUIRED, name: IsdDocumentTypeStatus.REQUIRED },
    { id: IsdDocumentTypeStatus.NOTREQUIRED, name: IsdDocumentTypeStatus.NOTREQUIRED }
  ]

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response, error } = await sendGet(`/ISDDocument/GetIsdDocuments?isdid=${props.isdID}`, {})
      if (error[0] === '') {
        setDocumentTypes(response)
      }

      const { response: rsp2, error: error2 } = await sendGet(`/ISDServices/GetServicesDocument?ISDID=${props.isdID}&ISDServiceID=${props.isdServiceID}`, {})
      if (error2[0] === '') {
        setCurrentSettings(rsp2)
        const list: DocumentSettings[] = []
        rsp2.forEach((item: { documentID: number, id: number, serviceID: number, status: string, isdid: number }) => {
          list.push({ documentID: item.documentID, id: item.id, isdServiceID: props.isdServiceID, status: item.status })
        })
        setDefaultCurrentSettings(list)
        setLoaded(true)
      }
    }

    void loadData()
  }, [props.isdServiceID])

  if (!loaded) return <CircularProgress />

  const content = <Box>
    <SwitchWithLabel
      onChange={async (e, checked) => { await handleIntakeChange(checked) }}
      label='Intake Service'
      name='isIntake'
      checked={isIntake}
      dataTestId='isIntake'
    />

    <SwitchWithLabel
      onChange={async (e, checked) => { setExcludeFromPortal(checked) }}
      label='Exclude from Caregiver Portal'
      name='excludeFromPortal'
      checked={excludeFromPortal}
      dataTestId='excludeFromPortal'
    />

    <TextAreaWithLabel
      name="description"
      label="Description"
      dataTestId='description'
      value={description}
      onChange={(e) => { setDescription(e.target.value) }}
    />

    <Typography variant="h6" component="div">
      Documents
    </Typography>

    <table data-testid="isd-table">
      <thead>
        <tr>
          <td>Document Name</td>
          <td>Require</td>
        </tr>
      </thead>
      <tbody>
        {documentTypes.map(s => {
          const row = <tr key={s.id} data-testid="isd-table-row">
            <td>{s.name}</td>
            <td>
              <SelectWithLabel
                name="documentTypeStatus"
                data-testid='documentTypeStatus'
                label="Status"
                width="100%"
                value={getISDDocumentTypeStatus(s.id)}
                options={documentTypeOptions}
                onChange={(evt) => { handleChange(evt, s.id) }}
              />
            </td>
          </tr>
          return row
        })}
      </tbody>
    </table>

    {showConfirmationModal && <Dialog
      open={true}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirmation'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Changing these values will impact any in-progress enrollments. Do you wish to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button onClick={submit} data-testid='dialogOK' autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>}
  </Box>

  return <Modal
    onClose={props.onClose}
    open={props.open}
    onConfirm={handleSubmit}
    title="Service Details"
    confirmationContent={content}
    isUpdateDisabled={updateDisabled}
  />
}
