import { useEffect, useState } from 'react'
import { Modal } from '../../../Components/Modal'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { Box, Grid, Typography } from '@mui/material'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { type dateRange } from '../../../core/types'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { BannerModal } from '../../../Components/Modal/BannerModal'

interface SessionBlockProps {
  item: SessionBlockDetailsView | null
  serviceName: string
  serviceId: number
  spId: number
  open: boolean
  onClose: () => void
  onSubmit: (updatedItem: SessionBlockDetailsView) => void
}

export interface SessionBlockDetailsView {
  id: number | null
  name: string
  startDate: Date | null
  endDate: Date | null
  dateRangeId: number | null
  serviceId: number
  selectedProgramIds: number[]
  inactive: boolean
}

interface ProgramOption {
  id: number
  name: string
  requiresIncomeCalculation: boolean
  isUsed: boolean
}

export function SessionBlockDetailsModal (props: SessionBlockProps): JSX.Element {
  const accountId = useAccountId()?.id ?? 0
  const customDateRange = { id: 0, name: 'Use Custom', startDate: new Date(), endDate: new Date() }
  const initialSessionBlock = { id: null, name: '', startDate: null, endDate: null, dateRangeId: null, serviceId: props.serviceId, spId: props.spId, selectedProgramIds: [], inactive: false }
  const [sessionBlock, setSessionBlock] = useState<SessionBlockDetailsView>(props.item ?? initialSessionBlock)
  const [dateRanges, setDateRanges] = useState([customDateRange])
  const [programs, setPrograms] = useState<ProgramOption[]>([])
  const [isTimeSpanCustom, setIsTimeSpanCustom] = useState(false)
  const [areFieldsBlank, setAreFieldsBlank] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [tempIds, setTempIds] = useState<number[]>([])
  const [showWarning, setShowWarning] = useState(false)
  const availableDateRanges = programs.filter(p => sessionBlock.selectedProgramIds.includes(p.id)).some(p => p.requiresIncomeCalculation) ? dateRanges.filter(d => d.id > 0) : dateRanges
  const programNeedsIncomeCalc = programs.filter(p => sessionBlock.selectedProgramIds.includes(p.id)).some(p => p.requiresIncomeCalculation)

  useEffect(() => {
    setAreFieldsBlank(false)
    setIsTimeSpanCustom(false)
    if (props.item === null) {
      setSessionBlock(initialSessionBlock)
    } else {
      setSessionBlock(props.item)
      if (props.item.dateRangeId === null) {
        setIsTimeSpanCustom(true)
      }
    }
  }, [props.item, props.open])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response } = await sendGet('/DateRange/GetAll', {})
      const dateRanges = response.map((dr: dateRange) => {
        return {
          id: dr.id,
          name: dr.name,
          startDate: dr.startDate,
          endDate: dr.endDate
        }
      })
      setDateRanges([customDateRange, ...dateRanges])
      const { response: rsp } = await sendGet(`/ServiceProviderDetails/GetProgramsUsage?id=${accountId}&sessionBlockID=${sessionBlock.id ?? ''}`, {})
      setPrograms(rsp)
    }
    void loadData()
  }, [sessionBlock])

  const onDateRangeSelected = (e: any): void => {
    const dateRangeIdString = e.target.value
    const dateRangeId = parseInt(dateRangeIdString)
    if (dateRangeId === 0) {
      setSessionBlock({ ...sessionBlock, dateRangeId: null, startDate: null, endDate: null })
      setIsTimeSpanCustom(true)
    } else {
      setIsTimeSpanCustom(false)
      const dateRange = dateRanges.find((dr) => dr.id === dateRangeId)
      if (dateRange !== undefined) {
        const startDate = dateRange.startDate
        const endDate = dateRange.endDate
        setSessionBlock({ ...sessionBlock, startDate, endDate, dateRangeId })
      }
    }
  }

  const handleActiveChange = (e: any): void => {
    setSessionBlock({ ...sessionBlock, inactive: e.target.value })
  }

  const onSubmit = async (): Promise<void> => {
    if (!submitting) {
      setSubmitting(true)

      if (programNeedsIncomeCalc && sessionBlock.dateRangeId === null && !isTimeSpanCustom) {
        setAreFieldsBlank(true)
        toast.error('Program requires income calculation. Please select a time span')
        setSubmitting(false)

        return
      }

      let fieldsBlank = false
      if (isTimeSpanCustom && sessionBlock.startDate == null && sessionBlock.endDate != null) {
        fieldsBlank = true
      }
      if (isTimeSpanCustom && sessionBlock.startDate != null && sessionBlock.endDate == null) {
        fieldsBlank = true
      }
      if (sessionBlock.name === '') {
        fieldsBlank = true
      }
      if (fieldsBlank) {
        setAreFieldsBlank(true)
        toast.error('Please fill out the required fields')
        setSubmitting(false)

        return
      }
      setAreFieldsBlank(false)

      if ((sessionBlock.startDate ?? new Date()) > (sessionBlock.endDate ?? new Date())) {
        toast.error('Start date must be before end date')
        setSubmitting(false)

        return
      }
      if (sessionBlock.id === null) {
        const { success } = await sendPost('/SessionBlock/Create', sessionBlock)
        if (success) {
          toast.success('Session block was created')
        } else {
          toast.error('Error saving session block')
          setSubmitting(false)

          return
        }
      } else {
        const { success } = await sendPost('/SessionBlock/Update', sessionBlock)
        if (success) {
          toast.success('Session block was updated')
        } else {
          toast.error('Error saving session block')
          setSubmitting(false)
          return
        }
      }
      props.onSubmit(sessionBlock)
      setSubmitting(false)
    }
  }

  const handleProgramChange = (programId: number, selected: boolean): void => {
    const currentPrograms = programs.filter(p => sessionBlock.selectedProgramIds.includes(p.id))
    const incomeStateNow = currentPrograms.some(p => p.requiresIncomeCalculation)
    const hasDateRange = sessionBlock.dateRangeId !== null
    let newProgramIds = [...sessionBlock.selectedProgramIds]

    if (selected) {
      const newProgram = programs.find(p => p.id === programId)
      newProgramIds.push(programId)

      if (hasDateRange && newProgram?.requiresIncomeCalculation !== incomeStateNow) {
        setShowWarning(true)
        setTempIds(newProgramIds)
        return
      }

      setSessionBlock({ ...sessionBlock, selectedProgramIds: newProgramIds })
    } else {
      newProgramIds = newProgramIds.filter(pro => pro !== programId)
      const newPrograms = programs.filter(p => newProgramIds.includes(p.id))
      const newIncomeState = newPrograms.some(p => p.requiresIncomeCalculation)

      if (hasDateRange && newIncomeState !== incomeStateNow) {
        setShowWarning(true)
        setTempIds(newProgramIds)
        return
      }

      setSessionBlock({ ...sessionBlock, selectedProgramIds: newProgramIds })
    }
  }

  const content = <Box>
    <TextFieldWithLabel
      label='Block Name'
      name='blockName'
      data-testid='blockName'
      onChange={(e) => { setSessionBlock({ ...sessionBlock, name: e.target.value }) }}
      value={sessionBlock?.name ?? ''}
      error={areFieldsBlank && sessionBlock.name === ''}
    />
    <Typography variant='subtitle1'>Programs</Typography>
    <Grid container columns={12}>
      {programs.map(p => {
        return <Grid item sm={6} key={p.id}>
          <CheckBoxWithLabel
            label={p.name}
            name={`${p.name}checkbox`}
            value={sessionBlock.selectedProgramIds.find(pro => pro === p.id) !== undefined}
            setValue={(newVal: boolean) => { handleProgramChange(p.id, newVal) }}
            edge={'start'}
            disabled={p.isUsed && props.item !== null}
            dataTestId={`${p.name}checkbox`}
          />
        </Grid>
      })}
    </Grid>

    {sessionBlock.selectedProgramIds.length > 0 && (
      <SelectWithLabel
        label='Time Span'
        name='timeSpan'
        width='240px'
        onChange={onDateRangeSelected}
        value={sessionBlock.dateRangeId ?? (isTimeSpanCustom ? 0 : '')}
        error={areFieldsBlank && programNeedsIncomeCalc && sessionBlock.dateRangeId === null && !isTimeSpanCustom}
        options={availableDateRanges}
      />
    )}
    {isTimeSpanCustom &&
      <>
        <Box sx={{ boxSizing: 'border-box', p: '4px 0px', gap: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <DatePickerWithLabel
              label='Start Date'
              name='startDate'
              dataTestId='startDate'
              onChange={(newValue) => { setSessionBlock({ ...sessionBlock, startDate: newValue }) }}
              value={sessionBlock.startDate}
              error={areFieldsBlank && sessionBlock.startDate === null}
            />
          </Box>
          <Box>
            <DatePickerWithLabel
              label='End Date'
              name='endDate'
              dataTestId='endDate'
              onChange={(newValue) => { setSessionBlock({ ...sessionBlock, endDate: newValue }) }}
              value={sessionBlock.endDate}
              error={areFieldsBlank && sessionBlock.endDate === null}
            />
          </Box>
        </Box>
      </>
    }
    <SelectWithLabel
      width='240px'
      label='Status'
      name='status'
      onChange={handleActiveChange}
      options={[{ id: false, name: 'Active' }, { id: true, name: 'Inactive' }]}
      value={sessionBlock.inactive}
    />
    <BannerModal
      maxWidth='sm'
      fullWidth={true}
      onClose={() => {
        setShowWarning(false)
        setTempIds([])
      }}
      open={showWarning}
      onConfirm={() => {
        setShowWarning(false)
        setSessionBlock({ ...sessionBlock, selectedProgramIds: tempIds, dateRangeId: null })
        setTempIds([])
      }}
      content={<>This action will reset your selected time span settings. Do you wish to continue?</>}
      title={'Confirm'}
    />
  </Box>

  return <Modal
    maxWidth='sm'
    fullWidth={true}
    open={props.open}
    data-testid='session-block-modal'
    title={'Session Block - ' + props.serviceName}
    confirmationContent={content}
    onClose={props.onClose}
    onConfirm={onSubmit}
  />
}
