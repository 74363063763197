import { type location } from '../../../core/types'
import { LocationCardDisplay } from '../LocationCard/LocationCardDisplay'
import { MapStateName } from '../LocationCard/mapStateName'
interface AddLocationCardProps {
  location: location
  onLocationChange: (changedLocation: location) => void
  errors: string[]
}

export function AddLocationCard (props: AddLocationCardProps): JSX.Element {
  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: location = { ...props.location }
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode

    props.onLocationChange(newplace)
  }

  const handleEditChange = (e: any): void => {
    props.onLocationChange({ ...props.location, [e.target.name]: e.target.value })
  }

  return (
    <LocationCardDisplay
      isPrimary={true}
      hideActions={true}
      showHomelessOption={true}
      data={props.location}
      edit={true}
      onEditChange={handleEditChange}
      onPlaceSelected={handlePlaceSelected}
      errors={props.errors} anchorEl={null} onClick={() => {}} onClose={() => {}}
      onEdit={() => {}} onDelete={() => {}} onUpdate={() => {} } onCancel={() => {}}
    />
  )
}
