import { Box, Grid, Typography } from '@mui/material'
import { TextFieldWithLabel } from '../../TextField'
import { type lookup, type location } from '../../../core/types'
import { MapModule, useBingMaps } from '../../../hooks/use-bingMaps'
import { useEffect, useState } from 'react'
import { CheckBoxWithLabel } from '../../CheckBox'
import { DatePickerWithLabel } from '../../DatePicker'
import { SelectWithLabel } from '../../SelectWithLabel'
import { sendGet } from '../../../hooks/use-fetch'
import { toast } from 'react-toastify'

interface LocationDetailsProps {
  onChange: (e: any) => void
  onPlaceSelected: (place: Microsoft.Maps.ISuggestionResult) => Promise<void>
  editData: location
  onBlur?: (dat: location) => void
  hidePrimary?: boolean
  showHomelessOption: boolean
  errors?: string[]
}

export function LocationDetails (props: LocationDetailsProps): JSX.Element {
  const [isError] = useState(false)
  const [homeless, setHomeless] = useState<boolean>(props.editData.isHomeless ?? false)
  const [residentOptions, setResidentOptions] = useState<lookup[] | null>(null)
  const handleBlur = (): void => {
    try {
      validateLocation(props.editData)
    } catch { }
  }

  const unaccompaniedOptions: Array<{ id: boolean, name: string }> = [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' }
  ]

  const doNothing = (): void => { }

  const locationValidated = (isValid: boolean): void => {
    if (props.onBlur === undefined) return
    props.onBlur(props.editData)
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const { validateLocation } = useBingMaps({ modules: [MapModule.AutoSuggest], selectFunction: props.onPlaceSelected ?? doNothing, inputID: 'address', onResultVerified: locationValidated })

  useEffect(() => {
    if (homeless && residentOptions === null) {
      const loadData = async (): Promise<void> => {
        const { response: rsp, error } = await sendGet('/CareNetworkEdit/HomelessResidenceOptions', {})
        if (error[0] === '' && rsp !== null) {
          setResidentOptions(rsp)
        }
      }
      void loadData()
    }
  }, [homeless])

  const valueChanged = (field: string, newValue: any): void => {
    props.onChange({ target: { name: field, value: newValue } })
  }

  const handleHomelessChanged = (newValue: boolean): void => {
    setHomeless(newValue)
    valueChanged('isHomeless', newValue)
  }

  useEffect(() => {
    props.errors?.forEach((err) => {
      toast.error(err)
    })
  }, [props.errors])

  return (<>
    {isError && (
      <Typography>This location could not be found.</Typography>
    )}
    <TextFieldWithLabel
      ariaRole='combobox'
      ariaExpanded={false}
      ariaControls='as_containerSearch_address'
      label='Street'
      name='address'
      data-testid="address"
      required={true}
      autoComplete={'off'}
      onBlur={handleBlur}
      onChange={props.onChange}
      value={props.editData.address ?? ''}
    />
    <div id='searchBoxContainer'></div>
    <TextFieldWithLabel
      label='Apt'
      name='apartment'
      onBlur={handleBlur}
      onChange={props.onChange}
      value={props.editData.apartment ?? ''}
      dataTestId='apartment'
    />
    <Grid container columns={12} spacing={1}>
      <Grid item xs={6} sm={6}>
        <TextFieldWithLabel
          label='City'
          name='city'
          data-testid="city"
          required={true}
          onBlur={handleBlur}
          onChange={props.onChange}
          value={props.editData.city ?? ''}
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <TextFieldWithLabel
          label='State'
          name='state'
          data-testid="state"
          required={true}
          onBlur={handleBlur}
          onChange={props.onChange}
          maxLength={2}
          value={props.editData.state ?? ''}
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <TextFieldWithLabel
          label='Zip'
          name='zipCode'
          data-testid="zip"
          required={true}
          onBlur={handleBlur}
          onChange={props.onChange}
          maxLength={5}
          value={props.editData.zipCode ?? ''}
        />
      </Grid>
    </Grid>

    {!(props.hidePrimary ?? false) && (
      <Box>
        <CheckBoxWithLabel
          edge='end'
          label='Is Primary'
          dataTestId="primary"
          name='isPrimary'
          setValue={(value) => { valueChanged('isPrimary', value) }}
          value={props.editData.isPrimary ?? false}
        />
      </Box>
    )}
    {props.showHomelessOption && (
      <>
        <Box>
          <CheckBoxWithLabel
            edge='end'
            label='Currently Experiencing Homelessness'
            dataTestId="homeless"
            name='isHomeless'
            setValue={handleHomelessChanged}
            value={homeless}
          />
        </Box>

        {homeless && (
          <>
            <Grid container columns={12} spacing={1}>
              <Grid item xs={6} sm={6}>
                <DatePickerWithLabel
                  label='Homeless Start Date'
                  name='startDate'
                  data-testid="startDate"
                  required={true}
                  onChange={(value) => { valueChanged('startDate', value) }}
                  value={props.editData.startDate ?? null}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <DatePickerWithLabel
                  label='Homeless End Date'
                  name='endDate'
                  data-testid="endDate"
                  required={false}
                  onChange={(value) => { valueChanged('endDate', value) }}
                  value={props.editData.endDate ?? null}
                />
              </Grid>
            </Grid>
            {residentOptions !== null && <SelectWithLabel
              name="homelessResidenceID"
              label="Primary Nighttime Residence"
              value={props.editData.homelessResidenceID ?? undefined}
              options={residentOptions}
              onChange={props.onChange}
              width='100%'
              required={true}
            />
            }
            <SelectWithLabel
              options={unaccompaniedOptions}
              label='Is Unaccompanied Youth'
              name='isUnaccompaniedYouth'
              onChange={props.onChange}
              value={props.editData.isUnaccompaniedYouth ?? undefined}
              width='100%'
              required={true}
            />
          </>
        )}
      </>
    )}
  </>)
}
