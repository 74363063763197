/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { Box, CircularProgress, Typography } from '@mui/material'
import { type location, type ChildCaregiver, type ChildSiblings, type ChildEditValues, type ChildAuditAccessTypeEnum, type race, type eligibilityFactor } from '../../core/types'
import { ChildDetailDisplay } from './ChildDetailDisplay'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { usePermissions } from '../../hooks/use-permissions'
import { NotAllowed } from '../../Routes/ProtectedRoute'
import { toast } from 'react-toastify'

export interface ChildDetailProps {
  childID: number
  isdid: number
  requestingModule?: ChildAuditAccessTypeEnum
  onRefresh: () => void
}

export function ChildDetails (props: ChildDetailProps): JSX.Element {
  const account = useAccountId()
  const perms = usePermissions()
  const [child, setChild] = useState<ChildEditValues>()
  const [childCaregivers, setChildCaregivers] = useState<ChildCaregiver[]>([])
  const [childSiblings, setChildSiblings] = useState<ChildSiblings[]>([])
  const [loaded, setLoaded] = useState(false)
  const [childAddress, setChildAddress] = useState<location>({} as location)
  const [unauthorized, setUnauthorized] = useState(false)
  const [refresh, setReferesh] = useState(new Date())

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, success, status } = await sendGet(`/ChildDetails/Find?id=${props.childID}&accountId=${account?.id ?? 0}&accountType=${account?.type ?? ''}&requestingModule=${String(props.requestingModule) ?? ''}`, {})
      if (success) {
        const details = response.response
        const { response: raceResponse } = await sendGet('/Race/GetLookup', {})
        const { response: factorResponse } = await sendGet('/EligibilityFactor/GetLookup', {})
        const factors: eligibilityFactor[] = factorResponse
        const races: race[] = raceResponse
        const childRaceListStrings: string[] = details.childRaceList as string[]
        const childRaceListCorrected: race[] = childRaceListStrings.map(s => {
          const race = races.find(r => r.name === s)
          return race
        }).filter(r => r !== undefined) as race[]
        details.childRaceList = childRaceListCorrected
        const childFactorStrings: string[] = details.eligibilityFactorList as string[]
        const childFactorListCorrected: eligibilityFactor[] = childFactorStrings.map(s => {
          const race = factors.find(r => r.name === s)
          return race
        }).filter(f => f !== undefined) as eligibilityFactor[]
        details.eligibilityFactorList = childFactorListCorrected

        setChild(details)
        if (details.childLocations.length > 0) {
          const loc = details.childLocations[0]
          loc.apartment = loc.unitNumber
          setChildAddress(loc)
        }
        setChildCaregivers(details.careGivers)
        setChildSiblings(details.childSiblings)
      } else {
        if (status === 401) {
          setUnauthorized(true)
        }
        const errs: string[] = response.errors
        errs.forEach(e => toast.error(e))
      }
      setLoaded(true)
    }
    void fetchData()
  }, [refresh])

  const onRefresh = (): void => {
    setReferesh(new Date())
    props.onRefresh()
  }

  if (!loaded) return <CircularProgress role='loading' />
  if (unauthorized) return <NotAllowed />
  if (child === undefined) return <Box><Typography>Child not found</Typography></Box>

  return <ChildDetailDisplay
    address={childAddress}
    caregivers={childCaregivers}
    child={child}
    siblings={childSiblings}
    isdid={props.isdid}
    onRefresh={onRefresh}
    hideEditButtons={!perms.ServiceProviders_Edit && !perms.CareNetwork_Enrollment_Edit}
  />
}
