/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect } from 'react'
import { DocumentListEdit } from '../../../Components/DocumentList/DocumentListEdit'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { sendGet } from '../../../hooks/use-fetch'
import { type documentEditModel, type documentTypeModel } from '../../../Components/DocumentList'
import { Box, Typography } from '@mui/material'
import { useAuth } from '../../../hooks/use-auth'

export function AddChildDocuments (): JSX.Element {
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()
  const auth = useAuth()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (data.childDocuments.length === 0) {
        const isdid = data.isdID ?? data.manualDistrict?.isdId
        if (isdid !== null && isdid !== undefined) {
          const { response: rsp, success: suc } = await sendGet(`/Document/GetEmptyDocuments?isdId=${isdid}`, {})
          if (suc) {
            dispatch({ type: 'setDocumentTypes', documentsTypes: rsp })
          }
        }
      }
    }
    void fetchData()
  }, [])

  const handleDocumentChange = (newDocs: documentTypeModel[]): void => {
    dispatch({ type: 'updateChildDocuments', documents: [...newDocs] })
  }

  const handleDocumentDelete = async (id: number, documentTypeID: number, index: number): Promise<boolean> => {
    const docTypes = [...data.childDocuments ?? []]

    const alteringDoc = docTypes.find(dt => dt.documentTypeID === documentTypeID)
    if (alteringDoc !== undefined) {
      alteringDoc.documents.splice(index, 1)

      if (alteringDoc.documents.length === 0) {
        alteringDoc.documentStatus = 'Missing'
      }
      handleDocumentChange(docTypes)
    }
    return true
  }

  const handleUpload = async (documentTypeID: number, file: File): Promise<boolean> => {
    const docTypes = [...data.childDocuments]
    const alteringDoc = docTypes.find(dt => dt.documentTypeID === documentTypeID)
    const newDoc: documentEditModel = {
      fileName: file.name,
      verificationDetails: '',
      verified: false,
      verifiedBy: null,
      verifiedDate: null,
      whenUploaded: new Date(),
      file,
      incomeYear: null
    } as documentEditModel
    if (alteringDoc !== undefined) {
      alteringDoc.documentStatus = 'Unverified'
      alteringDoc.documents.push(newDoc)
      handleDocumentChange(docTypes)
    }
    return true
  }

  const handleDocumentVerifiedChanged = async (documentTypeID: number, index: number, verified: boolean): Promise<boolean> => {
    const docTypes = [...data.childDocuments ?? []]
    const alteringDoc = docTypes.find(dt => dt.documentTypeID === documentTypeID)
    if (alteringDoc !== undefined) {
      const doc = alteringDoc.documents[index]
      doc.verified = verified
      doc.verifiedBy = verified ? auth.user?.email ?? '' : ''
      doc.verifiedDate = verified ? new Date() : null
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      alteringDoc.documents.some(d => !d.verified) ? alteringDoc.documentStatus = 'Unverified' : alteringDoc.documentStatus = 'Verified'
      handleDocumentChange(docTypes)
    }
    return true
  }

  return <Box>
    <Typography variant='body1' fontWeight={'bold'}>Documents for {data.child?.firstName} {data.child?.lastName} {data.child?.suffix}</Typography>
    <DocumentListEdit
      documentTypes={data.childDocuments}
      editable={true}
      canVerify={true}
      onDelete={handleDocumentDelete}
      onUpload={handleUpload}
      onVerify={handleDocumentVerifiedChanged}
      onEdit={undefined}
      showDisclaimer={true}
      incomeRecords={null}
    />

    {data.caregivers.map((caregiver) => {
      const handleCaregiverDocumentChange = (newDocs: documentTypeModel[]): void => {
        dispatch({ type: 'updateCaregiverDocuments', index: caregiver.index, documents: [...newDocs] })
      }

      const handleCaregiverDocumentDelete = async (id: number, documentTypeID: number, index: number): Promise<boolean> => {
        const docTypes = [...caregiver.caregiver.documents ?? []]

        const alteringDoc = docTypes.find(dt => dt.documentTypeID === documentTypeID)
        if (alteringDoc !== undefined) {
          alteringDoc.documents.splice(index, 1)

          if (alteringDoc.documents.length === 0) {
            alteringDoc.documentStatus = 'Missing'
          }
          handleCaregiverDocumentChange(docTypes)
        }

        return true
      }

      const handleCaregiverUpload = async (documentTypeID: number, file: File, year: number | null): Promise<boolean> => {
        const docTypes = [...caregiver.caregiver.documents ?? []]
        const alteringDoc = docTypes.find(dt => dt.documentTypeID === documentTypeID)
        const newDoc: documentEditModel = {
          verificationDetails: '',
          verified: false,
          verifiedBy: null,
          verifiedDate: null,
          whenUploaded: new Date(),
          incomeYear: year,
          fileName: file.name,
          file
        } as documentEditModel
        if (alteringDoc !== undefined) {
          alteringDoc.documentStatus = 'Unverified'
          alteringDoc.documents.push(newDoc)
          handleCaregiverDocumentChange(docTypes)
        }
        return true
      }

      const handleCaregiverDocumentVerifiedChanged = async (documentTypeID: number, index: number, verified: boolean): Promise<boolean> => {
        const docTypes = [...caregiver.caregiver.documents ?? []]
        const alteringDoc = docTypes.find(dt => dt.documentTypeID === documentTypeID)
        if (alteringDoc !== undefined) {
          const doc = alteringDoc.documents[index]
          doc.verified = verified
          doc.verifiedBy = verified ? auth.user?.email ?? '' : ''
          alteringDoc.documents.some(d => !d.verified) ? alteringDoc.documentStatus = 'Unverified' : alteringDoc.documentStatus = 'Verified'

          handleCaregiverDocumentChange(docTypes)
        }
        return true
      }

      return <Box className={'mt-10'} key={caregiver.index}>
        <Typography variant='body1' fontWeight={'bold'}>Documents for {caregiver.caregiver.firstName} {caregiver.caregiver.lastName}</Typography>
        <DocumentListEdit
          documentTypes={caregiver.caregiver.documents ?? []}
          editable={true}
          canVerify={true}
          onDelete={handleCaregiverDocumentDelete}
          onUpload={handleCaregiverUpload}
          onVerify={handleCaregiverDocumentVerifiedChanged}
          onEdit={undefined}
          showDisclaimer={false}
          incomeRecords={caregiver.caregiver.incomes ?? null}
        />
      </Box>
    })}
  </Box>
}
