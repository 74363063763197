import { Box } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { type User } from '../core/types'
import { useAuth } from '../hooks/use-auth'
import './ProtectedRoute.css'
import { Container } from '@mui/system'

interface Props {
  allow: (user: User) => boolean
  allowAnonymous?: boolean
  noContainer?: boolean
}

export const ProtectedRoute = (props: Props): JSX.Element => {
  const auth = useAuth()
  const nav = useNavigate()
  const route = useLocation()

  if (auth.loading) return <></>

  if ((auth.user === null || auth.user.inactive) && props.allowAnonymous !== true) {
    nav('/', { state: { returnUrl: route.pathname } })
    return <></>
  }

  let outlet = <Outlet />
  let cnt = outlet
  if (props.noContainer === undefined || !props.noContainer) { cnt = outlet = <Container className='outletContainer'>{outlet}</Container> }

  // Allow access to the page if the user has permission or if the page allows anonymous access and the user is not logged in.
  // In the event that a logged in user does not have the permission to access an anonymous page, they are still
  // considered unauthorized so as to avoid unexpected behavior.
  // Signing in to an invalid account with the "noAccount" flag counts as not signed in for the purposes of the caregiver portal.
  return (props.allowAnonymous === true && (auth.user == null || auth.user.noAccount)) || (auth.user != null && props.allow(auth.user)) ? cnt : <NotAllowed />
}

export const NotAllowed = (): JSX.Element => {
  return (
    <Box justifyContent="center" display={'flex'}>
      You are not authorized to access this page
    </Box>
  )
}
