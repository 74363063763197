import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from '@mui/material'
import { TabDisplay } from '../../../Components/TabDisplay'
import { SERVICE_PROVIDER_ADMIN_FACILITIES, SERVICE_PROVIDER_ADMIN_SETTINGS } from '../../Routes'
import { useNavigate } from 'react-router'
import { FacilityRooms } from './SettingsFacilityRooms'
import { FacilityDetailsView } from './FacilityDetailsView'
import { useState } from 'react'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'

export function FacilityPage (): JSX.Element {
  const nav = useNavigate()
  const [value, setValue] = useState(0)

  return (
    <Box>
      <Typography variant="h3">Facility Details</Typography>

      <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ marginTop: '10px', marginBottom: '10px' }}>
        <Link underline="hover" sx={{ cursor: 'pointer' }} color="inherit" onClick={() => { nav(SERVICE_PROVIDER_ADMIN_SETTINGS) }} >
          Service Provider Settings
        </Link>
        <Link underline="hover" sx={{ cursor: 'pointer' }} color="inherit" onClick={() => { nav(SERVICE_PROVIDER_ADMIN_FACILITIES) }}>
          Facilities
        </Link>
      </Breadcrumbs>

      <Tabs value={value}
        data-test-tabs
        onChange={(e, val) => { setValue(val) }}
        aria-label="Facility Detail Tabs"
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Details" value={0} data-test-details />
        <Tab label="Facility Rooms" value={1} data-test-rooms />
      </Tabs>
      <TabDisplay
        index={0}
        value={value}
      >
        <FacilityDetailsView />
      </TabDisplay>
      <TabDisplay
        index={1}
        value={value}
      >
        <ErrorProvider>
          <FacilityRooms />
        </ErrorProvider>
      </TabDisplay>
    </Box>
  )
}
