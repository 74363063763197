import { Box, Button, Grid, Typography } from '@mui/material'
import { LargeCard } from '../../../Components/Cards/Large'
import { TextFieldWithLabel } from '../../../Components/TextField'
import DragDropFiles from '../../../Components/DragAndDrop'
import { LabelWithToolTip } from '../../../Components/Label/Index'
import { useEffect, useState } from 'react'
import { sendPostFormData, sendPut } from '../../../hooks/use-fetch'
import CancelIcon from '@mui/icons-material/Cancel'
import { type location, type postError } from '../../../core/types'
import S3Image from '../../../Components/S3Image'
import { Modal } from '../../../Components/Modal'
import { type SpDetails } from './DetailsView'
import { ErrorDisplay, useErrorDispatch } from '../../../ContextProviders/ErrorProvider'
interface ServiceProviderDetailsEditProps {
  details: SpDetails
  location: location
  onSave: () => void
  onClose: () => void
  show: boolean
}
export function DetailsEdit (props: ServiceProviderDetailsEditProps): JSX.Element {
  const [data, setData] = useState<SpDetails>(props.details)
  const dispatch = useErrorDispatch()
  useEffect(() => {
    setData(props.details)
    setLoc(props.location)
    dispatch({ type: 'clear', errors: [] })
  }, [props.show])

  const [files, setFiles] = useState<FileList | null>(null)
  const [loc, setLoc] = useState<location>(props.location)
  const handleSubmit = async (): Promise<void> => {
    const request = { data, location: loc }
    const { response: rsp } = await sendPut('/ServiceProviderDetails/Update', request)
    const result: { response: number, errors: postError[], success: boolean } = rsp
    if (result.success) {
      if (files != null) {
        const fileData = new FormData()
        fileData.append('id', data.id.toString())
        fileData.append('file', files[0])
        const { response: s3Key } = await sendPostFormData('/ServiceProviderDetails/UploadLogo', fileData)
        const strVal: string | null = s3Key ?? null
        const updatedSp = { ...data, imageKey: strVal }
        setData(updatedSp)
      }
      props.onSave()
      dispatch({ type: 'clear', errors: result.errors })
    } else {
      dispatch({ type: 'update', errors: result.errors })
    }
  }
  const handleChange = (e: any): void => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const identityDisplay = <Box width={'100%'}>
    <TextFieldWithLabel label='Name' data-testid='sp-name' onChange={handleChange} value={data?.name ?? ''} name='name'/>
    <TextFieldWithLabel label='EEM Code' data-testid='sp-eem' onChange={handleChange} value={data?.eemCode ?? ''} name='eemCode' />
  </Box>
  const removeLogo = async (): Promise<void> => {
    const updatedIsd = { ...data, imageKey: null }
    setData(updatedIsd)
    setFiles(null)
    const fileData = new FormData()
    fileData.append('id', data.id.toString())
    await sendPostFormData('/ServiceProviderDetails/UploadLogo', fileData)
  }

  const informationDisplay = <Box width={'100%'}>
    <ErrorDisplay fieldName='info' />

    <TextFieldWithLabel label='Website' onChange={handleChange} value={data?.website ?? ''} name='website'/>
    <LabelWithToolTip labelText='Logo' toolTipText='Upload Icon.' />
    {data.imageKey != null && <Box>
      <S3Image style={{ maxWidth: '100px', maxHeight: '100px' }} imageKey={data.imageKey ?? ''} alt='logo' />
      <Button onClick={removeLogo} data-testid='remove-logo' sx={{ paddingLeft: 0, verticalAlign: 'top', minWidth: '45px' }}><CancelIcon sx={{ color: 'black' }} /></Button>
    </Box>
    }
    {files !== null && files !== undefined && (files?.length ?? 0) > 0 && (<>
      Uploading {files[0].name}
    </>)}
    <DragDropFiles files={files} setFiles={setFiles} instructions='+ Add Image' fileType='image/*' removable={false} />
  </Box>

  const contactDisplay = <Grid container sx={{ display: 'flex', flexDirection: 'row' }} spacing={1} columns={12}>
    <ErrorDisplay fieldName='contact' />

    <Grid item sm={6} md={6}>
      <TextFieldWithLabel label='Email' onChange={handleChange} value={data?.contactEmail ?? ''} name='contactEmail' />
    </Grid>
    <Grid item sm={6} md={6}>
      <TextFieldWithLabel label='Phone' onChange={handleChange} value={data?.contactPhone ?? ''} name='contactPhone' />
    </Grid>
  </Grid>

  const content = <Box width={'100%'}>
    <Typography>Settings - {data?.name}</Typography>
    <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columns={12} rowSpacing={1}>
      <Grid item sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <LargeCard
          fullWidth
          header='Name & Identity'
          content={identityDisplay}
        />

      </Grid>
      <Grid item sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>

        <LargeCard
          fullWidth
          header='Information'
          content={informationDisplay}
        />
      </Grid>
      <Grid item sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>

        <LargeCard
          fullWidth
          header='Contact'
          content={contactDisplay}
        />
      </Grid>
    </Grid>
  </Box>
  return <Modal
    onClose={props.onClose}
    open={props.show}
    onConfirm={handleSubmit }
    confirmationContent={content}
  />
}
