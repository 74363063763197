import { Box } from '@mui/material'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { CheckBoxWithLabel } from '../../Components/CheckBox'

export interface SentSearchCriteria {
  timeFrameDisplay: string
  actionInLast30Days: boolean
}

export function SentSearch (props: { current: SentSearchCriteria, onChange: (newSearch: SentSearchCriteria) => void, addButton?: JSX.Element }): JSX.Element {
  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }

  const timeFrameOptions: Array<{ id: string, name: string }> = [
    { id: 'Immediate', name: 'Immediate' },
    { id: 'upcomingSchoolYear', name: 'Upcoming School Year' },
    { id: 'both', name: 'Both' }
  ]

  return <Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
    <SelectWithLabel
      name='timeFrameDisplay'
      label='Time Frame'
      width='250px'
      value={props.current.timeFrameDisplay}
      options={timeFrameOptions}
      onChange={handleChange}
    />
    <CheckBoxWithLabel
      edge='start'
      label='Recently Sent'
      name='recentlySent'
      value={props.current.actionInLast30Days}
      setValue={function (newVal: boolean): void {
        props.onChange({ ...props.current, actionInLast30Days: newVal })
      }}
    />
    {props.addButton}
  </Box>
}
