import { type ChildEditValues } from '../../../core/types'
import { useEffect, useState } from 'react'
import { AcceptDenyModal } from '../../../Components/Modal/AcceptDenyModal'
import { sendDelete, sendGet } from '../../../hooks/use-fetch'

export interface ChildMergeProps {
  open: boolean
  child: ChildEditValues
  onClose: (deleted: boolean) => void
}

export function ChildMerge (props: ChildMergeProps): JSX.Element {
  const [canDelete, setCanDelete] = useState<boolean | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const url = `/ChildDetails/CanRemove?childId=${props.child.id}`
      const { response, success } = await sendGet(url, {})
      if (success) {
        setCanDelete(response === 'Y')
      }
    }
    void fetchData()
  })

  const titleContent = <div className="d-flex f-align-items-center">
    Remove Child Record
  </div>

  const handleClose = (): void => {
    props.onClose(false)
  }

  const handleSubmit = async (): Promise<void> => {
    if (canDelete === true) {
      const url = `/ChildDetails/DeleteChild?childId=${props.child.id}`
      const { success } = await sendDelete(url)
      if (success) {
        props.onClose(true)
      }
    } else {
      props.onClose(false)
    }
  }

  const content = (canDelete !== null && !canDelete)
    ? <div>Only children with no services active can be removed.</div>
    : (canDelete === true ? <div>This will permanently remove this child record.  Are you sure you want to to continue?</div> : null)

  return <AcceptDenyModal
    open={props.open && canDelete !== null}
    titleContent={titleContent}
    dialogContent={content}
    onClose={handleClose}
    onAccept={handleSubmit}
    acceptButtonText={canDelete === true ? 'Yes, Confirm' : 'Close'}
    cancelButtonText='No, Cancel'
    hideCancelButton={canDelete === false}
  />
}
