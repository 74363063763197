import { Box, Button, CircularProgress, InputLabel } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { type ISDDetail } from '.'
import { useEffect, useState } from 'react'
import { TextFieldWithLabel } from '../../Components/TextField'
import { LargeCard } from '../../Components/Cards/Large'
import S3Image from '../../Components/S3Image'
import DragDropFiles from '../../Components/DragAndDrop'
import { sendGet, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import CancelIcon from '@mui/icons-material/Cancel'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { SERVICE_PROVIDER_MANAGEMENT } from '../Routes'
import { useNavigate } from 'react-router'
import WarningIcon from '@mui/icons-material/Warning'
import { type postError } from '../../core/types'
import { useErrorDispatch } from '../../ContextProviders/ErrorProvider'

interface ISDDetailsEditProps {
  onClose: () => void
  show: boolean
  isd: ISDDetail
  onConfirm: () => void
}

export function ISDDetailsEdit (props: ISDDetailsEditProps): JSX.Element {
  const [isd, setIsd] = useState<ISDDetail>(props.isd)
  const [files, setFiles] = useState<FileList | null>(null)
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [availableProviders, setProviders] = useState<Array<{ id: number, name: string }>>([])
  const dispatch = useErrorDispatch()

  const nav = useNavigate()
  dispatch({ errors: [], type: 'clear' })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: providers } = await sendGet('/ISDDetails/GetIntakeProviders?id=' + isd.id.toString(), {})
      setProviders(providers)
    }
    void fetchData()
  }, [])

  const handleChange = (e: any): void => {
    setIsd({ ...isd, [e.target.name]: e.target.value })
  }

  const onConfirm = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      const result = await sendPut('/ISDDetails/Update', isd)
      const resp: { newRecordID: number, success: boolean, errors: postError[] } = result.response

      if (resp.success) {
        const fileData = new FormData()
        fileData.append('id', isd.id.toString())
        if (files != null) {
          fileData.append('file', files[0])
          const { response: s3Key } = await sendPostFormData('/ISDDetails/UploadLogo', fileData)
          const updatedIsd = { ...isd, logoKey: s3Key }
          setIsd(updatedIsd)
        } else {
          await sendPostFormData('/ISDDetails/UploadLogo', fileData)
        }
        props.onConfirm()
      } else {
        dispatch({ errors: resp.errors, type: 'update' })
      }
      setIsRequestLoading(false)
    }
  }

  const uploadFile = (files: FileList | null): void => {
    setFiles(files)
  }

  const removeLogo = async (): Promise<void> => {
    const updatedIsd = { ...isd, logoKey: null }
    setIsd(updatedIsd)
    setFiles(null)
  }

  if (isd === null) return <CircularProgress role='loading' />

  const locationContent = <>
    <Box>
      <TextFieldWithLabel label='Street Address' data-testid='address' name='address' maxLength={100} onChange={handleChange} value={isd.address} />
      <Box className="d-flex">
        <TextFieldWithLabel label='City' data-testid='city' name='city' maxLength={50} onChange={handleChange} value={isd.city} className='mr-5' />
        <TextFieldWithLabel label='State' data-testid='state' name='state' maxLength={2} onChange={handleChange} value={isd.state} className='mr-5 width-60px' />
        <TextFieldWithLabel label='Zip' data-testid='zip-code' name='zipCode' maxLength={5} onChange={handleChange} value={isd.zipCode} />
      </Box>
    </Box>
  </>

  const infoContent = <Box>
    <TextFieldWithLabel pattern='.+\..+' title="Please include a '.' within the url" label='Website' data-testid='website' name='website' maxLength={200} onChange={handleChange} value={isd.website ?? ''} />
    <InputLabel
      sx={{ color: 'black' }}
      id='Logo label'
      disableAnimation
      shrink={false}
      variant="standard"
    >
      Logo
    </InputLabel>
    {isd.logoKey != null &&
      <Box>
        <S3Image alt='logo' imageKey={isd.logoKey} />
        <Button onClick={removeLogo} data-testid='remove-logo' sx={{ paddingLeft: 0, verticalAlign: 'top', minWidth: '45px' }}><CancelIcon sx={{ color: 'black' }} /></Button>
      </Box>
    }
    <DragDropFiles files={files} setFiles={uploadFile} instructions='+ Add Image' fileType='image/*' removable={false} />
  </Box>

  const contactContent = <Box className="d-flex">
    <TextFieldWithLabel type='email' data-testid='contact-email' label='Email' name='contactEmail' maxLength={100} onChange={handleChange} value={isd.contactEmail ?? ''} className='mr-5' />
    <TextFieldWithLabel type='tel' label='Phone' data-testid='phone' name='contactPhone' maxLength={50} onChange={handleChange} value={isd.contactPhone ?? ''} />
  </Box>

  const providerContent = <Box>
    <SelectWithLabel options={availableProviders} value={isd.intakeProviderID ?? undefined} onChange={handleChange} name='intakeProviderID' label={'Intake Provider'} width={'100%'} />

    <Box className='d-flex f-justify-content-center'>
      <Button variant='text' sx={{ color: '#595959', textTransform: 'none' }} onClick={() => { nav(SERVICE_PROVIDER_MANAGEMENT) }}>Add New Provider +</Button>
    </Box>
  </Box>

  const connectionContent = <Box className="d-flex">
    <TextFieldWithLabel label='UIC API Key' data-testid='uicapiKey' name='uicapiKey' onChange={handleChange} value={isd.uicapiKey ?? ''} className='mr-5' />
    <TextFieldWithLabel label='UIC API Secret' data-testid='uicapiSecret' name='uicapiSecret' onChange={handleChange} value={isd.uicapiSecret ?? ''} />
  </Box>

  const content =
    <Box>
      <LargeCard header='Location' content={locationContent} fullWidth={true} />
      <LargeCard header='Information' content={infoContent} className='mt-10' fullWidth={true} />
      <LargeCard header='Contact' content={contactContent} className='mt-10' fullWidth={true} />
      <LargeCard header='Intake' content={providerContent} icon={<> {isd.intakeProviderID === null && <WarningIcon sx={{ marginLeft: '10px' }} />} </>} className='mt-10' fullWidth={true} />
      <LargeCard header='API Connection' content={connectionContent} fullWidth={true} className='mt-10' />
    </Box>

  return (
    <Modal
      onClose={props.onClose}
      onConfirm={onConfirm}
      open={props.show}
      confirmationContent={content}
      title='Edit ISD Details'
    />
  )
}
