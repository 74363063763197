import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { TextAreaWithLabel } from '../TextArea'

interface Props {
  name: string
  onClick: () => void
  checked: boolean
  text?: string
  labelContent?: JSX.Element
  unclickableContent?: JSX.Element
  hasTextArea?: boolean
  textAreaValue?: string
  setTextAreaValue?: (e: any) => void
  disabled?: boolean
  flex?: boolean
  edge: 'end' | 'start'
  textClassName?: string
  buttonClassName?: string
  checkboxClassName?: string
  dataTestId?: string
}

export function CheckListItem (props: Props): JSX.Element {
  const disabled = props.disabled ?? false
  const flex = props.flex ?? false

  const icon = (<ListItemIcon sx={{ height: '30px' }}>
    {disabled ? <CircleIcon sx={{ height: '10px', mt: 1 }} /> : null}

    {!disabled && (
      <Checkbox
        checked={props.checked}
        disableRipple
        inputProps={{ 'aria-labelledby': props.name, 'aria-label': props.name }}
        sx={{ height: '30px' }}
        tabIndex={-1}
        className={props.checkboxClassName}
      />
    )}
  </ListItemIcon>)

  const text = (<ListItemText
    id={props.name}
    primary={props.labelContent ?? props.text}
    sx={{ height: '30px' }}
    className={props.textClassName}
  />)

  return (<List sx={{ padding: 0 }}>
    <ListItem
      dense
      disablePadding
      key={props.name}
      sx={{ height: '30px', maxWidth: flex ? '100px' : '100%' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemButton
          dense
          disabled={props.disabled}
          data-testid={props.dataTestId}
          onClick={props.onClick}
          role={undefined}
          sx={{ height: '30px' }}
          className={props.buttonClassName}
        >
          {props.edge === 'start' && (
            <>
              {icon}
              {text}
            </>
          )}

          {props.edge !== 'start' && (
            <>
              {text}
              {icon}
            </>
          )}
        </ListItemButton>

        {props.unclickableContent}
      </Box>
    </ListItem>
    {(props.hasTextArea !== null && props.hasTextArea === true) && props.checked && (props.setTextAreaValue != null) && (
      <TextAreaWithLabel
        label=""
        name="other"
        disabled={props.disabled}
        value={props.textAreaValue}
        onChange={props.setTextAreaValue}
      />
    )}
  </List>
  )
}
