import { Button, Grid, Link, Typography } from '@mui/material'
import { type lookup, type EnrollmentDetails, type PortalLocation, type PortalChildDetails, type race } from '../../../../core/types'
import { TextFieldWithLabel } from '../../../../Components/TextField'
import { DatePickerWithLabel } from '../../../../Components/DatePicker'
import { SelectWithLabel } from '../../../../Components/SelectWithLabel'
import { CheckBoxWithLabel } from '../../../../Components/CheckBox'
import { useEffect, useState } from 'react'
import { Modal } from '../../../../Components/Modal'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { toast } from 'react-toastify'
import { sendGet } from '../../../../hooks/use-fetch'
import { LocationDetails } from '../LocationDetails'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useParams } from 'react-router'
import { AgeFromDate } from '../../../../core/Utilities'
import { CheckBoxWithCustomLabel } from '../../../../Components/CustomLabel/CheckboxWithCustomLabel'
import { useScrollTop } from '../../../../hooks/use-scrollTop'
import AlertMessage from '../../../../Components/AlertMessage'

interface AddChildDetailsProps {
  caregiverForm: EnrollmentDetails
}

interface FormField {
  isDirty: boolean
  isValid: boolean
}

interface ValidationState {
  firstName: FormField
  lastName: FormField
  dateOfBirth: FormField
  race: FormField
  dueDate: FormField
  address: FormField
  city: FormField
  state: FormField
  zipCode: FormField
}

const genderOptions: Array<{ id: string, name: string }> = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' }
]

export function AddChildDetails (props: AddChildDetailsProps): JSX.Element {
  const { isdId } = useParams()
  const [languages, setLanguages] = useState<lookup[]>([])
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [validationState, setValidationState] = useState<ValidationState>({
    firstName: { isDirty: false, isValid: false },
    lastName: { isDirty: false, isValid: false },
    dateOfBirth: { isDirty: false, isValid: false },
    race: { isDirty: false, isValid: false },
    dueDate: { isDirty: false, isValid: false },
    address: { isDirty: false, isValid: false },
    city: { isDirty: false, isValid: false },
    state: { isDirty: false, isValid: false },
    zipCode: { isDirty: false, isValid: false }
  })
  const [bingMapsLocation, setBingMapsLocation] = useState<PortalLocation | undefined>(undefined)
  const caregiverForm = props.caregiverForm
  const dispatch = useCaregiverWizardUpdateDispatch()
  const [ethnicityItem, setEthnicityItem] = useState<race>()
  const [raceItems, setRaceItems] = useState<race[]>([])
  const [captureIEP, setCaptureIEP] = useState(false)
  const [captureIFSP, setCaptureIFSP] = useState(false)
  const [captureEOIS, setCaptureEOIS] = useState(false)
  const [outOfISDWarning, setOutOfISDWarning] = useState<boolean>(false)

  useScrollTop()

  // The bing maps location is set in the useBingMaps hook, but that has issues with state changes
  useEffect(() => {
    if (bingMapsLocation != null) {
      handleLocationChange(bingMapsLocation)
    }
  }, [bingMapsLocation])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: langs, error: err1 } = await sendGet('/Language/GetLookup?sort=true', {})
      if (err1[0] === '') {
        setLanguages(langs)
      }

      const { response, error } = await sendGet('/Race/GetLookup', {})
      if (error[0] === '') {
        const serverItems: race[] = response
        const raceList: race[] = []
        serverItems.forEach(r => {
          if (r.id === 4) {
            setEthnicityItem(r)
          } else {
            raceList.push(r)
          }
        })
        setRaceItems(raceList)
      }

      const { response: isdRsp } = await sendGet(`/ISDDetails/GetDetails?ID=${isdId ?? ''}`, {})
      if (isdRsp != null) {
        setCaptureIEP(isdRsp.captureIEP)
        setCaptureIFSP(isdRsp.captureIFSP)
        setCaptureEOIS(isdRsp.captureEOIS)
      }
    }
    void fetchData()
  }, [])

  function RaceCheckBox (r: race): JSX.Element {
    return <CheckBoxWithLabel
      edge={'start'}
      key={r.id}
      label={r.name}
      name={r.name}
      dataTestId={r.name}
      value={child.races.some(race => race === r.id)}
      setValue={(value) => { handleRaceChange(r.id, value) }}
      buttonClassName='no-horiz-padding'
      textClassName='vertical-center-align'
      checkboxClassName='no-horiz-padding'
    />
  }

  const child = caregiverForm.currentChild
  const unborn = caregiverForm.unbornChild
  const location = child.location
  const hasOtherChildren = caregiverForm.children.length > 0
  const isFirstChild = !hasOtherChildren || caregiverForm.children[0].isCurrentlyUpdating

  const handleDateOfBirthChange = (newDate: Date | null): void => {
    handleChildChange('dateOfBirth', newDate)

    const tempState = {
      ...validationState,
      dateOfBirth: { isDirty: true, isValid: newDate != null }
    }
    setValidationState(tempState)
  }

  const handleDueDateChange = (newDate: Date | null): void => {
    handleChildChange('dueDate', newDate)

    const tempState = {
      ...validationState,
      dueDate: { isDirty: true, isValid: newDate != null }
    }
    setValidationState(tempState)
  }

  const handleLanguageChange = (e: any): void => {
    handleChildChange('languageID', e.target.value)
  }

  const handleGenderChange = (e: any): void => {
    handleChildChange('birthGender', e.target.value)
  }

  const handleRaceChange = (id: number, value: boolean): void => {
    let races = [...child.races]
    if (value) {
      races.push(id)
    } else {
      races = races.filter(r => r !== id)
    }
    handleChildChange('races', races)

    if (!unborn) {
      const tempState = {
        ...validationState,
        race: { isDirty: true, isValid: races.length > 0 }
      }
      setValidationState(tempState)
    }
  }

  const handleFirstNameChange = (e: any): void => {
    handleChildChange('firstName', e.target.value)
    handleFirstNameBlur(e)
  }

  const handleFirstNameBlur = (e: any): void => {
    if (!unborn) {
      const tempState = {
        ...validationState,
        firstName: { isDirty: true, isValid: e.target.value !== '' }
      }
      setValidationState(tempState)
    }
  }

  const handleTextChange = (e: any): void => {
    handleChildChange(e.target.name, e.target.value)
  }

  const handleTextBlur = (e: any): void => {
    const tempState = {
      ...validationState,
      [e.target.name]: { isDirty: true, isValid: e.target.value !== '' }
    }
    setValidationState(tempState)
  }

  const handleCheckChange = (newVal: boolean, field: string): void => {
    handleChildChange(field, newVal)
  }

  const locationChanged = async (newLocation: PortalLocation): Promise<void> => {
    setBingMapsLocation(newLocation)

    const { response } = await sendGet(`/ReferralEdit/GetLocationISD?address=${newLocation.address}` +
      `&city=${newLocation.city}&state=${newLocation.state}&zipCode=${newLocation.zipCode}`, {})
    setOutOfISDWarning(response.id == null || response.id === 0 || response.isdID.toString() !== isdId)
  }

  const handleLocationChange = (newLocation: PortalLocation): void => {
    const tempChild = { ...child, location: newLocation }
    dispatch({ type: 'child', child: tempChild })

    if (!child.isHomeless) {
      const tempState = {
        ...validationState,
        address: { isDirty: true, isValid: newLocation.address !== '' },
        city: { isDirty: true, isValid: newLocation.city !== '' },
        state: { isDirty: true, isValid: newLocation.state !== '' },
        zipCode: { isDirty: true, isValid: newLocation.zipCode !== '' }
      }
      setValidationState(tempState)
    }
  }

  const handleLocationFieldChange = (e: any): void => {
    const tempLocation = { ...location, [e.target.name]: e.target.value }
    const tempChild = { ...child, location: tempLocation }
    dispatch({ type: 'child', child: tempChild })
  }

  const handleChildChange = (field: string, value: any): void => {
    const tempChild = { ...child, [field]: value }
    dispatch({ type: 'child', child: tempChild })
  }

  const handleConfirmUnborn = (form: EnrollmentDetails): void => {
    form.unbornChild = true
  }

  const handleConfirmBack = (form: EnrollmentDetails): void => {
    form.currentSubStep = 3
  }

  const handleUnbornClick = (): void => {
    setConfirmFunction(() => handleConfirmUnborn)
    setShowConfirmModal(true)
  }

  const handleBack = (): void => {
    setConfirmFunction(() => handleConfirmBack)
    setShowConfirmModal(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const [confirmFunction, setConfirmFunction] = useState<(form: EnrollmentDetails) => void>(() => (form: EnrollmentDetails) => { handleConfirmUnborn(form) })

  const handleConfirm = async (): Promise<void> => {
    const initialForm = initialEnrollmentDetails()
    const tempForm = { ...caregiverForm, currentChild: initialForm.currentChild }
    confirmFunction(tempForm)
    if (tempForm.currentSubStep === 3) {
      // If we are returning from editing a child, clear out the isCurrentlyUpdating flag
      const existingChild = tempForm.children.find(c => c.isCurrentlyUpdating)
      if (existingChild != null) {
        existingChild.isCurrentlyUpdating = false
      }
    }
    await saveSession(tempForm)
    setShowConfirmModal(false)
    dispatch({ type: 'form', form: tempForm })
  }

  const scrollToField = (field: string): void => {
    const element = document.getElementById(field)
    if (element != null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const validateForm = (): boolean => {
    const tempState = {
      ...validationState,
      firstName: { isDirty: true, isValid: child.firstName !== '' },
      lastName: { isDirty: true, isValid: child.lastName !== '' },
      dateOfBirth: { isDirty: true, isValid: child.dateOfBirth != null },
      race: { isDirty: true, isValid: child.races.length > 0 },
      dueDate: { isDirty: true, isValid: child.dueDate != null },
      address: { isDirty: true, isValid: location.address !== '' },
      city: { isDirty: true, isValid: location.city !== '' },
      state: { isDirty: true, isValid: location.state !== '' },
      zipCode: { isDirty: true, isValid: location.zipCode !== '' }
    }
    if (unborn) {
      tempState.firstName.isValid = true
      tempState.firstName.isDirty = false
      tempState.dateOfBirth.isValid = true
      tempState.dateOfBirth.isDirty = false
      tempState.race.isValid = true
      tempState.race.isDirty = false
    } else {
      tempState.dueDate.isValid = true
      tempState.dueDate.isDirty = false
    }
    if (child.livesWithFirstChild || child.isHomeless) {
      tempState.address.isValid = true
      tempState.address.isDirty = false
      tempState.city.isValid = true
      tempState.city.isDirty = false
      tempState.state.isValid = true
      tempState.state.isDirty = false
      tempState.zipCode.isValid = true
      tempState.zipCode.isDirty = false
    }
    setValidationState(tempState)

    if (!tempState.firstName.isValid) {
      scrollToField('firstName')
    } else if (!tempState.lastName.isValid) {
      scrollToField('lastName')
    } else if (!tempState.dateOfBirth.isValid) {
      scrollToField('dateOfBirth')
    } else if (!tempState.dueDate.isValid) {
      scrollToField('dueDate')
    } else if (!tempState.race.isValid) {
      scrollToField('race')
    } else if (!tempState.address.isValid) {
      scrollToField('address')
    } else if (!tempState.city.isValid) {
      scrollToField('city')
    } else if (!tempState.state.isValid) {
      scrollToField('state')
    } else if (!tempState.zipCode.isValid) {
      scrollToField('zipCode')
    }

    return Object.values(tempState).every(v => v.isValid)
  }

  const handleContinue = async (): Promise<void> => {
    const isFormValid = validateForm()
    if (!isFormValid) {
      toast.error('Please fill out all required fields to continue')
      return
    }

    const initialForm = initialEnrollmentDetails()
    const tempForm = { ...caregiverForm, currentChild: initialForm.currentChild, currentSubStep: 3 }
    const tempChild = { ...child, unborn }
    if (tempChild.livesWithFirstChild) {
      tempChild.location = caregiverForm.children[0].location
    }
    const existingChild = tempForm.children.find(c => c.isCurrentlyUpdating)
    if (existingChild != null) {
      // If we are updating an existing child, replace the child instead of adding a new one
      tempChild.isCurrentlyUpdating = false
      const index = tempForm.children.indexOf(existingChild)
      tempForm.children[index] = tempChild
    } else {
      tempForm.children.push(tempChild)
    }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const childName = (child: PortalChildDetails): string => {
    const firstName = child.firstName != null && child.firstName !== '' ? child.firstName : '(Unnamed)'
    return `${firstName} ${child.lastName}`
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' sx={{ mb: '30px' }} component='div' data-testid="add-child-details-prompt">
          {!hasOtherChildren &&
            <CustomLabelWithToolTip
              name='IF_Child_Intro'
              isdId={isdId ?? 0}
              defaultLabelText='First, we need to know a little about the child to help us find the best programs and services.'
            />}
          {hasOtherChildren &&
            <CustomLabelWithToolTip
              name='IF_Child_Intro_Next'
              isdId={isdId ?? 0}
              defaultLabelText='Tell us a little about your next child.'
            />}
        </Typography>
        {!hasOtherChildren && <Typography variant='subtitle1' sx={{ mb: '30px' }} component='div' data-testid="add-child-details-subprompt">
          <CustomLabelWithToolTip
            name='IF_Child_Subtext'
            isdId={isdId ?? 0}
            defaultLabelText={'If you have more than one child needing early childhood services, you\'ll have the option to add them later.'}
          />
        </Typography>}
        {!unborn && <Typography variant='subtitle1' sx={{ mb: '30px' }} component='div' data-testid="unborn-child-prompt">
          Is this an unborn child? Go <Link sx={{ cursor: 'pointer' }} onClick={handleUnbornClick}>here</Link> instead.
        </Typography>}
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithLabel
          label={'First Name Exactly as on Child\'s Birth Certificate'}
          name='firstName'
          maxLength={50}
          data-testid='firstName'
          value={child.firstName}
          showRequiredText={unborn || (validationState.firstName.isDirty && !validationState.firstName.isValid)}
          required={!unborn && validationState.firstName.isDirty && !validationState.firstName.isValid}
          error={!unborn && validationState.firstName.isDirty && !validationState.firstName.isValid}
          onChange={handleFirstNameChange}
          onBlur={handleFirstNameBlur}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithLabel
          label='Nickname'
          name='nickname'
          maxLength={50}
          data-testid='nickname'
          value={child.nickname}
          showRequiredText={true}
          required={false}
          onChange={handleTextChange}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithLabel
          label='Middle Name'
          name='middleName'
          maxLength={50}
          data-testid='middleName'
          value={child.middleName}
          showRequiredText={true}
          required={false}
          onChange={handleTextChange}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithLabel
          label={'Last Name Exactly as on Child\'s Birth Certificate'}
          name='lastName'
          maxLength={50}
          data-testid='lastName'
          value={child.lastName}
          showRequiredText={validationState.lastName.isDirty && !validationState.lastName.isValid}
          required={validationState.lastName.isDirty && !validationState.lastName.isValid}
          error={validationState.lastName.isDirty && !validationState.lastName.isValid}
          onChange={handleTextChange}
          onBlur={handleTextBlur}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>

      {!unborn && <>
        <Grid item xs={6}>
          <TextFieldWithLabel
            label='Suffix'
            name='suffix'
            maxLength={50}
            value={child.suffix}
            showRequiredText={true}
            required={false}
            onChange={handleTextChange}
            className='pt-0'
            textFieldClassName='pb-0'
          />
        </Grid>
        <Grid item xs={6}>
          <DatePickerWithLabel
            name='dateOfBirth'
            dataTestId='dateOfBirth'
            value={child.dateOfBirth}
            showRequiredText={validationState.dateOfBirth.isDirty && !validationState.dateOfBirth.isValid}
            required={validationState.dateOfBirth.isDirty && !validationState.dateOfBirth.isValid}
            error={validationState.dateOfBirth.isDirty && !validationState.dateOfBirth.isValid}
            onChange={handleDateOfBirthChange}
            onBlur={handleTextBlur}
            FormControlProps={{ className: 'pb-0 width-100' }}
            noPaddingTop={true}
            labelContent={<CustomLabelWithToolTip
              name='IF_Child_DOB'
              isdId={isdId ?? 0}
              defaultLabelText='Date of Birth'
              defaultTooltipText={'Exact Date as appears on Child\'s Birth Certificate'}
            />}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectWithLabel
            name="birthGender"
            width="100%"
            value={child.birthGender}
            showRequiredText={true}
            required={false}
            options={genderOptions}
            onChange={handleGenderChange}
            FormControlProps={{ className: 'pb-0' }}
            labelContent={<CustomLabelWithToolTip
              name='IF_Child_Gender'
              isdId={isdId ?? 0}
              defaultLabelText='Gender'
              defaultTooltipText={'This gender must match the gender on your child\'s birth certificate'}
            />}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectWithLabel
            name="primaryLanguage"
            label="Primary Language (if not English)"
            width="100%"
            value={child.languageID?.toString() ?? ''}
            showRequiredText={true}
            required={false}
            includeNoneOption={true}
            options={languages.map(l => { return { id: l.id.toString(), name: l.name } })}
            onChange={handleLanguageChange}
            FormControlProps={{ className: 'pb-0' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="subtitle1" id='race'>Additional Information</Typography>
          {/* taking child's age into consideration for when to show the following */}
          {captureIEP && child.dateOfBirth !== null && AgeFromDate(child.dateOfBirth) >= 3 && <CheckBoxWithLabel
            edge='start'
            name='hasIEP'
            value={child.hasIEP}
            setValue={(val) => { handleCheckChange(val, 'hasIEP') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            labelContent={<CustomLabelWithToolTip
              name='IF_Child_IEP'
              isdId={isdId ?? 0}
              defaultLabelText='Has an Individualized Education Plan (IEP)'
            />}
          />
          }
          {captureIFSP && child.dateOfBirth !== null && AgeFromDate(child.dateOfBirth) < 3 && <CheckBoxWithLabel
            edge='start'
            name='hasIFSP'
            value={child.hasIFSP}
            setValue={(val) => { handleCheckChange(val, 'hasIFSP') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            labelContent={<CustomLabelWithToolTip
              name='IF_Child_IFSP'
              isdId={isdId ?? 0}
              defaultLabelText='Has an Individualized Family Service Plan (IFSP)'
            />}
          />
          }
          {captureEOIS && child.dateOfBirth !== null && AgeFromDate(child.dateOfBirth) >= 3 && <CheckBoxWithLabel
            edge='start'
            name='hasEOIS'
            value={child.hasEOIS}
            setValue={(val) => { handleCheckChange(val, 'hasEOIS') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            labelContent={<CustomLabelWithToolTip
              name='IF_Child_EOIS'
              isdId={isdId ?? 0}
              defaultLabelText='Has an Early On Intervening Service Plan (EOIS)'
            />}
          />
          }
          <CheckBoxWithLabel
            edge='start'
            name='fosterChild'
            value={child.fosterChild}
            setValue={(val) => { handleCheckChange(val, 'fosterChild') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            labelContent={<CustomLabelWithToolTip
              name='IF_Child_Foster'
              isdId={isdId ?? 0}
              defaultLabelText='Foster Child'
            />}
          />
          <CheckBoxWithCustomLabel
            edge='start'
            name='isHomeless'
            value={child.isHomeless}
            setValue={(val) => { handleCheckChange(val, 'isHomeless') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            labelName='IF_Child_Homeless'
            isdId={isdId ?? 0}
            defaultLabelText='Currently Experiencing Homelessness'
            defaultTooltipText='Checking this box will make the address below optional'
          />
          <CheckBoxWithCustomLabel
            edge='start'
            name='hasSNAPBenefits'
            value={child.hasSNAPBenefits}
            setValue={(val) => { handleCheckChange(val, 'hasSNAPBenefits') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            labelName='IF_Child_SNAP'
            isdId={isdId ?? 0}
            defaultLabelText='SNAP Benefits (food assistance)'
            defaultTooltipText='The Supplemental Nutrition Assistance Program (SNAP) is a federal program that provides monthly benefits to low-income individuals and families to buy groceries.'
          />
          <CheckBoxWithLabel label='Cash Assistance' name='isOnCashAssistance' edge='start'
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            value={child.isOnCashAssistance ?? false} setValue={(val) => { handleCheckChange(val, 'isOnCashAssistance') }}
          />
          <CheckBoxWithLabel label='SSI (Supplemental Security Income)' name='isOnSSI' edge='start'
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
            value={child.isOnSSI ?? false} setValue={(val) => { handleCheckChange(val, 'isOnSSI') }}
          />
        </Grid>
      </>}

      {unborn && <>
        <Grid item xs={6}>
          <DatePickerWithLabel
            label='Due Date'
            name='dueDate'
            dataTestId='dueDate'
            value={child.dueDate}
            showRequiredText={validationState.dueDate.isDirty && !validationState.dueDate.isValid}
            required={validationState.dueDate.isDirty && !validationState.dueDate.isValid}
            error={validationState.dueDate.isDirty && !validationState.dueDate.isValid}
            onChange={handleDueDateChange}
            onBlur={handleTextBlur}
            FormControlProps={{ className: 'pb-0 width-100' }}
            noPaddingTop={true}
          />
        </Grid>
      </>}

      <Grid item xs={12}>
        {!unborn && validationState.race.isDirty && !validationState.race.isValid &&
          <Typography component="div" variant="subtitle1" sx={{ color: '#E00000' }}>Please add at least one race.</Typography>
        }
        <Typography component="span" variant="subtitle1" id='race'>Race</Typography>
        {raceItems.map(r => {
          return RaceCheckBox(r)
        })}

        <Typography component="span" variant="subtitle1" id='race'>Ethnicity</Typography>
        {ethnicityItem != null && RaceCheckBox(ethnicityItem)}

      </Grid>

      {hasOtherChildren && !isFirstChild &&
        <Grid item xs={12} sx={{ mt: '30px' }}>
          <CheckBoxWithLabel
            edge='start'
            label={`Lives with ${childName(caregiverForm.children[0])}`}
            name='livesWithFirstChild'
            dataTestId='lives-with-checkbox'
            value={child.livesWithFirstChild}
            setValue={(val) => { handleCheckChange(val, 'livesWithFirstChild') }}
            buttonClassName='no-horiz-padding'
            textClassName='vertical-center-align'
            checkboxClassName='no-horiz-padding'
          />
        </Grid>
      }

      {!child.livesWithFirstChild &&
        <>
          <LocationDetails
            location={location}
            validationState={validationState}
            allFieldsOptional={child.isHomeless}
            childLocation={true}
            isdId={isdId}
            onLocationChange={locationChanged}
            handleLocationFieldChange={handleLocationFieldChange}
            validateField={handleTextBlur}
          />
          {outOfISDWarning &&
            <div className='mt-10 width-100'>
              <AlertMessage type={'warning'} message={'Be aware that you address you entered is outside the service area of the current ISD.'} dismissible={false} />
            </div>
          }
        </>
      }
      {hasOtherChildren &&
        <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex', mb: '20px' }}>
          <Button
            name='backButton'
            className='back-button'
            data-testid='back-button'
            onClick={handleBack}
            variant='outlined'
          >
            Back
          </Button>
        </Grid>
      }
      <Grid item xs={hasOtherChildren ? 6 : 12} sx={{ justifyContent: 'flex-end', display: 'flex', mb: '20px' }}>
        <Button
          name='continueButton'
          className='footer-button'
          data-testid='continue-button'
          onClick={handleContinue}
          variant='contained'
        >
          Continue
        </Button>
      </Grid>
    </Grid>

    <Modal
      open={showConfirmModal}
      onClose={() => { setShowConfirmModal(false) }}
      title='Discard Changes'
      confirmationContent='This will discard any changes. Do you want to continue?'
      confirmButtonText='Yes'
      cancelButtonText='No'
      onConfirm={handleConfirm}
      onCancel={() => { setShowConfirmModal(false) }}
      data-testid='discard-changes-modal'
    />
  </>
}
