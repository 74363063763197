import { type location } from '../../../core/types'
import { LocationDetails } from './LocationDetails'
import { MapStateName } from './mapStateName'

export interface LocationSelectEditProps {
  location: location
  onEnter: (newValue: location) => void
  hidePrimary?: boolean
  showHomelessOption: boolean
}

export function LocationSelect (props: LocationSelectEditProps): JSX.Element {
  const handleBlur = (newData: location): void => {
    props.onEnter(newData)
  }

  const handleEditChange = (e: any): void => {
    const newData = { ...props.location, [e.target.name]: e.target.value }
    props.onEnter(newData)
  }

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: location = { ...props.location }
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode

    props.onEnter(newplace)
  }

  return <LocationDetails
    hidePrimary={props.hidePrimary}
    showHomelessOption={props.showHomelessOption}
    editData={props.location}
    onBlur={handleBlur}
    onChange={handleEditChange}
    onPlaceSelected={handlePlaceSelected}
  />
}
