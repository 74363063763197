import { Box, Button, CircularProgress } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { DateRangeDetails } from './DateRangeDetails'
import { type dateRange } from '../../core/types'
import { sendGet, sendPost, sendPut } from '../../hooks/use-fetch'
import { usePagination, type PaginationProps } from '../../hooks/use-pagination'
import { toast } from 'react-toastify'

const dateRangeColumn = new Column('name', 'Time Span')
const startDateColumn = new Column('startDate', 'Start Date', ColumnType.CUSTOM)
startDateColumn.customCellGenerator = (row: RowData): JSX.Element => {
  if (row.startDate === null) return <span></span>
  return <span>{new Date(row.startDate).toLocaleDateString()}</span>
}
const endDateColumn = new Column('endDate', 'End Date', ColumnType.CUSTOM)
endDateColumn.customCellGenerator = (row: RowData): JSX.Element => {
  if (row.endDate === null) return <span></span>
  return <span>{new Date(row.endDate).toLocaleDateString()}</span>
}
const yearsColumn = new Column('years', 'Years', ColumnType.CUSTOM)
yearsColumn.customCellGenerator = (row: RowData): JSX.Element => {
  return <span>{(row.years as []).sort().join(', ')}</span>
}

const columns = [dateRangeColumn, startDateColumn, endDateColumn, yearsColumn]

export function DateRangeList (): JSX.Element {
  const [refreshTime, setRefreshTime] = useState(new Date())
  const initialDateRange: dateRange = { id: 0, name: '', startDate: null, endDate: null, years: [] }
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [selectedDateRange, setSelectedDateRange] = useState<dateRange>(initialDateRange)
  const [open, setOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [timeSpans, setTimeSpans] = useState<dateRange[]>([])
  const [loading, setLoading] = useState(true)

  const paginationProps: PaginationProps<RowData> = {
    rows: timeSpans
  }
  const pagination = usePagination(paginationProps)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: timeSpanResponse } = await sendGet('/DateRange/GetAll', {})
      if (timeSpanResponse !== null) {
        setTimeSpans(timeSpanResponse)
      }
      setLoading(false)
    }
    void loadData()
  }, [refreshTime])

  const addClick = (): void => {
    setSelectedDateRange(initialDateRange)
    setOpen(true)
    setIsNew(true)
  }
  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const selectedYear: dateRange = {
      id: row.id,
      name: row.name,
      endDate: row.endDate,
      startDate: row.startDate,
      years: row.years
    }
    setSelectedDateRange(selectedYear)
    setOpen(true)
    setIsNew(false)
  }

  const handleSubmit = async (dateRangeData: dateRange): Promise<void> => {
    if (!loading) {
      setLoading(true)
      setErrors(undefined)
      let rsp: { errors: string[], success: boolean, response: any }
      const url = `/DateRange/${isNew ? 'Insert' : 'Update'}`

      if (isNew) {
        const { response } = await sendPost(url, dateRangeData)
        rsp = response
      } else {
        const { response } = await sendPut(url, dateRangeData)
        rsp = response
      }
      if (rsp.success) {
        setOpen(false)
        setRefreshTime(new Date())
        toast.success('Timespan changes have been saved')
      } else {
        setErrors(rsp.errors)
      }
    }
  }

  if (loading) return <CircularProgress />
  return <Box>
    <Box justifyContent='flex-end' display='flex' width='100%' >
      <Button color='secondary' variant='contained' onClick={addClick} data-test-add-school-year>Add Time Span</Button>
    </Box>

    <DataTable
      page={pagination.page}

      data-test-table
      name='dateRangeTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows ?? []}
      totalRecords={pagination.recordCount}
      loading={loading}
      onSortChange={(col, sorts) => {
        pagination.onSortChange(col, sorts[0]?.order)
      }}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      onRowClick={rowClick}
    />
    {open && (

      <DateRangeDetails
        onClose={() => { setOpen(false) }}
        onSubmit={handleSubmit}
        open={open}
        dateRange={selectedDateRange}
        errors={errors}
      />
    )}
  </Box>
}
