/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { Modal } from '../../../Components/Modal'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { useEffect, useState } from 'react'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import AlertMessage from '../../../Components/AlertMessage'
import { type location, type eligibilityFactor, type race, type ChildSiblings, type environmentalRisk, AccountType } from '../../../core/types'
import { LocationSelect } from '../../../Components/Cards/LocationCard/LocationSelect'
import { sendGet } from '../../../hooks/use-fetch'
import { AgeFromDate, ScrollModalToTop } from '../../../core/Utilities'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { RaceList } from '../../../Components/CheckList/RaceList'
import { type EligibilityValues, EligiblityFactorList } from '../../../Components/CheckList/EligiblityFactorList'
import { ChildAdditionalInfoList, type AdditionalValues } from '../../../Components/CheckList/ChildAdditionalInfoList'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export interface SiblingAddProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: ChildSiblings) => void
  errors: string[] | undefined
  canViewEligibilityFactors: boolean
  childId: number
  childName: string
  captureIEP: boolean
  captureIFSP: boolean
  captureEOIS: boolean
  siblingId: number
  addUnborn: boolean
}

const genderOptions: Array<{ id: string, name: string }> = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' }
]

export function SiblingAdd (props: SiblingAddProps): JSX.Element {
  const initialChild: ChildSiblings = {
    id: 0,
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: null,
    dateOfBirth: new Date(),
    birthGender: '',
    uic: null,
    caregiverFirstName: '',
    caregiverLastName: '',
    relationshipID: 0,
    otherRelationship: null,
    address: '',
    apartment: '',
    city: '',
    state: '',
    zipCode: '',
    childLocations: [] as location[],
    childRaceList: [] as race[],
    eligibilityFactorList: [] as eligibilityFactor[],
    otherEligibilityFactor: null,
    isLivingWithStudent: true,
    isSiblingRecord: true,
    siblingChildId: props.siblingId,
    hasIEP: false,
    hasIFSP: false,
    hasEOIS: false,
    isInformationalOnly: false,
    hasSNAPBenefits: false,
    isUnborn: props.addUnborn,
    dueDate: null
  } as ChildSiblings

  const [child, setChild] = useState<ChildSiblings>(initialChild)
  const [duplicateWarning, setDuplicateWarning] = useState(false)
  const [childAge, setChildAge] = useState(AgeFromDate(initialChild.dateOfBirth))
  const account = useAccountId()
  const [unauthorized, setUnauthorized] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isUnborn, setIsUnborn] = useState(props.addUnborn)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.childId === 0) {
        setChild(initialChild)
        setLoading(false)
      } else {
        let url = `/ChildDetails/EditableRecord?childId=${props.childId}`
        if (account?.type === AccountType.ISD) {
          url += `&isdId=${account.id}`
        }
        const { response: details, success, status } = await sendGet(url, {})
        if (success) {
          details.response.siblingChildId = props.siblingId
          details.response.issiblingRecord = true
          setChild(details.response)
          setIsUnborn(details.response.isUnborn)
        } else {
          if (status === 401) {
            setUnauthorized(true)
          }
        }
        setLoading(false)
      }
    }
    void fetchData()
  }, [props.open, props.childId])

  if (loading) return <CircularProgress />
  if (unauthorized) return <>You are not authorized to view this child</>
  const handleClose = (): void => {
    props.onClose()
  }

  const handleChange = (e: any): void => {
    setChild({ ...child, [e.target.name]: e.target.value })
    setDuplicateWarning(false)
  }

  const dobChanged = (newDate: Date | null): void => {
    setChild({ ...child, dateOfBirth: newDate })
    setChildAge(AgeFromDate(newDate))
    setDuplicateWarning(false)
  }

  const dueDateChanged = (newDate: Date | null): void => {
    setChild({ ...child, dueDate: newDate })
  }

  const handleSubmit = async (): Promise<void> => {
    if (!duplicateWarning) {
      const date = new Date(child.dateOfBirth ?? new Date())
      const { response: rsp } = await sendGet(`/ChildSearch/DuplicateCheck?firstName=${child.firstName}&lastName=${child.lastName}&dob=${date.toDateString()}&uic=${child.uic ?? ''}&gender=${child.birthGender}&id=${child.id}`, {})
      if (rsp === 'Y') {
        ScrollModalToTop()
        setDuplicateWarning(true)
      } else {
        props.onSubmit(child)
      }
    }
  }
  const handleAdditionalInfoChange = (values: AdditionalValues): void => {
    if (child !== undefined) {
      const childUpdate: ChildSiblings = { ...child, hasSNAPBenefits: values.hasSNAPBenefits, isFosterChild: values.isFosterChild, isOnCashAssistance: values.isOnCashAssistance, isOnSSI: values.isOnSSI }
      setChild(childUpdate)
    }
  }

  const onConfirmDuplicate = (e: any): void => {
    props.onSubmit(child)
  }

  const handleRaceChange = (raceList: race[]): void => {
    if (child !== undefined) {
      const childUpdate: ChildSiblings = { ...child, childRaceList: raceList }
      setChild(childUpdate)
    }
  }

  const handleEligibilityChange = (eligibilityFactors: eligibilityFactor[], environmentalRiskList: environmentalRisk[], values: EligibilityValues): void => {
    if (child !== undefined) {
      let childUpdate: ChildSiblings = { ...child, eligibilityFactorList: eligibilityFactors }
      childUpdate = { ...child, eligibilityFactorList: eligibilityFactors, environmentalRiskList, otherEligibilityFactor: values.otherFactor, hasIEP: values.hasIEP, hasIFSP: values.hasIFSP, hasEOIS: values.hasEOIS }
      setChild(childUpdate)
    }
  }
  const additionalValues: AdditionalValues = {
    hasSNAPBenefits: child.hasSNAPBenefits,
    isFosterChild: child.isFosterChild,
    isOnCashAssistance: child.isOnCashAssistance,
    isOnSSI: child.isOnSSI
  }
  const loc = child.childLocations.length > 0 ? child.childLocations[0] : {} as location
  const addressContent = <><div className="info-box-header">Home Address</div>
    <LocationSelect showHomelessOption={false}
      location={loc}
      onEnter={function (place: location): void {
        const locations = { ...child.childLocations }
        const locationUpdate = locations.length > 0 ? { ...locations[0] } : place
        if (child.lastName !== '') {
          locationUpdate.address = place.address
          locationUpdate.city = place.city
          locationUpdate.apartment = place.apartment
          locationUpdate.state = place.state
          locationUpdate.zipCode = place.zipCode
          const childUpdate: ChildSiblings = { ...child, childLocations: [locationUpdate] }
          setChild(childUpdate)
        }
      }}
    /></>

  const content = <Box>
    {props.errors?.map((err) => (
      <li data-testid='error-message' key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    {duplicateWarning && <div>
      <AlertMessage type={'warning'} message={'Based on the information you have entered, we believe there is a high chance this is a duplicate record. Are you sure you want to continue?'} />
      <Button onClick={onConfirmDuplicate} color='secondary' variant='contained' className='mt-10 mb-5'> Yes, Continue</Button>
    </div>
    }

    <div className="info-box-header">Basic Information</div>

    <Grid container columns={12}>
      <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
        <TextFieldWithLabel
          label='First Name'
          onChange={handleChange}
          name='firstName'
          data-testid='firstName'
          value={child.firstName}
          required={!isUnborn}
        />
      </Grid>
      <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
        <TextFieldWithLabel
          label='Last Name'
          name='lastName'
          data-testid='lastName'
          onChange={handleChange}
          value={child.lastName}
          required={true}
        />
      </Grid>
      <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
        <TextFieldWithLabel
          label='Nickname'
          name='nickName'
          data-testid='nickname'
          onChange={handleChange}
          value={child.nickName}
        />
      </Grid>
      <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
        <TextFieldWithLabel
          label='Middle Name'
          name='middleName'
          data-testid='middleName'
          onChange={handleChange}
          value={child.middleName}
        />
      </Grid>
      {isUnborn && <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
        <DatePickerWithLabel
          label='Due Date'
          name='dueDate'
          dataTestId='dueDate'
          onChange={dueDateChanged}
          value={child.dueDate}
          required={true}
        />
      </Grid> }
      {!isUnborn &&
        <>
          <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
            <TextFieldWithLabel
              label='UIC'
              name='uIC'
              data-testid='uic'
              onChange={handleChange}
              value={child.uic}
            />
          </Grid>
          <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
            <DatePickerWithLabel
              label='Date of Birth'
              name='dateOfBirth'
              dataTestId='dateOfBirth'
              onChange={dobChanged}
              value={child.dateOfBirth}
            />
          </Grid>
          <Grid item sm={6} md={6} sx={{ paddingRight: '20px' }}>
            <SelectWithLabel
              name="birthGender"
              width='100%'
              label="Assigned Gender at Birth"
              value={child.birthGender}
              options={genderOptions}
              onChange={async (e) => {
                if (child !== undefined) {
                  setChild({ ...child, birthGender: e.target.value })
                }
              }}
            />
          </Grid>
        </>
      }
    </Grid>
    <Box className="d-flex f-direction-column width-100">
      <EligiblityFactorList
        eligiblityList={child.eligibilityFactorList}
        enviromentalRiskList={child.environmentalRiskList ?? []}
        setEligibilityValues={handleEligibilityChange}
        otherFactor={child.otherEligibilityFactor}
        // taking child's age into consideration for when to show the following
        captureIEP={props.captureIEP && childAge >= 3}
        captureIFSP={props.captureIFSP && childAge < 3}
        captureEOIS={props.captureEOIS && childAge >= 3}
        hasIEP={child.hasIEP}
        hasIFSP={child.hasIFSP}
        hasEOIS={child.hasEOIS}
      />

    </Box>
    <Box className="d-flex f-direction-column width-100">
      <RaceList
        raceList={child?.childRaceList ?? []}
        setRaceList={handleRaceChange}
      />
    </Box>
    <Box sx={{ marginTop: 2 }}>
      <CheckBoxWithLabel
        label="Informational Only"
        value={child.isInformationalOnly} name='InformationalOnly'
        setValue={function (newVal: boolean): void {
          setChild({ ...child, isInformationalOnly: newVal })
        }}
        edge={'end'}
      />
      {child !== undefined &&
        <ChildAdditionalInfoList
          values={additionalValues}
          setAdditionalValues={handleAdditionalInfoChange}
        />
      }
      <Box sx={{ marginTop: 2 }}>
        <CheckBoxWithLabel
          label={'Share All Caregivers'}
          value={child?.shareAllCaregivers ?? false}
          name='ShareCaregiver'
          dataTestId='ShareCaregiver'
          setValue={function (newVal: boolean): void {
            if (child !== undefined) {
              setChild({ ...child, shareAllCaregivers: newVal })
            }
          }}
          edge={'end'}
        />
      </Box>
      <CheckBoxWithLabel
        label={`Lives With ${props.childName}`}
        value={child.isLivingWithStudent} name='LivesWithStudent'
        setValue={function (newVal: boolean): void {
          setChild({ ...child, isLivingWithStudent: newVal })
        }}
        edge={'end'}
      />
    </Box>
    {!child.isLivingWithStudent && addressContent }
  </Box>
  return <Modal
    data-test-modal
    open={props.open}
    title={`${props.childId !== 0 ? 'Edit' : 'Add'}${isUnborn ? ' Unborn' : ''} Sibling`}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
