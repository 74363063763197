import { Avatar, Box, FormLabel, type SxProps, Tooltip, tooltipClasses } from '@mui/material'
import { common } from '@mui/material/colors'
import { useState, useEffect } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { type InterestFormLabel } from '../../core/types'

interface CustomLabelProps {
  name: string
  isdId: string | number
  defaultLabelText?: string
  defaultTooltipText?: string
  sx?: SxProps
  insertNewLines?: boolean
}

export const CustomLabelWithToolTip = (props: CustomLabelProps): JSX.Element => {
  const color = '#4591bb'
  const [open, setOpen] = useState(false)
  const [tooltipText, setTooltipText] = useState<string>('')
  const [labelText, setLabelText] = useState<string>('')

  const handleTooltipClose = (): void => {
    setOpen(false)
  }

  const handleTooltipOpen = (): void => {
    setOpen(true)
  }

  useEffect(() => {
    async function fetchValues (): Promise<void> {
      const { response }: { response: InterestFormLabel } = await sendGet(`/Label/GetDetails?isdId=${props.isdId}&name=${props.name}`, {})

      let tooltip = props.defaultTooltipText ?? ''
      let label = props.defaultLabelText ?? ''
      if (response != null) {
        if (response.hoverText != null && response.hoverText.trim() !== '') {
          tooltip = response.hoverText
        }
        if (response.labelText != null && response.labelText.trim() !== '') {
          label = response.labelText
        }
      }
      setTooltipText(tooltip)
      setLabelText(label)
    }
    void fetchValues()
  }, [props.name])

  const toolTip =
    <Tooltip
      title={tooltipText}
      placement='top'
      onClose={handleTooltipClose}
      open={open}
      arrow
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.arrow}`]: {
            color
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: color
          }
        }
      }}
    >
      <div>
        <Avatar onClick={handleTooltipOpen} sx={{ bgcolor: common.black, width: '18px', height: '18px', fontSize: '15px', cursor: 'pointer' }}>i</Avatar>
      </div>
    </Tooltip>

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
        <FormLabel
          data-testid={`custom-label-${props.name}`}
          htmlFor={props.name}
          sx={{
            color: 'black',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            letterSpacing: 'inherit',
            fontWeight: 'inherit',
            ...props.sx
          }}
        >
          {props.insertNewLines === true && labelText.split('\n').map((text, index) => {
            return (
              <Box key={index} sx={{ mb: '20px' }}>
                {text}
              </Box>
            )
          })}
          {props.insertNewLines !== true && labelText}
        </FormLabel>
        {(tooltipText !== '') && (toolTip)}
      </Box>
    </Box>
  )
}
