import { LabelWithToolTip } from '../Label/Index'
import { HierarchyCheckList, type functionType } from './HierarchyCheckList'

interface UserFunctionCheckListProps {
  options: functionType[]
  selectedValues: string[]
  onValuesChanged: (newValue: string[]) => void
}

export function UserFunctionCheckList (props: UserFunctionCheckListProps): JSX.Element {
  return <HierarchyCheckList
    data-testid='checkList'
    headers= {[
      <LabelWithToolTip
        key={'functionName'}
        labelText={'Authorized Functions'}
        sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
      />,

      <LabelWithToolTip
        key={'read'}

        labelText={'Read'}
        sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
      />,

      <LabelWithToolTip
        key={'write'}

        labelText={'Read/Write'}
        sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
      />]

        }
    optionsList={props.options} selectedValues={props.selectedValues} setSelectedValues={props.onValuesChanged}
  />
}
