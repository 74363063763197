import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Modal } from '../../../Components/Modal'
import { TextAreaWithLabel } from '../../../Components/TextArea'
import { sendGet, sendPut } from '../../../hooks/use-fetch'
import { AccountType, type postError } from '../../../core/types'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { AcceptDenyModal } from '../../../Components/Modal/AcceptDenyModal'
import { AccessRequestEdit } from '../../AccessRequest/AccessRequestEdit'
import { type AccessRequestData } from '../../AccessRequest/AccessRequestReview'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export interface AccessRequestActionProps {
  childId: number
  changeMade: () => void
  requiresAccessRequest: boolean
  currentAccessRequestStatus: boolean | null // null means no request exists, true means it's approved, false means it's denied
  currentAccessRequestID: number | null
  childNotTiedToISD: boolean
}

export function AccessRequestAction (props: AccessRequestActionProps): JSX.Element {
  const account = useAccountId()
  const [notes, setNotes] = useState<string>('')
  const [showAccessRequest, setShowAccessRequest] = useState<boolean>(false)
  const [showReviewRequest, setShowReviewRequest] = useState<boolean>(false)
  const [accessRequest, setAccessRequest] = useState<AccessRequestData | null>()
  const title = account?.type !== AccountType.SERVICE_PROVIDER ? 'Request Access' : 'Request Transfer'

  const submitAccessRequest = async (): Promise<void> => {
    const request = {
      childId: props.childId,
      accountId: account?.id ?? null,
      accountType: account?.type,
      notes: notes ?? '',
      childNotTiedToISD: props?.childNotTiedToISD ?? false
    }
    const result = await sendPut('/ChildDetails/RequestAccess', request)
    const rsp: { newRecordID: number, success: boolean, errors: postError[] } = result.response
    if (rsp.success) {
      if (props?.childNotTiedToISD ?? false) {
        toast.success('Access Granted')
      } else {
        toast.success('Access Request Submitted')
      }
      setShowAccessRequest(false)
      props.changeMade()
    } else {
      rsp.errors.forEach((e: postError) => {
        toast.error(e.error)
      })
    }
  }

  const requestAccess = (): void => {
    setShowAccessRequest(true)
  }

  const reviewRequest = async (): Promise<void> => {
    if (props?.currentAccessRequestID != null) {
      const request = await sendGet(`/ChildDetails/AccessRequestReview?id=${props.currentAccessRequestID}`, {})
      setAccessRequest(request.response)
      setShowReviewRequest(true)
    }
  }

  const closeDialog = (): void => {
    setShowAccessRequest(false)
    setShowReviewRequest(false)
  }

  if (account === undefined) {
    return <CircularProgress />
  } else if (!props.requiresAccessRequest || props.currentAccessRequestStatus === true) {
    return <></>
  } else if (props.currentAccessRequestID === null) {
    return <> <Button color='secondary' onClick={() => { requestAccess() }}>{title}</Button>
      {showAccessRequest && <Modal
        title={title}
        confirmationContent={<Box>
          {props.childNotTiedToISD
            ? <div>This child is not tied to a ISD, do you want to become a supporting ISD for them?</div>
            : <TextAreaWithLabel
                label={`Please give a brief explanation of why you are ${account?.type !== AccountType.SERVICE_PROVIDER ? 'requesting access to' : 'requesting a transfer of'} this child`}
                name='notes'
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                value={notes}
            />
          }

        </Box>}
        confirmButtonText={(props.childNotTiedToISD ? 'OK' : 'Submit')}
        cancelButtonText='Cancel'
        onClose={closeDialog}
        onConfirm={submitAccessRequest}
        open={true}
      />}
    </>
  } else if (props.currentAccessRequestStatus === null) {
    return <Typography>Access Requested</Typography>
  } else {
    return <>
      <Button color='secondary' onClick={async () => { await reviewRequest() }}>Review Request</Button>
      {showReviewRequest && accessRequest !== undefined && accessRequest !== null &&
        <AcceptDenyModal title='Review Request'
          onClose={closeDialog}
          hideCancelButton={true}
          open={true}
          dialogContent={
            <AccessRequestEdit
              accessRequest={accessRequest}
              errors={[]}
              setAccessRequest={() => { }}
            />}
        />
      }
    </>
  }
}
