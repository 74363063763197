import { type ChangeEvent, useEffect, useState } from 'react'
import { Modal } from '../Modal'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { Box, Typography, Grid } from '@mui/material'
import { TextAreaWithLabel } from '../TextArea'
import { type lookup, type TransferReferralRequest, type carenetworkService } from '../../core/types'
import { toast } from 'react-toastify'
import { ServiceSummaryCard } from './ServiceSummaryCard'
import { Autocomplete } from '../../Components/AutoComplete'

export interface TransferServiceProps {
  referralId: number
  referralChildId: number
  open: boolean
  onClose: () => void
  onSubmit?: () => void
  service: carenetworkService
}

export function TransferService (props: TransferServiceProps): JSX.Element {
  const [errors, setErrors] = useState<string[]>([])
  const initialTransferRequest: TransferReferralRequest = { note: '', referralId: props.referralId, transferReason: '', serviceProviderId: null }
  const [transferRequest, setTransferRequestModel] = useState<TransferReferralRequest>(initialTransferRequest)

  const handleClose = (): void => {
    props.onClose()
  }

  const handleSubmit = async (): Promise<void> => {
    if (transferRequest !== undefined) {
      const { response } = await sendPost('/ReferralEdit/TransferReferral', transferRequest)
      if (response !== undefined) {
        if (response.errors !== undefined && response.errors.length > 0) {
          setErrors(response.errors)
        } else {
          props.onClose()
          if (props.onSubmit != null) props.onSubmit()
          toast.success('Service request was transferred')
        }
      }
    }
  }

  const [serviceProviders, setServiceProviders] = useState<lookup[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet(`/ReferralSearch/GetProvidersByReferralISD?serviceId=${props.service.systemServiceID}&referralId=${props.referralId}`, {})
      if (error[0] === '') {
        setServiceProviders(response)
      }
      setLoading(false)
    }
    void fetchData()
  }, [])

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>Transfer Service</span>
  </div>

  const displayHeader = <ServiceSummaryCard
    blockName={props.service.sessionBlockName ?? ''}
    buildingName={props.service.facilityName ?? ''}
    sessionName={props.service.sessionName ?? ''}
    serviceLogo={props.service.systemServiceLogo ?? null}
    serviceProviderLogo={props.service.serviceProviderLogo ?? null}
    programName={props.service.systemProgramName}
    referralTimeFrame={props.service.referralTimeFrame}
    serviceProviderName={props.service.systemServiceProviderName}
    serviceStatus={props.service.status}
    serviceName={props.service.systemServiceName}
  />

  if (loading) {
    return <></> // return nothing while loading
  } else if (serviceProviders.length === 0) {
    const content = <Box>
      {displayHeader}

      <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em', padding: '10px 0 0 0' }}>
        There are no providers available to transfer to.
      </Typography>
    </Box>

    return <Modal
      maxWidth='sm'
      fullWidth={true}
      open={props.open}
      titleContent={titleContent}
      confirmationContent={content}
      hideCancelButton={true}
      confirmButtonText='Close'
      onConfirm={handleClose}
      onClose={handleClose}
    />
  } else {
    const content = <Box>
      {displayHeader }

      {errors.map((err) => (
        <li data-testid="error-message" key={err} style={{ color: 'red', padding: '10px 0 0' }}>
          {err}
        </li>
      ))}

      <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em', padding: '10px 0 0 0' }}>
        Please confirm you would like to refer this child to another provider
      </Typography>

      <TransferServiceDataDisplay
        transferRequest={transferRequest}
        setTransferRequestModel={setTransferRequestModel}
        referralId={props.referralId}
        childId={props.referralChildId}
        systemServiceId={props.service.systemServiceID}
        showReferTo={true}
        referToProviders={serviceProviders}
      />
    </Box>

    return <Modal
      maxWidth='sm'
      fullWidth={true}
      open={props.open}
      titleContent={titleContent}
      confirmationContent={content}
      onClose={handleClose}
      onConfirm={handleSubmit}
    />
  }
}

export interface TransferServiceDataDisplayProps {
  systemServiceId: number
  transferRequest: TransferReferralRequest
  setTransferRequestModel: (request: TransferReferralRequest) => void
  referralId: number
  childId: number
  showReferTo: boolean
  referToProviders?: lookup[]
}

export function TransferServiceDataDisplay (props: TransferServiceDataDisplayProps): JSX.Element {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (props.transferRequest !== undefined) {
      props.setTransferRequestModel({ ...props.transferRequest, [e.target.name]: e.target.value })
    }
  }

  return <Grid container alignItems={'center'} width={'100%'} >
    <Grid item width={'100%'}>
      <TextAreaWithLabel
        name="transferReason"
        label="Reason"
        data-testid="reason"
        value={props.transferRequest?.transferReason}
        onChange={handleChange}
      />
    </Grid>
    <Grid item width={'100%'}>
      <TextAreaWithLabel
        name="note"
        label="Notes"
        data-testid="notes"
        value={props.transferRequest?.note}
        onChange={handleChange}
      />
    </Grid>
    {props.showReferTo && props.referToProviders !== undefined && (
      <Grid item width={'100%'}>
        <Autocomplete
          width='100%'
          name="referto"
          label="Refer to"
          onChange={(e, value) => {
            if (props.transferRequest !== undefined) {
              props.setTransferRequestModel({ ...props.transferRequest, serviceProviderId: value?.id as number })
            }
          }}
          options={props.referToProviders}
          value={props.transferRequest?.serviceProviderId ?? 0}
          placeholder="Search providers..."
          noBottomPadding
          dataTestId='service-provider'
        />
      </Grid>
    )}
  </Grid>
}
