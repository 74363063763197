
interface jpgProps {
  fill?: string
}

export function SchoolIcon (props: jpgProps): JSX.Element {
  const fill = props.fill ?? 'black'
  return <>
    <svg width="100" height="100" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M106.4 54.8935L70.9333 37.1602V26.6H97.5333V0H62.0667V37.1602L26.6 54.8935V70.9333H8.86667V124.133H0V133H53.2V97.5333H79.8V133H133V124.133H124.133V70.9333H106.4V54.8935ZM115.267 124.133V79.8H106.4V124.133H115.267ZM26.6 124.133H17.7333V79.8H26.6V124.133ZM53.2 75.3667C53.2 71.8393 54.6012 68.4564 57.0955 65.9621C59.5897 63.4679 62.9726 62.0667 66.5 62.0667C70.0274 62.0667 73.4103 63.4679 75.9045 65.9621C78.3988 68.4564 79.8 71.8393 79.8 75.3667C79.8 78.894 78.3988 82.277 75.9045 84.7712C73.4103 87.2654 70.0274 88.6667 66.5 88.6667C62.9726 88.6667 59.5897 87.2654 57.0955 84.7712C54.6012 82.277 53.2 78.894 53.2 75.3667Z"/>
    </svg>
  </>
}
