import { Tab, Tabs, Typography, Box } from '@mui/material'
import { useState } from 'react'
import { TabDisplay } from '../../Components/TabDisplay'
import { DateRangeList } from './DateRangeList'
import { SystemServicesList } from './Service'
import { ExportPeriodList } from './MsdsExportPeriod'
import { FileTypeList } from './FileType'
import { SystemProgramsList } from './Program'
import { SystemServiceProviderList } from './ServiceProviderList'
import { FederalPovertyLevelList } from './FederalPovertyLevelList'

export function ApplicationSettings (): JSX.Element {
  const [value, valueChange] = useState(0)
  return <Box>
    <Typography variant="h3" data-test-page-header>Application Settings</Typography>
    <Tabs value={value}
      data-test-tabs
      onChange={(e, val) => { valueChange(val) }}
      aria-label="Application Settings Tabs"
      textColor="secondary"
      indicatorColor="secondary"
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label="Time Spans" value={0} data-test-school-years />
      <Tab label="FPL Years" value={6} data-test-fpl />"
      <Tab label="Service Providers" value={5} data-test-service-providers />
      <Tab label="Services" value={1} data-test-services />
      <Tab label="Programs" value={2} data-test-programs />
      <Tab label="MSDS Export Periods" value={3} data-test-msds-export-periods />
      <Tab label="Document Types" value={4} data-test-document-types />
    </Tabs>
    <TabDisplay
      index={0}
      value={value}
    >
      <DateRangeList />
    </TabDisplay>
    <TabDisplay
      index={1}
      value={value}
    >
      <SystemServicesList />
    </TabDisplay>
    <TabDisplay
      index={2}
      value={value}
    >
      <SystemProgramsList />
    </TabDisplay>
    <TabDisplay
      index={3}
      value={value}
    >
      <ExportPeriodList />
    </TabDisplay>
    <TabDisplay
      index={4}
      value={value}
    >
      <FileTypeList />
    </TabDisplay>
    <TabDisplay
      index={5}
      value={value}
    >
      <SystemServiceProviderList />
    </TabDisplay>
    <TabDisplay
      index={6}
      value={value}
    >
      <FederalPovertyLevelList />
    </TabDisplay>

  </Box>
}
