import { Card, CardContent, Typography, CardActionArea, Box } from '@mui/material'

interface RadiusCardClickableProps {
  header: string
  content: JSX.Element
  className?: string
  contentClassName?: string
  headerClassName?: string
  width?: string
  icon?: JSX.Element
  dataTestId?: string
  onClick: () => void
}

export const RadiusCardClickable = (props: RadiusCardClickableProps): JSX.Element => {
  return (
    <Card data-testid={props.dataTestId ?? 'clickable-card'} sx={{ maxWidth: props.width ?? 420, borderRadius: 3 }} className={props.className}>
      <CardActionArea onClick={props.onClick}>
        <CardContent className={props.contentClassName}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
            <Box> { props.icon }</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
              <Box><Typography component="div" variant="h6" className={'d-flex f-align-items-center ' + (props.headerClassName ?? '')}>{props.header}</Typography></Box>
              <Box>{props.content}</Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
