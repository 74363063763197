import { type documentTypeModel } from '../Components/DocumentList'
import { type notificationEvent } from '../Pages/CareNetworkManagement/CareNetworkDetail/ChildHistoryDisplay'

export enum Role {
  CARE_GIVER = 'Caregiver',
  SERVICE_PROVIDER_USER = 'ServiceProviderUser',
  ISD_USER = 'ISDUser',
  MIECC_ADMIN = 'MiECCAdmin',
  INTAKE_USER = 'IntakeUser',
}
export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}
export enum MonthOfYear {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}
export enum IsdDocumentTypeStatus {
  AVAILABLE = 'Available',
  NOTAVAILABLE = 'Not Available',
  REQUIRED = 'Required',
  NOTREQUIRED = 'Not Required'
}
export enum CaregiverAccessType {
  PRIMARYCAREGIVER = 'Primary Caregiver',
  CAREGIVER = 'Caregiver',
  CUSTODIAL_GUARDIAN = 'Custodial Guardian'
}

export enum NeedsMetStatus {
  YES = 'Yes',
  NO = 'No',
  PREFERNOTTOSAY = 'PreferNotToSay',
}
export enum Status {
  INACTIVE = 'Inactive',
  IN_PROGRESS = 'InProgress',
  ACTIVE = 'Active'
}

export enum SecurityLevel {
  NONE = 'None',
  VIEW = 'ViewOnly',
  MODIFY = 'Edit'
}

export enum DocumentTarget {
  CHILD = 'Child',
  BOTH = 'Both',
  ADULT = 'Adult'
}

export enum FacilityStatus {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active'
}

export interface ActiveAccount {
  id: number
  type: AccountType
}

export enum AccountType {
  ISD = 'ISD',
  SERVICE_PROVIDER = 'Service Provider',
  INTAKE_USER = 'Intake'
}

export enum EnrollmentStatus {
  Pending = 'Pending',
  EnrollPendingApproval = 'EnrollPendingApproval',
  ProgramHoldPendingApproval = 'ProgramHoldPendingApproval',
  Waitlist = 'Waitlist',
  Enrolled = 'Enrolled',
  Exited = 'Exited',
  ApprovalDenied = 'ApprovalDenied'
}

export enum CommunicationType {
  Communication = 'Communication',
  Reminder = 'Reminder',
  Note = 'Note'
}

export enum WaiverDenyOptions {
  ReferToMe = 'ReferToMe',
  ReturnToIntake = 'ReturnToIntake'
}

export enum WaiverAcceptOptions {
  ReferToMe = 'ReferToMe',
  ReturnToIntake = 'ReturnToIntake'
}

export enum ReferralTimeFrame {
  Immediate = 'Immediate',
  UpcomingSchoolYear = 'UpcomingSchoolYear',
  SpecificDate = 'SpecificDate'
}

export enum ReferralTimeFrameDisplay {
  Immediate = 'Immediate',
  UpcomingSchoolYear = 'Upcoming School Year',
  SpecificDate = 'Specific Date'
}

export enum DesiredSchedule {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  AllDay = 'AllDay'
}

export enum ChildAuditAccessTypeEnum {
  Referral,
  Enrollment,
  CareNetwork
}

export enum NoteSource {
  ContactLog = 'ContactLog',
  Note = 'Note',
  Reminder = 'Reminder',
  ReferralReminder = 'ReferralReminder',
  EnrollmentReminder = 'EnrollmentReminder',
  ReferralNote = 'ReferralNote',
  WaiverNote = 'WaiverNote',
  EnrollmentNote = 'EnrollmentNote',
  AccessRequestNote = 'AccessRequestNote',
  NoteOnReferral = 'NoteOnReferral'
}

export type UserFunction =
| 'CaregiverPortal'
| 'CaregiverPortal_Write'
| 'CaregiverPortal_Enrollments'
| 'CaregiverPortal_Enrollments_Write'
| 'CaregiverPortal_Details'
| 'CaregiverPortal_Details_Write'
| 'CareNetwork'
| 'CareNetwork_Caregiver'
| 'CareNetwork_Caregiver_Edit'
| 'CareNetwork_NetworkMembers'
| 'CareNetwork_NetworkMembers_Edit'
| 'CareNetwork_Documentation'
| 'CareNetwork_Documentation_Edit'
| 'CareNetwork_IncomeRequest'
| 'CareNetwork_IncomeRequest_Edit'
| 'CareNetwork_SensitiveDetails'
| 'CareNetwork_SensitiveDetails_Edit'
| 'CareNetwork_MSDS'
| 'CareNetwork_MSDS_Edit'
| 'CareNetwork_Enrollment'
| 'CareNetwork_Enrollment_Edit'
| 'CareNetwork_Communication'
| 'CareNetwork_Communication_Edit'
| 'Enrollment'
| 'Enrollment_Edit'
| 'Reports'
| 'Users'
| 'Users_Edit'
| 'Impersonation'
| 'ServiceProviders'
| 'ServiceProviders_Edit'
| 'ISDs'
| 'ISDs_Edit'
| 'ISDs_Approvals'
| 'ISDs_Approvals_Edit'
| 'MiECCAdmin'
| 'Enrollment_Documentation'
| 'Enrollment_Communication'
| 'Enrollment_Documentation_Edit'
| 'Enrollment_Communication_Edit'
| 'CareNetwork_MergeRecords'
| 'WaiverApprovals'

export interface RoleInfo {
  id: Role
  manageableRoles: RoleInfo[]
  implicitFunctions: UserFunction[]
  explicitFunctions: UserFunction[]
}

export interface ApproveWaiverRequest {
  id: number
  approvalReasonId?: number | null
}

export interface DenyWaiverRequest {
  id: number
  notes: string
  denyReasonId?: number | null
  waiverOption?: WaiverDenyOptions | null
}

export interface DenyReferralRequest {
  referralId: number
  note: string
  serviceProviderId?: number | null
  denyReasonId?: number | null
  manualReferEnabled?: boolean | null
}

export interface AcceptReferralRequest {
  referralId: number
  sessionBlockId?: number | null
  facilityId?: number | null
  sessionId?: number | null
  enrollNow: boolean
  enrollmentDate?: Date | null
  startDate?: Date | null
  priorityProviderId?: number | null
  priorityNotes?: string | null
  requiresPriority?: boolean | null
}

export interface CareNetworkCommunication {
  id: number
  enteredOn: Date
  note: string
  needMet: NeedsMetStatus | null
  addressedWhatMatteredMost: NeedsMetStatus | null
  reasonNotMet: string | null
  type: NoteSource
  reminderDate: Date | null
  userID: number | null
  caregiverID: number | null
  contactMethodID: number | null
  childID: number | null
  referralID: number | null
}

export interface AuthorizedFunction { id: number, authorizedFunction: UserFunction }

export interface User {
  id: number
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  inactive: boolean
  externalLogin: boolean
  isImpersonating: boolean
  isFakeAdmin: boolean
  securityLevel: Role
  userAuthorizedFunctions: AuthorizedFunction[]
  isDs: ISD[]
  serviceProviders: serviceProvider[]
  noAccount: boolean
  imageKey?: string
  userNotificationSettings: userNotificationSetting[]
  notifications: notificationEvent[]
}

export interface caregiver {
  id: number
  firstName: string
  lastName: string
  primaryPhone: string
  secondaryPhone: string
  email: string
  preferredContactMethod: number
  educationLevelID: number
  userID: number
}

export interface contactmethod {
  id: number
  name: string
}

export interface lookup {
  id: number
  name: string
}

export interface PersonBasicInfo {
  id: number
  firstName: string
  lastName: string
  suffix: string
  isdId?: number
  districtId?: number
}

export interface caregiverChildRelationship {
  id: number
  caregiverID: number
  childID: number
  relationshipID: number
  otherRelationship: string
  legalGuardian: boolean
  careGiver?: ChildCaregiver
}

export interface enrollment {
  id: number
  child: child
  program: program
  programID: number
  status: string
  incomeApprovalStatus?: string
}

export interface ChildCaregiver {
  id: number
  firstName: string
  lastName: string
  relationshipName: string
  accessType: string
  isLivingWithStudent: boolean
  email: string
  dateOfBirth: Date | null
  languageID: number | null
  educationLevelID: number | null
  relationshipID: number | null
  preferredContactMethod: number | null
  isFinancialResponsible: boolean
  createAccount: boolean | null
  childId: number
  securityLevel: SecurityLevel
  phone: string | null
  address: string | null
  city: string | null
  state: string | null
  apartment: string | null
  zipCode: string | null
  childSiblings: ChildSiblings[]
  newChildSiblings: number[]
  incomes?: income[]
  documents?: documentTypeModel[]
}

export type ChildSiblings = ChildEditValues & relationshipData

interface relationshipData {
  relationshipName: string
  isLivingWithStudent: boolean
  isSiblingRecord: boolean
  siblingChildId: number
  shareAllCaregivers: boolean
  caregiverFirstName: string
  caregiverLastName: string
  relationshipID: number
  otherRelationship: string | null
  address: string
  apartment: string
  city: string
  state: string
  zipCode: string
}

export interface ChildEditValues {
  id: number
  firstName: string
  lastName: string
  middleName: string | null
  suffix: string | null
  nickName: string | null
  dateOfBirth: Date | null
  birthGender: string
  uic: string | null
  languageID: number | null
  image: string | null
  canEditEligibilityFactors: boolean
  childRaceList: race[]
  eligibilityFactorList: eligibilityFactor[]
  environmentalRiskList: environmentalRisk[]
  otherEligibilityFactor: string | null
  ageDisplay: string | null
  language: string | null
  primaryLocationID: number | null
  residentDistrict: string | null
  childLocations: location[]
  documentTypeRequirements: DocumentTypeRequirement[]
  childRelationships?: caregiverChildRelationship[]
  captureIEP: boolean
  captureIFSP: boolean
  captureEOIS: boolean
  hasIEP: boolean
  hasIFSP: boolean
  hasEOIS: boolean
  isdID?: number
  hasSNAPBenefits: boolean
  isFosterChild: boolean
  isOnCashAssistance: boolean
  isOnSSI: boolean
  isInformationalOnly: boolean
  authorizationStatusID: number | null
  authorizationStatus: string | null
  isUnborn: boolean
  dueDate: Date | null
}

export interface DocumentTypeRequirement {
  documentTypeID: number
  required: boolean
  documentTarget: DocumentTarget
}

export interface child {
  id: number
  firstName: string
  lastName: string
  fullName: string
  middleName: string
  suffix: string
  nickName: string
  dateOfBirth: Date | null
  birthGender: string
  uic: string
  hasIEP: boolean
  fosterChild: boolean
  hasSNAPBenefits: boolean
  primaryLanguage: string
  yearsMonthsAge: string
  races?: string[]
  eligiblityFactors?: string[]
  povertyPercentage?: number
}

export interface AccessControlModel {
  caregiverId: number
  childId: number
  securityLevel: SecurityLevel
}

export interface district {
  id: number
  iSDID: number
  stateCode: string
  name: string
  nCES_Code: string
}

export interface document {
  id: number
  careNetworkID: number
  caregiverID: number
  childID: number
  fileTypeID: number
  fileName: string
  whenUploaded: Date
}

export interface educationLevel {
  id: number
  name: string
  stateCode: string
}

export interface eligibilityFactor {
  id: number
  name: string
  stateCode: string
}

export interface environmentalRisk {
  id: number
  name: string
}

export interface exportPeriod {
  id: number
  name: string
  code: string
  periodStart: string
  periodEnd: string
  collectionID: number
  majorVersion: string
  minorVersion: string
}

export interface facility {
  id: number
  serviceProviderID: number
  name: string
  facilityCode: string
  licenseNumber: string
  licenseExpirationDate: Date | null
  phoneNumber: string
  website: string
  inactive: boolean
  lastModified: Date
  facilityHours: businessHours[]
  location: location
  qualityRating: number | null
  qualityRatingName?: string | null
  isdid: number | null
  isdName?: string | null
  locationISDFound: boolean
  offeringNarrative: string | null
  services: lookup[]
  programs: lookup[]
  providesBeforeAfterSchoolCare: boolean
}

export interface businessHours {
  id: number
  facilityID: number
  dayOfWeek: string
  dayDisplay: string
  openingTime: string
  closingTime: string
}

export interface facilityRoom {
  id: number
  facilityID: number
  name: string
  capacity: number | null
  noCapacityLimit: boolean
  hasAttachedSessions: boolean
  federallyFunded: boolean
  inactive: boolean
  systemPrograms: ProviderProgram[]
}

export interface ProviderProgram {
  id: number
  name: string
  enabled: boolean
  noCapacityLimit: boolean
}

export interface federalPovertyLevel {
  id: number
  year: number
  baselineAmount: number | null
  increasePerPerson: number | null
}

export interface fileType {
  id: number
  name: string
  description?: string | null
  inactive: boolean
  documentTarget: string
  s3Key: string | null
  incomeRelated: boolean
}

export interface fundingSource {
  id: number
  name: string
}

export interface income {
  id: number
  careNetworkID: number
  year: number
  annualIncome: number
  memberCount: number
  unknownIncome: boolean
}

export interface userNotificationSetting {
  id: number
  userID: number
  eventType: string
  caregiverID: number
  isdid: number
  intakeServiceProviderID: number
  serviceProviderID: number
  isAppNotificationEnabled: boolean
  appSubscriptionDate: Date | null
  isEmailNotificationEnabled: boolean
  emailSubscriptionDate: Date | null
}

export interface ISD {
  id: number
  name: string
  notes?: string
  intakeProviderID: number | null
  website: string
  address: string
  city: string
  state?: string
  zipCode: string
  contactEmail: string
  contactPhone: string
  logoKey?: string | null
  status: string
  onboardDate: Date
  top3Selection: boolean
  captureIFSP: boolean
  captureIEP: boolean
  captureEOIS: boolean
  showResultsFromOtherCounties: boolean
  selectProviderOnReferralDecline: boolean
  referAllToIntakeProvider: boolean
  adminUserId: number | null
  showServicesForMyChild: boolean
  showServicesForMyself: boolean
  showCreateReferral: boolean
  adultServicesName?: string
  adultServicesLink?: string
}

export interface isdFileType {
  id: number
  isdID: number
  fileTypeID: number
  programID: number
}

export interface isdProgram {
  id: number
  isdid: number
  systemProgramID: number
  name: string
  allowConcurrentEnrollment: boolean
  priorityProgram: boolean
  overIncomeCutOffFourPlus: number | null
  overIncomeCutOffThree: number | null
  overIncomeCutOffZeroToTwo: number | null
  enabled: boolean
  fundingSource?: fundingSource
  requiresIncomeCalculation: boolean
  isIntake: boolean
  noCapacityLimit: boolean
  hasEnrollments: boolean
  ageSettingList: ageSetting[]
}

export interface ageSetting {
  id: number
  allowForAge: number
  fplIncomeThreshhold: number | null
  requiresApproval: boolean | null
  maxEnrollmentAllocationPercent: number | null
}

export interface location {
  id: number
  address: string
  apartment: string
  city: string
  state: string
  zipCode: string
  note: string
  isPrimary?: boolean
  districtID?: number
  livesWith?: string[]
  isHomeless?: boolean
  startDate?: Date | null
  endDate?: Date | null
  homelessResidenceID?: number | null
  isUnaccompaniedYouth?: boolean | null
}

export interface program {
  id: number
  name: string
  stateCode: string
  inactive: boolean
  fundingSourceID: number
  description: string
  requiresIncomeCalculation: boolean
  programId?: number | null
}

export interface race {
  id: number
  name: string
  codePosition: number
}

export interface relationship {
  id: number
  name: string
}

export interface dateRange {
  id: number
  name: string
  startDate: Date | null
  endDate: Date | null
  years: number[]
}

export interface seatAllocation {
  id: number
  sessionID: number
  programID: number
  name?: string
  seats: number
}

export interface service {
  id: number
  isdid?: number
  name: string
  description: string
  s3Key: string | null
  inactive: boolean
  serviceId?: number | null
}

export interface serviceOffering {
  id: number
  serviceProviderID: number
  serviceID: number
  schoolYearID: number
}
export interface systemServiceProvider {
  id: number
  name: string
  eemCode: string
  onboardDate: Date
  status: string
  userID: number | null
  imageKey: string | null
  website: string
  contactEmail: string
  contactPhone: string
  location?: location
}

export interface serviceProvider {
  id: number
  isdid: number
  name: string
  eemCode: string
  onboardDate: Date
  status: string
  userID: number | null
  s3Key: string | null
  isIntake: boolean
  location?: location
  allowUICLookup: boolean
  serviceList: ISDServiceOption[]
  programList: ISDProgramOption[]
  systemServiceProviderID?: number
  isdServiceProviderID?: number
}

export interface ISDServiceOption {
  id: number
  name: string
  isIntake: boolean
}

export interface ISDProgramOption {
  id: number
  name: string
  isIntake: boolean
}

export interface carenetworkService {
  systemServiceID: number
  systemServiceName: string
  systemServiceLogo?: string
  systemProgramID: number | null
  systemProgramName: string | null
  systemServiceProviderID: number
  systemServiceProviderName: string
  sessionBlockId?: number | null
  sessionBlockName?: string | null
  serviceProviderLogo?: string | null
  dateRange: dateRange | null
  lastModified: Date | null
  status: string
  s3Key: string | null
  inactive: boolean
  referralTimeFrame: string
  customDate: Date | null
  desiredSchedule: string
  facilityID: number
  facilityName: string | null
  type: string
  referralId: number
  enrollmentID: number | null
  sessionName?: string
  requiresIncomeCalculation: boolean
  queueOrder: number
  serviceID: number
  programID: number | null
  notes: string | null
}

export interface TransferReferralRequest {
  referralId: number
  note: string
  serviceProviderId?: number | null
  transferReason: string
}

export interface session {
  id: number
  name: string
  startDate: Date | null
  endDate: Date | null
  facilityRoomID: number
  roomName?: string
  facilityName?: string
  serviceID: number
  schoolYearID: number
  sessionHours: businessHours[]
  staffLeadID: number | null
  staffLeadName: string | null
  sessionStaff: sessionStaffMember[]
  facilityID: number | null
  programs: seatAllocation[]
  currentEnrollments: number
  currentReferrals: number
  noCapacityLimit: boolean
  inactive: boolean
}

export interface sessionBlock {
  id: number
  name: string
  startDate: Date | null
  endDate: Date | null
  lastModified: Date
  sessionCount: number
  dateRange: dateRange | null
  selectedProgramIds: number[]
  inactive: boolean
}

export interface sessionStaffMember {
  id: number
  name?: string
}

export interface staffMember {
  id: number
  serviceProviderID: number
  firstName: string
  lastName: string
  inactive: boolean
}

export interface userAuthorizedFunction {
  id: number
  isdid: number | null
  systemServiceProviderID: number | null
  authorizedFunction: string
}

export interface waiver {
  id: number
  childID: number
  isdid: number
  childName: string
  studentImageKey?: string
  dateOfBirth: Date
  requestedProviderDetails: string
  providerImageKey?: string
  requestedFacility: string
  requestedProgramName: string | null
  isPriorityProgram: boolean
  addressLine1?: string
  addressLine2?: string
  povertyPercent?: number
  householdIncome?: number
  incomeVerified: boolean
  schoolYearStartingYear: number
  notes?: string
  eligibilityFactors: eligibilityFactor[]
  otherEligibilityFactor: string | null
  createdByName: string
  createdByEmail: string
  createdByContact: string
  status: string
}

export interface postError {
  field: string
  group?: string
  error: string
}

export interface ISDSettings {
  showServicesForMyChild: boolean
  showServicesForMyself: boolean
  showCreateReferral: boolean
  adultServicesName?: string
  adultServicesLink?: string
}

export interface EnrollmentDetails {
  unbornChild: boolean
  isCaregiverOfChild: boolean
  userServiceProviderId: number | null
  hasStartedCreatingAccount: boolean
  currentStep: number
  currentSubStep: number
  note: string
  currentChild: PortalChildDetails
  currentCaregiver: PortalCaregiverDetails
  children: PortalChildDetails[]
  caregivers: PortalCaregiverDetails[]
  isNewSession: boolean
}

export interface PortalChildDetails {
  firstName: string | null
  lastName: string
  middleName: string
  nickname: string
  suffix: string
  dateOfBirth: Date | null
  birthGender: string
  races: number[]
  languageID: number | null
  hasIEP: boolean
  hasIFSP: boolean
  hasEOIS: boolean
  fosterChild: boolean
  hasSNAPBenefits: boolean
  isOnCashAssistance: boolean
  isOnSSI: boolean
  isHomeless: boolean
  dueDate: Date | null
  unborn: boolean
  livesWithFirstChild: boolean
  isCurrentlyUpdating: boolean
  location: PortalLocation
  requestedServices: PortalService[]
}

export interface PortalCaregiverDetails {
  firstName: string
  lastName: string
  dateOfBirth: Date | null
  relationshipId: number | null
  legalGuardian: boolean
  primaryPhone: string
  secondaryPhone: string
  email: string
  doesNotHaveEmail: boolean
  preferredContactMethod: number | null
  educationLevelID: number | null
  isLivingWithStudent: boolean
  location: PortalLocation
  totalNumberOfHouseholdMembers: number | null
  income: number | null
  taxYear: number | null
  isCurrentlyUpdating: boolean
}

export interface PortalLocation {
  address: string
  apartment: string
  city: string
  state: string
  zipCode: string
}

export interface PortalService {
  id: number
  timeFrame: ReferralTimeFrame | null
  startDate: Date | null
  desiredSchedule: DesiredSchedule | null
  facilityIds: number[]
  reachOut: boolean
  isCurrentlyUpdating: boolean
}

export interface PortalServiceProviderSearchResult {
  facilityID: number
  facilityName: string
  serviceProviderName: string
  facilityHours: PortalFacilityHours[]
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  latitude: number | null
  longitude: number | null
  phoneNumber: string | null
  website: string | null
  qualityRating: string
  offeringNarrative: string
  providesBeforeAfterSchoolCare: boolean
  programs: PortalProgram[]
}

export interface PortalFacilityHours {
  dayOfWeek: string
  openTime: string
  closeTime: string
}

export interface PortalProgram {
  name: string
  description: string | null
  s3Key: string | null
}

export interface PortalFacilityViewModel {
  facilityID: number
  facilityName: string
  facilityHours: PortalFacilityHours[]
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  latitude: number | null
  longitude: number | null
}

export interface InterestFormLabel {
  id: number
  labelText: string
  hoverText: string
  name: string
}

export interface PortalChildSummary {
  id: number
  firstName: string
  lastName: string
  dateOfBirth: Date | null
  unborn: boolean
}

export interface PortalCaregiverSummary {
  id: number
  firstName: string
  lastName: string
  phoneNumber: string | null
  email: string | null
}

export interface ReferralDocumentModel {
  id: number
  childID: number
  fileName: string
  whenUploaded: Date
  verified: boolean
  verifiedDate: Date | null
  verifiedBy: string | null
  verificationDetails: string | null
  documentIncome: number | null
  incomeYear: number | null
  caregivers: string[]
  isLinked: boolean
}

export interface DocumentVerifyModel {
  documentID?: number
  documentTypeID?: number
  incomeRelated: boolean
  incomeAmount: number | null
  incomeYear: number | null
  isVerified: boolean
  verifyNotes: string | null
  documentCaregivers: DocumentCaregiverModel[]
}

export interface DocumentCaregiverModel {
  caregiverID: number
  name: string
  appliesTo: boolean
}
