/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Typography } from '@mui/material'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { ReferralAcceptEdit } from './ReferralAcceptEdit'
import { useEffect, useState } from 'react'
import { type AcceptReferralRequest, type sessionBlock, type lookup } from '../../core/types'
import { sendGet } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'

export interface ReferralAcceptModalProps {
  in: boolean
  onClose: () => void
  onConfirm: (acceptRequest: AcceptReferralRequest) => void
  documentStatus: boolean | null
  referralId: number
  isdid: number
  spid: number
  programid: number | null
  serviceid: number
  serviceProviderName: string
  errors: string[]
  facilityId: number | null
  sessionBlockId: number | null
  incomeRelated: boolean
}

export interface PriorityProgram {
  id: number
  name: string
  ageRange: number
  cutoffPercent: number
}

export function ReferralAcceptModal (props: ReferralAcceptModalProps): JSX.Element {
  const [acceptRequest, setAcceptRequest] = useState<AcceptReferralRequest>({ facilityId: props.facilityId, sessionBlockId: props.sessionBlockId, referralId: props.referralId, enrollNow: false } as AcceptReferralRequest)
  const [sessionBlockList, setSessionBlockList] = useState<sessionBlock[]>([])
  const [facilityList, setFacilityList] = useState<lookup[]>([])
  const [sessionList, setSessionList] = useState<lookup[]>([])
  const [priorityProviders, setPriorityProviders] = useState<Array<{ id: number, name: string, districtDefaults: number[] }>>([])
  const [priorityPrograms, setPriorityPrograms] = useState<PriorityProgram[]>()
  const [showPriority, setShowPriority] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setAcceptRequest({ referralId: props.referralId, sessionBlockId: props.sessionBlockId, enrollNow: false, facilityId: props.facilityId } as AcceptReferralRequest)
      if (props.programid != null) {
        const { response: facilities } = await sendGet(`/CareNetworkEdit/GetFacilities?isdid=${props.isdid}&providerId=${props.spid}` +
          `&programID=${props.programid}`, {})
        setFacilityList(facilities)
      }

      const { response: rsp2 } = await sendGet(`/CareNetworkEdit/GetPriority?isdId=${props.isdid}`, {})
      if (rsp2 != null && rsp2.length > 0) {
        setPriorityPrograms(rsp2)
        const programid: string = rsp2[0].id
        const { response: rsp3 } = await sendGet(`/CareNetworkEdit/GetPriorityProviders?isdId=${props.isdid}&programId=${programid}`, {})
        if (rsp3 != null) {
          setPriorityProviders(rsp3)
        }
      } else {
        setPriorityPrograms(undefined)
      }
    }

    if (props.in) {
      void fetchData()
    }
  }, [props.in])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (priorityPrograms != null && priorityPrograms.length > 0) {
        const { response: incomeResponse } = await sendGet(`/ChildDetails/GetIncomeFPLByReferral?referralId=${props.referralId}`, {})
        const childAge = incomeResponse.age >= 4 ? 4 : incomeResponse.age

        // if this program is not the same as the prio program, but the child fits in the right age and income bracket, show prio selection
        const prog = priorityPrograms.find(p => p.ageRange === childAge)
        if (prog != null && incomeResponse.incomePercent <= prog.cutoffPercent) {
          const { response: defaultProviderResponse } = await sendGet(`/ChildDetails/GetDefaultProvider?referralId=${props.referralId}`, {})
          toast.warning(`This child qualifies for the ${prog.name} program. A local ${prog.name} provider will need to approve a waiver prior to sending this ` +
              `application to ${props.serviceProviderName}. Submitting this referral will send a request to the selected ${prog.name} provider below.`)
          setShowPriority(true)
          setAcceptRequest({ ...acceptRequest, requiresPriority: true, priorityProviderId: acceptRequest.priorityProviderId ?? defaultProviderResponse })
        } else {
          setAcceptRequest({ ...acceptRequest, requiresPriority: false })
        }
      }
    }

    if (props.in && props.incomeRelated) {
      void fetchData()
    }
  }, [priorityPrograms, props.in, props.incomeRelated])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (acceptRequest.facilityId == null || props.programid == null) return

      const { response: sessionBlockList } = await sendGet(`/SessionBlock/GetSessionBlockByFacility?facilityID=${acceptRequest.facilityId}&programID=${props.programid ?? ''}`, {})
      setSessionBlockList(sessionBlockList)
    }
    void fetchData()
  }, [acceptRequest.facilityId])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (acceptRequest.sessionBlockId == null) return

      const { response: sessionList } = await sendGet(`/SessionList/GetSessions?blockId=${acceptRequest.sessionBlockId ?? 0}&facilityId=${acceptRequest.facilityId ?? 0}`, {})
      setSessionList(sessionList)
      setAcceptRequest({ ...acceptRequest, sessionId: null })
    }
    void fetchData()
  }, [acceptRequest.sessionBlockId, acceptRequest.facilityId])

  const onConfirm = (): void => {
    props.onConfirm(acceptRequest)
  }

  const docError = props.documentStatus === false

  const display = <Box>
    {docError && (
      <Typography>
        There are missing documents that need to be verified before this can be accepted.
      </Typography>
    )}
    {!docError && (
      <ReferralAcceptEdit
        acceptRequest={acceptRequest}
        showPriority={showPriority}
        onChange={setAcceptRequest}
        blocks={sessionBlockList}
        facilityList={facilityList}
        sessions={sessionList}
        priorityProviders={priorityProviders}
        errors={props.errors}
      />
    )}
  </Box>

  return <BannerModal
    dataTestId='accept-referral-modal'
    className='Nested Referral'
    maxWidth='sm'
    open={props.in}
    content={display}
    onClose={props.onClose}
    onConfirm={onConfirm}
    isUpdateDisabled={docError}
    title='Accept Referral'
    cancelButtonText='No, Cancel'
    confirmButtonText='Yes, Continue'
  />
}
