/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Modal } from '../../../Components/Modal'
import { LocationSelect } from '../../../Components/Cards/LocationCard/LocationSelect'
import { type PortalCaregiverSummary, type PortalChildSummary, type location } from '../../../core/types'
import { useEffect, useState } from 'react'
import { FormControlLabel, RadioGroup } from '@mui/material'
import Radio from '@mui/material/Radio'
import { toast } from 'react-toastify'
import { sendGet } from '../../../hooks/use-fetch'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'

export interface ChangeAddressProps {
  open: boolean
  onClose: () => void
  onSubmit: (location: location, type: AddressChangeType, selectedMembers: Array<{ id: number, type: MemberType }>) => void
}

export enum AddressChangeType {
  Household,
  SinglePerson,
  SelectMembers
}

export enum MemberType {
  Caregiver,
  Child
}

export function ChangeAddress (props: ChangeAddressProps): JSX.Element {
  const [newLocation, setNewLocation] = useState<location>({} as location)
  const [changeType, setChangeType] = useState<AddressChangeType | null>(null)
  const [children, setChildren] = useState<PortalChildSummary[]>([])
  const [caregivers, setCaregivers] = useState<PortalCaregiverSummary[]>([])
  const [selectedMembers, setSelectedMembers] = useState<Array<{ id: number, type: MemberType }>>([])

  useEffect(() => {
    if (changeType == AddressChangeType.SelectMembers && (children.length === 0 || caregivers.length === 0)) {
      const getData = async (): Promise<void> => {
        const { response, success } = await sendGet('/CaregiverPortal/GetRelatedMembers', {})
        if (success) {
          setChildren(response.children)
          setCaregivers(response.caregivers)
        }
      }
      void getData()
    }
  }, [changeType])

  const handleClose = (): void => {
    props.onClose()
  }

  const handleTypeChange = (event: any): void => {
    setChangeType(event.target.value as AddressChangeType)
  }

  const locationSelect = (loc: location): void => {
    setNewLocation(loc)
  }

  const handleSubmit = (): void => {
    if (changeType === null) {
      toast.error('Please select an option')
    } else if (newLocation.address === undefined) {
      toast.error('Please enter an address')
    } else if (changeType == AddressChangeType.SelectMembers && selectedMembers.length === 0) {
      toast.error('Please select at least one member')
    } else {
      props.onSubmit(newLocation, changeType, selectedMembers)
    }
  }

  const memberChange = (id: number, type: MemberType, adding: boolean): void => {
    const existing = selectedMembers.find(l => l.id === id && l.type === type)
    if (adding && existing === undefined) {
      setSelectedMembers([...selectedMembers, { id, type }])
    } else if (!adding && existing !== undefined) {
      setSelectedMembers(selectedMembers.filter(l => l.id !== id || l.type !== type))
    }
  }

  const content =
    <>
      <RadioGroup value={changeType} onChange={handleTypeChange}>
        <FormControlLabel value={AddressChangeType.Household} control={<Radio />} label='All members living at this caregiver&apos;s address are moving to a new location' />
        <FormControlLabel value={AddressChangeType.SinglePerson} control={<Radio />} label='Only this caregiver is moving to a new location' />
        <FormControlLabel value={AddressChangeType.SelectMembers} control={<Radio />} label='Select which members are moving to a new location' />
      </RadioGroup>
      {changeType == AddressChangeType.SelectMembers &&
        <>
          {caregivers.map(c => {
            return <CheckBoxWithLabel
              label={`${c.firstName} ${c.lastName}`}
              value={selectedMembers.findIndex(l => l.id === c.id && l.type === MemberType.Caregiver) !== -1}
              name={`caregiver${c.id}`}
              setValue={(value) => { memberChange(c.id, MemberType.Caregiver, value) }}
              edge={'start'}
            />
          })}
          {children.map(c => {
            return <CheckBoxWithLabel
              label={`${c.firstName} ${c.lastName}`}
              value={selectedMembers.findIndex(l => l.id === c.id && l.type === MemberType.Child) !== -1}
              name={`child${c.id}`}
              setValue={(value) => { memberChange(c.id, MemberType.Child, value) }}
              edge={'start'}
            />
          })}
        </>
      }

      <LocationSelect hidePrimary={true} showHomelessOption={false} onEnter={locationSelect} location={newLocation} />
    </>

  return <Modal
    open={props.open}
    titleContent={<>Change Address</>}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
