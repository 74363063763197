import { useEffect, useState } from 'react'
import { sendPost } from '../../hooks/use-fetch'
import { Box, CircularProgress, Grid } from '@mui/material'
import AlertMessage from '../AlertMessage'
import { LargeCard } from '../Cards/Large'
import { generatePath, useNavigate } from 'react-router'
import { CARENETWORK_MANAGEMENT_DETAILS } from '../../Pages/Routes'
import { AccountType, type ChildEditValues, type location } from '../../core/types'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { AccessRequestAction } from '../../Pages/CareNetworkManagement/CareNetworkDetail/AccessRequestAction'

interface DuplicateListProps {
  child: ChildEditValues
  onDuplicatesFound?: (dupesFound: boolean) => void
  onLoadChildData?: (childData: ChildSearchResult) => void
}

interface DuplicateChild {
  id: number | null
  firstName: string
  lastName: string
  dateOfBirth: Date
  birthGender: string
  uic: string
  address?: location | null
  requestingISDID: number | null
}

export interface ChildSearchResult {
  id: number
  name: string
  age: string
  dateOfBirth: Date
  birthGender: string | null
  uic: string | null
  residentISD: string
  isdID: number
  address: string
  city: string
  state: string
  zipCode: string
  canSelect: boolean
  childNotTiedToISD: boolean
  currentAccessRequestID: number | null
  currentAccessRequestStatus: boolean | null
  childServices: Array<{ systemProgramName: string, systemServiceName: string, systemServiceProviderName: string, systemServiceProviderID: number, systemProgramID: number, systemServiceID: number, referralId?: number, enrollmentId?: number }>

}

export function DuplicateList (props: DuplicateListProps): JSX.Element {
  const [duplicateList, setDuplicateList] = useState<ChildSearchResult[]>([])
  const [loaded, setLoaded] = useState(false)
  const nav = useNavigate()
  const [refreshTime, setRefreshTime] = useState(new Date())

  const cardClick = (item: ChildSearchResult, canSelect: boolean): void => {
    if (account?.type === AccountType.SERVICE_PROVIDER && props.onLoadChildData != null) {
      props.onLoadChildData(item)
    } else if (canSelect) {
      const editChild = generatePath(CARENETWORK_MANAGEMENT_DETAILS, { id: item.id.toString() ?? '' })
      nav(editChild)
    }
  }

  const account = useAccountId()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const child: DuplicateChild = {
        id: null,
        birthGender: props.child.birthGender,
        dateOfBirth: props.child.dateOfBirth != null ? new Date(props.child.dateOfBirth) : new Date(),
        firstName: props.child.firstName,
        lastName: props.child.lastName,
        uic: props.child.uic ?? '',
        requestingISDID: account?.type === AccountType.ISD ? account.id : (props.child.isdID ?? 0)
      }
      if (isNaN(child.dateOfBirth.getDate())) {
        setLoaded(true)
        return
      }
      const { response: details } = await sendPost('/ChildDetails/GetDuplicates', child)
      if (details !== null) {
        setDuplicateList(details)
      }
      props.onDuplicatesFound?.(details.length > 0)
      setLoaded(true)
    }
    void fetchData()
  }, [props.child, refreshTime])

  if (!loaded) return <CircularProgress />
  if (duplicateList?.length === 0) return <></>

  return <><Grid container data-testid="duplicateGrid">
    <AlertMessage type={'warning'} message={'Based on the information you have entered, we believe there is a high chance this is a duplicate record.'} />

    {duplicateList.map((item: ChildSearchResult) => {
      return <Grid item sm={12} md={12} key={item.id} className='pt-10'>
        <LargeCard
          className='Bolder'
          onClick={() => { cardClick(item, item.canSelect) }}
          content={<Grid container>
            <Grid item xs={8} >
              {item.address !== null && (<>
                <Box>
                  {item.address}
                </Box>
                <Box>
                  {item.city} {item.state}, {item.zipCode}
                </Box>
              </>
              )}
              <Box>
                {item.birthGender}
              </Box>
              <Box>
                {item.uic}
              </Box>
              <Box>
                {item.childServices.map(s => {
                  return <Box>{s.systemServiceProviderName} - {s.systemServiceName} - {s.systemProgramName}</Box>
                })}
              </Box>
            </Grid >
            <Grid item xs={4} >
              {item.canSelect || account?.type === AccountType.SERVICE_PROVIDER
                ? <KeyboardArrowRightIcon sx={{ height: '80px', width: '80px' }} />
                : <AccessRequestAction
                    childNotTiedToISD={item.childNotTiedToISD}
                    currentAccessRequestID={item.currentAccessRequestID}
                    currentAccessRequestStatus={item.currentAccessRequestStatus}
                    requiresAccessRequest={!item.canSelect}
                    childId={item.id}
                    changeMade={() => { setRefreshTime(new Date()) }}
                />
              }
            </Grid>
          </Grid>}
          header={`${item.name} (${item.dateOfBirth !== null ? new Date(item.dateOfBirth).toLocaleDateString() : ''})`}
          fullWidth
        />
      </Grid>
    })}
  </Grid>
  </>
}
