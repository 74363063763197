import { Box, CircularProgress, Grid, LinearProgress, Typography, type SelectChangeEvent } from '@mui/material'
import { LargeCard } from '../../../Components/Cards/Large'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { Multiselect } from '../../../Components/MultiSelect/MultiSelect'
import { type SelectOption, SelectWithLabel } from '../../../Components/SelectWithLabel'
import { BusinessWeekHours } from '../../../Components/BusinessHours/BusinessWeekHours'
import { type session, type businessHours, type staffMember, type sessionStaffMember, type postError, type seatAllocation, type lookup } from '../../../core/types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { sendDelete, sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { ErrorDisplay, useErrorDispatch } from '../../../ContextProviders/ErrorProvider'
import { Modal } from '../../../Components/Modal'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { AcceptDenyModal } from '../../../Components/Modal/AcceptDenyModal'
import { BannerModal } from '../../../Components/Modal/BannerModal'

interface detailModalProps {
  session: session
  setSession: (session: session) => void
  onConfirm: (id: number) => void
  onCancel: () => void
  onDelete: () => void
}

export function SessionDetail (props: detailModalProps): JSX.Element {
  const { sessionId, blockId } = useParams()
  const spAccount = useAccountId()
  const [capacity, setCapacity] = useState(0)
  const [noCapacityLimit, setNoCapacityLimit] = useState(false)
  const [allPrograms, setAllPrograms] = useState<seatAllocation[]>([])
  const [session, setSession] = useState<session>(props.session)
  const [staffCount, setStaffCount] = useState(0)
  const [facilities, setFacilities] = useState<Array<SelectOption<number>>>([])
  const [rooms, setRooms] = useState<Array<SelectOption<number>>>([])
  const [loaded, setLoaded] = useState(false)
  const [loaded2, setLoaded2] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [allStaff, setAllStaff] = useState<Array<SelectOption<number>>>([])
  const dispatch = useErrorDispatch()
  const [showPreChangeModal, setShowPreChangeModal] = useState(false)
  const [pendingFacility, setPendingFacility] = useState<number | null>(null)
  const [validProgramIds, setValidProgramIds] = useState<number[]>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const showPreChangeWarning = session.programs?.length > 0 || session.sessionHours?.length > 0 || session.sessionStaff?.length > 0

  const handleSubmit = async (): Promise<void> => {
    if (overCap) {
      toast.error('The program seats exceed the selected room\'s capacity.')
      return
    }

    await saveSession()
  }

  const saveSession = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      let resp
      if (sessionId === '0' || sessionId === 'new') {
        resp = await sendPost('/SessionList/Create', session)
      } else {
        resp = await sendPut('/SessionList/Update', session)
      }
      const rsp: { errors: postError[], success: boolean, newRecordID: number } = resp.response
      if (!rsp.success) {
        dispatch({ errors: rsp.errors, type: 'update' })
      } else {
        dispatch({ type: 'clear' })
        props.onConfirm(rsp.newRecordID)
      }
      setIsRequestLoading(false)
    }
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: facilitiesRsp, success } = await sendGet(`/SessionList/GetFacilitiesBySessionblock?sessionBlockId=${blockId ?? ''}`, {})
      if (success) {
        setFacilities(facilitiesRsp)
        if (facilitiesRsp.length === 0) {
          toast.error('There are no facilities associated with this session block. Please add a facility to this session block before adding a session.')
          setLoaded2(true)
          return
        }
      }

      const blockresp = await sendGet(`/SessionList/GetBlock?blockId=${blockId ?? ''}`, {})
      if (blockresp.success) {
        setValidProgramIds(blockresp.response.selectedProgramIds)
      }

      if (props.session.currentEnrollments === 0 && (props.session.currentReferrals === null || props.session.currentReferrals === 0)) {
        setCanDelete(true)
      }

      if (props.session.currentEnrollments > 0) {
        toast.error('This Session has enrollments associated with it. Changing any details regarding this session can have adverse effects.')
      } else if ((!props.session.noCapacityLimit && props.session.programs?.find(p => p.seats === 0) !== undefined) ||
        props.session.sessionHours.length === 0 ||
        props.session.staffLeadID === null ||
        sessionId === 'new'
      ) {
        toast.error('This Session\'s details are incomplete. Please fill out all required fields.')
      }

      if (props.session.facilityRoomID > 0) {
        const { response: capResponse } = await sendGet(`/SessionList/GetRoomCapacity?roomID=${props.session.facilityRoomID}`, {})
        setCapacity(capResponse.capacity)
        setNoCapacityLimit(capResponse.noCapacityLimit)

        const resp = await sendGet(`/SessionList/GetRoomPrograms?roomID=${props.session.facilityRoomID}`, {})
        if (resp.success) {
          setAllPrograms(resp.response)
          if (session.programs === undefined || session.programs === null || session.programs.length === 0) {
            const seatAllocations = resp.response?.map((p: { id: number, name: string, seats: number }) => {
              return { id: p.id, name: p.name, seats: 0 }
            })
            const ses = { ...session, programs: seatAllocations }
            setSession(ses)
          }
        }
      }
      setLoaded2(true)
    }
    void loadData()
  }, [])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const staff = await sendGet(`/SessionList/AllStaff?serviceProviderID=${spAccount?.id ?? 0}`, {})
      if (staff.success) {
        const staffArray: lookup[] = [{ id: 0, name: '(none)' }]
        staff.response?.forEach((s: staffMember) => {
          staffArray.push({ id: s.id, name: `${s.firstName} ${s.lastName}` })
        })
        setAllStaff(staffArray)
      }

      if (session.facilityID != null && session.facilityID > 0) {
        const resp = await sendGet(`/SessionList/GetFacilityRooms?facilityID=${session.facilityID}&blockId=${blockId ?? 0}`, {})
        if (resp.success) {
          setRooms(resp.response)
        }
      }
      setLoaded(true)
    }
    void loadData()
  }, [session.id, session.facilityID])

  const handleHoursChanged = async (newHours: businessHours[]): Promise<void> => {
    setSession({ ...session, sessionHours: newHours })
  }

  const handleHoursAccept = async (newHours: businessHours[]): Promise<void> => {
    const ses = { ...session, sessionHours: newHours }
    setSession(ses)
  }

  const handleChange = (e: any): void => {
    setSession({ ...session, [e.target.name]: e.target.value })
  }

  const onConfirmFacilityChange = (): void => {
    applyFacilityChange(pendingFacility ?? 0)
    setShowPreChangeModal(false)
  }

  const applyFacilityChange = (facilityId: number): void => {
    const ses = { ...session }
    ses.programs?.forEach(p => { p.seats = 0 })
    ses.sessionStaff = []
    ses.sessionHours = []
    ses.staffLeadID = 0
    ses.facilityRoomID = 0
    ses.facilityID = facilityId
    setSession(ses)
  }

  const handleFacilityChange = (e: any): void => {
    if (showPreChangeWarning) {
      setShowPreChangeModal(true)
      setPendingFacility(e.target.value)
    } else {
      setShowPreChangeModal(false)
      applyFacilityChange(e.target.value)
    }
  }

  const handleFacilityRoomChange = async (e: any): Promise<void> => {
    const val = parseInt(e.target.value)

    const { response: capResponse } = await sendGet(`/SessionList/GetRoomCapacity?roomID=${val}`, {})
    setCapacity(capResponse.capacity)
    setNoCapacityLimit(capResponse.noCapacityLimit)
    const resp = await sendGet(`/SessionList/GetRoomPrograms?roomID=${val}`, {})
    setAllPrograms(resp.response)

    const seatAllocations = resp.response?.map((p: { id: number, name: string, seats: number }) => {
      return { id: p.id, name: p.name, seats: 0 }
    })
    const ses = { ...session, facilityRoomID: e.target.value, programs: seatAllocations }
    setSession(ses)
  }

  const handleDateChange = async (name: string, date: Date | null): Promise<void> => {
    const ses = { ...session, [name]: date }
    setSession(ses)
  }

  const handleStaffLeadChange = async (newLeadId: SelectChangeEvent<string | number | boolean>): Promise<void> => {
    const ses = { ...session, staffLeadID: newLeadId.target.value as number }
    setSession(ses)
  }

  const handleProgramChange = async (e: any): Promise<void> => {
    const programName = e.target.name

    const programsCopy = [...session.programs]
    const program = programsCopy?.find(p => p.name === programName)
    if (program !== undefined) {
      let val = parseInt(e.target.value)
      if (isNaN(val)) { val = 0 }
      program.seats = val
      setSession({ ...session, programs: programsCopy })
    }
  }

  const handleStaffChange = async (newStaff: Array<{ id: number, value: number | null }>): Promise<void> => {
    const ses = {
      ...session,
      sessionStaff: newStaff.map(s => {
        const member: sessionStaffMember = {
          id: s.value as number
        }
        return member
      })
    }
    setSession(ses)
  }

  const handleActiveChange = (e: any): void => {
    setSession({ ...session, inactive: e.target.value })
  }

  const handleDelete = async (): Promise<void> => {
    if (!canDelete) {
      toast.error('The session cannot be deleted because it has enrollments or referrals')
    } else {
      setShowDeleteModal(true)
    }
  }

  const handleConfirmDelete = async (): Promise<void> => {
    if (!canDelete) {
      toast.error('The session cannot be deleted because it has enrollments or referrals')
      return
    }

    await deleteSession()
  }

  const deleteSession = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const resp = await sendDelete(`/SessionList/Delete?sessionId=${session.id}`)
      const rsp: { errors: postError[], success: boolean } = resp.response
      if (!rsp.success) {
        dispatch({ errors: rsp.errors, type: 'update' })
      } else {
        dispatch({ type: 'clear' })
        props.onDelete()
      }
      setIsRequestLoading(false)
    }
  }

  if (!loaded || !loaded2) return <CircularProgress />

  const hoursContent = <>
    <ErrorDisplay fieldName={'sessionHours'} />
    <BusinessWeekHours
      BusinessWeek={session.sessionHours}
      onChange={handleHoursChanged}
      onAccept={handleHoursAccept}
    />
  </>

  const seatCount = session.programs?.reduce((a, b) => a + (b.seats ?? 0), 0)
  const capacityValue = seatCount / capacity
  const overCap = !noCapacityLimit && capacityValue > 1

  return <>
    <AcceptDenyModal
      maxWidth='lg'
      onClose={() => { props.onCancel() } }
      open={true}
      title='Edit Session'
      acceptButtonText='Update'
      cancelButtonText='Discard Changes'
      denyButtonText='Delete'
      onAccept={handleSubmit}
      onDeny={handleDelete}
      dialogContent={
        <Box width={'100%'}
          data-testid='sessionDetail'
        >
          <Modal
            className=''
            onClose={() => { setShowPreChangeModal(false) }}
            onConfirm={onConfirmFacilityChange}
            open={showPreChangeModal}
            title={'Confirm Facility Change'}
            confirmationContent={'This will clear the selected room along with the Schedule, Staff and Capacity sections. Are you sure you would like to continue?'}
          />
          <Grid container spacing={1} >
            <Grid item xs={12} md={6} >
              <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <LargeCard
                  fullWidth
                  className='Borderless'
                  content={<Box>
                    <TextFieldWithLabel
                      label='Name'
                      name='name'
                      onChange={handleChange}
                      value={session?.name ?? ''}
                    />
                    <SelectWithLabel
                      width='100%'
                      label='Facility'
                      name='facilityID'
                      onChange={handleFacilityChange}
                      value={session.facilityID ?? ''}
                      options={facilities}
                      disabled={session.currentEnrollments > 0}
                    />
                    <SelectWithLabel
                      width='100%'
                      label='Facility Room'
                      name='facilityRoomID'
                      onChange={handleFacilityRoomChange}
                      value={session.facilityRoomID ?? ''}
                      options={rooms}
                      disabled={session.currentEnrollments > 0}
                    />
                  </Box>}
                  header='Name & Location'
                />
                <LargeCard
                  fullWidth
                  className='Borderless'
                  content={<Box>
                    <SelectWithLabel
                      width='100%'
                      label='Staff Lead'
                      name='staffLeadID'
                      onChange={handleStaffLeadChange}
                      value={(session.staffLeadID ?? 0) > 0 ? (session.staffLeadID ?? '') : ''}
                      options={allStaff}
                    />
                    <Multiselect
                      itemList={session.sessionStaff?.map((s: sessionStaffMember) => { return { id: s.id, value: s.id } }) ?? []}
                      setItemList={handleStaffChange}
                      itemIndex={staffCount}
                      setItemIndex={setStaffCount}
                      options={allStaff}
                      label={'Support Staff'}
                      minLength={0}
                      name='supportStaff'
                    />
                  </Box>}
                  header='Staff'
                />

                <LargeCard
                  fullWidth
                  className='Borderless'
                  header='Capacity & Funding Allocation'
                  content={<Grid container columns={2}>
                    {noCapacityLimit && <Typography variant='body1'>No Capacity Limit</Typography>}
                    {!noCapacityLimit && <>
                      <Grid item xs={2}>
                        <ErrorDisplay fieldName={'totalCapacity'} />
                        <TextWithLabel
                          label='Total Room Capacity'
                          name='roomCapacity'
                          value={`${seatCount ?? 0}/${capacity}`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <LinearProgress
                          variant='determinate'
                          color={(capacityValue * 100) > 100 ? 'error' : 'secondary'}
                          value={capacityValue * 100}
                        />
                      </Grid>

                      {session.programs?.map(s => {
                        const isDisabled = validProgramIds?.find(i => i === s.id) === undefined
                        const maxSeats = allPrograms.find(p => p.name === s.name)?.seats
                        return <Grid item xs={2} key={s.name}>
                          <TextFieldWithLabel
                            type='number'
                            label={s.name ?? ''}
                            name={s.name ?? ''}
                            onChange={handleProgramChange}
                            value={s.seats ?? 0}
                            min={session.currentEnrollments}
                            max={maxSeats}
                            disabled={isDisabled}
                            toolTip={isDisabled ? 'This block is not configured to support this program' : undefined}
                          />
                        </Grid>
                      })}
                    </>}
                  </Grid>}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <LargeCard
                  className='Borderless'
                  fullWidth={true}
                  header='Dates'
                  content={<>
                    <ErrorDisplay fieldName={'dateFields'} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                      <Box>
                        <DatePickerWithLabel
                          label='Start Date'
                          name='startDate'
                          onChange={async (d) => { await handleDateChange('startDate', d) }}
                          value={session.startDate}
                        />
                      </Box>
                      <Box>
                        <DatePickerWithLabel
                          label='End Date'
                          name='endDate'
                          onChange={async (d) => { await handleDateChange('endDate', d) }}
                          value={session.endDate}
                        />
                      </Box>
                    </Box>
                  </>}
                />
                <LargeCard
                  className='Borderless'
                  key='datesCard'
                  fullWidth={true}
                  content={hoursContent}
                  header='Schedule'
                />
                <SelectWithLabel
                  width='240px'
                  label='Status'
                  name='status'
                  onChange={handleActiveChange}
                  options={[{ id: false, name: 'Active' }, { id: true, name: 'Inactive' }]}
                  value={session.inactive}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    />
    {showDeleteModal && <BannerModal
      onConfirm={handleConfirmDelete}
      onClose={() => { setShowDeleteModal(false) }}
      confirmButtonText='Delete'
      confirmButtonColor='error'
      cancelButtonText='Cancel'
      content={<>Are you sure you want to delete this session?</>}
      title='Delete Session'
      open={showDeleteModal}
    >
    </BannerModal>
    }
  </>
}
