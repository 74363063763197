import { CircularProgress, TableBody, TableCell } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import type * as React from 'react'
import { ColumnType, type Column, type RowData, DEFAULT_CHECKBOX_ID_COLUMN_NAME } from './DataTable.model'

export interface DataTableProps {
  columns: Column[]
  rows: RowData[]
  loading?: boolean
  hasCheckbox?: boolean
  checkboxIdColumnName?: string
  checkedItems?: any[]
  hoverShading?: boolean
  rowClickable?: boolean
  onRowCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, row: RowData) => void
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData) => void
}

export default function DataTableBody (props: DataTableProps): JSX.Element {
  const columns = props.columns != null ? props.columns : []
  const rows = props.rows ?? []
  const loading = props.loading != null ? props.loading : false
  const hasCheckbox = props.hasCheckbox != null ? props.hasCheckbox : true
  const checkboxIdColumnName = props.checkboxIdColumnName != null ? props.checkboxIdColumnName : DEFAULT_CHECKBOX_ID_COLUMN_NAME
  const checkedItems = props.checkedItems != null ? props.checkedItems : []
  const hoverShading = props.hoverShading != null ? props.hoverShading : true
  const rowClickable = props.rowClickable != null ? props.rowClickable : true
  const { onRowCheckboxChange, onRowClick } = props

  const visibleColumns = columns.filter(column => column.visibility)
  const totalTableColumns = !hasCheckbox ? visibleColumns.length : (visibleColumns.length + 1)

  const _onRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: RowData): void => {
    if (onRowCheckboxChange != null) {
      onRowCheckboxChange(event, row)
    }
  }

  const _onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    if (onRowClick != null) {
      onRowClick(event, row)
    }
  }

  return (
    <TableBody>
      {
        !loading
          ? null
          : <TableRow>
            <TableCell colSpan={totalTableColumns}>
              <div className="d-flex f-justify-content-center">
                <CircularProgress />
              </div>
            </TableCell>
          </TableRow>
      }
      {
        loading
          ? null
          : rows.map(row => {
            return (
              <TableRow
                data-testid='data-row'
                key={row.id}
                className={rowClickable ? 'cursor-pointer' : ''}
                selected={hasCheckbox && checkedItems.includes(row[checkboxIdColumnName])}
                {...(hoverShading || rowClickable ? { hover: true } : {})}
                {...(rowClickable
                  ? { onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => { _onRowClick(event, row) } }
                  : {})
                }
              >
                {
                  hasCheckbox
                    ? <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={checkedItems.includes(row[checkboxIdColumnName])}
                        onChange={event => { _onRowCheckboxChange(event, row) }}
                      />
                    </TableCell>
                    : null
                }
                {
                  visibleColumns.map(column => {
                    const attributes = column.key === checkboxIdColumnName
                      ? { id: row[column.key], scope: 'row' }
                      : {}
                    const elementToUse = column.key === checkboxIdColumnName ? 'th' : 'td'
                    const cellKey = column.key
                    switch (column.type) {
                      case ColumnType.TEXT:
                        return <TableCell key={cellKey} component={elementToUse} {...attributes}>{row[column.key]}</TableCell>
                      case ColumnType.CUSTOM:
                        return <TableCell key={cellKey} component={elementToUse} {...attributes}>
                          {column.customCellGenerator(row)}
                        </TableCell>
                      default:
                        return null
                    }
                  })
                }
              </TableRow>
            )
          })
      }
    </TableBody>
  )
}
