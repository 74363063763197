import { type Dispatch, createContext, useReducer, useContext } from 'react'
import { type postError } from '../core/types'

export const ErrorContext = createContext<postError[] | undefined>(undefined)
export const ErrorDispatchContext =
        createContext<Dispatch<{
          errors?: postError[] | undefined
          type: string
        }>>(() => null)

export function useError (fieldName: string): postError[] | undefined {
  let ctx = useContext(ErrorContext)
  if (fieldName !== '') ctx = ctx?.filter(e => e.field === fieldName)
  return ctx
}

export function useErrorDispatch (): Dispatch<{
  errors?: postError[] | undefined
  type: string
}> {
  return useContext(ErrorDispatchContext)
}
export function ErrorDisplay (props: { fieldName: string }): JSX.Element {
  const errors = useError(props.fieldName)
  if (errors === undefined || errors.length === 0) return <></>
  return (
    <>
      {
        errors.map(e => {
          return <p data-testid={e.field + 'errorDisplay'} style={{ color: 'red' }} key={e.error}>{e.error}</p>
        })
      }
    </>
  )
}
export function ErrorProvider (props: { children: JSX.Element }): JSX.Element {
  const [errors, dispatch] = useReducer(errorReducer, initialErrors)
  return (
    <ErrorContext.Provider value={errors}>
      <ErrorDispatchContext.Provider value={dispatch}>
        {props.children}
      </ErrorDispatchContext.Provider>
    </ErrorContext.Provider>
  )
}
function errorReducer (errors: postError[] | undefined, action: { errors?: postError[] | undefined, type: string }): postError[] | undefined {
  switch (action.type) {
    case 'update': {
      return action.errors
    }
    case 'clear': {
      return undefined
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initialErrors = undefined
