/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Modal } from '../../Components/Modal'
import S3Image from '../../Components/S3Image'
import { toast } from 'react-toastify'
import { sendPost } from '../../hooks/use-fetch'
import { type userNotificationSetting, type lookup } from '../../core/types'
import { Typography, Switch, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
export interface SubscriptionEditProps {
  show: boolean
  onClose: () => void
  onSubmit: () => void
  usernotificationSetting: userNotificationSetting[]
  userSecurityType: string
  imageKey?: string | null
  imageAltText?: string | null
  userid: number
  defaultEvents: lookup[]
  title: string
  id: number
  type: string
}
export function UserSubscriptionEdit (props: SubscriptionEditProps): JSX.Element {
  const [index, setIndex] = useState(-1)
  const [userSettings, setUserSettings] = useState<userNotificationSetting[]>([])
  useEffect(() => {
    setUserSettings(props.usernotificationSetting?.map((uns: userNotificationSetting) => {
      return {
        caregiverID: uns.caregiverID,
        eventType: uns.eventType,
        id: uns.id,
        userID: uns.userID,
        intakeServiceProviderID: uns.intakeServiceProviderID,
        isAppNotificationEnabled: uns.isAppNotificationEnabled,
        appSubscriptionDate: uns.appSubscriptionDate,
        emailSubscriptionDate: uns.emailSubscriptionDate,
        isEmailNotificationEnabled: uns.isEmailNotificationEnabled,
        isdid: uns.isdid,
        serviceProviderID: uns.serviceProviderID
      }
    }) ?? [])
  }, props.usernotificationSetting)
  const handleSubmit = async (): Promise<void> => {
    if (userSettings !== undefined) {
      const { success } = await sendPost('/User/SetUserNotificationSetting', { UserID: props.userid, Settings: userSettings, ID: props.id, Type: props.type })
      if (success) {
        toast.success('Your subscriptions have been updated.')
        props.onSubmit()
      }
    }
  }

  const handleChange = (setting: userNotificationSetting, checked: boolean, permissionType: string): void => {
    const settingsCopy = [...userSettings]
    let settingId = setting.id
    if (settingId === 0) {
      settingId = index
      const newSetting = { ...setting, id: settingId }
      settingsCopy.push(newSetting)
      setIndex(index - 1)
    }
    const updatedVal = settingsCopy.find(s => s.id === settingId)
    if (updatedVal != null) {
      if (permissionType === 'APP') {
        updatedVal.isAppNotificationEnabled = checked
      } else {
        updatedVal.isEmailNotificationEnabled = checked
      }

      setUserSettings(settingsCopy)
    }
  }
  const content = <>{props.imageKey != null && (
    <S3Image imageKey={props.imageKey ?? 'none'} alt={props.imageAltText ?? ''} />
  )}
    <Typography>{props.title}</Typography>
    <Grid container xs={12}>
      <Grid item xs={4} key="subscription-header"><Typography sx={{ fontWeight: 'bold' }}>Subscriptions</Typography></Grid>
      <Grid item xs={4} key="app-header"><Typography sx={{ fontWeight: 'bold' }}>App</Typography></Grid>
      <Grid item xs={4} key="email-header"><Typography sx={{ fontWeight: 'bold' }}>Email</Typography></Grid>
      {props.defaultEvents.map(item => {
        const eventType = item.name
        let setting = userSettings.find(s => s.eventType === eventType)
        if (setting === undefined) {
          setting = {
            id: 0, isAppNotificationEnabled: false, isEmailNotificationEnabled: false, eventType
          } as userNotificationSetting
        }
        return <><Grid item xs ={4}>{setting.eventType}</Grid>
          <Grid item xs ={4}><Switch
            name={setting.eventType}
            checked={setting.isAppNotificationEnabled}
            onChange={(e, isChecked) => { handleChange(setting as userNotificationSetting, isChecked, 'APP') }}
          /></Grid>
          <Grid item xs ={4}><Switch
            name={setting.eventType}
            checked={setting.isEmailNotificationEnabled}
            onChange={(e, isChecked) => { handleChange(setting as userNotificationSetting, isChecked, 'EMAIL') }}
          /></Grid>
        </>
      })}
    </Grid>
  </>
  return <Modal
    onClose={props.onClose}
    onConfirm={handleSubmit}
    open={props.show}
    titleContent={<Typography>{props.title} Notification Settings</Typography>}
    confirmationContent={content}
  />
}
