/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Button, CircularProgress, Chip } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { type systemServiceProvider } from '../../core/types'
import { type PaginationProps, usePagination } from '../../hooks/use-pagination'
import { sendGet, sendPost, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import { SystemServiceProviderEdit } from './ServiceProviderDetails'

const nameColumn = new Column('name', 'Name')
const statusColumn = new Column('inactive', 'In Use', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  let color = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  const isInactive = row?.inactive as boolean
  if (isInactive) {
    color = '#D4316C !important'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={isInactive ? 'Inactive' : 'Active'} size="small" sx={{ color, backgroundColor }} />
}
const iconColumn = new Column('image', 'Icon', ColumnType.CUSTOM)
iconColumn.customCellGenerator = row => {
  if (row.s3Key != null) {
    const imagePath = row.s3Key as string
    return <img
      src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${imagePath}`}
      alt='logo'
      data-testid={'logo-' + imagePath ?? ''}
      style={{ maxWidth: '100px' }}
    />
  } else {
    return <div></div>
  }
}
const columns = [nameColumn, statusColumn, iconColumn]

export function SystemServiceProviderList (): JSX.Element {
  const initialProvider: systemServiceProvider = { eemCode: '', id: 0, name: '', imageKey: '' } as systemServiceProvider
  const [providers, setProviders] = useState<systemServiceProvider[]>([])
  const [selectedProvider, setSelectedProvider] = useState<systemServiceProvider>(initialProvider)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [updateDate, setUpdateDate] = useState(new Date())

  const paginationProps: PaginationProps<RowData> = {
    rows: providers
  }
  const pagination = usePagination(paginationProps)

  const addClick = (): void => {
    setSelectedProvider(initialProvider)
    setOpen(true)
    setIsNew(true)
  }
  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const selectedService: systemServiceProvider = row as systemServiceProvider
    setSelectedProvider(selectedService)
    setOpen(true)
    setIsNew(false)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: providerResp } = await sendGet('/SystemProviderMaintenance/GetAll', {})
      if (providerResp !== null) {
        setProviders(providerResp)
      }
      setLoading(false)
    }
    void loadData()
  }, [updateDate])

  const handleSubmit = async (providerData: systemServiceProvider, file: File | null): Promise<void> => {
    if (!loading) {
      setLoading(true)
      let recordID
      let suc = false
      const url = `/SystemProviderMaintenance/${isNew ? 'Insert' : 'Update'}`

      if (isNew) {
        const { response, success } = await sendPost(url, providerData)

        recordID = response
        suc = success
      } else {
        const { success } = await sendPut(url, providerData)
        recordID = providerData.id
        suc = success
      }

      if (suc && file != null) {
        const fileUrl = '/SystemProviderMaintenance/UploadFile'
        const fileData = new FormData()
        fileData.append('id', recordID)
        fileData.append('file', file)
        const { success } = await sendPostFormData(fileUrl, fileData)
        suc = success
      }
      if (suc) {
        setOpen(false)
        setUpdateDate(new Date())
      } else {
        setLoading(false)
      }
    }
  }

  if (loading) {
    return <CircularProgress role='loading' />
  } else {
    return <Box width='fit-content' sx={{ marginTop: '10px' }}>
      <Box justifyContent='flex-end' display='flex' width='100%' >
        <Button data-test-add-service-provider color='secondary' variant='contained' onClick={addClick}>Add Provider</Button>
      </Box>

      <DataTable
        page={pagination.page}
        data-testid='providers-table'
        name='providersTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        initialColumnSorts={[new ColumnSort('name')]}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
      />
      {open && (
        <SystemServiceProviderEdit
          details={selectedProvider}
          onClose={() => { setOpen(false) }}
          onSave={handleSubmit}
          show={open}
        />
      )}
    </Box>
  }
}
