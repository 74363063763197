/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Breadcrumbs, Button, Chip, CircularProgress, IconButton, Link, ListItemText, MenuItem, MenuList, Popover, Typography } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CardIcon from '@mui/icons-material/ArticleOutlined'
import TableIcon from '@mui/icons-material/TableRowsOutlined'
import { type SelectOption, SelectWithLabel } from '../../../Components/SelectWithLabel'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { LargeCard } from '../../../Components/Cards/Large'
import { sendDelete, sendGet } from '../../../hooks/use-fetch'
import { type location, type facility, type lookup } from '../../../core/types'
import { FormatDateAsTimeAgo } from '../../../core/Utilities'
import './Facilities.css'
import _ from 'lodash'
import DataTable from '../../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { SERVICE_PROVIDER_ADMIN_FACILITIES_DETAILS, SERVICE_PROVIDER_ADMIN_SETTINGS } from '../../Routes'
import { FacilityDetailsEdit } from './FacilityDetailsEdit'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { BannerModal } from '../../../Components/Modal/BannerModal'
import { toast } from 'react-toastify'

interface facilityView {
  id: number
  name: string
  inactive: boolean
  status: JSX.Element
  address: string
  addressLine2: string
  fullAddress: string
  lastModified: Date
  formattedLastModified: string
}

export function FacilitiesListPage (): JSX.Element {
  const account = useAccountId()?.id
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [statuses] = useState<Array<SelectOption<string>>>([{ id: 'All', name: 'All' }, { id: 'Active', name: 'Active' }, { id: 'Inactive', name: 'Inactive' }])
  const [currentStatus, setCurrentStatus] = useState<string>('All')
  const [facilities, setFacilities] = useState<facilityView[]>([])
  const [allFacilities, setAllFacilities] = useState<facilityView[]>([])
  const [loading, setLoading] = useState(true)
  const [isCardModeSelected, setIsCardModeSelected] = useState(true)
  const nav = useNavigate()
  const [showConfirmation, setConfirmation] = useState(false)
  const [deletingId, setDeletingId] = useState(0)

  const [showAddNew, setShowAddNew] = useState(false)
  const paginationProps: PaginationProps<RowData> = {
    rows: facilities
  }
  const pagination = usePagination(paginationProps)

  const handleStatusChange = (e: any): void => {
    const status = e.target.value
    setCurrentStatus(status)
    if (status === 'Active') {
      setFacilities(allFacilities.filter(f => !f.inactive))
    } else if (status === 'Inactive') {
      setFacilities(allFacilities.filter(f => f.inactive))
    } else if (status === 'All') {
      setFacilities(allFacilities)
    }
  }

  const handleModeChange = (isCardMode: boolean): void => {
    if (isCardMode) {
      setIsCardModeSelected(true)
    } else {
      setIsCardModeSelected(false)
    }
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    facilityClick(row)
  }

  const facilityClick = (row: RowData): void => {
    const facilityId: string = row.id
    const editFacility = generatePath(SERVICE_PROVIDER_ADMIN_FACILITIES_DETAILS, {
      id: facilityId
    })

    nav(editFacility)
  }
  const closePopup = (): void => {
    setShowAddNew(false)
  }
  const onSave = (): void => {
    setRefreshTime(new Date())
    setShowAddNew(false)
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, facilityView: facilityView): void => {
    setAnchorEl(event.currentTarget)
    setDeletingId(facilityView.id)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const onDelete = async (): Promise<void> => {
    setConfirmation(true)
    setAnchorEl(null)
  }
  const onCancelDelete = (): void => {
    setConfirmation(false)
  }
  const onConfirmDelete = async (): Promise<void> => {
    const { response, error } = await sendDelete(`/Facility/Delete/${deletingId ?? ''}`)

    if (error[0] === '' && response === null) {
      setConfirmation(false)
      setRefreshTime(new Date())
      toast.success('The facility was removed from the system')
    } else {
      error.forEach(e => {
        if (e !== '') {
          toast.error(e)
        }
      })
      if (response != null) {
        toast.warning(response)
      }
    }
  }

  const createFacility = (): void => {
    setShowAddNew(true)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: facilitiesRsp } = await sendGet(`/FacilityDetails/GetAllForProvider?systemServiceProviderID=${account ?? 0}`, {})
      if (facilitiesRsp !== null) {
        const sortedFacilities: facility[] = _.sortBy(facilitiesRsp, r => r.name)
        const facilityViews = sortedFacilities.map(f => {
          let color = '#4DA4A4 !important'
          let backgroundColor = 'rgba(77, 164, 164, 0.1)'
          let text = 'Active'
          if (f.inactive) {
            color = '#D4316C !important'
            backgroundColor = 'rgba(212, 49, 108, 0.1)'
            text = 'Inactive'
          }

          const addressLine2 = `${f.location.city}, ${f.location.state} ${f.location.zipCode}`

          return {
            id: f.id,
            name: f.name,
            inactive: f.inactive,
            status: <Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={text} size="small" sx={{ color, backgroundColor }} />,
            address: f.location.address,
            addressLine2,
            fullAddress: `${f.location.address}, ${addressLine2}`,
            lastModified: f.lastModified,
            formattedLastModified: FormatDateAsTimeAgo(f.lastModified)
          }
        })
        setFacilities(facilityViews)
        setAllFacilities(facilityViews)
      }
      setLoading(false)
    }
    void loadData()
  }, [refreshTime])

  const [isds, setIsds] = useState<lookup[]>([])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const resp = await sendGet('/FacilityDetails/GetAllIsds', {})
      setIsds(resp.response)
    }
    void loadData()
  }, [])

  if (loading) return <CircularProgress/>

  const cardContent =
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {facilities.map(f => {
        const content =
          <Box className='facility-card-content' data-testid='facility-card'>
            <Box className='d-flex f-direction-column mt-10' >
              <span>{f.address}</span>
              <span>{f.addressLine2}</span>
            </Box>
            <span>{f.status}</span>
            <span>{f.formattedLastModified}</span>
          </Box>

        return <LargeCard
          key={f.id}
          header={f.name}
          content={content}
          className='facility-card'
          contentClassName='facility-card-content-wrapper'
          headerClassName='facility-card-header'
          onClick={() => { facilityClick(f) }}
          actions={ <IconButton sx={{ p: 0 }} onClick={(e) => { handleClick(e, f) }}><MoreHorizIcon /></IconButton>}
        />
      })}
    </Box>

  const nameColumn = new Column('name', 'Facility Name')
  const addressColumn = new Column('fullAddress', 'Address')
  const statusColumn = new Column('inactive', 'Status', ColumnType.CUSTOM)
  statusColumn.customCellGenerator = row => {
    return row.status
  }
  const lastModifiedColumn = new Column('lastModified', 'Last Modified', ColumnType.CUSTOM)
  lastModifiedColumn.customCellGenerator = row => {
    return row.formattedLastModified
  }
  const columns = [nameColumn, addressColumn, statusColumn, lastModifiedColumn]

  const tableContent = <DataTable
    data-testid='facilities-table'
    name='facilitiesTable'
    page={pagination.page}

    hasCheckbox={false}
    columns={columns}
    rows={pagination.internalRows ?? []}
    totalRecords={pagination.recordCount}
    loading={loading}
    initialColumnSorts={[new ColumnSort('name')]}
    onSortChange={(col, sorts) => {
      pagination.onSortChange(col, sorts[0].order)
    }}
    onPageChange={pagination.handleChangePage}
    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    onRowClick={rowClick}
  />
  const open = anchorEl !== null
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <ErrorProvider>
        <FacilityDetailsEdit
          isds={isds}
          isNew={true}
          show={showAddNew}
          facility={{
            location: {} as location,
            name: '',
            inactive: false,
            id: 0,
            lastModified: new Date(),
            serviceProviderID: account ?? 0,
            facilityCode: '',
            facilityHours: [],
            licenseExpirationDate: null,
            licenseNumber: '',
            phoneNumber: '',
            website: '',
            isdid: 0,
            isdName: '',
            locationISDFound: false,
            offeringNarrative: '',
            programs: [],
            qualityRating: 0,
            services: [],
            providesBeforeAfterSchoolCare: false
          }}
          onSave={onSave}
          onClose={closePopup}
        />
      </ErrorProvider>
      <Typography variant="h3">Facilities</Typography>

      <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ marginTop: '10px', marginBottom: '10px' }}>
        <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(SERVICE_PROVIDER_ADMIN_SETTINGS) }} >
          Service Provider Settings
        </Link>
      </Breadcrumbs>

      <Box className='d-flex f-justify-content-space-between'>
        <SelectWithLabel
          label='Status'
          name='status'
          onChange={handleStatusChange}
          width='135px'
          value={currentStatus}
          options={statuses}
        />
        <Box className='d-flex f-direction-column'>
          <Box>
            <IconButton onClick={() => { handleModeChange(true) }} className={'selectable-icon-button ' + (isCardModeSelected ? 'selected-icon' : '')}>
              <CardIcon className='selectable-icon' />
            </IconButton>
            <IconButton onClick={() => { handleModeChange(false) }} className={'selectable-icon-button ' + (!isCardModeSelected ? 'selected-icon' : '')}>
              <TableIcon className='selectable-icon' />
            </IconButton>
          </Box>
          <Button variant='contained' data-testid="btn-add-facility" color='secondary' className='mt-10' onClick={createFacility}>Add Facility</Button>
        </Box>
      </Box>
      <Popover
        id = {id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MenuList>
          <MenuItem onClick={onDelete}>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <BannerModal
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Confirm Delete'
        content={<Typography>Are you sure you want to delete this facility?</Typography>}
        open={showConfirmation}
        onClose={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
      {isCardModeSelected && cardContent}
      {!isCardModeSelected && tableContent}
    </>
  )
}
