import { type DocumentVerifyModel } from '../../core/types'
import { useState, useEffect } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { Box, CircularProgress, TextField, Select, MenuItem, Grid } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material/Select'
import { SwitchWithLabel } from '../Switch/SwitchWithLabel'
import { TextAreaWithLabel } from '../TextArea'

// set documentID if an existing document is being selected, otherwise set documentTypeID to specify the type of the document being added
export interface DocumentVerifyProps {
  documentID?: number | undefined
  documentTypeID?: number | undefined
  childID: number
  togglingVerificationStatus: boolean
  onChange: (item: DocumentVerifyModel) => void
  years: number[]
}

export function DocumentVerify (props: DocumentVerifyProps): JSX.Element {
  const [documentVerify, setDocumentVerify] = useState<DocumentVerifyModel | null>(null)

  if (props.documentID !== undefined) {
    useEffect(() => {
      const fetchData = async (): Promise<void> => {
        setDocumentVerify(null)
        const { response, success } = await sendGet(`/Document/GetDocumentVerifyDetails?childID=${props.childID}&documentID=${props.documentID ?? 0}`, {})
        if (success) {
          const item = (response as DocumentVerifyModel)
          item.documentID = props.documentID ?? 0
          setDocumentVerify(item)
        }
      }
      void fetchData()
    }, [props.documentID])
  } else if (props.documentTypeID !== undefined) {
    useEffect(() => {
      const fetchData = async (): Promise<void> => {
        setDocumentVerify(null)
        const { response, success } = await sendGet(`/Document/NewDocumentVerifyDetails?childID=${props.childID}&documentTypeID=${props.documentTypeID ?? 0}`, {})
        if (success) {
          const item = (response as DocumentVerifyModel)
          item.documentID = props.documentID ?? 0
          setDocumentVerify(item)
        }
      }
      void fetchData()
    }, [props.documentTypeID])
  } else {
    throw new DOMException('Either documentID or documentTypeID must be provided')
  }
  useEffect(() => {
    if (documentVerify !== null) {
      props.onChange(documentVerify)
    }
  }, [documentVerify])

  const caregiverStateChanged = (caregiverID: number, checked: boolean): void => {
    if (documentVerify !== null) {
      const newCaregivers = documentVerify.documentCaregivers.map((caregiver) => {
        if (caregiver.caregiverID === caregiverID) {
          return { ...caregiver, appliesTo: checked }
        }
        return caregiver
      })
      setDocumentVerify({ ...documentVerify, documentCaregivers: newCaregivers })
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<unknown>): void => {
    if (documentVerify !== null) {
      setDocumentVerify({ ...documentVerify, [event.target.name]: event.target.value })
      props.onChange(documentVerify)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (documentVerify !== null) {
      setDocumentVerify({ ...documentVerify, [event.target.name]: event.target.value })
    }
  }

  if (documentVerify === null) {
    return <CircularProgress />
  }
  return <Box>
    {(!props.togglingVerificationStatus || !documentVerify.isVerified) && <>
      {documentVerify.documentCaregivers.length !== 0 && <>
        <Box>Which caregivers does this document apply to?</Box>
        {documentVerify.documentCaregivers.map((caregiver) => {
          return <Box key={caregiver.caregiverID}>
            <SwitchWithLabel
              onChange={(event, checked) => { caregiverStateChanged(caregiver.caregiverID, checked) }}
              label={caregiver.name}
              name='appliesTo'
              dataTestId={`caregiverSwitch${caregiver.caregiverID}`}
              checked={caregiver.appliesTo}
              isInline={true}
            />
          </Box>
        })
        }
      </>
      }
      {documentVerify.incomeRelated && <>
        <Box>What is the total annual income defined in this document?</Box>
        <Grid container direction='row' spacing='10'>
          <Grid item>
            <div style={{ padding: '18px 5px 0 0', display: 'inline-block' }}>$</div>
            <TextField name='incomeAmount' onChange={handleChange} data-testid='incomeAmount' value={documentVerify.incomeAmount} sx={{ width: '120px' }} />
          </Grid>
          <Grid item>
            <Select value={documentVerify.incomeYear} name='incomeYear' data-testid='incomeYear' onChange={handleSelectChange} sx={{ width: '100px' }}>
              {props.years.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </Select>
          </Grid >
        </Grid >
      </>
      }
    </>
    }
    <Box>
      <TextAreaWithLabel
        label='Verification Details'
        name='verifyNotes'
        dataTestId='verifyNotes'
        onChange={handleChange}
        value={documentVerify.verifyNotes}
      />
    </Box>
  </Box >
}
