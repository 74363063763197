import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { sendGet, sendPut } from '../../../hooks/use-fetch'
import { Column, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { usePagination, type PaginationProps } from '../../../hooks/use-pagination'
import DataTable from '../../../Components/Table/DataTable'
import { RelationshipEdit } from './RelationshipEdit'
import { type postError } from '../../../core/types'
import { toast } from 'react-toastify'
import { FormatBoolean } from '../../../core/Utilities'

export interface ChildRelationship {
  id: number | null
  childID: number
  caregiverID: number
  relationshipID: number | null
  financiallyResponsibleFor: boolean | null
  caregiverName: string
  relationshipName: string | null
  childName?: string | null
}

export interface ChildRelationshipsProps {
  childID: number
  canEdit: boolean
}

export function ChildRelationships (props: ChildRelationshipsProps): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const [relationships, setRelationships] = useState<ChildRelationship[]>([])
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [editRecord, setEditRecord] = useState<ChildRelationship | null>(null)
  const [errors, setErrors] = useState<postError[]>([])

  useEffect(() => {
    async function getData (): Promise<void> {
      const { response, success } = await sendGet(`/CaregiverPortal/GetChildRelationships/${props.childID}`, {})
      if (success) {
        setRelationships(response)
      }
      setLoading(false)
    }
    void getData()
  }, [refreshTime])

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setEditRecord(row as ChildRelationship)
    setShowEditModal(true)
  }

  const handleSubmit = async (data: ChildRelationship): Promise<void> => {
    setErrors([])

    const { response } = await sendPut('/CaregiverPortal/UpdateRelationship', data)
    const rsp: { errors: postError[], success: boolean, response: any } = response

    if (rsp.success) {
      setShowEditModal(false)
      setRefreshTime(new Date())
      toast.success('Relationship record has been saved')
    } else {
      setErrors(rsp.errors)
    }
  }

  const paginationProps: PaginationProps<RowData> = {
    rows: relationships
  }

  const pagination = usePagination(paginationProps)
  const nameColumn = new Column('caregiverName', 'Caregiver Name')
  const relationshipColumn = new Column('relationshipID', 'Relationship', ColumnType.CUSTOM)
  relationshipColumn.customCellGenerator = row => {
    return <div>{row.id == null ? '(none)' : row.relationshipName} </div>
  }
  const frfColumn = new Column('financiallyResponsibleFor', 'Financially Responsible For', ColumnType.CUSTOM)
  frfColumn.customCellGenerator = row => {
    return <div>{FormatBoolean(row.financiallyResponsibleFor)} </div>
  }

  const columns = [nameColumn, relationshipColumn, frfColumn]

  if (loading) {
    return <CircularProgress />
  } else {
    return <>
      <DataTable
        page={pagination.page}
        data-test-table
        name='relationshipsTable'
        columns={columns}
        loading={loading}
        hasCheckbox={false}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        sortable={true}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        rowClickable={props.canEdit}
        onRowClick={rowClick}
      />

      {showEditModal && editRecord !== null && (
        <RelationshipEdit
          open={showEditModal}
          onSubmit={handleSubmit}
          onClose={() => { setShowEditModal(false) }}
          relationship={editRecord}
          errors={errors}
        />
      )
      }
    </>
  }
}
