import { Box, Grid, Chip, Typography, Button, Paper, Avatar, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { SessionHeader } from './SessionHeader'
import { usePagination, type PaginationProps, type SearchCriteria } from '../../../hooks/use-pagination'
import { Column, ColumnSort, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import DataTable from '../../../Components/Table/DataTable'
import { LargeCard } from '../../../Components/Cards/Large'
import { SessionListSearch, type SessionSearchFields } from './SessionListSearch'
import { sendGet } from '../../../hooks/use-fetch'
import { useNavigate, useParams, generatePath } from 'react-router'
import { Modal } from '../../../Components/Modal'
import {
  SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS,
  SERVICE_PROVIDER_ADMIN_SERVICES,
  SERVICE_PROVIDER_ADMIN_SESSION_BLOCK
} from '../../Routes'
import { ListCards } from './SummaryCards/ListCards'
import { FormatTimeAs12Hour } from '../../../core/Utilities'

const sessionNameColumn = new Column('name', 'Session Name')
const facilityColumn = new Column('facility', 'Facility')
const scheduleColumn = new Column('schedule', 'Schedule', ColumnType.CUSTOM)
scheduleColumn.sortable = false
scheduleColumn.customCellGenerator = row => {
  const rowDat = row as sessionRecord
  return <Box>
    {rowDat.sessionHours.map(h => {
      let hoursContent = 'various'
      if (h.openingTime !== null) {
        hoursContent = `${FormatTimeAs12Hour(h.openingTime)} - ${FormatTimeAs12Hour(h.closingTime)}`
      }
      return <Box key={h.dayDisplay}>
        <Box>{h.dayDisplay}</Box>
        <Box>{hoursContent}</Box>
      </Box>
    })}
  </Box>
}
const staffColumn = new Column('staffLead', 'Staff Lead')
const programsColumn = new Column('programs', 'Programs', ColumnType.CUSTOM)
programsColumn.customCellGenerator = row => {
  const rowDat = row as sessionRecord
  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
    {rowDat.programs.map(p => {
      const colorItem = p.id % 3
      let indicatorClass = 'cyan-'
      if (colorItem === 1) {
        indicatorClass = 'purple-'
      } else if (colorItem === 2) {
        indicatorClass = 'red-'
      }
      const icon = <Avatar sx={{ width: 20, height: 20 }} className={'dark-' + indicatorClass + 'indicator'}>{p.seats}</Avatar>
      return <Chip
        key={p.name}
        icon={rowDat.noCapacityLimit ? undefined : icon}
        label={p.name}
        size='small'
        className={indicatorClass + 'indicator'}
      />
    })}
  </Box>
}
const capacityColumn = new Column('capacity', 'Capacity', ColumnType.CUSTOM)
capacityColumn.customCellGenerator = row => {
  return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    {row.noCapacityLimit === true && <Typography>No Limit</Typography>}
    {row.noCapacityLimit !== true && <><Typography>{row.currentEnrollments}</Typography>/<Typography>{row.totalCapacity}</Typography></>}
  </Box>
}

export interface sessionRecord {
  id: number
  name: string
  facility: string
  days: string
  staffLead: string
  programs: programRecord[]
  totalCapacity: number
  noCapacityLimit: boolean
  currentEnrollments: number
  sessionHours: sessionHour[]
}

export interface programRecord {
  id: number
  name: string
  seats: number
}

interface sessionHour {
  dayOfWeek: string
  dayDisplay: string
  openingTime: string
  closingTime: string
}

export function SessionList (): JSX.Element {
  const { serviceId, blockId } = useParams()
  const [serviceName, setServiceName] = useState('')
  const [sessionBlockName, setSessionBlockName] = useState('')
  const [rows, setRows] = useState<sessionRecord[]>([])
  const [allocations, setAllocations] = useState<Array<{ key: string, count: number }>>([])
  const [totalSeats, setTotalSeats] = useState(0)
  const [importModalOpen, setImportModalOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const nav = useNavigate()

  const addSession = (): void => {
    const route = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS,
      { serviceId: serviceId ?? '', blockId: blockId ?? '', sessionId: 'new' })
    nav(route)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const resp = await sendGet(`/SessionList/GetSessions?blockId=${blockId ?? ''}`, {})
      if (resp.success) {
        setRows(resp.response)
        const allocations = (resp.response as sessionRecord[]).flatMap(r => { return r.programs })
        const groupedAllocations: Array<{ key: string, count: number }> = []
        setTotalSeats(allocations.reduce((a, b) => a + b.seats, 0))
        allocations.forEach(a => {
          const existing = groupedAllocations.find(g => g.key === a.name) ?? null
          if (existing != null) {
            existing.count += a.seats
          } else {
            groupedAllocations.push({ key: a.name, count: a.seats })
          }
        })
        setAllocations(groupedAllocations)
      }

      const serviceResp = await sendGet(`/SessionList/GetService?serviceId=${serviceId ?? ''}`, {})

      if (serviceResp.success) {
        setServiceName(serviceResp.response.name)
      }

      const blockresp = await sendGet(`/SessionList/GetBlock?blockId=${blockId ?? ''}`, {})
      if (blockresp.success) {
        setSessionBlockName(blockresp.response.name)
      }
      setIsLoaded(true)
    }
    void loadData()
  }, [blockId, serviceId])

  const [search, setSearch] = useState<SessionSearchFields>({ facility: '', program: '', inactive: false })
  const columns = [sessionNameColumn, facilityColumn, scheduleColumn, staffColumn, programsColumn, capacityColumn]

  const openImportSession = (): void => {
    setImportModalOpen(true)
  }

  const paginationProps: PaginationProps<RowData> = {
    rows,
    initialSize: 5,
    pageSizeSteps: [5, 10],
    initialFilter: [{ field: 'inactive', value: 'false' }]
  }
  const pagination = usePagination(paginationProps)
  if (!isLoaded) return <CircularProgress />

  const handleSearch = (newdata: SessionSearchFields): void => {
    setSearch(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.facility !== '') {
      fieldArray.push({
        field: 'facility',
        value: newdata.facility
      })
    }
    if (newdata.program !== '') {
      fieldArray.push({
        field: 'programs.id',
        value: newdata.program
      })
    }
    fieldArray.push({
      field: 'inactive',
      value: newdata.inactive.toString()
    })

    pagination.setSearchFields(fieldArray)
  }

  const emptyDisplay = <LargeCard fullWidth
    header=''
    content={<Grid container>
      <Grid data-testid="emptyCard" item sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>There are currently no sessions for this session block.</Typography>
        <Typography>Here you can set up all of your sessions for the school year by selecting a facility room, schedule, and seat types. You can also import
          sessions from a previous block.
        </Typography>
        <Box data-testid="addbox">
          <Button data-testid="addButton" id='addButton' variant='contained' color='secondary' onClick={addSession}>Add Session</Button>
        </Box>
        <Box>
          <Button variant='contained' color='inherit' onClick={openImportSession}>Import From Previous Year</Button>
        </Box>
      </Grid>
      <Grid item></Grid>
    </Grid>}
  />

  const tableDisplay = <Box>
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <SessionListSearch search={search} setSearch={handleSearch}/>
      </Grid>
      <Grid item alignSelf={'center'}>
        <Button data-testid="addButton" variant='contained' color='secondary' sx={{ marginBottom: '16px' }} onClick={addSession}>Add Session</Button>
      </Grid>
    </Grid>
    <Paper>
      <DataTable
        name='sessionsTable'
        data-testid='sessionTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        page={pagination.page}
        loading={false}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={(e: any, r: RowData) => {
          const route = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS,
            { serviceId: serviceId ?? '', blockId: blockId ?? '', sessionId: r.id })
          nav(route)
        }}
        initialColumnSorts={[new ColumnSort('id')]}
      />

    </Paper>
  </Box>

  const breadCrumbs = [
    {
      path: generatePath(SERVICE_PROVIDER_ADMIN_SERVICES, {}),
      text: 'Services'
    },
    {
      path: generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK, {
        serviceId: serviceId ?? ''
      }),
      text: serviceName
    }

  ]
  return <Box>
    <SessionHeader
      breadCrumbs={breadCrumbs}
      cards={<ListCards
        allocations={allocations}
        facilityCapacity={totalSeats}
      />}
      title={sessionBlockName}
    />
    <Box>
      {rows.length > 0
        ? tableDisplay
        : emptyDisplay
    }
      {importModalOpen && (
        <Modal
          onClose={() => { setImportModalOpen(false) }}
          open={importModalOpen}
          onConfirm={() => {}}
          confirmationContent={<>import content</>}
          title={'Import Sessions'}
        />
      )}

    </Box>
  </Box>
}
