import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { type carenetworkService } from '../../../core/types'
import { Box, CircularProgress } from '@mui/material'
import { ServiceCard } from '../../../Components/Services/ServiceCard'

export interface ChildServicesProps {
  childID: number
  canEdit: boolean
}

export function ChildServices (props: ChildServicesProps): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [services, setServices] = useState<carenetworkService[]>([])

  useEffect(() => {
    async function getServices (): Promise<void> {
      const { response, success } = await sendGet(`/CaregiverPortal/GetServices?id=${props.childID}`, {})
      if (success) {
        setServices(response)
      }
      setLoading(false)
    }
    void getServices()
  }, [])

  if (loading) {
    return <CircularProgress />
  } else {
    return <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {services.length === 0 && <Box>There are no services for this child.</Box>}
      {services.map((service) => (
        <ServiceCard
          key={service.referralId}
          service={service}
          childId={props.childID}
        />
      ))}
    </Box>
  }
}
