import { type ChangeEvent, useEffect, useState, useRef } from 'react'
import { type lookup, type postError } from '../../../core/types'
import { type ChildMemberDetails } from './ChildDisplay'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { Box, Grid, Typography, type SelectChangeEvent, Container, CircularProgress, Button, DialogActions } from '@mui/material'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { AgeFromDate } from '../../../core/Utilities'
import { generatePath, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { CAREGIVER_PORTAL_MEMBERS, CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS } from '../../Routes'
import { childName } from '../PortalHelpers'
import { type ChildRelationship } from './ChildRelationships'

const newChild: ChildMemberDetails = {
  id: 0,
  isdid: 0,
  firstName: '',
  lastName: '',
  middleName: '',
  nickname: '',
  suffix: '',
  dateOfBirth: null,
  birthGender: '',
  races: [],
  languageID: undefined,
  primaryLanguage: '',
  hasIEP: false,
  hasIFSP: false,
  hasEOIS: false,
  fosterChild: false,
  hasSNAPBenefits: false,
  isOnCashAssistance: false,
  isOnSSI: false,
  dueDate: null,
  unborn: false,
  primaryLocationID: null,
  locationIDs: [],
  location: null,
  canEdit: true
}

interface ChildAddress {
  id: number
  address: string
  apartment: string
  city: string
  state: string
  zipCode: string
}

export function ChildEdit (): JSX.Element {
  const { childId } = useParams()
  const isNew = useRef(childId === 'new')
  const isdID = useRef(0)
  const nav = useNavigate()
  const [child, setChild] = useState<ChildMemberDetails>(newChild)
  const [relationships, setRelationships] = useState<ChildRelationship[]>([])
  const [languages, setLanguages] = useState<lookup[]>([])
  const [raceOptions, setRaceOptions] = useState<lookup[]>([])
  const [relationshipTypes, setRelationshipTypes] = useState<lookup[]>([])
  const [addresses, setAddresses] = useState<ChildAddress[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [captureIEP, setCaptureIEP] = useState(false)
  const [captureIFSP, setCaptureIFSP] = useState(false)
  const [captureEOIS, setCaptureEOIS] = useState(false)
  const [errors, setErrors] = useState<postError[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/Language/GetLookup?sort=true', {})
      if (error[0] === '') {
        setLanguages(response)
      }

      const { response: raceResponse } = await sendGet('/Race/GetLookup', {})
      setRaceOptions(raceResponse)

      if (isNew.current) {
        const { response: isdRsp } = await sendGet('/CaregiverPortal/GetISDSettings?id=', {})
        if (isdRsp != null) {
          isdID.current = isdRsp.ISDID
          setCaptureIEP(isdRsp.captureIEP)
          setCaptureIFSP(isdRsp.captureIFSP)
          setCaptureEOIS(isdRsp.captureEOIS)
        }

        // get the caregivers and load both the how-related list and the person lives with dropdown list
        const { response: relRsp } = await sendGet('/CaregiverPortal/GetRelatedMembers', {})
        let firstCaregiverID: number | null = null
        if (relRsp != null) {
          const howRelatedList: ChildRelationship[] = []
          const livesWithList: lookup[] = []
          relRsp.caregivers.forEach((c: any) => {
            if (firstCaregiverID === null) {
              firstCaregiverID = c.id
            }
            livesWithList.push({
              id: c.id,
              name: `${c.firstName as string} ${c.lastName as string}`
            })
            howRelatedList.push({
              caregiverID: c.id,
              relationshipID: null,
              financiallyResponsibleFor: null,
              caregiverName: `${c.firstName as string} ${c.lastName as string}`,
              id: null,
              childID: 0,
              relationshipName: null
            })
          })
          setRelationships(howRelatedList)
        }
        handleValueChange('livesWithCaregiverID', firstCaregiverID ?? 0)

        const { response: relations, error: err1 } = await sendGet('/Relationship/GetLookup', {})
        if (err1[0] === '') {
          setRelationshipTypes(relations)
        }

        setIsLoading(false)
      } else {
        const { response: childRsp, success } = await sendGet(`/CaregiverPortal/GetChildDetails/${childId ?? '0'}`, {})
        if (success) {
          setChild(childRsp)
        } else {
          toast.error('Failed to load child details')
        }
      }

      const { response: relAdr } = await sendGet(`/CaregiverPortal/GetCaregiverAddresses?childID=${(isNew.current ? '' : childId ?? '')}`, {})
      if (relAdr != null) {
        setAddresses(relAdr)
      }
    }
    void fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!isNew.current) {
        const { response: isdRsp } = await sendGet(`/CaregiverPortal/GetISDSettings?id=${child.isdid ?? ''}`, {})
        if (isdRsp != null) {
          isdID.current = isdRsp.ISDID
          setCaptureIEP(isdRsp.captureIEP)
          setCaptureIFSP(isdRsp.captureIFSP)
          setCaptureEOIS(isdRsp.captureEOIS)
        }
        setIsLoading(false)
      }
    }
    void fetchData()
  }, [child])

  const genderOptions: Array<{ id: string, name: string }> = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' }
  ]

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setChild({ ...child, [e.target.name]: e.target.value })
  }

  const handleValueChange = (name: string, newValue: any): void => {
    setChild({ ...child, [name]: newValue })
  }

  const handleSelectChange = async (e: SelectChangeEvent<any | null>): Promise<void> => {
    setChild({ ...child, [e.target.name]: e.target.value })
  }

  function RaceCheckBox (r: lookup): JSX.Element {
    return <CheckBoxWithLabel
      edge={'start'}
      key={r.id}
      label={r.name}
      name={r.name}
      dataTestId={r.name}
      value={child.races.some(race => race === r.id)}
      setValue={(value) => { handleRaceChange(r.id, value) }}
    />
  }

  const handleRaceChange = (id: number, value: boolean): void => {
    let races = [...child.races]
    if (value) {
      races.push(id)
    } else {
      races = races.filter(r => r !== id)
    }
    handleValueChange('races', races)
  }

  const handleRelationshipValueChange = (value: any, relationship: ChildRelationship, index: number, fieldName: string): void => {
    setRelationships([...relationships.slice(0, index), { ...relationship, [fieldName]: value }, ...relationships.slice(index + 1)])
  }

  const returnToDetails = (): void => {
    let route: string
    if (isNew.current) {
      route = generatePath(CAREGIVER_PORTAL_MEMBERS)
    } else {
      route = generatePath(CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS, { childId: childId ?? '' })
    }
    nav(route)
  }

  const addressSelectionChanged = (state: boolean, id: number): void => {
    if (state) {
      setChild({ ...child, locationIDs: [...child.locationIDs, id] })
    } else {
      setChild({ ...child, locationIDs: child.locationIDs.filter(l => l !== id) })
    }
  }

  const handleSubmit = async (): Promise<void> => {
    let rsp: { success: boolean, errors: postError[], newRecordID: number | null }
    if (isNew.current) {
      if (relationships.filter(r => r.relationshipID != null).length === 0) {
        toast.error('Please select at least one caregiver relationship')
        return
      }
      const result = await sendPost('/CaregiverPortal/InsertChild', child)
      rsp = result.response
      if (rsp.newRecordID !== null) {
        relationships.forEach((relationship) => {
          relationship.childID = rsp.newRecordID as number
        })
        if (result.success) {
          await sendPost('/CaregiverPortal/UpdateChildRelationships', relationships)
        }
      }
    } else {
      child.location = null
      const result = await sendPut('/CaregiverPortal/UpdateChildDetails', child)
      rsp = result.response
    }

    if (rsp.success) {
      if (isNew.current) {
        const route = generatePath(CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS, { childId: rsp?.newRecordID?.toString() ?? '' })
        nav(route)
      } else {
        returnToDetails()
      }
    } else {
      window.scrollTo(0, 0)
      setErrors(rsp.errors)
    }
  }

  if (isLoading) {
    return <CircularProgress />
  } else {
    return <Container sx={{ mb: '30px' }}>
      <Box sx={{ mt: '30px', mb: '30px', display: 'flex' }}>
        <Typography variant='h5' sx={{ fontWeight: 600, ml: '5px' }} component='div'>
          {(isNew.current ? 'New Child' : childName(child))}
        </Typography>
      </Box>

      <Box >
        {errors !== undefined && errors.length > 0 &&
          <ul>
            {errors.map((err) => (
              <li key={err.field} style={{ color: 'red', padding: '10px 0 0' }}>
                {err.error}
              </li>
            ))}
          </ul>
        }
        <Grid container spacing={1} columns={6}>
          <Grid item xs={6}>
            <TextFieldWithLabel label='First Name' required={true} data-testid="firstName" name='firstName' maxLength={50} value={child?.firstName ?? ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Nickname' data-testid="nickname" name='nickname' maxLength={50} value={child?.nickname ?? ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Middle Name' data-testid="middleName" name='middleName' maxLength={50} value={child.middleName} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Last Name' required={true} data-testid="lastName" name='lastName' maxLength={50} value={child.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Suffix' data-testid="suffix" name='suffix' maxLength={10} value={child.suffix} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <DatePickerWithLabel label='Date of Birth' required={true} onChange={(newValue) => { handleValueChange('dateOfBirth', newValue) }} value={child?.dateOfBirth ?? null} name='dateOfBirth' />
          </Grid>
          <Grid item xs={6}>
            <SelectWithLabel
              name="birthGender"
              label="Birth Gender"
              width="100%"
              value={child.birthGender}
              includeNoneOption={true}
              options={genderOptions}
              onChange={handleSelectChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectWithLabel
              name="languageID"
              label="Primary Language (if not English)"
              width="100%"
              includeNoneOption={true}
              value={child.languageID}
              options={languages}
              onChange={handleSelectChange}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component="span" variant="subtitle1" id='race'>Race *</Typography>
            {raceOptions.filter(r => r.id !== 4).map(r => {
              return RaceCheckBox(r)
            })
            }
          </Grid>
          <Grid item xs={6}>
            <Typography component="span" variant="subtitle1" id='race'>Ethnicity</Typography>
            {raceOptions.filter(r => r.id === 4).map(r => {
              return RaceCheckBox(r)
            })
            }
          </Grid>

          <Grid item xs={6}>
            <Typography component="span" variant="subtitle1" >Additional Information</Typography>
          </Grid>
          <Grid item xs={6}>
            {captureIEP && child.dateOfBirth !== null && AgeFromDate(child.dateOfBirth) >= 3 &&
              <CheckBoxWithLabel label='Has an Individualized Education Plan (IEP)' edge='start' name='hasIEP' value={child.hasIEP} setValue={(val) => { handleValueChange('hasIEP', val) }} />
            }
            {captureIFSP && child.dateOfBirth !== null && AgeFromDate(child.dateOfBirth) < 3 &&
              <CheckBoxWithLabel label='Has an Individualized Family Service Plan (IFSP)' edge='start' name='hasIFSP' value={child.hasIFSP} setValue={(val) => { handleValueChange('hasIFSP', val) }} />
            }
            {captureEOIS && child.dateOfBirth !== null && AgeFromDate(child.dateOfBirth) >= 3 &&
              <CheckBoxWithLabel label='Has an Early On Intervening Service Plan (EOIS)' edge='start' name='hasEOIS' value={child.hasEOIS} setValue={(val) => { handleValueChange('hasEOIS', val) }} />
            }
            <CheckBoxWithLabel label='Foster Child' edge='start' name='fosterChild' value={child.fosterChild} setValue={(val) => { handleValueChange('fosterChild', val) }} />
            <CheckBoxWithLabel label='SNAP Benefits (food assistance)' edge='start' name='hasSNAPBenefits' value={child.hasSNAPBenefits} setValue={(val) => { handleValueChange('hasSNAPBenefits', val) }} />
            <CheckBoxWithLabel label='Cash Assistance' edge='start' name='isOnCashAssistance' value={child.isOnCashAssistance} setValue={(val) => { handleValueChange('isOnCashAssistance', val) }} />
            <CheckBoxWithLabel label='SSI (Social Security Income)' edge='start' name='isOnSSI' value={child.isOnSSI} setValue={(val) => { handleValueChange('isOnSSI', val) }} />
          </Grid>

          <Grid item xs={6}>
            <Typography component="span" variant="subtitle1" >Where does this child live?</Typography>
          </Grid>
          <Grid item xs={6}>
            <table>
              {addresses.map((address) => {
                return <tr>
                  <td>
                    <CheckBoxWithLabel
                      name="livesWithCaregiverID"
                      label={`${address.address} - ${address.city}`}
                      value={child.locationIDs.includes(address.id)}
                      setValue={(val) => { addressSelectionChanged(val, address.id) }}
                      edge={'start'}
                    />
                  </td>
                  {child.locationIDs.length > 1 && <td>
                    <CheckBoxWithLabel
                      name="primaryLocationID"
                      label='Primary Address'
                      value={child.primaryLocationID === address.id}
                      setValue={(val) => { handleValueChange('primaryLocationID', val ? address.id : null) }}
                      edge={'start'}
                    />
                  </td>
                  }
                </tr>
              }
              )}
            </table>
          </Grid>

        </Grid>
        {isNew.current &&
          <Box>
            <table style={{ marginTop: '15px' }}>
              <tr>
                <th>Caregiver</th>
                <th>Relationship</th>
              </tr>
              {relationships.map((relationship, index) => {
                return <tr>
                  <td style={{ paddingBottom: '15px', paddingRight: '10px' }} >{relationship.caregiverName}</td>
                  <td>
                    <SelectWithLabel
                      width='200px'
                      label=''
                      name='relationshipID'
                      includeNoneOption={true}
                      onChange={(e) => { handleRelationshipValueChange(e.target.value, relationship, index, 'relationshipID') }}
                      options={relationshipTypes}
                      value={relationship.relationshipID ?? undefined}
                    />
                  </td>
                  <td style={{ paddingBottom: '15px' }}>
                    {relationship.relationshipID !== null && <Box>
                      <CheckBoxWithLabel label='Financially Responsible For' edge='start' name='financiallyResponsibleFor'
                        value={relationship.financiallyResponsibleFor ?? false}
                        setValue={(val) => { handleRelationshipValueChange(val, relationship, index, 'financiallyResponsibleFor') }}
                      />
                    </Box>
                    }
                  </td>
                </tr>
              })}
            </table>
          </Box>
        }

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            data-testid='cancelButton'
            variant='outlined'
            color='inherit'
            onClick={returnToDetails}
          >
            Discard Changes
          </Button>

          <Button data-test-update
            data-testid='updateButton'
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Container >
  }
}
