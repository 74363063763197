import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { TabDisplay } from '../../Components/TabDisplay'
import { ServiceList } from './Services'
import { ProgramISDList } from './Programs'
import { StaffList } from './StaffList'
import { sendGet } from '../../hooks/use-fetch'
import { ISDDetails } from './ISDDetails'
import { Configuration } from './Configuration'
import { ErrorProvider } from '../../ContextProviders/ErrorProvider'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { DocumentList } from './Documents'
import { PriorityProviderDefaultConfiguration } from './PriorityProviderDefaultConfiguration'

export interface ISDDetail {
  id: number
  name: string
  notes?: string
  intakeProviderID: number | null
  intakeProviderName: string | null
  website: string
  address: string
  city: string
  state?: string
  zipCode: string
  contactEmail: string
  contactPhone: string
  logoKey?: string | null
  uicapiKey: string | null
  uicapiSecret: string | null
}

export function ISDSettings (): JSX.Element {
  const [value, setValue] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [isDocumentsConfigured, setIsDocumentsConfigured] = useState(false)
  const [currentISD, setCurrentISD] = useState<ISDDetail>()
  const [refreshTime, setRefreshTime] = useState<Date>(new Date())
  const isdID = useAccountId()?.id ?? 0

  const checkISDDocuments = async (): Promise<void> => {
    const { response } = await sendGet('/ISDDocument/IsISDDocumentConfigured?isdid=' + isdID.toString(), {})
    if (response !== undefined) {
      setIsDocumentsConfigured(response)
    }
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (isdID === 0) return

      const { response: rsp, error } = await sendGet('/ISDDetails/GetDetails?ID=' + isdID.toString(), {})
      if (error[0] === '' && rsp !== null) {
        setCurrentISD(rsp)
        setLoaded(true)
      }
      void checkISDDocuments()
    }
    void loadData()
  }, [refreshTime, isdID])

  const refresh = (): void => {
    setRefreshTime(new Date())
  }

  if (!loaded) return <CircularProgress role='loading' />

  return <Box>
    <Typography variant='h3' data-test-page-header>ISD Settings - {currentISD?.name}</Typography>
    <Tabs value={value}
      data-test-tabs
      onChange={(e, val) => { setValue(val) }}
      aria-label="ISD Settings Tabs"
      textColor="secondary"
      indicatorColor="secondary"
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label="Details" value={0} data-test-details />
      <Tab label="Documents" value={1} data-test-documents />
      <Tab disabled={!isDocumentsConfigured} label="Services" value={2} data-test-services />
      <Tab disabled={!isDocumentsConfigured} label="Programs" value={3} data-test-programs />
      <Tab label="Configuration" value={4} data-test-configuration />
      <Tab label="Staff" value={5} data-test-staff />
      <Tab label="District Defaults" value={6} data-test-providerconfig/>
    </Tabs>
    <TabDisplay
      index={0}
      value={value}
    >
      <ISDDetails isd={currentISD} onRefresh={refresh} />
    </TabDisplay>
    <TabDisplay
      index={1}
      value={value}
    >
      <DocumentList refreshTabs={checkISDDocuments } />
    </TabDisplay>
    <TabDisplay
      index={2}
      value={value}
    >
      <ServiceList />
    </TabDisplay>
    <TabDisplay
      index={3}
      value={value}
    >
      <ProgramISDList />
    </TabDisplay>
    <TabDisplay
      index={4}
      value={value}
    >
      <ErrorProvider>
        <Configuration />
      </ErrorProvider>
    </TabDisplay>
    <TabDisplay
      index={5}
      value={value}
    >
      <StaffList />
    </TabDisplay>
    <TabDisplay index={6}
      value={value}
    >
      <PriorityProviderDefaultConfiguration isdid={isdID}/>
    </TabDisplay>
  </Box>
}
