/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material'
import { LabelWithToolTip } from '../../Label/Index'
import { type location } from '../../../core/types'
import { AddLocationCard } from '../ReferralWorkflow/AddLocationCard'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { sendGet } from '../../../hooks/use-fetch'
import AlertMessage from '../../AlertMessage'
import { SelectWithLabel } from '../../SelectWithLabel'
import { useEffect, useState } from 'react'

interface MultiCardListProps {
  label: string
  toolTip?: string
}

export function ChildAddLocationList (props: MultiCardListProps): JSX.Element {
  const [districts, setDistricts] = useState<Array<{ id: number, name: string, isdID: number }>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: rsp2 } = await sendGet('/ReferralEdit/GetDistricts', {})
      setDistricts(rsp2)
    }
    void fetchData()
  }, [])

  const handleUpdate = async (location: location): Promise<string[]> => {
    const tempArray = [...data.locations]
    let loc = tempArray[0]
    if (loc === null || loc === undefined) {
      loc = { index: 0, location: {} as location, edit: true, isNew: true }
      tempArray.push(loc)
    }
    loc.location = location
    dispatch({ type: 'locations', locations: tempArray })
    setLoading(false)
    return []
  }

  const handleDistrictSelect = (e: SelectChangeEvent<number>): void => {
    const val = districts.find(d => d.id.toString() === e.target.value.toString())
    if (val != null) {
      dispatch({ type: 'district', manualDistrict: { id: val.id, isdId: val.isdID } })
    }
  }
  const districtId = (data.manualDistrict != null && data.manualDistrict?.id > 0) ? data.manualDistrict.id : data.isdID ?? 0
  return (
    <Box>
      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          isInline={false}
          required={false}
          name={''}
          labelText={props.label}
          toolTipText={props.toolTip}
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />
        {loading && <CircularProgress />}
        {(data.manualDistrict != null) && (<>
          <AlertMessage
            message={'This address could not be verified. Please select the district you would like to use for this referral'}
            type='warning'
            icon={<></>}
          />
          <SelectWithLabel
            width='100%'
            label='Resident District'
            name='district'
            onChange={handleDistrictSelect}
            options={districts}
            value={districtId}
          />
        </>
        )}
        {(data.locations != null &&
          <AddLocationCard
            location={data.locations[0]?.location ?? {} as location}
            onLocationChange={handleUpdate}
            errors={[]}
          />
        )}
      </Box>
    </Box>
  )
}
