import { useEffect, useState } from 'react'
import { type Column, type ColumnSort, SortOrder } from '../Components/Table/DataTable/DataTable.model'
import { sendGet } from './use-fetch'
import { useAuth } from './use-auth'

export interface SearchProps {
  isdId?: number
  endPoint: string
  searchData: any
  refreshTime: Date
  includes?: string
  autoUpdateOnSearchDataChange: boolean
}

export interface SearchType <T extends object> {
  rows: T[] | null
  totalRecords: number
  loading: boolean
  onSortChange: (column: Column, columnSorts: ColumnSort[]) => void
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, rowsPerPage: number) => void
}

export function useSearch <T extends object> (props: SearchProps): SearchType<T> {
  const [columnSorts, setColumnSorts] = useState<ColumnSort[]>([])
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState<number>(5)
  const [rows, setRows] = useState<T[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const auth = useAuth()

  useEffect(() => {
    let ignore = false
    const fetchData = async (): Promise<void> => {
      if (auth.user?.id == null || auth.user?.id === undefined || auth.user?.id === 0) { return }
      const userUrl = `/${props.endPoint}`
      const queryString = getFullQueryString()
      const searchQueryString = getSearchQueryString(props.searchData)
      const { response: userInfos } = await sendGet(userUrl + '/PagedSearch?' + queryString, {})
      const { response: totalUserInfos } = await sendGet(userUrl + '/RecordCount?' + searchQueryString, {})
      if (!ignore) {
        try {
          const data = userInfos
          setRows(data)
        } catch (e) {
          setRows([])
        }
        try {
          const data = totalUserInfos
          setTotalRecords(data)
        } catch (e) {
          setTotalRecords(0)
        }
        setLoading(false)
      }
    }
    void fetchData()
    return () => {
      ignore = true
    }
  }, (props.autoUpdateOnSearchDataChange ? [columnSorts, props.searchData, props.refreshTime, selectedPage, selectedRowsPerPage, auth.user?.id] : [columnSorts, props.refreshTime, selectedPage, selectedRowsPerPage, auth.user?.id]))

  const getFullQueryString = (): string => {
    let queryString = getSearchQueryString(props.searchData)
    let sortField = 'ID'
    let direction = 'A'
    if (columnSorts != null && columnSorts.length > 0) {
      sortField = columnSorts[0].key
      direction = columnSorts[0].order === SortOrder.ASC ? 'A' : 'D'
    }
    queryString += `&sortField=${sortField}&sortDirection=${direction}`
    queryString += `&pageNumber=${selectedPage}&pageSize=${selectedRowsPerPage}`
    if (auth.user !== null) {
      queryString += `&userId=${auth.user.id}`
    }
    if (props.isdId !== null && props.isdId !== undefined) { queryString += `&isdid=${props.isdId}` }
    return queryString
  }
  const getSearchQueryString = (searchData: any): string => {
    const searchField: string[] = []
    const equals = '%3D'
    const and = '%26'
    const keys = Reflect.ownKeys(searchData)
    if (searchData != null) {
      keys.forEach(key => {
        if (searchData[key] !== null && searchData[key] !== undefined) {
          if (searchData[key]?.toString() !== '') {
            let searchString: string = searchData[key]?.toString() ?? ''
            if ((searchData[key] instanceof Date) && !isNaN(searchData[key])) {
              searchString = searchData[key].toISOString().substring(0, 10)
            }
            const searchTerm = `${key?.toString()}${equals}${searchString}`
            searchField.push(searchTerm)
          }
        }
      })
    }
    const queryString = `queryInclude=${props.includes ?? ''}&queryStringFilter=${searchField.join(and)}`

    return queryString
  }
  const onSortChange = (column: Column, columnSorts: ColumnSort[]): void => {
    setColumnSorts(columnSorts)
  }

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number): void => {
    setSelectedPage(page)
  }

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, rowsPerPage: number): void => {
    setSelectedRowsPerPage(rowsPerPage)
  }

  return {
    rows,
    loading,
    totalRecords,
    onSortChange,
    onPageChange,
    onRowsPerPageChange
  }
}
