import { Avatar } from '@mui/material'

interface Props {
  imageKey: string
  alt?: string
  style?: React.CSSProperties
}

export default function S3Avatar (props: Props): JSX.Element {
  return (
    <Avatar
      src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${props.imageKey}`}
      alt={props.alt ?? 'image'}
      style={props.style}
      sx={{ width: '100px', height: '100px' }}
    />
  )
}
