import { Box, Chip, Typography } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { LargeCard } from '../../../Components/Cards/Large'
import S3Image from '../../../Components/S3Image'
import { ReferralTimeFrameDisplay } from '../../../core/types'
import { FormatDateValue } from '../../../core/Utilities'
import { generatePath, useNavigate } from 'react-router'
import { CAREGIVER_PORTAL_ENROLLMENT_DETAILS } from '../../Routes'

export interface InquiryView {
  referralID: number
  status: string
  childName: string
  systemServiceName: string
  sessionBlockName?: string
  timeframe: string
  customDate?: Date
  systemServiceProviderName: string
  facilityName: string
  providerLogoS3Key?: string
  informationRequested: boolean
}

interface PendingInquiryCardProps {
  inquiry: InquiryView
}

export function PendingInquiryCard (props: PendingInquiryCardProps): JSX.Element {
  const nav = useNavigate()

  const inquiry = props.inquiry

  let iconColor = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  if (inquiry.status === 'Waiting') {
    iconColor = '#FFA500 !important'
    backgroundColor = 'rgba(255, 165, 0, 0.1)'
  } else if (inquiry.status === 'Waiver') {
    iconColor = '#9D25C8 !important'
    backgroundColor = 'rgba(157, 37, 200, 0.1)'
  }

  const cardClick = (): void => {
    const detailsRoute = generatePath(CAREGIVER_PORTAL_ENROLLMENT_DETAILS, { referralId: inquiry.referralID.toString() })
    nav(detailsRoute)
  }

  let timeframe = inquiry.sessionBlockName
  if (timeframe == null) {
    timeframe = (ReferralTimeFrameDisplay as any)[inquiry.timeframe]
    if (timeframe === ReferralTimeFrameDisplay.SpecificDate) {
      timeframe = FormatDateValue(inquiry.customDate)
    }
  }

  const content =
    <Box data-testid='inquiry-card' onClick={cardClick}>
      <Box>
        {timeframe}
        <Chip
          icon={<FiberManualRecordIcon sx={{ color: iconColor }} />}
          label={inquiry.status} size="small"
          sx={{ color: iconColor, backgroundColor, ml: '10px' }}
        />
      </Box>
      <Box sx={{ display: 'flex', mt: '10px' }}>
        {inquiry.providerLogoS3Key != null && <S3Image imageKey={inquiry.providerLogoS3Key} style={{ width: '50px', height: '50px' }} />}
        <Box sx={{ ml: '20px' }}>
          {`${inquiry.systemServiceProviderName} - ${inquiry.facilityName}`}
          {inquiry.informationRequested && <Typography sx={{ color: '#D4316C', mt: '5px' }}>Information Requested</Typography>}
        </Box>
      </Box>
    </Box>

  return <LargeCard
    header={`${inquiry.childName} - ${inquiry.systemServiceName}`}
    headerClassName='card-subheader'
    content={content}
    data-testid={`service-card-${inquiry.referralID}`}
    customContentWidth={true}
    onClick={cardClick}
  />
}
