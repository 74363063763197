import { CircularProgress, IconButton, Switch } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { ServiceDetail } from './ServiceDetail'
import { Modal } from '../../Components/Modal'
import { toast } from 'react-toastify'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import documentIcon from '../../Assets/Icons/Document.png'
import { usePagination, type PaginationProps, type SearchCriteria } from '../../hooks/use-pagination'
import S3Image from '../../Components/S3Image'
import { TextFieldWithLabel } from '../../Components/TextField'

const nameColumn = new Column('name', 'Name')

const iconColumn = new Column('image', 'Icon', ColumnType.CUSTOM)
iconColumn.customCellGenerator = row => {
  const s3Key: string = row.s3Key
  if (s3Key != null) {
    return <S3Image
      imageKey={s3Key}
      alt='logo'
      data-testid={'logo-' + s3Key }
      style={{ maxWidth: '100px' }}
    />
  } else {
    return <div></div>
  }
}

const enabledColumn = new Column('switch', 'Enabled', ColumnType.CUSTOM)

const settingsColumn = new Column('settings', 'Settings', ColumnType.CUSTOM)

const columns = [nameColumn, iconColumn, enabledColumn, settingsColumn]

interface ISDService {
  id: number
  isdid: number
  serviceId: number
  enable: boolean
  description: string
  isIntake: boolean
  excludeFromPortal: boolean
}

interface ISDServiceData {
  id: number
  isdid: number
  serviceId: number
  name: string
  description: string
  isIntake: boolean
  status: string
  s3Key: string | null
  enabled: boolean
  excludeFromPortal: boolean
}

export function ServiceList (): JSX.Element {
  const isdId = useAccountId()?.id ?? 0
  const [filterDate, setFilterDate] = useState(new Date())
  const [serviceData, setServiceData] = useState<ISDServiceData[]>([])
  const [loaded, setLoaded] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [currentService, setCurrentService] = useState<ISDService | null>(null)
  const [displayModal, setDisplayModal] = useState(false)
  const [searchValue, setSearchValue] = useState(null)

  const paginationProps: PaginationProps<RowData> = {
    rows: serviceData,
    initialSize: 10,
    pageSizeSteps: [10, 25, 50, 100]
  }

  const pagination = usePagination(paginationProps)
  settingsColumn.customCellGenerator = row => {
    return <IconButton onClick={(e) => { handleClick(row) }}>
      <img src={documentIcon} data-testid="settingsButton"></img>
    </IconButton>
  }

  enabledColumn.customCellGenerator = row => {
    return <Switch
      checked={row.enabled}
      onChange={(e: any, checked: boolean) => { handleEnableChange(row, checked) }}
      data-testid={`switch-${row.id as number}`}
    />
  }

  const handleEnableChange = (data: RowData, checked: boolean): void => {
    const service = {
      id: data.id,
      enable: checked,
      isdid: data.isdid,
      serviceId: data.serviceID,
      description: data.description,
      isIntake: data.isIntake,
      excludeFromPortal: data.excludeFromPortal
    }
    setCurrentService(service)

    if (!checked) {
      setDisplayModal(true)
      // some alert thing
    } else {
      const update = {
        ISDServiceID: service?.id,
        enable: checked,
        ISDID: service?.isdid,
        ServiceID: service?.serviceId
      }

      void submitEnableChanged(update)
    }
  }

  const submitEnableChanged = async (updateData: object): Promise<void> => {
    await sendPost('/ISDServices/ToggleService', { update: updateData })
    setFilterDate(new Date())
    setDisplayModal(false)
  }

  const handleClick = (data: RowData): void => {
    setCurrentService({ id: data.id, enable: data.checked, isdid: data.isdid, serviceId: data.serviceID, description: data.description, isIntake: data.isIntake, excludeFromPortal: data.excludeFromPortal })
    setShowDetail(true)
  }

  const handleSubmit = (description: string, isIntake: boolean, excludeFromPortal: boolean): void => {
    const service = serviceData.find(s => s.id === currentService?.id)
    if (service != null) {
      service.description = description
      service.isIntake = isIntake
      service.excludeFromPortal = excludeFromPortal
    }

    setShowDetail(false)
    toast.success('Service has been updated')
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: sd } = await sendGet(`/ISDServices/GetServices?ISDID=${isdId}`, { })
      setServiceData(sd)
      setLoaded(true)
    }
    void loadData()
  }, [filterDate])

  useEffect(() => {
    const fieldArray: SearchCriteria[] = []
    if (searchValue !== null) {
      fieldArray.push({
        field: 'name',
        value: searchValue
      })
    }
    pagination.setSearchFields(fieldArray)
  }, [searchValue])

  if (!loaded) { return <CircularProgress /> }

  return <Box>
    <Modal
      onClose={() => { setDisplayModal(false) }}
      onConfirm={() => {
        const update = {
          ISDServiceID: currentService?.id,
          enable: false,
          ISDID: currentService?.isdid,
          ServiceID: currentService?.serviceId
        }
        void submitEnableChanged(update)
      }}
      open={displayModal}
      confirmationContent={<>Deactivating this service will disable it from being used for future enrollments. Any enrollments or referrals currently in process will be allowed to complete. Do you wish to continue?</>}
    />
    <TextFieldWithLabel
      label='Name'
      onChange={(e) => { setSearchValue(e.target.value) }}
      name='name'
      maxWidth='205px'
      value={searchValue}
      data-test-name-search
    />
    <DataTable
      page={pagination.page}
      name='serviceTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows}
      totalRecords={pagination.recordCount}
      loading={false}
      onSortChange={(col, sorts) => {
        pagination.onSortChange(col, sorts[0].order)
      }}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      initialColumnSorts={[new ColumnSort('name')]}
    />

    {showDetail && (
      <ServiceDetail
        open={showDetail}
        onSubmit={handleSubmit}
        onClose={() => { setShowDetail(false) }}
        isdServiceID={currentService?.id ?? 0}
        isdID={currentService?.isdid ?? 0 }
        description={currentService?.description ?? ''}
        isIntake={currentService?.isIntake ?? false}
        excludeFromPortal={currentService?.excludeFromPortal ?? false}
      />
    )}
  </Box>
}
