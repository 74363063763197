import { Grid } from '@mui/material'
import { SelectWithLabel, type SelectOption } from '../../../Components/SelectWithLabel'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { Autocomplete } from '../../../Components/AutoComplete'
import { useParams } from 'react-router'

export interface SessionSearchFields {
  facility: string
  program: string
  inactive: boolean
}

export interface SessionListSearchProps {
  search: SessionSearchFields
  setSearch: (fields: SessionSearchFields) => void
}
export function SessionListSearch (props: SessionListSearchProps): JSX.Element {
  const [facilityList, setFacilityList] = useState<Array<SelectOption<string>>>([])
  const [programs, setPrograms] = useState<Array<SelectOption<string>>>([])
  const { blockId } = useParams()

  const statusOptions = [{ id: false, name: 'Active' }, { id: true, name: 'Inactive' }]

  const handleSelect = (target: string, val: string): void => {
    const newSearch = { ...props.search, [target]: val }
    props.setSearch(newSearch)
  }
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: facilityResponse } = await sendGet(`/SessionList/GetFacilitiesBySessionblock?sessionBlockId=${blockId ?? ''}`, {})
      if (facilityResponse !== undefined && facilityResponse.length > 0) {
        setFacilityList((facilityResponse as Array<SelectOption<string>>).map(f => { return { id: f.name, name: f.name } }))
      }
      const { response: programResponse } = await sendGet('/SessionList/GetPrograms', {})
      if (programResponse !== undefined && programResponse.length > 0) {
        setPrograms(programResponse)
      }
    }
    void fetchData()
  }, [])
  return (
    <Grid data-testid="searchContainer" container flexDirection={'row'} justifyContent={'space-between'} minWidth={'800px'}>
      <Grid item sx={{ width: '38%', marginRight: '3%' }}>
        <Autocomplete
          width='100%'
          label='Facility'
          name='facility'
          onChange={(e: any, val) => { handleSelect('facility', val?.name.toString() ?? '') }}
          options={facilityList}
          value={props.search.facility}
          dataTestId="facilitySearch"
        />
      </Grid>
      <Grid item sx={{ width: '38%', marginRight: '3%' }}>
        <Autocomplete
          width='100%'
          label='Program'
          name='program'
          onChange={(e: any, val) => { handleSelect('program', val?.id.toString() ?? '') }}
          options={programs}
          value={props.search.program}
          dataTestId="programSearch"
        />
      </Grid>
      <Grid item sx={{ width: '18%' }}>
        <SelectWithLabel
          name="status"
          label="Status"
          width='100%'
          value={props.search.inactive}
          options={statusOptions}
          onChange={(e) => { handleSelect('inactive', e.target.value?.toString() ?? '') }}
        />
      </Grid>
    </Grid>
  )
}
