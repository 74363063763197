import { type ChangeEvent, useEffect, useState, useRef } from 'react'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { Box, Button, CircularProgress, Container, DialogActions, Grid, Typography, type SelectChangeEvent } from '@mui/material'
import { type postError, type lookup, type PortalLocation } from '../../../core/types'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { type CaregiverMemberDetails } from './CaregiverDisplay'
import { generatePath, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { CAREGIVER_PORTAL_MEMBERS, CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS } from '../../Routes'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { type ChildRelationship } from './ChildRelationships'
import { LocationDetails, type ValidationState } from '../InterestForm/LocationDetails'

const newCaregiver: CaregiverMemberDetails = {
  id: 0,
  isdid: 0,
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  primaryPhone: '',
  secondaryPhone: '',
  email: '',
  preferredContactMethodID: null,
  preferredContactMethod: '',
  educationLevelID: null,
  highestEducationLevel: '',
  languageID: null,
  primaryLanguage: '',
  locationID: 0,
  location: null,
  livesWithCaregiverID: null
}

const newLocation: PortalLocation = {
  address: '',
  city: '',
  state: '',
  zipCode: '',
  apartment: ''
}

export function CaregiverEdit (): JSX.Element {
  const { caregiverId } = useParams()
  const isNew = useRef(caregiverId === 'new')
  const [isLoading, setIsLoading] = useState(true)
  const [languages, setLanguages] = useState<lookup[]>([])
  const [educationLevels, setEducationLevels] = useState<lookup[]>([])
  const [caregiver, setCaregiver] = useState<CaregiverMemberDetails>(newCaregiver)
  const [contactMethods, setContactMethods] = useState<lookup[]>([])
  const [relationshipTypes, setRelationshipTypes] = useState<lookup[]>([])
  const [livesWithList, setLivesWithList] = useState<lookup[]>([])
  const [enterAddress, setEnterAddress] = useState(false)
  const [relationships, setRelationships] = useState<ChildRelationship[]>([])
  const [errors, setErrors] = useState<postError[]>([])
  const nav = useNavigate()
  const [validationState, setValidationState] = useState<ValidationState>({
    address: { isDirty: false, isValid: false },
    city: { isDirty: false, isValid: false },
    state: { isDirty: false, isValid: false },
    zipCode: { isDirty: false, isValid: false }
  })
  const [bingMapsLocation, setBingMapsLocation] = useState<PortalLocation | undefined>(undefined)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!isNew.current) {
        const { response, success } = await sendGet(`/CaregiverPortal/GetCaregiverDetails/${caregiverId ?? 0}`, {})
        if (success) {
          setCaregiver(response)
        } else {
          toast.error('Failed to load caregiver details')
        }
      } else {
        // get the caregivers and load both the how-related list and the person lives with dropdown list
        const { response: relRsp } = await sendGet('/CaregiverPortal/GetRelatedMembers', {})
        if (relRsp != null) {
          const howRelatedList: ChildRelationship[] = []
          const livesWithList: lookup[] = [{ id: -1, name: '(Other Location)' }]
          relRsp.caregivers.forEach((c: any) => {
            livesWithList.push({
              id: c.id,
              name: `${c.firstName as string} ${c.lastName as string}`
            })
          })
          relRsp.children.forEach((c: any) => {
            howRelatedList.push({
              caregiverID: parseInt(caregiverId ?? '0'),
              caregiverName: '',
              relationshipID: null,
              financiallyResponsibleFor: null,
              childName: `${c.firstName as string} ${c.lastName as string}`,
              id: null,
              childID: c.id,
              relationshipName: null
            })
          })
          setRelationships(howRelatedList)
          setLivesWithList(livesWithList)

          const { response: relations, error: err1 } = await sendGet('/Relationship/GetLookup', {})
          if (err1[0] === '') {
            setRelationshipTypes(relations)
          }
        }
      }
      const { response: langs, error: err1 } = await sendGet('/Language/GetLookup?sort=true', {})
      if (err1[0] === '') {
        setLanguages(langs)
      }
      const { response: eduLevels, error: err } = await sendGet('/EducationLevel/GetLookup?sort=true', {})
      if (err[0] === '') {
        setEducationLevels(eduLevels)
      }
      const { response: contactmethods, error: err2 } = await sendGet('/ContactMethod/GetAll', {})
      if (err2[0] === '') {
        setContactMethods(contactmethods)
      }
      setIsLoading(false)
    }
    void fetchData()
  }, [])

  // The bing maps location is set in the useBingMaps hook, but that has issues with state changes
  useEffect(() => {
    if (bingMapsLocation != null) {
      handleLocationChange(bingMapsLocation)
    }
  }, [bingMapsLocation])

  const handleLocationChange = (newLocation: PortalLocation): void => {
    setCaregiver({ ...caregiver, location: newLocation })

    const tempState = {
      ...validationState,
      address: { isDirty: true, isValid: newLocation.address !== '' },
      city: { isDirty: true, isValid: newLocation.city !== '' },
      state: { isDirty: true, isValid: newLocation.state !== '' },
      zipCode: { isDirty: true, isValid: newLocation.zipCode !== '' }
    }
    setValidationState(tempState)
  }

  const returnToDetails = (): void => {
    let route: string
    if (isNew.current) {
      route = generatePath(CAREGIVER_PORTAL_MEMBERS)
    } else {
      route = generatePath(CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS, { caregiverId: caregiverId ?? '' })
    }
    nav(route)
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setCaregiver({ ...caregiver, [e.target.name]: e.target.value })
  }

  const handleDateChange = (name: string, newDate: Date | null): void => {
    setCaregiver({ ...caregiver, [name]: newDate })
  }

  const handleSelectChange = (e: SelectChangeEvent<number | null>): void => {
    setCaregiver({ ...caregiver, [e.target.name]: e.target.value })
  }

  const handleLivesWithChange = (e: SelectChangeEvent<number | null>): void => {
    handleSelectChange(e)
    setEnterAddress(e.target.value === '-1')
  }

  const handleRelationshipValueChange = (value: any, relationship: ChildRelationship, index: number, fieldName: string): void => {
    setRelationships([...relationships.slice(0, index), { ...relationship, [fieldName]: value }, ...relationships.slice(index + 1)])
  }

  const handleSubmit = async (): Promise<void> => {
    let rsp: { success: boolean, errors: postError[], newRecordID: number | null }
    if (isNew.current) {
      const result = await sendPost('/CaregiverPortal/InsertCaregiver', caregiver)
      rsp = result.response
      if (rsp.newRecordID !== null) {
        relationships.forEach((relationship) => {
          relationship.caregiverID = rsp.newRecordID as number
        })
        if (result.success) {
          await sendPost('/CaregiverPortal/UpdateChildRelationships', relationships)
        }
      }
    } else {
      caregiver.location = null
      const result = await sendPut('/CaregiverPortal/UpdateCaregiverDetails', caregiver)
      rsp = result.response
    }

    if (rsp.success) {
      if (isNew.current) {
        const route = generatePath(CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS, { caregiverId: rsp?.newRecordID?.toString() ?? '' })
        nav(route)
      } else {
        returnToDetails()
      }
    } else {
      window.scrollTo(0, 0)
      setErrors(rsp.errors)
    }
  }

  if (isLoading) {
    return <CircularProgress />
  } else {
    const handleTextBlur = (e: any): void => {
      const tempState = {
        ...validationState,
        [e.target.name]: { isDirty: true, isValid: e.target.value !== '' }
      }
      setValidationState(tempState)
    }

    return <Container sx={{ mb: '30px' }}>
      <Box sx={{ mt: '30px', mb: '30px', display: 'flex' }}>
        <Typography variant='h5' sx={{ fontWeight: 600, ml: '5px' }} component='div'>
          {(isNew.current ? 'New Caregiver' : caregiver.firstName + ' ' + caregiver.lastName)}
        </Typography>
      </Box>

      <Box >
        {errors !== undefined && errors.length > 0 &&
          <ul>
            {errors.map((err) => (
              <li key={err.field} style={{ color: 'red', padding: '10px 0 0' }}>
                {err.error}
              </li>
            ))}
          </ul>
        }
        <Grid container columns={6} spacing={1}>
          <Grid item xs={6}>
            <TextFieldWithLabel label='First Name' required={true} data-testid="firstName" name='firstName' maxLength={50} value={caregiver?.firstName ?? ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Last Name' required={true} data-testid="lastName" name='lastName' maxLength={50} value={caregiver?.lastName ?? ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Email Address' data-testid="email" name='email' maxLength={100} value={caregiver?.email ?? ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <DatePickerWithLabel label='Date of Birth' onChange={(newValue) => { handleDateChange('dateOfBirth', newValue) }} value={caregiver?.dateOfBirth ?? null} name='dateofBirth' />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithLabel label='Phone Number' data-testid="primaryPhone" name='primaryPhone' maxLength={50} value={caregiver?.primaryPhone ?? ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={6} >
            <SelectWithLabel
              name="preferredContactMethodID"
              label="Preferred Contact Method"
              width="100%"
              value={caregiver.preferredContactMethodID ?? undefined}
              options={contactMethods}
              onChange={handleSelectChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectWithLabel
              name="educationLevelID"
              label="Highest Level of Education"
              value={caregiver.educationLevelID ?? undefined}
              includeNoneOption={true}
              options={educationLevels}
              width="100%"
              onChange={handleSelectChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectWithLabel
              name="languageID"
              label="Primary Language (if not English)"
              width="100%"
              includeNoneOption={true}
              value={caregiver.languageID ?? undefined}
              options={languages}
              onChange={handleSelectChange}
            />
          </Grid>
          {isNew.current && <><Grid item xs={6}>
            <SelectWithLabel
              name="livesWithCaregiverID"
              label="This Caregiver lives with"
              width="100%"
              includeNoneOption={false}
              value={caregiver.livesWithCaregiverID ?? 0}
              options={livesWithList}
              onChange={handleLivesWithChange}
            />
          </Grid>
            {enterAddress &&
              <LocationDetails
                location={caregiver.location ?? newLocation}
                validationState={validationState}
                allFieldsOptional={false}
                childLocation={false}
                isdId={undefined}
                onLocationChange={setBingMapsLocation}
                handleLocationFieldChange={handleLocationChange}
                validateField={handleTextBlur}
              />
            }
          </>
          }
        </Grid>
        {isNew.current &&
          <Box>
            <table style={{ marginTop: '15px' }}>
              <tr>
                <th>Child</th>
                <th>Relationship</th>
              </tr>
              {relationships.map((relationship, index) => {
                return <tr>
                  <td style={{ paddingBottom: '15px', paddingRight: '10px' }} >{relationship.childName}</td>
                  <td>
                    <SelectWithLabel
                      width='200px'
                      label=''
                      name='relationshipID'
                      includeNoneOption={true}
                      onChange={(e) => { handleRelationshipValueChange(e.target.value, relationship, index, 'relationshipID') }}
                      options={relationshipTypes}
                      value={relationship.relationshipID ?? undefined}
                    />
                  </td>
                  <td style={{ paddingBottom: '15px' }}>
                    {relationship.relationshipID !== null && <Box>
                      <CheckBoxWithLabel label='Financially Responsible For' edge='start' name='financiallyResponsibleFor'
                        value={relationship.financiallyResponsibleFor ?? false}
                        setValue={(val) => { handleRelationshipValueChange(val, relationship, index, 'financiallyResponsibleFor') }}
                      />
                    </Box>
                    }
                  </td>
                </tr>
              })}
            </table>
          </Box>
        }

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            data-testid='cancelButton'
            variant='outlined'
            color='inherit'
            onClick={returnToDetails}
          >
            Discard Changes
          </Button>

          <Button data-test-update
            data-testid='updateButton'
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Container>
  }
}
