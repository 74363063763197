import { PermissionsService } from '../services/permissions-service'
import { useAuth } from './use-auth'

export interface userPermissions {
  CaregiverPortal: boolean
  CaregiverPortal_Write: boolean
  CaregiverPortal_Enrollments: boolean
  CaregiverPortal_Enrollments_Write: boolean
  CaregiverPortal_Details: boolean
  CaregiverPortal_Details_Write: boolean
  CareNetwork: boolean
  CareNetwork_Caregiver: boolean
  CareNetwork_Caregiver_Edit: boolean
  CareNetwork_NetworkMembers: boolean
  CareNetwork_NetworkMembers_Edit: boolean
  CareNetwork_Documentation: boolean
  CareNetwork_Documentation_Edit: boolean
  CareNetwork_IncomeRequest: boolean
  CareNetwork_IncomeRequest_Edit: boolean
  CareNetwork_SensitiveDetails: boolean
  CareNetwork_SensitiveDetails_Edit: boolean
  CareNetwork_Enrollment: boolean
  CareNetwork_Enrollment_Edit: boolean
  CareNetwork_Communication: boolean
  CareNetwork_Communication_Edit: boolean
  Enrollment: boolean
  Enrollment_Edit: boolean
  Enrollment_Documentation: boolean
  Enrollment_Communication: boolean
  Enrollment_Documentation_Edit: boolean
  Enrollment_Communication_Edit: boolean
  Reports: boolean
  Users: boolean
  Users_Edit: boolean
  Impersonation: boolean
  ServiceProviders: boolean
  ServiceProviders_Edit: boolean
  ISDs: boolean
  ISDs_Edit: boolean
  ISDs_Approvals: boolean
  ISDs_Approvals_Edit: boolean
  MiECCAdmin: boolean
  CareNetwork_MergeRecords: boolean
  WaiverApprovals: boolean
}

export function usePermissions (): userPermissions {
  const { user, loading } = useAuth()
  if (!loading && (user != null && !user.inactive)) {
    const perms: userPermissions = {
      CaregiverPortal: PermissionsService.CaregiverPortal(user),
      CaregiverPortal_Write: PermissionsService.CaregiverPortal_Write(user),
      CaregiverPortal_Enrollments: PermissionsService.CaregiverPortal_Enrollments(user),
      CaregiverPortal_Enrollments_Write: PermissionsService.CaregiverPortal_Enrollments_Write(user),
      CaregiverPortal_Details: PermissionsService.CaregiverPortal_Details(user),
      CaregiverPortal_Details_Write: PermissionsService.CaregiverPortal_Details_Write(user),
      CareNetwork: PermissionsService.CareNetwork(user),
      CareNetwork_Caregiver: PermissionsService.CareNetwork_Caregiver(user),
      CareNetwork_Caregiver_Edit: PermissionsService.CareNetwork_Caregiver_Edit(user),
      CareNetwork_NetworkMembers: PermissionsService.CareNetwork_NetworkMembers(user),
      CareNetwork_NetworkMembers_Edit: PermissionsService.CareNetwork_NetworkMembers_Edit(user),
      CareNetwork_Documentation: PermissionsService.CareNetwork_Documentation(user),
      CareNetwork_Documentation_Edit: PermissionsService.CareNetwork_Documentation_Edit(user),
      CareNetwork_IncomeRequest: PermissionsService.CareNetwork_IncomeRequest(user),
      CareNetwork_IncomeRequest_Edit: PermissionsService.CareNetwork_IncomeRequest_Edit(user),
      CareNetwork_SensitiveDetails: PermissionsService.CareNetwork_SensitiveDetails(user),
      CareNetwork_SensitiveDetails_Edit: PermissionsService.CareNetwork_SensitiveDetails_Edit(user),
      CareNetwork_Enrollment: PermissionsService.CareNetwork_Enrollment(user),
      CareNetwork_Enrollment_Edit: PermissionsService.CareNetwork_Enrollment_Edit(user),
      CareNetwork_Communication: PermissionsService.CareNetwork_Communication(user),
      CareNetwork_Communication_Edit: PermissionsService.CareNetwork_Communication_Edit(user),
      Enrollment: PermissionsService.Enrollment(user),
      Enrollment_Edit: PermissionsService.Enrollment_Edit(user),
      Enrollment_Documentation: PermissionsService.Enrollment_Documentation(user),
      Enrollment_Communication: PermissionsService.Enrollment_Communication(user),
      Enrollment_Documentation_Edit: PermissionsService.Enrollment_Documentation_Edit(user),
      Enrollment_Communication_Edit: PermissionsService.Enrollment_Communication_Edit(user),
      Reports: PermissionsService.Reports(user),
      Users: PermissionsService.Users(user),
      Users_Edit: PermissionsService.Users_Edit(user),
      Impersonation: PermissionsService.Impersonation(user),
      ServiceProviders: PermissionsService.ServiceProviders(user),
      ServiceProviders_Edit: PermissionsService.ServiceProviders_Edit(user),
      ISDs: PermissionsService.ISDs(user),
      ISDs_Edit: PermissionsService.ISDs_Edit(user),
      ISDs_Approvals: PermissionsService.ISDs_Approvals(user),
      ISDs_Approvals_Edit: PermissionsService.ISDs_Approvals_Edit(user),
      MiECCAdmin: PermissionsService.MiECCAdmin(user),
      CareNetwork_MergeRecords: PermissionsService.CareNetwork_MergeRecords(user),
      WaiverApprovals: PermissionsService.WaiverApprovals(user)
    }
    return perms
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {} as userPermissions
}
