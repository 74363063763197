import { type ChangeEvent, useEffect, useState } from 'react'
import { Modal } from '../../../Components/Modal'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { sendGet } from '../../../hooks/use-fetch'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { Box, Grid, Typography } from '@mui/material'
import { CaregiverAccessType, type income, type ChildCaregiver, type lookup, type location } from '../../../core/types'
import { SelectWithLabel, type SelectOption } from '../../../Components/SelectWithLabel'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { BannerModal } from '../../../Components/Modal/BannerModal'
import { FormatDateValue } from '../../../core/Utilities'
import { CaregiverFinanceList } from '../../../Components/Cards/MultiCardList/CaregiverFinanceList'
import { LocationDetails } from '../../../Components/Cards/LocationCard/LocationDetails'
import { MapStateName } from '../../../Components/Cards/LocationCard/mapStateName'

export interface CareGiverProps {
  childID?: number
  open: boolean
  onClose: () => void
  onSubmit: (caregiverData: ChildCaregiver, match: boolean) => void
  errors?: string[] | undefined
  isLoadedRecord: boolean
  caregiver: ChildCaregiver
  childName: string
  caregiverSearchEndpoint: string
}

const accessTypeOptions: Array<SelectOption<CaregiverAccessType>> = [
  { id: CaregiverAccessType.PRIMARYCAREGIVER, name: CaregiverAccessType.PRIMARYCAREGIVER },
  { id: CaregiverAccessType.CAREGIVER, name: CaregiverAccessType.CAREGIVER },
  { id: CaregiverAccessType.CUSTODIAL_GUARDIAN, name: CaregiverAccessType.CUSTODIAL_GUARDIAN }
]

export function CaregiverAddEdit (props: CareGiverProps): JSX.Element {
  let livingWithStudent = false
  let isAccountCreated = false
  if (props.caregiver.id !== 0) {
    livingWithStudent = props.caregiver.isLivingWithStudent
    isAccountCreated = props.caregiver.createAccount ?? false
  }

  const [languages, setLanguages] = useState<lookup[]>([])
  const [educationLevels, setEducationLevels] = useState<lookup[]>([])
  const [relationships, setRelationShips] = useState<lookup[]>([])
  const [caregiver, setCaregiver] = useState<ChildCaregiver>(props.caregiver)
  const [lookupCaregiver, setLookUpCaregiver] = useState<ChildCaregiver>()
  const [isCreateAccount, setCreateAccount] = useState(isAccountCreated)
  const [isLivingWithStudent, setLivingWithStudent] = useState(livingWithStudent)
  const [contactMethods, setContactMethods] = useState<lookup[]>([])
  const [showBannerModal, setShowBannerModal] = useState(false)

  if (caregiver.isFinancialResponsible && (caregiver.incomes === undefined || caregiver.incomes.length === 0)) {
    setCaregiver({ ...caregiver, incomes: [{ id: 0, year: new Date().getFullYear(), annualIncome: 0, memberCount: 0, careNetworkID: 0, unknownIncome: false }] })
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const location: location = {
    address: caregiver.address ?? '',
    apartment: caregiver.apartment ?? '',
    city: caregiver.city ?? '',
    state: caregiver.state ?? '',
    zipCode: caregiver.zipCode ?? ''

  } as location

  const searchCaregiver = async (searchFilters: string): Promise<void> => {
    const childIdLookup = (props.childID ?? 0) !== 0 ? `childId=${props.childID ?? 0}&` : ''
    const url = `${props.caregiverSearchEndpoint}?${childIdLookup}${searchFilters}`
    const { response, error } = await sendGet(url, {})
    if (error[0] === '') {
      if (response.id > 0) {
        response.childID = props.childID
        setLookUpCaregiver(response)
        setShowBannerModal(true)
      }
    }
  }

  const getBannerModalContent = (): any => {
    if (lookupCaregiver !== undefined && lookupCaregiver.id > 0) {
      return <Box><Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>
        This information matches to the caregiver below, would you like to link them to this child?</Typography>
        <table role={'presentation'}>
          <tr><td>{lookupCaregiver?.firstName} {lookupCaregiver?.lastName}</td></tr>
          <tr><td>{FormatDateValue(lookupCaregiver?.dateOfBirth)}</td></tr>
          <tr><td>Language</td></tr>
          <tr><td>{lookupCaregiver?.phone}</td></tr>
        </table>
        <table role={'presentation'}>
          {lookupCaregiver?.childSiblings?.map((child) => {
            return <tr>
              <td>{child.firstName} {child.lastName}</td>
              <td><CheckBoxWithLabel
                label={'Link Children as Siblings'}
                value={lookupCaregiver.newChildSiblings?.findIndex(c => c === child.id) > -1}
                name='linkchildren'
                setValue={(newVal: boolean) => { handleLinkChildrenChange(newVal, child.id) }}
                edge={'start'}
              /></td>
            </tr>
          })}
        </table>
      </Box>
    } else return <Box></Box>
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/Language/GetLookup?sort=true', {})
      if (error[0] === '') {
        setLanguages(response)
      }
      const { response: eduLevels, error: err } = await sendGet('/EducationLevel/GetLookup?sort=true', {})
      if (err[0] === '') {
        setEducationLevels(eduLevels)
      }
      const { response: relations, error: err1 } = await sendGet('/Relationship/GetLookup?sort=true', {})
      if (err1[0] === '') {
        setRelationShips(relations)
      }
      const { response: contactmethods, error: err2 } = await sendGet('/ContactMethod/GetAll', {})
      if (err2[0] === '') {
        setContactMethods(contactmethods)
      }
    }
    void fetchData()
  }, [])

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>{(props.caregiver.id === 0 ? 'Add' : 'Edit')} Caregiver</span>
  </div>

  const handleClose = (): void => {
    props.onClose()
  }

  const handleLinkChildrenChange = (newVal: boolean, childId: number): void => {
    if (lookupCaregiver !== undefined) {
      let newSiblings: number[] = []
      if (lookupCaregiver.newChildSiblings !== null && lookupCaregiver.newChildSiblings !== undefined) {
        newSiblings = lookupCaregiver.newChildSiblings.slice()
      }
      const childIndex = newSiblings.findIndex(c => c === childId)
      if (newVal) {
        if (childIndex === -1) {
          newSiblings.push(childId)
        }
      } else {
        newSiblings.splice(childIndex, 1)
      }
      setLookUpCaregiver({ ...lookupCaregiver, ...caregiver, newChildSiblings: newSiblings })
    }
  }

  const handleEmailBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!props.isLoadedRecord) {
      if (e.target.value !== '') {
        void searchCaregiver(`email=${e.target.value}`)
      }
    }
  }

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!props.isLoadedRecord) {
      if (caregiver != null && caregiver?.lastName !== '' && caregiver.firstName !== '' && caregiver?.dateOfBirth !== null) {
        const date = new Date(caregiver?.dateOfBirth).toLocaleDateString()
        void searchCaregiver(`firstName=${caregiver?.firstName}&lastName=${caregiver?.lastName}&dateOfBirth=${date}`)
      }
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (caregiver !== undefined) {
      setCaregiver({ ...caregiver, [e.target.name]: e.target.value })
    }
  }

  const handleLocationChange = (e: any): void => {
    const newLoc: location = { ...location, [e.target.name]: e.target.value }
    setCaregiver({ ...caregiver, address: newLoc.address, apartment: newLoc.apartment, city: newLoc.city, state: newLoc.state, zipCode: newLoc.zipCode })
  }
  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newLoc: location = { ...location }
    newLoc.address = place.address.addressLine
    newLoc.city = place.address.locality
    newLoc.apartment = ''
    newLoc.state = MapStateName(place.address.adminDistrict)
    newLoc.zipCode = place.address.postalCode
    setCaregiver({ ...caregiver, address: newLoc.address, apartment: newLoc.apartment, city: newLoc.city, state: newLoc.state, zipCode: newLoc.zipCode })
  }

  const handleDateChange = (name: string, newDate: Date | null): void => {
    if (caregiver !== undefined && caregiver != null) {
      setCaregiver({ ...caregiver, [name]: newDate })

      if (caregiver?.lastName !== '' && caregiver.firstName !== '' && newDate !== null) {
        void searchCaregiver(`firstName=${caregiver?.firstName}&lastName=${caregiver?.lastName}&dateOfBirth=${newDate?.toDateString()}`)
      }
    }
  }

  const handleBannerConfirm = (): void => {
    if (lookupCaregiver !== undefined) {
      setCaregiver(lookupCaregiver)
      setShowBannerModal(false)
      props.onSubmit(lookupCaregiver, true)
    }
  }

  const handleSubmit = (): void => {
    if (caregiver !== undefined) {
      props.onSubmit(caregiver, props.isLoadedRecord)
    }
  }

  const firstNameError = (props.errors?.indexOf('firstName') ?? -1) >= 0
  const lastNameError = (props.errors?.indexOf('lastName') ?? -1) >= 0
  const relationshipError = (props.errors?.indexOf('relationshipID') ?? -1) >= 0

  const content =
    <Box >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextFieldWithLabel label='Email Address' data-testid="email" name='email' onBlur={handleEmailBlur} maxLength={50} value={caregiver?.email ?? ''} onChange={handleChange} className='mr-5' />
        </Grid>
        <Grid item xs={6}>
          <CheckBoxWithLabel
            label={'Create Account?'}
            value={isCreateAccount}
            name='createAccount'
            textClassName='mt-10'
            buttonClassName='mt-50'
            setValue={function (newVal: boolean): void {
              setCreateAccount(newVal)
              if (caregiver !== undefined) {
                setCaregiver({ ...caregiver, createAccount: newVal })
              }
            }}
            edge={'start'}
          />
        </Grid>

        {isCreateAccount && (
          <>
            <Grid item xs={6}>
              <SelectWithLabel
                name="accessType"
                label="Access Type"
                width="100%"
                value={caregiver?.accessType}
                options={accessTypeOptions}
                onChange={async (e) => {
                  if (caregiver !== undefined) {
                    setCaregiver({ ...caregiver, accessType: e.target.value })
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextFieldWithLabel label='First Name'
            required={true}
            showRequiredText={firstNameError}
            error={firstNameError}
            data-testid="firstName" onBlur={handleBlur}
            name='firstName' maxLength={50} value={caregiver?.firstName ?? ''} onChange={handleChange} className='mr-5'
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWithLabel label='Last Name'
            required={true}
            showRequiredText={lastNameError}
            error={lastNameError}
            data-testid="lastName" name='lastName' onBlur={handleBlur} maxLength={50}
            value={caregiver?.lastName ?? ''} onChange={handleChange} className='mr-5'
          />
        </Grid>
        <Grid item xs={6}>
          <DatePickerWithLabel label='Date of Birth' onChange={(newValue) => { handleDateChange('dateOfBirth', newValue) }} value={caregiver?.dateOfBirth ?? null} name='dateofBirth' />
        </Grid>
        <Grid item xs={6}>

          <SelectWithLabel
            name="educationlevel"
            label="Highest Level of Education"
            value={caregiver?.educationLevelID ?? ''}
            options={educationLevels}
            width="100%"
            onChange={async (e) => {
              if (caregiver !== undefined) {
                setCaregiver({ ...caregiver, educationLevelID: Number(e.target.value) })
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWithLabel label='Phone Number' data-testid="phone" name='phone' maxLength={50} value={caregiver?.phone ?? ''} onChange={handleChange} className='mr-5' />
        </Grid>
        <Grid item xs={6}>
          <SelectWithLabel
            name="language"
            label="Primary Language"
            width="100%"
            required={false}
            value={caregiver?.languageID ?? ''}
            options={languages}
            onChange={async (e) => {
              if (caregiver !== undefined) {
                setCaregiver({ ...caregiver, languageID: Number(e.target.value) })
              }
            }}
          />
        </Grid>
        <Grid item xs={6} >
          <SelectWithLabel
            name="contactMethod"
            label="Preferred Contact Method"
            width="100%"
            value={caregiver?.preferredContactMethod ?? ''}
            options={contactMethods}
            onChange={async (e) => {
              if (caregiver !== undefined) {
                setCaregiver({ ...caregiver, preferredContactMethod: Number(e.target.value) })
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectWithLabel
            name="relationshipID"
            label="Relationship to Child"
            width="100%"
            value={caregiver?.relationshipID ?? ''}
            options={relationships}
            onChange={async (e) => {
              if (caregiver !== undefined) {
                setCaregiver({ ...caregiver, relationshipID: Number(e.target.value) })
              }
            }}
            required={true}
            showRequiredText={relationshipError}
            error={relationshipError}

          />
        </Grid>
        <Grid item xs={6}>
          <CheckBoxWithLabel
            label={`Financially Responsible for ${props.childName}`}
            dataTestId='financiallyResponsible'
            value={caregiver?.isFinancialResponsible ?? false}
            name='financiallyResponsible'
            setValue={(newVal: boolean) => {
              setCaregiver({ ...caregiver, isFinancialResponsible: newVal })
            }}
            edge={'start'}
          />
        </Grid>
        {caregiver.isFinancialResponsible && (
          <Grid item xs={12}>
            <CaregiverFinanceList
              incomes={caregiver.incomes ?? []}
              setIncomes={(years: income[]) => {
                setCaregiver({ ...caregiver, incomes: years })
              }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <CheckBoxWithLabel
            label={`Lives With ${props.childName}`}
            dataTestId='livesWith'
            value={caregiver?.isLivingWithStudent ?? false} name='LivesWithStudent'
            setValue={function (newVal: boolean): void {
              setLivingWithStudent(newVal)
              if (caregiver !== undefined) {
                setCaregiver({ ...caregiver, isLivingWithStudent: newVal })
              }
            }}
            edge={'start'}
          />
        </Grid>

        {!isLivingWithStudent && <Grid item xs={12}>
          <LocationDetails
            editData={location}
            onPlaceSelected={handlePlaceSelected}
            onChange={handleLocationChange}
            hidePrimary={true}
            showHomelessOption={false}
            key={caregiver.id ?? 0}
            errors={props.errors}
          />
        </Grid>}
      </Grid>
      <Box>
        <BannerModal
          open={showBannerModal}
          hideXtoClose={true}
          content={getBannerModalContent()}
          onClose={() => { setShowBannerModal(false) }}
          onConfirm={handleBannerConfirm}
          title='Caregiver Found'
          confirmButtonText='Yes, Link Them'
          cancelButtonText='No, Proceed with Entering'
        />
      </Box>
    </Box>

  return <Modal
    open={props.open}
    titleContent={titleContent}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
