export const LANDING_PATH = '/'
export const UNAUTHORIZED = '/Unauthorized'
export const USER_MANAGEMENT = '/UserManagement'
export const APPLICATION_SETTINGS = '/ApplicationSettings/'
export const CAREGIVER_PORTAL_DASHBOARD = '/CaregiverPortal/Dashboard/'
export const CAREGIVER_PORTAL_MEMBERS = '/CaregiverPortal/Members/'
export const CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS = '/CaregiverPortal/Members/Child/:childId'
export const CAREGIVER_PORTAL_MEMBERS_EDIT_CHILD = '/CaregiverPortal/Members/ChildEdit/:childId'
export const CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS = '/CaregiverPortal/Members/Caregiver/:caregiverId'
export const CAREGIVER_PORTAL_MEMBERS_EDIT_CAREGIVER = '/CaregiverPortal/Members/CaregiverEdit/:caregiverId'
export const CAREGIVER_PORTAL_ENROLLMENT_DETAILS = '/CaregiverPortal/EnrollmentDetails/:referralId'
export const CAREGIVER_PORTAL_SERVICE_FINDER = '/CaregiverPortal/ServiceFInder/'
export const CAREGIVER_PORTAL = '/CaregiverPortal/:isdId/:isdServiceProviderId?'
export const CAREGIVER_ENROLLMENTS = '/CaregiverPortal/:isdId/Enrollments/:isdServiceProviderId?'
export const CAREGIVER_PORTAL_SUBMITTED = '/CaregiverPortal/:isdId/Submitted/'
export const CARENETWORK_MANAGEMENT = '/CareNetworkManagement/'
export const CARENETWORK_MANAGEMENT_DETAILS = '/CareNetworkManagement/Details/:id'
export const CAREGIVER_MANAGEMENT = '/CareNetworkManagement/Caregiver/'
export const CARENETWORK_MEMBERS = '/CareNetworkManagement/NetworkMembers/'
export const DOCUMENTATION = '/CareNetworkManagement/Documentation/'
export const INCOME_REQUEST = '/CareNetworkManagement/IncomeRequest/'
export const SENSITIVE_DETAILS = '/CareNetworkManagement/SensitiveDetails/'
export const ENROLLMENT_DETAILS = '/CareNetworkManagement/EnrollmentDetails/'
export const COMMUNICATION = '/CareNetworkManagement/Communication/'
export const SERVICE_PROVIDER_ADMIN = '/ServiceProviderAdministration/'
export const SERVICE_PROVIDER_ADMIN_SERVICES = '/ServiceProviderAdministration/Services/'
export const SERVICE_PROVIDER_ADMIN_SESSION_BLOCK = '/ServiceProviderAdministration/Services/:serviceId/SessionBlock/'
export const SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION = '/ServiceProviderAdministration/Services/:serviceId/SessionBlock/:blockId'
export const SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS = '/ServiceProviderAdministration/Services/:serviceId/SessionBlock/:blockId/Details/:sessionId'
export const SERVICE_PROVIDER_ADMIN_SETTINGS = '/ServiceProviderAdministration/Settings/'
export const SERVICE_PROVIDER_ADMIN_DETAILS = '/ServiceProviderAdministration/Settings/Details/'
export const SERVICE_PROVIDER_ADMIN_STAFF = '/ServiceProviderAdministration/Staff/'
export const SERVICE_PROVIDER_ADMIN_FACILITIES = '/ServiceProviderAdministration/Settings/Facilities/'
export const SERVICE_PROVIDER_ADMIN_FACILITIES_DETAILS = '/ServiceProviderAdministration/Settings/Facilities/:id'
export const FUNDING_MANAGEMENT = '/FundingManagement/'
export const ENROLLMENT_MANAGEMENT = '/EnrollmentManagement/'
export const ACCESS_REQUESTS = '/AccessRequest/'
export const ENROLLMENT_MANAGEMENT_DETAILS = '/EnrollmentManagement/:serviceId'
export const REFERRAL_MANAGEMENT = '/ReferralManagement/'
export const REFERRALS = '/ReferralManagement/:serviceId/'
export const ISD_SETTINGS = '/ISD/Settings/'
export const FUNDING_ALLOCATION = '/ISD/FundingAllocation/'
export const SERVICE_PROVIDER_MANAGEMENT = '/ISD/ServiceProviderManagement/'
export const INCOME_APPROVAL = '/ISD/IncomeApproval/'
export const LANGUAGE_MANAGEMENT = '/LanguageManagement/'
export const ISD_MANAGEMENT = '/ISDManagement/'
export const REPORTS = '/Reports/'
export const CHANGE_PASSWORD = '/ChangePassword/'
export const MY_PROFILE = '/MyProfile/'
export const SERVICE_PROVIDER_HOME = '/ServiceProvider/'
export const WAIVER_APPROVALS = '/WaiverApprovals/'
