import { Box, Grid, Typography } from '@mui/material'
import { type SelectOption, SelectWithLabel } from '../../Components/SelectWithLabel'
import { TextAreaWithLabel } from '../../Components/TextArea'
import { type DenyReferralRequest } from '../../core/types'

export interface ReferralDenyEditProps {
  denyRequest: DenyReferralRequest
  onChange: (denyRequest: DenyReferralRequest) => void
  denyReasons: Array<SelectOption<number>>
  serviceProviders: Array<SelectOption<number>>
  denialErrors: string[]
}

export function ReferralDenyEdit (props: ReferralDenyEditProps): JSX.Element {
  const handleChange = async (e: any): Promise<void> => {
    const newVal = { ...props.denyRequest, [e.target.name]: e.target.value }
    props.onChange(newVal)
  }

  return <Box sx={{ p: '0 24px' }}><Typography data-testid="ReferralDenyHeader" sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>
    Please confirm you would like to deny this child access to your services</Typography>
    <Grid container alignItems={'center'} width={'100%'} >
      <Grid item width={'100%'}>
        <SelectWithLabel
          name="denyReasonId"
          label="Reason"
          width="100%"
          value={props.denyRequest.denyReasonId ?? 0}
          options={props.denyReasons}
          onChange={handleChange}
          required={true}
          showRequiredText={props.denialErrors.includes('denyReasonId')}
          error={props.denialErrors.includes('denyReasonId')}
        />
      </Grid>
      <Grid item width={'100%'}>
        <TextAreaWithLabel
          name="note"
          label="Notes"
          data-testid="notes"
          value={props.denyRequest.note}
          onChange={handleChange}
        />
      </Grid>
      {props.denyRequest.manualReferEnabled === true && <Grid item width={'100%'}>
        <SelectWithLabel
          name="serviceProviderId"
          label="Refer to"
          width="100%"
          value={props.denyRequest.serviceProviderId ?? 0}
          options={props.serviceProviders}
          onChange={handleChange}
          required={false}
          showRequiredText={props.denialErrors.includes('serviceProviderId')}
          error={props.denialErrors.includes('serviceProviderId')}
        />
      </Grid>}
    </Grid>
  </Box>
}
