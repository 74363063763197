import { Box, Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Modal } from '../../Components/Modal'
import { SelectWithLabel, type SelectOption } from '../../Components/SelectWithLabel'
import { type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { TextFieldWithLabel } from '../../Components/TextField'
import { Role, type userAuthorizedFunction } from '../../core/types'
import { sendGet } from '../../hooks/use-fetch'
import { HierarchyCheckList, type functionType } from '../../Components/CheckList/HierarchyCheckList'
import { Multiselect } from '../../Components/MultiSelect/MultiSelect'
import { LabelWithToolTip } from '../../Components/Label/Index'
import { type AuthType } from '../../hooks/use-auth'
import FilePicker from '../../Components/FilePicker'
import S3Image from '../../Components/S3Image'
import SimpleAccordion from '../../Components/Accordian'

export interface UserManagementDetailProps {
  item: RowData
  open: boolean
  onClose: () => void
  onSubmit: (newRowData: RowData, file: File | undefined) => void
  auth: AuthType
  impersonation: boolean
  isdOptions: Array<{ id: number, name: string }>
  spOptions: Array<{ id: number, name: string }>
  ipOptions: Array<{ id: number, name: string }>
}

export function UserManagementDetail (props: UserManagementDetailProps): JSX.Element {
  const [user, setUser] = useState(props.item)
  const [functionList, setFunctionList] = useState<functionType[]>([])
  const [checkedValues, setCheckedValues] = useState<Array<{ id: number | null, name: string }>>([]) // id is the id of the ISD or Service Provider or null if there is none, string is the Role name
  const [facilityList, setFacilityList] = useState<Array<{ id: number, name: string }>>([])
  const [facilitylessPermissons, setFacilitylessPermissions] = useState(false)
  const [file, setFile] = useState<File>()

  let isdCount = 1
  const isdCollection: Array<{ id: number }> = props.item.isDs
  const initialIsds: Array<{ id: number, value: number | null }> = isdCollection.map(i => { return { id: isdCount++, value: i.id } })
  if (isdCount === 1) { initialIsds.push({ id: isdCount++, value: null }) }

  const [isdFieldList, setISDFieldList] = useState<Array<{ id: number, value: number | null }>>(initialIsds)
  const [totalISD, setTotalISD] = useState(isdCount)

  let spCount = 1
  const spCollection: Array<{ id: number }> = props.item.serviceProviders
  const initialSps: Array<{ id: number, value: number | null }> = spCollection.map(i => { return { id: spCount++, value: i.id } })
  if (spCount === 1) { initialSps.push({ id: spCount++, value: null }) }

  const [totalSP, setTotalSP] = useState(spCount)
  const [serviceProviderFieldList, setServiceProviderFieldList] = useState<Array<{ id: number, value: number | null }>>(initialSps)

  const [currentRole, setCurrentRole] = useState<Role>(props.item.securityLevel)

  useEffect(() => {
    const currentFacilityList = Array<{ id: number, name: string }>()
    let noFacility = false
    if ((user.securityLevel === Role.SERVICE_PROVIDER_USER || user.securityLevel === Role.INTAKE_USER) && serviceProviderFieldList != null) {
      noFacility = false
      const facilityList = serviceProviderFieldList.filter(s => s.value != null).map(p => { return { id: p.value ?? 0, name: props.spOptions.find(sp => sp.id === p.value)?.name ?? '' } })
      facilityList.forEach(f => {
        // exclude duplicates
        if (currentFacilityList.find(c => c.id === f.id) === undefined) {
          currentFacilityList.push(f)
        }
      })
    } else if (user.securityLevel === Role.ISD_USER && isdFieldList != null) {
      noFacility = false
      const isdList = isdFieldList.filter(i => i.value != null).map(i => { return { id: i.value ?? 0, name: props.isdOptions.find(isd => isd.id === i.value)?.name ?? '' } })
      isdList.forEach(f => {
        // exclude duplicates
        if (currentFacilityList.find(c => c.id === f.id) === undefined) {
          currentFacilityList.push(f)
        }
      })
    } else {
      noFacility = true
    }

    function populateImplicit (funcs: functionType[]): string[] {
      let newStrings: string[] = []
      funcs.map(f => {
        if (f.readImplicit && f.readPermissionName != null) { newStrings.push(f.readPermissionName ?? '') }
        if (f.readImplicit && f.writePermissionName != null) { newStrings.push(f.writePermissionName ?? '') }

        if (f.subPermissions != null && f.subPermissions.length > 0) { newStrings = [...newStrings, ...populateImplicit(f.subPermissions)] }
        return ''
      })
      return newStrings
    }

    async function getAllFunctions (): Promise<void> {
      const request = await sendGet(`/User/AllUserFunctions?userSecurityLevel=${user.securityLevel as string}`, {})
      const functions: functionType[] = request.response
      if (functions === null) { return }
      const implicitFunctions: string[] = populateImplicit(functions)
      const values: Array<{ id: number | null, name: string }> = []

      if (props.item.securityLevel === user.securityLevel) {
        const currentUserFuncs = props.item.userAuthorizedFunctions as userAuthorizedFunction[]
        currentUserFuncs.forEach(f => {
          const facilityID = f.systemServiceProviderID ?? f.isdid
          values.push({ id: facilityID, name: f.authorizedFunction })
        })
      }
      setFunctionList(functions)

      if (noFacility) {
        implicitFunctions.forEach(c => {
          if (values.find(i => i.name === c) === undefined) {
            values.push({ id: null, name: c })
          }
        })
      } else {
        currentFacilityList.forEach(f => {
          implicitFunctions.forEach(c => {
            if (values.find(i => i.name === c && i.id === f.id) === undefined) {
              values.push({ id: f.id, name: c })
            }
          })
        })
      }
      setCheckedValues(values)
    }
    void getAllFunctions()

    setFacilitylessPermissions(noFacility)
    setFacilityList(currentFacilityList)
  }, [isdFieldList, serviceProviderFieldList, currentRole])

  const handleChange = (e: any): void => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const handleRoleChange = (e: any): void => {
    setUser({ ...user, [e.target.name]: e.target.value })
    setCurrentRole(e.target.value)
  }

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>User Details</span>
    {user.id !== 0 && user.inactive !== true && props.auth.user?.id !== user.id && props.auth.user?.isImpersonating === false && props.impersonation &&
      <Button variant="contained" onClick={async () => { await props.auth.impersonate(user.id) }} data-testid='impersonate'>Impersonate</Button>}
  </div>
  const roleOptions: Array<SelectOption<Role>> = [
    { id: Role.INTAKE_USER, name: Role.INTAKE_USER },
    { id: Role.CARE_GIVER, name: Role.CARE_GIVER },
    { id: Role.SERVICE_PROVIDER_USER, name: Role.SERVICE_PROVIDER_USER },
    { id: Role.ISD_USER, name: Role.ISD_USER },
    { id: Role.MIECC_ADMIN, name: Role.MIECC_ADMIN }
  ]
  const statusOptions: Array<{ id: string, name: string }> = [
    { id: 'false', name: 'Active' },
    { id: 'true', name: 'Deactivated' }
  ]

  const setDefaultISDID = (value: number | null): void => {
    setUser({ ...user, defaultISDID: value })
  }

  const setDefaultServiceProviderID = (value: number | null): void => {
    setUser({ ...user, defaultServiceProviderID: value })
  }

  const handleClose = (): void => {
    props.onClose()
  }

  const handleSubmit = (): void => {
    const spList = serviceProviderFieldList.filter(s => s.value != null).map(s => { return { ServiceProviderID: s.value } })
    const isdList = isdFieldList.filter(s => s.value != null).map(s => { return { IsdID: s.value } })
    const finalValue = { ...user, userAuthorizedFunctions: checkedValues, isDs: isdList, serviceProviders: spList }
    props.onSubmit({ ...finalValue }, file)
  }

  function setPermission (id: number | null, permission: string[]): void {
    // set the permission for the facility defined by id
    let items = [...checkedValues]

    permission.forEach(p => {
      if (items.find(f => f.name === p && ((f.id == null && id == null) || f.id === id)) === undefined) {
        items.push({ id, name: p })
      }
    })

    items.forEach(i => {
      if ((id === null || i.id === id) && permission.find(p => p === i.name && ((i.id == null && id == null) || i.id === id)) === undefined) {
        items = items.filter(f => f.name !== i.name || f.id !== i.id)
      }
    })

    setCheckedValues(items)
  }

  function PermissionContent (id: number | null): JSX.Element {
    const permissionList: string[] = checkedValues.filter(f => (id == null && f.id == null) || f.id === id).map(f => f.name)
    return <Box sx={{ pt: '24px', width: '60%' }}>
      <HierarchyCheckList
        headers={[
          <LabelWithToolTip
            labelText={'Authorized Functions'}
            sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
          />,
          <LabelWithToolTip
            labelText={'Read'}
            sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
          />,
          <LabelWithToolTip
            labelText={'Read/Write'}
            sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
          />
        ]}
        optionsList={functionList}
        selectedValues={permissionList}
        setSelectedValues={(newValue: string[]) => { setPermission(id, newValue) }}
      />
    </Box>
  }

  const content =
    <Box>
      <Grid container columns={12} spacing={1}>
        <Grid item sm={12} md={6}>
          <TextFieldWithLabel
            name="firstName"
            label="First Name"
            value={user.firstName}
            onChange={handleChange}
            data-testid='firstName'
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextFieldWithLabel
            name="lastName"
            label="Last Name"
            value={user.lastName}
            onChange={handleChange}
            data-testid='lastName'
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextFieldWithLabel
            name="email"
            label="Email"
            disabled={props.item.id !== 0}
            value={user.email}
            onChange={handleChange}
            data-testid='email'
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextFieldWithLabel
            name="phoneNumber"
            label="Phone Number"
            value={user.phoneNumber ?? ''}
            onChange={handleChange}
            data-testid='phoneNumber'
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <SelectWithLabel
            name="inactive"
            label="Status"
            width="100%"
            value={user.inactive.toString()}
            options={statusOptions}
            onChange={(e) => {
              setUser({ ...user, inactive: e.target.value === 'true' })
            }}
          />
        </Grid>

        <Grid item sm={12} md={12}>
          <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
            {file != null && <Box>
              <Typography>Uploading {file.name}</Typography>
            </Box>}
            <FilePicker
              label='Profile Pic'
              name='file'
              onSelect={(e: any) => {
                setFile(e.target.files[0])
              }}
            />
            {user.imageKey != null && user.imageKey !== '' && (
              <S3Image imageKey={user.imageKey} />
            )}
          </Box>
        </Grid>

        <Grid item sm={12} md={6}>
          <SelectWithLabel
            name="securityLevel"
            label="Role"
            width="100%"
            value={user.securityLevel}
            options={roleOptions}
            onChange={handleRoleChange}
            data-testid='role'
          />
        </Grid>
      </Grid>

      <div className="d-flex f-justify-content-space-between">
        {currentRole === Role.ISD_USER && <div className="width-49">
          <Multiselect
            name='ISD'
            itemIndex={totalISD}
            itemList={isdFieldList}
            label='ISD'
            options={props.isdOptions}
            setItemIndex={setTotalISD}
            setItemList={setISDFieldList}
            hasDefaultOption={true}
            defaultValue={props.item.defaultISDID}
            setDefaultValue={setDefaultISDID}
          />
        </div>
        }
        {currentRole === Role.SERVICE_PROVIDER_USER && <div className="width-49">
          <Multiselect
            name='SP'
            itemIndex={totalSP}
            itemList={serviceProviderFieldList}
            label='Service Provider'
            options={props.spOptions}
            setItemIndex={setTotalSP}
            setItemList={setServiceProviderFieldList}
            hasDefaultOption={true}
            defaultValue={props.item.defaultServiceProviderID}
            setDefaultValue={setDefaultServiceProviderID}
          />
        </div>
        }
        {currentRole === Role.INTAKE_USER && <div className="width-49">
          <Multiselect
            name='SP'
            itemIndex={totalSP}
            itemList={serviceProviderFieldList}
            label='Intake Provider'
            options={props.ipOptions}
            setItemIndex={setTotalSP}
            setItemList={setServiceProviderFieldList}
            hasDefaultOption={true}
            defaultValue={props.item.defaultServiceProviderID}
            setDefaultValue={setDefaultServiceProviderID}
          />
        </div>
        }
      </div>
      {facilitylessPermissons
        ? PermissionContent(null)
        : facilityList.map((facility, index) => {
          return <SimpleAccordion
            key={index}
            title={`${facility.name} Permissions`}
            icon={<div></div>}
            width='100%'
            className='short-accordion'
            expanded={index === 0}
            body={PermissionContent(facility.id)}
          />
        })
      }
    </Box>

  return <Modal
    open={props.open}
    titleContent={titleContent}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
