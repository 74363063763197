import { Box, Breadcrumbs, Button, CircularProgress, IconButton, Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import CardIcon from '@mui/icons-material/ArticleOutlined'
import EllipsisIcon from '@mui/icons-material/MoreHoriz'
import TableIcon from '@mui/icons-material/TableRowsOutlined'
import { type sessionBlock } from '../../../core/types'
import { sendDelete, sendGet } from '../../../hooks/use-fetch'
import _ from 'lodash'
import { LargeCard } from '../../../Components/Cards/Large'
import { FormatDateAsTimeAgo } from '../../../core/Utilities'
import './SessionBlock.css'
import { SessionBlockDetailsModal } from './SessionBlockDetailsModal'
import DataTable from '../../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { Dropdown } from '../../../Components/Dropdown'
import { generatePath, useNavigate, useParams } from 'react-router'
import { SERVICE_PROVIDER_ADMIN_SERVICES, SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION } from '../../Routes'
import { Modal } from '../../../Components/Modal'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'

interface SessionBlockView {
  id: number
  name: string
  sessionCount: number
  sessionCountText: string
  startDate: Date | null
  endDate: Date | null
  dateRangeId: number | null
  serviceId: number
  fullDateRange: string
  lastModified: Date
  formattedLastModified: string
  isTimeSpanCustom: boolean
  selectedProgramIds: number[]
  inactive: boolean
}

export function SessionBlockList (): JSX.Element {
  const id = useAccountId()?.id ?? 0
  const [sessionBlocks, setSessionBlocks] = useState<SessionBlockView[]>([])
  const [allSessionBlocks, setAllSessionBlocks] = useState<SessionBlockView[]>([])
  const [loading, setLoading] = useState(true)
  const [isCardModeSelected, setIsCardModeSelected] = useState(true)
  const [isShowingEditModal, setIsShowingEditModal] = useState(false)
  const [selectedSessionBlock, setSelectedSessionBlock] = useState<SessionBlockView | null>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [service, setService] = useState({ id: 0, name: '' })
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const { serviceId } = useParams()
  const [hasNoFacilities, setHasNoFacilities] = useState(false)
  const [showInactive, setShowInactive] = useState<boolean>(false)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp } = await sendGet(`/ServiceProviderDetails/GetProgramsUsage?id=${id}`, {})
      if (rsp.length === 0) {
        setHasNoFacilities(true)
        toast.error('Your settings do not contain any facilities with rooms that have program selections made.  Please finish setting up your facilities to continue')
      }
    }
    void loadData()
  }, [])

  const nav = useNavigate()

  const paginationProps: PaginationProps<RowData> = {
    rows: sessionBlocks
  }
  const pagination = usePagination(paginationProps)

  const handleModeChange = (isCardMode: boolean): void => {
    if (isCardMode) {
      setIsCardModeSelected(true)
    } else {
      setIsCardModeSelected(false)
    }
  }

  const createSessionBlock = (): void => {
    setSelectedSessionBlock(null)
    setIsShowingEditModal(true)
  }

  const deleteConfirm = (): void => {
    if (selectedSessionBlock?.sessionCount === 0) {
      setIsDeleteModalOpen(true)
    } else {
      setConfirmationMessage('You cannot delete this session block. There are sessions attached to it.')
      setIsConfirmationModalOpen(true)
    }
  }

  const deleteSessionBlock = async (): Promise<void> => {
    setIsDeleteModalOpen(false)
    const { response, error } = await sendDelete(`/SessionBlock/Delete/${selectedSessionBlock?.id ?? ''}`)
    if (error[0] === '' && response === null) {
      const sessionBlocksCopy = [...sessionBlocks]
      const index = sessionBlocksCopy.findIndex(s => s.id === selectedSessionBlock?.id ?? '')
      sessionBlocksCopy.splice(index, 1)
      setSessionBlocks(sessionBlocksCopy)
      toast.success('The session block was removed from the system')
    } else {
      error.forEach(e => {
        if (e !== '') {
          toast.error(e)
        }
      })
      if (response != null) {
        toast.error(response)
      }
    }
  }

  const handleStatusChange = (e: any): void => {
    const inactive = e.target.value
    setShowInactive(inactive === 'true')
    if (inactive === 'true') {
      setSessionBlocks(allSessionBlocks.filter(s => s.inactive))
    } else {
      setSessionBlocks(allSessionBlocks.filter(s => !s.inactive))
    }
  }

  const statusOptions = [{ id: false, name: 'Active' }, { id: true, name: 'Inactive' }]

  const editSessionBlock = (): void => {
    setIsShowingEditModal(true)
  }

  const handleSubmit = async (): Promise<void> => {
    setIsShowingEditModal(false)
    await loadData()
  }

  const loadData = async (): Promise<void> => {
    if (serviceId === undefined || id === 0) {
      return
    }

    const { response: sessionBlockRsp } = await sendGet(`/SessionBlock/GetAll?serviceId=${serviceId}&spId=${id}`, {})
    if (sessionBlockRsp !== null) {
      const sortedSessionBlocks: sessionBlock[] = _.sortBy(sessionBlockRsp, r => r.name)
      const sessionBlockViews = sortedSessionBlocks.map(s => {
        const startDate = s.dateRange?.startDate ?? s.startDate
        const endDate = s.dateRange?.endDate ?? s.endDate
        const startDateString = startDate != null ? new Date(startDate).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }) : ''
        const endDateString = endDate != null ? new Date(endDate).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }) : ''
        let fullDateRange = `${startDateString} - ${endDateString}`
        if (startDateString === '' && endDateString === '') {
          fullDateRange = ''
        }

        const sessionCountText = `${s.sessionCount.toString()} session${s.sessionCount === 1 ? '' : 's'}`

        return {
          id: s.id,
          name: s.name,
          sessionCount: s.sessionCount,
          sessionCountText,
          startDate,
          endDate,
          dateRangeId: s.dateRange?.id ?? null,
          serviceId: parseInt(serviceId),
          fullDateRange,
          lastModified: s.lastModified,
          formattedLastModified: FormatDateAsTimeAgo(s.lastModified),
          isTimeSpanCustom: s.dateRange == null,
          selectedProgramIds: s.selectedProgramIds,
          inactive: s.inactive
        }
      })
      setSessionBlocks(sessionBlockViews.filter(s => s.inactive === showInactive))
      setAllSessionBlocks(sessionBlockViews)
    }
    const { response: serviceRsp } = await sendGet(`/ServiceProviderDetails/GetService?spID=${id}&systemServiceId=${serviceId}`, {})
    if (serviceRsp !== null) {
      setService(serviceRsp)
    }
    setLoading(false)
  }

  useEffect(() => {
    void loadData()
  }, [id, serviceId])

  if (loading) return <CircularProgress />

  const sessionPath = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION, { serviceId: serviceId ?? '', blockId: selectedSessionBlock?.id.toString() ?? '' })
  const dropdownOptions = [
    { name: 'Edit', onClick: editSessionBlock },
    { name: 'Delete', onClick: deleteConfirm },
    { name: 'View Sessions', onClick: () => { nav(sessionPath) } }
  ]

  const cardContent =
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {sessionBlocks.map(s => {
        const content =
          <Box className='session-block-card-content' data-testid='session-block-card'>
            <Typography sx={{ fontWeight: 'bold' }} className='mt-10'>{s.fullDateRange}</Typography>
            <span>{s.sessionCountText}</span>
            <span>{s.formattedLastModified}</span>

          </Box>

        const actions = <Box className='d-flex f-justify-content-space-between' data-testid='cardfooter'>
          <Dropdown
            data-testid='session-block-ellipsis-menu'
            selectComponent={<EllipsisIcon />}
            options={dropdownOptions}
            onOpenMenu={() => { setSelectedSessionBlock(s) }}
          />
        </Box>

        return <LargeCard
          key={s.id}
          header={s.name}
          content={content}
          data-testid={'session-block-card-' + s.name}
          className='session-block-card'
          contentClassName='session-block-card-content-wrapper'
          headerClassName='session-block-card-header'
          actions={actions}
          onClick={() => {
            const path = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION, { serviceId: serviceId ?? '', blockId: s.id.toString() ?? '' })
            nav(path)
          }}
        />
      })}
    </Box>

  const ellipsisMenuColumn = new Column('ellipsisMenu', '', ColumnType.CUSTOM)
  ellipsisMenuColumn.customCellGenerator = row => {
    return <Dropdown
      selectComponent={<EllipsisIcon />}
      options={dropdownOptions}
      onOpenMenu={() => { setSelectedSessionBlock(row as SessionBlockView) }}
    />
  }
  const nameColumn = new Column('name', 'Block Name')
  const startDateColumn = new Column('startDate', 'Start Date', ColumnType.CUSTOM)
  startDateColumn.customCellGenerator = row => {
    return row.startDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
  }

  const endDateColumn = new Column('endDate', 'End Date', ColumnType.CUSTOM)
  endDateColumn.customCellGenerator = row => {
    return row.endDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
  }

  const timeSpanColumn = new Column('isTimeSpanCustom', 'Timespan Used', ColumnType.CUSTOM)
  timeSpanColumn.customCellGenerator = row => {
    return row.isTimeSpanCustom as boolean ? 'Custom' : row.fullDateRange
  }

  const lastModifiedColumn = new Column('lastModified', 'Last Updated', ColumnType.CUSTOM)
  lastModifiedColumn.customCellGenerator = row => {
    return row.formattedLastModified
  }
  const columns = [ellipsisMenuColumn, nameColumn, startDateColumn, endDateColumn, timeSpanColumn, lastModifiedColumn]

  const tableContent = <DataTable
    data-testid='session-blocks-table'
    name='sessionBlocksTable'
    hasCheckbox={false}
    columns={columns}
    rows={pagination.internalRows ?? []}
    totalRecords={pagination.recordCount}
    loading={loading}
    hoverShading={false}
    rowClickable={false}
    page={pagination.page}

    initialColumnSorts={[new ColumnSort('name')]}
    onSortChange={(col, sorts) => {
      pagination.onSortChange(col, sorts[0].order)
    }}
    onPageChange={pagination.handleChangePage}
    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
  />

  return (
    <>
      <Typography variant="h3" data-testid='blockListHeader'>{service.name} Session Blocks</Typography>
      <Breadcrumbs maxItems={1} aria-label="breadcrumb">
        <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(SERVICE_PROVIDER_ADMIN_SERVICES) }} >
          Services
        </Link>
      </Breadcrumbs>
      <Box className='d-flex f-justify-content-space-between'>
        <Box>
          <SelectWithLabel
            name="status"
            label="Status"
            width='135px'
            value={showInactive}
            options={statusOptions}
            onChange={handleStatusChange}
          />
        </Box>
        <Box className='d-flex f-direction-column'>
          <Box>
            <IconButton onClick={() => { handleModeChange(true) }} className={'selectable-icon-button ' + (isCardModeSelected ? 'selected-icon' : '')}>
              <CardIcon className='selectable-icon' />
            </IconButton>
            <IconButton onClick={() => { handleModeChange(false) }} className={'selectable-icon-button ' + (!isCardModeSelected ? 'selected-icon' : '')}>
              <TableIcon className='selectable-icon' />
            </IconButton>
          </Box>
          <Button disabled={hasNoFacilities} variant='contained' color='secondary' className='mt-10' data-testid='add-session-block-button' onClick={createSessionBlock}>Add Session Block</Button>
        </Box>
      </Box>
      {isCardModeSelected && cardContent}
      {!isCardModeSelected && tableContent}

      <SessionBlockDetailsModal
        item={selectedSessionBlock}
        serviceName={service.name}
        serviceId={service.id}
        spId={id}
        onClose={() => { setIsShowingEditModal(false) }}
        onSubmit={handleSubmit}
        open={isShowingEditModal}
      />

      <Modal
        open={isDeleteModalOpen}
        onClose={() => { setIsDeleteModalOpen(false) }}
        title='Delete Session Block'
        confirmationContent='This will permanently delete this session block. Are you sure you want to continue?'
        cancelButtonText='No'
        confirmButtonText='Yes'
        onConfirm={deleteSessionBlock}
      />

      <Modal
        open={isConfirmationModalOpen}
        onClose={() => { setIsConfirmationModalOpen(false) }}
        title='Confirm'
        confirmationContent={confirmationMessage}
        confirmButtonText='Ok'
        hideCancelButton={true}
        onConfirm={() => { setIsConfirmationModalOpen(false) }}
      />
    </>
  )
}
