import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useState, useEffect } from 'react'
import { type program, type enrollment } from '../../../core/types'
import { sendGet } from '../../../hooks/use-fetch'
import { Column, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import DataTable from '../../../Components/Table/DataTable'
import { Autocomplete } from '../../../Components/AutoComplete'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { FormatDateValue } from '../../../core/Utilities'
import { REFERRAL_MANAGEMENT } from '../../../Pages/Routes'
import { EnrollmentDetails } from '../../../Pages/ServiceProvider/Enrollments/EnrollmentDetails'
import { useNavigate } from 'react-router'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export interface SessionEnrollmentListProps {
  sessionId: number
}

const nameColumn = new Column('fullName', 'Student Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = row => {
  return <div>
    {row.child?.fullName !== null && row.child?.fullName}
  </div>
}
const dateOfBirthColumn = new Column('dateOfBirth', 'DOB', ColumnType.CUSTOM)
dateOfBirthColumn.customCellGenerator = row => {
  return <div className="d-flex f-align-items-center">
    <span>{FormatDateValue(row.child?.dateOfBirth)}</span>
  </div>
}
const ageColumn = new Column('yearsMonthsAge', 'Age(y.m)', ColumnType.CUSTOM)
ageColumn.customCellGenerator = row => {
  return <div>
    {row.child?.ageColumn !== null && row.child?.yearsMonthsAge}
  </div>
}
const ageOnColumn = new Column('yearsMonthsAgeOn9-1', 'Age on 9/1(y.m)', ColumnType.CUSTOM)
ageOnColumn.customCellGenerator = row => {
  return <div>
    {row.child?.yearsMonthsAge !== null && row.child?.yearsMonthsAge}
  </div>
}
const genderColumn = new Column('birthGender', 'Gender', ColumnType.CUSTOM)
genderColumn.customCellGenerator = row => {
  return <div>
    {row.child?.birthGender !== null && row.child?.birthGender}
  </div>
}
const ethCodeColumn = new Column('EthCode', 'Eth.Code', ColumnType.CUSTOM)
ethCodeColumn.customCellGenerator = row => {
  return <div>
    {row.child?.ethCode}
  </div>
}
const homelessColumn = new Column('homeless', 'Homeless', ColumnType.CUSTOM)
homelessColumn.customCellGenerator = row => {
  return <div>
    {row.child?.isHomeless === true ? 'Yes' : 'No'}
  </div>
}
const PovertyColumn = new Column('poverty', 'Poverty %', ColumnType.CUSTOM)
PovertyColumn.customCellGenerator = row => {
  return <div>
    {row.child?.povertyPercentage !== null && row.child?.povertyPercentage}
  </div>
}
const uicColumn = new Column('uic', 'UIC', ColumnType.CUSTOM)
uicColumn.customCellGenerator = row => {
  return <div>
    {row.child?.uic !== null && row.child?.uic}
  </div>
}
const typeColumn = new Column('Program', 'Type', ColumnType.CUSTOM)
typeColumn.customCellGenerator = row => {
  return <div>
    {row.programName !== null && <Chip icon={<FiberManualRecordIcon className={'cyan-icon'} />} label={row.programName} size='small' className={'cyan-indicator'} />}
  </div>
}

const columns = [nameColumn, dateOfBirthColumn, ageColumn, ageOnColumn, genderColumn, ethCodeColumn, typeColumn, PovertyColumn, homelessColumn, uicColumn]

export function SessionEnrollmentList (props: SessionEnrollmentListProps): JSX.Element {
  const [enrollments, setEnrollments] = useState<enrollment[]>([])
  const [enrollmentList, setEnrollmentList] = useState<enrollment[]>([])
  const [selectedProgram, setSelectedProgram] = useState<number>(0)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [programs, setPrograms] = useState<program[]>([])
  const [selectedEnrollmentID, setSelectedEnrollmentID] = useState<number>(0)
  const [selectedEnrollmentChildID, setSelectedEnrollmentChildID] = useState<number>(0)
  const [openDetail, setOpenDetail] = useState<boolean>(false)
  const [page, setPage] = useState(0)
  const nav = useNavigate()
  const accountID = useAccountId()?.id ?? 0

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedEnrollmentID(row.id)
    setSelectedEnrollmentChildID(row.child.id)
    setOpenDetail(true)
  }

  const addClick = (): void => {
    setShowConfirmation(true)
  }

  const handleConfrim = (): void => {
    nav(REFERRAL_MANAGEMENT)
  }

  const onProgramChange = (e: any): void => {
    if (e.target.value !== undefined) {
      setSelectedProgram(e.target.value)
      setEnrollmentList(enrollments.filter(x => x.programID === e.target.value) ?? [])
    } else {
      setSelectedProgram(0)
      setEnrollmentList(enrollments)
    }
  }

  useEffect(() => {
    async function fetchPrograms (): Promise<void> {
      const { response } = await sendGet(`/ServiceProviderDetails/GetProviderPrograms?id=${accountID}`, {})
      if (response !== null) { setPrograms(response) }
      const { response: sessionEnrollmentList } = await sendGet(`/Enrollment/GetEnrollmentsBySessionId?sessionId=${props.sessionId}`, {})
      if (sessionEnrollmentList !== null) {
        setEnrollmentList(sessionEnrollmentList)
        setEnrollments(sessionEnrollmentList)
      }
    }
    void fetchPrograms()
  }, [])

  return (<>
    <Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
      <Autocomplete
        label='Program'
        name='status'
        onChange={onProgramChange}
        options={programs}
        width='200px'
        value={selectedProgram}
      />
      <Box justifyContent='flex-end' display='flex' width='100%' >
        <Button color='secondary' variant='contained' data-testid="addbutton" onClick={addClick}>Add Student</Button>
      </Box>
    </Box>
    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          name='enrollmentList'
          columns={columns}
          rows={enrollmentList}
          page={page}
          onPageChange={(e, p) => { setPage(p) }}
          onRowClick={rowClick}
        />
      </Box>
    </div>

    {showConfirmation && <Dialog
      open={showConfirmation}
      data-testid="confirmationModal"
      onClose={() => { setShowConfirmation(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirmation'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will take you directly to the referral screen and kick off the process of adding a new student there.  Do you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setShowConfirmation(false) }}>Cancel</Button>
        <Button onClick={handleConfrim} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>}

    {openDetail && <EnrollmentDetails
      childID={selectedEnrollmentChildID}
      enrollmentID={selectedEnrollmentID}
      onClose={() => { setOpenDetail(false) }}
      open={openDetail}
    />}
  </>)
}
