import { useState } from 'react'
import { toast } from 'react-toastify'
import { TextFieldWithLabel } from '../../Components/TextField'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { sendPost } from '../../hooks/use-fetch'
import { useAuth } from '../../hooks/use-auth'

export function ChangePassword (): JSX.Element {
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [requirementsWarning, setRequirementsWarning] = useState<boolean>(false)
  const { logout } = useAuth()

  const PasswordMeetsRequirements = (): boolean => {
    const pass = newPassword
    const containsNumber = /\d/.test(pass)
    const containsUpperCaseLetter = /[A-Z]/.test(pass)
    const containsLowerCaseLetter = /[a-z]/.test(pass)

    return pass.length >= 8 && containsNumber && containsUpperCaseLetter && containsLowerCaseLetter
  }

  const UpdatePassword = async (): Promise<void> => {
    if (currentPassword == null || currentPassword.length === 0) {
      toast.error('Current password is required.')
      return
    } else if (newPassword == null || newPassword.length === 0) {
      toast.error('New password is required.')
      return
    } else if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match.')
      return
    } else if (!PasswordMeetsRequirements()) {
      toast.error('Your new password does not meet the system requirements.')
      setRequirementsWarning(true)
      return
    }

    const result = await sendPost('/Account/UpdatePassword', {
      currentPassword,
      newPassword
    })

    if (result.success && result.response === true) {
      toast.success('Your MiCredentials password has been changed.')
      logout()
    } else {
      toast.error('Current password is incorrect.')
    }
  }

  return (
    <>
      <TextFieldWithLabel
        label='Current Password'
        onChange={event => { setCurrentPassword(event.target.value) }}
        name='currentPassword'
        maxWidth='205px'
        type='password'
        value={currentPassword}
        data-testid='current-password'
      />
      <TextFieldWithLabel
        label='New Password'
        onChange={event => { setNewPassword(event.target.value) }}
        name='newPassword'
        maxWidth='205px'
        type='password'
        value={newPassword}
        data-testid='new-password'
      />
      <TextFieldWithLabel
        label='Confirm Password'
        onChange={event => { setConfirmPassword(event.target.value) }}
        name='confirmPassword'
        maxWidth='205px'
        type='password'
        value={confirmPassword}
        data-testid='confirm-password'
      />
      <Button
        sx={{ textTransform: 'none', marginTop: '30px' }}
        variant='contained'
        onClick={UpdatePassword}
        data-testid='submit-button'
      >
        Save Changes
      </Button>

      <Box sx={{
        marginTop: '30px',
        padding: '10px',
        border: requirementsWarning ? 1 : 0,
        borderColor: 'error.main',
        borderRadius: 1
      }}
      >
        <Typography variant="h5">Password Requirements:</Typography>
        <List sx = {{
          listStyleType: 'disc',
          pl: 2
        }}
        >
          <ListItem sx={{ display: 'list-item' }} disableGutters>Must contain an uppercase letter</ListItem>
          <ListItem sx={{ display: 'list-item' }} disableGutters>Must contain a lowercase letter</ListItem>
          <ListItem sx={{ display: 'list-item' }} disableGutters>Must contain a number</ListItem>
          <ListItem sx={{ display: 'list-item' }} disableGutters>Must contain at least 8 characters</ListItem>
        </List>
      </Box>
    </>
  )
}
