import { MoreHoriz } from '@mui/icons-material'
import { Button, IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

interface MenuButtonProps {
  options: MenuButtonOption[]
  onContinue: (index: number) => void
  activeSelection: number | undefined
  setActiveSelection: (index: number | undefined) => void
}

export interface MenuButtonOption {
  id: number
  name: string
  warning: string
  onClick: () => void
  onConfirm: () => Promise<boolean>
  onCancel?: () => void
}

export function MenuButton (props: MenuButtonProps): JSX.Element {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClose = (): void => {
    setOpenMenu(false)
  }

  const handleMenuOpen = (event: any): void => {
    setOpenMenu(true)
    setAnchorEl(event.target)
  }

  const handleMenuItemClick = (option: MenuButtonOption, index: number): void => {
    props.setActiveSelection(index)
    setOpenMenu(false)
    setAnchorEl(null)
    option.onClick()
  }

  const handleCancelClick = (): void => {
    if (props.activeSelection !== undefined) {
      props.options[props.activeSelection].onCancel?.()
    }
    props.setActiveSelection(undefined)
  }

  return <>
    {props.activeSelection !== undefined && (<>
      <Button
        data-testid="confirm-button"
        onClick={() => { props.onContinue(props.activeSelection ?? 0) }}
      >
        Confirm
      </Button>

      {props.options[props.activeSelection].onCancel !== undefined && <Button
        data-testid="cancel-button"
        onClick={handleCancelClick}
      >
        Cancel
      </Button>}
    </>)}
    <IconButton aria-label="settings" onClick={handleMenuOpen} >
      <MoreHoriz data-testid="settingbutton" />
    </IconButton>
    <Menu open={openMenu} data-testid="setting-menue" anchorEl={anchorEl} onClose={handleMenuClose}>
      {props.options.map((option, index) => {
        return <MenuItem
          key={option.id}
          data-testid={'menuItem' + index.toString()}
          onClick={() => { handleMenuItemClick(option, index) }}
        >
          {option.name}
        </MenuItem>
      })}
    </Menu>
  </>
}
