import { useState } from 'react'
import { sendPut } from '../../hooks/use-fetch'
import { Modal } from '../Modal'
import { toast } from 'react-toastify'
import { type DocumentVerifyModel } from '../../core/types'
import { DocumentVerify } from '../DocumentList/DocumentVerify'

export interface VerifyDocumentModalProps {
  documentID: number
  childID: number
  onClose: (updated: boolean) => void
  years: number[]
}

export function VerifyDocumentModal (props: VerifyDocumentModalProps): JSX.Element {
  const [documentVerify, setDocumentVerify] = useState<DocumentVerifyModel | null>(null)

  const handleSubmit = async (): Promise<void> => {
    if (documentVerify !== null) {
      if (documentVerify.documentCaregivers.length !== 0 && !documentVerify.documentCaregivers.some(caregiver => caregiver.appliesTo)) {
        toast.error('Please select at least one caregiver')
      } else {
        documentVerify.isVerified = !documentVerify.isVerified
        const { success } = await sendPut('/Document/UpdateDocumentVerifyDetails', Object(documentVerify))
        if (success) {
          props.onClose(true)
        }
      }
    }
  }

  const content = <DocumentVerify documentID={props.documentID} childID={props.childID} togglingVerificationStatus={true} onChange={setDocumentVerify} years={props.years} />

  return <Modal
    confirmationContent={content}
    title={`${documentVerify?.isVerified ?? false ? 'Unverify' : 'Verify'} Document`}
    open={true}
    onClose={() => { props.onClose(false) }}
    onConfirm={handleSubmit}
  />
}
