import Box from '@mui/material/Box'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  fullWidth?: boolean
}

export const TabDisplay = (props: TabPanelProps): JSX.Element => {
  const { children, value, index } = props

  return (
    <div
      hidden={value !== index}
      id={`tabdisplay-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: (props?.fullWidth ?? false) ? '100%' : 'auto' }}
    >
      {value === index && <Box width={(props?.fullWidth ?? false) ? '100%' : 'auto'} >{children}</Box>}
    </div>
  )
}
