import { IconButton, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { LargeCard } from '../../../Components/Cards/Large'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { generatePath, useNavigate, useParams } from 'react-router'
import { type session } from '../../../core/types'
import { SessionDetail } from './SessionDetails'
import { usePermissions } from '../../../hooks/use-permissions'
import { SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION, SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS } from '../../Routes'
import { FormatTimeAs12Hour } from '../../../core/Utilities'

export function SessionDetailsDisplay (): JSX.Element {
  const nav = useNavigate()
  const { serviceId, sessionId, blockId } = useParams()
  const perms = usePermissions()
  const [session, setSession] = useState<session>({ serviceId, sessionBlockId: blockId, sessionHours: [], startDate: null, endDate: null, inactive: false } as unknown as session)
  const [loaded, setLoaded] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [refresh, setRefresh] = useState(Date.now())

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (sessionId !== 'new') {
        const resp = await sendGet(`/SessionList/Get?sessionId=${sessionId ?? ''}`, {})
        if (resp.success) {
          const sess = { ...resp.response, serviceId, sessionBlockId: blockId }
          setSession(sess)
        }
      }
      const blockresp = await sendGet(`/SessionList/GetBlock?blockId=${blockId ?? ''}`, {})
      if (blockresp.success) {
        session.startDate = blockresp.response.dateRange?.startDate ?? blockresp.response.startDate
        session.endDate = blockresp.response.dateRange?.endDate ?? blockresp.response.endDate
      }
      setLoaded(true)
    }
    void loadData()
  }, [sessionId, refresh])

  const startDateDisplay = session.startDate != null ? new Date(session.startDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null
  const endDateDisplay = session.endDate != null ? new Date(session.endDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null

  const days = []
  const sundayHours = session.sessionHours.find(h => h.dayOfWeek === 'Sunday')
  if (sundayHours != null) days.push(sundayHours)
  const mondayHours = session.sessionHours.find(h => h.dayOfWeek === 'Monday')
  if (mondayHours != null) days.push(mondayHours)
  const tuesdayHours = session.sessionHours.find(h => h.dayOfWeek === 'Tuesday')
  if (tuesdayHours != null) days.push(tuesdayHours)
  const wednesdayHours = session.sessionHours.find(h => h.dayOfWeek === 'Wednesday')
  if (wednesdayHours != null) days.push(wednesdayHours)
  const thursdayHours = session.sessionHours.find(h => h.dayOfWeek === 'Thursday')
  if (thursdayHours != null) days.push(thursdayHours)
  const fridayHours = session.sessionHours.find(h => h.dayOfWeek === 'Friday')
  if (fridayHours != null) days.push(fridayHours)
  const saturdayHours = session.sessionHours.find(h => h.dayOfWeek === 'Saturday')
  if (saturdayHours != null) days.push(saturdayHours)

  if (!loaded) return <CircularProgress />

  return <Paper sx={{ width: '100%', borderRadius: '32px', marginTop: '20px' }}>
    {showModal && (
      <SessionDetail
        session={session}
        setSession={setSession}
        onCancel={() => { setShowModal(false) }}
        onDelete={() => {
          setShowModal(false)
          const url = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION,
            { serviceId: serviceId ?? '', blockId: blockId ?? '' })
          nav(url)
        }}
        onConfirm={(id: number) => {
          setShowModal(false)
          if (sessionId === 'new') {
            const url = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS,
              { serviceId: serviceId ?? '', blockId: blockId ?? '', sessionId: id.toString() })
            nav(url)
          } else {
            setRefresh(Date.now())
          }
        }}
      />
    )}
    {perms.ServiceProviders_Edit && (
      <IconButton sx={{ float: 'right' }} data-testid='editButton' onClick={() => { setShowModal(true) }}><EditIcon /></IconButton>
    )}

    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
          <LargeCard
            className='Borderless'
            fullWidth={true}
            header='Name & Location'
            content={<>
              <TextWithLabel label='Name' key='name' name='name' value={session.name} />
              <TextWithLabel label='Facility' key='facility' name='facility' value={session.facilityName ?? ''} />
              <TextWithLabel label='Facility Room' key='room' name='room' value={session.roomName ?? ''} />
            </>}
          />
          <LargeCard
            fullWidth={true}
            className='Borderless'
            header='Staff'
            content={<>
              <TextWithLabel label='Staff Lead' key='staffLead' name='staffLead' value={session.staffLeadName} />

              {session.sessionStaff?.map((s) => {
                return <TextWithLabel testId='staff' label='Support Staff' name={s.name ?? ''} key={s.name} value={s.name} />
              })}
            </>}
          />
          <LargeCard
            fullWidth={true}
            className='Borderless'
            header='Capacity & Funding Allocation'
            content={<>
              {session.noCapacityLimit && <Typography variant='body1'>No Capacity Limit</Typography>}
              {!session.noCapacityLimit && session.programs?.map((p) => {
                return <TextWithLabel testId='programs' label={p.name ?? ''} key={p.name} name={p.name ?? ''} value={String(p.seats ?? 0)} />
              })}
            </>}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <LargeCard
            className='Borderless'
            fullWidth={true}
            header='Dates'
            content={<>
              <TextWithLabel label='Start Date' name='start' key='start' value={startDateDisplay} />
              <TextWithLabel label='End Date' name='end' key='end' value={endDateDisplay} />
            </>}
          />
          <LargeCard
            className='Borderless'
            fullWidth={true}
            header='Schedule'
            content={<>{ days?.map((sh) => {
              return <TextWithLabel testId='sessionHours' label={sh.dayOfWeek} name={sh.dayOfWeek} key={sh.dayOfWeek} value={`${FormatTimeAs12Hour(sh.openingTime)} - ${FormatTimeAs12Hour(sh.closingTime)}`} />
            })}</>}
          />
        </Grid>
      </Grid>
    </Grid>
  </Paper>
}
