import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { CheckBoxWithLabel } from '../CheckBox'
import { type race } from '../../core/types'

interface RaceListProps {
  raceList: race[]
  setRaceList: (raceList: race[]) => void
}

export function RaceList (props: RaceListProps): JSX.Element {
  const [ethnicityItem, setEthnicityItem] = useState<race>()
  const [raceItems, setRaceItems] = useState<race[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/Race/GetLookup', {})
      if (error[0] === '') {
        const serverItems: race[] = response
        const raceList: race[] = []
        serverItems.forEach(r => {
          if (r.id === 4) {
            setEthnicityItem(r)
          } else {
            raceList.push(r)
          }
        })
        setRaceItems(raceList)
      }
    }
    void fetchData()
  }, [])

  function RaceCheckBox (r: race): JSX.Element {
    const changeRace = (selected: boolean): void => {
      if (selected && !props.raceList.map(rl => rl.name).includes(r.name)) {
        props.setRaceList([...props.raceList, { id: r.id, name: r.name, codePosition: 0 }])
      } else {
        props.setRaceList(props.raceList.filter(x => x.name !== r.name))
      }
    }
    const result = <CheckBoxWithLabel key={r.id} label={r.name} name={r.name} dataTestId={`raceCheckBox-${r.name}`} value={props.raceList.map(rl => rl.name).includes(r.name)} setValue={changeRace} edge={'start'} />
    return result
  }

  return <>
    <Box display={'block'}>
      <Typography className="info-box-header">Ethnicity</Typography>
      {ethnicityItem != null && RaceCheckBox(ethnicityItem)}
    </Box>
    <Box display={'block'}>
      <Typography className="info-box-header">Race - Select all that apply</Typography>
      {raceItems.map(r => {
        return RaceCheckBox(r)
      })}
    </Box>
  </>
}
