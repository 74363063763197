import { IconButton, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { type DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import './Modal.css'

export type ConfirmationDialogProps = DialogProps & {
  id?: string
  title?: string
  titleContent?: JSX.Element
  open: boolean
  confirmationContent?: React.ReactNode
  maxWidth?: string
  cancelButtonText?: string
  confirmButtonText?: string
  confirmButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  hideCancelButton?: boolean
  isUpdateDisabled?: boolean
  className?: string
  buttonClassName?: string
  hideXtoClose?: boolean
  noButtons?: boolean
  onClose: () => void
  onCancel?: () => void
  onConfirm: () => void
}

export const Modal = (props: ConfirmationDialogProps): JSX.Element => {
  function onDialogClose (event: any, reason: string): void {
    if (reason !== 'backdropClick') {
      props.onClose()
    }
  }

  const {
    onClose, onCancel, onConfirm, confirmationContent, title, open, titleContent, maxWidth, cancelButtonText, confirmButtonText, hideCancelButton,
    className, isUpdateDisabled, confirmButtonColor, buttonClassName, hideXtoClose, noButtons,
    ...other
  } =
    props
  const content = confirmationContent !== null ? confirmationContent : 'Are you sure you\'d like to perform this action?'
  const confirmationTitle = props.title ?? 'Confirm'
  return (
    <Dialog
      maxWidth={maxWidth ?? 'xs'}
      PaperProps={{ className: className ?? 'Modal', sx: { overflow: 'hidden', '&&': { maxWidth } } }}
      open={open}
      onClose={onDialogClose}
      {...other}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }} data-testid='modal-title'>
        {
          titleContent != null
            ? titleContent
            : <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>{confirmationTitle}</Typography>
        }
        {(props.hideXtoClose == null || !props.hideXtoClose) &&
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              width: 40,
              height: 40,
              color: (theme) => theme.palette.grey[900]
            }}
          >
            <CloseIcon sx={{ width: '40px', height: '40px' }} />
          </IconButton>
        }
      </DialogTitle>
      <DialogContent data-testid='modal-content'>
        {content}
      </DialogContent>
      {(noButtons == null || !noButtons) && <DialogActions sx={{ justifyContent: 'space-between' }}>
        {hideCancelButton !== true && <Button
          data-testid='cancelButton'
          className={buttonClassName}
          variant='outlined'
          color='inherit'
          sx={{ fontWeight: 'bold' }}
          onClick={onCancel ?? onClose}
        >
          {cancelButtonText ?? 'Discard Changes'}
        </Button>
        }
        {hideCancelButton === true && <span />}
        <Button data-test-update
          data-testid='updateButton'
          onClick={onConfirm}
          color={confirmButtonColor ?? 'secondary'}
          variant='contained'
          disabled={props.isUpdateDisabled ?? false}
          className={buttonClassName}
        >
          {confirmButtonText ?? 'Update'}
        </Button>
      </DialogActions>
      }
    </Dialog>
  )
}
