import { Button, Grid } from '@mui/material'
import { type EnrollmentDetails } from '../../../../core/types'
import { submitForm } from '../../../../ContextProviders/CaregiverWizard'
import { generatePath, useNavigate, useParams } from 'react-router'
import { CAREGIVER_PORTAL_SUBMITTED } from '../../../Routes'
import { useScrollTop } from '../../../../hooks/use-scrollTop'
import { useRef } from 'react'

export interface DataReconcileProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function DataReconcile (props: DataReconcileProps): JSX.Element {
  const nav = useNavigate()
  const { isdId } = useParams()
  const isSubmitting = useRef(false)

  useScrollTop()

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting.current) {
      return
    }

    isSubmitting.current = true
    await submitForm(isdId ?? '')
    nav(generatePath(CAREGIVER_PORTAL_SUBMITTED, { isdId: isdId ?? '' }))
    window.location.reload() // Clears the session
  }

  return <Grid container spacing={2}>
    <Grid item xs={12} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='submitButton'
        className='footer-button'
        data-testid='final-submit'
        onClick={handleSubmit}
        variant='contained'
      >
        Submit Interest Form
      </Button>
    </Grid>
  </Grid>
}
