/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Chip } from '@mui/material'
import { useState, useEffect } from 'react'
import { EnrollmentStatus, type enrollment, type sessionBlock, type program, type postError, type lookup } from '../../../core/types'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { Column, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import DataTable from '../../../Components/Table/DataTable'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { EnrollmentDetails } from './EnrollmentDetails'
import { EnrollmentSearch } from './EnrollmentSearch'
import { type MenuButtonOption } from '../../../Components/Menu/MenuButton'
import { BannerModal } from '../../../Components/Modal/BannerModal'
import { toast } from 'react-toastify'
import { ErrorDisplay, useErrorDispatch } from '../../../ContextProviders/ErrorProvider'
import { type SelectOption, SelectWithLabel } from '../../../Components/SelectWithLabel'
import { useParams } from 'react-router'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { DatePickerWithLabel } from '../../../Components/DatePicker'

export interface EnrollmentListProps {
  enrollmentStatus: EnrollmentStatus
  sessionBlockList: sessionBlock[]
  sessionList: lookup[]
  programs: program[]
  facilityList: lookup[]
  exitReasons?: lookup[]
  massEnrollment?: boolean
  massExit?: boolean
  enrollment?: boolean
  exit?: boolean
  showExitFilter: boolean
  onEnrollmentChanged: () => void
}

// #region columns
const nameColumn = new Column('fullName', 'Student Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = row => {
  return <div>
    {row.child?.fullName !== null && row.child?.fullName}
  </div>
}
const facilityColumn = new Column('facilityName', 'Facility', ColumnType.CUSTOM)
facilityColumn.customCellGenerator = row => {
  return <div>
    {row.child?.facilityName !== null && row.child?.facilityName}
  </div>
}
const sessionColumn = new Column('sessionName', 'Session', ColumnType.CUSTOM)
sessionColumn.customCellGenerator = row => {
  return <div>
    {row.child?.sessionName !== null && row.child?.sessionName}
  </div>
}
const ageColumn = new Column('yearsMonthsAge', 'Age(y.m)', ColumnType.CUSTOM)
ageColumn.customCellGenerator = row => {
  return <div>
    {row.child?.yearsMonthsAge !== null && row.child?.yearsMonthsAge}
  </div>
}
const fundingColumn = new Column('fundingName', 'Funding', ColumnType.CUSTOM)
fundingColumn.customCellGenerator = row => {
  return <div>
    {row.fundingSourceName != null && <Chip icon={<FiberManualRecordIcon className={'cyan-icon'} />} label={row.fundingSourceName} size='small' className={'cyan-indicator'} />}
  </div>
}
const homelessColumn = new Column('homeless', 'Homeless', ColumnType.CUSTOM)
homelessColumn.customCellGenerator = row => {
  return <div>
    {row.child?.isHomeless === true ? 'Yes' : 'No'}
  </div>
}
const approvalColumn = new Column('incomeApproval', 'Income Approval', ColumnType.CUSTOM)
approvalColumn.customCellGenerator = row => {
  const enrollmentRow = row as enrollment

  let iconColor = ''
  let backgroundColor = ''
  if (enrollmentRow.incomeApprovalStatus === 'Pending') {
    iconColor = '#9D25C8 !important'
    backgroundColor = 'rgba(157, 37, 200, 0.1)'
  } else if (enrollmentRow.incomeApprovalStatus === 'Approved') {
    iconColor = '#4DA4A4 !important'
    backgroundColor = 'rgba(77, 164, 164, 0.1)'
  } else if (enrollmentRow.incomeApprovalStatus === 'Denied') {
    iconColor = '#D4316C !important'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }

  return <div>
    {enrollmentRow.incomeApprovalStatus !== null &&
      <Chip icon={<FiberManualRecordIcon sx={{ color: iconColor }} />} label={enrollmentRow.incomeApprovalStatus} size="small" sx={{ color: iconColor, backgroundColor }} />
    }
  </div>
}
const povertyColumn = new Column('poverty', 'Poverty %', ColumnType.CUSTOM)
povertyColumn.customCellGenerator = row => {
  const enrollmentRow = row as enrollment

  return <div>
    {enrollmentRow.child?.povertyPercentage != null && (`${enrollmentRow.child?.povertyPercentage}%`)}
  </div>
}
const uicColumn = new Column('uic', 'UIC', ColumnType.CUSTOM)
uicColumn.customCellGenerator = row => {
  return <div>
    {row.child?.uic !== null && row.child?.uic}
  </div>
}
const variableColumn = new Column('EthCode', 'Eth.Code', ColumnType.CUSTOM)
variableColumn.customCellGenerator = row => {
  return <div>
    {row.child?.ethCode}
  </div>
}
// #endregion

export function EnrollmentList (props: EnrollmentListProps): JSX.Element {
  const enrollmentWarning = 'Enrolling these children will be done in order of their corresponding federal poverty level. This action will fill all available seats with active enrollments. Any children that are left over will be added to your capacity waitlist.  Do you wish to continue?'
  const { serviceId } = useParams()
  const accountId = useAccountId()?.id ?? 0
  const [selectedEnrollmentID, setSelectedEnrollmentID] = useState<number>(0)
  const [selectedEnrollmentChildID, setSelectedEnrollmentChildID] = useState<number>(0)
  const [exitReasonId, setExitReasonId] = useState<number | undefined>(undefined)
  const [exitDate, setExitDate] = useState<Date | undefined>(undefined)
  const [sessionId, setSessionId] = useState<number | undefined>(undefined)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [enrollDate, setEnrollDate] = useState<Date | undefined>(undefined)
  const [openDetail, setOpenDetail] = useState<boolean>(false)
  const [showCheckBox, setShowCheckbox] = useState<boolean>(false)
  const [enrollments, setEnrollments] = useState<enrollment[]>([])
  const baseSearchString = `/Enrollment/SearchEnrollments?systemServiceID=${serviceId ?? 0}&spId=${accountId}&statusId=${props.enrollmentStatus}`
  const [searchString, setSearchString] = useState<string>(baseSearchString)
  const [showEnrollmentWarning, setShowEnrollmentWarning] = useState<boolean>(false)
  const [showExitWarning, setShowExitWarning] = useState<boolean>(false)
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [massEnrollmentList, setMassEnrollmentList] = useState<number[]>([])
  const [sessionList, setSessionList] = useState<Array<{ id: number, name: string }>>([])
  const [page, setPage] = useState(0)
  const dispatch = useErrorDispatch()

  const columns = [nameColumn, facilityColumn, sessionColumn, ageColumn, variableColumn, fundingColumn]
  if (props.enrollmentStatus === EnrollmentStatus.Pending) {
    columns.push(approvalColumn)
  }
  columns.push(povertyColumn, homelessColumn, uicColumn)

  const exitDialogContent = <>
    <Box sx={{ margin: '20px' }}>
      <ErrorDisplay fieldName='Exit' />
      <SelectWithLabel
        width='100%'
        label='Exit Reason'
        name='exitReasonId'
        onChange={(evt: any) => { setExitReasonId(evt.target.value) }}
        options={props.exitReasons?.map(x => ({ name: x.name, id: x.id } satisfies SelectOption<number>)) ?? []}
        value={exitReasonId ?? ''}
      />
      <DatePickerWithLabel
        label='Exit Date'
        name='exitDate'
        onChange={(date: Date | null) => { setExitDate(date ?? undefined) }}
        value={exitDate ?? null}
      />
    </Box>
  </>

  const sessionSelect = <Box sx={{ margin: '20px' }}>
    <DatePickerWithLabel
      label='Start Date'
      name='startDate'
      onChange={(date: Date | null) => { setStartDate(date ?? undefined) }}
      value={startDate ?? null}
    />
    <DatePickerWithLabel
      label='Enroll Date'
      name='enrollDate'
      onChange={(date: Date | null) => { setEnrollDate(date ?? undefined) }}
      value={enrollDate ?? null}
    />
    <SelectWithLabel
      width='75%'
      label='Session Enrolling In'
      name='sessionId'
      onChange={(evt: any) => { setSessionId(evt.target.value) }}
      options={sessionList}
      value={sessionId ?? ''}
    />
  </Box>

  const handleConfirmEnrollment = async (): Promise<boolean> => {
    if (!isRequestLoading) {
      let errors = false
      if (sessionId === undefined) {
        toast.error('Please select a session first')
        errors = true
      }
      if (startDate === undefined) {
        toast.error('Please select a start date')
        errors = true
      }
      if (enrollDate === undefined) {
        toast.error('Please select an enrollment date')
        errors = true
      }
      if (errors) { return false }
      setIsRequestLoading(true)
      const data = {
        enrollmentId: selectedEnrollmentID,
        exitReasonId,
        sessionId,
        startDate,
        enrollDate
      }

      const url = '/Enrollment/Enroll'
      const { response } = await sendPost(url, data)
      const rsp: { response: any, errors: postError[], success: boolean } = response
      if (rsp.success) {
        toast.success('Enrollment Completed')
        setShowEnrollmentWarning(false)
        setOpenDetail(false)
        await searchEnrollments()
        props.onEnrollmentChanged()
      } else {
        toast.error(rsp.errors[0].error)
        setShowEnrollmentWarning(false)
      }
      setIsRequestLoading(false)
      return rsp.success
    }
    return false
  }

  const handleConfirmExit = async (): Promise<boolean> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const data = {
        enrollmentId: selectedEnrollmentID,
        exitReasonId,
        exitDate
      }
      const url = '/Enrollment/Exit'
      const { response } = await sendPost(url, data)
      const rsp: { response: any, errors: postError[], success: boolean } = response
      if (rsp.success) {
        toast.success('Exit Completed')
        setShowExitWarning(false)
        setOpenDetail(false)
        await searchEnrollments()
        props.onEnrollmentChanged()
      } else {
        dispatch({ errors: rsp.errors, type: 'update' })
      }
      setIsRequestLoading(false)
      return rsp.success
    }
    return false
  }

  async function searchEnrollments (): Promise<void> {
    const { response } = await sendGet(searchString, {})
    if (response != null) {
      setEnrollments(response)
    }
  }

  useEffect(() => {
    void searchEnrollments()
  }, [searchString])

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    if (enrollments !== undefined && enrollments.length > 0) {
      const enrollmentIds: number[] = []
      if (checked) {
        enrollments.forEach((enrollment: enrollment) => {
          enrollmentIds.push(enrollment.id)
        })
      }
      setMassEnrollmentList(enrollmentIds)
    }
  }

  const handleRawCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: RowData): void => {
    if (massEnrollmentList !== undefined) {
      const index = massEnrollmentList?.findIndex(e => e === row.id)
      if (checked) {
        if (index === -1) {
          setMassEnrollmentList([...massEnrollmentList, row.id])
        }
      } else {
        setMassEnrollmentList(massEnrollmentList.filter(e => e !== row.id))
      }
    }
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedEnrollmentID(row.id)
    setSelectedEnrollmentChildID(row.child.id)
    setSessionId(row.child.sessionId)
    setOpenDetail(true)
  }

  const handleConfirmMassEnrollment = async (): Promise<boolean> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      const url = '/Enrollment/MassEnrollment'
      const { success, response } = await sendPost(url, { EnrollmentIds: massEnrollmentList ?? [] })
      if (success && (response as boolean)) {
        setShowCheckbox(false)
        setMassEnrollmentList([])
        toast.success('Mass Enrollment Completed')
        await searchEnrollments()
        props.onEnrollmentChanged()
      } else {
        toast.error('Mass Enrollment failed.  Be sure that all selected items have a session assigned')
      }
      setIsRequestLoading(false)
      return success
    }
    return false
  }

  const handleConfirmMassExit = async (): Promise<boolean> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const data = {
        enrollmentIds: massEnrollmentList,
        exitReasonId,
        exitDate
      }
      const url = '/Enrollment/MassExit'
      const { response } = await sendPost(url, data)
      const rsp: { response: any, errors: postError[], success: boolean } = response
      if (rsp.success) {
        toast.success('Mass Enrollment Completed')
        setShowCheckbox(false)
        await searchEnrollments()
        props.onEnrollmentChanged()
      } else {
        dispatch({ errors: rsp.errors, type: 'update' })
      }
      setIsRequestLoading(false)
      return rsp.success
    }
    return false
  }

  const showCheckboxes = (): void => {
    setShowCheckbox(true)
  }

  const hideCheckboxes = (): void => {
    setShowCheckbox(false)
  }

  const menuButtonOptions: MenuButtonOption[] = []
  if (props.massEnrollment === true) {
    menuButtonOptions.push({
      id: 1,
      name: 'Mass Enrollment',
      warning: enrollmentWarning ?? '',
      onClick: showCheckboxes,
      onConfirm: handleConfirmMassEnrollment,
      onCancel: hideCheckboxes
    })
  }
  if (props.massExit === true) {
    menuButtonOptions.push({
      id: 2,
      name: 'Mass Exit',
      warning: 'Please enter values and confirm the mass exit.',
      onClick: showCheckboxes,
      onConfirm: handleConfirmMassExit,
      onCancel: hideCheckboxes
    })
  }

  const startExit = (): void => {
    setExitReasonId(undefined)
    setExitDate(undefined)
    dispatch({ type: 'clear', errors: [] })
    setShowExitWarning(true)
  }

  const cancelExit = (): void => {
    setShowExitWarning(false)
  }

  const cancelEnrollment = (): void => {
    setShowEnrollmentWarning(false)
  }

  const startEnrollment = async (): Promise<void> => {
    const enrollment = enrollments.find(e => e.id === selectedEnrollmentID)
    if (enrollment == null) {
      return
    } else if (enrollment.incomeApprovalStatus === 'Denied') {
      toast.warning('Income approval has been denied. Enrollment cannot be completed')
      return
    } else if (enrollment.incomeApprovalStatus === 'Pending') {
      toast.warning('Income approval has not been accepted yet. Enrollment cannot be completed')
      return
    }

    const { response: sessions } = await sendGet(`/SessionList/GetSessionsByEnrollment?id=${selectedEnrollmentID}`, {})
    if (props.sessionBlockList !== null) {
      setSessionList(sessions)
    }

    setShowEnrollmentWarning(true)
  }

  return (<>
    <EnrollmentSearch
      facilityList={props.facilityList}
      programs={props.programs}
      sessionBlockList={props.sessionBlockList}
      sessionList={props.sessionList}
      exitReasons={props.showExitFilter ? props.exitReasons : undefined}
      setSearchString={setSearchString}
      baseSearchString={baseSearchString}
      menuButtonOptions={menuButtonOptions}
      warningForm={exitDialogContent}
    />

    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          name='exitedlistEnrollments'
          columns={columns}
          page={page}
          onPageChange={(e, p) => { setPage(p) }}
          rows={enrollments}
          hasCheckbox={showCheckBox}
          initialCheckedItems={massEnrollmentList}
          onRowClick={showCheckBox ? () => { } : rowClick}
          onRowCheckboxChange={handleRawCheckboxChange}
          onHeaderCheckboxChange={handleSelectAllChange}
        />
      </Box>
    </div>
    {props.exit === true && (
      <BannerModal
        className='Nested Referral'
        maxWidth='sm'
        cancelButtonText='No, Cancel'
        confirmButtonText='Yes, Continue'
        content={exitDialogContent}
        title='Confirm Exit'
        onConfirm={async () => { await handleConfirmExit() }}
        open={showExitWarning}
        onClose={cancelExit}
      />
    )}
    {props.enrollment === true && (
      <BannerModal
        className='Nested Referral'
        maxWidth='sm'
        cancelButtonText='No, Cancel'
        confirmButtonText='Yes, Continue'
        content={sessionSelect}
        title='Confirm Enrollment'
        onConfirm={async () => { await handleConfirmEnrollment() }}
        open={showEnrollmentWarning}
        onClose={cancelEnrollment}
      />
    )}
    {openDetail && <EnrollmentDetails
      childID={selectedEnrollmentChildID}
      enrollmentID={selectedEnrollmentID}
      onClose={() => { setOpenDetail(false) }}
      open={openDetail}
      onEnroll={props.enrollment === true ? startEnrollment : undefined}
      onExit={props.exit === true ? startExit : undefined}
      onReapplyIncome={searchEnrollments}
    />}
  </>)
}
