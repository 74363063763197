import { Box, Grid } from '@mui/material'
import { RaceList } from '../../../Components/CheckList/RaceList'
import { type EligibilityValues, EligiblityFactorList } from '../../../Components/CheckList/EligiblityFactorList'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { type race, type eligibilityFactor, type environmentalRisk } from '../../../core/types'
import { AgeFromDate } from '../../../core/Utilities'
import { type AdditionalValues, ChildAdditionalInfoList } from '../../../Components/CheckList/ChildAdditionalInfoList'

export function AddChildRace (): JSX.Element {
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()
  const childAge: number = AgeFromDate(data.child.dateOfBirth)
  const handleRaceChange = (list: race[]): void => {
    dispatch({ type: 'child', child: { ...data.child, childRaceList: list } })
  }

  const handleFactorsChange = (list: eligibilityFactor[], environmentalRiskList: environmentalRisk[], values: EligibilityValues): void => {
    let child = { ...data.child, eligibilityFactorList: list, environmentalRiskList }
    child = { ...child, otherEligibilityFactor: values.otherFactor, hasIEP: values.hasIEP, hasIFSP: values.hasIFSP, hasEOIS: values.hasEOIS }
    dispatch({ type: 'child', child })
  }

  const handleAdditionalValuesChange = (values: AdditionalValues): void => {
    let child = { ...data.child }
    child = { ...child, isFosterChild: values.isFosterChild, isOnSSI: values.isOnSSI, isOnCashAssistance: values.isOnCashAssistance, hasSNAPBenefits: values.hasSNAPBenefits }
    dispatch({ type: 'child', child })
  }
  const additionalValues: AdditionalValues = {
    hasSNAPBenefits: data.child.hasSNAPBenefits,
    isFosterChild: data.child.isFosterChild,
    isOnCashAssistance: data.child.isOnCashAssistance,
    isOnSSI: data.child.isOnSSI
  }

  return <Box width={'100%'}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RaceList
          raceList={data.child.childRaceList ?? []}
          setRaceList={handleRaceChange}
        />
      </Grid>
      <Grid item xs={12}>
        <EligiblityFactorList
          eligiblityList={data.child.eligibilityFactorList ?? []}
          enviromentalRiskList={data.child.environmentalRiskList ?? [] }
          setEligibilityValues={handleFactorsChange}
          otherFactor={data.child.otherEligibilityFactor}
          // taking child's age into consideration for when to show the following
          captureIEP={data.child.captureIEP && childAge >= 3}
          captureIFSP={data.child.captureIFSP && childAge < 3}
          captureEOIS={data.child.captureEOIS && childAge >= 3}
          hasIEP={data.child.hasIEP}
          hasIFSP={data.child.hasIFSP}
          hasEOIS={data.child.hasEOIS}
        />
      </Grid>
      <Grid item xs={12}>
        <ChildAdditionalInfoList
          values={additionalValues}
          setAdditionalValues={handleAdditionalValuesChange}
        />
      </Grid>
    </Grid>
  </Box>
}
