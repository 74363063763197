export function MapStateName (stateName: string): string {
  return states.find(s => s.name === stateName)?.abr ?? ''
}

const states: Array<{ name: string, abr: string }> = [
  { name: 'Alabama', abr: 'AL' },
  { name: 'Alaska', abr: 'AK' },
  { name: 'American Samoa', abr: 'AS' },
  { name: 'Arizona', abr: 'AZ' },
  { name: 'Arkansas', abr: 'AR' },
  { name: 'Armed Forces Americas', abr: 'AA' },
  { name: 'Armed Forces Europe', abr: 'AE' },
  { name: 'Armed Forces Pacific', abr: 'AP' },
  { name: 'California', abr: 'CA' },
  { name: 'Colorado', abr: 'CO' },
  { name: 'Connecticut', abr: 'CT' },
  { name: 'Delaware', abr: 'DE' },
  { name: 'District Of Columbia', abr: 'DC' },
  { name: 'Florida', abr: 'FL' },
  { name: 'Georgia', abr: 'GA' },
  { name: 'Guam', abr: 'GU' },
  { name: 'Hawaii', abr: 'HI' },
  { name: 'Idaho', abr: 'ID' },
  { name: 'Illinois', abr: 'IL' },
  { name: 'Indiana', abr: 'IN' },
  { name: 'Iowa', abr: 'IA' },
  { name: 'Kansas', abr: 'KS' },
  { name: 'Kentucky', abr: 'KY' },
  { name: 'Louisiana', abr: 'LA' },
  { name: 'Maine', abr: 'ME' },
  { name: 'Marshall Islands', abr: 'MH' },
  { name: 'Maryland', abr: 'MD' },
  { name: 'Massachusetts', abr: 'MA' },
  { name: 'Michigan', abr: 'MI' },
  { name: 'Minnesota', abr: 'MN' },
  { name: 'Mississippi', abr: 'MS' },
  { name: 'Missouri', abr: 'MO' },
  { name: 'Montana', abr: 'MT' },
  { name: 'Nebraska', abr: 'NE' },
  { name: 'Nevada', abr: 'NV' },
  { name: 'New Hampshire', abr: 'NH' },
  { name: 'New Jersey', abr: 'NJ' },
  { name: 'New Mexico', abr: 'NM' },
  { name: 'New York', abr: 'NY' },
  { name: 'North Carolina', abr: 'NC' },
  { name: 'North Dakota', abr: 'ND' },
  { name: 'Northern Mariana Islands', abr: 'NP' },
  { name: 'Ohio', abr: 'OH' },
  { name: 'Oklahoma', abr: 'OK' },
  { name: 'Oregon', abr: 'OR' },
  { name: 'Pennsylvania', abr: 'PA' },
  { name: 'Puerto Rico', abr: 'PR' },
  { name: 'Rhode Island', abr: 'RI' },
  { name: 'South Carolina', abr: 'SC' },
  { name: 'South Dakota', abr: 'SD' },
  { name: 'Tennessee', abr: 'TN' },
  { name: 'Texas', abr: 'TX' },
  { name: 'US Virgin Islands', abr: 'VI' },
  { name: 'Utah', abr: 'UT' },
  { name: 'Vermont', abr: 'VT' },
  { name: 'Virginia', abr: 'VA' },
  { name: 'Washington', abr: 'WA' },
  { name: 'West Virginia', abr: 'WV' },
  { name: 'Wisconsin', abr: 'WI' },
  { name: 'Wyoming', abr: 'WY' }
]
