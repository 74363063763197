import { Container, Grid, Typography } from '@mui/material'
import { SlideTab } from '../../../Components/TabDisplay/SlideTab'
import { useEffect, useState } from 'react'
import ProgressBar from '../../../Components/ProgressBar'
import { ChildDetailsStep } from './Step1/ChildDetailsStep'
import { useCaregiverWizardContext } from '../../../ContextProviders/CaregiverWizard'
import { generatePath, useNavigate, useParams } from 'react-router'
import { CAREGIVER_PORTAL } from '../../Routes'
import { CaregiverDetailsStep } from './Step2/CaregiverDetailsStep'
import { ServiceSelectionStep } from './Step3/ServiceSelectionStep'
import { ServiceProviderStep } from './Step4/ServiceProviderStep'
import { AccountCreationStep } from './Step5/AccountCreationStep'
import { FinalSummaryStep } from './Step6/FinalSummaryStep'

export function CaregiverEnrollments (): JSX.Element {
  const [backwards, setBackwards] = useState<boolean>(false)
  const { isdId, isdServiceProviderId } = useParams()
  const caregiverForm = useCaregiverWizardContext()
  const nav = useNavigate()

  useEffect(() => {
    if ((caregiverForm?.isNewSession) === true) {
      const portalRoute = generatePath(CAREGIVER_PORTAL, { isdId: isdId ?? '', isdServiceProviderId: isdServiceProviderId ?? '' })
      nav(portalRoute)
    }
  }, [caregiverForm, isdId])

  if (caregiverForm == null) {
    return <></>
  }

  const handleStep = (backwards: boolean): void => {
    setBackwards(backwards)
  }

  return <Container>
    <Typography variant='h4' sx={{ mb: '30px' }}>Inquiry Form</Typography>
    <Grid container sx={{ overflowX: 'hidden' }}>
      <Grid item xs={12} sx={{ mb: '20px' }}>
        <ProgressBar
          totalSteps={6}
          currentStep={caregiverForm.currentStep}
        />
      </Grid>
      <SlideTab index={1} value={caregiverForm.currentStep} backwards={backwards}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
          <ChildDetailsStep caregiverForm={caregiverForm} handleStep={handleStep} />
        </Grid>
      </SlideTab>
      <SlideTab index={2} value={caregiverForm.currentStep} backwards={backwards} >
        <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
          <CaregiverDetailsStep caregiverForm={caregiverForm} handleStep={handleStep} />
        </Grid>
      </SlideTab>
      <SlideTab index={3} value={caregiverForm.currentStep} backwards={backwards}>
        <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
          <ServiceSelectionStep caregiverForm={caregiverForm} handleStep={handleStep} />
        </Grid>
      </SlideTab>
      <SlideTab index={4} value={caregiverForm.currentStep} backwards={backwards}>
        <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
          <ServiceProviderStep caregiverForm={caregiverForm} handleStep={handleStep} />
        </Grid>
      </SlideTab>
      <SlideTab index={5} value={caregiverForm.currentStep} backwards={backwards}>
        <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
          <AccountCreationStep caregiverForm={caregiverForm} handleStep={handleStep} />
        </Grid>
      </SlideTab>
      <SlideTab index={6} value={caregiverForm.currentStep} backwards={backwards}>
        <Grid item xs={12} sx={{ display: 'flex', pt: 2, pl: 2 }}>
          <FinalSummaryStep caregiverForm={caregiverForm} handleStep={handleStep} />
        </Grid>
      </SlideTab>
    </Grid>
  </Container>
}
