import { Box } from '@mui/material'
import { BusinessHourPicker } from './BusinessHourPicker'
import { type businessHours } from '../../core/types'

export interface BusinessWeekHoursProps {
  BusinessWeek: businessHours[]
  onChange: (newWeek: businessHours[]) => void
  onAccept: (newWeek: businessHours[]) => void
}

export function BusinessWeekHours (props: BusinessWeekHoursProps): JSX.Element {
  const handleTimeChange = (date: string, field: string, time: Date | null): void => {
    const modifyingDay = props.BusinessWeek.find(h => h.dayOfWeek === date)
    if (modifyingDay !== undefined) {
      const timeString = `${time?.getHours() ?? '00'}:${time?.getMinutes() ?? '00'}:${time?.getSeconds() ?? '00'}`
      const newHours = { ...modifyingDay, [field]: timeString }
      const newWeek = props.BusinessWeek.filter(h => h.dayOfWeek !== date)
      newWeek.push(newHours)
      props.onChange(newWeek)
    }
  }

  const handleTimeAccept = (date: string, field: string, time: Date | null): void => {
    const modifyingDay = props.BusinessWeek.find(h => h.dayOfWeek === date)
    if (modifyingDay !== undefined) {
      const timeString = `${time?.getHours() ?? '00'}:${time?.getMinutes() ?? '00'}:${time?.getSeconds() ?? '00'}`
      const newHours = { ...modifyingDay, [field]: timeString }
      const newWeek = props.BusinessWeek.filter(h => h.dayOfWeek !== date)
      newWeek.push(newHours)
      props.onAccept(newWeek)
    }
  }

  const handleEnableChange = (date: string, enabled: boolean): void => {
    if (enabled) {
      const newDay: businessHours = {
        dayOfWeek: date,
        facilityID: 0,
        id: 0,
        dayDisplay: date,
        closingTime: '17:00:00',
        openingTime: '08:00:00'
      }
      const newWeek = props.BusinessWeek
      newWeek.push(newDay)
      props.onAccept(newWeek)
    } else {
      props.onAccept(props.BusinessWeek.filter(h => h.dayOfWeek !== date))
    }
  }

  const sundayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Sunday')
  const mondayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Monday')
  const tuesdayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Tuesday')
  const wednesdayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Wednesday')
  const thursdayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Thursday')
  const fridayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Friday')
  const saturdayHours = props.BusinessWeek.find(h => h.dayOfWeek === 'Saturday')

  return <Box>
    <BusinessHourPicker
      date='Sunday'
      dayOfWeek='Sunday'
      facilityHours={sundayHours}
      onEnabledChange={handleEnableChange}
      onTimeChange={handleTimeChange}
      onTimeAccept={handleTimeAccept}
    />
    <BusinessHourPicker
      date='Monday'
      dayOfWeek='Monday'
      facilityHours={mondayHours}
      onEnabledChange={handleEnableChange}
      onTimeAccept={handleTimeAccept}
      onTimeChange={handleTimeChange}
    />
    <BusinessHourPicker
      date='Tuesday'
      dayOfWeek='Tuesday'
      facilityHours={tuesdayHours}
      onEnabledChange={handleEnableChange}
      onTimeAccept={handleTimeAccept}
      onTimeChange={handleTimeChange}
    />
    <BusinessHourPicker
      date='Wednesday'
      dayOfWeek='Wednesday'
      facilityHours={wednesdayHours}
      onEnabledChange={handleEnableChange}
      onTimeAccept={handleTimeAccept}
      onTimeChange={handleTimeChange}
    />
    <BusinessHourPicker
      date='Thursday'
      dayOfWeek='Thursday'
      facilityHours={thursdayHours}
      onEnabledChange={handleEnableChange}
      onTimeAccept={handleTimeAccept}
      onTimeChange={handleTimeChange}
    />
    <BusinessHourPicker
      date='Friday'
      dayOfWeek='Friday'
      facilityHours={fridayHours}
      onEnabledChange={handleEnableChange}
      onTimeAccept={handleTimeAccept}
      onTimeChange={handleTimeChange}
    />
    <BusinessHourPicker
      date='Saturday'
      dayOfWeek='Saturday'
      facilityHours={saturdayHours}
      onEnabledChange={handleEnableChange}
      onTimeAccept={handleTimeAccept}
      onTimeChange={handleTimeChange}
    />
  </Box>
}
