import { useEffect, useRef, useState } from 'react'
import { DraggableItem } from './DraggableItem'
import { Box, type SxProps } from '@mui/material'

interface DragAndDropReorderListProps {
  items: ReorderItem[]
  onListReordered: (items: ReorderItem[]) => void
  sx?: SxProps
}

export interface ReorderItem {
  id: number
  index: number
  firstLine: string
  secondLine: string
  thirdLine: string
}

interface ItemPosition {
  item: ReorderItem
  top: number
  bottom: number
  left: number
  right: number
}

export function DragAndDropReorderList (props: DragAndDropReorderListProps): JSX.Element {
  const [draggingItem, setDraggingItem] = useState<ReorderItem | null>(null)
  const [draggingElement, setDraggingElement] = useState<HTMLElement | null>(null)
  const [itemBounds, setItemBounds] = useState<ItemPosition[]>([])

  const containerRef = useRef<HTMLElement>(null)

  // Get the bounds of each rendered element and map it to the corresponding item.
  // This is used for determining which item the dragged element is dropped on when in mobile mode.
  useEffect(() => {
    const elem = containerRef.current
    if (elem == null) {
      return
    }

    const scrollHeight = getScrollHeight()

    const children = elem.children
    const newBounds: ItemPosition[] = []
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      const rect = child.getBoundingClientRect()
      newBounds.push({
        item: props.items[i],
        top: rect.top + scrollHeight,
        bottom: rect.bottom + scrollHeight,
        left: rect.left,
        right: rect.right
      })
    }
    setItemBounds(newBounds)
  }, [props.items])

  const getScrollHeight = (): number => {
    // window.scrollY is not supported in all browsers, in which case we use document.documentElement.scrollTop
    let scrollHeight = window.scrollY
    if (scrollHeight == null) {
      scrollHeight = document.documentElement.scrollTop
    }

    return scrollHeight
  }

  const onTouchStart = (e: TouchEvent, elem: HTMLElement, item: ReorderItem): void => {
    if (draggingElement != null) {
      return
    }

    // This prevents the page from scrolling while dragging.
    e.preventDefault()

    // Create a clone of the element to drag. This is handled automatically for mouse events, but not for touch events.
    const clone = elem.cloneNode(true) as HTMLElement
    const elemStyle = getComputedStyle(elem)
    const actualWidth = elem.clientWidth - parseFloat(elemStyle.paddingLeft) - parseFloat(elemStyle.paddingRight)
    const scrollHeight = getScrollHeight()

    clone.style.position = 'absolute'
    clone.style.top = (-elem.clientHeight / 2 + scrollHeight).toString() + 'px'
    clone.style.left = (-actualWidth / 2).toString() + 'px'
    clone.style.width = actualWidth.toString() + 'px'
    clone.style.transform = `translate(${e.touches[0].clientX}px, ${e.touches[0].clientY}px)`

    document.body.appendChild(clone)
    setDraggingElement(clone)
    handleDragStart(item)
  }

  const onTouchMove = (e: React.TouchEvent): void => {
    // Update the position of the element we are dragging.
    if (draggingElement === null) {
      return
    }
    draggingElement.style.transform = `translate(${e.touches[0].clientX}px, ${e.touches[0].clientY}px)`
  }

  const onTouchEnd = (e: React.TouchEvent): void => {
    if (draggingElement === null) {
      return
    }
    // Remove the clone.
    document.body.removeChild(draggingElement)
    setDraggingElement(null)

    const scrollHeight = getScrollHeight()

    // Find the item that the dragged element was dropped on.
    const item = itemBounds.find(b => {
      const x = e.changedTouches[0].clientX
      const y = e.changedTouches[0].clientY + scrollHeight
      return x >= b.left && x <= b.right && y >= b.top && y <= b.bottom
    })

    if (item != null) {
      handleDrop(item.item)
    }
    handleDragEnd()
  }

  const handleDragStart = (item: ReorderItem): void => {
    setDraggingItem(item)
  }

  const handleDragEnd = (): void => {
    setDraggingItem(null)
  }

  const handleDrop = (item: ReorderItem): void => {
    if (draggingItem === null) {
      return
    }

    const draggingIndex = draggingItem.index
    const overIndex = item.index

    if (draggingIndex === overIndex) {
      return
    }

    // Move the dragged item to the index of the item it was dropped on.
    const newItems = [...props.items]
    newItems.splice(draggingIndex, 1)
    newItems.splice(overIndex, 0, draggingItem)

    for (let i = 0; i < newItems.length; i++) {
      newItems[i].index = i
    }

    props.onListReordered(newItems)
  }

  return <Box ref={containerRef} sx={props.sx}>
    {props.items.map(i => {
      return <DraggableItem
        key={i.index}
        item={i}
        draggingItemIndex={draggingItem?.index ?? null}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDrop={handleDrop}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      />
    })}
  </Box>
}
