import { type EnrollmentStatus, type sessionBlock, type program, type lookup } from '../../../core/types'
import { EnrollmentList } from './EnrollmentList'

export interface exitedListProps {
  enrollmentStatus: EnrollmentStatus
  sessionBlockList: sessionBlock[]
  sessionList: lookup[]
  programs: program[]
  facilityList: lookup[]
  exitReasons?: lookup[]
  onEnrollmentChanged: () => void
}

export function Exitedlist (props: exitedListProps): JSX.Element {
  return (<>
    <EnrollmentList
      enrollmentStatus={props.enrollmentStatus}
      facilityList={props.facilityList}
      programs={props.programs}
      sessionBlockList={props.sessionBlockList}
      sessionList={props.sessionList}
      exitReasons={props.exitReasons}
      showExitFilter={true}
      onEnrollmentChanged={props.onEnrollmentChanged}
    />
  </>)
}
