/* eslint-disable @typescript-eslint/explicit-function-return-type */

export async function sendPostFormData (request: string, data: FormData) {
  const { response: rsp, success, error } = await sendRequest(request, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*'
    },
    body: data
  })
  return { response: rsp, error, success }
}

export async function sendPost (request: string, data: object) {
  const { response: rsp, success, error } = await sendRequest(request, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    body: JSON.stringify(data)
  })
  return { response: rsp, error, success }
}

export async function sendGet (request: string, data: object): Promise<{ response: any, error: string[], success: boolean, status?: number }> {
  const { response: rsp, success, error, status } = await sendRequest(request, {
    method: 'GET',
    credentials: 'include'
  })
  return { response: rsp, error, success, status }
}

export async function sendPut (request: string, data: object) {
  const { response: rsp, success, error } = await sendRequest(request, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(data)
  })
  return { response: rsp, error, success }
}

export async function sendDelete (request: string) {
  const { response, success, error } = await sendRequest(request, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    credentials: 'include'
  })
  return { response, error, success }
}

async function sendRequest (request: string, init?: RequestInit) {
  const abortController = new AbortController()
  try {
    const url = (process.env.REACT_APP_SERVER_URL ?? '') + request
    const response = await fetch(url, {
      signal: abortController.signal,
      ...init
    })
    let rsp = null
    // Ensure we don't try to parse an empty response
    if (response.status !== 204 && (response.headers.get('Content-Type')?.includes('application/json') ?? false)) {
      rsp = await response.json()
    }

    let errors: string[] = ['']
    if (!response.ok) {
      if (rsp === null) {
        rsp = response
      } else {
        const errs = rsp?.errors
        const values = Object.keys(errs).map(k => errs[k][0])
        errors = values
      }
    }

    return { response: rsp, error: errors, success: response.ok, status: response.status }
  } catch (e) {
    if (!isAbortError(e)) {
      const error: Error = e as Error
      return { response: null, error: [error.toString()], success: false, status: 500 }
    }
  }
  return { response: null, error: [''], success: false }
}

function isAbortError (error: any): error is DOMException {
  if (error !== null && error.name === 'AbortError') {
    return true
  }
  return false
}
