import { Modal } from '../../../Components/Modal'
import { ServiceProviderCardDisplay } from '../../../Components/Cards/ServiceProviderCard/ServiceProviderCardDisplay'
import { type serviceProvider } from '../../../core/types'

interface DupeModalProps {
  open: boolean
  data: serviceProvider | null
  onConfirm: () => void
  onCancel: () => void
}
export function DupeModal (props: DupeModalProps): JSX.Element {
  const content = props.data !== null ? <ServiceProviderCardDisplay serviceProvider={props.data} /> : <></>
  const text = <>This information matches to a service provider already in the system. Would you like to continue with the following service provider?
    {content}
  </>

  return <Modal
    onConfirm={props.onConfirm}
    open={props.open}
    confirmationContent={text}
    onClose={props.onCancel}
    cancelButtonText='No'
    confirmButtonText='Yes, Continue'
  />
}
