import { Box, Button } from '@mui/material'
import { LabelWithToolTip } from '../../Label/Index'
import { type ChildEditValues, type ChildSiblings } from '../../../core/types'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { SiblingCardDisplay } from '../SiblingCard/SiblingCardDisplay'

interface MultiCardListProps {
  label: string
  toolTip?: string
}

export function SiblingAddList (props: MultiCardListProps): JSX.Element {
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const newSibling: ChildSiblings = { id: 0, isLivingWithStudent: true, childLocations: [{}] } as ChildSiblings

  const onDeleteClick = (index: number): void => {
    const tempArray = [...data.siblings]
    tempArray.splice(tempArray.findIndex(v => v.index === index), 1)
    dispatch({ type: 'siblings', siblings: tempArray })
  }

  const addSection = (): void => {
    const tempArray = [...data.siblings, { index: data.siblingIndex, sibling: newSibling, edit: true, isNew: true, isLoadedRecord: false }]
    dispatch({ type: 'index', field: 'siblings' })
    dispatch({ type: 'siblings', siblings: tempArray })
  }

  const handleEditChange = (index: number, edit: boolean): void => {
    const tempArray = [...data.siblings]
    const siblingData = tempArray.find(v => v.index === index)
    if (siblingData != null) {
      siblingData.edit = edit
      siblingData.isNew = false
      dispatch({ type: 'siblings', siblings: tempArray })
    }
  }

  const handleUpdate = (index: number, sibling: ChildEditValues): void => {
    const tempArray = [...data.siblings]
    const siblingData = tempArray.find(v => v.index === index)
    if (siblingData != null) {
      siblingData.sibling = sibling as ChildSiblings
      siblingData.edit = false
      siblingData.isNew = false
      dispatch({ type: 'siblings', siblings: tempArray })
    }
  }

  return (
    <Box>
      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          isInline={false}
          required={false}
          name={''}
          labelText={props.label}
          toolTipText={props.toolTip}
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />
        {
          data.errors.filter(e => e.group === 'Sibling').map((val) => {
            const loc = <div key={val.field}>{val.error}</div>
            return loc
          })
        }
        {
          data.siblings.map((val) => {
            const card = <SiblingCardDisplay
              originalChildName={`${data.child.firstName} ${data.child.lastName}`}
              index={val.index}
              sibling={val.sibling}
              edit={val.edit}
              setEdit={handleEditChange}
              onSubmit={handleUpdate}
              onDelete={onDeleteClick}
            />
            return card
          })
        }
      </Box>
      <div className="d-flex f-justify-content-center">
        <Button size="small" variant="text" sx={{ color: '#595959', textTransform: 'none' }}
          onClick={e => { addSection() }}
        >
          Add {props.label} +
        </Button>
      </div>
    </Box>
  )
}
