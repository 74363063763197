import { Box, Typography, Button, Grid } from '@mui/material'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'

export interface ChildBornOrNotProps {
  onAnswered: () => void
}

export function ChildBornOrNot (props: ChildBornOrNotProps): JSX.Element {
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()

  const addChild = (isUnborn: boolean): void => {
    let child = { ...data.child }
    child = { ...child, isUnborn }
    dispatch({ type: 'child', child })

    props.onAnswered()
  }

  return <Box width={'100%'}>
    <Typography variant="h6">Has this child been born?</Typography>
    <Grid container spacing={2}>
      <Grid item>
        <Button variant='contained' color='secondary' data-testid='childBorn' onClick={() => { addChild(false) }}>Yes</Button>
      </Grid>
      <Grid item>
        <Button variant='contained' color='secondary' data-testid='childUnborn' onClick={() => { addChild(true) }}>No</Button>
      </Grid>
    </Grid>
  </Box>
}
