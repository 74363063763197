import {
  type FormControlProps,
  InputLabel,
  Box,
  TextField,
  FormControl
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { type Dayjs } from 'dayjs'
import { ErrorDisplay } from '../../ContextProviders/ErrorProvider'

interface Props {
  value: Date | null
  onChange: (newValue: Date | null) => void
  onBlur?: (e: any) => void
  name: string
  label?: string
  labelContent?: JSX.Element
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  cypressMarker?: string
  dataTestId?: string
  noPaddingTop?: boolean
}

export function DatePickerWithLabel (props: Props): JSX.Element {
  const { value, name, label, labelContent, onChange } = props
  function handleChange (newValue: Dayjs | null): void {
    if (newValue == null) {
      onChange(null)
    } else {
      const newDate = new Date(newValue.year(), newValue.month(), newValue.date())
      onChange(newDate)
    }
  }
  const cypressObject = {
    [props.cypressMarker ?? 'data-test-default-date-picker']: true
  }
  return (
    <>
      <ErrorDisplay fieldName={props.name} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: props.noPaddingTop === true ? 0 : '8px' }}>
        <InputLabel id={name} sx={{ color: 'black' }} required={props.required === true && props.showRequiredText !== true}>
          {labelContent ?? label}
        </InputLabel>
        {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
        {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
      </Box>
      <Box>
        <FormControl id={`${name}FormControl`} aria-labelledby={name} sx={{ pt: 0, pb: 1 }} {...props.FormControlProps}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disableOpenPicker={props.disabled}
              disabled={props.disabled}
              value={value}
              onChange={handleChange}
              renderInput={(inputProps) => (
                <TextField
                  name={name}
                  sx={{ width: '100%' }}
                  required={props.required}
                  {...inputProps}
                  error={props.error}
                  {...cypressObject}
                  onBlur={props.onBlur ?? (() => {})}
                  data-testid={props.dataTestId}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
    </>
  )
}
