import { Box, Grid } from '@mui/material'
import { RadiusCard } from '../Cards/Radius/RadiusCard'

interface CaregiverDisplayProps {
  relationshipName: string
  onDocumentClick: () => void
  accessType: string
  isLivingWithStudent: boolean
  studentName: string
  caregiverName: string
  onDelete: () => void
  onEdit: () => void
  hideDocumentLink?: boolean
}

export function CaregiverDisplay (props: CaregiverDisplayProps): JSX.Element {
  const content = <>
    <Grid item xs={6} p={0}>
      <Box>{props.relationshipName}</Box>
    </Grid>
    <Grid item xs={12}>
      <Box>{props.accessType}</Box>
    </Grid>
    <Grid item xs={12}>
      {props.isLivingWithStudent ? `Lives with ${props.studentName}` : `Does not live with ${props.studentName}`}
    </Grid>
  </>

  return <RadiusCard
    onModalConfirm={props.onDelete}
    onEditMenu={props.onEdit}
    confirmationMessage={'Are you sure you want to remove this caregiver from this child?  Doing so will revoke all of their access.'}
    showMenu={true}
    header={props.caregiverName}
    content={content}
    fullWidth={true}
    dataTestId='caregiver-card'
  />
}
