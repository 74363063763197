import { Box, CircularProgress, Grid, IconButton, Paper } from '@mui/material'
import { LargeCard } from '../../../Components/Cards/Large'
import { LabelWithToolTip } from '../../../Components/Label/Index'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import S3Image from '../../../Components/S3Image'
import { DetailsEdit } from './DetailsEdit'
import EditIcon from '@mui/icons-material/Edit'
import { type location } from '../../../core/types'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'

export interface SpDetails {
  id: number
  name: string
  eemCode: string
  website: string
  iconID?: number
  contactEmail: string
  contactPhone: string
  locationID?: number
  imageKey: string | null
}

export function ServiceProviderDetailsView (): JSX.Element {
  const activeAccount = useAccountId()
  const id = activeAccount?.id ?? ''
  const [data, setData] = useState<SpDetails>({
    id: 0,
    eemCode: '',
    contactEmail: '',
    name: '',
    contactPhone: '',
    website: '',
    locationID: 0,
    imageKey: ''
  })

  const [loc, setLoc] = useState<location>({
    address: '',
    apartment: '',
    city: '',
    state: '',
    zipCode: '',
    id: 0,
    isPrimary: false,
    note: ''
  })
  const [showModal, setShowModal] = useState(false)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const loadDetails = async (): Promise<void> => {
      const { response: rsp } = await sendGet(`/ServiceProviderDetails/Get?id=${id}`, { })
      if (rsp !== null) {
        const locString: string | null = rsp.locationID?.toString()
        if (locString !== null) {
          const { response: locResponse } = await sendGet(`/ServiceProviderDetails/GetLocation?id=${locString}`, {})
          setLoc(locResponse)
        } else {
          setLoc({ address: '', apartment: '', city: '', state: '', zipCode: '', id: 0, isPrimary: false, note: '' })
        }
        setData({
          id: rsp.id,
          eemCode: rsp.eemCode,
          contactEmail: rsp.contactEmail,
          name: rsp.name,
          contactPhone: rsp.contactPhone,
          website: rsp.website,
          locationID: rsp.locationID,
          iconID: rsp.iconID,
          imageKey: rsp.imageKey
        })
      }
      setLoaded(true)
    }
    void loadDetails()
  }, [id, refreshTime])

  if (id === '') { return <></> }
  if (!loaded) { return <CircularProgress role='loading' /> }

  return <Paper sx={{ pt: 2, borderRadius: '32px', marginTop: '20px' }}>
    <ErrorProvider>
      <DetailsEdit
        details={data}
        location={loc}
        onSave={() => { setRefreshTime(new Date()); setShowModal(false) }}
        onClose={() => { setShowModal(false) }}
        show={showModal}
      />
    </ErrorProvider>

    <Grid container sx={{ display: 'flex', flexDirection: 'row' }} rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <IconButton data-testid="editButton" onClick={() => { setShowModal(true) }} >
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item sm={12} md={6} >
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columnSpacing={1} rowSpacing={1}>
          <Grid item sm={12} md={12}>
            <LargeCard
              fullWidth
              header='Name & Identity'
              className='Borderless'
              content={<Box>
                <TextWithLabel label='Name' data-testid='sp-name' value={data?.name ?? ''} name='name'/>
                <TextWithLabel label='EEM Code' data-testid='sp-eem' value={data?.eemCode ?? ''} name='eemCode' />
              </Box>}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <LargeCard
              fullWidth
              header='Information'
              className='Borderless'
              content={<Box>
                <TextWithLabel label='Website' value={data?.website ?? ''} name='website'/>
                <LabelWithToolTip labelText='Logo' />
                {data.imageKey != null && <S3Image style={{ maxWidth: '100px', maxHeight: '100px' }} imageKey={data.imageKey ?? ''} alt='logo' />}
              </Box>}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columns={12} columnSpacing={1} rowSpacing={1}>
          <Grid item sm={12} md={12}>
            <LargeCard
              fullWidth
              header='Contact'
              className='Borderless'
              content={ <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextWithLabel label='Email' value={data?.contactEmail ?? ''} name='contactEmail' />
                <TextWithLabel label='Phone' value={data?.contactPhone ?? ''} name='contactPhone' />
              </Box>}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
}
