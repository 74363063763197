interface Props {
  imageKey: string
  alt?: string
  style?: React.CSSProperties
  className?: string
}

export default function S3Image (props: Props): JSX.Element {
  return (
    <img
      src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${props.imageKey}`}
      alt={props.alt ?? 'image'}
      style={props.style}
      className={props.className}
      height={props.style?.height ?? '100px'}
      width={props.style?.width ?? '100px'}
    />
  )
}
