import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from '@mui/material'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'
import { TabDisplay } from '../../../Components/TabDisplay'
import { useState } from 'react'
import { ServiceProviderDetailsView } from './DetailsView'
import { ServiceList } from './ServicesList'
import { ProgramList } from './ProgramList'
import { SERVICE_PROVIDER_ADMIN_SETTINGS } from '../../Routes'
import { useNavigate } from 'react-router'

export function ProviderSettingsTabs (): JSX.Element {
  const [value, setValue] = useState(0)
  const nav = useNavigate()

  return <Box sx={{ width: '100%' }}>
    <Typography variant="h3">Provider Details</Typography>

    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ margin: '20px' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(SERVICE_PROVIDER_ADMIN_SETTINGS) }} >
        Service Provider Settings
      </Link>
    </Breadcrumbs>

    <ErrorProvider>
      <>
        <Tabs value={value} onChange={(e, val) => { setValue(val) }} aria-label="Session Tabs" data-testid="tabsHeading"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Details" data-testid="tab-details" value={0}/>
          <Tab label="Services" data-testid="tab-services" value={1}/>
          <Tab label="Programs" data-testid="tab-program" value={2}/>
        </Tabs>
        <TabDisplay index={0} value={value}>
          <ServiceProviderDetailsView />
        </TabDisplay>
        <TabDisplay index={1} value={value}>
          <ServiceList />
        </TabDisplay>
        <TabDisplay index={2} value={value}>
          <ProgramList />
        </TabDisplay>
      </>
    </ErrorProvider>
  </Box>
}
