import { Button, Box } from '@mui/material'

export interface BlockButtonProps {
  backgroundColor: string
  icon: JSX.Element
  onClick: () => void
  text: string
  testId?: string
  marginOverride?: string
}

export function BlockButton (props: BlockButtonProps): JSX.Element {
  const styleProps = {
    width: '250px',
    height: '250px',
    borderRadius: '32px',
    backgroundColor: '#eaedee',
    padding: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: '0px 30px 20px -30px rgba(3, 28, 50, 0.25)',
    margin: ''
  }
  if (props.marginOverride !== undefined && props.marginOverride !== null) {
    styleProps.margin = props.marginOverride
  }
  return (<>
    <Button
      data-testid={props.testId}
      sx={styleProps}
      onClick={props.onClick}
    >
      <Box sx = {{
        display: 'flex',
        flexDirection: 'column'
      }}
      >

        <Box sx={{ position: 'relative' }}>
          <div style={{
            backgroundColor: props.backgroundColor,
            borderRadius: '50%'
          }}
          >
            {props.icon}
          </div>
        </Box>
        <Box>

          <Box sx={{ color: 'black' }}>
            {props.text}
          </Box>
        </Box>
      </Box>

    </Button>
  </>)
}
