interface jpgProps {
  fill?: string
}

export function VerifiedCheck (props: jpgProps): JSX.Element {
  const fill = props.fill ?? '#02AED9'
  return <>
    <svg width="56" height="36" viewBox="0 0 56 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="36" rx="18" fill={fill} />
      <path d="M26 22.4L22 18.4L23.4 17L26 19.6L32.6 13L34 14.4L26 22.4Z" fill="#FBFBFB" />
    </svg>
  </>
}
