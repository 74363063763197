import { Box, Typography } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TextFieldWithLabel } from '../../Components/TextField'
import { type fileType } from '../../core/types'
import { useState } from 'react'
import { CheckBoxWithLabel } from '../../Components/CheckBox'
import FilePicker from '../../Components/FilePicker'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { TextAreaWithLabel } from '../../Components/TextArea'

export interface FileTypeDetailsProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: fileType, file: File | null) => void
  fileType: fileType
  errors?: string[] | undefined
}

export function FileTypeDetails (props: FileTypeDetailsProps): JSX.Element {
  const [fileType, setFileType] = useState(props.fileType)
  const [file, setFile] = useState<File>()
  const title = 'Document Type Details'
  const status = [{ id: 'Adult', name: 'Adult' }, { id: 'Child', name: 'Child' }, { id: 'Both', name: 'Both' }]

  const handleClose = (): void => {
    props.onClose()
  }

  const handleChange = (e: any): void => {
    setFileType({ ...fileType, [e.target.name]: e.target.value })
  }

  const handleSubmit = (): void => {
    if (file !== undefined) {
      props.onSubmit(fileType, file)
    } else {
      props.onSubmit(fileType, null)
    }
  }

  const content = <Box>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    <Box sx={{ boxSizing: 'border-box', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
      <Box>
        <TextFieldWithLabel
          label='Name'
          name='name'
          onChange={handleChange}
          value={fileType.name}
          dataTestId='name'
        />
      </Box>
      <Box>
        <SelectWithLabel
          label='Document Target'
          name='documentTarget'
          onChange={handleChange}
          value={fileType.documentTarget}
          options={status}
          width='240px'
        />
      </Box>
      <Box>
        <CheckBoxWithLabel
          edge='start'
          label='Income Related'
          name='incomeRelated'
          setValue={(newValue) => { setFileType({ ...fileType, incomeRelated: newValue }) }}
          value={fileType.incomeRelated}
        />
      </Box>
      <Box>
        <CheckBoxWithLabel
          edge='start'
          label='Inactive'
          name='inactive'
          setValue={(newValue) => { setFileType({ ...fileType, inactive: newValue }) }}
          value={fileType.inactive}
        />
      </Box>
      <Box>
        {file != null && <Box>
          <Typography>Uploading {file.name}</Typography>
        </Box>}

        <FilePicker
          label='Choose an image'
          name='file'
          onSelect={(e: any) => {
            setFile(e.target.files[0])
          }}
        />
        {fileType.s3Key !== null && (
          <img
            src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${fileType.s3Key}`}
            alt='logo'
            data-testid={'logo-' + (fileType.s3Key)}
            style={{ maxWidth: '100px' }}
          />)}
      </Box>
      <Box>
        <TextAreaWithLabel
          label='Description'
          name='description'
          onChange={handleChange}
          value={fileType.description}
          dataTestId='description'
        />
      </Box>
    </Box>
  </Box>

  return <Modal
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
