/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { AcceptDenyModal } from '../../Components/Modal/AcceptDenyModal'
import { Box, CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { type DenyReferralRequest, type postError, type AcceptReferralRequest, type DocumentTypeRequirement, type ChildEditValues } from '../../core/types'
import { toast } from 'react-toastify'
import { ReferralAcceptModal } from './ReferralAcceptModal'
import { ReferralDenyModal } from './ReferralDenyModal'
import { ReferralView } from '../../Components/ChildDetails/ReferralView'
import { ReferralEditModal } from './ReferralEditModal'
import { type childServiceSettings } from '../../ContextProviders/AddChildProvider'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { ReferralHouseholdMembersModal } from './ReferralHouseholdMembersModal'

export enum ReferralAction {
  Accept,
  Deny
}

export interface ReferralDetailProps {
  hideIncomeReapply?: boolean
  referralID: number
  childID: number
  open: boolean
  manualReferEnabled: boolean
  serviceID: number
  onClose: () => void
  onRefresh: () => void
  hideAccept?: boolean
  hideDeny?: boolean
}

export interface ReferralData {
  id: number
  isdid: number
  childID: number
  childName: string
  statedIncome: number | null
  verifiedIncome: number
  householdMemberCount: number | null
  incomeVerified: boolean
  povertyPercent: number | null
  povertyPercentExceedsCutoff: boolean
  documentsSubmittedAndVerified: boolean | null
  desiredSchedule: string
  timePeriod: string
  timePeriodDisplay: string
  facilityName: string
  facilityId?: number
  systemServiceName: string
  referralID?: number | null
  systemServiceId: number
  serviceId: number
  programName: string | null
  programId: number | null
  notes: string | null
  serviceProviderID: number
  systemServiceProviderID: number
  serviceProviderName: string
  incomeApproved: string
  reportURL: string
  sessionBlockName: string | null
  sessionBlockId: number | null
  sessionBlockYears: number[]
  childDocumentTypeRequirements?: DocumentTypeRequirement[]
  caregiverDocumentTypeRequirements?: DocumentTypeRequirement[]
}

export function ReferralDetail (props: ReferralDetailProps): JSX.Element {
  const account = useAccountId()
  const [referral, setReferral] = useState<ReferralData>()
  const [loaded, setLoaded] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [refresh, setRefresh] = useState(new Date())
  const [existingReferrals, setExistingReferrals] = useState([])

  async function getData (): Promise<void> {
    const request = await sendGet(`/Waiver/GetReferralDetail?id=${props.referralID}`, {})
    const referral: ReferralData = request.response
    setReferral(referral)
    const { response, success } = await sendGet(`/ChildDetails/GetAllServices?id=${props.childID}`, {})
    if (success) {
      setExistingReferrals(response.response)
    }

    setLoaded(true)
  }

  useEffect(() => {
    void getData()
  }, [refresh])

  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [denialErrors, setDenialErrors] = useState<string[]>([])
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditHouseholdMembersModal, setShowEditHouseholdMembersModal] = useState(false)
  const [showCreateNewReferral, setShowCreateNewReferral] = useState(false)

  const handleClose = (): void => {
    props.onClose()
  }

  const handleAccept = (): void => {
    if (referral !== undefined && referral.householdMemberCount === null && referral.incomeApproved !== 'NotApplicable') {
      toast.error('Please update the household member count before accepting the referral.')
    } else {
      setShowAcceptModal(true)
    }
  }

  const printDetails = (): void => {
    window.open(referral?.reportURL, '_blank')?.focus()
  }

  const handleDenyRequest = async (denyRequest: DenyReferralRequest): Promise<void> => {
    if (denyRequest !== undefined && denyRequest.referralId > 0) {
      if (!isRequestLoading) {
        setIsRequestLoading(true)
        const { response } = await sendPost('/ReferralEdit/DenyReferral', denyRequest)
        if (response !== undefined) {
          if (response.newRecordID === props.referralID) {
            setShowDenyModal(false)
            const name: string = response.message.toString()
            if (name === '') {
              toast.success('Referral was denied')
            } else {
              toast.success(`Referral was denied and sent to ${name}`)
            }
            props.onRefresh()
            props.onClose()
          } else {
            setDenialErrors(response.errors)
            response.errors.forEach((e: postError) => toast.error(e.error))
          }
        }
        setIsRequestLoading(false)
      }
    }
  }

  const handleAcceptRequest = async (acceptRequest: AcceptReferralRequest): Promise<void> => {
    if (acceptRequest !== undefined && acceptRequest.referralId > 0) {
      if (!isRequestLoading) {
        setIsRequestLoading(true)
        const result = await sendPost('/ReferralEdit/AcceptReferral', acceptRequest)
        const rsp: { newRecordID: number, success: boolean, errors: postError[] } = result.response
        if (rsp !== undefined) {
          if (rsp.success) {
            setShowAcceptModal(false)
            if (rsp.newRecordID === 0) {
              toast.success('An Income Approval was created that will have to be approved for the enrollment to be accepted.')
            } else {
              toast.success('The enrollment was accepted.')
            }
            props.onClose()
            props.onRefresh()
          } else {
            setErrors(rsp.errors.map((e: postError) => e.field))
          }
        }
        setIsRequestLoading(false)
      }
    }
  }

  const handleDeny = (): void => {
    setShowDenyModal(true)
  }

  const detailsChanged = (): void => {
    setRefresh(new Date())
  }

  const handleUpdateHouseholdMemberCount = async (newHouseholdMemberCount: number | null): Promise<boolean> => {
    if (!isRequestLoading) {
      const submitValues = {
        ReferralID: referral?.id,
        HouseholdMemberCount: newHouseholdMemberCount
      }

      setIsRequestLoading(true)
      const { response } = await sendPost('/ReferralEdit/EditReferralHouseholdMemberCount', submitValues)
      const rsp: { success: boolean, response: any, errors: postError[] } = response

      if (rsp !== undefined) {
        if (rsp.success) {
          setShowEditModal(false)
          toast.success('Referral Updated')
          setRefresh(new Date())
          props.onRefresh()
          setShowEditHouseholdMembersModal(false)
        } else {
          rsp.errors.forEach((e: postError) => toast.error(e.error))
          setErrors(rsp.errors.map((e: postError) => e.field))
        }
      }
      setIsRequestLoading(false)
      return rsp.success
    }
    return false
  }

  const handleSubmitNew = async (settings: childServiceSettings): Promise<boolean> => {
    const newReferral = {
      child: { id: referral?.childID } as ChildEditValues,
      serviceSettings: settings,
      districtSettings: { isdid: referral?.isdid }
    }

    const { response, success, error } = await sendPost('/ReferralEdit/CreateServiceReferral', newReferral)
    if (response != null) {
      if (success) {
        toast.success('New referral created')
        setShowCreateNewReferral(false)
        props.onClose()
        props.onRefresh()
        return true
      } else {
        toast.error(error.join(','))
      }
    }

    return false
  }
  const handleUpdate = async (settings: childServiceSettings): Promise<boolean> => {
    if (!isRequestLoading) {
      const submitValues = {
        ReferralID: settings.referralId,
        FacilityID: settings.facilityId,
        TimeFrame: settings.timeFrame,
        CustomDate: settings.customDate,
        DesiredSchedule: settings.desiredSchedule,
        ServiceId: settings.systemService?.serviceId,
        ProgramId: settings.systemProgram?.programId,
        Notes: settings.notes,
        SessionBlockID: settings.sessionBlockId
      }
      setIsRequestLoading(true)
      const { response } = await sendPost('/ReferralEdit/EditReferral', submitValues)
      const rsp: { success: boolean, response: any, errors: postError[] } = response
      if (rsp !== undefined) {
        if (rsp.success) {
          setShowEditModal(false)
          toast.success('Referral Updated')
          setRefresh(new Date())
          props.onRefresh()
        } else {
          rsp.errors.forEach((e: postError) => toast.error(e.error))
          setErrors(rsp.errors.map((e: postError) => e.field))
        }
      }
      setIsRequestLoading(false)
      return rsp.success
    }
    return false
  }

  if (!loaded) return <CircularProgress role='loading' />
  else if (referral == null) return <div>Record not found</div>
  else {
    const content = <Box>
      <ReferralView
        hideIncomeReapply={props.hideIncomeReapply}
        referral={referral}
        hideEditButtons={false}
        onPrint={printDetails}
        onRefresh={detailsChanged}
        onEdit={setShowEditModal}
        onCreateNew={setShowCreateNewReferral}
        onEditHouseholdMembers={setShowEditHouseholdMembersModal}
        type='referral'
      />
      {showCreateNewReferral && <ReferralEditModal
        open={true} referral={{ childID: referral.childID, isdid: referral.isdid } as ReferralData}
        onSubmit={handleSubmitNew}
        onClose={() => { setShowCreateNewReferral(false) }}
        status={'Pending'}
        isNew={true}
        existingReferrals={existingReferrals}
      />}
      {showEditModal && <ReferralEditModal
        isNew={false}
        open={true}
        referral={referral}
        onSubmit={handleUpdate}
        onClose={() => { setShowEditModal(false) }}
        status='Pending'
      />
      }
      {showEditHouseholdMembersModal && <ReferralHouseholdMembersModal
        open={true}
        householdMemberCount={referral.householdMemberCount}
        onSubmit={handleUpdateHouseholdMemberCount}
        onClose={() => { setShowEditHouseholdMembersModal(false) }}
      />
      }
      <ReferralAcceptModal
        in={showAcceptModal}
        documentStatus={referral.documentsSubmittedAndVerified}
        facilityId={referral.facilityId ?? null}
        onClose={() => { setShowAcceptModal(false) }}
        onConfirm={handleAcceptRequest}
        referralId={props.referralID}
        isdid={referral.isdid}
        programid={referral.programId}
        sessionBlockId={referral.sessionBlockId}
        serviceid={props.serviceID}
        spid={account?.id ?? 0}
        serviceProviderName={referral.serviceProviderName}
        errors={errors}
        incomeRelated={referral.incomeApproved !== 'NotApplicable'}
      />
      <ReferralDenyModal
        manualReferEnabled={props.manualReferEnabled}
        referral={referral}
        in={showDenyModal}
        close={() => { setShowDenyModal(false) }}
        confirm={handleDenyRequest}
        errors={denialErrors}
      />
    </Box>

    const acceptFunction = (props.hideAccept ?? false) ? undefined : handleAccept
    const denyFunction = (props.hideDeny ?? false) ? undefined : handleDeny

    return <AcceptDenyModal
      className='Modal Referral'
      open={props.open}
      title='Student Information'
      dialogContent={content}
      onClose={handleClose}
      onAccept={acceptFunction}
      onDeny={denyFunction}
      maxWidth={'sm'}
      data-testid='referral-details-modal'
    />
  }
}
