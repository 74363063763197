import { Box, Typography, Grid } from '@mui/material'
import { SelectWithLabel } from '../../../../Components/SelectWithLabel'
import { TextAreaWithLabel } from '../../../../Components/TextArea'
import { type ChangeEvent, useState } from 'react'
import { BannerModal } from '../../../../Components/Modal/BannerModal'
import { type carenetworkService, type lookup } from '../../../../core/types'

interface CareNetworkServiceExitProps {
  service: carenetworkService
  cancelReasons: lookup[]
  open: boolean
  onClose: () => void
  onConfirm: (cancelRequest: CancelRequestModel) => void
}

export interface CancelRequestModel {
  reasonId: number
  note: string
  referralId: number | null
  enrollmentId: number | null
}

export function CareNetworkServiceExit (props: CareNetworkServiceExitProps): JSX.Element {
  const initialCancelRequestModel: CancelRequestModel = { reasonId: 0, note: '', referralId: props.service.referralId, enrollmentId: props.service.enrollmentID }
  const [cancelRequest, setCancelRequest] = useState<CancelRequestModel>(initialCancelRequestModel)

  const handleNoteChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (cancelRequest !== undefined) {
      setCancelRequest({ ...cancelRequest, note: e.target.value })
    }
  }

  const content = <Box><Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>
    Please confirm you would like to cancel this service request</Typography>
    <Grid container alignItems={'center'} width={'100%'} >
      <Grid item width={'100%'}>
        <SelectWithLabel
          name="exitReason"
          label="Reason"
          width="100%"
          value={cancelRequest?.reasonId ?? 0}
          options={props.cancelReasons}
          onChange={(e) => {
            if (cancelRequest !== undefined) {
              setCancelRequest({ ...cancelRequest, reasonId: Number(e.target.value) })
            }
          }}
        />
      </Grid>
      <Grid item width={'100%'}>
        <TextAreaWithLabel
          name="Note"
          label="Notes"
          data-testid="notes"
          value={cancelRequest?.note}
          onChange={handleNoteChange}
        />
      </Grid>
    </Grid>
  </Box>

  return <BannerModal
    open={props.open}
    content={content}
    onClose={props.onClose}
    onConfirm={() => { props.onConfirm(cancelRequest) }}
    title='Cancel Service'
    confirmButtonText='Yes, Continue'
  />
}
