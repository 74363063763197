import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

export interface SessionHeaderProps {
  title: string
  cards: JSX.Element
  breadCrumbs: BreadCrumbData[]
}

export interface BreadCrumbData {
  text: string
  path: string
}

export function SessionHeader (props: SessionHeaderProps): JSX.Element {
  const nav = useNavigate()
  return <Box key={'sessionHeader'}>
    <Typography variant="h3" marginBottom="20px">{props.title}</Typography>
    <Breadcrumbs aria-label="breadcrumb" data-testid="breadcrumbList">
      {props.breadCrumbs.map(b => {
        return <Link
          key={b.text}
          sx={{ cursor: 'pointer' }}
          underline="hover"
          color="inherit"
          onClick={() => { nav(b.path) }}
          data-testid="breadcrumbLink"
        >
          {b.text}
        </Link>
      })}
    </Breadcrumbs>

    <Box marginTop="20px" marginBottom="20px">{props.cards}</Box>
  </Box>
}
