import { MoreVert, CheckCircleRounded, DeleteOutlineRounded, DownloadForOfflineRounded, UndoRounded, EditRounded, OpenInBrowserRounded } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import { LargeCard } from '../Cards/Large'
import { type documentEditModel } from '.'
import { FormatDateTimeValue, FormatDateValue, FormatCurrency } from '../../core/Utilities'
import { useState } from 'react'

interface DocumentDisplayProps {
  document: documentEditModel
  canVerify: boolean
  canEdit: boolean
  onEdit?: () => void
  onDelete: () => void
  onVerifyChanged: (verified: boolean) => void
  onDownload?: (id: number) => void
  deleteOptionText?: string
}

export function DocumentDisplay (props: DocumentDisplayProps): JSX.Element {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [showVerify, setShowVerify] = useState(true)

  const fileExtenstion = props.document.fileName.split('.').pop()?.toLowerCase()
  // the extensions that can be opened in the browser
  const canOpen = fileExtenstion === 'pdf' || fileExtenstion === 'jpg' || fileExtenstion === 'jpeg' || fileExtenstion === 'png'

  const noReturn = (): void => { }

  const openDocument = (): void => {
    const url = `${process.env.REACT_APP_SERVER_URL ?? ''}/Document/OpenFile/${props.document.id.toString()}`
    window.open(url, '_blank')
  }

  return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <LargeCard header={''} className='mt-5 card-button'
      content={
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box>
            <Box>{props.document.fileName}</Box>
            <Box>{FormatDateValue(props.document.whenUploaded)}</Box>
          </Box>
          <Box>
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setShowVerify(!props.document.verified)
              setMenuAnchor(event.currentTarget)
            }}
            >
              <MoreVert />
            </Button>
          </Box>
        </Box>
      }
    />
    {
      props.document.verified && (
        <table>
          <tbody>
            <tr>
              <td>
                <CheckCircleRounded className='mr-5' htmlColor='#02AED9' />
              </td>
              <td>
                <div>Verified by {props.document.verifiedBy} </div>
                <div>{FormatDateTimeValue(props.document.verifiedDate)} </div>
                {props.document.caregivers?.map((value, i) => {
                  return <div>{value}</div>
                })}
                {props.document.documentIncome !== null && <div data-testid='incomeValue'>Documented Income: {FormatCurrency(props.document.documentIncome)}</div>}
              </td>
            </tr>
            <tr>
              <td colSpan={2} width='300px'>{props.document.verificationDetails}</td>
            </tr>

          </tbody>
        </table>
      )
    }
    < Menu
      open={menuAnchor !== null}
      onClose={() => { setMenuAnchor(null) }}
      anchorEl={menuAnchor}
    >
      {canOpen && <MenuItem key='Open' onClick={openDocument} >
        <OpenInBrowserRounded className='mr-5' /> Open
      </MenuItem>
      }
      {props.onDownload !== null && props.onDownload !== undefined && (
        <MenuItem key='Download' onClick={() => { props.onDownload !== undefined ? props.onDownload(props.document.id) : noReturn() }} >
          <DownloadForOfflineRounded className='mr-5' /> Download
        </MenuItem>
      )}
      {props.canVerify && showVerify && <MenuItem key='Verify' onClick={() => {
        props.onVerifyChanged(showVerify)
        setMenuAnchor(null)
      }}
      >
        <CheckCircleRounded className='mr-5' /> Verify
      </MenuItem>}
      {props.canVerify && !showVerify && <MenuItem key='Unverify' onClick={() => {
        props.onVerifyChanged(showVerify)
        setMenuAnchor(null)
      }}
      >
        <UndoRounded className='mr-5' /> Unverify
      </MenuItem>}
      {props.canEdit && props.onEdit !== undefined &&
        <MenuItem key='Edit' onClick={() => {
          setMenuAnchor(null)
          if (props.onEdit !== undefined) {
            props.onEdit()
          }
        }}
        >
          <EditRounded className='mr-5' />Edit
        </MenuItem>}
      {props.canEdit && <MenuItem key='Delete' onClick={() => {
        props.onDelete()
        setMenuAnchor(null)
      }}
      >
        <DeleteOutlineRounded className='mr-5' /> {props.deleteOptionText ?? 'Delete'}
      </MenuItem>}
    </Menu>
  </Box >
}
