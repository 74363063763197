import { createTheme, type Theme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true
  }
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}
declare module '@mui/material/styles' {

  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(113, 54, 188, 1)',
      light: 'rgba(113, 54, 188, 0.1)'
    },
    secondary: {
      main: '#7136BC'

    },
    neutral: {
      light: '#6aedf7',
      main: '#34afb8',
      dark: '#12757c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    }
  }
})
/// Certainly there is an easier way to do this for our custom variants, but i sure cant figure it out
export const mieccTheme: Theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100
        }
      }
    }
  }

})
