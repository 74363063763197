import { Box, Typography } from '@mui/material'
import { type IncomeApprovalSearchCriteria, IncomeApprovalSearchOptions } from './IncomeApprovalSearchOptions'
import { useEffect, useState } from 'react'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { sendGet } from '../../hooks/use-fetch'
import { IncomeApprovalDetail } from './IncomeApprovalDetail'
import { useAccountId } from '../../ContextProviders/CurrentAccount'

const childColumn = new Column('child', 'Child', ColumnType.CUSTOM)
childColumn.customCellGenerator = row => {
  const s3Key: string = row.s3Key
  let imgTag: JSX.Element
  if (s3Key != null) {
    imgTag = <img
      src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${s3Key}`}
      alt='Child image'
      style={{ maxWidth: '100px' }}
    />
  } else {
    imgTag = <span></span>
  }
  return <div>{imgTag} <span>{row.childName}</span></div>
}

const fplColumn = new Column('povertyPercent', 'FPL%', ColumnType.CUSTOM)
fplColumn.customCellGenerator = row => {
  return <>{row.povertyPercent}%</>
}
const providerColumn = new Column('serviceProviderName', 'Provider')
const requestedByColumn = new Column('requestingUserName', 'Requested By')
const eligibilityFactorColumn = new Column('factors', 'Factors', ColumnType.CUSTOM)
eligibilityFactorColumn.customCellGenerator = row => {
  return <ul className='tight-ul'>
    {row.eligibilityFactors.map((f: string) => {
      return <li>{f}</li>
    })}
  </ul>
}
const serviceColumn = new Column('serviceName', 'Service')

const serviceProviderIDColumn = new Column('serviceProviderID', 'ServiceProviderID')
serviceProviderIDColumn.visibility = false

const columns = [childColumn, fplColumn, providerColumn, requestedByColumn, eligibilityFactorColumn, serviceColumn, serviceProviderIDColumn]

export function IncomeApprovalList (): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [ias, setIAs] = useState<RowData[]>([])
  const [selectedRecordID, setSelectedRecordID] = useState<number | null>(null)
  const [open, setOpen] = useState(false)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [searchData, setSearchData] = useState<IncomeApprovalSearchCriteria>({
    approvalStatus: 'P',
    childName: '',
    userName: '',
    serviceProviderID: -1
  })

  const account = useAccountId()
  const header = String(account?.type) === 'Service Provider' ? 'Waiver' : 'Over Income'

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response, error } = await sendGet(`/IncomeApproval/GetList?id=${String(account?.id)}&accountType=${String(account?.type)}`, {})
      if (error[0] === '' && response !== null) {
        setIAs(response)
      }

      setLoading(false)
      handleSearch(searchData)
    }
    void loadData()
  }, [refreshTime])

  const handleSearch = (newdata: IncomeApprovalSearchCriteria): void => {
    setSearchData(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.approvalStatus !== null) {
      fieldArray.push({
        field: 'approvalStatus',
        value: newdata.approvalStatus
      })
    }
    if (newdata.childName !== '') {
      fieldArray.push({
        field: 'childName',
        value: newdata.childName
      })
    }
    if (newdata.userName !== '') {
      fieldArray.push({
        field: 'requestingUserName',
        value: newdata.userName
      })
    }
    if (newdata.serviceProviderID !== -1) {
      fieldArray.push({
        field: 'serviceProviderID',
        value: String(newdata.serviceProviderID)
      })
    }
    pagination.setSearchFields(fieldArray)
  }

  const paginationProps: PaginationProps<RowData> = {
    rows: ias,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedRecordID(row.id)
    setOpen(true)
  }

  return <>
    <Typography variant="h3">{header} Approval</Typography>
    <IncomeApprovalSearchOptions current={searchData} onChange={handleSearch} />
    <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
      <DataTable
        page={pagination.page}
        hasCheckbox={false}
        name='oiaTable'
        columns={columns}
        rows={pagination.internalRows}
        totalRecords={pagination.recordCount}
        loading={loading}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
        initialColumnSorts={[new ColumnSort('fullName')]}
      />
    </Box>
    {open && selectedRecordID !== null && (
      <IncomeApprovalDetail
        onClose={() => {
          setOpen(false)
          setRefreshTime(new Date())
        }}
        setRefreshTime={() => {
          setRefreshTime(new Date())
        }}
        incomeApprovalID={selectedRecordID}
        isWaiver={String(account?.type) === 'Service Provider'}
        open={open}
      />
    )}

  </>
}
