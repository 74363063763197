import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { Box, Typography } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { AccessRequestSearchOptions, type AccessRequestApprovalSearchCriteria } from './AccessRequestSearchOptions'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { FormatDateValue } from '../../core/Utilities'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { AccountType } from '../../core/types'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { AccessRequestReview } from './AccessRequestReview'

const requestedByColumn = new Column('userRequesting', 'Requested By')
const childNameColumn = new Column('childName', 'Child Name')
const requestDateColumn = new Column('requestDate', 'Request Date', ColumnType.CUSTOM)
requestDateColumn.customCellGenerator = row => {
  return <div>{FormatDateValue(row.requestDate)} </div>
}
const explanationColumn = new Column('explanation', 'Details')
const typeColumn = new Column('isTransfer', 'Request Type', ColumnType.CUSTOM)
typeColumn.customCellGenerator = row => {
  return <div>{row.isTransfer === true ? 'Transfer' : 'Access'} </div>
}

const statusColumn = new Column('approvedStatus', 'Status')
statusColumn.visibility = false

const columns = [requestedByColumn, childNameColumn, requestDateColumn, explanationColumn, typeColumn, statusColumn]

export function AccessRequestList (): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [selectedRecordID, setSelectedRecordID] = useState<number | null>(null)
  const [open, setOpen] = useState(false)
  const account = useAccountId()
  const [accessRequests, setAccessRequests] = useState<RowData[]>([])
  const [searchData, setSearchData] = useState<AccessRequestApprovalSearchCriteria>({
    approvalStatus: 'P',
    childName: '',
    userName: ''
  })

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      let queryString = ''
      if (account?.type === AccountType.ISD) {
        queryString = `isdID=${String(account?.id)}`
      } else if (account?.type === AccountType.SERVICE_PROVIDER || account?.type === AccountType.INTAKE_USER) {
        queryString = `serviceProviderID=${String(account?.id)}`
      }

      const { response, error } = await sendGet(`/AccessRequest/GetList?${queryString}`, {})
      if (error[0] === '' && response !== null) {
        setAccessRequests(response)
      }

      setLoading(false)
      handleSearch(searchData)
    }
    void loadData()
  }, [refreshTime])

  const handleSearch = (newdata: AccessRequestApprovalSearchCriteria): void => {
    setSearchData(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.approvalStatus !== null) {
      fieldArray.push({
        field: 'approvedStatus',
        value: newdata.approvalStatus
      })
    }
    if (newdata.childName !== '') {
      fieldArray.push({
        field: 'childName',
        value: newdata.childName
      })
    }
    if (newdata.userName !== '') {
      fieldArray.push({
        field: 'userRequesting',
        value: newdata.userName
      })
    }
    pagination.setSearchFields(fieldArray)
  }

  const paginationProps: PaginationProps<RowData> = {
    rows: accessRequests,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedRecordID(row.id)
    setOpen(true)
  }

  return <>
    <Typography variant="h3">Access Requests</Typography>
    <AccessRequestSearchOptions current={searchData} onChange={handleSearch} />
    <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
      <DataTable
        page={pagination.page}

        hasCheckbox={false}
        name='accessRequestTable'
        columns={columns}
        rows={pagination.internalRows}
        totalRecords={pagination.recordCount}
        loading={loading}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
        initialColumnSorts={[new ColumnSort('childName')]}
      />
    </Box>
    {open && selectedRecordID !== null && (
      <AccessRequestReview
        onClose={(updated: boolean) => {
          setOpen(false)
          if (updated) {
            setRefreshTime(new Date())
          }
        }}
        accessRequestID={selectedRecordID}
        open={open}
      />
    )}
  </>
}
