import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import { Badge, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { type ChangeEvent, useState, useEffect } from 'react'
import { NavBar } from '../NavBar'
import { useLocation, useNavigate } from 'react-router'
import type { SelectChangeEvent } from '@mui/material/Select'
import { NotificationCard } from '../Notifications'
import UserDropdown from '../../Pages/Login/UserDropdown'
import { useAuth } from '../../hooks/use-auth'
import { Role, AccountType } from '../../core/types'
import { useAccountId, useAccountIdDispatch } from '../../ContextProviders/CurrentAccount'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import { AcceptDenyModal } from '../Modal/AcceptDenyModal'
import { type notificationEvent } from '../../Pages/CareNetworkManagement/CareNetworkDetail/ChildHistoryDisplay'

export interface HeaderProps {
  onSearchTextChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => void
}

export default function Header (props: HeaderProps): JSX.Element {
  const accountId = useAccountId()
  const updateAccountId = useAccountIdDispatch()
  const [showBurgerMenu, setBurgerMenu] = useState(false)
  const location = useLocation()
  const [showNotifications, setShowNotifications] = useState(false)
  const nav = useNavigate()
  const auth = useAuth()
  const [loaded, setLoaded] = useState(false)
  if (auth.user != null && loaded) {
    setLoaded(true)
    const notificationArray: notificationEvent[] = auth.user.notifications
    notificationArray.sort((a, b) => new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf())
    auth.user.notifications = notificationArray
  }
  const [selectOptions, setSelectOptions] = useState<Array<{ id: number, name: string }>>()
  const isSpUser = auth.user?.securityLevel === Role.SERVICE_PROVIDER_USER
  const isIsdUser = auth.user?.securityLevel === Role.ISD_USER
  const isIntakeUser = auth.user?.securityLevel === Role.INTAKE_USER
  let type: AccountType = AccountType.ISD
  if (isSpUser) {
    type = AccountType.SERVICE_PROVIDER
  } else if (isIntakeUser) {
    type = AccountType.INTAKE_USER
  }

  useEffect(() => {
    if (auth.loading) return
    let opts: Array<{ id: number, name: string }> | undefined
    if (isIsdUser) {
      opts = auth.user?.isDs.map(i => { return { id: i.id, name: i.name } })
    } else if (isSpUser || isIntakeUser) {
      opts = auth.user?.serviceProviders.map(i => { return { id: i.id, name: i.name } })
    }
    const slice = opts?.slice()
    setSelectOptions(slice)
    if (!((opts?.some(i => i.id === accountId?.id)) ?? false)) {
      updateAccountId({ account: { id: opts?.slice()?.shift()?.id ?? 0, type }, type: 'update' })
    }
  }, [auth.user?.id])

  const navItemClicked = (url: string): void => {
    nav(url)
  }

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    updateAccountId({ account: { id: event.target.value as number, type }, type: 'update' })
    window.location.reload()
  }

  const newCount = auth.user?.notifications?.filter(e => !e.viewed).length ?? 0
  const notificationButton = <IconButton
    aria-label='notifications bell'
    onClick= { async () => {
      await auth.setCurrentUser()
      setShowNotifications(!showNotifications)
    }}
  >
    {newCount > 0 && (
      <Badge badgeContent={newCount} color='error' >
        <NotificationImportantIcon />
      </Badge>
    )}
    {newCount === 0 && <NotificationsNoneOutlinedIcon />}
  </IconButton>

  return (
    <div style={{
      padding: '15px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#fff'
    }}
    >
      <MenuIcon sx={{ display: { sm: 'block', md: 'none' } }} onClick={() => { setBurgerMenu(!showBurgerMenu) }}></MenuIcon>
      <NavBar current={location.pathname} onClick={navItemClicked} visible={showBurgerMenu} setVisible={setBurgerMenu}/>
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}
      >
        {(selectOptions != null && auth.user?.inactive !== true) && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select sx={{
              width: '212px',
              height: '32px',
              outline: 'none',
              borderRadius: '8px',
              ':focus': { border: '1px solid #000' },
              ':active': { border: '1px solid #000' }
            }}
              value={accountId?.id ?? 0}
              data-testid='account-dropdown'
              onChange={ handleChange }
            >
              {selectOptions.map(option => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)}
            </Select>
          </FormControl>
        )}
        {auth.user !== null && !auth.user.inactive && notificationButton}
        <UserDropdown />
      </div>
      <AcceptDenyModal
        dialogContent={<NotificationCard events={auth.user != null ? auth.user.notifications : []} />}
        onClose={() => { setShowNotifications(false) }}
        open={showNotifications}
        hideCancelButton={true}
        title='Notifications'
      />
    </div>
  )
}
