import { Autocomplete as AC, Box, FormControl, type FormControlProps, InputLabel, TextField } from '@mui/material'

interface Props {
  options: Array<{ id: string | number, name: string, subtext?: string }>
  onChange: (e: any, value: { id: string | number, name: string } | null) => void
  label: string
  value: string | number
  name: string
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  width?: string
  maxWidth?: string
  dataTestId?: string
  placeholder?: string
  noBottomPadding?: boolean
}

export function Autocomplete (props: Props): JSX.Element {
  const {
    options,
    onChange,
    label,
    value,
    name,
    FormControlProps,
    disabled,
    required,
    width,
    maxWidth,
    placeholder,
    dataTestId,
    noBottomPadding
  } = props
  const selectedItem = options.find(y => y.id?.toString() === value?.toString()) ?? null

  return (
    <Box sx={{ pt: 1 }} data-testid={dataTestId}>
      <InputLabel
        sx={{ color: 'black' }}
        htmlFor={name}
        required={required}
        disableAnimation
        shrink={false}
        variant="standard"
      >
        {label}
      </InputLabel>
      <FormControl
        variant="filled"
        {...FormControlProps}
        disabled={disabled}
        sx={{ pt: 0, width, maxWidth }}
      >
        <AC
          id={name}
          aria-labelledby={name}
          disabled={disabled}
          options={options}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
          sx={{ pb: noBottomPadding === true ? 0 : 2, minWidth: width }}
          value={selectedItem}
          renderInput={(params) => <TextField name={name} placeholder={placeholder} {...params} />}
          renderOption={(internalProps, option, state) => (
            <li {...internalProps} value={option.id} key={option.id}>
              <Box className={'flex'}>
                <Box>{option.name}</Box>
                {option.subtext != null && <Box sx={{ color: '#888' }}>{option.subtext}</Box>}
              </Box>
            </li>
          )}
        />
      </FormControl>
    </Box>
  )
}
