import { Box, Button, CircularProgress, Chip } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { sendGet, sendPost, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import { type PaginationProps, usePagination } from '../../hooks/use-pagination'
import { ISDStaffRole, StaffDetail } from './StaffDetail'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { Role } from '../../core/types'

const nameColumn = new Column('name', 'Staff Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = row => {
  if (row.image != null) {
    const imageurl: string = row.image.toString()
    return <div>
      <img src={'data:image /png; base64,' + imageurl} className='list-image'/>
      {row.name}
    </div>
  } else {
    return <div>{row.name}</div>
  }
}
const emailColumn = new Column('email', 'Email')
const levelColumn = new Column('accessLevel', 'Access Level')
const statusColumn = new Column('inactive', 'Account Status', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  let color = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  let label = 'Active'
  if (row?.inactive as boolean) {
    color = '#D4316C !important'
    label = 'Inactive'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={label} size="small" sx={{ color, backgroundColor }} />
}
const columns = [nameColumn, emailColumn, levelColumn, statusColumn]

export interface ISDStaff {
  id: number
  isdid: number
  firstName: string
  lastName: string
  email: string
  accessLevel: ISDStaffRole
  inactive: boolean
  functionList: string[]
  userType: Role
}

export function StaffList (): JSX.Element {
  const isdId = useAccountId()?.id ?? 0
  const [filterDate, setFilterDate] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const url = `/ISDStaff/GetStaffList?isdID=${isdId}`
      const { response: userInfos } = await sendGet(url, {})
      try {
        const data = userInfos ?? []
        setRows(data)
      } catch (e) {
        setRows([])
      }
      setLoading(false)
    }
    void fetchData()
  }, [filterDate])

  const paginationProps: PaginationProps<RowData> = {
    rows,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }

  const pagination = usePagination(paginationProps)
  const initialISDStaff: ISDStaff = {
    id: 0,
    isdid: isdId,
    firstName: '',
    lastName: '',
    email: '',
    inactive: false,
    accessLevel: ISDStaffRole.ReadOnly,
    functionList: [],
    userType: Role.ISD_USER
  }
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [selectedISDStaff, setselectedISDStaff] = useState<ISDStaff>(initialISDStaff)
  const [open, setOpen] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)

  const addClick = (): void => {
    setselectedISDStaff(initialISDStaff)
    setErrors(undefined)
    setOpen(true)
    setIsNew(true)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const loadData = async (): Promise<void> => {
      const { response, error } = await sendGet(`/ISDStaff/FindByID/${row.id as string}`, {})
      if (error[0] === '' && response !== null) {
        setselectedISDStaff(response)
        setErrors(undefined)
        setOpen(true)
        setIsNew(false)
      }
    }
    void loadData()
  }

  const handleSubmit = (data: ISDStaff, file: File | null): void => {
    const updateUser = async (): Promise<void> => {
      if (!isRequestLoading) {
        setIsRequestLoading(true)
        setErrors(undefined)
        let err: string[] | null = null
        const url = `/ISDStaff/${isNew ? 'Insert' : 'Update'}`
        let recordID: number = 0
        if (isNew) {
          const { response } = await sendPost(url, data)
          if (!isNaN(response)) {
            recordID = response
          } else {
            err = response.split(';')
          }
        } else {
          recordID = selectedISDStaff.id
          const { response } = await sendPut(url, data)
          if (response !== '') {
            err = response.split(';')
          }
        }

        if (err == null && file != null) {
          const fileUrl = '/ISDStaff/UploadStaffImage'
          const fileData = new FormData()
          fileData.append('id', recordID.toString())
          fileData.append('file', file)
          const { response } = await sendPostFormData(fileUrl, fileData)
          if (response !== '') {
            err = response.split(';')
          }
        }

        if (err == null) {
          setFilterDate(new Date())
          setOpen(false)
        } else {
          setErrors(err)
        }
        setIsRequestLoading(false)
      }
    }
    void updateUser()
  }

  if (loading) { return <CircularProgress /> } else {
    return <Box width='fit-content' sx={{ marginTop: '10px' }}>
      <Box justifyContent='flex-end' display='flex' width='100%'>
        <Button color='secondary' variant='contained' onClick={addClick} data-testid="addStaff">Add Staff</Button>
      </Box>

      <DataTable
        page={pagination.page}
        name='staffTable'
        data-testid='staffTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
        initialColumnSorts={[new ColumnSort('name')]}
      />
      {open && (
        <StaffDetail
          onClose={() => { setOpen(false) }}
          onSubmit={handleSubmit}
          open={open}
          isNew={isNew}
          errors={errors}
          item={selectedISDStaff}
        />
      )}
    </Box>
  }
}
