export function DragDots (): JSX.Element {
  return <svg width="48" height="48" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="7.5" width="3" height="3" fill="#ccc"/>
    <rect x="7" y="14.5" width="3" height="3" fill="#ccc"/>
    <rect x="7" y="21.5" width="3" height="3" fill="#ccc"/>
    <rect x="14" y="7.5" width="3" height="3" fill="#ccc"/>
    <rect x="14" y="14.5" width="3" height="3" fill="#ccc"/>
    <rect x="14" y="21.5" width="3" height="3" fill="#ccc"/>
    <rect x="21" y="7.5" width="3" height="3" fill="#ccc"/>
    <rect x="21" y="14.5" width="3" height="3" fill="#ccc"/>
    <rect x="21" y="21.5" width="3" height="3" fill="#ccc"/>
  </svg>
}
