/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Card, CardContent, Typography } from '@mui/material'
import S3Image from '../../S3Image'
import { type location, type ISD } from '../../../core/types'
import { LocationDisplay } from '../LocationCard/LocationDisplay'

export function IsdCardDisplay (props: { isd: ISD }): JSX.Element {
  return <Card>
    <CardContent>
      {props.isd.logoKey !== null && (
        <S3Image imageKey={props.isd.logoKey ?? 'none'} alt='ISD Logo' />
      )}
      <Typography>{props.isd.name}</Typography>
      <Typography>{props.isd.notes}</Typography>
      <LocationDisplay location={{
        address: props.isd.address,
        apartment: '',
        city: props.isd.city,
        state: props.isd.state,
        zipCode: props.isd.zipCode,
        isPrimary: false
      } as location}
      />
    </CardContent>

  </Card>
}
