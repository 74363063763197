import { Box, CircularProgress } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { sendDelete, sendGet, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { ErrorDisplay, useErrorDispatch } from '../../ContextProviders/ErrorProvider'
import { type DocumentTypeRequirement, DocumentTarget, type income } from '../../core/types'
import { DocumentListEdit } from './DocumentListEdit'
import { BannerModal } from '../Modal/BannerModal'
import { SelectWithLabel } from '../SelectWithLabel'
import { VerifyDocumentModal } from './VerifyDocumentModal'
import { DownloadDocument } from '../../core/Utilities'

export interface DocumentListProps {
  childID: number | null
  caregiverID: number | null
  editable: boolean
  canVerify: boolean
  showDisclaimer: boolean
  isdId: number
  documentRequirements?: DocumentTypeRequirement[]
  dataChanged?: () => void
  years: number[]
}

export interface documentTypeModel {
  documentTypeID: number
  name: string
  documentDescription: string | null
  imageFile: string | null
  required: boolean
  documents: documentEditModel[]
  documentStatus: string
  documentTarget?: string
  incomeRelated: boolean
}

export interface documentEditModel {
  id: number
  fileName: string
  file?: File
  whenUploaded: Date
  verified: boolean
  verifiedDate: Date | null
  verifiedBy: string | null
  verificationDetails: string | null
  incomeYear: number | null
  documentIncome: number | null
  caregivers: string[]
}

export function DocumentList (props: DocumentListProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const [documentList, setDocumentList] = useState<documentTypeModel[]>([])
  const [refreshTime, setRefreshTime] = useState(new Date())
  const errorDispatch = useErrorDispatch()
  const [hasNoAccess, setHasNoAccess] = useState(false)
  const [recordID, setRecordID] = useState(0)
  const record = useRef<documentEditModel | null>(null)
  const [showVerifying, setShowVerifying] = useState(false)
  const [showEditing, setShowEditing] = useState(false)
  const [incomeRecords, setIncomeRecords] = useState<income[] | null>(null)
  const [incomeYear, setIncomeYear] = useState<number | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.isdId === 0) {
        return
      }

      if (props.caregiverID !== null) {
        const { response: rsp1, success: suc1 } = await sendGet(`/CareNetworkEdit/CaregiverIncomeList?caregiverID=${props.caregiverID}`, {})
        if (suc1) {
          setIncomeRecords(rsp1 as income[])
        }
      }

      let typeList: string = ''
      let docs: DocumentTypeRequirement[] = []
      if (props.documentRequirements === undefined) {
        const { response: rsp, success: suc } = await sendGet(`/Document/DocumentTypes?isdId=${props.isdId}`, {})
        if (suc) {
          docs = rsp as DocumentTypeRequirement[]
        }
      } else {
        docs = props.documentRequirements ?? []
      }

      docs.forEach(i => {
        if (typeList !== '') {
          typeList += ';'
        }
        if (props.caregiverID != null) {
          if (i.documentTarget === DocumentTarget.ADULT || i.documentTarget === DocumentTarget.BOTH) {
            typeList += `${i.documentTypeID},${(i.required ? '1' : '0')}`
          }
        } else if (props.childID != null) {
          if (i.documentTarget === DocumentTarget.CHILD || i.documentTarget === DocumentTarget.BOTH) {
            typeList += `${i.documentTypeID},${(i.required ? '1' : '0')}`
          }
        }
      })

      let queryString: string = ''
      if (props.caregiverID != null) {
        queryString = `?caregiverID=${props.caregiverID.toString()}`
      } else if (props.childID != null) {
        queryString = `?childID=${props.childID.toString()}`
      }
      // construct the documentTypes parameters which is a set of value pairs separated where the first is a documentTypeID and the second is either 0 or 1 indicating if it's requried.
      // each value set is separated by ; and the 2 values are separated by ,

      const { response, success } = await sendGet(`/Document/DocumentList${queryString}&documentTypes=${typeList}`, {})
      if (success && (Boolean(response.success))) {
        setDocumentList(response.result.documentTypes)
        errorDispatch({ errors: [], type: 'clear' })
      } else {
        if (response.status === 401) {
          setHasNoAccess(true)
        } else {
          errorDispatch({ errors: response.errors, type: 'update' })
        }
      }
      setLoaded(true)
    }
    void fetchData()
  }, [refreshTime, props.isdId])

  const uploadFile = async (documentTypeID: number, file: File, year: number | null): Promise<boolean> => {
    const fileUrl = '/Document/UploadFile'
    const fileData = new FormData()
    fileData.append('documentTypeID', documentTypeID.toString())
    if (props.caregiverID !== null) {
      fileData.append('caregiverID', props.caregiverID.toString())
    } else if (props.childID !== null) {
      fileData.append('childID', props.childID.toString())
    }
    if (year !== null) {
      fileData.append('incomeYear', year.toString())
    }
    fileData.append('file', file)

    const { error, success } = await sendPostFormData(fileUrl, fileData)
    if (success) {
      setRefreshTime(new Date())
      toast.success('Document has been uploaded')
      if (props.dataChanged !== undefined) {
        props.dataChanged()
      }
      return true
    } else {
      toast.error(error)
    }
    return false
  }

  const onEditStart = async (document: documentEditModel): Promise<void> => {
    record.current = document
    setIncomeYear(document.incomeYear)
    setShowEditing(true)
  }

  const onSaveEdit = async (): Promise<void> => {
    if (record.current !== null && incomeYear !== null) {
      const url = `/Document/SetIncomeYear/${record.current.id.toString()}`
      const { response } = await sendPut(url, Object(incomeYear))
      if (response as boolean) {
        setRefreshTime(new Date())
        setShowEditing(false)
      }
    }
  }

  const onVerifyStart = async (documentTypeId: number, index: number, verified: boolean): Promise<boolean> => {
    if (documentList !== undefined) {
      const doc = documentList?.find(d => d.documentTypeID === documentTypeId)?.documents[index] ?? null
      if (doc !== undefined && doc !== null) {
        setRecordID(doc.id)
        setShowVerifying(true)
      }
    }
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const unverifyDocument = async (documentId: number): Promise<boolean> => {
    if (documentId !== undefined) {
      const url = `/Document/UnverifyDocument/${documentId.toString()}`
      const { response } = await sendPut(url, {})
      if (response as boolean) {
        setRefreshTime(new Date())
        if (props.dataChanged !== undefined) {
          props.dataChanged()
        }
        return true
      } else {
        toast.error('Could not unverify document')
      }
    }
    return false
  }

  const editModal = <Box>
    {incomeRecords !== null && <SelectWithLabel
      label='Tax Year for Income Document'
      name='incomeYear'
      width='100%'
      options={incomeRecords.map(i => { return { id: i.year, name: i.year.toString() } })}
      value={incomeYear ?? undefined}
      onChange={(e: any): void => {
        setIncomeYear(e.target.value)
      }}
    />
    }
  </Box>

  const downloadDocument = async (documentId: number): Promise<boolean> => {
    return DownloadDocument(documentId)
  }

  const deleteDocument = async (documentId: number, documentTypeID: number, index: number): Promise<boolean> => {
    if (confirm('Are you sure you want to delete this document?')) { // may want to switch this to something else, but for now we're just using confirm
      if (documentId !== undefined) {
        const url = `/Document/Delete/${documentId.toString()}`
        const { error, success } = await sendDelete(url)
        if (success) {
          setRefreshTime(new Date())
          toast.success('Document has been deleted')
          if (props.dataChanged !== undefined) {
            props.dataChanged()
          }
          return true
        } else {
          toast.error(error)
          return false
        }
      }
    }
    return false
  }

  if (hasNoAccess) {
    return <div>You do not have access to documents</div>
  } else if (!loaded) {
    return <CircularProgress role='loading' />
  }

  const editComplete = (updated: boolean): void => {
    if (updated) {
      setRefreshTime(new Date())
    }
    setShowVerifying(false)
  }

  return <>
    <ErrorDisplay fieldName='' />
    <Box width={'100%'}>
      <DocumentListEdit
        documentTypes={documentList ?? []}
        editable={props.editable}
        showDisclaimer={props.showDisclaimer}
        canVerify={props.canVerify}
        onDelete={deleteDocument}
        onDownload={downloadDocument}
        onEdit={onEditStart}
        onVerify={onVerifyStart}
        onUpload={uploadFile}
        incomeRecords={incomeRecords}
      />
    </Box>
    {showVerifying &&
      <VerifyDocumentModal
        childID={props.childID ?? 0}
        documentID={recordID}
        onClose={editComplete}
        years={props.years}
      />
    }
    <BannerModal
      title='Edit Document'
      content={editModal}
      onConfirm={onSaveEdit}
      open={showEditing}
      onClose={() => { setShowEditing(false) }}
    />
  </>
}
