import { type DocumentVerifyModel, type ReferralDocumentModel } from '../../core/types'
import { sendGet } from '../../hooks/use-fetch'
import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { RadiusCardClickable } from '../../Components/Cards/Radius/RadiusCardClickable'
import { RadiusCard } from '../../Components/Cards/Radius/RadiusCard'
import { FormatCurrency, FormatDateValue } from '../../core/Utilities'
import { IncludeDocument } from '../../Components/DocumentList/IncludeDocument'
import checkmark from '../../Assets/Icons/checkmark.png'

interface ReferralDocumentSelectionProps {
  referralID: number
  documentTypeID: number
  incomeRelated: boolean
  onInclude: (item: DocumentVerifyModel[]) => void
  years: number[]
}

export function ReferralDocumentSelection (props: ReferralDocumentSelectionProps): JSX.Element {
  const [documents, setDocuments] = useState<ReferralDocumentModel[]>([])
  const [selectedDocument, setSelectedDocument] = useState<ReferralDocumentModel | null>(null)
  const [includedDocuments, setIncludedDocuments] = useState<DocumentVerifyModel[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, success } = await sendGet(`/ReferralEdit/AvailableReferralDocuments?referralID=${props.referralID}&documentTypeID=${props.documentTypeID}`, {})
      if (success) {
        setDocuments(response as ReferralDocumentModel[])
      }
    }
    void fetchData()
  }, [])

  useEffect(() => {
    props.onInclude(includedDocuments)
  }, [includedDocuments])

  const selectDocument = (item: ReferralDocumentModel): void => {
    if (includedDocuments.findIndex(f => f.documentID === item.id) === -1) {
      // can only see the details if it hasn't been included
      setSelectedDocument(item)
    } else {
      setSelectedDocument(null)
    }
  }

  const includeDocument = (item: DocumentVerifyModel): void => {
    const existing = documents.find(f => f.id === item.documentID)
    if (existing !== undefined) {
      if (item.incomeAmount !== null) {
        existing.documentIncome = parseInt(item.incomeAmount.toString())
      }
      existing.incomeYear = item.incomeYear

      existing.verificationDetails = item.verifyNotes
      if (!existing.verified) {
        existing.verifiedDate = new Date()
      }
      existing.verified = true
      existing.caregivers = []
      for (let i = 0; i < item.documentCaregivers.length; i++) {
        existing.caregivers.push(item.documentCaregivers[i].name)
      }

      const updatedDocuments = documents.map((d) => {
        if (d.id === item.documentID) {
          return existing
        } else {
          return d
        }
      })

      setDocuments(updatedDocuments)
    }
    setIncludedDocuments([...includedDocuments, item])
    setSelectedDocument(null)
  }

  return <table className='ml-5' >
    {documents.length === 0 && <tr>
      <td colSpan={2} className='pl-25' data-testid='noDocsMessage'>No documents available to include</td>
    </tr>
    }
    {documents.length !== 0 && <>
      <tr>
        <td colSpan={2} className='pl-25'>Click on documents on the left and select whether to include them.</td>
      </tr>
      <tr>
        <td className='width-45' style={{ verticalAlign: 'top' }}>
          {documents.map((d) => {
            return <RadiusCardClickable
              icon={includedDocuments.findIndex(f => f.documentID === d.id) === -1 ? undefined : <img data-testid='selectedIcon' src={checkmark} alt='This document has been selected'/> }
              headerClassName='width-245'
              header={d.fileName}
              width={'100%'}
              dataTestId={`documentCard${d.id}`}
              contentClassName={selectedDocument !== null && selectedDocument.id === d.id ? 'card-selected' : ''}
              content={
                <Grid container direction='column'>
                  {props.incomeRelated && <Grid container direction='row' spacing={1} paddingBottom={1}>
                    <Grid item>Document Income:</Grid>
                    <Grid item data-testid='incomeDisplay'>{FormatCurrency(d.documentIncome)}</Grid>
                  </Grid>
                  }
                  {props.incomeRelated && <Grid container direction='row' spacing={1} paddingBottom={1}>
                    <Grid item>Tax Year:</Grid>
                    <Grid item>{d.incomeYear}</Grid>
                  </Grid>
                  }
                  {
                    d.caregivers.map((val) => {
                      return <Grid item>{val}</Grid>
                    })
                  }
                  {d.verified && <Grid item paddingTop={1}>Verified on {FormatDateValue(d.verifiedDate)}</Grid>}
                  {d.verified && <Grid item >{d.verificationDetails}</Grid>}
                </Grid>
              }
              onClick={() => { selectDocument(d) }}
              className='mt-5'
            />
          })
          }
        </td>
        <td style={{ verticalAlign: 'top' }}>
          {selectedDocument !== null && <RadiusCard
            className='ml-10 width-275'
            dataTestId='includeDocumentCard'
            contentClassName='font-reducer'
            header={selectedDocument.fileName}
            content={<IncludeDocument documentID={selectedDocument.id} childID={selectedDocument.childID} onInclude={includeDocument} years={props.years} />}
          />
          }
        </td>
      </tr>
    </>
    }
  </table >
}
