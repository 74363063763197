export function FormatDateValue (d: Date | null | undefined): string {
  if (d === undefined || d === null) {
    return ''
  } else {
    const dateVal = new Date(d)
    const datestring = `${(dateVal.getMonth() + 1)}/${dateVal.getDate()}/${dateVal.getFullYear()}`
    return datestring
  }
}

export function FormatDateTimeValue (d: Date | null | undefined): string {
  if (d === undefined || d === null) {
    return ''
  } else {
    const dateVal = new Date(d)
    const datestring = `${(dateVal.getMonth() + 1)}/${dateVal.getDate()}/${dateVal.getFullYear()} ${dateVal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    return datestring
  }
}

export function BirthdayToAgeInYearsAndMonths (d: Date | null | undefined): string {
  if (d === undefined || d === null) {
    return ''
  } else {
    const dateVal = new Date(d)
    const today = new Date()
    const years = today.getFullYear() - dateVal.getFullYear()
    const months = today.getMonth() - dateVal.getMonth()
    const days = today.getDate() - dateVal.getDate()
    if (months < 0 || (months === 0 && days < 0)) {
      return `${years - 1}y ${12 + months}m`
    } else {
      return `${years}y ${months}m`
    }
  }
}

export function FormatDateAsTimeAgo (d: Date): string {
  const dateVal = new Date(d)
  const seconds = Math.floor((Date.now() - dateVal.getTime()) / 1000)

  let interval = Math.floor(seconds / 31536000)
  if (interval === 1) {
    return '1 year ago'
  } else if (interval > 1) {
    return interval.toString() + ' years ago'
  }

  interval = Math.floor(seconds / 2592000)
  if (interval === 1) {
    return '1 month ago'
  } else if (interval > 1) {
    return interval.toString() + ' months ago'
  }

  interval = Math.floor(seconds / 86400)
  if (interval === 1) {
    return '1 day ago'
  } else if (interval > 1) {
    return interval.toString() + ' days ago'
  }

  interval = Math.floor(seconds / 3600)
  if (interval === 1) {
    return '1 hour ago'
  } else if (interval > 1) {
    return interval.toString() + ' hours ago'
  }

  interval = Math.floor(seconds / 60)
  if (interval === 1) {
    return '1 minute ago'
  } else if (interval > 1) {
    return interval.toString() + ' minutes ago'
  }

  if (seconds < 10) {
    return 'just now'
  }

  return Math.floor(seconds).toString() + ' seconds ago'
}

export function AgeFromDate (dob: Date | null | undefined): number {
  if (dob === undefined || dob === null) {
    return 0
  } else {
    const dateVal = new Date(dob)
    const today = new Date()
    let age: number = today.getFullYear() - dateVal.getFullYear()
    const m: number = today.getMonth() - dateVal.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < dateVal.getDate())) {
      age--
    }
    return age
  }
}

export function FormatTimeAs12Hour (time: string): string {
  const timeParts = time.split(':')
  const hours = parseInt(timeParts[0])
  const minutes = parseInt(timeParts[1])
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const hours12 = hours % 12 > 0 ? hours % 12 : 12
  return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`
}

export function FormatCurrency (amount: number | null): string {
  if (amount === undefined || amount === null) {
    return ''
  } else {
    return '$' + amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export function FormatBoolean (value: boolean | null): string {
  if (value === undefined || value === null) {
    return ''
  } else if (value) {
    return 'Yes'
  } else {
    return 'No'
  }
}

export function ScrollModalToTop (): void {
  // scroll the contents of a modal dialog to the top.  Can be used make sure errors listed at the top are displayed
  const modalDiv = document.getElementsByClassName('MuiDialogContent-root')
  if (modalDiv.length !== 0) {
    modalDiv[0].scrollTop = 0
  }
}

export function FileNameFromS3Key (key: string | null): string {
  if (key === null) {
    return ''
  } else {
    return key.substring(37)
  }
}

export function SpaceBeforeCapitals (s: string): string {
  return s.replace(/([A-Z])/g, ' $1').trim()
}

export function DownloadDocument (documentId: number | undefined): boolean {
  if (documentId !== undefined) {
    const encodedUri = `${process.env.REACT_APP_SERVER_URL ?? ''}/Document/DownloadFile/${documentId.toString()}`
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('href', encodedUri)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return true
  }
  return false
}
