import { Box, Grid } from '@mui/material'
import { type AuthType } from '../../hooks/use-auth'
import { SubscriptionCard } from '../../Components/Cards/Subscription/SubscriptionCard'
import { type userNotificationSetting, Role, type lookup } from '../../core/types'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'

export interface SubscriptionCardProps {
  id: number
  usernotificationSetting: userNotificationSetting[]
  onSubmit: () => void
  userSecurityLevel: string
  userId: number
  defaultEvents: lookup[]
  logoKey?: string | null
  logoAlt?: string | null
  title: string
  type: 'caregiver' | 'isd' | 'serviceProvider' | 'intakeProvider'
}

export function UserSubscriptions (props: AuthType): JSX.Element {
  const [defaultEvents, setDefaultEvents] = useState<lookup[]>([])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response, success } = await sendGet(`/User/AllApplicableEventTypes?securityLevelEnumVal=${props.user?.securityLevel ?? 'CARE_GIVER'}`, {})
      if (success) {
        setDefaultEvents(response)
      }
    }
    void loadData()
  }, [props.user?.securityLevel])
  return <Box>
    {props.user?.securityLevel === Role.INTAKE_USER && (<>
      <Grid container spacing={1}>
        {props.user?.serviceProviders.map((sp) => {
          return <Grid item key={sp.isdServiceProviderID}>
            <SubscriptionCard
              id={sp.isdServiceProviderID ?? 0}
              defaultEvents={defaultEvents}
              key={sp.isdServiceProviderID}
              usernotificationSetting={props.user?.userNotificationSettings
                ?.filter((userSetting: userNotificationSetting) => (userSetting.intakeServiceProviderID === sp.isdServiceProviderID)) ?? []}
              userSecurityLevel={props.user?.securityLevel ?? ''}
              onSubmit={props.setCurrentUser}
              userId={props.user?.id ?? 0}
              logoAlt={'intake provider logo'}
              logoKey={sp.s3Key}
              title={sp.name}
              type='intakeProvider'
            />
          </Grid>
        })}
      </Grid>
    </>
    )}
    {props.user?.securityLevel === Role.SERVICE_PROVIDER_USER && (<>
      <Grid container spacing={1}>
        {props.user?.serviceProviders.map((sp) => {
          return <Grid item key={sp.isdServiceProviderID}>
            <SubscriptionCard
              id={sp.isdServiceProviderID ?? 0}
              defaultEvents={defaultEvents}
              key={sp.isdServiceProviderID}
              usernotificationSetting={props.user?.userNotificationSettings
                ?.filter((userSetting: userNotificationSetting) => (userSetting.serviceProviderID === sp.isdServiceProviderID)) ?? []}
              userSecurityLevel={props.user?.securityLevel ?? ''}
              onSubmit={props.setCurrentUser}
              userId={props.user?.id ?? 0}
              logoAlt={'sp logo'}
              logoKey={sp.s3Key}
              title={sp.name}
              type='serviceProvider'
            />
          </Grid>
        })}
      </Grid>
    </>
    )}
    {props.user?.securityLevel === Role.ISD_USER && (<>
      <Grid container spacing={1} >
        {props.user?.isDs.map((isd) => {
          return <Grid item key={isd.id}>
            <SubscriptionCard
              id={isd.id}
              defaultEvents={defaultEvents}
              key={isd.id}
              usernotificationSetting={props.user?.userNotificationSettings?.filter((userSetting: userNotificationSetting) => userSetting.isdid === isd.id) ?? []}
              userSecurityLevel={props.user?.securityLevel ?? ''}
              onSubmit={props.setCurrentUser}
              userId={props.user?.id ?? 0}
              logoAlt={'isd logo'}
              logoKey={isd.logoKey}
              title={isd.name}
              type='isd'
            />
          </Grid>
        })}
      </Grid>
    </>
    )}
    {(props.user?.securityLevel === Role.CARE_GIVER) &&
          (<>
            <Grid container spacing={1} >
              <Grid item key={props.user?.id}>
                <SubscriptionCard
                  id={props.user?.id}
                  defaultEvents={defaultEvents}
                  key={props.user?.id}
                  usernotificationSetting={props.user?.userNotificationSettings?.filter((userSetting: userNotificationSetting) => userSetting.userID === props.user?.id) ?? []}
                  userSecurityLevel={props.user?.securityLevel ?? ''}
                  onSubmit={props.setCurrentUser}
                  userId={props.user?.id ?? 0}
                  title={'Caregiver'}
                  type='caregiver'
                />
              </Grid>
            </Grid>
          </>
          ) }
  </Box>
}
