import { useEffect, useState } from 'react'
import { DocumentList } from '.'
import { sendGet } from '../../hooks/use-fetch'
import { type PersonBasicInfo, type DocumentTypeRequirement } from '../../core/types'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useAccountId } from '../../ContextProviders/CurrentAccount'

export interface ChildAndGuardianDocumentProps {
  childID: number
  editable: boolean
  isdID: number
  documentTypes?: DocumentTypeRequirement[] | undefined
  dataChanged?: () => void
  childName?: string
  isDocumentPermission?: boolean
  maxWidth?: string | undefined
}

export function ChildAndGuardianDocumentList (props: ChildAndGuardianDocumentProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const [child, setChild] = useState<PersonBasicInfo>()
  const [caregiverList, setCaregiverList] = useState<PersonBasicInfo[]>([])
  const account = useAccountId()
  const yearOptions: number[] = []

  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 6; i <= currentYear + 1; i++) {
    yearOptions.push(i)
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: details, success } = await sendGet(`/ChildDetails/Find?id=${props.childID}&accountId=${account?.id ?? 0}&accountType=${account?.type ?? ''}`, {})
      if (success) {
        setChild(details.response)
      }

      const { response: caregivers } = await sendGet(`/CareNetworkEdit/ChildsFinanciallyResponsibleCaregivers?childID=${props.childID}`, {})
      setCaregiverList(caregivers)

      setLoaded(true)
    }
    void fetchData()
  }, [])

  if (!loaded) {
    return <CircularProgress role='loading' />
  }

  if (props.isDocumentPermission === false) {
    return <div>You do not have access to this feature</div>
  }

  return <Box sx={{ maxWidth: props.maxWidth === undefined ? '100%' : props.maxWidth }}>
    <Typography variant='body1' fontWeight={'bold'}>Documents for
      {props.childName !== undefined
        ? props.childName
        : child !== undefined ? ` ${child.firstName} ${child.lastName} ${child.suffix ?? ''}` : ''
      }
    </Typography>
    <DocumentList
      editable={props.editable}
      showDisclaimer={true}
      canVerify={true}
      childID={props.childID}
      isdId={props.isdID}
      caregiverID={null}
      documentRequirements={props.documentTypes}
      dataChanged={props.dataChanged}
      years={yearOptions}
    />

    {caregiverList.map((caregiver) => {
      return <Box className={'mt-10'} key={caregiver.id}>
        <Typography variant='body1' fontWeight={'bold'}>Documents for {caregiver?.firstName} {caregiver?.lastName}</Typography>
        <DocumentList
          editable={props.editable}
          showDisclaimer={false}
          canVerify={true}
          childID={props.childID}
          isdId={props.isdID}
          caregiverID={caregiver.id}
          documentRequirements={props.documentTypes}
          dataChanged={props.dataChanged}
          years={yearOptions}
        />
      </Box>
    })}
  </Box>
}
