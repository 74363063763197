import { Button, Grid, Typography } from '@mui/material'
import { type lookup, type EnrollmentDetails, type PortalLocation } from '../../../../core/types'
import { TextFieldWithLabel } from '../../../../Components/TextField'
import { useEffect, useState } from 'react'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { SelectWithLabel } from '../../../../Components/SelectWithLabel'
import { sendGet } from '../../../../hooks/use-fetch'
import { CheckBoxWithLabel } from '../../../../Components/CheckBox'
import { Modal } from '../../../../Components/Modal'
import { toast } from 'react-toastify'
import { LocationDetails } from '../LocationDetails'
import { useParams } from 'react-router'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { CheckBoxWithCustomLabel } from '../../../../Components/CustomLabel/CheckboxWithCustomLabel'
import { DatePickerWithLabel } from '../../../../Components/DatePicker'
import { AsYouType } from 'libphonenumber-js'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface AddCaregiverDetailsProps {
  caregiverForm: EnrollmentDetails
  handleStep: (backwards: boolean) => void
}

interface FormField {
  isDirty: boolean
  isValid: boolean
}

interface ValidationState {
  firstName: FormField
  lastName: FormField
  relationshipId: FormField
  primaryPhone: FormField
  email: FormField
  preferredContactMethod: FormField
  address: FormField
  city: FormField
  state: FormField
  zipCode: FormField
  income: FormField
  taxYear: FormField
}

const currentYear = new Date().getFullYear()
const taxYears = [currentYear - 1, currentYear, currentYear + 1]
const selectableYears = taxYears.map(y => ({ id: y, name: y.toString() }))

export function AddCaregiverDetails (props: AddCaregiverDetailsProps): JSX.Element {
  const { isdId } = useParams()
  const dispatch = useCaregiverWizardUpdateDispatch()
  const [validationState, setValidationState] = useState<ValidationState>({
    firstName: { isDirty: false, isValid: false },
    lastName: { isDirty: false, isValid: false },
    relationshipId: { isDirty: false, isValid: false },
    primaryPhone: { isDirty: false, isValid: false },
    email: { isDirty: false, isValid: false },
    preferredContactMethod: { isDirty: false, isValid: false },
    address: { isDirty: false, isValid: false },
    city: { isDirty: false, isValid: false },
    state: { isDirty: false, isValid: false },
    zipCode: { isDirty: false, isValid: false },
    income: { isDirty: false, isValid: false },
    taxYear: { isDirty: false, isValid: false }
  })
  const [relationships, setRelationShips] = useState<lookup[]>([])
  const [contactMethods, setContactMethods] = useState<lookup[]>([])
  const [educationLevels, setEducationLevels] = useState<lookup[]>([])
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [bingMapsLocation, setBingMapsLocation] = useState<PortalLocation | undefined>(undefined)

  useScrollTop()

  // The bing maps location is set in the useBingMaps hook, but that has issues with state changes
  useEffect(() => {
    if (bingMapsLocation != null) {
      handleLocationChange(bingMapsLocation)
    }
  }, [bingMapsLocation])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: eduLevels, error: err } = await sendGet('/EducationLevel/GetLookup', {})
      if (err[0] === '') {
        setEducationLevels(eduLevels)
      }
      const { response: relations, error: err1 } = await sendGet('/Relationship/GetLookup', {})
      if (err1[0] === '') {
        setRelationShips(relations)
      }
      const { response: methods, error: err2 } = await sendGet('/ContactMethod/GetAll', {})
      if (err2[0] === '') {
        setContactMethods(methods)
      }
    }
    void fetchData()
  }, [])

  const caregiverForm = props.caregiverForm
  const caregiver = caregiverForm.currentCaregiver
  const location = caregiver.location
  const hasOtherCaregivers = caregiverForm.caregivers.length > 0
  const isFirstCaregiver = !hasOtherCaregivers || caregiverForm.caregivers[0].isCurrentlyUpdating

  const handleCaregiverChange = (field: string, value: any): void => {
    const tempCaregiver = { ...caregiver, [field]: value }
    dispatch({ type: 'caregiver', caregiver: tempCaregiver })
  }

  const handleLocationFieldChange = (e: any): void => {
    const tempLocation = { ...location, [e.target.name]: e.target.value }
    const tempCaregiver = { ...caregiver, location: tempLocation }
    dispatch({ type: 'caregiver', caregiver: tempCaregiver })
  }

  const handleTextBlur = (e: any): void => {
    const tempState = {
      ...validationState,
      [e.target.name]: { isDirty: true, isValid: e.target.value !== '' }
    }
    setValidationState(tempState)
  }

  const handleTextChange = (e: any): void => {
    handleCaregiverChange(e.target.name, e.target.value)
  }

  const handleSelectChange = (e: any): void => {
    handleCaregiverChange(e.target.name, e.target.value)
    const tempState = {
      ...validationState,
      [e.target.name]: { isDirty: true, isValid: e.target.value !== 0 }
    }
    setValidationState(tempState)
  }

  const handleCheckChange = (field: string, newVal: boolean): void => {
    handleCaregiverChange(field, newVal)
  }

  const handleLocationChange = (newLocation: PortalLocation): void => {
    const tempCaregiver = { ...caregiver, location: newLocation }
    dispatch({ type: 'caregiver', caregiver: tempCaregiver })

    const tempState = {
      ...validationState,
      address: { isDirty: true, isValid: newLocation.address !== '' },
      city: { isDirty: true, isValid: newLocation.city !== '' },
      state: { isDirty: true, isValid: newLocation.state !== '' },
      zipCode: { isDirty: true, isValid: newLocation.zipCode !== '' }
    }
    setValidationState(tempState)
  }

  const handleConfirm = async (): Promise<void> => {
    const initialForm = initialEnrollmentDetails()
    const tempForm = { ...caregiverForm, currentCaregiver: initialForm.currentCaregiver, currentStep: 1, currentSubStep: 3 }
    if (caregiverForm.caregivers.length > 0) {
      tempForm.currentStep = 2
      tempForm.currentSubStep = 2
      // If we are returning from editing a caregiver, clear out the isCurrentlyUpdating flag
      const existingCaregiver = tempForm.caregivers.find(c => c.isCurrentlyUpdating)
      if (existingCaregiver != null) {
        existingCaregiver.isCurrentlyUpdating = false
      }
    }
    await saveSession(tempForm)
    props.handleStep(true)
    setShowConfirmModal(false)
    dispatch({ type: 'form', form: tempForm })
  }

  const scrollToField = (field: string): void => {
    const element = document.getElementsByName(field)[0]
    if (element != null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const validateForm = (): boolean => {
    const tempState = {
      ...validationState,
      firstName: { isDirty: true, isValid: caregiver.firstName !== '' },
      lastName: { isDirty: true, isValid: caregiver.lastName !== '' },
      relationshipId: { isDirty: true, isValid: caregiver.relationshipId != null },
      primaryPhone: { isDirty: true, isValid: caregiver.primaryPhone !== '' },
      email: { isDirty: true, isValid: caregiver.email !== '' },
      preferredContactMethod: { isDirty: true, isValid: caregiver.preferredContactMethod != null },
      address: { isDirty: true, isValid: location.address !== '' },
      city: { isDirty: true, isValid: location.city !== '' },
      state: { isDirty: true, isValid: location.state !== '' },
      zipCode: { isDirty: true, isValid: location.zipCode !== '' },
      income: { isDirty: true, isValid: caregiver.income != null },
      taxYear: { isDirty: true, isValid: caregiver.taxYear != null }
    }
    if (caregiver.doesNotHaveEmail) {
      tempState.email.isValid = true
      tempState.email.isDirty = false
    }
    if (caregiver.isLivingWithStudent) {
      tempState.address.isValid = true
      tempState.address.isDirty = false
      tempState.city.isValid = true
      tempState.city.isDirty = false
      tempState.state.isValid = true
      tempState.state.isDirty = false
      tempState.zipCode.isValid = true
      tempState.zipCode.isDirty = false
    }
    if (!caregiver.legalGuardian) {
      tempState.income.isValid = true
      tempState.income.isDirty = false
      tempState.taxYear.isValid = true
      tempState.taxYear.isDirty = false
    }
    setValidationState(tempState)

    if (!tempState.firstName.isValid) {
      scrollToField('firstName')
    } else if (!tempState.lastName.isValid) {
      scrollToField('lastName')
    } else if (!tempState.relationshipId.isValid) {
      scrollToField('relationshipId')
    } else if (!tempState.primaryPhone.isValid) {
      scrollToField('primaryPhone')
    } else if (!tempState.email.isValid) {
      scrollToField('email')
    } else if (!tempState.preferredContactMethod.isValid) {
      scrollToField('preferredContactMethod')
    } else if (!tempState.address.isValid) {
      scrollToField('address')
    } else if (!tempState.city.isValid) {
      scrollToField('city')
    } else if (!tempState.state.isValid) {
      scrollToField('state')
    } else if (!tempState.zipCode.isValid) {
      scrollToField('zipCode')
    } else if (!tempState.income.isValid) {
      scrollToField('income')
    } else if (!tempState.taxYear.isValid) {
      scrollToField('taxYear')
    }

    return Object.values(tempState).every(v => v.isValid)
  }

  const handleContinue = async (): Promise<void> => {
    const isFormValid = validateForm()
    if (!isFormValid) {
      toast.error('Please fill out all required fields to continue')
      return
    }

    const initialForm = initialEnrollmentDetails()
    const tempForm = { ...caregiverForm, currentCaregiver: initialForm.currentCaregiver, currentSubStep: 2 }
    const tempCaregiver = { ...caregiver }
    if (tempCaregiver.isLivingWithStudent) {
      tempCaregiver.location = caregiverForm.children[0].location
    }
    const existingcaregiver = tempForm.caregivers.find(c => c.isCurrentlyUpdating)
    if (existingcaregiver != null) {
      // If we are updating an existing caregiver, replace the caregiver instead of adding a new one
      tempCaregiver.isCurrentlyUpdating = false
      const index = tempForm.caregivers.indexOf(existingcaregiver)
      tempForm.caregivers[index] = tempCaregiver
    } else {
      tempForm.caregivers.push(tempCaregiver)
    }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!hasOtherCaregivers &&
          <Typography variant='h5' sx={{ mb: '30px' }} component='div' data-testid="add-caregiver-details-prompt">
            <CustomLabelWithToolTip
              name='IF_Caregiver_Intro'
              isdId={isdId ?? 0}
              defaultLabelText='Next, we need to know a little about the household members to see if they qualify for any additional assistance.'
            />
          </Typography>
        }
        {hasOtherCaregivers &&
          <Typography variant='h5' sx={{ mb: '30px' }} component='div' data-testid="add-caregiver-details-prompt">
            <CustomLabelWithToolTip
              name='IF_Caregiver_Intro_Next'
              isdId={isdId ?? 0}
              defaultLabelText='Great! Please let us know the following information.'
            />
          </Typography>
        }
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithLabel
          label='First Name'
          name='firstName'
          maxLength={50}
          data-testid='firstName'
          value={caregiver.firstName}
          showRequiredText={validationState.firstName.isDirty && !validationState.firstName.isValid}
          required={validationState.firstName.isDirty && !validationState.firstName.isValid}
          error={validationState.firstName.isDirty && !validationState.firstName.isValid}
          onChange={handleTextChange}
          onBlur={handleTextBlur}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithLabel
          label='Last Name'
          name='lastName'
          maxLength={50}
          data-testid='lastName'
          value={caregiver.lastName}
          showRequiredText={validationState.lastName.isDirty && !validationState.lastName.isValid}
          required={validationState.lastName.isDirty && !validationState.lastName.isValid}
          error={validationState.lastName.isDirty && !validationState.lastName.isValid}
          onChange={handleTextChange}
          onBlur={handleTextBlur}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      <Grid item xs={12}>
        <DatePickerWithLabel
          name='dateOfBirth'
          label='Date of Birth'
          dataTestId='dateOfBirth'
          value={caregiver.dateOfBirth}
          showRequiredText={true}
          required={false}
          onChange={(newDate) => { handleCaregiverChange('dateOfBirth', newDate) }}
          FormControlProps={{ className: 'pb-0 width-100' }}
          noPaddingTop={true}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectWithLabel
          name="relationshipId"
          label="Relationship to Child"
          width="100%"
          data-testid='relationshipId'
          value={caregiver.relationshipId ?? ''}
          showRequiredText={validationState.relationshipId.isDirty && !validationState.relationshipId.isValid}
          required={validationState.relationshipId.isDirty && !validationState.relationshipId.isValid}
          error={validationState.relationshipId.isDirty && !validationState.relationshipId.isValid}
          options={relationships}
          onChange={handleSelectChange}
          noTopPadding={true}
          FormControlProps={{ className: 'pb-0' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldWithLabel
          label='Primary Phone'
          name='primaryPhone'
          maxLength={50}
          type='tel'
          data-testid='primaryPhone'
          value={new AsYouType('US').input(caregiver.primaryPhone)}
          showRequiredText={validationState.primaryPhone.isDirty && !validationState.primaryPhone.isValid}
          required={validationState.primaryPhone.isDirty && !validationState.primaryPhone.isValid}
          error={validationState.primaryPhone.isDirty && !validationState.primaryPhone.isValid}
          onChange={handleTextChange}
          onBlur={handleTextBlur}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldWithLabel
          label='Secondary Phone'
          name='secondaryPhone'
          maxLength={50}
          type='tel'
          data-testid='secondaryPhone'
          value={new AsYouType('US').input(caregiver.secondaryPhone)}
          showRequiredText={true}
          required={false}
          onChange={handleTextChange}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>
      {!caregiver.doesNotHaveEmail && <Grid item xs={12}>
        <TextFieldWithLabel
          label='Email Address'
          name='email'
          maxLength={50}
          data-testid='email'
          value={caregiver.email}
          showRequiredText={validationState.email.isDirty && !validationState.email.isValid}
          required={validationState.email.isDirty && !validationState.email.isValid}
          error={validationState.email.isDirty && !validationState.email.isValid}
          onChange={handleTextChange}
          onBlur={handleTextBlur}
          className='pt-0'
          textFieldClassName='pb-0'
        />
      </Grid>}
      <Grid item xs={12}>
        <CheckBoxWithLabel
          edge='start'
          label='No Email Address'
          name='doesNotHaveEmail'
          value={caregiver.doesNotHaveEmail}
          setValue={(val) => { handleCheckChange('doesNotHaveEmail', val) }}
          buttonClassName='no-horiz-padding'
          textClassName='vertical-center-align checkbox-label'
          checkboxClassName='no-horiz-padding'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' sx={{ mb: '30px', ml: '56px', fontWeight: 300 }} component='div'>
          <CustomLabelWithToolTip
            name='IF_Caregiver_No_Email_Notice'
            isdId={isdId ?? 0}
            defaultLabelText='This should only be checked if this individual does not have access to email. Email is our primary method of contacting families.'
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectWithLabel
          name="preferredContactMethod"
          width="100%"
          data-testid='preferredContactMethod'
          value={caregiver.preferredContactMethod ?? ''}
          showRequiredText={validationState.preferredContactMethod.isDirty && !validationState.preferredContactMethod.isValid}
          required={validationState.preferredContactMethod.isDirty && !validationState.preferredContactMethod.isValid}
          error={validationState.preferredContactMethod.isDirty && !validationState.preferredContactMethod.isValid}
          options={contactMethods}
          onChange={handleSelectChange}
          noTopPadding={true}
          FormControlProps={{ className: 'pb-0' }}
          labelContent={<CustomLabelWithToolTip
            name='IF_Caregiver_Contact_Method'
            isdId={isdId ?? 0}
            defaultLabelText='Preferred Contact Method'
            defaultTooltipText='This is the primary method by which the MiECC team and providers will contact you.'
          />}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectWithLabel
          name="educationLevelID"
          label="Highest Level of Education"
          width="100%"
          data-testid='educationLevelID'
          value={caregiver.educationLevelID ?? ''}
          showRequiredText={true}
          required={false}
          options={educationLevels}
          onChange={handleSelectChange}
          noTopPadding={true}
          FormControlProps={{ className: 'pb-0' }}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckBoxWithCustomLabel
          edge='start'
          name='isLivingWithStudent'
          value={!caregiver.isLivingWithStudent}
          setValue={(val) => { handleCheckChange('isLivingWithStudent', !val) }}
          buttonClassName='no-horiz-padding'
          textClassName='vertical-center-align checkbox-label'
          checkboxClassName='no-horiz-padding'
          dataTestId='isLivingWithStudent'
          labelName='IF_Caregiver_Different_Address'
          isdId={isdId ?? 0}
          defaultLabelText='Lives at a different address than the child'
          defaultTooltipText='Checking this box will require another address to be entered'
        />
      </Grid>

      {!caregiver.isLivingWithStudent && <LocationDetails
        location={location}
        validationState={validationState}
        childLocation={false}
        isdId={isdId}
        onLocationChange={setBingMapsLocation}
        handleLocationFieldChange={handleLocationFieldChange}
        validateField={handleTextBlur}
      />}

      {isFirstCaregiver &&
        <Grid item xs={12}>
          <TextFieldWithLabel
            name='totalNumberOfHouseholdMembers'
            maxLength={3}
            type='number'
            data-testid='totalNumberOfHouseholdMembers'
            value={caregiver.totalNumberOfHouseholdMembers ?? ''}
            showRequiredText={true}
            required={false}
            onChange={handleTextChange}
            className='pt-0'
            textFieldClassName='pb-0'
            labelContent={<CustomLabelWithToolTip
              name='IF_Caregiver_Household_Members'
              isdId={isdId ?? 0}
              defaultLabelText='Total Number of Household Members'
              defaultTooltipText='This is the total number of individuals in your household.'
            />}
          />
        </Grid>
      }

      <Grid item xs={12}>
        <CheckBoxWithCustomLabel
          edge='start'
          name='legalGuardian'
          value={caregiver.legalGuardian}
          setValue={(val) => { handleCheckChange('legalGuardian', val) }}
          buttonClassName='no-horiz-padding'
          textClassName='vertical-center-align checkbox-label'
          checkboxClassName='no-horiz-padding'
          labelName='IF_Caregiver_Guardian'
          isdId={isdId ?? 0}
          defaultLabelText='Is Caregiver / Guardian'
          defaultTooltipText='Check this if you are a person who is given the authority by a court to act on behalf of this child.'
        />
      </Grid>

      {caregiver.legalGuardian && <>
        <Grid item xs={6}>
          <TextFieldWithLabel
            label='Annual Household Income'
            name='income'
            maxLength={15}
            type='number'
            data-testid='income'
            value={caregiver.income ?? ''}
            showRequiredText={validationState.income.isDirty && !validationState.income.isValid}
            required={validationState.income.isDirty && !validationState.income.isValid}
            error={validationState.income.isDirty && !validationState.income.isValid}
            onChange={handleTextChange}
            onBlur={handleTextBlur}
            className='pt-0'
            textFieldClassName='pb-0'
          />
        </Grid>
        <Grid item xs={6}>
          <SelectWithLabel
            name="taxYear"
            label="Tax Year for Income"
            width="100%"
            data-testid='taxYear'
            value={caregiver.taxYear ?? ''}
            showRequiredText={validationState.taxYear.isDirty && !validationState.taxYear.isValid}
            required={validationState.taxYear.isDirty && !validationState.taxYear.isValid}
            error={validationState.taxYear.isDirty && !validationState.taxYear.isValid}
            options={selectableYears}
            onChange={handleSelectChange}
            noTopPadding={true}
            FormControlProps={{ className: 'pb-0' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' sx={{ mb: '30px' }} component='div'>
            <CustomLabelWithToolTip
              name='IF_Caregiver_Documentation_Notice'
              isdId={isdId ?? 0}
              defaultLabelText='Income documentation may be required later in the process. The best documentation of income is the latest tax return. If this is not available, 4 weeks of pay stubs or a W-2 form may be used until a tax return is received.'
            />
          </Typography>
        </Grid>
      </>
      }

      <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex', mb: '20px' }}>
        <Button
          name='backButton'
          className='back-button'
          data-testid='caregiver-details-back-button'
          onClick={() => { setShowConfirmModal(true) }}
          variant='outlined'
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex', mb: '20px' }}>
        <Button
          name='continueButton'
          className='footer-button'
          data-testid='continue-button'
          onClick={handleContinue}
          variant='contained'
        >
          Continue
        </Button>
      </Grid>
    </Grid>

    <Modal
      open={showConfirmModal}
      onClose={() => { setShowConfirmModal(false) }}
      title='Discard Changes'
      confirmationContent='This will discard any changes. Do you want to continue?'
      confirmButtonText='Yes'
      cancelButtonText='No'
      onConfirm={handleConfirm}
      onCancel={() => { setShowConfirmModal(false) }}
      data-testid='discard-changes-modal'
    />
  </>
}
