import { Box, CircularProgress, Grid, IconButton, Link, Paper } from '@mui/material'
import { LargeCard } from '../../Components/Cards/Large'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { useEffect, useState } from 'react'
import { ConfigurationEdit } from './ConfigurationEdit'
import { type postError, type ISD } from '../../core/types'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { sendGet, sendPut } from '../../hooks/use-fetch'
import { useErrorDispatch } from '../../ContextProviders/ErrorProvider'
import EditIcon from '@mui/icons-material/Edit'
import { InterestFormLanguageModal } from './InterestFormLanguageCustomization/InterestFormLanguageModal'

export function Configuration (): JSX.Element {
  const isdID = useAccountId()?.id ?? 0
  const dispatch = useErrorDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [interestFormModalVisible, setInterestFormModalVisible] = useState(false)
  const [theIsd, setTheIsd] = useState<ISD>({} as unknown as ISD)
  const [loaded, setLoaded] = useState(false)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const languageCustomizationTooltip = 'Click the link below to customize specific elements of your interest form.  These changes are specific to your ISD but will show up for anyone filling out an interest form for your ISD.'
  const ifspTooltip = 'Enabling this setting will allow for an checkbox to capture whether a child has an IFSP on both the Guardian interest form and other places throughout the application'
  const iepTooltip = 'Enabling this setting will allow for an checkbox to capture whether a child has an IEP on both the Guardian interest form and other places throughout the application'
  const eoisTooltip = 'Enabling this setting will allow for an checkbox to capture whether a child has an EIOS on both the Guardian interest form and other places throughout the application'
  const otherCountiesTooltip = 'Enabling this setting will allow service provider facilities from other ISDs to show up in the browse providers section of the interest form'
  const intakeProviderTooltip = 'Enabling this setting will send all incoming interest form referrals to require intervention from the intake provider or ISD prior to being acted on by the selected provider(s)'
  const newProviderTooltip = 'Enabling this setting will allow a service provider to select another provider to create a referral for when the decline a referral'

  useEffect(() => {
    const getIsd = async (): Promise<void> => {
      const { response: rsp } = await sendGet('/ISDDetails/GetISDConfig?id=' + isdID.toString(), {})
      if (rsp !== null) {
        setTheIsd(rsp)
        setLoaded(true)
      }
    }
    void getIsd()
  }, [isdID, refreshTime])

  const showModal = (): void => {
    setModalVisible(true)
  }

  const hideModal = (): void => {
    setModalVisible(false)
  }

  const handleSubmit = async (newValue: ISD): Promise<void> => {
    const { response } = await sendPut('/ISDDetails/ConfigureISD', newValue)
    const resp: { success: boolean, errors: postError[], newRecordID: number } = response
    if (resp.success) {
      setRefreshTime(new Date())
      setModalVisible(false)
      setTheIsd(newValue)
    } else {
      dispatch({ errors: resp.errors, type: 'update' })
    }
  }

  if (!loaded) return <CircularProgress />

  return (
    <>
      {modalVisible && (
        <ConfigurationEdit show={modalVisible} onClose={hideModal} isdSettings={theIsd} onConfirm={handleSubmit} />
      )}
      <Paper sx={{ pt: 2, borderRadius: '32px', marginTop: '20px' }}>
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }} rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <IconButton data-testid="editButton" onClick={showModal} >
              <EditIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <LargeCard
              className='Borderless'
              content={<Box>
                <Box>
                  <TextWithLabel label='Language Customization' value='' name='languageCustomization' toolTip={languageCustomizationTooltip} />
                  <Link
                    style={{ cursor: 'pointer' }}
                    data-testid="languageLink"
                    key={'languageLink'}
                    underline="hover"
                    onClick={() => { setInterestFormModalVisible(true) }}
                  >
                    Click here to customize the interest form language
                  </Link>
                </Box>
                <TextWithLabel
                  label='Capture IFSP'
                  name='captureIFSP'
                  value={theIsd.captureIFSP ? 'true' : 'false'}
                  toolTip={ifspTooltip}
                />
                <TextWithLabel
                  label='Capture IEP'
                  name='captureIEP'
                  value={theIsd.captureIEP ? 'true' : 'false'}
                  toolTip={iepTooltip}
                />
                <TextWithLabel
                  label='Capture EOIS'
                  name='captureEOIS'
                  value={theIsd.captureEOIS ? 'true' : 'false'}
                  toolTip={eoisTooltip}
                />
                <TextWithLabel
                  label='Show results from other counties than the resident ISD'
                  name='showResultsFromOtherCounties'
                  value={theIsd.showResultsFromOtherCounties ? 'true' : 'false'}
                  toolTip={otherCountiesTooltip}
                />
                <TextWithLabel
                  label='Show Services for My Child'
                  name='showServicesForMyChild'
                  value={theIsd.showServicesForMyChild ? 'true' : 'false'}
                />
                <TextWithLabel
                  label='Show Services for Myself'
                  name='showServicesForMyself'
                  value={theIsd.showServicesForMyself ? 'true' : 'false'}
                />
                <TextWithLabel
                  label='Show Create a Referral'
                  name='showCreateReferral'
                  value={theIsd.showCreateReferral ? 'true' : 'false'}
                />
                <TextWithLabel
                  label='Adult Services Name'
                  name='adultServicesName'
                  value={theIsd.adultServicesName}
                />
                <TextWithLabel
                  label='Adult Services Link'
                  name='adultServicesLink'
                  value={theIsd.adultServicesLink}
                />
              </Box>}
              header='Interest Form Process'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <LargeCard
              className='Borderless'
              content={<Box>
                <TextWithLabel
                  label='Auto refer to Intake Provider regardless of selected providers'
                  name='referAllToIntakeProvider'
                  value={theIsd.referAllToIntakeProvider ? 'true' : 'false'}
                  toolTip={intakeProviderTooltip}
                />
                <TextWithLabel
                  label='Allow provider to select new provider on referral decline'
                  name='selectProviderOnReferralDecline'
                  value={theIsd.selectProviderOnReferralDecline ? 'true' : 'false'}
                  toolTip={newProviderTooltip}
                />

              </Box>}
              header='Enrollment Process'
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>

      <InterestFormLanguageModal
        isdid={isdID}
        open={interestFormModalVisible}
        onClose={() => { setInterestFormModalVisible(false) }}
      />
    </>
  )
}
