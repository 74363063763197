import { useEffect, useState } from 'react'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { sendGet } from '../../../hooks/use-fetch'
import { Box, Chip, CircularProgress } from '@mui/material'
import DataTable from '../../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const nameColumn = new Column('name', 'Name')
const statusColumn = new Column('isds', 'ISDs', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  const rowISds: string[] = row.isds as string[]
  const items: JSX.Element[] = []
  rowISds.forEach(isd => {
    let color = '#4DA4A4 !important'
    let backgroundColor = 'rgba(77, 164, 164, 0.1)'
    const isEnabled = row?.enabled as boolean
    if (!isEnabled) {
      color = '#D4316C !important'
      backgroundColor = 'rgba(212, 49, 108, 0.1)'
    }
    items.push(<Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={isd} size="small" sx={{ color, backgroundColor }} />)
  })
  return <>{items }</>
}
const columns = [nameColumn, statusColumn]

export function ProgramList (): JSX.Element {
  const activeAccount = useAccountId()
  const id = activeAccount?.id ?? ''
  const [programs, setprograms] = useState<Array<{ id: number, name: string, enabled: boolean }>>([])

  useEffect(() => {
    const loadDetails = async (): Promise<void> => {
      const { response: rsp } = await sendGet(`/ServiceProviderDetails/GetProgramsUsage?id=${id}`, { })
      setLoading(false)
      setprograms(rsp)
    }
    void loadDetails()
  }, [id])

  const [loading, setLoading] = useState(true)

  const paginationProps: PaginationProps<RowData> = {
    rows: programs
  }
  const pagination = usePagination(paginationProps)

  if (loading) {
    return <CircularProgress />
  } else {
    return <Box>
      <DataTable
        data-testid='facilities-table'
        name='programTable'
        hasCheckbox={false}
        page={pagination.page}

        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        initialColumnSorts={[new ColumnSort('name')]}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={() => {}}
      />
    </Box>
  }
}
