import { useEffect, useState } from 'react'
import { Chip, CircularProgress, Switch } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import documentIcon from '../../Assets/Icons/Document.png'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { Modal } from '../../Components/Modal'
import { toast } from 'react-toastify'
import { ProgramSettings } from './ProgramSettings'
import { type isdProgram } from '../../core/types'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { ErrorProvider } from '../../ContextProviders/ErrorProvider'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { TextFieldWithLabel } from '../../Components/TextField'

const nameColumn = new Column('name', 'Name')
const fundingSourceColumn = new Column('fundingSource', 'Funding Source', ColumnType.CUSTOM)
const enabledColumn = new Column('enabled', 'Enabled?', ColumnType.CUSTOM)
const settingsColumn = new Column('settings', 'Settings', ColumnType.CUSTOM)
const columns = [nameColumn, fundingSourceColumn, enabledColumn, settingsColumn]

fundingSourceColumn.customCellGenerator = row => {
  if (row.fundingSource === null || row.fundingSource === undefined) { return <></> }
  const colorItem = row.fundingSource?.id % 3
  let indicatorClass = 'cyan-'
  if (colorItem === 1) {
    indicatorClass = 'purple-'
  } else if (colorItem === 2) {
    indicatorClass = 'red-'
  }
  return <Chip icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={row.fundingSource?.name} size='small' className={indicatorClass + 'indicator'} />
}

export function ProgramISDList (): JSX.Element {
  const isdID = useAccountId()?.id ?? 0
  const [loaded, setLoaded] = useState(false)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [currentProgram, setCurrentProgram] = useState<null | isdProgram>(null)
  const [rows, setRows] = useState<isdProgram[]>([])
  const [displayModal, setDisplayModal] = useState(false)
  const [enableUpdate, setEnableUpdate] = useState<null | isdProgram>(null)
  const [searchValue, setSearchValue] = useState(null)

  const paginationProps: PaginationProps<RowData> = {
    rows
  }
  const pagination = usePagination(paginationProps)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: sd } = await sendGet(`/ISDPrograms/GetPrograms?ISDID=${isdID}`, {})
      setRows(sd)
      setLoaded(true)
    }
    void loadData()
  }, [refreshTime, isdID])

  enabledColumn.customCellGenerator = row => {
    return <Switch
      size='medium'
      value={row.systemProgramID} checked={row.enabled ?? false}
      onChange={async (event: any, checked) => { await handleEnabledChanged(event, row) }}
      data-testid={`switch-${row.id as number}`}
    />
  }

  settingsColumn.customCellGenerator = row => {
    if (row.enabled !== undefined && (row.enabled === true)) {
      return <img src={documentIcon} onClick={() => { documentClicked(row as isdProgram) }} data-testid='settingsButton'></img>
    } else {
      return <span></span>
    }
  }

  function documentClicked (program: isdProgram): void {
    setCurrentProgram(program)
    setIsDetailModalOpen(true)
  }

  const handleEnabledChanged = async (event: any, row: RowData): Promise<void> => {
    const program = row as isdProgram
    const checked = !((row.enabled as boolean) ?? false)
    program.enabled = checked

    setEnableUpdate(program)

    if (!checked) {
      setDisplayModal(true)
    } else {
      void submitEnableChanged(program)
    }
  }

  const submitEnableChanged = async (data: isdProgram | null): Promise<void> => {
    const { success } = await sendPost('/ISDPrograms/ToggleProgram', {
      ID: data?.id,
      ISDID: data?.isdid,
      SystemProgramID: data?.systemProgramID,
      enabled: data?.enabled
    })

    if (!success) {
      toast.error('There was an error saving the change.')
    } else {
      setRefreshTime(new Date())
    }
    setDisplayModal(false)
  }

  const handleClose = (): void => {
    setIsDetailModalOpen(false)
  }

  useEffect(() => {
    const fieldArray: SearchCriteria[] = []
    if (searchValue !== null) {
      fieldArray.push({
        field: 'name',
        value: searchValue
      })
    }
    pagination.setSearchFields(fieldArray)
  }, [searchValue])

  if (!loaded) return <CircularProgress />

  return (<>
    <Modal
      onClose={() => { setDisplayModal(false) }}
      onConfirm={() => {
        void submitEnableChanged(enableUpdate)
      }}
      open={displayModal}
      confirmationContent={<>Deactivating this program will disable it from being used for future enrollments. Any enrollments or referrals currently in process will be allowed to complete. Do you wish to continue?</>}
    />
    <TextFieldWithLabel
      label='Name'
      onChange={(e) => { setSearchValue(e.target.value) }}
      name='name'
      maxWidth='205px'
      value={searchValue ?? ''}
      data-test-name-search
    />
    <DataTable
      page={pagination.page}
      name='programTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows}
      totalRecords={pagination.recordCount}
      loading={false}
      onSortChange={(col, sorts) => {
        pagination.onSortChange(col, sorts[0].order)
      }}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    />

    {isDetailModalOpen && currentProgram != null && (
      <ErrorProvider>
        <ProgramSettings
          open={isDetailModalOpen}
          isdID={isdID}
          program={currentProgram}
          onClose={handleClose}
        />
      </ErrorProvider>
    )}
  </>)
}
