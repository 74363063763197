import { IconButton, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { type DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import './Modal.css'
import { forwardRef } from 'react'

export type AcceptDenyDialogProps = DialogProps & {
  id?: string
  title?: string
  titleContent?: JSX.Element
  dialogContent: React.ReactNode
  open: boolean
  maxWidth?: string
  acceptButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  hideCancelButton?: boolean
  isUpdateDisabled?: boolean
  className?: string
  onClose: () => void
  onDeny?: () => void
  onAccept?: () => void
}

export const AcceptDenyModal = forwardRef((props: AcceptDenyDialogProps, ref: React.Ref<HTMLDivElement>): JSX.Element => {
  const {
    onClose, onDeny, onAccept, title, open, titleContent, maxWidth, acceptButtonText,
    denyButtonText, hideCancelButton, className, dialogContent, cancelButtonText, isUpdateDisabled,
    ...other
  } =
    props

  const confirmationTitle = props.title ?? 'Confirm'

  function onDialogClose (event: any, reason: string): void {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  return (
    <Dialog
      ref={ref}
      maxWidth={maxWidth ?? 'xs'}
      PaperProps={{ className: className ?? 'Modal', sx: { overflow: 'hidden', '&&': { maxWidth } } }}
      open={open}
      data-testid='acceptdenymodal'
      onClose={onDialogClose}
      {...other}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }} data-testid='modal-title'>
        {
          titleContent != null
            ? titleContent
            : <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>{confirmationTitle}</Typography>
        }
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            width: 40,
            height: 40,
            color: (theme) => theme.palette.grey[900]
          }}
        >
          <CloseIcon sx={{ width: '40px', height: '40px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: '0 24px' }}>
        {props.onDeny !== null && props.onDeny !== undefined &&
          <Button
            data-testid='denyButton' className='ml-10'
            onClick={onDeny} color='error' variant='contained'
          > {denyButtonText ?? 'Deny'}</Button>}
        {hideCancelButton !== true && <Button
          data-testid='cancelButton'
          variant='outlined'
          color='secondary'
          sx={{ fontWeight: 'bold' }}
          onClick={onClose}
        >
          {props.cancelButtonText ?? 'Cancel'}
        </Button>
        }
        {props.onAccept !== null && props.onAccept !== undefined && <div>
          <Button
            data-testid='acceptButton'
            onClick={onAccept} color='success' variant='contained'
            disabled={props.isUpdateDisabled ?? false}
          > {acceptButtonText ?? 'Accept'}</Button>
        </div>}

      </DialogActions>
    </Dialog>
  )
})
