import { Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material'
import './LargeCard.css'

interface LargeCardProps {
  header?: string
  content: JSX.Element
  className?: string
  contentClassName?: string
  headerClassName?: string
  fullWidth?: boolean
  customContentWidth?: boolean
  icon?: JSX.Element
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  actions?: JSX.Element
}

export const LargeCard = (props: LargeCardProps): JSX.Element => {
  const { header, content, className, contentClassName, headerClassName, fullWidth, customContentWidth, icon, onClick, ...other } = props

  const onCardClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (onClick != null) {
      onClick(event)
    }
  }

  const cardContent = <CardContent sx={{ display: 'flex', flexDirection: 'column', width: customContentWidth === true ? 'unset' : '100%' }} className={contentClassName}>
    {header != null && <Typography data-testid='cardHeader' component="div" variant="h5" className={'d-flex f-align-items-center ' + (headerClassName ?? '')}>{header}{icon}</Typography>}
    {content}
  </CardContent>

  return (
    <Card sx={{ maxWidth: fullWidth === true ? '100%' : 345, display: 'flex', flexDirection: 'row', borderRadius: '32px' }} className={className} {...other}>
      {props.onClick !== undefined && (<>
        <CardActionArea onClick={(e) => { onCardClick(e) }} >
          {cardContent}
        </CardActionArea>
        <CardActions sx={{ alignItems: 'flex-end', p: 0 }}>
          {props.actions}
        </CardActions>
      </>
      )}
      {props.onClick === undefined && (<>
        {cardContent}
      </>
      )}
    </Card>
  )
}
