import { useState } from 'react'
import { LargeCard } from '../../Components/Cards/Large'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { CircularProgress, IconButton } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TextFieldWithLabel } from '../../Components/TextField'
import { type postError, type User } from '../../core/types'
import EditIcon from '@mui/icons-material/Edit'
import { sendPut } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { type AuthType } from '../../hooks/use-auth'

export function UserProfileDisplay (props: { auth: AuthType }): JSX.Element {
  const [showEdit, setShowEdit] = useState(false)
  const editClick = (): void => {
    setShowEdit(true)
  }
  const closeEdit = (): void => {
    setShowEdit(false)
  }
  const handleSubmit = async (updatedUser: User): Promise<void> => {
    const entity = { FirstName: updatedUser.firstName, LastName: updatedUser.lastName, ID: updatedUser.id, PhoneNumber: updatedUser.phoneNumber }
    const userUpdateUrl = '/User/UpdateUserProfile'
    const { response } = await sendPut(userUpdateUrl, entity)

    const resp: { success: boolean, errors: postError[], newRecordID: number } = response
    if (resp.success) {
      await props.auth.setCurrentUser()
      setShowEdit(false)
      toast.success('Profile Updated')
    } else {
      // ??
    }
  }

  if (props.auth.loading) return <CircularProgress />
  return <><LargeCard
    content={<>
      <TextWithLabel name="firstName" label="First Name" value={props.auth.user?.firstName } />
      <TextWithLabel name="lastName" label="Last Name" value={props.auth.user?.lastName } />
      <TextWithLabel name="email" label="Email Address" value={props.auth.user?.email} />
      <TextWithLabel name="phoneNumber" label="Phone Number" value={props.auth.user?.phoneNumber} />
    </>}
    header='Basic Information'
    icon={<IconButton onClick={editClick}><EditIcon /></IconButton>}
  />
    <UserProfileEdit user={props.auth.user as User} show={showEdit} onClose={closeEdit} onSubmit={handleSubmit} />
  </>
}
export function UserProfileEdit (props: { user: User, show: boolean, onClose: () => void, onSubmit: (updatedUser: User) => void }): JSX.Element {
  const [user, setUser] = useState(props.user)
  const handleChange = (e: any): void => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }
  const handleClose = (): void => {
    props.onClose()
  }
  const handleSubmit = (): void => {
    props.onSubmit(user)
  }
  return <Modal
    onClose={handleClose}
    onConfirm={handleSubmit}
    open={props.show}
    confirmationContent={ <LargeCard
      header='Basic Information'
      content={<>
        <TextFieldWithLabel onChange={handleChange} name="firstName" label="First Name" value={user.firstName} />
        <TextFieldWithLabel onChange={handleChange} name="lastName" label="Last Name" value={user.lastName} />
        <TextWithLabel name="email" label="Email Address" value={user.email} />
        <TextFieldWithLabel onChange={handleChange} name="phoneNumber" label="Phone Number" value={user.phoneNumber} />
      </>
      }
    />}
  />
}
