import { Box, Button } from '@mui/material'
import { LabelWithToolTip } from '../../Label/Index'
import { LocationCard } from '../LocationCard'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { type location, type ChildAuditAccessTypeEnum } from '../../../core/types'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { NotAllowed } from '../../../Routes/ProtectedRoute'
import { toast } from 'react-toastify'

interface MultiCardListProps {
  entityId: number
  primaryLocationId: number
  label: string
  toolTip?: string
  requestingModule?: ChildAuditAccessTypeEnum
  onDataChange?: () => void
}

export function LocationCardList (props: MultiCardListProps): JSX.Element {
  const account = useAccountId()
  const [locationList, setLocationList] = useState<Array<{ id: number, locationId: number }>>([])
  const [primaryLocationID, setPrimaryLocationID] = useState<number>(props.primaryLocationId)
  const [locationIndex, setLocationIndex] = useState(0)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [unauthorized, setUnauthorized] = useState(false)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response, success, error, status } = await sendGet(`/ChildDetails/Find?id=${props.entityId}&accountId=${account?.id ?? 0}&accountType=${account?.type ?? ''}&requestingModule=${String(props.requestingModule) ?? ''}`, {})
      if (success) {
        const details = response.response
        const addrs = details.childLocations as location[]
        const locations: Array<{ id: number, locationId: number }> = addrs.map((a, i) => {
          return { id: i, locationId: a.id }
        })
        setPrimaryLocationID(details.primaryLocationID ?? 0)
        setLocationList(locations)
        setLocationIndex(locations.length)
      } else {
        if (status === 401) {
          setUnauthorized(true)
        }
        error.forEach(e => toast.error(e))
      }
    }
    void loadData()
  }, [refreshTime, props.entityId])

  const onDeleteClick = (id: number): void => {
    const tempArray = [...locationList]
    tempArray.splice(tempArray.findIndex(v => v.id === id), 1)
    setLocationList([...tempArray])
  }

  const addSection = (): void => {
    setLocationList([...locationList, { id: locationIndex, locationId: 0 }])
    setLocationIndex(locationIndex + 1)
  }

  const dataChanged = (): void => {
    setRefreshTime(new Date())
    if (props.onDataChange != null) {
      props.onDataChange()
    }
  }

  if (unauthorized) return <NotAllowed />

  return (
    <Box>
      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          isInline={false}
          required={false}
          name={''}
          labelText={props.label}
          toolTipText={props.toolTip}
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />

        {
          locationList.map((val) => {
            const isPrimary = val.locationId === primaryLocationID
            const loc =
              <Box sx={{ marginBottom: '20px' }} key={val.locationId}>
                <LocationCard
                  key={val.locationId}
                  locationId={val.locationId}
                  entityId={props.entityId}
                  isPrimary={isPrimary}
                  controller='CareNetworkEdit'
                  lookupEndPoint='GetLocation'
                  updateEndPoint='UpdateLocation'
                  createEndPoint='CreateLocation'
                  deleteEndPoint='DeleteLocation'
                  deleteDisabled={false}
                  onDelete={() => { onDeleteClick(val.id) }}
                  setRefreshTime={() => { dataChanged() }}
                />
              </Box>
            return loc
          })
        }
      </Box>
      <div className="d-flex f-justify-content-center">
        <Button
          size="small"
          variant="text"
          sx={{ color: '#595959', textTransform: 'none' }}
          onClick={e => { addSection() }}
          data-testid="addLocation"
        >
          Add {props.label} +
        </Button>
      </div>
    </Box>
  )
}
