import { Box, CircularProgress, Container, Tab, Tabs, Typography } from '@mui/material'
import { BackArrow } from '../../../Assets/SVGs/back-arrow'
import { CAREGIVER_PORTAL_MEMBERS } from '../../Routes'
import { useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { type PortalLocation } from '../../../core/types'
import { childName } from '../PortalHelpers'
import { toast } from 'react-toastify'
import { TabDisplay } from '../../../Components/TabDisplay'
import { ChildDetails } from './ChildDetails'
import { ChildServices } from './ChildServices'
import { DocumentList } from '../../../Components/DocumentList'
import { ChildRelationships } from './ChildRelationships'

export interface ChildMemberDetails {
  id: number
  isdid: number
  firstName: string
  lastName: string
  middleName: string
  nickname: string
  suffix: string
  dateOfBirth: Date | null
  birthGender: string | undefined
  races: number[]
  languageID: number | undefined
  primaryLanguage: string
  hasIEP: boolean
  hasIFSP: boolean
  hasEOIS: boolean
  fosterChild: boolean
  hasSNAPBenefits: boolean
  isOnCashAssistance: boolean
  isOnSSI: boolean
  dueDate: Date | null
  unborn: boolean
  primaryLocationID: number | null
  locationIDs: number[]
  location: PortalLocation | null
  canEdit: boolean
}

export function ChildDisplay (): JSX.Element {
  const nav = useNavigate()
  const { childId } = useParams()
  const [child, setChild] = useState<ChildMemberDetails | null>(null)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState(0)
  const yearOptions: number[] = []

  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 6; i <= currentYear + 1; i++) {
    yearOptions.push(i)
  }

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const { response: childRsp, success } = await sendGet(`/CaregiverPortal/GetChildDetails/${childId ?? 0}`, {})
      if (success) {
        setChild(childRsp)
      } else {
        toast.error('Failed to load child details')
      }
      setIsLoading(false)
    }

    void getData()
  }, [childId, refreshTime])

  if (isLoading || child == null) {
    return <CircularProgress />
  } else {
    return <Container sx={{ mb: '30px' }}>
      <Box sx={{ mt: '30px', mb: '30px', display: 'flex' }}>
        <Box onClick={() => { nav(CAREGIVER_PORTAL_MEMBERS) }} sx={{ cursor: 'pointer' }}>
          <BackArrow />
        </Box>
        <Typography variant='h5' sx={{ fontWeight: 600, ml: '5px' }} component='div'>
          {childName(child)}
        </Typography>
      </Box>

      <Tabs value={currentTab} onChange={(e, val) => { setCurrentTab(val) }}>
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid="details" label='Details' value={0} />
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid="details" label='Relationships' value={1} />
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid='services' label='Services' value={2} />
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid='documents' label='Documents' value={3} />
      </Tabs>

      <TabDisplay
        index={0}
        value={currentTab}
      >
        <ChildDetails child={child} updateData={() => { setRefreshTime(new Date()) }} />
      </TabDisplay>

      <TabDisplay
        index={1}
        value={currentTab}
      >
        <ChildRelationships childID={child.id} canEdit={child.canEdit} />
      </TabDisplay>

      <TabDisplay
        index={2}
        value={currentTab}
      >
        <ChildServices childID={child.id} canEdit={child.canEdit} />
      </TabDisplay>

      <TabDisplay
        index={3}
        value={currentTab}
      >
        {child.isdid === 0 && <Typography variant='h6'>Please save the child details before adding documents. Child must belong to an ISD.</Typography>}
        {child.isdid > 0 && <DocumentList
          childID={child.id}
          caregiverID={null}
          editable={child.canEdit}
          canVerify={false}
          showDisclaimer={child.canEdit}
          isdId={child.isdid}
          years={yearOptions}
        />}
      </TabDisplay>
    </Container>
  }
}
