import { Box } from '@mui/material'
import { LabelWithToolTip } from '../Label/Index'

interface Props {
  label: string
  name: string
  value?: string | null | undefined
  content?: JSX.Element
  inline?: boolean
  maxWidth?: string
  toolTip?: string
  className?: string
  testId?: string
}

export const TextWithLabel = (props: Props): JSX.Element => {
  const isInline = props.inline ?? false

  return (
    <Box
      data-testid={props.testId}
      sx={{
        pt: '8px',
        flexDirection: isInline ? 'row' : 'column',
        display: 'flex',
        maxWidth: props.maxWidth,
        margin: '5px 5px 5px 0'
      }}
      className={props.className}
    >
      <LabelWithToolTip
        sx={{ fontWeight: '600' }}
        isInline={isInline}
        required={false}
        maxWidth={props.maxWidth}
        name={props.name}
        labelText={props.label}
        toolTipText={props.toolTip}
      />

      <Box aria-label={props.label} data-testid={props.name + 'TextDisplay'} sx={{ marginLeft: '10px', minHeight: '20px' }}>
        {props.content !== undefined
          ? props.content
          : props.value
        }
      </Box>
    </Box>
  )
}
