import { Box } from '@mui/material'
import { TextFieldWithLabel } from '../../../Components/TextField'

interface StaffSearchProps {
  name: string
  email: string
  onChange: (e: any) => void
}
export function StaffSearch (props: StaffSearchProps): JSX.Element {
  return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <Box sx={{ margin: '10px' }}>
      <TextFieldWithLabel
        data-testid='nameField'
        label='Staff Name'
        name='name'
        onChange={props.onChange}
        value={props.name}
      />
    </Box>
    <Box sx={{ margin: '10px' }}>
      <TextFieldWithLabel
        data-testid='emailField'
        label='Staff Email'
        name='email'
        onChange={props.onChange}
        value={props.email}
      />
    </Box>
  </Box>
}
