import { useEffect, useState } from 'react'
import { sendGet, sendPut } from '../../hooks/use-fetch'
import DataTable from '../../Components/Table/DataTable'
import { Modal } from '../../Components/Modal'
import { Column, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../hooks/use-pagination'
import { type SelectChangeEvent, Typography } from '@mui/material'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { SelectWithLabel } from '../../Components/SelectWithLabel'

interface PriorityConfiguration {
  districts: districtInfo[]
  serviceProviders: Array<{
    id: number
    name: string
  }>
  priorityProgram: { id: number, name: string }
}
interface districtInfo {
  id: number
  districtName: string
  defaultProviderID?: number
}

export function PriorityProviderDefaultConfiguration (props: { isdid: number }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [data, setData] = useState<PriorityConfiguration>({ districts: [], serviceProviders: [], priorityProgram: { id: 0, name: 'none' } })
  const [showModal, setShowModal] = useState(false)
  const [refreshTime, setRefreshTime] = useState<Date>(new Date())
  const [editingRow, setEditingRow] = useState<{ id: number, districtName: string, defaultProviderID?: number }>({ id: 0, districtName: 'None' })
  const distirctNameColumn = new Column('districtName', 'District Name')
  const selectedProviderColumn = new Column('defaultProviderID', 'Default Provider', ColumnType.CUSTOM)
  selectedProviderColumn.customCellGenerator = row => {
    const val = data.serviceProviders.filter(i => i.id === (row.defaultProviderID ?? 0))
    if (val.length > 0) { return <>{val[0].name}</> }
    return <></>
  }
  const columns = [distirctNameColumn, selectedProviderColumn]

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const { response: rsp } = await sendGet(`/ISDPrograms/GetPriorityConfigurationData?isdid=${props.isdid}`, {})
      const rspns: PriorityConfiguration = rsp
      rspns.serviceProviders = [{ id: 0, name: 'None' }, ...rsp.serviceProviders]
      setData(rspns)
    }
    void getData()
  }, [refreshTime])
  const paginationProps: PaginationProps<RowData> = {
    rows: data?.districts ?? [],
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)
  const handleRowClick = (e: any, rowData: RowData): void => {
    setShowModal(true)
    const vals: { id: number
      districtName: string
      defaultProviderID?: number
    } = {
      id: rowData.id,
      districtName: rowData.districtName,
      defaultProviderID: rowData.defaultProviderID
    }
    setEditingRow(vals)
  }
  const handleUpdate = (e: SelectChangeEvent<number>): void => {
    const val: number = parseInt(e.target.value.toString())
    const newVals: districtInfo = { ...editingRow, defaultProviderID: val }
    setEditingRow(newVals)
  }
  const handleSubmit = async (): Promise<void> => {
    const { response: rsp } = await sendPut('/ISDPrograms/SetPriorityConfiguration', editingRow)
    if (rsp === true) {
      setShowModal(false)
      setRefreshTime(new Date())
    } else {
      //
    }
  }
  return <>
    <Typography variant='h5' >
      Default Providers
    </Typography>
    <DataTable
      hasCheckbox={false}
      loading={false}
      columns={columns}
      rows={pagination.internalRows}
      name={'defaultProviderListTable'}
      page={pagination.page}
      totalRecords={pagination.recordCount}
      onPageChange={pagination.handleChangePage}
      onRowClick={handleRowClick}
    />
    <Modal
      onClose={() => { setShowModal(false) }}
      open={showModal}
      onConfirm={handleSubmit}
      confirmationContent={<>
        <TextWithLabel label={'District ' + editingRow.districtName} name={'district name'} />
        <SelectWithLabel
          label='Selected Default Provider'
          options={data.serviceProviders}
          value={editingRow?.defaultProviderID ?? 0}
          onChange={handleUpdate}
          name={'provider value'}
        />
      </>}
    />
  </>
}
