import { ThemeProvider } from '@mui/system'
import { Route, Routes, useLocation } from 'react-router'
import { Layout } from '../Components/Layout'
import { NotAllowed, ProtectedRoute } from '../Routes/ProtectedRoute'
import * as AppRoutes from './Routes'
import { mieccTheme } from '../theme'
import { AboutPage } from './AboutPage'
import { UserManagement } from './UserManagement'
import { CareNetworkManagement } from './CareNetworkManagement'
import { CareNetworkDetail } from './CareNetworkManagement/CareNetworkDetail/Index'
import { ApplicationSettings } from './ApplicationSettings'
import { ISDManagement } from './ISDManagement'
import { StyledEngineProvider } from '@mui/material/styles'
import { PermissionsService as PS } from '../services/permissions-service'
import { PlaceHolder } from './Placeholder'
import { ISDSettings } from './ISDSettings'
import { ChangePassword } from './ChangePassword'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ToastifyOverrides.css'
import { SettingsSplashPage } from './ServiceProvider/SettingsSplash'
import { ServiceSplashPage } from './ServiceProvider/Services/ServicesSplash'
import { ISDServiceProviderManagement } from './ISD/ServiceProviderManagement'
import { ServiceProviderStaffList } from './ServiceProvider/Staff/SettingsStaff'
import { FacilitiesListPage } from './ServiceProvider/Facilities/FacilitiesList'
import { SessionBlockList } from './ServiceProvider/Services/SessionBlockList'
import { SessionList } from './ServiceProvider/Services/SessionList'
import { useEffect } from 'react'
import { sendPost } from '../hooks/use-fetch'
import { useAuth } from '../hooks/use-auth'
import { AccountProvider } from '../ContextProviders/CurrentAccount'
import { SessionTabs } from './ServiceProvider/Services/SessionTabs'
import { ReferralSplashScreen } from './Referral'
import { EnrollmentSplashPage } from './ServiceProvider/Enrollments/EnrollmentSplash'
import { ReferalTabs } from './Referral/ReferralTabs'
import { ErrorProvider } from '../ContextProviders/ErrorProvider'
import { EnrollmentTabs } from './ServiceProvider/Enrollments/Index'
import { FacilityPage } from './ServiceProvider/Facilities/FacilityPage'
import { IncomeApprovalList } from './IncomeApproval'
import { AccessRequestList } from './AccessRequest'
import { UserProfile } from './UserManagement/UserProfile'
import { CaregiverPortalHomePage } from './CaregiverPortal/Home/HomePage'
import { CaregiverWizard } from '../ContextProviders/CaregiverWizard'
import { CaregiverEnrollments } from './CaregiverPortal/InterestForm/CaregiverEnrollments'
import { ProviderSettingsTabs } from './ServiceProvider/Settings/ProviderSettingsTabs'
import { useLocalize } from '../hooks/use-localize'
import { CaregiverPostSubmit } from './CaregiverPortal/PostSubmit/CaregiverPostSubmit'
import { CaregiverDashboard } from './CaregiverPortal/Dashboard/CaregiverDashboard'
import { CaregiverMembers } from './CaregiverPortal/Members/CaregiverMembers'
import { CaregiverEnrollmentDetails } from './CaregiverPortal/Enrollment/CaregiverEnrollmentDetails'
import { ServiceFinder } from './CaregiverPortal/ServiceFinder/ServiceFinder'
import { AuthProvider } from '../ContextProviders/AuthProvider'
import { ChildDisplay } from './CaregiverPortal/Members/ChildDisplay'
import { CaregiverDisplay } from './CaregiverPortal/Members/CaregiverDisplay'
import { ChildEdit } from './CaregiverPortal/Members/ChildEdit'
import { CaregiverEdit } from './CaregiverPortal/Members/CaregiverEdit'
import { ReportList } from './Reports/ReportList'

function App (): JSX.Element {
  const { user } = useAuth()
  const location = useLocation()
  useLocalize()

  useEffect(() => {
    if (user?.isImpersonating ?? false) {
      void sendPost('/PageVisit/LogPageVisit', { pageUrl: location.pathname })
    }
  }, [location, user])

  return (
    <StyledEngineProvider injectFirst>
      <AuthProvider >
        <ThemeProvider theme={mieccTheme}>
          <AccountProvider>
            <CaregiverWizard>
              <Layout>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar
                  closeOnClick
                  draggable={false}
                  theme="colored"
                />
                <Routes>
                  <Route
                    index={true}
                    path={AppRoutes.LANDING_PATH}
                    element={
                      <AboutPage />
                  }
                  />
                  <Route
                    path={AppRoutes.UNAUTHORIZED}
                    element={
                      <NotAllowed />
                  }
                  />
                  <Route
                    element={
                      <ProtectedRoute allow={(user?) => { return user !== null }} />
                  }
                  >
                    <Route
                      path={AppRoutes.MY_PROFILE}
                      element={<UserProfile />}
                    />
                    <Route
                      path={AppRoutes.CHANGE_PASSWORD}
                      element={
                        <ChangePassword />
                    }
                    />
                  </Route>

                  <Route
                    element={
                      <ProtectedRoute allow={PS.CaregiverPortal} noContainer={true} allowAnonymous={true} />
                  }
                  >
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL}
                      element={<CaregiverPortalHomePage />}
                    />
                    <Route
                      element={
                        <ProtectedRoute allow={PS.CaregiverPortal_Enrollments} noContainer={true} allowAnonymous={true} />
                    }
                    >
                      <Route
                        path={AppRoutes.CAREGIVER_ENROLLMENTS}
                        element={
                          <CaregiverEnrollments />
                      }
                      />
                      <Route
                        path={AppRoutes.CAREGIVER_PORTAL_SUBMITTED}
                        element={
                          <CaregiverPostSubmit />
                      }
                      />
                    </Route>
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_DASHBOARD}
                      element={<CaregiverDashboard />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_MEMBERS}
                      element={<CaregiverMembers />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_MEMBERS_CHILD_DETAILS}
                      element={<ChildDisplay />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_MEMBERS_EDIT_CHILD}
                      element={<ChildEdit />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_MEMBERS_CAREGIVER_DETAILS}
                      element={<CaregiverDisplay />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_MEMBERS_EDIT_CAREGIVER}
                      element={<CaregiverEdit />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_ENROLLMENT_DETAILS}
                      element={<CaregiverEnrollmentDetails />}
                    />
                    <Route
                      path={AppRoutes.CAREGIVER_PORTAL_SERVICE_FINDER}
                      element={<ServiceFinder />}
                    />
                  </Route>

                  <Route
                    element={
                      <ProtectedRoute allow={PS.CareNetwork} />
                  }
                  >
                    <Route
                      path={AppRoutes.CARENETWORK_MANAGEMENT}
                      element={<CareNetworkManagement />}
                    />
                    <Route
                      path={AppRoutes.CARENETWORK_MANAGEMENT_DETAILS}
                      element={<CareNetworkDetail />}
                    />

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_Caregiver} />
                    }
                    >
                      <Route
                        path={AppRoutes.CAREGIVER_MANAGEMENT}
                        element={<PlaceHolder title={'Caregiver Management'} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_NetworkMembers} />
                    }
                    >
                      <Route
                        path={AppRoutes.CARENETWORK_MEMBERS}
                        element={<PlaceHolder title={'Network Members'} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_Documentation} />
                    }
                    >
                      <Route
                        path={AppRoutes.DOCUMENTATION}
                        element={<PlaceHolder title={'Documentation'} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_IncomeRequest} />
                    }
                    >
                      <Route
                        path={AppRoutes.INCOME_REQUEST}
                        element={<PlaceHolder title={'Income Request'} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_SensitiveDetails} />
                    }
                    >
                      <Route
                        path={AppRoutes.SENSITIVE_DETAILS}
                        element={<PlaceHolder title={'Sensitive Details'} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_Enrollment} />
                    }
                    >
                      <Route
                        path={AppRoutes.ENROLLMENT_DETAILS}
                        element={<PlaceHolder title={'Enrollment Details'} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute allow={PS.CareNetwork_Communication} />
                    }
                    >
                      <Route
                        path={AppRoutes.COMMUNICATION}
                        element={<PlaceHolder title={'Communication'} />}
                      />
                    </Route>
                  </Route>

                  <Route
                    element={<ProtectedRoute allow={PS.Enrollment} />}
                  >
                    <Route
                      path={AppRoutes.REFERRAL_MANAGEMENT}
                      element={<ReferralSplashScreen />}
                    />
                    <Route
                      path={AppRoutes.REFERRALS}
                      element={<ReferalTabs />}
                    />
                    <Route
                      path={AppRoutes.ENROLLMENT_MANAGEMENT}
                      element={<EnrollmentSplashPage />}
                    />
                    <Route
                      path={AppRoutes.ENROLLMENT_MANAGEMENT_DETAILS}
                      element={<EnrollmentTabs />}
                    />
                    <Route
                      path={AppRoutes.ENROLLMENT_DETAILS}
                      element={<PlaceHolder title='enrollment details' />}
                    />
                  </Route>
                  <Route
                    element={<ProtectedRoute allow={PS.Reports} />}
                  >
                    <Route
                      path={AppRoutes.REPORTS}
                      element={<ReportList />}
                    />
                  </Route>
                  <Route
                    element={<ProtectedRoute allow={PS.WaiverApprovals} />}
                  >
                    <Route
                      path={AppRoutes.WAIVER_APPROVALS}
                      element={<IncomeApprovalList />}
                    />
                  </Route>
                  <Route
                    element={<ProtectedRoute allow={PS.ISDs_Approvals} />}
                  >
                    <Route
                      path={AppRoutes.ACCESS_REQUESTS}
                      element={<AccessRequestList />}
                    />
                  </Route>
                  <Route
                    element={<ProtectedRoute allow={PS.ServiceProviders} />}
                  >
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_SERVICES}
                      element={<ServiceSplashPage />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_SESSION_BLOCK}
                      element={<SessionBlockList />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION}
                      element={<SessionList />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_SESSION_BLOCK_SESSION_DETAILS}
                      element={<SessionTabs />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_SETTINGS}
                      element={<SettingsSplashPage />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_DETAILS}
                      element={<ProviderSettingsTabs />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_FACILITIES}
                      element={<FacilitiesListPage />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_FACILITIES_DETAILS}
                      element={<FacilityPage />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_ADMIN_STAFF}
                      element={<ServiceProviderStaffList />}
                    />
                  </Route>

                  <Route
                    element={<ProtectedRoute allow={PS.ISDs} />}
                  >
                    <Route
                      path={AppRoutes.ISD_SETTINGS}
                      element={<ISDSettings />}
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_MANAGEMENT}
                      element={<ISDServiceProviderManagement />}
                    />
                    <Route
                      element={<ProtectedRoute allow={PS.ISDs_Approvals} />}
                    >
                      <Route
                        path={AppRoutes.INCOME_APPROVAL}
                        element={<IncomeApprovalList />}
                      />
                      <Route
                        path={AppRoutes.FUNDING_ALLOCATION}
                        element={<PlaceHolder title='Funding Allocation' />}
                      />

                    </Route>

                  </Route>

                  <Route
                    element={<ProtectedRoute allow={PS.MiECCAdmin} />}
                  >
                    <Route
                      path={AppRoutes.ISD_MANAGEMENT}
                      element={
                        <ISDManagement />
                    }
                    />
                    <Route
                      path={AppRoutes.SERVICE_PROVIDER_MANAGEMENT}
                      element={
                        <ISDServiceProviderManagement />
                    }
                    />
                    <Route
                      path={AppRoutes.APPLICATION_SETTINGS}
                      element={
                        <ApplicationSettings />
                    }
                    />
                  </Route>
                  <Route
                    element={<ProtectedRoute allow={PS.Users} />}
                  >
                    <Route
                      path={AppRoutes.USER_MANAGEMENT}
                      element={
                        <ErrorProvider>
                          <UserManagement />
                        </ErrorProvider>
                    }
                    />
                  </Route>

                </Routes>
              </Layout>
            </CaregiverWizard>
          </AccountProvider>
        </ThemeProvider>
      </AuthProvider>
    </StyledEngineProvider>
  )
}

export default App
