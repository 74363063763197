import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material'

interface Props {
  current: string
  icon: JSX.Element
  label: string
  value: string
  onClick: (label: string) => void
}
export function NavBarItem (props: Props): JSX.Element {
  const theme = useTheme()
  const color = props.current === props.value ? theme.palette.primary.main : '#595959'
  const backgroundColor = props.current === props.value ? theme.palette.primary.light : 'transparent'
  return (
    <ListItem sx={{ color, backgroundColor, cursor: 'pointer' }} onClick={() => { props.onClick(props.value) }}>
      <ListItemIcon sx={{ color }}>
        {props.icon}
      </ListItemIcon>
      <ListItemText primary={props.label} />
    </ListItem>
  )
}
