import { useEffect, useState } from 'react'
import { type postError, type location } from '../../../core/types'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'

interface LocationDataProps {
  locationId: number
  entityId: number
  controller: string
  lookupEndPoint: string
  updateEndPoint: string
  createEndPoint: string
  deleteEndPoint?: string
}

export function useLocationData (props: LocationDataProps): {
  data: location
  loaded: boolean
  errors: string[] | undefined
  handleUpdate: (dat: location) => Promise<number>
  handleDelete: (locId: number) => Promise<number>
} {
  const [data, setData] = useState<location>({ address: '', apartment: '', city: '', id: 0, note: '', state: '', zipCode: '' })
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (props.locationId === 0 && !loaded) {
        setLoaded(true)
        return
      }
      const { response: rsp } = await sendGet(`/${props.controller}/${props.lookupEndPoint}?id=${props.locationId}`, { })
      if (rsp !== null) {
        setData(rsp)
        setLoaded(true)
      }
    }
    void loadData()
  }, [props.locationId, props.lookupEndPoint, refreshTime])

  const [errors, setErrors] = useState<string[] | undefined>()

  const handleDelete = async (locId: number): Promise<number> => {
    if (props.deleteEndPoint === undefined) return 0
    const submitData: { locationId: number } = { locationId: locId }
    const { response: rsp } = await sendPost(`/${props.controller}/${props.deleteEndPoint}`, submitData)
    const result: { newRecordID: number, errors: postError[], success: boolean } = rsp
    if (result.success) {
      setErrors(undefined)
      setRefreshTime(new Date())
      return 1
    } else {
      const errorList: string[] = []
      result.errors?.forEach((e) => {
        errorList.push(e.error)
      })
      setErrors(errorList)
      return 0
    }
  }

  const handleUpdate = async (dat: location): Promise<number> => {
    const { isPrimary, ...loc } = dat
    const submitData: { entityId: number, Location: location, isPrimary: boolean } = { entityId: props.entityId, Location: loc, isPrimary: (isPrimary ?? false) }
    if (dat.id === 0) {
      const { response: rsp } = await sendPost(`/${props.controller}/${props.createEndPoint}`, submitData)
      const result: { newRecordID: number, errors: postError[], success: boolean } = rsp
      if (result.success) {
        setErrors(undefined)
        setRefreshTime(new Date())
        setData({ ...dat, id: result.newRecordID })
        return 1
      } else {
        const errorList: string[] = []
        result.errors?.forEach((e) => {
          errorList.push(e.error)
        })
        setErrors(errorList)
        return 0
      }
    } else {
      const { response: rsp } = await sendPut(`/${props.controller}/${props.updateEndPoint}`, submitData)
      const result: { newRecordID: number, errors: postError[], success: boolean } = rsp
      if (result.success) {
        setErrors(undefined)
        setData({ ...dat, id: result.newRecordID })
        return 1
      } else {
        const errorList: string[] = []
        result.errors?.forEach((e) => {
          errorList.push(e.error)
        })
        setErrors(errorList)
        return 0
      }
    }
  }

  return { data, loaded, errors, handleUpdate, handleDelete }
}
