import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box } from '@mui/system'
import { type SxProps, type Theme } from '@mui/material'

interface AccordionProps {
  title: string
  icon: JSX.Element
  summary?: string
  body: JSX.Element
  tag?: string
  width: string
  className: string | undefined
  dataTestid?: string | undefined
  sx?: SxProps<Theme>
  expanded?: boolean | null
}
export default function SimpleAccordion (props: AccordionProps): JSX.Element {
  const { title, icon, summary, body, tag } = props
  const [expanded, setExpanded] = React.useState(props.expanded ?? false)

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded)
        }}
        data-testid={props.dataTestid ?? 'accordionButton'}
        sx={{
          width: `${props.width}`,
          border: '1px solid black',
          borderRadius: '62px',
          overflow: 'hidden',
          ...props.sx
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <RemoveIcon sx={{ color: 'black' }} /> : <AddIcon sx={{ color: 'black' }}/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={props.className}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '95px'
          }}
        >
          <Box sx={{ height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {icon}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '18px', height: '70px', justifyContent: 'center', alignItems: 'left' }}>
            <Typography sx={{ fontWeight: 'bold' }} variant='body1'>{title}</Typography>
            {summary !== undefined && (<Typography variant='caption'>{summary}</Typography>)}
            {tag !== undefined && (<Typography variant='caption' sx={{ fontWeight: 'bold', pt: 1, color: '#02AED9' }}>{tag}</Typography>)}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: '1px solid black' }}>
          {body}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
