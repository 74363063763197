import { Box, Typography } from '@mui/material'
import { BlockButton } from '../../../Components/Button/BlockButton'
import { sendGet } from '../../../hooks/use-fetch'
import { type service } from '../../../core/types'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { SERVICE_PROVIDER_ADMIN_SESSION_BLOCK } from '../../Routes'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export function ServiceSplashPage (): JSX.Element {
  const activeAccount = useAccountId()
  const [services, setServices] = useState<service[]>([])
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (activeAccount == null) { return }

      const { response } = await sendGet(`/ServiceProviderDetails/GetProviderServices?id=${String(activeAccount?.id ?? 0)}`, {})
      if (response !== undefined && response.length > 0) {
        setServices(response)
      }
    }
    void fetchData()
  }, [activeAccount])

  const getIcon = (s3Key: string | null): any => {
    if (s3Key !== null) {
      return <img
        src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${s3Key}`}
        alt='logo'
        data-testid={'logo-' + s3Key}
        style={{ maxWidth: '100px' }}
      />
    } else { return <></> }
  }

  const serviceClick = (serviceId: number): void => {
    const editSessionBlocks = generatePath(SERVICE_PROVIDER_ADMIN_SESSION_BLOCK, {
      serviceId: serviceId.toString()
    })

    nav(editSessionBlocks)
  }

  return (<>
    <Typography variant="h3" data-test-page-header>Services</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }} data-testid='buttonBox'>
      {services.map((s) => (
        <BlockButton key={s.id} data-testid="raw" marginOverride='20px 20px 20px 0px' text={`${s.name}`} onClick={() => { serviceClick(s.id) }} icon={getIcon(s.s3Key)} backgroundColor='rgba(241, 235, 248, 1)' />
      ))}
    </Box>
  </>
  )
}
