import { type Dispatch, createContext, useReducer, useContext, useEffect } from 'react'
import { type ActiveAccount } from '../core/types'
import { sendPut } from '../hooks/use-fetch'

export const AccountContext = createContext<ActiveAccount | undefined>(undefined)
export const AccountDispatchContext =
        createContext<Dispatch<{
          account: ActiveAccount | undefined
          type: string
        }>>(() => null)

export function useAccountId (): ActiveAccount | undefined {
  return useContext(AccountContext)
}

export function useAccountIdDispatch (): Dispatch<{
  account: ActiveAccount | undefined
  type: string
}> {
  return useContext(AccountDispatchContext)
}
export function AccountProvider (props: { children: JSX.Element }): JSX.Element {
  const [accountId, dispatch] = useReducer(accountIdReducer, initialAccount)
  useEffect(() => {
    const localStorage = window.localStorage.getItem('selectedOption')
    const val = JSON.parse(localStorage ?? 'null') as ActiveAccount
    dispatch({ account: val, type: 'update' })
  }, [])

  return (
    <AccountContext.Provider value={accountId}>
      <AccountDispatchContext.Provider value={dispatch}>
        {props.children}
      </AccountDispatchContext.Provider>
    </AccountContext.Provider>
  )
}
function accountIdReducer (account: ActiveAccount | undefined, action: { account: ActiveAccount | undefined, type: string }): ActiveAccount | undefined {
  switch (action.type) {
    case 'update': {
      window.localStorage.setItem('selectedOption', JSON.stringify(action.account))

      if (action?.account !== undefined && action.account?.id !== undefined && action.account.id !== 0) {
        void sendPut(`/Account/StoreSelectedFacility?facilityID=${action.account.id}&type=${action.account.type.replace(' ', '')}`, [])
      }
      return action.account
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initialAccount = undefined
