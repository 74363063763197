import { Box } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { type exportPeriod } from '../../core/types'
import { useState } from 'react'
import { TextFieldWithLabel } from '../../Components/TextField'
import { DatePickerWithLabel } from '../../Components/DatePicker'

export interface ExportPeriodDetailsProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: exportPeriod) => void
  exportPeriod: exportPeriod
  errors?: string[] | undefined
}

export function ExportPeriodDetails (props: ExportPeriodDetailsProps): JSX.Element {
  const [exportPeriod, setExportPeriod] = useState(props.exportPeriod)
  const title = 'MSDS Export Period'
  const handleClose = (): void => {
    props.onClose()
  }
  const handleChange = (e: any): void => {
    setExportPeriod({ ...exportPeriod, [e.target.name]: e.target.value })
  }
  const handleDateChange = (name: string, newDate: Date | null): void => {
    setExportPeriod({ ...exportPeriod, [name]: newDate })
  }
  const handleSubmit = (): void => {
    props.onSubmit(exportPeriod)
  }
  const content = <Box>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    <Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '72px', display: 'flex', flexDirection: 'row' }}>
      <table>
        <tbody>
          <tr>
            <td><TextFieldWithLabel
              data-test-name
              label='Name'
              name='name'
              onChange={handleChange}
              value={exportPeriod.name}
            /></td>
            <td><TextFieldWithLabel
              data-test-code
              label='Code'
              name='code'
              onChange={handleChange}
              value={exportPeriod.code}
            /></td>
          </tr>
          <tr>
            <td><DatePickerWithLabel
              cypressMarker='data-test-period-starting'
              label='Period Starting'
              name='periodStart'
              onChange={(newValue) => { handleDateChange('periodStart', newValue) }}
              value={new Date(exportPeriod.periodStart)}
            /></td>
            <td><DatePickerWithLabel
              cypressMarker='data-test-period-ending'
              label='Period Ending'
              name='periodEnd'
              onChange={(newValue) => { handleDateChange('periodEnd', newValue) }}
              value={new Date(exportPeriod.periodEnd)}
            /></td>
          </tr>
          <tr>
            <td><TextFieldWithLabel
              data-test-collection-id
              label='collection id'
              name='collectionId'
              onChange={handleChange}
              value={exportPeriod.collectionID}
            /></td>
            <td><TextFieldWithLabel
              data-test-major-version
              label='major version'
              name='majorVersion'
              onChange={handleChange}
              value={exportPeriod.majorVersion}
            /></td>
          </tr>
          <tr>
            <td><TextFieldWithLabel
              data-test-minor-version
              label='minor version'
              name='minorVersion'
              onChange={handleChange}
              value={exportPeriod.minorVersion}
            /></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </Box>
  </Box>
  return <Modal
    data-test-modal
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
