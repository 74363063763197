import { Box } from '@mui/system'
import supportIcon from '../../Assets/Icons/support-icon.png'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

export function Footer (): JSX.Element {
  const theme = useTheme()
  const largeWindow = useMediaQuery(theme.breakpoints.up('md'))
  const year = new Date().getFullYear()

  return <Box sx={{ backgroundColor: '#fff', position: 'fixed', zIndex: '1201', bottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: largeWindow ? '29px' : '40px' }}>
    <p data-test-copyright-text>© Copyright {year} Michigan Collaboration Hub at MAISA</p>
    <a style={{ textDecoration: 'none', color: 'black', marginLeft: '44px' }} href="https://servicedesk.oakland.k12.mi.us/servicedesk/customer/portal/19/create/462" target="_blank">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
        <img src={ supportIcon } alt='support-icon' style={{ marginRight: '6px' }}/>
        <p style={{ fontStyle: 'bold', lineHeight: '180%' }}><b data-test-support-link>Support</b></p>
      </Box>
    </a>
  </Box>
}
