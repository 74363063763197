import checkmark from '../../../Assets/Icons/gray-checkmark.png'
import { Button, Typography } from '@mui/material'

interface SelectableRowProps {
  text?: string
  content?: JSX.Element
  id: number
  selectedId: number | null
  setSelected: (id: number) => void
}

export function SelectableRow (props: SelectableRowProps): JSX.Element {
  return <Typography
    variant='h6'
    component='div'
    className='selectable-row'
    data-testid='selectable-row'
  >
    {props.text != null && props.text}
    {props.content != null && props.content}
    <Button
      variant="outlined"
      className={'select-button vertical-center-align' + (props.selectedId === props.id ? ' selected' : '')}
      data-testid='select-button'
      onClick={() => { props.setSelected(props.id) }}
    >
      {props.selectedId !== props.id && 'Select'}
      {props.selectedId === props.id && <img src={checkmark} alt={'checkmark'} data-testid="selected"/>}
    </Button>
  </Typography>
}
