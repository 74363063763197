import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Button, Chip, CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type React from 'react'
import { useEffect, useState } from 'react'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { ISDManagementSearch, type ISDSearchCriteria } from './ISDManagementSearch'
import { type SearchProps, useSearch } from '../../hooks/use-search'
import { sendGet, sendPut } from '../../hooks/use-fetch'
import { type User, type ISD } from '../../core/types'
import { ISDEdit, type userData } from './ISDEdit'
import { FormatDateValue } from '../../core/Utilities'

export interface ISDInfo {
  id: number
  name: string
  isd: string
  securityLevel: string
  serviceProvider: string
  inactive: boolean
  email: string
}

const nameColumn = new Column('name', 'Name')
const codeColumn = new Column('eemCode', 'EEM Code', ColumnType.CUSTOM)
const dateColumn = new Column('onboardDate', 'Onboarded Date', ColumnType.CUSTOM)
const statusColumn = new Column('status', 'Status', ColumnType.CUSTOM)
const contactColumn = new Column('contact', 'Primary Contact', ColumnType.CUSTOM)

codeColumn.customCellGenerator = row => {
  const isdid: string = row.id.toString()
  return <div>{isdid + '000'}</div>
}

contactColumn.customCellGenerator = row => {
  const users: User[] = row.users
  let contact = ''
  if (users != null && users.length > 0) {
    const user: User | undefined = users.find(u => u.id === row.adminUserId)
    if (user !== null) {
      const firstName: string = user?.firstName ?? ''
      const lastName: string = user?.lastName ?? ''
      contact = firstName + ' ' + lastName
    }
  }
  return <div>{contact}</div>
}

dateColumn.customCellGenerator = row => {
  // need to format this
  return <div>{FormatDateValue(row.onboardDate)}</div>
}

statusColumn.customCellGenerator = row => {
  let displayName = row.status
  let indicatorClass = 'cyan-'
  if (row.status === 'InProgress') {
    displayName = 'In Progress' // ideally we would instead make a call to the server to get the Description attribute off this enum to get the display value.
    indicatorClass = 'purple-'
  } else if (row.status === 'Inactive') {
    indicatorClass = 'red-'
  }
  return <Chip icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={displayName} size='small' className={indicatorClass + 'indicator'} />
}

const columns = [nameColumn, codeColumn, dateColumn, statusColumn, contactColumn]

export function ISDManagement (): JSX.Element {
  const [searchData, setSearchData] = useState<ISDSearchCriteria>({ name: '', status: undefined })
  const [refreshTime, setRefreshTime] = useState(new Date())
  const searchType: SearchProps = { endPoint: 'ISD', includes: 'Users', searchData, refreshTime, autoUpdateOnSearchDataChange: true }
  const { rows, totalRecords, loading, onPageChange, onRowsPerPageChange, onSortChange } = useSearch(searchType)
  const [totalCount, setTotalCount] = useState(0)
  const [activeCount, setActiveCount] = useState(0)
  const [inProgressCount, setInProgressCount] = useState(0)
  const [inActiveCount, setInactiveCount] = useState(0)
  const [isNew, setIsNew] = useState(false)
  const initialISD: ISD = {
    id: 0,
    adminUserId: null,
    name: '',
    intakeProviderID: 0,
    website: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    contactEmail: '',
    contactPhone: '',
    status: '',
    onboardDate: new Date(),
    top3Selection: false,
    captureIFSP: false,
    captureIEP: false,
    captureEOIS: false,
    showResultsFromOtherCounties: false,
    selectProviderOnReferralDecline: false,
    referAllToIntakeProvider: false,
    showServicesForMyChild: false,
    showServicesForMyself: false,
    showCreateReferral: false,
    adultServicesName: '',
    adultServicesLink: ''
  }
  const [selectedISD, setSelectedISD] = useState<ISD>(initialISD)
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0)

  const addClick = (): void => {
    setIsNew(true)
    setOpen(true)
    setSelectedISD(initialISD)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const editingISD: ISD = row as ISD

    setIsNew(false)
    setOpen(true)
    setSelectedISD(editingISD)
  }

  const handleSubmit = async (isdData: ISD, newUserData: userData): Promise<void> => {
    setErrors(undefined)
    let err: string[] | null = null
    let suc = false
    const submitData = { isd: isdData, newUserData }
    const { error, success } = await sendPut('/ISD/UpdateEnrollment', submitData)
    err = error
    suc = success

    if (suc) {
      setOpen(false)
      setRefreshTime(new Date())
    } else {
      if (err != null && err.length > 0) {
        setErrors(err)
      }
    }
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: totalRsp } = await sendGet('/ISD/RecordCount', {})
      setTotalCount(totalRsp)
      const { response: activeRsp } = await sendGet('/ISD/RecordCount?queryStringFilter=Status%3DActive', {})
      setActiveCount(activeRsp)
      const { response: inProgressRsp } = await sendGet('/ISD/RecordCount?queryStringFilter=Status%3DInProgress', {})
      setInProgressCount(inProgressRsp)
      const { response: inactiveRsp } = await sendGet('/ISD/RecordCount?queryStringFilter=Status%3DInactive', {})
      setInactiveCount(inactiveRsp)
    }
    void loadData()
  }, [])

  if (loading) return <CircularProgress />

  return (<>
    <Typography variant='h3'> ISD Management </Typography>
    <Box sx={{ background: '#FFFFFF', borderRadius: '16px', padding: '24px', margin: '10px 0 10px 0' }}>
      <div className='info-box-header'>Total Onboarded ISDs</div>
      <div style={{ marginTop: '15px' }}>
        <span style={{ marginRight: '150px', fontWeight: '700', fontSize: '24px' }}>{totalCount}</span>
        <span title='Active'><FiberManualRecordIcon className='cyan-icon' /><span className='icon-count' >{activeCount}</span></span>
        <span title='In Progress'><FiberManualRecordIcon className='purple-icon' /><span className='icon-count'>{inProgressCount}</span></span>
        <span title='Inactive'><FiberManualRecordIcon className='red-icon' /><span className='icon-count'>{inActiveCount}</span></span>
      </div>
    </Box>
    <Box justifyContent='flex-end' display='flex' width='100%' >
      <Button color='secondary' variant='contained' onClick={addClick} data-testid='add-isd'>Add ISD</Button>
    </Box>

    <ISDManagementSearch current={searchData} onChange={setSearchData} />
    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          page={page}
          hasCheckbox={false}
          name='isdTable'
          columns={columns}
          rows={rows ?? []}
          totalRecords={totalRecords}
          loading={loading}
          onSortChange={onSortChange}
          onPageChange={(e, p) => {
            onPageChange(e, p)
            setPage(p)
          }}
          onRowsPerPageChange={onRowsPerPageChange}
          onRowClick={rowClick}
        />
        {open && (
          <ISDEdit
            onClose={() => { setOpen(false) }}
            onSubmit={handleSubmit}
            open={open}
            isNew={isNew}
            isd={selectedISD}
            errors={errors}
          />
        )}
      </Box>
    </div>
  </>)
}
