import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { CheckBoxWithLabel } from '../CheckBox'
import { type environmentalRisk, type eligibilityFactor } from '../../core/types'
import { TextFieldWithLabel } from '../TextField'

interface EligiblityFactorListProps {
  eligiblityList: eligibilityFactor[]
  otherFactor: string | null
  enviromentalRiskList: environmentalRisk[]
  captureIEP: boolean
  captureIFSP: boolean
  captureEOIS: boolean
  hasIEP: boolean
  hasIFSP: boolean
  hasEOIS: boolean
  setEligibilityValues: (eligibilityList: eligibilityFactor[], environmentalRiskList: environmentalRisk[], values: EligibilityValues) => void
}

export interface EligibilityValues {
  hasIEP: boolean
  hasIFSP: boolean
  hasEOIS: boolean
  otherFactor: string | null
}

export function EligiblityFactorList (props: EligiblityFactorListProps): JSX.Element {
  const [systemFactors, setSystemFactors] = useState<eligibilityFactor[]>([])
  const [environmentalRisks, setEnvironmentalRisks] = useState<environmentalRisk[]>([])
  const [hasOtherFactor, setHasOtherFactor] = useState(props.otherFactor != null)
  const [values, setValues] = useState<EligibilityValues>({ hasIEP: props.hasIEP, hasIFSP: props.hasIFSP, hasEOIS: props.hasEOIS, otherFactor: props.otherFactor })
  const [hasEnvironmentalRisk, setHasEnvironmentalRisk] = useState(props.eligiblityList.some(r => r.stateCode === '07'))

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/EligibilityFactor/GetAll', {})
      if (error[0] === '') {
        setSystemFactors(response)
      }
      const { response: rsp2, error: err2 } = await sendGet('/EnvironmentalRisk/GetLookup', {})
      if (err2[0] === '') {
        setEnvironmentalRisks(rsp2)
      }
    }
    void fetchData()
  }, [])

  useEffect(() => {
    props.setEligibilityValues(props.eligiblityList, props.enviromentalRiskList, values)
  }, [values])

  const valueChanged = (field: string, value: any): void => {
    setValues({ ...values, [field]: value })
  }

  const otherChanged = (e: any): void => {
    setValues({ ...values, otherFactor: e.target.value })
  }

  const otherChecked = (value: boolean): void => {
    setHasOtherFactor(value)
    if (!value) {
      setValues({ ...values, otherFactor: null })
    } else {
      setValues({ ...values, otherFactor: '' })
    }
  }

  return <Box display={'block'}>
    {(props.captureIEP || props.captureIFSP || props.captureEOIS) && <>
      <Typography className="info-box-header">Accomodations</Typography>

      {props.captureIEP &&
        <CheckBoxWithLabel label='Has an IEP' name='hasIEP' edge='start' value={values.hasIEP} setValue={(val) => { valueChanged('hasIEP', val) }} />
      }
      {props.captureIFSP &&
        <CheckBoxWithLabel label='Has an IFSP' name='hasIFSP' edge='start' value={values.hasIFSP} setValue={(val) => { valueChanged('hasIFSP', val) }} />
      }
      {props.captureEOIS &&
        <CheckBoxWithLabel label='Has an EOIS' name='hasEOIS' edge='start' value={values.hasEOIS} setValue={(val) => { valueChanged('hasEOIS', val) }} />
      }
    </>
    }

    <Typography className="info-box-header">Eligibility Factors</Typography>
    {systemFactors.map(r => {
      const changeFactor = (selected: boolean): void => {
        if (selected && !props.eligiblityList.map(r => r.name).includes(r.name)) {
          props.setEligibilityValues([...props.eligiblityList, { id: r.id, name: r.name, stateCode: r.stateCode }], props.enviromentalRiskList, values)
        } else {
          props.setEligibilityValues(props.eligiblityList.filter(x => x.name !== r.name), props.enviromentalRiskList, values)
        }
        if (r.stateCode === '07') {
          setHasEnvironmentalRisk(selected)
        }
      }
      return <CheckBoxWithLabel
        key={r.id}
        label={r.name}
        name={r.name}
        value={props.eligiblityList.map(r => r.name).includes(r.name)}
        setValue={changeFactor} edge={'start'}
        dataTestId={`eligibilityFactorCheckBox-${r.name}`}
      />
    })}
    <CheckBoxWithLabel key='otherFactor' label='Other' name='otherFactor' value={hasOtherFactor} edge={'start'} setValue={otherChecked} />
    {hasOtherFactor && <TextFieldWithLabel label='Other Eligibility Factor' data-testid='lastName' name='lastName' maxLength={200} required={true} value={values.otherFactor} onChange={otherChanged} />}

    {hasEnvironmentalRisk && <>
      <Typography className="info-box-header">Environmental Risks</Typography>
      {environmentalRisks.map(r => {
        const changeEnvRisk = (selected: boolean): void => {
          if (selected && !props.enviromentalRiskList.map(r => r.name).includes(r.name)) {
            props.setEligibilityValues(props.eligiblityList, [...props.enviromentalRiskList, { id: r.id, name: r.name }], values)
          } else {
            props.setEligibilityValues(props.eligiblityList, props.enviromentalRiskList.filter(x => x.name !== r.name), values)
          }
        }
        return <CheckBoxWithLabel
          key={r.id}
          label={r.name}
          name={r.name}
          value={props.enviromentalRiskList.some(i => r.name === i.name)}
          setValue={changeEnvRisk} edge={'start'}
          dataTestId={`environmentalRiskCheckBox-${r.name}`}
        />
      })}
    </>
     }
  </Box>
}
