import { useEffect, useState } from 'react'
import { type ChildMemberDetails } from './ChildDisplay'
import { sendGet } from '../../../hooks/use-fetch'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { AgeFromDate, FormatBoolean, FormatDateValue } from '../../../core/Utilities'
import { Button } from '@mui/material'
import { type lookup } from '../../../core/types'
import { CAREGIVER_PORTAL_MEMBERS_EDIT_CHILD } from '../../Routes'
import { generatePath, useNavigate } from 'react-router'

export interface ChildDetailsProps {
  child: ChildMemberDetails
  updateData: () => void
}

export function ChildDetails (props: ChildDetailsProps): JSX.Element {
  const nav = useNavigate()
  const [captureIEP, setCaptureIEP] = useState(false)
  const [captureIFSP, setCaptureIFSP] = useState(false)
  const [captureEOIS, setCaptureEOIS] = useState(false)
  const [raceItems, setRaceItems] = useState<lookup[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: isdRsp } = await sendGet(`/CaregiverPortal/GetISDSettings?id=${props.child.isdid ?? ''}`, {})
      if (isdRsp != null) {
        setCaptureIEP(isdRsp.captureIEP)
        setCaptureIFSP(isdRsp.captureIFSP)
        setCaptureEOIS(isdRsp.captureEOIS)
      }

      const { response } = await sendGet('/Race/GetLookup', {})
      setRaceItems(response)
    }
    void fetchData()
  }, [props.child.dateOfBirth])

  function editChild (): void {
    const route = generatePath(CAREGIVER_PORTAL_MEMBERS_EDIT_CHILD, { childId: props.child.id.toString() })
    nav(route)
  }

  const addressDisplay = <>
    <div>{props.child.location?.address} </div> <div>{props.child.location?.city} {props.child.location?.state} {props.child.location?.zipCode}</div>
  </>

  function raceDisplay (): string {
    let result = ''
    props.child.races.forEach((raceID) => {
      if (result !== '') {
        result += ', '
      }
      result += raceItems.find(r => r.id === raceID)?.name ?? ''
    })
    return result
  }

  return <>
    <TextWithLabel label='First Name' name='firstName' value={props.child.firstName} />
    <TextWithLabel label='Nickname' name='nickname' value={props.child.nickname} />
    <TextWithLabel label='Middle Name' name='middleName' value={props.child.middleName} />
    <TextWithLabel label='Last Name' name='lastName' value={props.child.lastName} />
    <TextWithLabel label='Date of Birth' name='dateOfBirth' value={FormatDateValue(props.child.dateOfBirth)} />
    <TextWithLabel label='Gender' name='birthGender' value={props.child.birthGender} />
    <TextWithLabel label='Primary Language (if not English)' name='primaryLanguage' value={props.child.primaryLanguage} />
    <TextWithLabel label='Race/Ethnicity' name='race' value={raceDisplay()} />
    {/* taking child's age into consideration for when to show the following */}
    {captureIEP && props.child.dateOfBirth !== null && AgeFromDate(props.child.dateOfBirth) >= 3 &&
      <TextWithLabel label='Has an Individualized Education Plan (IEP)' name='hasIEP' value={FormatBoolean(props.child.hasIEP)} />
    }
    {captureIFSP && props.child.dateOfBirth !== null && AgeFromDate(props.child.dateOfBirth) < 3 &&
      <TextWithLabel label='Has an Individualized Family Service Plan (IFSP)' name='hasIFSP' value={FormatBoolean(props.child.hasIFSP)} />
    }
    {captureEOIS && props.child.dateOfBirth !== null && AgeFromDate(props.child.dateOfBirth) >= 3 &&
      <TextWithLabel label='Has an Early On Intervening Service Plan (EOIS)' name='hasEOIS' value={FormatBoolean(props.child.hasEOIS)} />
    }
    <TextWithLabel label='Foster Child' name='fosterChild' value={FormatBoolean(props.child.fosterChild)} />
    <TextWithLabel label='SNAP Benefits (food assistance)' name='hasSNAPBenefits' value={FormatBoolean(props.child.hasSNAPBenefits)} />
    <TextWithLabel label='Cash Assistance' name='isOnCashAssistance' value={FormatBoolean(props.child.isOnCashAssistance)} />
    <TextWithLabel label='SSI (Social Security Income)' name='IsOnSSI' value={FormatBoolean(props.child.isOnSSI)} />

    <TextWithLabel label='Address' name='address' content={addressDisplay} />

    {props.child.canEdit &&
      <>
        <Button
          variant='contained'
          className='footer-button'
          sx={{ mt: '20px' }}
          name='editDetails'
          onClick={editChild}
        >
          Edit
        </Button>
      </>
    }
  </>
}
