import { Box, Typography, Button, Grid, Tab, Tabs, IconButton, Link, Alert } from '@mui/material'
import { FormatCurrency, FormatDateValue } from '../../core/Utilities'
import { type ReferralData } from '../../Pages/Referral/ReferralDetail'
import { usePermissions } from '../../hooks/use-permissions'
import stopwatch from '../../Assets/Icons/stopwatch.png'
import printer from '../../Assets/Icons/printer.png'
import { ChildDetails } from '.'
import { ReferralNotes } from '../../Pages/Referral/ReferralNotes'
import { type CareNetworkCommunication, ChildAuditAccessTypeEnum, type DocumentTypeRequirement, NoteSource, type postError, AccountType, type lookup } from '../../core/types'
import { ReferralDocuments } from '../../Pages/Referral/ReferralDocuments'
import { TabDisplay } from '../TabDisplay'
import { useState } from 'react'
import { EnrollmentNotes } from '../../Pages/ServiceProvider/Enrollments/EnrollmentNotes'
import { useAuth } from '../../hooks/use-auth'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { useErrorDispatch } from '../../ContextProviders/ErrorProvider'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { CommunicationDetail } from '../../Pages/CareNetworkManagement/CareNetworkDetail/CommunicationDetail'
import EditIcon from '@mui/icons-material/Edit'
import { SelectProgramModal } from './SelectProgramModal'
import { ChildAndGuardianDocumentList } from '../DocumentList/ChildAndGuardianDocumentList'
import AddIcon from '@mui/icons-material/Add'

export interface ReferralViewProps {
  hideIncomeReapply?: boolean
  enrollmentId?: number
  referral: ReferralData
  onPrint: () => void
  docTypes?: DocumentTypeRequirement[]
  type: 'referral' | 'enrollment'
  hideEditButtons: boolean
  onRefresh: () => void
  onEdit: (show: boolean) => void
  onCreateNew?: (show: boolean) => void
  onEditHouseholdMembers: (show: boolean) => void
}

export function ReferralView (props: ReferralViewProps): JSX.Element {
  const { user } = useAuth()
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [reminderUsers, setReminderUsers] = useState<Array<{ id: number, name: string }>>([])
  const account = useAccountId()
  const dispatch = useErrorDispatch()
  const perms = usePermissions()
  const [value, setValue] = useState(0)
  const [showReminderModel, setShowReminderModel] = useState(false)
  const [reminderRecord, setReminderRecord] = useState<CareNetworkCommunication | null>(null)
  const [isProgramModalOpen, setIsProgramModalOpen] = useState(false)

  const handleReminderClose = (): void => {
    setShowReminderModel(false)
  }

  const handleReminderAccept = async (com: CareNetworkCommunication): Promise<void> => {
    dispatch({ type: 'clear', errors: [] })
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const result = await sendPost('/Communication/CreateCommunication', com)
      const rsp: { success: boolean, errors: postError[] } = result.response
      if (rsp.success) {
        setShowReminderModel(false)
        const userindex = reminderUsers.findIndex(x => x.id === com.userID)
        toast.success(`Communication record was updated. A reminder will be sent on ${FormatDateValue(com.reminderDate ?? new Date())} to ${reminderUsers[userindex].name}`, { autoClose: false })
      } else {
        dispatch({ type: 'update', errors: rsp.errors })
      }
      setIsRequestLoading(false)
    }
  }

  const setReminder = async (): Promise<void> => {
    // first get users depending if the selected facility is an ISD or Service Provider
    if (account?.type === AccountType.ISD) {
      const { response } = await sendGet(`/ISDStaff/GetStaffList?isdID=${account?.id ?? 0}`, {})
      if (response !== null) {
        setReminderUsers(response)
      }
    } else if (account?.type === AccountType.SERVICE_PROVIDER || account?.type === AccountType.INTAKE_USER) {
      const { response } = await sendGet(`/ServiceProviderSettings/GetProviderStaff?systemSpID=${account?.id ?? 0}`, {})
      if (response !== null) {
        const users: Array<{ id: number, name: string }> = response.map((a: { id: number, firstName: string, lastName: string }) => {
          return { id: a.id, name: `${a.firstName} ${a.lastName}` }
        })
        setReminderUsers(users)
      }
    }

    const initialRecord: CareNetworkCommunication = {
      userID: user?.id ?? 0,
      addressedWhatMatteredMost: null,
      caregiverID: null,
      contactMethodID: null,
      enteredOn: new Date(),
      id: 0,
      type: NoteSource.Reminder,
      childID: props.referral?.childID ?? 0,
      needMet: null,
      note: '',
      reasonNotMet: '',
      reminderDate: null,
      referralID: null
    }
    setReminderRecord(initialRecord)
    setShowReminderModel(true)
  }

  const requestingModule = props.type === 'referral' ? ChildAuditAccessTypeEnum.Referral : ChildAuditAccessTypeEnum.Enrollment

  const reApply = async (): Promise<boolean> => {
    if (!isRequestLoading) {
      const submitValues = {
        referralId: props.referral.id,
        enrollmentId: props.enrollmentId
      }
      setIsRequestLoading(true)
      const { response } = await sendPost('/ReferralEdit/ReApplyIncomeRequest', submitValues)
      const rsp: boolean = response
      if (rsp) {
        toast.success('The income request was re-applied')
        props.onRefresh()
      } else {
        toast.error('The income request could not be re-applied')
      }
      setIsRequestLoading(false)
      return rsp
    }
    return false
  }

  const handleConfirm = async (selectedProgram: lookup): Promise<void> => {
    props.referral.programId = selectedProgram.id
    props.referral.programName = selectedProgram.name
    setIsProgramModalOpen(false)
    props.onRefresh()
  }

  return <>
    <Box sx={{ p: '0 24px' }}>
      <Typography component="div" variant="h5" fontWeight={'bold'}>{props.referral?.childName}</Typography>

      {perms.Enrollment_Communication && <Button onClick={setReminder} sx={{ textTransform: 'none' }} color='inherit'>
        <img src={stopwatch} alt="Set Reminder" />
        Set Reminder
      </Button>}
      <Button onClick={props.onPrint} color='inherit' sx={{ textTransform: 'none' }}>
        <img src={printer} alt="Print Details" />
        Print Details
      </Button>
      <Button startIcon={<AddIcon />} color='inherit' sx={{ textTransform: 'none' }} onClick={() => { props.onCreateNew?.(true) }}>
        Add Referral
      </Button>
      <Grid container columns={2} spacing={2} sx={{ paddingTop: '10px' }}>
        <Grid container item spacing={1} xs={6}>
          <Box className={props.referral.documentsSubmittedAndVerified == null || props.referral.documentsSubmittedAndVerified ? 'valid-status' : 'error-status'}>
            {props.referral.documentsSubmittedAndVerified == null ? 'No Documents Required' : (props.referral.documentsSubmittedAndVerified ? 'Documents are Verified' : 'Missing / Unverified Documents')}
          </Box>
        </Grid>
        <Grid item container spacing={1} xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box className='valid-status'>
            {props.referral.sessionBlockName}
          </Box>
        </Grid>
        <Grid item container xs={1} sx={{ pt: '0px !important' }}>
          {props.type === 'referral' && !props.hideEditButtons && (
            <Box><IconButton data-testid='referralEditButton' onClick={() => { props.onEdit(true) }}><EditIcon /></IconButton></Box>
          )}
        </Grid>
      </Grid>
      <Grid container columns={2} spacing={2} sx={{ paddingTop: '10px' }}>
        {props.referral.incomeApproved !== 'NotApplicable' && <Grid container item spacing={1} xs={6}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>Stated Income</Box>
            <Box sx={{ fontWeight: 'bold' }}>{FormatCurrency(props.referral.statedIncome)}</Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>Household Members</Box>
            <Box sx={{ fontWeight: 'bold' }}>{props.referral.householdMemberCount}
              {!props.hideEditButtons &&
                <IconButton size='small' data-testid='memberCountButton' onClick={() => { props.onEditHouseholdMembers(true) }}><EditIcon fontSize='inherit' /></IconButton>
              }
            </Box>
          </Grid>
          <Grid item xs={12}><hr style={{ margin: '0' }} /></Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>Verified Income</Box>
            <Box sx={{ fontWeight: 'bold' }}>{FormatCurrency(props.referral.verifiedIncome)}</Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>Poverty Level</Box>
            <Box sx={{ color: 'lightseagreen' }}>{props.referral.povertyPercent}{props.referral.povertyPercent !== null ? '%' : ''}</Box>
          </Grid>
        </Grid>
        }
        <Grid item container spacing={1} xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item sx={{ color: 'lightseagreen' }}>{props.referral.desiredSchedule} Schedule Desired</Grid>
          <Grid item>{props.referral.timePeriodDisplay}</Grid>
          <Grid item>{props.referral.facilityName}</Grid>
          <Grid item>{props.referral.programName ??
            (<>Program Missing - <Link sx={{ cursor: 'pointer', textDecoration: 'none' }} onClick={() => { setIsProgramModalOpen(true) }}>Select Here</Link></>)}</Grid>
        </Grid>

      </Grid>
      {props.type === 'referral' && props.referral.incomeApproved !== 'NotApplicable' && props.referral.statedIncome !== props.referral.verifiedIncome &&
        <Box sx={{ p: '10px 0 3px 0' }}>
          <Alert severity="warning" sx={{ border: 1, borderColor: 'orange' }}>
            Stated Income and Verified Income do not match. Please make sure income is correct before accepting.
          </Alert>
        </Box>
      }
    </Box>

    {props.referral.incomeApproved === 'Denied' && props.hideIncomeReapply !== true &&
      <Box sx={{ backgroundColor: 'lightgray', borderTop: '1px solid black', borderBottom: '1px solid black', display: 'grid', justifyContent: 'center' }}>
        <Typography>The income request was denied.</Typography>
        <Button variant='text' onClick={reApply}>Re-Apply</Button>
      </Box>
    }

    <Box sx={{ borderBottom: 1, borderColor: 'divider', p: '0 24px' }}>
      <Tabs value={value} onChange={(e, val) => { setValue(val) }}>
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold' }} data-testid="details" label={'Details'} value={0} />
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold' }} data-testid='documents' label={'Documents'} value={1} />
        <Tab sx={{ textTransform: 'none', fontWeight: 'bold' }} data-testid='notes' label={'Notes'} value={2} />
      </Tabs>
    </Box>

    <Box sx={{ p: '0 24px' }}>
      <TabDisplay index={0} value={value}>
        <ChildDetails childID={props.referral.childID} requestingModule={requestingModule} isdid={props.referral.isdid} onRefresh={props.onRefresh} />
      </TabDisplay>
      <TabDisplay index={1} value={value}>
        {(props.type === 'referral' || props.referral.referralID !== null)
          ? <ReferralDocuments referralID={props.referral.referralID ?? props.referral.id}
              childID={props.referral.childID} dataChanged={props.onRefresh}
              readOnly={props.hideEditButtons || !(perms.Enrollment_Documentation_Edit || perms.CareNetwork_Documentation_Edit)}
              showChildDocsIfNoneOnReferral={props.type === 'enrollment'}
              years={props.referral.sessionBlockYears}
          />
          : <ChildAndGuardianDocumentList
              editable={(perms.Enrollment_Documentation_Edit ?? false) && !props.hideEditButtons}
              childID={props.referral.childID}
              isdID={props.referral.isdid}
              documentTypes={props.docTypes}
              dataChanged={props.onRefresh}
              isDocumentPermission={(perms.Enrollment_Documentation || perms.CareNetwork_Documentation_Edit) ?? false}
          />
        }
      </TabDisplay>
      <TabDisplay index={2} value={value}>
        {props.type === 'referral' && (
          <ReferralNotes
            referralID={props.referral.id}
            isEditable={perms.Enrollment_Communication_Edit || perms.CareNetwork_Enrollment_Edit}
            isNotesPermission={perms.Enrollment_Communication || perms.CareNetwork_Enrollment}
          />
        )}
        {props.type !== 'referral' && (
          <EnrollmentNotes
            enrollmentID={props.enrollmentId ?? 0}
            isEditable={perms.Enrollment_Communication_Edit || perms.CareNetwork_Enrollment_Edit}
            isNotesPermission={perms.Enrollment_Communication || perms.CareNetwork_Enrollment}
          />
        )}

      </TabDisplay>

      {isProgramModalOpen && <SelectProgramModal
        open={isProgramModalOpen}
        onClose={() => { setIsProgramModalOpen(false) }}
        onConfirm={handleConfirm}
        referralId={props.referral.id}
        isdServiceProviderId={props.referral.serviceProviderID}
      />}

      {showReminderModel && reminderRecord !== null &&
        <CommunicationDetail
          open={showReminderModel}
          onClose={handleReminderClose}
          onSubmit={handleReminderAccept}
          isNew={true}
          selectedCommunication={reminderRecord}
          userOptions={reminderUsers}
        />
      }
    </Box>
  </>
}
