import { useEffect, useState } from 'react'
import { type PortalCaregiverDetails, type EnrollmentDetails } from '../../../../core/types'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { Button, Grid, Typography } from '@mui/material'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

export interface CaregiverSummaryProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function CaregiverSummary (props: CaregiverSummaryProps): JSX.Element {
  const dispatch = useCaregiverWizardUpdateDispatch()
  const [otherCaregivers, setOtherCaregivers] = useState<boolean>(false)

  const caregiverForm = props.caregiverForm
  const caregivers = caregiverForm.caregivers

  useScrollTop()

  useEffect(() => {
    // If the user has some old updates that they haven't saved, clear them out and reset the flag
    const resetUpdateFlag = async (): Promise<void> => {
      const caregiver = caregivers.find(c => c.isCurrentlyUpdating)
      if (caregiver != null) {
        caregiver.isCurrentlyUpdating = false
        const initialForm = initialEnrollmentDetails()
        const tempForm = { ...caregiverForm, currentCaregiver: initialForm.currentCaregiver }
        await saveSession(tempForm)
        dispatch({ type: 'form', form: tempForm })
      }
    }
    // If the user has no caregivers, redirect them to the add caregiver page
    const redirectIfNecessary = async (): Promise<void> => {
      if (caregivers.length === 0) {
        const tempForm = { ...caregiverForm, currentSubStep: 1 }
        await saveSession(tempForm)
        dispatch({ type: 'form', form: tempForm })
      }
    }

    if (caregiverForm.currentStep === 2) {
      void resetUpdateFlag()
      void redirectIfNecessary()
    }
  }, [])

  const handleCaregiverClick = async (caregiver: PortalCaregiverDetails): Promise<void> => {
    // Put the selected caregiver in currentCaregiver and track which one we are updating
    caregiver.isCurrentlyUpdating = true
    const tempForm = { ...caregiverForm, currentSubStep: 1, currentCaregiver: caregiver }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    let step = 3
    let subStep = 1

    // If the user has selected to add another caregiver, go to the add caregiver page.
    // If the user has already selected services for any of the children, go to the service summary page.
    // Otherwise, go to the service selection page.
    if (otherCaregivers) {
      step = 2
    } else if (caregiverForm.children.some(c => c.requestedServices.length > 0)) {
      step = 4
      subStep = 3
    }
    const tempForm = { ...caregiverForm, currentStep: step, currentSubStep: subStep }
    await saveSession(tempForm)

    if (!otherCaregivers) {
      props.handleStep(false)
    }
    dispatch({ type: 'form', form: tempForm })
  }

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 1, currentSubStep: 3 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ fontWeight: 600 }} component='div'>
        Caregivers
      </Typography>
    </Grid>
    <Grid item xs={12}>
      {caregivers.map(caregiver => {
        return <Typography
          key={caregiver.firstName + caregiver.lastName + (caregiver.relationshipId?.toString() ?? '')}
          variant='h6'
          component='div'
          className='clickable-row'
          data-testid='caregiver-row'
          onClick={async () => { await handleCaregiverClick(caregiver) }}
        >
          {caregiver.firstName} {caregiver.lastName}
        </Typography>
      })}
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h6' sx={{ mb: '10px', mt: '30px' }} component='div'>
        Would you like to add additional caregivers to your network?
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Button variant="outlined" className={'wide-button' + (otherCaregivers ? ' selected' : '')} data-testid='other-caregivers-yes' onClick={() => { setOtherCaregivers(true) }}>Yes</Button>
    </Grid>
    <Grid item xs={6}>
      <Button variant="outlined" className={'wide-button' + (otherCaregivers ? '' : ' selected')} data-testid='other-caregivers-no' onClick={() => { setOtherCaregivers(false) }}>No</Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='continueButton'
        className='footer-button'
        data-testid='continue-button'
        onClick={handleContinue}
        variant='contained'
      >
        Continue
      </Button>
    </Grid>
  </Grid>
}
