import { Box, Grid } from '@mui/material'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { useEffect, useState } from 'react'
import { type functionType } from '../../../Components/CheckList/HierarchyCheckList'
import { sendGet } from '../../../hooks/use-fetch'
import { UserFunctionCheckList } from '../../../Components/CheckList/UserFunctionCheckList'

export interface StaffData {
  id: number
  firstName: string
  lastName: string
  fullName: string
  email: string
  securityLevel: number
  inactive: boolean
  userFunctionConfig: string
  userFunctions?: string[]
  defaultServiceProviderID: number | null
}

interface StaffDetailProps {
  userData: StaffData
  onChange: (e: any) => void
}

export function ServiceProviderStaffDetail (props: StaffDetailProps): JSX.Element {
  const statusOptions = [{ id: false, name: 'Active' }, { id: true, name: 'Deactivated' }]
  const [functionOptions, setFunctionOptions] = useState<functionType[]>([])
  const [checkedValues, setCheckedValues] = useState<string[]>(props.userData.userFunctions ?? [])

  let accessOptions: Array<{ id: string, name: string }> = []
  if (props.userData.securityLevel === 2) {
    accessOptions = [
      { id: 'No Access', name: 'No Access' },
      { id: 'Read Only', name: 'Read Only' },
      { id: 'Basic', name: 'Basic' },
      { id: 'Advanced', name: 'Advanced' },
      { id: 'Full Access', name: 'Full' },
      { id: 'Custom', name: 'Custom' }
    ]
  } else if (props.userData.securityLevel === 5) {
    accessOptions = [
      { id: 'IntakeCoordinator', name: 'Intake Coordinator' },
      { id: 'ExtendedCare', name: 'Extended Care' },
      { id: 'Blended', name: 'Blended' },
      { id: 'Full', name: 'Full' },
      { id: 'Custom', name: 'Custom' }
    ]
  }

  useEffect(() => {
    const securityLevelType = props.userData.securityLevel
    const userUrl = `/User/AllUserFunctions?userSecurityLevel=${securityLevelType}`

    async function getAllFunctions (): Promise<void> {
      const request = await sendGet(userUrl, {})
      const functions: functionType[] = request.response
      setFunctionOptions(functions)
    }
    void getAllFunctions()
  }, [])

  const handleChange = (e: any): void => {
    props.onChange({ ...props.userData, [e.target.name]: e.target.value })
  }

  const userFunctionConfigChange = async (e: any): Promise<void> => {
    let newVals: string[] = []
    if (e.target.value !== 'Custom') {
      const val: string = e.target.value.toString()
      const userUrl = `/ServiceProviderSettings/GetProviderUserFunctions?accessLevel=${val}&securityLevel=${props.userData.securityLevel}`
      const request = await sendGet(userUrl, {})
      newVals = request.response
      setCheckedValues(request.response)
    } else {
      const checkedItems: string[] = populateImplicit(functionOptions)
      newVals = checkedItems
      setCheckedValues(checkedItems)
    }

    props.onChange({ ...props.userData, userFunctions: newVals, userFunctionConfig: e.target.value })
  }

  const handlePermissionChange = (newValue: string[]): void => {
    setCheckedValues(newValue)
    props.onChange({ ...props.userData, userFunctions: newValue, userFunctionConfig: 'Custom' })
  }

  function populateImplicit (funcs: functionType[]): string[] {
    let newStrings: string[] = []
    funcs.map(f => {
      if (f.readImplicit && f.readPermissionName !== null) { newStrings.push(f.readPermissionName ?? '') }
      if (f.readImplicit && f.writePermissionName !== null) { newStrings.push(f.writePermissionName ?? '') }

      if (f.subPermissions !== null && f.subPermissions.length > 0) { newStrings = [...newStrings, ...populateImplicit(f.subPermissions)] }
      return ''
    })
    return newStrings
  }

  return (<Box>
    <Grid sx={{ flexGrow: 1 }} container spacing={'2%'} >
      <Grid container columns={12}>
        <Grid item sm={6} md={6}>
          <Box sx={{ paddingRight: '5%' }}>
            <TextFieldWithLabel data-testid='firstName' label={'First Name'} name={'firstName'} onChange={handleChange } value={props.userData.firstName} />
          </Box>
        </Grid>
        <Grid item sm={6} md={6}>
          <TextFieldWithLabel data-testid='lastName' label={'Last Name'} name={'lastName'} onChange={handleChange } value={props.userData.lastName}/>
        </Grid>
        <Grid item sm={6} md={6}>
          <Box sx={{ paddingRight: '5%' }}>
            <TextFieldWithLabel data-testid='email' label={'Email'} name={'email'} onChange={handleChange } value={props.userData.email} />
          </Box>
        </Grid>
        <Grid item sm={6} md={6}>
          <SelectWithLabel width='100%' data-testid='status' options={statusOptions} value={props.userData.inactive} onChange={handleChange } name={'inactive'} label={'Status'} />
        </Grid>
        <Grid item sm={6} md={6}>
          <Box sx={{ paddingRight: '5%' }}>
            <SelectWithLabel data-testid='accessLevel' width='100%' options={accessOptions} value={props.userData.userFunctionConfig} onChange={ userFunctionConfigChange} name={'userFunctionConfig'} label={'Access Level'}/>
          </Box>
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <UserFunctionCheckList
            options={functionOptions}
            selectedValues={checkedValues}
            onValuesChanged={handlePermissionChange}
          />
        </Grid>
      </Grid>
    </Grid>
  </Box>)
}
