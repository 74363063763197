/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { ChildServiceSelection } from '../../Referral/AddChildModal/ChildServiceSelection'
import { type childServiceSettings } from '../../../ContextProviders/AddChildProvider'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { type serviceProvider, type program, type postError, type service, type carenetworkService } from '../../../core/types'
import { toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'
import { AcceptDenyModal } from '../../../Components/Modal/AcceptDenyModal'
import { ServiceSummaryCard } from '../../../Components/Services/ServiceSummaryCard'

export interface CareNetworkServiceDetailsProps {
  service: carenetworkService
  isNew: boolean
  childID: number
  isdid: number
  districtId: number
  open: boolean
  onClose: () => void
  onSubmit: (newSettings?: childServiceSettings) => void
  onExit: () => void
  childName?: string
  careNetworkServices: carenetworkService[]
  overrideSubmit?: boolean
  errorContent?: JSX.Element
}

export function CareNetworkServiceDetails (props: CareNetworkServiceDetailsProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const [serviceProviders, setServiceProviders] = useState<serviceProvider[]>([])
  const [serviceList, setServiceList] = useState<Array<{ id: number, name: string, s3Key: string, inactive: boolean, serviceId: number }>>([])
  const [programList, setProgramList] = useState<Array<{ id: number, name: string, allowConcurrentEnrollment: boolean, requiresIncomeCalculation: boolean, programId: number }>>([])
  const [facilities, setFacilities] = useState<Array<{ id: number, name: string }>>([])
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(false)
  const [serviceSettings, setServiceSettings] = useState<childServiceSettings>({
    referralId: props.service.referralId,
    desiredSchedule: props.service.desiredSchedule,
    sessionBlockId: props.service.sessionBlockId,
    sessionBlockName: props.service.sessionBlockName,
    timeFrame: props.service.referralTimeFrame,
    customDate: props.service.customDate,
    facilityId: props.service.facilityID,
    notes: props.service.notes,
    income: null,
    incomeVerified: null,
    povertyLevel: null,
    povertyPercent: null,
    povertyPercentCutOff: null,
    memberCount: null,
    systemProgram: {
      id: props.service.systemProgramID, requiresIncomeCalculation: props.service.requiresIncomeCalculation, programId: props.service.programID
    } as program,
    systemService: { isdid: props.isdid, id: props.service.systemServiceID, name: props.service.systemServiceName, serviceId: props.service.serviceID } as service,
    systemServiceProviderId: props.service.systemServiceProviderID,
    isdId: props.isdid,
    districtId: props.districtId,
    facilityName: props.service.facilityName?.toString() ?? '',
    systemServiceProviderName: props.service.systemServiceProviderName,
    status: props.service.status
  })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet(`/CareNetworkEdit/GetAllProviders?isdid=${props.isdid}`, {})
      if (response != null && response.length > 0) {
        setServiceProviders(response)
      }
      if (serviceSettings.systemServiceProviderId !== null && !props.isNew) {
        const { response: rsp } = await sendGet(`/CareNetworkEdit/GetServices?id=${serviceSettings.systemServiceProviderId}`, {})
        if (rsp != null && rsp.length > 0) {
          setServiceList(rsp)
        }
        const { response: rsp1 } = await sendGet(`/CareNetworkEdit/GetPrograms?id=${serviceSettings.systemServiceProviderId}`, {})
        if (rsp1 != null && rsp1.length > 0) {
          setProgramList(rsp1)
        }
      }
      setLoaded(true)
    }
    void fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if ((serviceSettings.status === 'Pending' || serviceSettings.status === 'Waiting') && serviceSettings.systemServiceProviderId != null) {
        void GetFacilities(serviceSettings.systemProgram?.id ?? 0)
      }
    }
    void fetchData()
  }, [serviceSettings.timeFrame, serviceSettings.customDate, serviceSettings.systemProgram?.id])

  const GetFacilities = async (prgmId: number): Promise<void> => {
    const { response: rsp } = await sendGet(`/CareNetworkEdit/GetFacilities?isdid=${props.isdid.toString()}&providerId=${String(serviceSettings.systemServiceProviderId ?? 0)}&programID=${prgmId}`, {})
    setFacilities(rsp)
  }

  const handleClose = (): void => {
    setIsUpdateDisabled(false)
    props.onClose()
  }

  const handleSubmit = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const data = {
        serviceSettings,
        childID: props.childID
      }
      if (props.overrideSubmit === true) {
        props.onSubmit(serviceSettings)
      } else {
        if (props.isNew) {
          const { response } = await sendPost('/CareNetworkEdit/AddService', data)
          const rsp: { response: any, success: boolean, errors: postError[] } = response
          if (rsp.success) {
            toast.success('Service added successfully')
            props.onSubmit()
          } else {
            rsp.errors.filter(e => e.group === 'services').map((err) => toast.error(err.error, { position: 'top-right' }))
          }
        } else {
          const { response } = await sendPut('/CareNetworkEdit/UpdateService', data)
          const rsp: { response: any, success: boolean, errors: postError[] } = response
          if (rsp.success) {
            toast.success('Service updated successfully')
            props.onSubmit()
          } else {
            rsp.errors.filter(e => e.group === 'services').map((err) => toast.error(err.error, { position: 'top-right' }))
          }
        }
      }
      setIsRequestLoading(false)
    }
  }

  const handleServiceChange = (id: string): void => {
    const intVal = parseInt(id)
    if (intVal === serviceSettings.systemService?.id) return
    const service = serviceList.find(p => p.id === intVal)
    const newSettings: childServiceSettings = {
      ...serviceSettings,
      systemService: {
        id: intVal,
        isdid: props.isdid,
        inactive: false,
        name: '',
        description: '',
        s3Key: '',
        serviceId: service?.serviceId ?? 0
      },
      systemProgram: null
    }
    setServiceSettings(newSettings)
  }

  const handleProviderChange = async (id: string): Promise<void> => {
    const intVal = parseInt(id)
    const newSettings: childServiceSettings = {
      ...serviceSettings,
      systemServiceProviderId: intVal,
      systemService: null,
      systemProgram: null
    }
    setServiceSettings(newSettings)

    const { response: rsp } = await sendGet(`/CareNetworkEdit/GetServices?id=${id}`, {})
    if (rsp !== undefined && rsp.length > 0) {
      setServiceList(rsp)
    } else {
      setServiceList([])
    }
    const { response: rsp2 } = await sendGet(`/CareNetworkEdit/GetPrograms?id=${id}`, {})
    if (rsp2 !== undefined && rsp2.length > 0) {
      setProgramList(rsp2)
    } else {
      setProgramList([])
    }
  }

  const handleProgramChange = async (prgmId: string): Promise<void> => {
    const intVal = parseInt(prgmId)
    if (intVal === serviceSettings.systemProgram?.id) return
    void GetFacilities(intVal)
    const program = programList.find(p => p.id === intVal)
    const existingService = props.careNetworkServices.find(s => s.systemProgramID === intVal &&
      s.systemServiceID === serviceSettings.systemService?.id &&
      s.status !== 'Exited' && s.status !== 'Cancelled' && s.status !== 'Denied' && s.status !== 'Transferred')
    setIsUpdateDisabled(false)
    const newSettings = {
      ...serviceSettings,
      systemProgram: {
        id: intVal, requiresIncomeCalculation: program?.requiresIncomeCalculation, programId: program?.programId
      } as program,
      desiredSchedule: null,
      facilityId: null,
      timeFrame: null
    }
    setServiceSettings(newSettings)
    if (program?.allowConcurrentEnrollment === false && existingService !== undefined) {
      toast.error('You are not allowed to create multiple enrollments for this program. You will need to initiate a transfer based on the current settings.')
      setIsUpdateDisabled(true)
    }
  }

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>Service Details - {props.childName}</span>
  </div>

  const isEnrolled = props.service.type === 'enrollment'
  const isExited = props.service.status === 'Exited'
  const isOpenReferral = props.service.status === 'Pending' || props.service.status === 'Waiting' || props.service.status === 'Waiver'

  const content = loaded
    ? isOpenReferral
      ? <ChildServiceSelection
          status={props.service.status}
          providerList={serviceProviders}
          serviceList={serviceList}
          programList={programList}
          facilityList={facilities}
          serviceSettings={serviceSettings}
          onProgramChange={handleProgramChange}
          onServiceChange={handleServiceChange}
          onServiceProviderChange={handleProviderChange}
          setServiceSettings={setServiceSettings}
          isNew={props.isNew}
          showIncome={serviceSettings.systemProgram?.requiresIncomeCalculation ?? false}
      />
      : <ServiceSummaryCard
          buildingName={props.service.facilityName ?? ''}
          blockName={props.service.sessionBlockName ?? '' }
          sessionName={props.service.sessionName ?? '' }
          serviceLogo={props.service.systemServiceLogo ?? null}
          serviceProviderLogo={props.service.serviceProviderLogo ?? null}
          programName={props.service.systemProgramName}
          referralTimeFrame={props.service.referralTimeFrame}
          serviceName={props.service.systemServiceName}
          serviceProviderName={props.service.systemServiceProviderName}
          serviceStatus={props.service.status}
      />
    : <CircularProgress />
  const fullContent = <>{props.errorContent}
    {content}
  </>

  if (props.childID === undefined) return <></>

  return <AcceptDenyModal
    id={'serviceModal'}
    open={props.open}
    titleContent={titleContent}
    maxWidth='sm'
    fullWidth={true}
    dialogContent={ fullContent }
    isUpdateDisabled={isUpdateDisabled}
    onClose={handleClose}
    acceptButtonText={props.isNew ? 'Submit' : 'Update'}
    denyButtonText='Exit'
    cancelButtonText={isOpenReferral ? 'Discard Changes' : 'Cancel'}
    onAccept={isOpenReferral ? handleSubmit : undefined}
    onDeny={isEnrolled && !isExited ? props.onExit : undefined}
  />
}
