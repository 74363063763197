import { type ChildCaregiver } from '../../../core/types'
import { CaregiverAddEdit } from '../../../Pages/CareNetworkManagement/CareNetworkDetail/CaregiverAddEdit'
import { CaregiverDetails } from '../../CaregiverDetails/Index'
import { useState } from 'react'
interface CaregiverCardDisplayProps {
  index: number
  edit: boolean
  childId: number
  isLoadedRecord: boolean
  caregiver: ChildCaregiver
  childName: string
  onSubmit: (index: number, caregiver: ChildCaregiver, match: boolean) => Promise<string[]>
  setEdit: (index: number, edit: boolean) => void
  onDelete: (index: number) => void
  hideDocumentLink?: boolean
}

export function CaregiverCardDisplay (props: CaregiverCardDisplayProps): JSX.Element {
  const [errors, setErrors] = useState<string[]>([])

  const handleClose = (): void => {
    props.setEdit(props.index, false)
  }

  const onEdit = (): void => {
    props.setEdit(props.index, true)
  }

  const onDelete = (): void => {
    props.onDelete(props.index)
  }

  const handleSubmit = async (caregiver: ChildCaregiver, match: boolean): Promise<void> => {
    const errors = await props.onSubmit(props.index, caregiver, match)
    setErrors(errors)
  }

  return <>
    <CaregiverDetails
      data={props.caregiver}
      studentName={props.childName}
      onCaregiverDelete={onDelete}
      onCaregiverEdit={onEdit}
      hideDocumentLink={props.hideDocumentLink}
    />
    <CaregiverAddEdit
      childID={props.childId}
      open={props.edit}
      onClose={handleClose }
      isLoadedRecord={props.isLoadedRecord}
      caregiverSearchEndpoint='/ReferralEdit/SearchCaregivers'
      onSubmit={handleSubmit}
      caregiver={props.caregiver}
      childName={props.childName}
      errors={errors}
    />
  </>
}
