import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { Button, Grid, Typography } from '@mui/material'
import { type EnrollmentDetails } from '../../../../core/types'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useParams } from 'react-router'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface ServiceSearchTypeSelectionProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

enum SearchType {
  Filter = 'Filter',
  Map = 'Map',
  ReachOut = 'ReachOut'
}

export function ServiceSearchTypeSelection (props: ServiceSearchTypeSelectionProps): JSX.Element {
  const { isdId } = useParams()
  const dispatch = useCaregiverWizardUpdateDispatch()
  const [selectedSearchType, setSelectedSearchType] = useState<string | null>(null)

  const caregiverForm = props.caregiverForm

  useScrollTop()

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentSubStep: 2 }
    const currentService = tempForm.currentChild.requestedServices.find(s => s.isCurrentlyUpdating)
    if (currentService != null) {
      currentService.startDate = null
    }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    if (selectedSearchType == null) {
      toast.error('Please select an option to continue')
      return
    }

    const tempForm = { ...caregiverForm, currentStep: 4, currentSubStep: 1 }
    if (selectedSearchType === SearchType.Map) {
      tempForm.currentSubStep = 2
    } else if (selectedSearchType === SearchType.ReachOut) {
      tempForm.currentSubStep = 3
      const tempChild = { ...tempForm.currentChild }
      const currentService = tempChild.requestedServices.find(s => s.isCurrentlyUpdating)
      if (currentService != null) {
        currentService.reachOut = true
        currentService.isCurrentlyUpdating = false
        currentService.facilityIds = []
      }
      const existingChildIndex = tempForm.children.findIndex(c => c.isCurrentlyUpdating)
      if (existingChildIndex !== -1) {
        tempChild.isCurrentlyUpdating = false
        tempForm.children[existingChildIndex] = tempChild
      }
      const initialForm = initialEnrollmentDetails()
      tempForm.currentChild = initialForm.currentChild
    }
    await saveSession(tempForm)
    props.handleStep(false)
    dispatch({ type: 'form', form: tempForm })
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ mb: '30px' }} component='div' data-testid="service-date-selection-prompt">
        <CustomLabelWithToolTip
          name='IF_Service_Select_Intro'
          isdId={isdId ?? 0}
          defaultLabelText='Thank you! Which of the following best describes how you would like to proceed?'
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (selectedSearchType === SearchType.Filter ? ' selected' : '')}
        data-testid='search-option-filter'
        onClick={() => { setSelectedSearchType(SearchType.Filter) }}
      >
        I have a service provider in mind
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (selectedSearchType === SearchType.Map ? ' selected' : '')}
        data-testid='search-option-map'
        onClick={() => { setSelectedSearchType(SearchType.Map) }}
      >
        I would like to browse providers in the area
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (selectedSearchType === SearchType.ReachOut ? ' selected' : '')}
        data-testid='search-option-reach-out'
        onClick={() => { setSelectedSearchType(SearchType.ReachOut) }}
      >
        I would like someone to reach out to me
      </Button>
    </Grid>

    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='continueButton'
        className='footer-button'
        data-testid='continue-button'
        onClick={handleContinue}
        variant='contained'
      >
        Continue
      </Button>
    </Grid>
  </Grid>
}
