import { Box, Button, Grid, IconButton } from '@mui/material'
import { type lookup, type income } from '../../../core/types'
import { TextFieldWithLabel } from '../../TextField'
import { SelectWithLabel } from '../../SelectWithLabel'
import { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

interface CaregiverFinanceListProps {
  incomes: income[]
  setIncomes: (incomes: income[]) => void
}

export function CaregiverFinanceList (props: CaregiverFinanceListProps): JSX.Element {
  const [dateRanges, setDateRanges] = useState<lookup[]>([])

  useEffect(() => {
    const yearOptions: lookup[] = []
    const currentYear = new Date().getFullYear()
    for (let i = currentYear - 5; i <= currentYear + 1; i++) {
      yearOptions.push({ id: i, name: i.toString() })
    }
    setDateRanges(yearOptions)
  }, [])

  const [index, setIndex] = useState(0)

  const addSection = (): void => {
    const income: income = {
      annualIncome: 0,
      careNetworkID: 0,
      id: index,
      year: 0,
      memberCount: 0,
      unknownIncome: false
    }
    setIndex(index + 1)
    const tempArray = [...props.incomes, income]
    props.setIncomes(tempArray)
  }

  const removeSection = (indx: number): void => {
    const tempArray = [...props.incomes].filter(y => y.id !== indx)
    props.setIncomes(tempArray)
  }

  const updateIncome = (indx: number, value: number): void => {
    const tempArray = [...props.incomes]
    const income = tempArray.find(v => v.id === indx)
    if (income !== undefined) {
      if (isNaN(value)) { value = 0 }
      income.annualIncome = value
      props.setIncomes(tempArray)
    }
  }

  const updateMembers = (index: number, value: number): void => {
    const tempArray = [...props.incomes]
    const income = tempArray.find(v => v.id === index)
    if (income !== undefined) {
      income.memberCount = value
      props.setIncomes(tempArray)
    }
  }

  const updateYear = (index: number, value: number): void => {
    const tempArray = [...props.incomes]
    const income = tempArray.find(v => v.id === index)
    if (income !== undefined) {
      income.year = value
      props.setIncomes(tempArray)
    }
  }

  return (
    <Box>
      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        {
          props.incomes.map((val) => {
            const card = <Grid container columns={12} key={val.id}>
              <Grid item sm={3} md={3} sx={{ paddingRight: '10px' }} alignItems='center' >
                <TextFieldWithLabel
                  label='Taxable Income'
                  name='taxableIncome'
                  onChange={(e) => { updateIncome(val.id, parseInt(e.target.value)) }}
                  value={val.annualIncome}
                  pattern='[0-9]*'
                  dataTestId='taxableIncome'
                />
              </Grid>
              <Grid item sm={2} md={2} sx={{ paddingRight: '10px' }}>
                <SelectWithLabel
                  width='100%'
                  label='Tax Year'
                  name='dateRangeID'
                  onChange={(e) => { updateYear(val.id, parseInt(e.target.value.toString())) }}
                  options={dateRanges}
                  value={val.year}
                />
              </Grid>
              <Grid item sm={3} md={3} sx={{ paddingRight: '10px' }}>
                <TextFieldWithLabel
                  label='Member Count'
                  name='memberCount'
                  onChange={(e) => { updateMembers(val.id, parseInt(e.target.value.toString() !== '' ? e.target.value.toString() : '0')) }}
                  value={val.memberCount}
                  dataTestId='memberCount'
                />
              </Grid>
              <Grid item sm={1} md={1} alignItems={'center'} display={'flex'}>
                <IconButton onClick={() => { removeSection(val.id) }} >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            return card
          })
        }
      </Box>

      <div className="d-flex f-justify-content-center">
        <Button size="small" variant="text" sx={{ color: '#595959', textTransform: 'none' }}
          onClick={addSection}
        >
          Add Income Year +
        </Button>
      </div>
    </Box>
  )
}
