import { useEffect, useState } from 'react'
import { Modal } from '../../../Components/Modal'
import { sendGet } from '../../../hooks/use-fetch'
import { Grid, Typography } from '@mui/material'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { type postError, type ChildEditValues, type ChildSiblings, type location, type lookup } from '../../../core/types'
import { ChildEditLayout } from './ChildEditLayout'
import { RaceList } from '../../../Components/CheckList/RaceList'
import { EligiblityFactorList } from '../../../Components/CheckList/EligiblityFactorList'
import { LocationDetails } from '../../../Components/Cards/LocationCard/LocationDetails'
import { MapStateName } from '../../../Components/Cards/LocationCard/mapStateName'
import { type AdditionalValues, ChildAdditionalInfoList } from '../../../Components/CheckList/ChildAdditionalInfoList'

export interface ChildEditProps {
  child: ChildSiblings
  open: boolean
  onClose: () => void
  onSubmit: (childData: ChildEditValues, childImage: File | null) => void
  errors?: postError[] | undefined
  isSibling: boolean
  isUnborn: boolean
  siblingName?: string
}

export function ChildEdit (props: ChildEditProps): JSX.Element {
  const [languages, setLanguages] = useState<lookup[]>([])
  const [authStatuses, setAuthStatuses] = useState<lookup[]>([])
  const [child, setChild] = useState<ChildSiblings>(props.child)
  const [file, setFile] = useState<File | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/Language/GetLookup?sort=true', {})
      if (error[0] === '') {
        setLanguages(response)
      }
      const { response: response2, error: error2 } = await sendGet('/AuthorizationStatus/GetLookup', {})
      if (error2[0] === '') {
        setAuthStatuses(response2)
      }
    }
    void fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setChild(props.child)
    }
    void fetchData()
  }, [props.open])

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>Edit Child</span>
  </div>

  const handleClose = (): void => {
    props.onClose()
  }

  const handleChange = (updatedChild: ChildEditValues): void => {
    if (child !== undefined) {
      setChild({ ...child, ...updatedChild }) // merge updatedChild onto child
    }
  }

  const valueChange = (field: string, e: any): void => {
    if (child !== undefined) {
      setChild({ ...child, [field]: e })
    }
  }

  const handleSubmit = (): void => {
    if (child !== undefined) {
      child.isUnborn = props.isUnborn
      props.onSubmit(child, file)
    }
  }
  const additionalValues: AdditionalValues = {
    hasSNAPBenefits: child.hasSNAPBenefits,
    isFosterChild: child.isFosterChild,
    isOnCashAssistance: child.isOnCashAssistance,
    isOnSSI: child.isOnSSI
  }

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: location = child.childLocations[0]
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode

    valueChange('childLocations', [{ ...child.childLocations[0], ...newplace }])
  }

  const content = <>
    <ChildEditLayout
      file={file}
      setFile={setFile}
      child={child}
      isUnborn={props.isUnborn}
      handleChange={handleChange}
      languages={languages}
      authStatuses={authStatuses}
      errors={props.errors}
    />

    {(props.child?.canEditEligibilityFactors) &&
      <EligiblityFactorList
        captureIEP={child.captureIEP}
        captureIFSP={child.captureIFSP}
        captureEOIS={child.captureEOIS}
        hasIEP={child.hasIEP}
        hasIFSP={child.hasIFSP}
        hasEOIS={child.hasEOIS}
        eligiblityList={child.eligibilityFactorList ?? []}
        enviromentalRiskList={child.environmentalRiskList ?? []}
        setEligibilityValues={(factors, envRisks, values) => {
          setChild({ ...child, eligibilityFactorList: factors, environmentalRiskList: envRisks, otherEligibilityFactor: values.otherFactor, hasIEP: values.hasIEP, hasIFSP: values.hasIFSP, hasEOIS: values.hasEOIS })
        }}
        otherFactor={child.otherEligibilityFactor}
      />
    }
    <RaceList
      raceList={child.childRaceList ?? []}
      setRaceList={(raceList) => { valueChange('childRaceList', raceList) }}
    />
    <ChildAdditionalInfoList
      values={additionalValues}
      setAdditionalValues={(values) => {
        setChild({ ...child, hasSNAPBenefits: values.hasSNAPBenefits, isFosterChild: values.isFosterChild, isOnCashAssistance: values.isOnCashAssistance, isOnSSI: values.isOnSSI })
      }}
    />
    {props.isSibling && (
      <Grid container>
        <Grid item>
          <Typography variant='h4' fontWeight={'bold'} >Sibling of {props.siblingName}</Typography>
        </Grid>
        <Grid item>
          <CheckBoxWithLabel
            label={'Lives With'}
            value={child.isLivingWithStudent}
            name='IsLivingWithStudent'
            setValue={(value) => { valueChange('isLivingWithStudent', value) }}
            edge={'start'}
          />
        </Grid>
        {!child.isLivingWithStudent && <Grid item>
          <div className="info-box-header">Home Address</div>
          <LocationDetails
            editData={child.childLocations[0]}
            onChange={(e) => {
              valueChange('childLocations', [{ ...child.childLocations[0], [e.target.name]: e.target.value }])
            }}
            onPlaceSelected={handlePlaceSelected}
            showHomelessOption={false}
            hidePrimary
          />
        </Grid>
        }
      </Grid>
    )}

  </>
  return <Modal
    open={props.open}
    titleContent={titleContent}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
