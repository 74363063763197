import React from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CancelIcon from '@mui/icons-material/Cancel'

interface Props {
  files: FileList | null
  setFiles: (files: FileList | null) => void
  multiple?: boolean
  instructions?: string
  fileType?: string
  removable?: boolean
}

const DragDropFiles = (props: Props): JSX.Element => {
  const { files, setFiles } = props
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleDragOver = (event: React.DragEvent<HTMLInputElement>): void => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLInputElement>): void => {
    event.preventDefault()
    setFiles(event.dataTransfer.files)
  }

  const removeFile = (fileIndex: number): void => {
    if (files !== null) {
      const updatedFiles = Array.from(files)
      updatedFiles.splice(fileIndex, 1)
      if (updatedFiles.length === 0) {
        setFiles(null)
      } else {
        const updatedFileList = updatedFiles.reduce((fileList, file) => {
          fileList.items.add(file)
          return fileList
        }, new DataTransfer())
        setFiles(updatedFileList.files)
      }
    }
  }

  return (
    <>
      <div
        style={{ height: 'auto', width: 'auto', border: '2px dashed #02AED9', borderRadius: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => { inputRef.current?.click() } }
        data-testid='dragDrop-div'
      >
        <div style= {{ padding: '15px', height: '30px' }} >
          <div style= {{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} data-testid='uploadFile-Icon'>
            <UploadFileIcon sx={{ width: '29px', height: '24px', color: '#02AED9', strokeWidth: '5' }} />
            <input
              type="file"
              accept={props.fileType ?? ''}
              multiple={props.multiple ?? false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setFiles(event.target.files) }}
              hidden
              ref={inputRef}
              data-testid='uploadFile-input'
            />
          </div>
          <div>
            <button style={{ border: 'none', backgroundColor: 'inherit', cursor: 'pointer' }}><p style={{ margin: '0', fontSize: '13px', lineHeight: '16px', color: '#02AED9' }}><b>{props.instructions ?? 'Upload a File'}</b></p></button>
          </div>
        </div>
      </div>
      {
          files !== null && props.removable !== false && (
            <div>
              <ul>
                {Array.from(files).map((file, idx) => <li style={{ marginRight: '10px', maxWidth: '150px', whiteSpace: 'nowrap', fontSize: '12px' }} key={idx}>{file.name}<CancelIcon sx={{ width: '12px', height: '12px' }} onClick={() => { removeFile(idx) }} data-testid='cancelIcon'/> </li>)}
              </ul>
            </div>
          )
        }
    </>
  )
}

export default DragDropFiles
