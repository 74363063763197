import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { type DocumentVerifyModel } from '../../core/types'
import { DocumentVerify } from '../DocumentList/DocumentVerify'
import { DownloadDocument } from '../../core/Utilities'

export interface IncludeDocumentProps {
  documentID: number
  childID: number
  onInclude: (item: DocumentVerifyModel) => void
  years: number[]
}

export function IncludeDocument (props: IncludeDocumentProps): JSX.Element {
  const [documentVerify, setDocumentVerify] = useState<DocumentVerifyModel | null>(null)

  const handleInclude = async (): Promise<void> => {
    if (documentVerify !== null) {
      if (documentVerify.documentCaregivers.length !== 0 && !documentVerify.documentCaregivers.some(caregiver => caregiver.appliesTo)) {
        toast.error('Please select at least one caregiver')
      } else {
        props.onInclude(documentVerify)
      }
    }
  }

  const downloadDocument = (documentId: number): void => {
    DownloadDocument(documentId)
  }

  return <>
    <DocumentVerify documentID={props.documentID} childID={props.childID} togglingVerificationStatus={false} onChange={setDocumentVerify} years={props.years} />
    {documentVerify !== null && <Box>
      <Button variant='outlined' color='inherit' className='mt-5' data-testid='includeButton' onClick={handleInclude}>{documentVerify.isVerified ? 'Include' : 'Verify and Include'}</Button>
      <Button variant='outlined' color='inherit' className='ml-10 mt-5' data-testid='downloadButton' onClick={() => { downloadDocument(props.documentID) }}>Download</Button>
    </Box >
    }
  </>
}
