import { Box } from '@mui/material'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'

export interface ServiceProviderManagementSearchCriteria {
  name: string
  status: string | undefined
}

export function ServiceProviderManagementSearch (props: { current: ServiceProviderManagementSearchCriteria, onChange: (newSearch: ServiceProviderManagementSearchCriteria) => void }): JSX.Element {
  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }

  const status = [{ id: 'Active', name: 'Active' }, { id: 'InProgress', name: 'In Progress' }, { id: 'Inactive', name: 'Inactive' }]

  return (<Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
    <TextFieldWithLabel
      label='Provider Name'
      onChange={handleChange}
      name='name'
      maxWidth='205px'
      value={props.current.name ?? ''}
      data-test-name-search
    />
    <SelectWithLabel
      label='Status'
      name='status'
      onChange={handleChange}
      width='135px'
      value={props.current.status ?? ''}
      options={status}
      data-test-status-search
    />
  </Box>
  )
}
