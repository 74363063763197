import { type sessionBlock, type program, type EnrollmentStatus, type lookup } from '../../../core/types'
import { EnrollmentList } from './EnrollmentList'

export interface programHoldListProps {
  enrollmentStatus: EnrollmentStatus
  sessionBlockList: sessionBlock[]
  sessionList: lookup[]
  programs: program[]
  facilityList: lookup[]
  exitReasons?: lookup[]
  onEnrollmentChanged: () => void
}

export function ProgramHoldList (props: programHoldListProps): JSX.Element {
  return (<>
    <EnrollmentList
      enrollmentStatus={props.enrollmentStatus}
      facilityList={props.facilityList}
      programs={props.programs}
      sessionBlockList={props.sessionBlockList}
      sessionList={props.sessionList}
      exitReasons={props.exitReasons}
      showExitFilter={false}
      enrollment={true}
      exit={true}
      massEnrollment={true}
      massExit={true}
      onEnrollmentChanged={props.onEnrollmentChanged}
    />
  </>)
}
