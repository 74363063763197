import { Box } from '@mui/material'
import { type ReorderItem } from './DragAndDropReorderList'
import { useEffect, useRef } from 'react'
import { DragDots } from '../../../../Assets/SVGs/drag-dots'

interface DraggableItemProps {
  item: ReorderItem
  draggingItemIndex: number | null
  onDragStart: (item: ReorderItem) => void
  onDragEnd: () => void
  onDrop: (item: ReorderItem) => void
  onTouchStart: (e: TouchEvent, elem: HTMLElement, item: ReorderItem) => void
  onTouchMove: (e: React.TouchEvent) => void
  onTouchEnd: (e: React.TouchEvent) => void
}

export function DraggableItem (props: DraggableItemProps): JSX.Element {
  const itemRef = useRef<HTMLElement>(null)

  const item = props.item

  useEffect(() => {
    const elem = itemRef.current
    if (elem == null) {
      return
    }
    // Use a non-passive event listener so we can call preventDefault() on the touchstart event.
    const eventHandler = (e: TouchEvent): void => { props.onTouchStart(e, elem, item) }
    elem.addEventListener('touchstart', eventHandler, { passive: false })

    return () => {
      elem.removeEventListener('touchstart', eventHandler)
    }
  }, [itemRef, item])

  return <Box
    ref={itemRef}
    className={'reorder-list-item ' + (props.draggingItemIndex === item.index ? ' dragging' : '')}
    draggable={true}
    onDragStart={() => { props.onDragStart(item) }}
    onDragEnd={props.onDragEnd}
    onDragOver={(e) => { e.preventDefault() }} // Needed to allow the item to be dropped on this element.
    onDrop={() => { props.onDrop(item) }}
    onTouchMove={props.onTouchMove}
    onTouchEnd={props.onTouchEnd}
  >
    <Box className="reorder-list-item-content">
      <Box>{item.firstLine}</Box>
      <Box sx={{ mt: '5px', mb: '5px' }}>{item.secondLine}</Box>
      <Box>{item.thirdLine}</Box>
    </Box>
    <Box className="reorder-list-item-handle">
      <DragDots />
    </Box>
  </Box>
}
