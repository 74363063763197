import { Box, Typography } from '@mui/material'

export function TermsAndConditions (): JSX.Element {
  return <Box>
    <Typography variant='h6'> MiECC System Usage Agreement</Typography>
    <p>
      Please read these Terms and Conditions ("Agreement") carefully before accessing or using MiECC (Michigan Early Childhood Connect). This Agreement sets forth the terms and conditions governing your use of the
      MiECC System provided by the MiCH Collaboration Hub at Michigan Association of Intermediate School Districts (MAISA). By accessing or using the Placement System, you agree to be bound by these Terms and Conditions.
      If you do not agree with any of these terms, you should not use the MiECC System.
    </p>

    <Typography variant='h6'> MiECC Overview</Typography>
    <ul>
      <li>MiECC is an online platform provided by the MiCH Collaboration Hub at MAISA to facilitate the placement of children in early childhood education and care programs in Michigan</li>
      <li>MiECC aims to match children with available program openings based on the preferences, eligibility criteria, and other relevant factors provided by parents or legal guardians</li>
      <li>MiECC is intended to streamline the enrollment process and enhance transparency and fairness in the placement of children in early childhood education and care programs</li>
    </ul>

    <Typography variant='h6'> Eligibility</Typography>
    To use MiECC, you must:
    <ul>
      <li>Be a parent or legal guardian of a child seeking placement in an early childhood education and care program in Michigan</li>
      <li>Be a representative of an ISD or RESA</li>
      <li>Be a representative of a Service Provider for Early Childhood services such as preschool placement, childcare, family assistance, or other services to help families in need</li>
      <li>Provide accurate and up-to-date information during the enrollment process and throughout the use of the MiECC System</li>
    </ul>

    <Typography variant='h6'> User Responsibilities</Typography>
    <ul>
      <li>You are responsible for maintaining the confidentiality and security of your MiECC Placement System account credentials</li>
      <li>You agree to use the MiECC Placement System only for lawful purposes and in accordance with these Terms and Conditions</li>
      <li>You acknowledge that the information you provide in the MiECC Placement System is truthful and accurate</li>
      <li>Any false or misleading information may result in the rejection of your application or the termination of your access to the MiECC Placement System</li>
    </ul>

    <Typography variant='h6'> Role of Intermediate School Districts (ISDs) or Regional Educational Service Agencies (RESAs)</Typography>
    <ul>
      <li>The ISDs or RESAs, as Placement Service Providers, are responsible for managing and administering the MiECC Placement System in their respective regions</li>
      <li>The ISDs or RESAs will collect and process the information provided by parents or legal guardians for the purpose of placing children in early childhood education and care programs</li>
      <li>The ISDs or RESAs will make reasonable efforts to match children with available and appropriate service providers based on the provided preferences and eligibility criteria</li>
      <li>The ISDs or RESAs will aid in the facilitation of placement decisions to parents or legal guardians and provide necessary information regarding enrollment and program requirements</li >
    </ul>

    <Typography variant='h6'> Limitation of Liability</Typography>
    <ul>
      <li>MAISA, the Placement Service Providers, and their respective employees, agents, and representatives shall not be liable for any direct, indirect, incidental, consequential,
        or punitive damages arising out of or related to the use of the MiECC Placement System</li>
      <li>MAISA and the Placement Service Providers do not guarantee the availability of program openings or the successful placement of children through the MiECC Placement System</li>
    </ul>

    <Typography variant='h6'> Modifications to the Placement System</Typography>
    <ul>
      <li>MAISA reserves the right to modify, suspend, or discontinue the MiECC Placement System or any part thereof at any time without prior notice</li>
      <li>MAISA may update these Terms and Conditions from time to time. It is your responsibility to review the most current version of the Agreement regularly</li>
    </ul>

    <Typography variant='h6'> Governing Law and Jurisdiction</Typography>
    <ul>
      <li>These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of Michigan</li>
      <li>Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the state and federal courts located in the State of Michigan</li>
    </ul>

    <p>
      By using the MiECC Placement System, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
    </p>
  </Box>
}
