import DialogActions from '@mui/material/DialogActions'
import { type ChildEditValues } from '../../core/types'
import { BannerModal } from '../Modal/BannerModal'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { RadiusCard } from '../Cards/Radius/RadiusCard'
import { BirthdayToAgeInYearsAndMonths, FormatDateValue } from '../../core/Utilities'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { VerifiedCheck } from '../../Assets/SVGs/VerifiedCheck'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { CheckBoxWithLabel } from '../../Components/CheckBox'

interface UICLookupModalProps {
  child: ChildEditValues
  onClose: (uic: string | null, child: IdentityResponseItem | null, updateValues: boolean) => void
}

enum ProcessState {
  CannotDoUICLookup,
  ChildReview,
  PerformLookup,
  WaitWhileProcessing,
  NoMatchFound,
  DisplayMatches,
  ConfirmRequestNewUIC,
  ProcessError
}

// this is the data we get back from the UIC API call
export interface IdentityResponseItem {
  uniqueId: string // the UIC
  score: number
  lastSurname: string
  firstName: string
  middleName: string | null
  generationCodeSuffix: string | null
  sexType: string
  birthDate: Date
}

export function UICLookupModal (props: UICLookupModalProps): JSX.Element {
  const [title, setTitle] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [currentState, setCurrentState] = useState<ProcessState>(ProcessState.ChildReview)
  const [identityResponses, setIdentityResponses] = useState<IdentityResponseItem[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [updateValues, setUpdateValues] = useState(false)
  const account = useAccountId()
  let isdParam: string = ''
  if (account?.type === 'ISD') {
    // if we don't provide the ISD via this, then it's gotten on the server side from the current user
    isdParam = `?isdID=${account.id}`
  }
  const cancelButton = <Button
    variant='outlined'
    color='inherit'
    sx={{ fontWeight: 'bold' }}
    onClick={() => { props.onClose(null, null, false) }}
  >
    Cancel
  </Button>

  const currentChildDisplay = <RadiusCard header={`${props.child.firstName} ${props.child.middleName ?? ''} ${props.child.lastName} ${props.child.suffix ?? ''} (${BirthdayToAgeInYearsAndMonths(props.child.dateOfBirth) ?? ''})`} content={
    <div>
      <div>{FormatDateValue(props.child.dateOfBirth)}</div>
      <div>{(props.child.birthGender === 'M' ? 'Male' : 'Female')}</div>
      <div>{props.child.uic}</div>
    </div>
  }
  />

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet(`/ChildDetails/CanISDUseUICLookup${isdParam}`, {})
      if (response === false) {
        setCurrentState(ProcessState.CannotDoUICLookup)
      }
    }
    void fetchData()
  }, [])

  useEffect(() => {
    const fetchData = (): void => {
      if (currentState === ProcessState.ChildReview) {
        setTitle('Lookup UIC')
        setMessage('Please review the child\'s information')
      } else if (currentState === ProcessState.CannotDoUICLookup) {
        setTitle('Lookup UIC')
        setMessage('Your ISD has not been configured to access the state\'s UIC Lookup API.')
      } else if (currentState === ProcessState.NoMatchFound) {
        setTitle('Lookup UIC')
        setMessage('No Match was found')
      } else if (currentState === ProcessState.DisplayMatches) {
        setTitle('Lookup UIC')
        setMessage('Please select the best matching result from the UIC lookup service')
      } else if (currentState === ProcessState.ConfirmRequestNewUIC) {
        setTitle('Request New UIC')
        setMessage('Are you sure you want to request a new UIC?')
      } else if (currentState === ProcessState.ProcessError) {
        setTitle('Lookup UIC')
        setMessage('There was an error calling the UIC service')
      } else if (currentState === ProcessState.WaitWhileProcessing) {
        setMessage('')
      }
    }
    fetchData()
  }, [currentState])

  const performLookup = async (): Promise<void> => {
    setCurrentState(ProcessState.WaitWhileProcessing)
    const { response, success } = await sendGet(`/ChildDetails/SearchForStudentUIC${ChildParams(props.child)}`, {})
    if (!success) {
      setCurrentState(ProcessState.ProcessError)
    } else if (response === null) {
      setCurrentState(ProcessState.NoMatchFound)
    } else {
      setIdentityResponses(response)
      setCurrentState(ProcessState.DisplayMatches)
    }
  }

  const requestUIC = async (): Promise<void> => {
    setCurrentState(ProcessState.WaitWhileProcessing)
    const { response, error, success } = await sendPost(`/ChildDetails/RequestNewUIC${ChildParams(props.child)}`, {})
    if (success) {
      // a UIC was successfully requested
      props.onClose(response, null, false)
    } else {
      toast.error(error)
    }
  }

  function ChildParams (child: ChildEditValues): string {
    let result = isdParam
    if (isdParam === '') {
      result = '?'
    } else {
      result += '&'
    }
    result += `firstName=${child.firstName}&lastName=${child.lastName}&dob=${FormatDateValue(child.dateOfBirth)}&gender=${child.birthGender}&middleName=${child.middleName ?? ''}`
    return result
  }

  const selectMatch = (index: number): void => {
    setSelectedIndex(index)
  }

  const bindUIC = (): void => {
    if (selectedIndex === null) {
      toast.error('Please select a match to continue')
    } else {
      props.onClose(identityResponses[selectedIndex].uniqueId, identityResponses[selectedIndex], updateValues)
    }
  }

  return <BannerModal title={title} noButtons onClose={() => { props.onClose(null, null, false) }} onConfirm={function (): void { }} open={true} hideXtoClose content={<>
    <Typography fontWeight='bold'>{message}</Typography>
    {currentState === ProcessState.WaitWhileProcessing &&
      <CircularProgress />
    }
    {(currentState === ProcessState.CannotDoUICLookup || currentState === ProcessState.ProcessError) &&
      <>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          {cancelButton}
        </DialogActions >
      </>
    }
    {currentState === ProcessState.ChildReview &&
      <>
        {currentChildDisplay}
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' sx={{ fontWeight: 'bold' }} onClick={performLookup} >
            Perform Lookup
          </Button>
          {cancelButton}
        </DialogActions >
      </>
    }
    {currentState === ProcessState.NoMatchFound &&
      <>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' sx={{ fontWeight: 'bold' }} onClick={() => { setCurrentState(ProcessState.ConfirmRequestNewUIC) }} >
            Request New UIC
          </Button>
          {cancelButton}
        </DialogActions >
      </>
    }
    {currentState === ProcessState.DisplayMatches &&
      <>
        {currentChildDisplay}
        <Typography fontWeight='bold' style={{ paddingTop: '10px' }} >Results</Typography>

        {identityResponses.map((item, index) => {
          let cardClass = 'card-warning'
          if (item.score >= 99.0) {
            cardClass = 'card-match'
          } else if (item.score >= 80.0) {
            cardClass = 'card-caution'
          }
          return <RadiusCard className={cardClass} header={`${item.firstName} ${item.middleName ?? ''} ${item.lastSurname} ${item.generationCodeSuffix ?? ''} (${BirthdayToAgeInYearsAndMonths(item.birthDate)})`} content={
            <>
              <Grid item xs={8}>
                <div>{FormatDateValue(item.birthDate)}</div>
                <div>{(item.sexType === 'M' ? 'Male' : 'Female')}</div>
                <div>{item.uniqueId}</div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ paddingBottom: '5px' }}> {item.score}% Match</div>
                {index === selectedIndex
                  ? <VerifiedCheck />
                  : <Button variant='outlined' color='inherit' onClick={() => { selectMatch(index) }}>Select</Button>
                }
              </Grid>
            </>
          }
          />
        })}
        <CheckBoxWithLabel label="Update data to match this result" value={updateValues} name='updateData' setValue={function (newVal: boolean): void { setUpdateValues(newVal) } } edge={'start'} />

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' sx={{ fontWeight: 'bold' }} onClick={bindUIC} >
            Bind Selected UIC
          </Button>
          {cancelButton}
        </DialogActions >
      </>
    }
    {currentState === ProcessState.ConfirmRequestNewUIC &&
      <>
        {currentChildDisplay}
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' sx={{ fontWeight: 'bold' }} onClick={requestUIC} >
            Confirm
          </Button>
          {cancelButton}
        </DialogActions >
      </>
    }
  </>
  }
  />
}
