/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { CircularProgress, Chip } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useSearch, type SearchProps } from '../../hooks/use-search'
import { CareNetworkSearch, type CareNetworkSearchCriteria } from './CareNetworkSearch'
import { generatePath, useNavigate } from 'react-router'
import * as AppRoutes from '../Routes'
import './index.css'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { AccessRequestAction } from './CareNetworkDetail/AccessRequestAction'
import { AccountType } from '../../core/types'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { sendPost } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'

const nameColumn = new Column('fullName', 'Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = row => {
  if (row.image != null) {
    const imageurl: string = row.image.toString()
    return <div key={row.id + 'childInfo'}><img src={'data:image /png; base64,' + imageurl} /> <span>{row.name}</span></div>
  } else {
    return <div key={row.id + 'childInfo'}>{row.name}</div>
  }
}

const ageColumn = new Column('age', 'Age')
const isdColumn = new Column('residentISD', 'Resident ISD')

const residentAddressColumn = new Column('address', 'Resident Address', ColumnType.CUSTOM)
residentAddressColumn.customCellGenerator = row => {
  return <div key={row.id + 'locationInfo'}>
    <div>{row.address} </div>
    <div>{row.city} {row.state} {row.zipCode}</div>
  </div>
}
residentAddressColumn.sortable = false

const careGiverName = new Column('caregiverName', 'Caregivers', ColumnType.CUSTOM)
careGiverName.customCellGenerator = row => {
  return <div key={row.id + 'caregiverInfo'}>
    {row.childSearchCaregivers.map((h: { firstName: string, lastName: string, contactPhone: string }) => {
      return <div key={'caregiver' + h.firstName + h.lastName}>{h.firstName} {h.lastName}</div>
    })}
  </div>
}
careGiverName.sortable = false

const careGiverContact = new Column('caregiverContact', 'Contact Phone', ColumnType.CUSTOM)
careGiverContact.customCellGenerator = row => {
  const content = row.childSearchCaregivers.map((h: { firstName: string, lastName: string, contactPhone: string }) => {
    return <div key={h.firstName + h.lastName + 'phone'}>{h.contactPhone}</div>
  })
  return <div key={row.id + 'contactInfo'}>
    {content}
  </div>
}
careGiverContact.sortable = false

const IsdServices = new Column('services', 'Services', ColumnType.CUSTOM)
IsdServices.customCellGenerator = row => {
  // pick one of 3 colors by doing a modulo on the id
  const listItems: JSX.Element[] = []
  const listNames: string[] = []
  row.childServices.map((s: { systemServiceID: number, systemServiceName: string }) => {
    const colorItem = s.systemServiceID % 3
    let indicatorClass = 'cyan-'
    if (colorItem === 1) {
      indicatorClass = 'purple-'
    } else if (colorItem === 2) {
      indicatorClass = 'red-'
    }
    if (!listNames.includes(s.systemServiceName)) {
      listNames.push(s.systemServiceName)
      listItems.push(<Chip key={s.systemServiceID?.toString() + 'indicator'} icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={s.systemServiceName} size='small' className={indicatorClass + 'indicator'} />)
    }
    return s.systemServiceName
  })
  IsdServices.sortable = false

  return <div key={row.id + 'stackedItems'} className='stacked-items'>
    {listItems}
  </div>
}

const requestAccessColumn = new Column('access', '', ColumnType.CUSTOM)
requestAccessColumn.sortable = false

const columns = [nameColumn, ageColumn, isdColumn, residentAddressColumn, careGiverName, careGiverContact, IsdServices, requestAccessColumn]

export function CareNetworkManagement (): JSX.Element {
  const account = useAccountId()
  const [searchData, setSearchData] = useState<CareNetworkSearchCriteria>({
    caregiverName: '',
    caregiverPhone: '',
    caregiverEmail: '',
    isd: account?.type === AccountType.ISD ? account?.id.toString() : '',
    name: '',
    inactive: false,
    currentProvider: '',
    intakeProvider: account?.type === AccountType.INTAKE_USER ? account?.id.toString() : '',
    onlyNew: false,
    noProvider: false,
    showInformationalOnly: false,
    service: '',
    birthDate: null,
    showStep4Only: false
  })
  const [showEnableServicesModal, setShowEnableServicesModal] = useState(false)

  requestAccessColumn.customCellGenerator = row => {
    return <AccessRequestAction
      childNotTiedToISD={row.childNotTiedToISD}
      currentAccessRequestID={row.currentAccessRequestID}
      currentAccessRequestStatus={row.currentAccessRequestStatus}
      requiresAccessRequest={!(row.canSelect as boolean)}
      childId={row.id}
      changeMade={() => { setRefreshTime(new Date()) }}
    />
  }

  const nav = useNavigate()

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    if (row.canSelect === true) {
      if (row.isInformationalOnly === true) {
        selectedChild.current = Number(row.id)
        setShowEnableServicesModal(true)
      } else {
        const editChild = generatePath(AppRoutes.CARENETWORK_MANAGEMENT_DETAILS, { id: row.id })
        nav(editChild)
      }
    }
  }

  const [refreshTime, setRefreshTime] = useState(new Date())
  const selectedChild = useRef(0)

  const handleSearch = (newSearch: CareNetworkSearchCriteria): void => {
    setSearchData(newSearch)
    const debouncedSearch = debounce(callSearch, 250) // doing a little research, 250ms debounce delay is about optimal
    debouncedSearch()
  }

  function callSearch (): void {
    setRefreshTime(new Date())
    onPageChange(null, 0)
    setPage(0)
  }

  const timer = useRef<NodeJS.Timeout | null>(null)

  function debounce<Params extends any[]> (func: (...args: Params) => any, timeout: number): (...args: Params) => void {
    return (...args: Params) => {
      if (timer.current !== null) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }

  const handleCloseEnableServicesModal = (): void => {
    setShowEnableServicesModal(false)
    selectedChild.current = 0
  }

  const handleConfirmEnableServices = async (): Promise<void> => {
    const url = `/ChildDetails/EnableServices/${selectedChild.current}`
    const response = await sendPost(url, {})
    if (response.success) {
      const editChild = generatePath(AppRoutes.CARENETWORK_MANAGEMENT_DETAILS, { id: String(selectedChild.current) })
      nav(editChild)
    } else {
      toast.error('An error occurred while updating the child')
    }
  }

  const searchType: SearchProps = { includes: '', endPoint: 'ChildSearch', searchData, refreshTime, autoUpdateOnSearchDataChange: false }
  if (account?.type === AccountType.ISD) {
    searchType.isdId = account?.id
  }
  const { rows, totalRecords, loading, onPageChange, onRowsPerPageChange, onSortChange } = useSearch(searchType)
  const [page, setPage] = useState(0)
  if (loading) return <CircularProgress />

  return (<>
    <Typography variant="h3">Find Child Care Networks</Typography>
    <CareNetworkSearch current={searchData} onChange={handleSearch} />

    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          page={page}
          hasCheckbox={false}
          name='userTable'
          columns={columns}
          rows={rows ?? []}
          totalRecords={totalRecords}
          loading={loading}
          onSortChange={onSortChange}
          onPageChange={(e, p) => {
            onPageChange(e, p)
            setPage(p)
          }}
          onRowsPerPageChange={onRowsPerPageChange}
          onRowClick={rowClick}
          initialColumnSorts={[new ColumnSort('fullName')]}
        />
      </Box>
    </div>

    <BannerModal
      open={showEnableServicesModal}
      onConfirm={handleConfirmEnableServices}
      onClose={handleCloseEnableServicesModal}
      title={'Confirmation'}
      hideXtoClose={true}
      content={<div>This will enable this child for services, do you wish to continue?</div>}
    />
  </>)
}
