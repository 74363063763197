import { Box, Breadcrumbs, Button, Chip, Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Column, ColumnType, ColumnSort, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { usePagination, type PaginationProps, type SearchCriteria } from '../../../hooks/use-pagination'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import DataTable from '../../../Components/Table/DataTable'
import { StaffSearch } from './SettingsStaffSearch'
import { Modal } from '../../../Components/Modal'
import { ServiceProviderStaffDetail, type StaffData } from './SettingsStaffDetails'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { useNavigate } from 'react-router-dom'
import { SERVICE_PROVIDER_ADMIN_SETTINGS } from '../../Routes'

const nameColumn = new Column('fullName', 'Staff Name')
const emailColumn = new Column('email', 'Email')
const statusColumn = new Column('inactive', 'Account Status', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  let color = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  let label = 'Active'
  if (row?.inactive as boolean) {
    color = '#D4316C !important'
    label = 'Inactive'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<></>} label={label} size="small" sx={{ color, backgroundColor }} />
}

const accessLevelColumn = new Column('userFunctionConfig', 'Access Level', ColumnType.CUSTOM)
accessLevelColumn.customCellGenerator = row => {
  const color = '#4DA4A4 !important'
  const backgroundColor = 'rgba(77, 164, 164, 0.1)'
  const label = row.userFunctionConfig
  return <Chip icon={<></>} label={label} size="small" sx={{ color, backgroundColor }} />
}

const columns = [nameColumn, emailColumn, accessLevelColumn, statusColumn]

export function ServiceProviderStaffList (): JSX.Element {
  const nav = useNavigate()
  const account = useAccountId()
  const [search, setSearch] = useState({ email: '', name: '' })
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: staffInfo } = await sendGet('/ServiceProviderSettings/GetProviderStaff?systemSpID=' + (account?.id?.toString() ?? ''), {})
      try {
        const data = (staffInfo as StaffData[]).map(s => {
          const firstName = s.firstName
          const lastName = s.lastName
          const fullName = `${firstName} ${lastName}`
          const staffData = { ...s, fullName }
          return staffData
        })
        setRows(data)
      } catch (e) {
        setRows([])
      }
      setLoading(false)
    }
    void fetchData()
  }, [refreshTime])

  const serviceProviderID: number | null = useAccountId()?.id ?? null
  const [selectedRowData, setSelectedRowData] = useState<StaffData>({ id: 0, firstName: '', lastName: '', fullName: '', email: '', securityLevel: 2, inactive: false, userFunctionConfig: 'No Access', defaultServiceProviderID: serviceProviderID })
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[] | undefined>(undefined)

  const modalContent = <>
    {errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}

    <ServiceProviderStaffDetail userData={selectedRowData} onChange={setSelectedRowData}/>
  </>

  const handleSubmit = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const endpoint = selectedRowData.id === 0 ? 'Add' : 'Update'
      const { response } = await sendPost(`/ServiceProviderSettings/${endpoint}Staff`, { data: selectedRowData, sspId: serviceProviderID })
      if (response === '') {
        setErrors(undefined)
        setIsDetailModalOpen(false)
        setRefreshTime(new Date())
      } else {
        setErrors((response as string).split(';'))
      }
      setIsRequestLoading(false)
    }
  }

  const addStaff = (): void => {
    const rowDat: StaffData = {
      securityLevel: account?.type === 'Service Provider' ? 2 : 5,
      inactive: false,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      id: 0,
      userFunctionConfig: 'No Access',
      userFunctions: [],
      defaultServiceProviderID: serviceProviderID
    }
    setErrors(undefined)
    setSelectedRowData(rowDat)
    setIsDetailModalOpen(true)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const fullname: string = `${row.firstName as string} ${row.lastName as string}`
    const rowDat: StaffData = {
      securityLevel: account?.type === 'Service Provider' ? 2 : 5,
      inactive: row.inactive,
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      fullName: fullname,
      id: row.id,
      userFunctionConfig: row.userFunctionConfig,
      userFunctions: row.userFunctions,
      defaultServiceProviderID: serviceProviderID
    }
    setErrors(undefined)
    setSelectedRowData(rowDat)
    setIsDetailModalOpen(true)
  }

  const paginationProps: PaginationProps<RowData> = {
    rows,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  const handleSearchChange = (e: any): void => {
    const newSearchVal = { ...search, [e.target.name]: e.target.value }
    setSearch(newSearchVal)

    const searchTerms: SearchCriteria[] = []

    if (newSearchVal.name !== '') { searchTerms.push({ field: 'fullName', value: newSearchVal.name }) }
    if (newSearchVal.email !== '') { searchTerms.push({ field: 'email', value: newSearchVal.email }) }
    pagination.setSearchFields(searchTerms)
  }

  return <Box sx={{ width: '95%' }}>
    <Typography variant="h3">Staff List</Typography>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ marginTop: '10px', marginBottom: '10px' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { nav(SERVICE_PROVIDER_ADMIN_SETTINGS) }} >
        Service Provider Settings
      </Link>
    </Breadcrumbs>

    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box><StaffSearch email={search.email} name={search.name} onChange={handleSearchChange} /></Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button variant='contained' onClick={addStaff} >Add Staff</Button>
      </Box>
    </Box>
    <Box>
      <DataTable
        name='staffTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        page={pagination.page}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
        initialColumnSorts={[new ColumnSort('name')]}
      />

    </Box>
    {isDetailModalOpen && selectedRowData !== null && (
      <Modal
        onClose={() => { setIsDetailModalOpen(false) }}
        open={isDetailModalOpen}
        onConfirm={handleSubmit}
        confirmationContent={modalContent}
        title='Update Staff'
      />
    )}
  </Box>
}
