import { type ChangeEvent, useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { Box, Button, CircularProgress } from '@mui/material'
import DataTable from '../../../Components/Table/DataTable'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { Column, ColumnType } from '../../../Components/Table/DataTable/DataTable.model'
import { FormatDateTimeValue } from '../../../core/Utilities'
import { BannerModal } from '../../../Components/Modal/BannerModal'
import { TextAreaWithLabel } from '../../../Components/TextArea'

export interface EnrollmentNotesProps {
  enrollmentID: number
  isNotesPermission: boolean
  isEditable: boolean
}

interface EnrollmentNote {
  id: number
  enrollmentID: number
  note: string
  createdDate: Date
  userID: number
  userName: string
}

export function EnrollmentNotes (props: EnrollmentNotesProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const [notes, setNotes] = useState<EnrollmentNote[]>([])
  const [showNoteEdit, setShowNoteEdit] = useState(false)
  const [editingNote, setEditingNote] = useState<EnrollmentNote | undefined>()
  const [refreshTime, setRefreshTime] = useState(new Date())

  useEffect(() => {
    async function getData (): Promise<void> {
      const request = await sendGet(`/Enrollment/EnrollmentNotes?enrollmentId=${props.enrollmentID}`, {})
      const referralNotes: EnrollmentNote[] = request.response
      setNotes(referralNotes)

      setLoaded(true)
    }
    void getData()
  }, [refreshTime])

  const showNoteAddBox = (): void => {
    const record: EnrollmentNote = {
      id: 0,
      enrollmentID: props.enrollmentID,
      note: '',
      createdDate: new Date(),
      userID: 0,
      userName: ''
    }
    setEditingNote(record)
    setShowNoteEdit(true)
  }

  const handleNoteAdd = async (): Promise<void> => {
    if (editingNote !== undefined) {
      const { response } = await sendPost('/Enrollment/AddEnrollmentNote', editingNote)
      if (response !== undefined) {
        setRefreshTime(new Date())
        setShowNoteEdit(false)
      }
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (editingNote !== undefined) {
      setEditingNote({ ...editingNote, [e.target.name]: e.target.value })
    }
  }

  const getEditNoteContent = (): any => {
    if (editingNote === undefined) {
      return <></>
    } else {
      return <Box sx={{ margin: '20px' }}>
        <TextAreaWithLabel
          name="Note"
          label="Note"
          data-testid="Note"
          value={editingNote.note}
          onChange={handleChange}
        />
      </Box>
    }
  }
  const noteColumn = new Column('note', 'Note')
  const aboutColumn = new Column('createdDate', 'About', ColumnType.CUSTOM)
  aboutColumn.customCellGenerator = row => {
    return <div>Added by {row.userName} on {FormatDateTimeValue(row.createdDate)} </div>
  }

  const columns = [noteColumn, aboutColumn]

  const paginationProps: PaginationProps<EnrollmentNote> = {
    rows: notes,
    initialSize: 5,
    pageSizeSteps: [5, 10]
  }
  const pagination = usePagination(paginationProps)

  if (!loaded) return <CircularProgress role='loading' />

  if (!props.isNotesPermission) {
    return <div>You do not have access to this feature</div>
  }

  return <>
    {props.isEditable && < Box >
      <Button color='secondary' variant='contained' onClick={showNoteAddBox}>Add Note</Button>
    </Box>
    }

    <DataTable
      name='enrollmentNoteTable'
      page={pagination.page}

      data-testid='enrollmentNoteTable'
      hasCheckbox={false}
      columns={columns}
      rows={pagination.internalRows}
      rowClickable={false}
      sortable={false}
      totalRecords={pagination.recordCount}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    />

    <BannerModal
      className='Nested Referral'
      maxWidth='sm'
      open={showNoteEdit}
      content={getEditNoteContent()}
      onClose={() => { setShowNoteEdit(false) }}
      onConfirm={handleNoteAdd}
      title='New Enrollment Note'
      cancelButtonText='Cancel'
      confirmButtonText='Save'
    />
  </>
}
