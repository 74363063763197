import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material'
import { type service, type EnrollmentDetails, type PortalFacilityViewModel, type PortalLocation, type lookup } from '../../../../core/types'
import { useEffect, useRef, useState } from 'react'
import { sendGet } from '../../../../hooks/use-fetch'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useAuth } from '../../../../hooks/use-auth'
import { childAge, childName, getDayOfWeek } from '../../PortalHelpers'
import { FormatTimeAs12Hour } from '../../../../core/Utilities'
import { MapModule, useBingMaps } from '../../../../hooks/use-bingMaps'
import { DragAndDropReorderList, type ReorderItem } from './DragAndDropReorderList'
import { saveSession, submitForm, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { toast } from 'react-toastify'
import { CAREGIVER_PORTAL_SUBMITTED } from '../../../Routes'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { TextFieldWithLabel } from '../../../../Components/TextField'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface FinalSummaryProps {
  caregiverForm: EnrollmentDetails
  handleStep: (backwards: boolean) => void
}

interface ServiceView {
  id: number
  serviceName: string
  children: ChildView[]
}

interface ChildView {
  index: number
  childName: string
  providers: ReorderItem[]
}

interface FacilityData {
  facilityId: number
  facilityName: string
  todayHours: string
  distance: string
}

export function FinalSummary (props: FinalSummaryProps): JSX.Element {
  const { isLoaded, getLocationFromAddress, distanceBetweenPoints } = useBingMaps({ modules: [MapModule.SpatialMath] })
  const { isdId } = useParams()
  const auth = useAuth()
  const nav = useNavigate()
  const dispatch = useCaregiverWizardUpdateDispatch()

  const [addedServices, setAddedServices] = useState<ServiceView[]>([])
  const [typedName, setTypedName] = useState<string>('')
  const [isExistingData, setIsExistingData] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')
  const [intakeProvider, setIntakeProvider] = useState<lookup | undefined>()
  const isSubmitting = useRef(false)

  const caregiverForm = props.caregiverForm

  useScrollTop()

  useEffect(() => {
    if (auth.user != null && caregiverForm.isCaregiverOfChild) {
      const caregiver = caregiverForm.caregivers.find(c => c.email.toLowerCase() === auth.user?.email.toLowerCase())
      if (caregiver == null) {
        // The user's account does not match any existing caregivers, so the user needs to correct that.
        const tempForm = { ...caregiverForm }
        tempForm.currentStep = 5
        tempForm.currentSubStep = 1
        void saveSession(tempForm)
        dispatch({ type: 'form', form: tempForm })
        return
      }
    }

    if (caregiverForm.currentStep === 6 && caregiverForm.currentSubStep === 1 && window.Microsoft !== undefined && isLoaded) {
      void fetchData()
    }
  }, [isLoaded])

  const fetchData = async (): Promise<void> => {
    const { response: servicesResponse } = await sendGet(`/ISDServices/GetCurrentServices?ISDID=${isdId ?? 0}`, {})
    const { response: facilitiesResponse }: { response: PortalFacilityViewModel[] } = await sendGet(`/CaregiverPortal/GetAllFacilities/${isdId ?? 0}`, {})
    const { response: intakeProviders } = await sendGet(`/ISDDetails/GetIntakeProviders?id=${isdId ?? 0}`, {})

    if (intakeProviders != null && intakeProviders.length > 0) {
      setIntakeProvider(intakeProviders[0])
    }

    if (caregiverForm.isCaregiverOfChild) {
      const { response } = await sendGet('/CaregiverPortal/GetRelatedMembers', {})
      if (response != null && (response.children.length > 0)) {
        toast.error('There was existing information found that is linked to the email you used to login. Please continue to reconcile this data.', { autoClose: false })
        setIsExistingData(true)
      }
    }

    const today = getDayOfWeek()

    const filteredServices: service[] = servicesResponse.filter((s: service) => {
      return caregiverForm.children.some(c => c.requestedServices.some(rs => rs.id === s.id))
    })

    // For every service, get the children that requested it and the providers that they selected for it
    const services: ServiceView[] = []
    for (const s of filteredServices) {
      const filteredChildren = caregiverForm.children
        .map((c, index) => { return { index, child: c } })
        .filter(c => c.child.requestedServices.some(rs => rs.id === s.id))

      const children: ChildView[] = []
      for (const c of filteredChildren) {
        const child = c.child
        let childLocation = child.location
        if (child.livesWithFirstChild) {
          childLocation = caregiverForm.children[0].location
        }
        const childMapLocation = await convertChildToLocation(childLocation)

        const facilityIds = c.child.requestedServices.find(rs => rs.id === s.id)?.facilityIds
        if (facilityIds == null) {
          continue
        }

        const providers: ReorderItem[] = []
        for (let i = 0; i < facilityIds.length; i++) {
          const fid = facilityIds[i]
          const facility = facilitiesResponse.find(f => f.facilityID === fid)
          if (facility == null) {
            continue
          }
          const facilityData = await getFacilityData(facility, childMapLocation, today)

          const orderableItem: ReorderItem = {
            id: fid,
            index: i,
            firstLine: facilityData?.facilityName ?? '',
            secondLine: facilityData?.todayHours ?? '',
            thirdLine: facilityData?.distance ?? ''
          }

          providers.push(orderableItem)
        }

        children.push({
          index: c.index,
          childName: `${childName(c.child)} ${childAge(c.child)}`,
          providers: providers ?? []
        })
      }

      services.push({
        id: s.id,
        serviceName: s.name,
        children
      })
    }

    setAddedServices(services)
  }

  const getFacilityData = async (facility: PortalFacilityViewModel, childLocation: Microsoft.Maps.Location | null, today: string): Promise<FacilityData> => {
    const todayHours = facility.facilityHours.find(h => h.dayOfWeek === today)
    const facilityLocation = await convertFacilityToLocation(facility)
    let distance = 'Unknown distance'
    if (childLocation != null && facilityLocation != null) {
      const milesTo = distanceBetweenPoints(childLocation, facilityLocation)
      distance = milesTo.toFixed(1) + ' miles away'
    }

    return {
      facilityId: facility.facilityID,
      facilityName: facility.facilityName,
      todayHours: todayHours != null
        ? `${FormatTimeAs12Hour(todayHours.openTime)} - ${FormatTimeAs12Hour(todayHours.closeTime)}`
        : 'Closed',
      distance
    }
  }

  const convertChildToLocation = async (childLocation: PortalLocation): Promise<Microsoft.Maps.Location | null> => {
    return await getLocationFromAddress({
      address: childLocation?.address,
      city: childLocation?.city,
      state: childLocation?.state,
      zipCode: childLocation?.zipCode
    })
  }

  const convertFacilityToLocation = async (facility: PortalFacilityViewModel): Promise<Microsoft.Maps.Location | null> => {
    return await getLocationFromAddress({
      latitude: facility.latitude,
      longitude: facility.longitude,
      address: facility.address,
      city: facility.city,
      state: facility.state,
      zipCode: facility.zipCode
    })
  }

  const handleListReordered = async (facilities: ReorderItem[], childIndex: number, serviceId: number): Promise<void> => {
    const tempForm = { ...caregiverForm }
    const service = tempForm.children[childIndex].requestedServices.find(rs => rs.id === serviceId)
    if (service != null) {
      service.facilityIds = facilities.map(f => f.id)
      dispatch({ type: 'form', form: tempForm })
      await saveSession(tempForm)
    }

    const newServices = [...addedServices]
    const serviceIndex = newServices.findIndex(s => s.id === serviceId)
    newServices[serviceIndex].children[childIndex].providers = facilities
    setAddedServices(newServices)

    toast.success('Your preferred ranking has been updated.')
  }

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 4, currentSubStep: 3 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  const goToChildSummary = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 1, currentSubStep: 3 }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const goToServiceSummary = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 4, currentSubStep: 3 }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting.current) {
      return
    }

    if (typedName === '') {
      toast.error('Please type your name')
      return
    }

    const tempForm = { ...caregiverForm }
    if (note !== '') {
      tempForm.note = note
    }

    if (isExistingData) {
      tempForm.currentSubStep = 2
      await saveSession(tempForm)
      dispatch({ type: 'form', form: tempForm })
      return
    }

    isSubmitting.current = true

    if (note !== '') {
      await saveSession(tempForm)
      dispatch({ type: 'form', form: tempForm })
    }

    await submitForm(isdId ?? '')
    nav(generatePath(CAREGIVER_PORTAL_SUBMITTED, { isdId: isdId ?? '' }))
    window.location.reload() // Clears the session
  }

  if (auth.user == null && caregiverForm.isCaregiverOfChild) {
    return <Grid container spacing={2}>
      <Typography variant='h5' sx={{ fontWeight: 600, mb: '30px' }} component='div'>
        Please log in to continue.
      </Typography>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
  }

  const intakeProviderText = intakeProvider != null ? ` ${intakeProvider.name} and other` : ''

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ fontWeight: 600 }} component='div'>
        Last step, {auth.user?.firstName}! Please review the information and submit the inquiry form.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      {addedServices.map(service => {
        return <Box key={service.id}>
          <Typography variant='h6' component='div'>
            {service.serviceName}
          </Typography>
          {service.children.map(child => {
            return <Box key={child.childName}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Typography variant='body1' component='div'>
                  {child.childName}
                </Typography>
                <Link sx={{ cursor: 'pointer' }} onClick={goToChildSummary}>Edit</Link>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Typography variant='body1' component='div' sx={{ mt: '20px', fontWeight: 'bold' }}>
                  Preferred Providers
                </Typography>
                <Link sx={{ cursor: 'pointer' }} onClick={goToServiceSummary}>Edit</Link>
              </Box>

              {child.providers.length > 1 &&
                <>
                  <Typography variant='body1' component='div'>
                    <CustomLabelWithToolTip
                      name='IF_Final_Summary_Rank'
                      isdId={isdId ?? 0}
                      defaultLabelText='Due to high demand, we would like you to rank your selected service providers by preference. This will ensure that your interest form will be seen and evaluated by your most desired providers first.'
                    />
                  </Typography>
                  <Typography variant='body1' component='div' sx={{ mt: '20px' }}>
                    Drag and drop to reorder the list.
                  </Typography>
                </>
              }
              {child.providers.length > 0 &&
                <DragAndDropReorderList
                  sx={{ mb: '20px' }}
                  items={child.providers}
                  onListReordered={async (items) => { await handleListReordered(items, child.index, service.id) }}
                />
              }
              {child.providers.length === 0 &&
                <Typography variant='body1' component='div' sx={{ mb: '20px' }}>
                  I would like someone to reach out to me
                </Typography>
              }
            </Box>
          })}
        </Box>
      })}
    </Grid>

    <Grid item xs={12}>
      <TextFieldWithLabel
        label='Is there anything else you want us to know or other things we can help you with?'
        name='note'
        data-testid='note'
        value={note}
        onChange={(e) => { setNote(e.target.value) }}
        className='pt-0'
        textFieldClassName='pb-0'
      />
    </Grid>

    <Grid item xs={12}>
      <Typography variant='h6' component='div'>
        Authorization
      </Typography>
      <Typography variant='body1' component='div'>
        <CustomLabelWithToolTip
          name='IF_Authorization_Text'
          isdId={isdId ?? 0}
          defaultLabelText={`By submitting this application, I acknowledge that the information given within will be shared with ${intakeProviderText} agencies to support my family needs. By typing my name below, it serves as my electronic signature and permission.`}
        />
      </Typography>
      <TextField
        value={typedName}
        inputProps={{ 'aria-label': 'Name' }}
        onChange={(e) => { setTypedName(e.target.value) }}
        sx={{ mt: '20px', width: '100%' }}
        data-testid='typed-name'
      />
    </Grid>
    <Grid item xs={12} sx={{ mb: '15px', mt: '40px' }}>
      <Button
        name='submitButton'
        className='footer-button'
        data-testid='final-submit'
        onClick={handleSubmit}
        variant='contained'
      >
        {isExistingData ? 'Continue' : 'Submit Interest Form'}
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='subtitle1' component='div'>
        <CustomLabelWithToolTip
          name='IF_NonDiscriminationDisclaimer'
          isdId={isdId ?? 0}
          defaultLabelText={'We do not discriminate on the basis of race, color, religion, sex, national origin, age, height, weight, marital status, handicap, disability, or limited English proficiency in any of our programs or activities.'}
        />
      </Typography>
    </Grid>
  </Grid>
}
