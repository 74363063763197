import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { DayOfWeek, MonthOfYear } from '../../../core/types'
import { ChildHistoryDetails } from './ChildHistoryDetails'
import { Box } from '@mui/material'

export interface notificationEvent {
  id: number
  caregiverID: number | null
  childID: number
  childName: string
  eventDate: string
  eventType: string
  description: string
  intakeProviderID: number | null
  isdid: number | null
  message: string
  serviceProviderID: number | null
  userID: number
  userName: string
  viewed: boolean
  isNew: boolean
}

export interface notificationEventGroup {
  title: string
  date: string
  events: notificationEvent[]
}

export function ChildHistoryDisplay (props: { childId: number, accountId: number }): JSX.Element {
  const [history, setHistory] = useState<notificationEventGroup[]>([])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: details } = await sendGet(`/ChildDetails/ChildHistory?childId=${props.childId}&isdId=${props.accountId}`, {})
      if (details !== undefined) {
        const groups: notificationEventGroup[] = []
        const notificationEvents = details as notificationEvent[]
        const sortFunction = (a: notificationEvent, b: notificationEvent): number => {
          if (a.eventDate < b.eventDate) {
            return 1
          }
          if (a.eventDate > b.eventDate) {
            return -1
          }
          return 0
        }
        const sortedFunctions = notificationEvents.sort(sortFunction)
        sortedFunctions.forEach(event => {
          const theDate = new Date(event.eventDate)
          const dayOfWeek = DayOfWeek[theDate.getDay()].toString()
          const month = MonthOfYear[theDate.getMonth()].toString()
          const eventDate: string = `${dayOfWeek}, ${month} ${theDate.getDate()}, ${theDate.getFullYear()}`
          const group = groups.find(g => g.date === eventDate)
          if (group === undefined) {
            groups.push({
              title: event.eventType === 'ChildReceived' ? eventDate + ' - Child Created' : eventDate,
              date: eventDate,
              events: [event]
            })
          } else {
            group.events.push(event)
            if (event.eventType === 'ChildReceived') {
              group.title = eventDate + ' - Child Created'
            }
          }
        })
        setHistory(groups)
      }
    }
    void loadData()
  }, [props.childId, props.accountId])

  return <Box sx={{ marginTop: '20px' }}>
    <>{history.map(group => {
      return <ChildHistoryDetails key={group.date + 'details'} title={group.title} date={group.date} events ={group.events} />
    })}</>
  </Box>
}
