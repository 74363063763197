/* eslint-disable no-eval */
import { useEffect } from 'react'

export function useLocalize (): void {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://global.localizecdn.com/localize.js'
    script.onload = () => {
      eval('!function (a) { if (!a.Localize) { a.Localize = {}; for (var e = ["translate", "untranslate", "phrase", "initialize", "translatePage", "setLanguage", "getLanguage", "getSourceLanguage", "detectLanguage", "getAvailableLanguages", "untranslatePage", "bootstrap", "prefetch", "on", "off", "hideWidget", "showWidget"], t = 0; t < e.length; t++)a.Localize[e[t]] = function () { } } }(window); ')
      eval('Localize.initialize({ key: "eJMpsjBQpL3t2",  rememberLanguage: true,  });')
    }
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  })
}
