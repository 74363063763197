import React, { useContext } from 'react'
import { type User } from '../core/types'
import { useNavigate } from 'react-router'
import { sendGet, sendPost } from './use-fetch'
import { AuthContext } from '../ContextProviders/AuthProvider'

export interface AuthType {
  loading: boolean
  user: User | null
  login: () => void
  logout: () => void
  createAccount: () => void
  setCurrentUser: () => Promise<void>
  impersonate: (userId: number) => Promise<void>
  impersonateProvider: (isdServiceProviderId: number) => Promise<void>
  endImpersonation: () => Promise<void>
  isImpersonating: () => boolean
}

export function useAuth (): AuthType {
  const serverUrl = process.env.REACT_APP_SERVER_URL ?? ''
  const auth = useContext(AuthContext)
  const nav = useNavigate()

  const login = (): void => {
    const clientUrl = process.env.REACT_APP_CLIENT_URL ?? ''

    const currentUrl = window.location.href
    if (currentUrl.includes('CaregiverPortal')) {
      // Return the user to the caregiver portal after login
      localStorage.setItem('currentUrl', currentUrl)
      window.location.href = `${serverUrl}/Account/PortalLogin?currentUrl=${currentUrl}`
    } else {
      window.location.href = `${serverUrl}/Account/Login?returnUrl=${clientUrl}`
    }
  }

  const logout = (): void => {
    const currentUrl = window.location.href
    if (currentUrl.includes('CaregiverPortal')) {
      // Return the user to the caregiver portal after logout
      localStorage.setItem('currentUrl', currentUrl)
    }

    window.location.href = `${serverUrl}/Account/Logout`
  }

  const createAccount = (): void => {
    const currentUrl = window.location.href
    localStorage.setItem('currentUrl', currentUrl) // URL to return to after account creation

    window.location.href = `${serverUrl}/Account/CreateAccount?currentUrl=${currentUrl}`
  }

  const impersonate = async (userId: number): Promise<void> => {
    const url = '/Account/ImpersonateUser'
    await sendPost(url, { userID: userId })
    nav('/')
    window.location.reload()
  }

  const impersonateProvider = async (isdServiceProviderId: number): Promise<void> => {
    const url = '/Account/ImpersonateProvider'
    await sendPost(url, { isdServiceProviderId })
    nav('/')
    window.location.reload()
  }

  const endImpersonation = React.useCallback(async () => {
    await sendGet('/Account/EndImpersonation', {})
    nav('/')
    window.location.reload()
  }, [])

  const isImpersonating = (): boolean => {
    return auth?.user?.isImpersonating ?? false
  }

  return {
    loading: auth.loading,
    user: auth.user,
    login,
    logout,
    createAccount,
    setCurrentUser: auth.setCurrentUser,
    impersonate,
    impersonateProvider,
    endImpersonation,
    isImpersonating
  }
}
