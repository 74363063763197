import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import profileImg from '../../../Assets/Icons/046-teacher.png'
import { Avatar, Button } from '@mui/material'
import { useAuth } from '../../../hooks/use-auth'
import { Dropdown, type DropdownOption } from '../../../Components/Dropdown'
import { useNavigate } from 'react-router'
import * as AppRoutes from '../../Routes'

export default function UserDropdown (): JSX.Element {
  const { user, loading, login, logout, endImpersonation } = useAuth()
  const nav = useNavigate()

  const changePassword = (): void => {
    nav(AppRoutes.CHANGE_PASSWORD)
  }

  const manageProfile = (): void => {
    nav(AppRoutes.MY_PROFILE)
  }

  const userIcon = (user?.imageKey ?? '') !== ''
    ? `${process.env.REACT_APP_SERVER_URL ?? ''}/File/${user?.imageKey ?? ''}`
    : profileImg

  if (user != null) {
    const userButton = (
      <div style={{ display: 'flex' }}>
        <Avatar alt="profile logo" src={userIcon} sx={{ width: 24, height: 24, marginRight: '3px', cursor: 'pointer' }} />
        <span style={{ cursor: 'pointer' }} data-testid='user-dropdown-name'>Hi, {user.firstName}</span>
        <ArrowDropDownIcon />
      </div>
    )

    const dropdownOptions: DropdownOption[] = []
    if (!user.isFakeAdmin && !user.inactive) {
      dropdownOptions.push({ name: 'Manage Profile', onClick: manageProfile })
    }
    if (!user.externalLogin && !user.noAccount && !user.inactive) {
      dropdownOptions.push({ name: 'Change Password', onClick: changePassword })
    }
    if (user.isImpersonating) {
      dropdownOptions.push({ name: 'Stop Impersonating', onClickAsync: endImpersonation })
    }
    dropdownOptions.push({ name: 'Logout', onClick: logout })

    if (loading) return <></>

    return (
      <>
        <Dropdown
          selectComponent={userButton}
          options={dropdownOptions}
        />
      </>
    )
  } else {
    return (
      <Button
        sx={{ textTransform: 'none' }}
        onClick={login}
        variant="contained"
        autoCapitalize="false"
        data-testid='login-button'
      >
        Login
      </Button>
    )
  }
}
