import { AddCaregiverDetails } from './AddCaregiverDetails'
import { type EnrollmentDetails } from '../../../../core/types'
import { CaregiverSummary } from './CaregiverSummary'

export interface CaregiverDetailsStepProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function CaregiverDetailsStep (props: CaregiverDetailsStepProps): JSX.Element {
  const caregiverForm = props.caregiverForm

  if (caregiverForm.currentSubStep === 1) {
    return <AddCaregiverDetails caregiverForm={caregiverForm} handleStep={props.handleStep} />
  } else if (caregiverForm.currentSubStep === 2) {
    return <CaregiverSummary caregiverForm={caregiverForm} handleStep={props.handleStep} />
  }

  return <></>
}
