import { Box } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TextFieldWithLabel } from '../../Components/TextField'
import { type service } from '../../core/types'
import { useEffect, useState } from 'react'
import { CheckBoxWithLabel } from '../../Components/CheckBox'
import FilePicker from '../../Components/FilePicker'
import { FileNameFromS3Key } from '../../core/Utilities'
import { TextWithLabel } from '../../Components/TextWithLabel'

export interface ServiceDetailsProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: service, file: File | null) => void
  service: service
  errors?: string[] | undefined
}

export function ServiceDetails (props: ServiceDetailsProps): JSX.Element {
  const [service, setService] = useState(props.service)
  const [file, setFile] = useState<File>()
  const [fileName, setFileName] = useState<string>('')
  const title = 'Service Details'
  const handleClose = (): void => {
    props.onClose()
  }
  const handleChange = (e: any): void => {
    setService({ ...service, [e.target.name]: e.target.value })
  }
  const handleSubmit = (): void => {
    if (file !== undefined) {
      props.onSubmit(service, file)
    } else {
      props.onSubmit(service, null)
    }
  }

  useEffect(() => {
    setFileName(FileNameFromS3Key(service.s3Key))
  }, [props.open])

  const content = <Box>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    <Box sx={{ boxSizing: 'border-box', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
      <Box>
        <TextFieldWithLabel
          data-test-service-name
          label='Name'
          name='name'
          onChange={handleChange}
          value={service.name}
          disabled={props.service.id !== 0}

        />
      </Box>
      <Box>
        <CheckBoxWithLabel
          edge='start'
          label='Inactive'
          name='inactive'
          setValue={(newValue) => { setService({ ...service, inactive: newValue }) }}
          value={service.inactive}
        />
      </Box>
      <Box>
        <FilePicker
          label='Choose an image'
          name='file'
          onSelect={(e: any) => {
            setFile(e.target.files[0])
            setFileName(e.target.files[0].name)
          }}
        />
      </Box>
      <Box>
        <TextWithLabel
          label='File Name'
          name='fileName'
          value={fileName}
        />
      </Box>
    </Box>
  </Box>
  return <Modal
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
