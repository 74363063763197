/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Switch, styled } from '@mui/material'

export const OnOffSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 80,
  height: 48,
  '& .MuiSwitch-switchBase': {
    padding: 11
  },
  '& .MuiSwitch-thumb': {
    width: 26,
    height: 26,
    backgroundColor: '#595959'

  },
  '& .MuiSwitch-track': {
    background: '#898989',
    opacity: '1',
    borderRadius: 20,
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: '#fff',
      textAlign: 'center'
    },
    '&:before': {
      content: '"On"',
      opacity: 0,
      left: 4
    },
    '&:after': {
      content: '"Off"',
      opacity: 1,
      right: 4
    }
  },
  '.Mui-disabled>.MuiSwitch-thumb': {
    backgroundColor: 'rgb(221 221 221)'
  },
  '& .Mui-checked': {
    padding: 11,
    transform: 'translateX(32px) !important',
    '&:hover': {
      backgroundColor: 'rgba(24,90,257,0.08)'
    },

    '& + .MuiSwitch-track': {
      background: 'rgba(113, 54, 188, 1) !important',
      opacity: '1 !important',
      '&:before': {
        opacity: 1
      },
      '&:after': {
        opacity: 0
      }
    }
  }

}))
