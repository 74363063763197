import { Box, Button, Grid, Typography } from '@mui/material'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { type service, type EnrollmentDetails, type PortalChildDetails } from '../../../../core/types'
import { useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../../../hooks/use-fetch'
import { useParams } from 'react-router'
import { useAuth } from '../../../../hooks/use-auth'
import { childAge, childName } from '../../PortalHelpers'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

export interface ServiceSummaryProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

interface ChildSummaryState {
  index: number
  name: string
}

interface ServiceSummaryState {
  id: number
  name: string
  children: ChildSummaryState[]
}

export function ServiceSummary (props: ServiceSummaryProps): JSX.Element {
  const dispatch = useCaregiverWizardUpdateDispatch()
  const auth = useAuth()
  const { isdId } = useParams()
  const [childServices, setChildServices] = useState<ServiceSummaryState[]>([])
  const [otherServices, setOtherServices] = useState<boolean>(false)
  const [selectedServiceId, setSelectedServiceId] = useState<number>(0)

  useScrollTop()

  const fetchData = async (): Promise<void> => {
    const { response: services } = await sendGet(`/ISDServices/GetCurrentServices?ISDID=${isdId ?? 0}`, {})

    const childServices: ServiceSummaryState[] = services
      .filter((service: service) => props.caregiverForm.children.some(c => c.requestedServices.some(rs => rs.id === service.id)))
      .map((service: service) => {
        const children = props.caregiverForm.children
          .filter(c => c.requestedServices.some(s => s.id === service.id))
          .map((child: PortalChildDetails, childIndex: number) => {
            return {
              index: childIndex,
              name: `${childName(child)} ${childAge(child)}`
            }
          })
        return {
          id: service.id,
          name: service.name,
          children
        }
      })

    setChildServices(childServices)
  }

  const resetUpdateFlag = async (): Promise<void> => {
    const tempForm = { ...props.caregiverForm }
    const existingChild = tempForm.children.find(c => c.isCurrentlyUpdating)
    if (existingChild != null) {
      existingChild.isCurrentlyUpdating = false
      const service = existingChild.requestedServices.find(s => s.isCurrentlyUpdating)
      if (service != null) {
        service.isCurrentlyUpdating = false
      }
    }
    const initialForm = initialEnrollmentDetails()
    tempForm.currentChild = initialForm.currentChild

    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  useEffect(() => {
    if (props.caregiverForm.currentStep === 4) {
      void resetUpdateFlag()
      void fetchData()
      // If we are already signed in and have clicked continue before, verify the account.
      // This is to handle returning to the page after creating an account.
      if (props.caregiverForm.hasStartedCreatingAccount && auth.user != null) {
        void handleAccountCreation()
      }
    }
  }, [auth.user, props.caregiverForm.hasStartedCreatingAccount])

  const caregiverForm = props.caregiverForm

  const handleAccountCreation = async (): Promise<void> => {
    if (auth.user == null) {
      return
    }
    const tempForm = { ...caregiverForm, hasStartedCreatingAccount: false }

    const email = auth.user.email
    const caregiver = caregiverForm.caregivers.find(c => c.email.toLowerCase() === email.toLowerCase())
    if (caregiver == null) {
      // The new account didn't match any existing caregivers, so the user needs to correct that.
      tempForm.currentStep = 5
      tempForm.currentSubStep = 1
      await saveSession(tempForm)
      dispatch({ type: 'form', form: tempForm })
      return
    }

    const user = {
      firstName: caregiver.firstName,
      lastName: caregiver.lastName,
      email: caregiver.email,
      externalLogin: auth.user.externalLogin
    }
    // Update the user in SSO with the first and last name, then create the MIECC user
    await sendPost('/Account/UpdateAccount', user)
    await sendPost('/CaregiverPortal/CreateUser', user)
    await goToStep6(tempForm)

    // Refresh the page so we get the updated logged in user
    window.location.reload()
  }

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 2, currentSubStep: 2 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    if (otherServices) {
      const tempForm = { ...caregiverForm, currentStep: 3, currentSubStep: 1 }
      await saveSession(tempForm)
      props.handleStep(true)
      dispatch({ type: 'form', form: tempForm })
    } else if (!caregiverForm.isCaregiverOfChild) {
      // Skip the account creation step if we are creating a referral
      const tempForm = { ...caregiverForm }
      await goToStep6(tempForm)
    } else if (auth.user != null) {
      // Verify the account if we are already signed in
      await handleAccountCreation()
    } else {
      const tempForm = { ...caregiverForm, hasStartedCreatingAccount: true }
      await saveSession(tempForm)
      // Step 5 is in the SSO app
      auth.createAccount()
    }
  }

  const goToStep6 = async (tempForm: EnrollmentDetails): Promise<void> => {
    tempForm.currentStep = 6
    tempForm.currentSubStep = 1
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleChildClick = async (child: ChildSummaryState, serviceId: number): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 3, currentSubStep: 3 }
    const service = tempForm.children[child.index].requestedServices.find(s => s.id === serviceId)
    if (service == null) {
      return
    }
    tempForm.children[child.index].isCurrentlyUpdating = true
    service.isCurrentlyUpdating = true
    tempForm.currentChild = { ...tempForm.children[child.index] }

    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ fontWeight: 600 }} component='div'>
        Inquiries Added
      </Typography>
    </Grid>
    <Grid item xs={12}>
      {childServices.map(service => {
        return <Box key={service.id}>
          <Typography
            variant='h6'
            component='div'
            className='expandable-row'
            data-testid='service-row'
            onClick={() => { setSelectedServiceId(service.id) }}
          >
            {service.name}
          </Typography>
          {selectedServiceId === service.id && <Box sx={{ pl: '20px' }}>
            {service.children.map(child => {
              return <Typography
                key={child.index}
                variant='body1'
                component='div'
                className='expandable-row'
                data-testid='child-row'
                onClick={async () => { await handleChildClick(child, service.id) }}
              >
                {child.name}
              </Typography>
            })}
          </Box>}
        </Box>
      })}
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h6' sx={{ mb: '10px', mt: '30px' }} component='div'>
        <CustomLabelWithToolTip
          name='IF_Service_Browse_Additional'
          isdId={isdId ?? 0}
          defaultLabelText='Are there additional services that you need at this time?'
        />
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Button variant="outlined" className={'wide-button' + (otherServices ? ' selected' : '')} data-testid='other-services-yes' onClick={() => { setOtherServices(true) }}>Yes</Button>
    </Grid>
    <Grid item xs={6}>
      <Button variant="outlined" className={'wide-button' + (otherServices ? '' : ' selected')} data-testid='other-services-no' onClick={() => { setOtherServices(false) }}>No</Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='continueButton'
        className='footer-button'
        data-testid='continue-button'
        onClick={handleContinue}
        variant='contained'
      >
        Continue
      </Button>
    </Grid>
  </Grid>
}
