import { useState, useEffect } from 'react'
import { Box, IconButton, Typography, CircularProgress, Grid, Button } from '@mui/material'
import { sendGet, sendPost, sendPostFormData } from '../../../hooks/use-fetch'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { LargeCard } from '../../../Components/Cards/Large'
import { LabelWithToolTip } from '../../../Components/Label/Index'
import { FormatBoolean, FormatDateValue } from '../../../core/Utilities'
import EditIcon from '@mui/icons-material/Edit'
import { ChildEdit } from './ChildEdit'
import { ErrorProvider } from '../../../ContextProviders/ErrorProvider'
import S3Image from '../../../Components/S3Image'
import { LocationCardList } from '../../../Components/Cards/MultiCardList/LocationCardList'
import { type ChildEditValues, type postError, type ChildSiblings, ChildAuditAccessTypeEnum, AccountType } from '../../../core/types'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { toast } from 'react-toastify'
import { NotAllowed } from '../../../Routes/ProtectedRoute'
import { ChildMerge } from './ChildMerge'
import { useNavigate } from 'react-router'
import { usePermissions } from '../../../hooks/use-permissions'

export interface ChildDetailsProps {
  childID: number
  onUpdate?: () => void
}

export function ChildDetails (props: ChildDetailsProps): JSX.Element {
  const account = useAccountId()
  const [loaded, setLoaded] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [child, setChild] = useState<ChildEditValues>({} as ChildEditValues)
  const [isUnborn, setIsUnborn] = useState(false)
  const [open, setOpen] = useState(false)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [unauthorized, setUnauthorized] = useState(false)
  const [showConfirmMerge, setShowConfirmMerge] = useState(false)
  const permissions = usePermissions()
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      let url = `/ChildDetails/EditableRecord?childId=${props.childID}`
      if (account?.type === AccountType.ISD) {
        url += `&isdId=${account.id}`
      }
      const { response, success, status } = await sendGet(url, {})
      if (success) {
        const details = response.response
        const child = { ...details, image: details.imageKey ?? '' }
        setChild(child)
        setIsUnborn(child.isUnborn)
      } else {
        if (status === 401) {
          setUnauthorized(true)
        }
        const errors: string[] = response.errors
        errors.forEach(e => toast.error(e))
      }
      setLoaded(true)
    }
    void fetchData()
  }, [refreshTime])

  const confirmRemove = async (): Promise<void> => {
    setShowConfirmMerge(true)
  }

  const childHasBeenBorn = async (): Promise<void> => {
    setIsUnborn(false)
    child.dateOfBirth = new Date()
    setOpen(true)
  }

  if (!loaded) return <CircularProgress role='loading' />
  if (unauthorized) return <NotAllowed />

  const basicContent = <>
    <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columnSpacing={1} rowSpacing={1}>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='First Name' name='firstName' value={child?.firstName ?? ''} className='mr-5' />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='Last Name' data-testid='lastName' name='lastName' value={child?.lastName ?? ''} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='Middle Name' name='middleName' value={child?.middleName ?? ''} className='mr-5' />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='Suffix' name='suffix' value={child?.suffix ?? ''} />
      </Grid>
      {child?.isUnborn &&
        <>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='Due Date' name='dueDate' value={FormatDateValue(child?.dueDate)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='Resident District' name='residentDistrict' value={child?.residentDistrict} />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextWithLabel label='Authorization Status' name='authorizationStatus' value={child?.authorizationStatus} />
          </Grid>
        </>
      }
      {!child?.isUnborn &&
        <>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='Date of Birth' name='dateOfBirth' value={FormatDateValue(child?.dateOfBirth)} />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='Assigned Gender at Birth' name='birthGender' value={child?.birthGender} />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='Primary Language (if not English)' name='primaryLanguage' value={child?.language} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
              <LabelWithToolTip
                labelText={'Image'}
                sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600' }}
              />
              {child?.image !== null && child?.image !== '' && child?.image !== undefined &&
                (
                  <S3Image imageKey={child?.image ?? ''} />
                )}
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='Resident District' name='residentDistrict' value={child?.residentDistrict} />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextWithLabel label='UIC' name='uic' value={child?.uic === null || child.uic === '' ? '(none)' : child.uic} />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextWithLabel label='Authorization Status' name='authorizationStatus' value={child?.authorizationStatus} />
          </Grid>
          {child?.captureIEP && <Grid item sm={12} md={6}>
            <TextWithLabel label='Has an IEP' name='hasIEP' value={FormatBoolean(child?.hasIEP)} />
          </Grid>}
          {child?.captureIFSP && <Grid item sm={12} md={6}>
            <TextWithLabel label='Has an IFSP' name='hasIFSP' value={FormatBoolean(child?.hasIFSP)} />
          </Grid>}
          {child?.captureEOIS && <Grid item sm={12} md={6}>
            <TextWithLabel label='Has as EOIS' name='hasEOIS' value={FormatBoolean(child?.hasEOIS)} />
          </Grid>}
        </>
      }
    </Grid>

    {(child?.canEditEligibilityFactors) &&
      <>
        <Typography component="div" variant="h5" className='d-flex f-align-items-center mt-10' data-testid='factors'>Eligibility Factors</Typography>
        {child?.eligibilityFactorList.length !== 0 || child?.otherEligibilityFactor !== null
          ? <>
            <Box>
              <ul className='mt-5' data-testid="eligibilityFactors">
                {child?.eligibilityFactorList.map(r => {
                  return <li key={r.name + 'eligibility'}>{r.name}</li>
                })}
                {child?.otherEligibilityFactor !== null && <li>{child.otherEligibilityFactor}</li>}
              </ul>
            </Box>
            {child?.eligibilityFactorList.some(f => f.stateCode === '07') &&
              <>
                <Typography component="div" variant="h5" className='d-flex f-align-items-center'>Environmental Risks</Typography>
                <Box>
                  <ul className='mt-5' data-testid="environmentalRisks">
                    {child?.environmentalRiskList.map(r => {
                      return <li key={r.name + 'environmental'}>{r.name}</li>
                    })}
                  </ul>
                </Box>
              </>
            }
          </>
          : <div className='none-display'>(none)</div>}
      </>
    }
    <Typography component="div" variant="h5" className='d-flex f-align-items-center'>Race/Ethnicity</Typography>
    {(child?.childRaceList?.length ?? 0) !== 0
      ? <Box>
        <ul className='mt-5' data-testid='races'>
          {child?.childRaceList?.map(r => {
            return <li key={r.name + 'race'}>{r.name}</li>
          })}
        </ul>
      </Box>
      : <div className='none-display'>(not entered)</div>}
    <Typography component="div" variant="h5" className='d-flex f-align-items-center'>Additional Information</Typography>
    <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columnSpacing={1} rowSpacing={1}>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='SNAP Benefits' name='hasSNAPBenefits' value={FormatBoolean(child?.hasSNAPBenefits)} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='Foster Child' name='isFosterChild' value={FormatBoolean(child?.isFosterChild)} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='Cash Assistance' name='isOnCashAssistance' value={FormatBoolean(child?.isOnCashAssistance)} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextWithLabel label='SSI (Supplemental Security Income)' name='isOnSSI' value={FormatBoolean(child?.isOnSSI)} />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      {permissions.CareNetwork_MergeRecords &&
        <Grid item>
          <Button data-test-add-service color='secondary' variant='contained' onClick={confirmRemove}>Merge Record</Button>
        </Grid>
      }
      {child?.isUnborn &&
        <Grid item>
          <Button color='secondary' variant='contained' onClick={childHasBeenBorn } >Child has been Born</Button>
        </Grid>
      }
    </Grid>
  </>

  const addrContent = <LocationCardList
    entityId={child?.id ?? 0}
    primaryLocationId={child?.primaryLocationID ?? 0}
    label=''
    requestingModule={ChildAuditAccessTypeEnum.CareNetwork}
    onDataChange={() => { setRefreshTime(new Date()) }}
  />

  function openChildEdit (): void {
    setOpen(true)
  }

  function handleSubmit (childData: ChildEditValues, childImage: File | null): void {
    const updateUser = async (): Promise<void> => {
      if (!isRequestLoading) {
        setIsRequestLoading(true)

        const { response } = await sendPost('/ChildDetails/Update', childData)
        if (response.success === false) {
          response.errors.forEach((e: postError) => toast.error(e.error))
        } else {
          const fileData = new FormData()
          fileData.append('id', childData.id.toString())
          if (childImage !== null) {
            if (childImage !== null) { fileData.append('file', childImage) }
            await sendPostFormData('/ChildDetails/UploadImage', fileData)
          } else {
            await sendPostFormData('/ChildDetails/RemoveImage', fileData)
          }

          setOpen(false)
          setRefreshTime(new Date())

          if (props.onUpdate !== undefined) {
            props.onUpdate()
          }
        }
        setIsRequestLoading(false)
      }
    }
    void updateUser()
  }

  return <>
    <Box sx={{ width: '100%;' }}>
      <ErrorProvider>
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columnSpacing={1} rowSpacing={1}>
          <Grid item sm={12} md={6} sx={{ m: 0 }}>
            <LargeCard header='Basic Information'
              content={basicContent}
              className='mt-10' fullWidth={true}
              icon={
                <IconButton sx={{ marginLeft: 'auto' }} onClick={openChildEdit}>
                  <EditIcon />
                </IconButton>
              }
            />
          </Grid>
          <Grid item sm={12} md={6} sx={{ m: 0 }}>
            <LargeCard header='Addresses' content={addrContent} className='mt-10' fullWidth={true} />
          </Grid>
        </Grid>
      </ErrorProvider>
    </Box>

    {open && (
      <ChildEdit
        isSibling={false}
        isUnborn={isUnborn}
        child={child as ChildSiblings}
        onClose={() => { setOpen(false) }}
        onSubmit={handleSubmit}
        open={open}
      />
    )
    }

    {showConfirmMerge &&
      <ChildMerge
        open={showConfirmMerge}
        child={child}
        onClose={(deleted: boolean) => {
          if (deleted) {
            nav('/CareNetworkManagement')
          } else {
            setShowConfirmMerge(false)
          }
        }}
      />
    }
  </>
}
