import { type EnrollmentDetails } from '../../../../core/types'
import { ServiceProviderMap } from './ServiceProviderMap'
import { ServiceProviderSearch } from './ServiceProviderSearch'
import { ServiceSummary } from './ServiceSummary'

export interface ServiceProviderStepProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function ServiceProviderStep (props: ServiceProviderStepProps): JSX.Element {
  const caregiverForm = props.caregiverForm

  if (caregiverForm.currentSubStep === 1) {
    return <ServiceProviderSearch caregiverForm={caregiverForm} handleStep={props.handleStep} />
  } else if (caregiverForm.currentSubStep === 2) {
    return <ServiceProviderMap caregiverForm={caregiverForm} handleStep={props.handleStep} />
  } else if (caregiverForm.currentSubStep === 3) {
    return <ServiceSummary caregiverForm={caregiverForm} handleStep={props.handleStep} />
  }

  return <></>
}
