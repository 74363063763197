import { Box, type InputProps, TextField } from '@mui/material'
import { LabelWithToolTip } from '../Label/Index'
import { ErrorDisplay } from '../../ContextProviders/ErrorProvider'

interface Props {
  label?: string
  labelContent?: JSX.Element
  name: string
  onChange: (e: any) => void
  onBlur?: (e: any) => void
  value: unknown
  required?: boolean
  showRequiredText?: boolean
  disabled?: boolean
  maxWidth?: string
  inline?: boolean
  type?: string
  toolTip?: string
  className?: string
  textFieldClassName?: string
  maxLength?: number
  pattern?: string
  title?: string
  min?: number
  max?: number
  inputRef?: any
  autoComplete?: 'on' | 'off'
  error?: boolean
  disableError?: boolean
  ariaRole?: string
  ariaExpanded?: boolean
  ariaControls?: string
  dataTestId?: string
}

export const TextFieldWithLabel = (props: Props): JSX.Element => {
  const isDisabled = props.disabled ?? false
  const isInline = props.inline ?? false
  const variant = isDisabled ? 'filled' : 'outlined'
  const showErrors = !(props.disableError ?? false)
  const defaultBlur = (): void => {}
  const onBlur = props.onBlur ?? defaultBlur
  const {
    name,
    onChange,
    value,
    required,
    showRequiredText,
    disabled,
    maxWidth,
    inline,
    inputRef,
    toolTip,
    textFieldClassName,
    disableError,
    label,
    labelContent,
    ariaRole,
    ariaExpanded,
    ariaControls,
    dataTestId,
    ...rest
  } = props

  const ip: InputProps = {
    ...rest,
    name,
    onChange,
    value,
    required,
    disabled
  }
  if (isDisabled) {
    ip.readOnly = true
    ip.disableUnderline = true
  }

  return (
    <Box
      sx={{
        pt: '8px',
        flexDirection: isInline ? 'row' : 'column',
        display: 'flex',
        maxWidth
      }}
      className={props.className}
    >
      {showErrors &&
      (<ErrorDisplay fieldName={props.name} />)}

      <LabelWithToolTip
        isInline={isInline}
        required={required}
        maxWidth={maxWidth}
        name={name}
        labelText={label}
        labelContent={labelContent}
        toolTipText={toolTip}
        showRequiredText={showRequiredText}
      />
      <TextField
        autoComplete={props.autoComplete}
        id={props.name}
        name={props.name}
        value={props.value}
        variant={variant}
        type={props.type}
        required={props.required}
        onChange={props.onChange}
        minRows={2}
        onBlur={onBlur}
        sx={{
          maxWidth: props.maxWidth,
          border: 'none',
          boxShadow: 'none',
          pt: 0,
          pb: 2
        }}
        InputProps={ip}
        inputProps={{
          maxLength: props.maxLength,
          pattern: props.pattern,
          title: props.title,
          min: props.min,
          max: props.max,
          shrink: 'false',
          role: ariaRole,
          'aria-expanded': ariaExpanded,
          'aria-controls': ariaControls
        }}
        data-testid={dataTestId ?? 'input-field'}
        inputRef={inputRef}
        error={props.error}
        className={textFieldClassName}
      />
    </Box>
  )
}
