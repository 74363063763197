import { Card, CardContent, Typography } from '@mui/material'
import S3Image from '../../S3Image'
import { type serviceProvider } from '../../../core/types'
import { LocationDisplay } from '../LocationCard/LocationDisplay'

export function ServiceProviderCardDisplay (props: { serviceProvider: serviceProvider }): JSX.Element {
  return <Card>
    <CardContent>
      {props.serviceProvider.s3Key !== null && (
        <S3Image imageKey={props.serviceProvider.s3Key ?? 'none'} alt='Service Provider Image' />
      )}
      <Typography>{props.serviceProvider.name}</Typography>
      {props.serviceProvider.location !== null && props.serviceProvider.location !== undefined && (
        <LocationDisplay location={props.serviceProvider.location} />
      )}
    </CardContent>

  </Card>
}
