import { Button, Grid, Typography } from '@mui/material'
import { DesiredSchedule, ReferralTimeFrame, type EnrollmentDetails } from '../../../../core/types'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { DatePickerWithLabel } from '../../../../Components/DatePicker'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useParams } from 'react-router'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface ServiceDateTypeSelectionProps {
  caregiverForm: EnrollmentDetails
}

export function ServiceDateTypeSelection (props: ServiceDateTypeSelectionProps): JSX.Element {
  const { isdId } = useParams()
  const dispatch = useCaregiverWizardUpdateDispatch()
  const [selectedTimeOption, setSelectedTimeOption] = useState<ReferralTimeFrame | null>(null)
  const [desiredSchedule, setDesiredSchedule] = useState<DesiredSchedule | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  useScrollTop()

  const caregiverForm = props.caregiverForm

  const handleBack = async (): Promise<void> => {
    const initialForm = initialEnrollmentDetails()
    const tempForm = { ...caregiverForm, currentSubStep: 1, currentChild: initialForm.currentChild }
    const existingChild = tempForm.children.find(c => c.isCurrentlyUpdating)
    if (existingChild != null) {
      existingChild.isCurrentlyUpdating = false
      const service = existingChild.requestedServices.find(s => s.isCurrentlyUpdating)
      if (service != null) {
        service.isCurrentlyUpdating = false
      }
    }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    if (selectedTimeOption == null || (selectedTimeOption === ReferralTimeFrame.SpecificDate && selectedDate == null)) {
      toast.error('Please select a service starting time to continue')
      return
    }
    if (desiredSchedule == null) {
      toast.error('Please select a desired schedule to continue')
      return
    }

    const tempForm = { ...caregiverForm, currentSubStep: 3 }
    const currentChild = tempForm.currentChild
    const service = currentChild.requestedServices.find(s => s.isCurrentlyUpdating)
    if (service != null) {
      if (selectedTimeOption === ReferralTimeFrame.SpecificDate) {
        service.startDate = selectedDate
      }
      service.desiredSchedule = desiredSchedule
      service.timeFrame = selectedTimeOption
    }

    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ mb: '30px' }} component='div' data-testid="service-date-selection-prompt">
        <CustomLabelWithToolTip
          name='IF_Service_Date_Intro'
          isdId={isdId ?? 0}
          defaultLabelText='When are you looking to start this service?'
        />
      </Typography>
      <Typography variant='subtitle1' sx={{ mb: '30px' }} component='div'>
        <CustomLabelWithToolTip
          name='IF_Service_Date_Subtext'
          isdId={isdId ?? 0}
          defaultLabelText='If your children need multiple or different services, you will have the ability to add that later.'
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (selectedTimeOption === ReferralTimeFrame.Immediate ? ' selected' : '')}
        data-testid='time-option-now'
        onClick={() => { setSelectedTimeOption(ReferralTimeFrame.Immediate) }}
      >
        Now
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (selectedTimeOption === ReferralTimeFrame.UpcomingSchoolYear ? ' selected' : '')}
        data-testid='time-option-next-school-year'
        onClick={() => { setSelectedTimeOption(ReferralTimeFrame.UpcomingSchoolYear) }}
      >
        Start of Next School Year
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (selectedTimeOption === ReferralTimeFrame.SpecificDate ? ' selected' : '')}
        data-testid='time-option-specific-date'
        onClick={() => { setSelectedTimeOption(ReferralTimeFrame.SpecificDate) }}
      >
        Specific Date
      </Button>
    </Grid>

    {selectedTimeOption === ReferralTimeFrame.SpecificDate &&
      <Grid item xs={12}>
        <DatePickerWithLabel
          label='Specific Date'
          name='selectedDate'
          dataTestId='serviceDatePicker'
          value={selectedDate}
          onChange={(val) => { setSelectedDate(val) } }
          FormControlProps={{ className: 'pb-0 width-100' }}
        />
      </Grid>
    }

    <Grid item xs={12} sx={{ mt: '30px' }}>
      <Typography variant='h5' component='div'>
        <CustomLabelWithToolTip
          name='IF_Service_Time_Intro'
          isdId={isdId ?? 0}
          defaultLabelText='What time of day do you desire this service?'
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (desiredSchedule === DesiredSchedule.Morning ? ' selected' : '')}
        data-testid='schedule-option-morning'
        onClick={() => { setDesiredSchedule(DesiredSchedule.Morning) }}
      >
        Morning
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (desiredSchedule === DesiredSchedule.Afternoon ? ' selected' : '')}
        data-testid='schedule-option-afternoon'
        onClick={() => { setDesiredSchedule(DesiredSchedule.Afternoon) }}
      >
        Afternoon
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (desiredSchedule === DesiredSchedule.Evening ? ' selected' : '')}
        data-testid='schedule-option-evening'
        onClick={() => { setDesiredSchedule(DesiredSchedule.Evening) }}
      >
        Evening
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="outlined"
        className={'wide-button' + (desiredSchedule === DesiredSchedule.AllDay ? ' selected' : '')}
        data-testid='schedule-option-all-day'
        onClick={() => { setDesiredSchedule(DesiredSchedule.AllDay) }}
      >
        All Day
      </Button>
    </Grid>

    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='backButton'
        className='back-button'
        data-testid='back-button'
        onClick={handleBack}
        variant='outlined'
      >
        Back
      </Button>
    </Grid>
    <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
      <Button
        name='continueButton'
        className='footer-button'
        data-testid='continue-button'
        onClick={handleContinue}
        variant='contained'
      >
        Continue
      </Button>
    </Grid>
  </Grid>
}
