import { Card, CardContent, Typography, IconButton } from '@mui/material'
import S3Image from '../../S3Image'
import { type userNotificationSetting } from '../../../core/types'
import EditIcon from '@mui/icons-material/Edit'
import '../../Cards/Large/LargeCard.css'
import { LabelWithToolTip } from '../../Label/Index'
import { UserSubscriptionEdit } from '../../../Pages/UserManagement/UserSubscriptionEdit'
import { useState } from 'react'
import { type SubscriptionCardProps } from '../../../Pages/UserManagement/UserSubscriptions'

export function SubscriptionCard (props: SubscriptionCardProps): JSX.Element {
  const [showEdit, setShowEdit] = useState(false)
  const editClick = (): void => {
    setShowEdit(true)
  }
  const cardHeader = <>
    {props.logoKey != null && (
      <S3Image imageKey={props.logoKey ?? ''} alt={props.logoAlt ?? 'logo'} />
    )}
    <Typography data-testid='cardHeader' component="div" variant="h5" className={'d-flex f-align-items-center '} >
      {props.title}
    </Typography>
  </>
  return <><Card sx={{ maxWidth: '100%', display: 'flex', flexDirection: 'row', borderRadius: '32px' }}>
    <CardContent sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {cardHeader}
      <Typography component="div" sx={{ fontWeight: 'bold' }} variant="body1" className={'d-flex f-align-items-center '}>
        Active Subscriptions <IconButton onClick={editClick}><EditIcon /></IconButton>
      </Typography>

      {props.usernotificationSetting.map((us: userNotificationSetting) => {
        return ((us.isAppNotificationEnabled || us.isEmailNotificationEnabled) &&
          < LabelWithToolTip
            key={us.id}
            labelText={us.eventType}
            sx={{
              whiteSpace: 'break-spaces',
              color: 'black',
              overflowWrap: 'break-word',
              margin: 2
            }}
          />)
      })}
    </CardContent>
  </Card>
    {showEdit && <UserSubscriptionEdit
      id={props.id}
      type={props.type}
      defaultEvents={props.defaultEvents}
      usernotificationSetting={props.usernotificationSetting}
      onClose={() => { setShowEdit(false) } }
      onSubmit={() => {
        props.onSubmit()
        setShowEdit(false)
      } }
      show={showEdit}
      userSecurityType={props.userSecurityLevel}
      imageKey={props.logoKey}
      imageAltText={props.logoAlt}
      userid={props.userId}
      title={props.title}
    />}
  </>
}
