/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material'
import { type location, type ChildCaregiver, type ChildSiblings, type ChildEditValues, type lookup, type postError } from '../../core/types'
import { FormatDateValue } from '../../core/Utilities'
import S3Image from '../S3Image'
import profileImg from '../../Assets/Icons/046-teacher.png'
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState } from 'react'
import { BannerModal } from '../Modal/BannerModal'
import { LocationDetails } from '../Cards/LocationCard/LocationDetails'
import { MapStateName } from '../Cards/LocationCard/mapStateName'
import { ChildEditLayout } from '../../Pages/CareNetworkManagement/CareNetworkDetail/ChildEditLayout'
import { RaceList } from '../CheckList/RaceList'
import { EligiblityFactorList } from '../CheckList/EligiblityFactorList'
import { sendGet, sendPost, sendPut } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { EditCareNetworkMembers } from './EditCareNetworkMembers'
import { type AdditionalValues, ChildAdditionalInfoList } from '../CheckList/ChildAdditionalInfoList'

export interface ChildDetailDisplayProps {
  child: ChildEditValues
  caregivers: ChildCaregiver[]
  siblings: ChildSiblings[]
  address: location
  isdid: number | null
  hideEditButtons: boolean
  onRefresh: () => void
}

export function ChildDetailDisplay (props: ChildDetailDisplayProps): JSX.Element {
  const { child } = props
  if (child == null) return (<></>)

  let img = <Avatar alt="profile logo" src={profileImg} sx={{ width: 50, height: 50, marginRight: '3px', cursor: 'pointer' }} />
  if (child.image !== null && child.image !== undefined && child.image !== '') {
    img = <S3Image
      imageKey={props.child.image ?? ''}
    />
  }

  const suffix: string = (child.suffix !== null && child.suffix !== undefined) ? ` ${child.suffix}` : ''
  const ageDisplay: string = (child.ageDisplay !== null && child.ageDisplay !== undefined) ? ` (${child.ageDisplay})` : ''
  const middleName: string = (child.middleName !== null && child.middleName !== undefined) ? ` ${child.middleName}` : ''
  const nameDisplay = `${child.firstName}${middleName} ${child.lastName}${suffix}${ageDisplay}`

  const [showEditEligibility, setShowEditEligibility] = useState(false)
  const [showEditChildDetails, setShowEditChildDetails] = useState(false)
  const [showEditLocation, setShowEditLocation] = useState(false)
  const [showEditMembers, setShowEditMembers] = useState(false)
  const [editingAddress, setEditingAddress] = useState(props.address)
  const [editingChild, setEditingChild] = useState(props.child)
  const [editingCaregivers, setEditingCaregivers] = useState<ChildCaregiver[]>(props.caregivers)
  const [editingSiblings, setEditingSiblings] = useState<ChildSiblings[]>(props.siblings)
  const [errors, setErrors] = useState<postError[]>([])
  const [loading, setLoading] = useState(false)

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: location = { ...editingAddress }
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode
    newplace.isPrimary = false
    setEditingAddress(newplace)
  }

  const [languages, setLanguages] = useState<lookup[]>([])
  const [authStatuses, setAuthStatuses] = useState<lookup[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/Language/GetLookup?sort=true', {})
      if (error[0] === '') {
        setLanguages(response)
      }
      const { response: response2, error: error2 } = await sendGet('/AuthorizationStatus/GetLookup', {})
      if (error2[0] === '') {
        setAuthStatuses(response2)
      }
    }

    void fetchData()
  }, [])

  const onEditEligibility = (): void => {
    setEditingChild(props.child)
    setShowEditEligibility(true)
  }

  const onEditChildDetails = (): void => {
    setEditingChild(props.child)
    setShowEditChildDetails(true)
  }

  const editLocationDetails = (): void => {
    setEditingAddress(props.address)
    setShowEditLocation(true)
  }

  const handleLocChange = (e: any): void => {
    setEditingAddress({ ...editingAddress, [e.target.name]: e.target.value })
  }

  const handleChildDataChange = (updatedChild: ChildEditValues): void => {
    setEditingChild({ ...updatedChild })
  }

  const editContent = <Box sx={{ p: '0 20px' }}><LocationDetails
    editData={editingAddress}
    onChange={handleLocChange}
    onPlaceSelected={handlePlaceSelected}
    errors={[]}
    hidePrimary={true}
    showHomelessOption={true}
  /></Box>

  const handleLocationUpdate = async (): Promise<boolean> => {
    if (!loading) {
      setLoading(true)
      const submitData = {
        EntityId: editingAddress.id,
        Location: editingAddress,
        IsPrimary: true
      }
      const fileUrl = '/CareNetworkEdit/UpdateLocation'
      const { response } = await sendPut(fileUrl, submitData)
      if (response.success as boolean) {
        props.onRefresh()
        setShowEditLocation(false)
        toast.success('Address Updated')
      }
      setLoading(false)
      return response.success
    }
    return false
  }

  const handleChildUpdate = async (message: string): Promise<boolean> => {
    if (!loading) {
      setLoading(true)
      const fileUrl = '/ChildDetails/Update'
      const { response } = await sendPost(fileUrl, editingChild)
      if (response.success as boolean) {
        props.onRefresh()
        setShowEditChildDetails(false)
        setShowEditEligibility(false)
        toast.success(message)
      }
      setLoading(false)
      return response.success
    }
    return false
  }

  const handleMembersUpdate = async (): Promise<boolean> => {
    if (!loading) {
      setLoading(true)

      const { response } = await sendPost('/CareNetworkEdit/UpdateMembers', { caregivers: editingCaregivers, siblings: editingSiblings, childId: editingChild.id, isdId: props.isdid })
      if (response.success as boolean) {
        props.onRefresh()
        setShowEditMembers(false)
        toast.success('Members Updated')
        setErrors([])
      } else {
        setErrors(response.errors as postError[])
      }

      setLoading(false)
      return true
    }
    return false
  }

  const handleMembersChange = (caregivers: ChildCaregiver[], siblings: ChildSiblings[]): void => {
    setEditingCaregivers(caregivers)
    setEditingSiblings(siblings)
  }

  const handleEditMembers = (): void => {
    setErrors([])
    setShowEditMembers(true)
  }
  const additionalValues: AdditionalValues = {
    hasSNAPBenefits: child.hasSNAPBenefits,
    isFosterChild: child.isFosterChild,
    isOnCashAssistance: child.isOnCashAssistance,
    isOnSSI: child.isOnSSI
  }

  return <>
    <BannerModal
      className='Nested Referral'
      onClose={() => { setShowEditEligibility(false) }}
      open={showEditEligibility}
      title={'Edit Race & Ethnicity / Eligibility Factors'}
      content={<Box sx={{ p: '0 20px' }}>
        <RaceList
          raceList={editingChild.childRaceList ?? []}
          setRaceList={(raceList) => {
            setEditingChild({ ...editingChild, childRaceList: raceList })
          }}
        />

        <EligiblityFactorList
          captureIEP={editingChild.captureIEP}
          captureIFSP={editingChild.captureIFSP}
          captureEOIS={editingChild.captureEOIS}
          hasIEP={editingChild.hasIEP}
          hasIFSP={editingChild.hasIFSP}
          hasEOIS={editingChild.hasEOIS}
          eligiblityList={editingChild.eligibilityFactorList ?? []}
          enviromentalRiskList={editingChild.environmentalRiskList ?? []}
          setEligibilityValues={(factors, envRisks, values) => {
            setEditingChild({ ...editingChild, eligibilityFactorList: factors, environmentalRiskList: envRisks, otherEligibilityFactor: values.otherFactor, hasIEP: values.hasIEP, hasIFSP: values.hasIFSP, hasEOIS: values.hasEOIS })
          }}
          otherFactor={editingChild.otherEligibilityFactor}
        />

        <ChildAdditionalInfoList
          values={additionalValues}
          setAdditionalValues={(values) => {
            setEditingChild({ ...editingChild, hasSNAPBenefits: values.hasSNAPBenefits, isFosterChild: values.isFosterChild, isOnCashAssistance: values.isOnCashAssistance, isOnSSI: values.isOnSSI })
          }}
        />
      </Box>}
      onConfirm={async () => { await handleChildUpdate('Demographic Data Updated') }}
    />

    <BannerModal
      className='Nested Referral'
      onClose={() => { setShowEditChildDetails(false) }}
      open={showEditChildDetails}
      title={'Edit Child Details'}
      content={<Box sx={{ p: '0 20px' }}>
        <ChildEditLayout
          child={editingChild}
          isUnborn={editingChild.isUnborn}
          handleChange={handleChildDataChange}
          file={null}
          setFile={() => { alert('picture is not being updated here') }}
          languages={languages}
          authStatuses={authStatuses}
        />
      </Box>}
      onConfirm={async () => await handleChildUpdate('Child Details Updated')}
    />

    <BannerModal
      className='Nested Referral'
      onClose={() => { setShowEditLocation(false) }}
      open={showEditLocation}
      title={'Edit Address'}
      content={editContent}
      onConfirm={handleLocationUpdate}
    />

    <BannerModal
      className='Nested Referral'
      onClose={() => { setShowEditMembers(false) }}
      open={showEditMembers}
      title={'Edit Members'}
      content={<Box sx={{ p: '0 20px' }}>
        <EditCareNetworkMembers
          child={child}
          isdid={props.isdid}
          errors={errors}
          initialCaregivers={props.caregivers}
          initialSiblings={props.siblings}
          handleUpdate={handleMembersChange}
        />
      </Box>}
      onConfirm={handleMembersUpdate}
    />

    <Grid container columns={3} rowSpacing={2}>
      <Grid item xs={1} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        {img}
      </Grid>
      <Grid item xs={2}>
        <Grid container columns={2} display='flex'>
          <Grid item xs={2}>
            <Typography component="div" variant="h6">{nameDisplay}</Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: 'min-content' }}>
              <Grid container columns={1}>
                {child.isUnborn ? <Grid item xs={1}>{FormatDateValue(props.child?.dueDate)}</Grid> : <Grid item xs={1}>{FormatDateValue(props.child?.dateOfBirth)}</Grid>}
                <Grid item xs={1}>{props.child?.birthGender}</Grid>
                <Grid item xs={1}>{props.child?.language}</Grid>
                <Grid item xs={1}>{props.child?.uic}</Grid>
              </Grid>
            </Box>
            <Box >
              {!props.hideEditButtons && <IconButton data-testid='childEditButton' sx={{ p: '0', mr: '20px' }} onClick={onEditChildDetails}><EditIcon /></IconButton>}
            </Box>
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: 'min-content', whiteSpace: 'nowrap' }}>
              <Grid container columns={1}>
                <Grid item xs={1}>{props.address?.address}</Grid>
                <Grid item xs={1} >{props.address?.city}, {props.address?.state}</Grid>
                <Grid item xs={1} >{props.address?.zipCode}</Grid>
                {props.child?.residentDistrict !== null &&
                  <>
                    <Grid item xs={1} fontWeight='bold' >Resident District:</Grid>
                    <Grid item xs={1} style={{ whiteSpace: 'break-spaces' }} >{props.child?.residentDistrict}</Grid>
                  </>
                }
              </Grid>
            </Box>
            <Box>
              {!props.hideEditButtons && <IconButton data-testid='addressEditButton' sx={{ p: 0 }} onClick={editLocationDetails}><EditIcon /></IconButton>}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <Typography component="div" variant="h6">Race & Eligibility Factors</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          {props.child?.childRaceList?.map(r => {
            return <Grid key={r.id}>{r.name}</Grid>
          })}
          {props.child?.eligibilityFactorList?.map((factor, i) => {
            return (<Grid key={i}>{factor.name}</Grid>)
          })}
        </Box>
        <Box>
          {!props.hideEditButtons && <IconButton data-testid="raceFactorEdit" sx={{ p: 0 }} onClick={onEditEligibility}><EditIcon /></IconButton>}
        </Box>
      </Grid>

      <Grid item xs={1}>
        <Typography component="div" variant="h6">Caregivers</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {props.caregivers.map((caregiver, i) => {
          return <Box key={i}>
            <Box>{caregiver.firstName} {caregiver.lastName}</Box>
            <Box>{caregiver.isLivingWithStudent ? `Lives with ${props.child?.firstName ?? ''}` : ''} </Box>
            <Box>{caregiver.accessType}</Box>
            <Box>{languages.find(l => l.id === caregiver.languageID)?.name ?? 'English'}</Box>
            <Box>{caregiver.email}</Box>
            <Box>{caregiver.phone}</Box>
          </Box>
        }
        )}
        <Box>
          {!props.hideEditButtons && <IconButton data-testid='editCaregiverButton' sx={{ p: 0 }} onClick={handleEditMembers}><EditIcon /></IconButton>}
        </Box>
      </Grid>

      {props.siblings.length > 0 && (<>
        <Grid item xs={1} className='table-label'>
          <Typography component="div" variant="h6">Siblings</Typography>
        </Grid>
        <Grid item xs={2}>
          {props.siblings.map((sibling, i) => {
            return <Box key={i}>
              <Grid>{sibling.firstName} {sibling.lastName}</Grid>
              <Grid className='pb-10'>{sibling.isLivingWithStudent ? `Lives with ${props.child?.firstName ?? ''}` : `Does not live with ${props.child?.firstName ?? ''}`} </Grid>
            </Box>
          }
          )}
        </Grid>
      </>
      )}
    </Grid>
  </>
}
