import { Button, Grid, Typography } from '@mui/material'
import { deleteSession, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { type lookup, type EnrollmentDetails } from '../../../../core/types'
import { generatePath, useNavigate, useParams } from 'react-router'
import { CAREGIVER_PORTAL } from '../../../Routes'
import { sendGet } from '../../../../hooks/use-fetch'
import { CheckBoxWithLabel } from '../../../../Components/CheckBox'
import { Autocomplete } from '../../../../Components/AutoComplete'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

interface ProviderSelectionProps {
  caregiverForm: EnrollmentDetails
}

export function ProviderSelection (props: ProviderSelectionProps): JSX.Element {
  const dispatch = useCaregiverWizardUpdateDispatch()
  const nav = useNavigate()
  const { isdId, isdServiceProviderId } = useParams()
  const [providers, setProviders] = useState<lookup[]>([])
  const [isProviderNotFound, setIsProviderNotFound] = useState<boolean>(false)
  const caregiverForm = props.caregiverForm

  useScrollTop()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, success } = await sendGet(`/CaregiverPortal/GetAllProviders/${isdId ?? 0}`, {})
      if (success) {
        setProviders(response)
      }
    }
    void fetchData()
  }, [isdId])

  const handleProviderSelected = (value: number): void => {
    const tempForm = { ...caregiverForm, userServiceProviderId: value }
    dispatch({ type: 'form', form: tempForm })
  }

  const handleBack = (): void => {
    deleteSession()
    dispatch({ type: 'form', form: null })

    const portalRoute = generatePath(CAREGIVER_PORTAL, { isdId: isdId ?? '', isdServiceProviderId: isdServiceProviderId ?? '' })
    nav(portalRoute)
  }

  const handleContinue = async (): Promise<void> => {
    if (caregiverForm.userServiceProviderId == null && !isProviderNotFound) {
      toast.error('Please select a provider to continue')
      return
    }

    const tempForm = { ...caregiverForm, currentSubStep: 2 }
    dispatch({ type: 'form', form: tempForm })
    await saveSession(tempForm)
  }

  return <>
    <Grid container spacing={2}>
      <Typography variant='h5' sx={{ mb: '30px' }} component='div'>
        Great news! Where would you like to go?
      </Typography>
      {!isProviderNotFound && <Grid item xs={12}>
        <Autocomplete
          width='100%'
          label='Service Provider'
          name='serviceProvider'
          onChange={(e, value) => { handleProviderSelected(value?.id as number) }}
          options={providers}
          value={caregiverForm.userServiceProviderId ?? 0}
          placeholder="Search providers..."
          noBottomPadding
          dataTestId='service-provider'
        />
      </Grid>}

      {caregiverForm.userServiceProviderId == null && <Grid item xs={12}>
        <CheckBoxWithLabel
          edge='start'
          label='Provider not found'
          name='isProviderNotFound'
          value={isProviderNotFound}
          setValue={setIsProviderNotFound}
          buttonClassName='no-horiz-padding'
          textClassName='vertical-center-align'
          checkboxClassName='no-horiz-padding'
          dataTestId='provider-not-found'
        />
      </Grid>}

      <Grid item xs={6} sx={{ mb: '20px', mt: '20px' }}>
        <Button
          name='backButton'
          className='back-button'
          data-testid='back-button'
          onClick={handleBack}
          variant='outlined'
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ mb: '20px', mt: '20px' }}>
        <Button
          name='continueButton'
          className='footer-button'
          data-testid='continue-button'
          onClick={handleContinue}
          variant='contained'
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  </>
}
