import { Box, Grid, Typography, type SelectChangeEvent } from '@mui/material'
import { LargeCard } from '../../../Components/Cards/Large'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { BusinessWeekHours } from '../../../Components/BusinessHours/BusinessWeekHours'
import { type postError, type businessHours, type facility, type lookup, type location } from '../../../core/types'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { useEffect, useState } from 'react'
import { Modal } from '../../../Components/Modal'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { TextAreaWithLabel } from '../../../Components/TextArea'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import AlertMessage from '../../../Components/AlertMessage'
import { LocationDetails } from '../../../Components/Cards/LocationCard/LocationDetails'
import { MapStateName } from '../../../Components/Cards/LocationCard/mapStateName'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'

interface FacilityDetailsProps {
  isNew: boolean
  facility: facility
  onSave: () => void
  onClose: () => void
  show: boolean
  isds: lookup[]
}

interface ISDInfo {
  isdid: number | null
  isdName: string | null
  locationISDFound: boolean
}

export function FacilityDetailsEdit (props: FacilityDetailsProps): JSX.Element {
  const accountId: number = useAccountId()?.id ?? 0
  const [errors, setErrors] = useState<postError[] | undefined>()
  const [facility, setFacility] = useState<facility>(props.facility)
  const [isdInfo, setIsdInfo] = useState<ISDInfo>({ isdid: props.facility.isdid, isdName: props.facility.isdName ?? null, locationISDFound: true })
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [validIsdIds, setValidIsdIds] = useState<number[]>([])
  const [locError, setLocError] = useState<number | undefined>(undefined)
  const [tempLoc, setTempLoc] = useState<location | null>(null)
  const [isCheckingAddress, setIsCheckingAddress] = useState(false)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (!props.show) { return }
      setFacility(props.facility)
      const { response: resp } = await sendGet(`/FacilityDetails/GetValidIsds?spId=${accountId}`, {})
      setValidIsdIds(resp)
    }
    void loadData()
  }, [props.show, accountId])

  useEffect(() => {
    // because it is async, we cant set the facility directly from the set place or else it will have the wrong info
    if (tempLoc !== null) {
      setFacility({ ...facility, location: { ...tempLoc } })
    }
  }, [tempLoc])

  useEffect(() => {
    const checkAddress = async (): Promise<void> => {
      if (!props.show || isCheckingAddress) { return }

      if (facility.location.address !== undefined && facility.location.city !== undefined && facility.location.state !== undefined && facility.location.zipCode !== undefined) {
        setIsCheckingAddress(true)

        const { response: resp } = await sendGet(`/FacilityDetails/GetLocationISD?address=${facility.location.address}&city=${facility.location.city}` +
          `&state=${facility.location.state}&zip=${facility.location.zipCode}`, {})
        const isd: lookup = resp
        if (isd.id === 0) {
          setIsdInfo({ isdid: null, isdName: isd.name, locationISDFound: false })
          setLocError(1)
        } else if (!validIsdIds.includes(isd.id)) {
          setIsdInfo({ isdid: validIsdIds[0], isdName: isd.name, locationISDFound: validIsdIds.length === 1 })
          setLocError(2)
        } else {
          setIsdInfo({ isdid: isd.id, isdName: isd.name, locationISDFound: true })
          setLocError(undefined)
        }

        setIsCheckingAddress(false)
      }
    }

    void checkAddress()
  }, [facility])

  const handleClose: () => void = () => {
    setErrors(undefined)
    props.onClose()
  }

  const qualityOptions = [
    { id: 0, name: 'None' },
    { id: 1, name: 'Maintaining Health & Safety' },
    { id: 2, name: 'Reflecting On Quality' },
    { id: 3, name: 'Enhancing Quality' },
    { id: 4, name: 'Enhancing Quality-Validated' },
    { id: 5, name: 'Demonstrating Quality' }
  ]

  const saveFacility = async (): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      if (isdInfo.isdid === null) {
        toast.error('Please select a valid ISD for this facility')
        setIsRequestLoading(false)
        return
      }
      let resp
      let newRecord: { errors: postError[], newRecordID: number, success: boolean }
      const facilityData = { ...facility, isdid: isdInfo.isdid, isdName: isdInfo.isdName, locationISDFound: isdInfo.locationISDFound }
      if (props.isNew) {
        resp = await sendPut('/FacilityDetails/Create', facilityData)
        newRecord = resp.response
      } else {
        resp = await sendPost('/FacilityDetails/Update', facilityData)
        newRecord = resp.response
      }
      if (!newRecord.success) {
        newRecord.errors.forEach(e => toast.error(e.error))
        setErrors(newRecord.errors)
      } else {
        setErrors(undefined)
        props.onSave()
      }
      setIsRequestLoading(false)
    }
  }

  const handleLocationChanged = async (e: any): Promise<void> => {
    setFacility({ ...facility, location: { ...facility.location, [e.target.name]: e.target.value }, locationISDFound: false })
  }

  const handleISDChanged = (e: any): void => {
    setIsdInfo({ ...isdInfo, isdid: parseInt(e.target.value) })
  }

  const handleChange = (e: any): void => {
    setFacility({ ...facility, [e.target.name]: e.target.value })
  }

  const valueChanged = (field: string, value: any): void => {
    setFacility({ ...facility, [field]: value })
  }

  const handleStatusChanged = (e: SelectChangeEvent<boolean>): void => {
    const newFacility = { ...facility, inactive: e.target.value as boolean }
    setFacility(newFacility)
  }

  const handleExpirationDateChanged = (newDate: Date | null): void => {
    const newFacility = { ...facility, licenseExpirationDate: newDate }
    setFacility(newFacility)
  }

  const handleHoursChanged = (newHours: businessHours[]): void => {
    setFacility({ ...facility, facilityHours: newHours })
  }

  const handleHoursAccept = (newHours: businessHours[]): void => {
    const newFacility = { ...facility, facilityHours: newHours }
    setFacility(newFacility)
  }

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const newplace: location = {} as location
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode
    newplace.isPrimary = false
    setTempLoc(newplace)
  }

  const grids = <>
    <Grid container columns={8} spacing={2}>
      <Grid item sm={3} md={3} >
        <Grid container columns={12} spacing={1}>
          <Grid item sm={6} md={6}>
            <TextFieldWithLabel
              label='Name'
              name='name'
              data-testid='name'
              required={true}
              error={errors?.find(e => e.field === 'Name') !== undefined}
              showRequiredText={errors?.find(e => e.field === 'Name') !== undefined}
              onChange={handleChange}
              value={facility.name ?? ''}
            />
          </Grid>
          <Grid item sm={6} md={6}>
            <TextFieldWithLabel
              label='Facility Code'
              name='facilityCode'
              data-testid='facilityCode'
              onChange={handleChange}
              value={facility.facilityCode ?? ''}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <LocationDetails
              editData={facility.location}
              onChange={async (e) => { await handleLocationChanged(e) }}
              hidePrimary
              showHomelessOption={false}
              errors={errors?.map(e => e.field) ?? []}
              onPlaceSelected={handlePlaceSelected}
            />

          </Grid>
          {locError === 1 && (
            <AlertMessage
              data-testid='alertMessage'
              message={'We could not find this address, you may select the operating ISD, however it may not appear in some searches.'}
              type='warning'
              icon={<></>}
            />
          )}
          {locError === 2 && (
            <AlertMessage
              data-testid='alertMessage'
              message='Be aware that the selected address is not located in an ISD tied to this service provider'
              type='warning'
              icon={<></>}
            />
          )}
          <Grid item sm={12} md={12}>
            <SelectWithLabel
              width='100%'
              label='Operating ISD'
              name='isdid'
              required={true}
              data-testid='isdid'
              error={errors?.find(e => e.field === 'ISD') !== undefined}
              showRequiredText={errors?.find(e => e.field === 'ISD') !== undefined}
              onChange={handleISDChanged}
              options={props.isds}
              disabled={isdInfo.locationISDFound}
              value={(isdInfo.isdid ?? 0) > 0 ? isdInfo.isdid ?? '' : ''}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextAreaWithLabel
              label='Offering Narrative'
              onChange={handleChange}
              value={facility.offeringNarrative ?? ''}
              name='offeringNarrative'
            />
          </Grid>

          <Grid item sm={12} >
            <Typography
              data-testid='statusHeader'
              component="div"
              variant="h5"
              className={'d-flex f-align-items-center'}
            >
              Status
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Box sx={{ pt: '8px' }}>
              <TextFieldWithLabel
                label='License Number'
                name='licenseNumber'
                onChange={handleChange}
                value={facility.licenseNumber ?? ''}
              />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box sx={{ pt: '8px' }}>
              <DatePickerWithLabel
                dataTestId='expirationDate'
                label='Expiration Date'
                name='expirationDate'
                onChange={handleExpirationDateChanged}
                value={facility.licenseExpirationDate}
              />
            </Box>
          </Grid>
          <Grid item sm={12}>
            <SelectWithLabel
              width='100%'
              label='Status'
              name='status'
              onChange={handleStatusChanged}
              options={[{ id: false, name: 'Active' }, { id: true, name: 'Deactivated' }]}
              value={facility.inactive ?? true}
            />
          </Grid>

        </Grid>
      </Grid>
      <Grid item sm={5} md={5} >
        <Grid container columns={12} spacing={1}>
          <Grid item sm={12}>
            <LargeCard fullWidth
              className='Borderless'
              data-testid='hours-card'
              content={
                <BusinessWeekHours
                  BusinessWeek={facility.facilityHours}
                  onChange={handleHoursChanged}
                  onAccept={handleHoursAccept}
                />
              }
              header='Operating Hours'
            />
          </Grid>
          <Grid item sm={6} >
            <Typography component="div" sx={{ marginLeft: '20px' }} variant="h5">Services</Typography>
            {facility.services.map((p, i) => { return <Typography key={i} sx={{ marginLeft: '20px' }}>{p.name}</Typography> })}
          </Grid>
          <Grid item sm={6} >
            <Typography component="div" variant="h5">Programs</Typography>
            {facility.programs.map((p, i) => { return <Typography key={i}>{p.name}</Typography> })}
          </Grid>
          <Grid item sm={12} >
            <SelectWithLabel
              leftMargin='20px'
              width='100%'
              label='Great Start to Quality Rating'
              name='qualityRating'
              value={(parseInt(facility.qualityRating?.toString() ?? '1'))}
              options={qualityOptions}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={12} >
            <CheckBoxWithLabel
              label='Provides Before / After School Care'
              name='providesBeforeAfterSchoolCare'
              value={facility.providesBeforeAfterSchoolCare}
              setValue={(val) => { valueChanged('providesBeforeAfterSchoolCare', val) }}
              edge='start'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>

  const modal = <Modal
    maxWidth='lg'
    confirmationContent={grids}
    open={props.show}
    onClose={handleClose}
    onConfirm={saveFacility}
    title='Edit Facility'
  />
  return modal
}
