import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'

interface SmallCardProps {
  header: string
  currentValue?: number
  totalValue: number
  badges: JSX.Element
  onClick: () => void
}

export const SmallCard = (props: SmallCardProps): JSX.Element => {
  let cardValue = <></>
  if (props.currentValue != null) { cardValue = <Typography variant="h5" color="text.secondary" component="div">{props.currentValue}/</Typography> }
  return (

    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardContent>
          <Typography component="div" variant="h5">{props.header}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
              {cardValue}<Typography component="div" variant="h5">{props.totalValue}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }} >{props.badges}</Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
