import { useEffect, useState } from 'react'
import { ChildDetailDisplay } from '../../../Components/ChildDetails/ChildDetailDisplay'
import { useChildContext } from '../../../ContextProviders/AddChildProvider'
import { type location } from '../../../core/types'
import { sendGet } from '../../../hooks/use-fetch'

export function AddChildReview (): JSX.Element {
  const [isdid, setIsdid] = useState<number | null>(null)
  const data = useChildContext()
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const locData = data.locations.find(l => l.location.isPrimary)?.location ?? {} as location

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: isdid } = await sendGet('/CareNetworkEdit/GetISDFromLocation', locData)
      if (isdid != null && isdid !== 0) {
        setIsdid(isdid)
      }
    }

    void loadData()
  }, [])

  return <ChildDetailDisplay
    address={locData}
    isdid={isdid}
    caregivers={data.caregivers.map(c => c.caregiver)}
    child={data.child}
    siblings={data.siblings.map(s => s.sibling)}
    onRefresh={() => { }}
    hideEditButtons={true}
  />
}
