import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Button, Chip, CircularProgress, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type React from 'react'
import { useEffect, useState } from 'react'
import DataTable from '../../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { type ISDProgramOption, type ISDServiceOption, type postError, type serviceProvider } from '../../../core/types'
import { FormatDateValue } from '../../../core/Utilities'
import { ServiceProviderManagementSearch, type ServiceProviderManagementSearchCriteria } from './ServiceProviderManagementSearch'
import { ServiceProviderEdit } from './ServiceProviderEdit'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { usePagination, type PaginationProps, type SearchCriteria } from '../../../hooks/use-pagination'
import { type userData } from '../../ISDManagement/ISDEdit'

const nameColumn = new Column('name', 'Name')
const codeColumn = new Column('eemCode', 'EEM Code')
const dateColumn = new Column('onboardDate', 'Onboarded Date', ColumnType.CUSTOM)
const statusColumn = new Column('status', 'Status', ColumnType.CUSTOM)
const contactColumn = new Column('contact', 'Primary Contact', ColumnType.CUSTOM)
const servicesColumn = new Column('services', 'Services', ColumnType.CUSTOM)
const programsColumn = new Column('programs', 'Programs', ColumnType.CUSTOM)

dateColumn.customCellGenerator = row => {
  return <div>{FormatDateValue(row.onboardDate)}</div>
}
contactColumn.customCellGenerator = row => {
  return <div>{row.userName}</div>
}
servicesColumn.customCellGenerator = row => {
  const services: Array<{ name: string, id: number }> = row.serviceList.sort((a: { name: string }, b: { name: string }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  // pick one of 3 colors by doing a modulo on the id
  const listItems = services.map(s => {
    const colorItem = s.id % 3
    let indicatorClass = 'cyan-'
    if (colorItem === 1) {
      indicatorClass = 'purple-'
    } else if (colorItem === 2) {
      indicatorClass = 'red-'
    }
    return <Chip icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} key={s.name + '-servicesChip'} label={s.name} size='small' className={indicatorClass + 'indicator'} />
  })
  return <div className='stacked-items'>
    {listItems}
  </div>
}

programsColumn.customCellGenerator = row => {
  const services: Array<{ name: string, id: number }> = row.programList.sort((a: { name: string }, b: { name: string }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  // pick one of 3 colors by doing a modulo on the id
  const listItems = services.map(s => {
    const colorItem = s.id % 3
    let indicatorClass = 'cyan-'
    if (colorItem === 1) {
      indicatorClass = 'purple-'
    } else if (colorItem === 2) {
      indicatorClass = 'red-'
    }
    return <Chip icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} key={s.name + '-programsChip'} label={s.name} size='small' className={indicatorClass + 'indicator'} />
  })
  return <div className='stacked-items'>
    {listItems}
  </div>
}

statusColumn.customCellGenerator = row => {
  const displayName = row.status
  let indicatorClass = 'cyan-'
  if (row.status === 'Inactive') {
    indicatorClass = 'red-'
  }
  return <Chip icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={displayName} size='small' className={indicatorClass + 'indicator'} />
}
const columns = [nameColumn, codeColumn, contactColumn, servicesColumn, programsColumn, statusColumn]

export function ISDServiceProviderManagement (): JSX.Element {
  const id = useAccountId()?.id ?? 0
  const [rows, setRows] = useState<Array<{ id: number, name: string, status: string }>>([])
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [services, setServices] = useState<ISDServiceOption[]>([])
  const [programs, setPrograms] = useState<ISDProgramOption[]>([])

  const activeCount = rows.filter(r => r.status === 'Active').length
  const inActiveCount = rows.filter(r => r.status === 'Inactive').length

  const [refreshTime, setRefreshTime] = useState(new Date())

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (id === 0) return

      initialServiceProvider.isdid = id
      const { response: data } = await sendGet('/ISDProviderManagement/GetAll?isdId=' + id.toString(), {})
      setRows(data)
      const { response: data2 } = await sendGet('/ISDProviderManagement/AvailableServices?isdId=' + id.toString(), {})
      setServices(data2)
      const { response: data3 } = await sendGet('/ISDProviderManagement/AvailablePrograms?isdId=' + id.toString(), {})
      setPrograms(data3)

      setLoading(false)
    }
    void getData()
  }, [id, refreshTime])

  const paginationProps: PaginationProps<RowData> = {
    rows
  }
  const pagination = usePagination(paginationProps)
  const [searchData, setSearchData] = useState<ServiceProviderManagementSearchCriteria>({ name: '', status: undefined })
  const [loading, setLoading] = useState(true)
  const initialServiceProvider: serviceProvider = { isdid: id, id: 0, programList: [], serviceList: [], name: '', eemCode: '', onboardDate: new Date(), status: 'Active', userID: null, s3Key: null, isIntake: false, allowUICLookup: false }
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<serviceProvider>(initialServiceProvider)
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [open, setOpen] = useState(false)

  const handleSearch = (newdata: ServiceProviderManagementSearchCriteria): void => {
    setSearchData(newdata)
    const fieldArray: SearchCriteria[] = []
    if (newdata.name !== '') {
      fieldArray.push({
        field: 'name',
        value: newdata.name
      })
    }
    if (newdata.status != null) {
      fieldArray.push({
        field: 'status',
        value: newdata.status
      })
    }
    pagination.setSearchFields(fieldArray)
  }

  const addClick = (): void => {
    setErrors(undefined)
    setOpen(true)
    setSelectedServiceProvider(initialServiceProvider)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const editingServiceProvider: serviceProvider = row as serviceProvider
    setErrors(undefined)
    setOpen(true)
    setSelectedServiceProvider(editingServiceProvider)
  }

  const handleSubmit = (newRowData: RowData, userData: userData): void => {
    const updateUser = async (): Promise<void> => {
      if (!isRequestLoading) {
        setIsRequestLoading(true)

        setErrors(undefined)
        const { primaryContactUser, ...rest } = newRowData

        let suc = false
        let result: { response: any, error: string[], success: boolean } | null = null
        const sp: { programIds: number[], serviceIDs: number[], id: number, userData: userData } = { ...rest, userData } as any
        if (sp.id === 0) {
          result = await sendPost('/ISDProviderManagement/Insert', sp)
        } else {
          result = await sendPut('/ISDProviderManagement/Update', sp)
        }
        const rst = result.response as { newRecordId: number, errors: postError[], success: boolean }
        suc = rst.success

        if (suc) {
          setOpen(false)
          setRefreshTime(new Date())
        } else {
          if (rst.errors != null && rst.errors.length > 0) {
            setErrors(rst.errors.map(e => e.error))
          }
        }
        setIsRequestLoading(false)
      }
    }
    void updateUser()
  }

  if (loading) return <CircularProgress />

  return (<>
    <Typography variant='h3'> Provider Management </Typography>
    <Grid container columns={12} alignItems={'center'} >
      <Grid item sm={12} md={12}>
        <Box sx={{ background: '#FFFFFF', borderRadius: '16px', padding: '24px', margin: '10px 0 10px 0', width: '40%' }}>
          <div className='info-box-header'>Total Onboarded Providers</div>
          <div style={{ marginTop: '15px' }}>
            <span style={{ marginRight: '150px', fontWeight: '700', fontSize: '24px' }}>{pagination.recordCount}</span>
            <span title='Active'><FiberManualRecordIcon className='cyan-icon' /><span className='icon-count' >{activeCount}</span></span>
            <span title='Inactive'><FiberManualRecordIcon className='red-icon' /><span className='icon-count'>{inActiveCount}</span></span>
          </div>
        </Box>
      </Grid>
      <Grid item sm={6} md={6}>
        <ServiceProviderManagementSearch current={searchData} onChange={handleSearch} />
      </Grid>
      <Grid item sm={6} md={6}>
        <Box justifyContent='flex-end' display='flex' width='100%' >
          <Button color='secondary' variant='contained' onClick={addClick} data-testid='add-provider'>Add Provider</Button>
        </Box>
      </Grid>
    </Grid>
    <Box width='fit-content' sx={{ marginTop: '10px' }}>
      <div>
        <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
          <DataTable
            name='providerTable'
            page={pagination.page}
            columns={columns}
            rows={pagination.internalRows}
            initialColumnSorts={[new ColumnSort('name')]}
            totalRecords={pagination.recordCount}
            loading={false}
            onSortChange={(col, sorts) => {
              pagination.onSortChange(col, sorts[0].order)
            }}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            onRowClick={rowClick}
            hasCheckbox={false}
          />

          {open && (
            <ServiceProviderEdit
              item={selectedServiceProvider}
              services={services}
              programs={programs}
              onClose={() => { setOpen(false) }}
              onSubmit={handleSubmit}
              open={open}
              errors={errors}
            />
          )}
        </Box>
      </div>
    </Box>
  </>)
}
