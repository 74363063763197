import SimpleAccordion from '../../../Components/Accordian'
import { Column, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { usePagination, type PaginationProps } from '../../../hooks/use-pagination'
import { type notificationEventGroup } from './ChildHistoryDisplay'
import DataTable from '../../../Components/Table/DataTable'
import { Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

export function ChildHistoryDetails (props: notificationEventGroup): JSX.Element {
  const eventTypeColumn = new Column('eventType', 'Action')

  const userColumn = new Column('userName', 'by')
  const timeColumn = new Column('eventDate', 'Time', ColumnType.CUSTOM)
  timeColumn.customCellGenerator = row => {
    const date = new Date(row.eventDate)
    let hour = date.getHours()
    if (hour === 0) { hour = 12 } else if (hour > 12) { hour = hour % 12 }
    return <Typography >{`${hour}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`}</Typography>
  }
  const detailsColumn = new Column('message', 'Details')

  const columns = [eventTypeColumn, userColumn, timeColumn, detailsColumn]
  const paginationProps: PaginationProps<RowData> = {
    rows: props.events,
    initialSize: 5,
    pageSizeSteps: [5, 10, 15]
  }
  const pagination = usePagination<RowData>(paginationProps)
  const dt = <DataTable
    data-test-table
    page={pagination.page}

    name='historyTable'
    hasCheckbox={false}
    columns={columns}
    rows={pagination.internalRows ?? []}
    totalRecords={pagination.recordCount}
    loading={false}
    onSortChange={(col, sorts) => {
      pagination.onSortChange(col, sorts[0]?.order)
    }}
    onPageChange={pagination.handleChangePage}
    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    onRowClick={() => {}}
  />
  return <SimpleAccordion
    body={dt}
    className='historyAccordion'
    icon={<CalendarMonthIcon />}
    title={props.title}
    width='100%'
    sx={{
      borderColor: 'lightGray',
      mb: '25px',
      ':first-of-type': {
        borderRadius: '32px'
      },
      ':last-of-type': {
        borderRadius: '32px'
      },
      '&.Mui-expanded:last-of-type': {
        mb: '25px'
      }
    }}
  />
}
