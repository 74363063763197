/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Alert, Box, Chip, Grid, Typography } from '@mui/material'
import { type childServiceSettings } from '../../../ContextProviders/AddChildProvider'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { DesiredSchedule, ReferralTimeFrame, type TransferReferralRequest, type lookup } from '../../../core/types'
import { DatePickerWithLabel } from '../../../Components/DatePicker'
import { TextAreaWithLabel } from '../../../Components/TextArea'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { TransferServiceDataDisplay } from '../../../Components/Services/TransferService'
import { GroupedAutoComplete } from '../../../Components/AutoComplete/GroupedAutoComplete'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'

interface ChildServiceSelectionProps {
  serviceSettings: childServiceSettings
  setServiceSettings: (serviceSettings: childServiceSettings) => void
  onServiceChange: (serviceId: string) => void
  onProgramChange: (programId: string) => void
  facilityList: lookup[]
  serviceList: Array<{ id: number, name: string, s3Key: string, inactive: boolean }>
  programList: lookup[]
  providerList: Array<{ id: number, name: string, isIntake: boolean }> | null
  onServiceProviderChange: (serviceProviderId: string) => void
  isNew?: boolean
  status: string
  showIncome: boolean
  requestTransfer?: boolean
  hasAccessRequest?: boolean
  childId?: number
  transferRequest?: TransferReferralRequest
  setTransferRequest?: (transferReq: TransferReferralRequest) => void
  showDuplicateWarning?: boolean
}

export function ChildServiceSelection (props: ChildServiceSelectionProps): JSX.Element {
  const [sessionBlockOptions, setSessionBlockOptions] = useState<Array<{ id: number, name: string, autoSelect: boolean }>>([])
  const [showSelectSessionBlock, setShowSelectSessionBlock] = useState<boolean>(false)

  const timeFrameOptions: Array<{ id: string, name: string }> = [
    { id: ReferralTimeFrame.UpcomingSchoolYear.toString(), name: 'Upcoming School Year' },
    { id: ReferralTimeFrame.Immediate.toString(), name: 'Immediate' },
    { id: ReferralTimeFrame.SpecificDate.toString(), name: 'Enter a Specific date' }
  ]
  const scheduleOptions: Array<{ id: string, name: string }> = [
    { id: DesiredSchedule.Morning.toString(), name: 'Morning' },
    { id: DesiredSchedule.Afternoon.toString(), name: 'Afternoon' },
    { id: DesiredSchedule.Evening.toString(), name: 'Evening' },
    { id: DesiredSchedule.AllDay.toString(), name: 'All Day' }
  ]

  const handleChange = (e: any): void => {
    props.setServiceSettings({ ...props.serviceSettings, [e.target.name]: e.target.value })
  }

  const handleDateChange = (date: Date | null): void => {
    props.setServiceSettings({ ...props.serviceSettings, customDate: date })
  }

  const handleServiceChange = (serviceID: string): void => {
    props.onServiceChange(serviceID)
  }

  const handleProgramChange = (programId: string): void => {
    props.onProgramChange(programId)
  }

  useEffect(() => {
    const getSessionBlockOptions = async (): Promise<void> => {
      const { response } = await sendGet(`/CareNetworkEdit/GetSessionBlocks?serviceProviderId=${props.serviceSettings.systemServiceProviderId ?? 0}&serviceId=${props.serviceSettings.systemService?.id ?? 0}
        &programId=${props.serviceSettings.systemProgram?.id ?? 0}&facilityId=${props.serviceSettings.facilityId ?? 0}&timeFrame=${props.serviceSettings.timeFrame ?? ''}&customDate=${props.serviceSettings.customDate?.toISOString() ?? ''}`, {})
      if (response != null && response.length > 0) {
        setSessionBlockOptions(response)
        props.setServiceSettings({ ...props.serviceSettings, sessionBlockId: response.find((e: { autoSelect: boolean }) => e.autoSelect)?.id })
      }
    }
    void getSessionBlockOptions()
  }, [props.serviceSettings.systemServiceProviderId, props.serviceSettings.systemProgram?.id, props.serviceSettings.systemService?.id, props.serviceSettings.facilityId, props.serviceSettings.timeFrame, props.serviceSettings.customDate])

  useEffect(() => {
    const showSessionBlocks = props.serviceSettings.systemServiceProviderId !== undefined &&
      props.serviceSettings.systemService?.id !== undefined &&
      props.serviceSettings.systemProgram?.id !== undefined

    setShowSelectSessionBlock(showSessionBlocks)
  }, [props.serviceSettings.systemServiceProviderId, props.serviceSettings.systemService?.id, props.serviceSettings.systemProgram?.id])

  const spValue = props.providerList?.find(i => i.id === props.serviceSettings.systemServiceProviderId) ?? null

  const isOpenReferral = props.status === 'Pending' || props.status === 'Waiting' || props.status === 'Waiver'
  const spField = (props.isNew ?? true)
    ? <GroupedAutoComplete
        id="systemServiceProviderId"
        options={props.providerList?.sort((a, b) => {
          const sameType = a.isIntake === b.isIntake
          if (sameType) { return a.name.localeCompare(b.name) } else {
            return a.isIntake ? -1 : 1
          }
        }) ?? []}
        value={spValue}
        groupBy={(option: { id: number, isIntake: boolean }) => option.isIntake ? 'Intake Providers' : 'Service Providers'}
        getOptionLabel={(option) => option.name}
        onChange={(e, v) => { props.onServiceProviderChange(v?.id?.toString() ?? '') } }
        sx={{ width: 300 }}
        disabled={!(props.isNew ?? true)}
        label={'Service Provider'}
        name={'systemServiceProviderId'}
    />
    : <TextWithLabel
        label='Service Provider'
        name='systemServiceProviderId'
        value={props.serviceSettings.systemServiceProviderName ?? ''}
    />
  const facilityField = isOpenReferral
    ? <SelectWithLabel
        width='100%'
        label='Facility'
        name='facilityId'
        onChange={handleChange}
        value={props.serviceSettings.facilityId ?? ''}
        options={props.facilityList}
    />
    : <TextWithLabel
        label='Facility'
        name='facilityId'
        value={props.serviceSettings.facilityName ?? ''}
    />
  const timeFrameField = isOpenReferral
    ? <SelectWithLabel
        width='100%'
        label='Referral Time Frame'
        name='timeFrame'
        onChange={handleChange}
        value={props.serviceSettings.timeFrame ?? ''}
        options={timeFrameOptions}
    />
    : <TextWithLabel
        label='Referral Time Frame'
        name='timeFrame'
        value={props.serviceSettings.timeFrame?.toString() ?? ''}
    />
  const specificDateField = isOpenReferral
    ? <DatePickerWithLabel
        label='Specific Date'
        name='customDate'
        onChange={handleDateChange}
        value={props.serviceSettings.customDate ?? new Date()}
    />
    : <TextWithLabel
        label='Specific Date'
        name='customDate'
        value={props.serviceSettings.customDate?.toLocaleString()}
    />
  const scheduleField = isOpenReferral
    ? <SelectWithLabel
        width='100%'
        label='Desired Schedule'
        name='desiredSchedule'
        onChange={handleChange}
        value={props.serviceSettings.desiredSchedule ?? ''}
        options={scheduleOptions}
    />
    : <TextWithLabel
        label='Desired Schedule'
        name='desiredSchedule'
        value={props.serviceSettings.desiredSchedule ?? ''}
    />
  const sessionBlockField = isOpenReferral
    ? <SelectWithLabel
        width='100%'
        label='Session Block'
        name='sessionBlockId'
        onChange={handleChange}
        value={props.serviceSettings.sessionBlockId ?? 0}
        options={sessionBlockOptions}
    />
    : <TextWithLabel
        label='Session Block'
        name='sessionBlockName'
        value={props.serviceSettings.sessionBlockName ?? ''}
    />
  const notesField = isOpenReferral
    ? <TextAreaWithLabel
        label='Notes'
        name='notes'
        onChange={handleChange}
        value={props.serviceSettings.notes ?? ''}
    />
    : <TextWithLabel
        label='Notes'
        name='notes'
        value={props.serviceSettings.notes ?? ''}
    />

  return <>
    {(props.serviceSettings.systemServiceProviderId === null || props.providerList != null) && (
      spField
    )}

    {props.serviceSettings.systemServiceProviderId !== null && (
      <SelectWithLabel
        name="serviceId"
        label="Service"
        width="100%"
        value={props.serviceList.find(s => s.id === props.serviceSettings.systemService?.id ?? '') != null ? props.serviceSettings.systemService?.id ?? '' : ''}
        options={props.serviceList}
        onChange={(e) => {
          handleServiceChange(e.target.value as string)
        }}
      />
    )}
    {props.serviceSettings.systemServiceProviderId !== null && (
      <SelectWithLabel
        name="programId"
        label="Program"
        width="100%"
        value={props.programList.find(p => p.id === props.serviceSettings.systemProgram?.id) != null ? props.serviceSettings.systemProgram?.id ?? '' : ''}
        options={props.programList}
        onChange={(e) => {
          handleProgramChange(e.target.value as string)
        }}
      />
    )}
    {props.showDuplicateWarning === true
      ? <Alert severity="warning" sx={{ border: 1, borderColor: 'orange' }}>
        This child already has a service for the selected program</Alert>
      : <></>}
    {isOpenReferral && (<>
      {props.serviceSettings.systemProgram?.id != null &&
        (props.requestTransfer ?? false) &&
        props.setTransferRequest != null
        ? <>
          {!(props.hasAccessRequest ?? false)
            ? <>
              <Typography>Request Transfer</Typography>
              <TransferServiceDataDisplay
                childId={props.childId ?? 0}
                referralId={0}
                setTransferRequestModel={props.setTransferRequest}
                systemServiceId={props.serviceSettings.systemService?.id ?? 0}
                transferRequest={props.transferRequest ?? {} as TransferReferralRequest}
                showReferTo={false}
              />
            </>
            : <>
              <Typography>A transfer request already exists for this service. Please contact your ISD for questions.</Typography>
            </>}
        </>
        : <>
          <Grid container spacing={1} columns={12}>
            <Grid item xs={6}>
              {facilityField}
            </Grid>
            <Grid item xs={6}>
              {timeFrameField}
            </Grid>
            {props.serviceSettings.timeFrame?.toString() === ReferralTimeFrame.SpecificDate.toString() && (
              <Grid item xs={6}>
                {specificDateField}
              </Grid>
            )}

            <Grid item xs={6}>
              {scheduleField}
            </Grid>
            <Grid item xs={6}>
              {showSelectSessionBlock && sessionBlockField}
            </Grid>
            <Grid item xs={12}>
              {notesField}
            </Grid>

          </Grid>
        </>}
    </>)}
    {!isOpenReferral && (
      <Box sx={{ align: 'center', width: '50%' }}><Chip icon={<FiberManualRecordIcon sx={{ color: '#4DA4A4 !important' }} />}
        label={props.serviceSettings.status} size="small" sx={{ color: '#4DA4A4 !important', backgroundColor: 'rgba(77, 164, 164, 0.1)' }}
      /></Box>
    )}
  </>
}
