import { useState, useEffect, useRef } from 'react'
import { CircularProgress } from '@mui/material'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { type ChildCaregiver, type ChildSiblings, type AccessControlModel, ChildAuditAccessTypeEnum, AccountType, type postError } from '../../../core/types'
import { toast } from 'react-toastify'
import { ScrollModalToTop } from '../../../core/Utilities'
import { usePermissions } from '../../../hooks/use-permissions'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { NotAllowed } from '../../../Routes/ProtectedRoute'
import { NetworkMembersDisplay } from '../../../Components/CaregiverDetails/NetworkMembersDisplay'

export interface CareNetworkProps {
  childID: number
  childName: string
}

export function NetworkMemberDetails (props: CareNetworkProps): JSX.Element {
  const account = useAccountId()
  const [loaded, setLoaded] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const permissions = usePermissions()
  const [canViewEligibilityFactors, setCanViewEligibilityFactors] = useState(true)
  const [childCaregivers, setChildCaregivers] = useState<ChildCaregiver[]>([])
  const [childSiblings, setChildSiblings] = useState<ChildSiblings[]>([])
  const [siblingError, setSiblingErrors] = useState<string[] | undefined>(undefined)
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [unauthorized, setUnauthorized] = useState(false)
  const captureIEP = useRef(false)
  const captureIFSP = useRef(false)
  const captureEOIS = useRef(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, success, status } = await sendGet(`/ChildDetails/Find?id=${props.childID}&accountId=${account?.id ?? 0}&accountType=${account?.type ?? ''}&requestingModule=${String(ChildAuditAccessTypeEnum.CareNetwork)}`, {})
      const details = response.response
      if (success) {
        setChildCaregivers(details.careGivers)
        setChildSiblings(details.childSiblings)
        setCanViewEligibilityFactors(details.canViewEligibilityFactors)
        captureIEP.current = details.captureIEP
        captureIFSP.current = details.captureIFSP
        captureEOIS.current = details.captureEOIS
      } else {
        if (status === 401) {
          setUnauthorized(true)
        }
      }
      setLoaded(true)
    }

    if (permissions.CareNetwork_NetworkMembers || permissions.ServiceProviders) {
      void fetchData()
    }
  }, [refreshTime, permissions.CareNetwork_NetworkMembers])

  const handleCaregiverDelete = async (id: number): Promise<void> => {
    const url = '/CareNetworkEdit/DeleteCaregiver'
    const { error } = await sendPost(url, { id })
    if (error[0] === '') {
      toast.success('Caregiver was deleted successfully')
      setRefreshTime(new Date())
    }
  }

  const searchCaregiver = async (id: number): Promise<ChildCaregiver | undefined> => {
    if (id === 0) return
    const url = `/CareGiverSearch/Search?childId=${props.childID}&id=${id}`
    const { response, error } = await sendGet(url, {})

    if (error[0] === '') {
      if (response.id > 0) {
        const _careGiver = childCaregivers.find(c => c.id === id)
        response.isLivingWithStudent = _careGiver?.isLivingWithStudent
        response.childID = props.childID
        return response
      }
    }
    return undefined
  }

  const handleCaregiverSubmit = async (caregiverData: ChildCaregiver): Promise<boolean> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      let url = '/CareNetworkEdit/NewCaregiverEntry'
      if (caregiverData.id > 0) { url = '/CareNetworkEdit/UpdateCaregiver' }
      const { response } = await sendPost(url, caregiverData)
      const rsp: { response: string, errors: string[], success: boolean } = response
      if (rsp.success) {
        if (caregiverData.id === 0) {
          const _caregiversList = [...childCaregivers]
          _caregiversList.push(caregiverData)
          setChildCaregivers(_caregiversList)
        }
        toast.success('Caregiver was updated successfully')
        setRefreshTime(new Date())
      } else {
        rsp.errors.map(e => toast.error(e))
      }
      setIsRequestLoading(false)
      return rsp.success
    }
    return false
  }

  const handleSiblingSave = async (siblingRequest: ChildSiblings): Promise<boolean> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      let result: { response: { errors: postError[], message: string, success: boolean, newRecordID: number }, error: string[], success: boolean } | null = null
      if (siblingRequest.id === 0 && account?.type === AccountType.ISD) {
        siblingRequest.isdID = account?.id
      }
      if (siblingRequest.id === 0) {
        result = await sendPost('/CareNetworkEdit/NewChildEntry', siblingRequest)
      } else {
        result = await sendPost('/ChildDetails/Update', siblingRequest)
      }
      const isInvalid = isNaN(Number(result.response.newRecordID))
      const isFailure = isInvalid || !result.success || !result.response.success
      if (isFailure) {
        ScrollModalToTop()
        setSiblingErrors(result.response.errors.map(e => e.error))
      } else {
        setRefreshTime(new Date())
      }
      setIsRequestLoading(false)
      return !isFailure
    }
    return false
  }

  const handleSiblingDelete = async (id: number): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)
      const response = await sendPost('/CareNetworkEdit/DeleteSibling', { childID: props.childID, siblingID: id })
      const rsp: { response: string, error: string[], success: boolean } = response
      if (rsp.success) {
        setRefreshTime(new Date())
      }
      setIsRequestLoading(false)
    }
  }

  const saveAccessControl = async (accessControl: AccessControlModel): Promise<void> => {
    if (accessControl !== undefined) {
      await sendPost('/CareNetworkEdit/SaveCareGiverAccessControl', accessControl)
    }
  }

  if (!loaded) return <CircularProgress role='loading' />
  if (unauthorized) return <NotAllowed />

  return <NetworkMembersDisplay
    childID={props.childID}
    childName={props.childName}
    captureIEP={captureIEP.current}
    captureIFSP={captureIFSP.current}
    captureEOIS={captureEOIS.current}
    canViewEligibilityFactors={canViewEligibilityFactors}
    canAddMembers={permissions.CareNetwork_NetworkMembers_Edit}
    onCaregiverEdit={searchCaregiver}
    setChildCaregivers={setChildCaregivers}
    saveAccessControl={saveAccessControl}
    childCaregivers={childCaregivers}
    onAddCaregiver={handleCaregiverSubmit}
    onCaregiverDelete={handleCaregiverDelete}
    childSiblings={childSiblings}
    onSiblingSave={handleSiblingSave}
    onDeleteSibling={handleSiblingDelete}
    siblingErrors={siblingError}

  />
}
