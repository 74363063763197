import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import { TextFieldWithLabel } from '../../Components/TextField'
import { Autocomplete } from '../../Components/AutoComplete'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { useNavigate, generatePath } from 'react-router'
import { AdvancedSearch } from '../../Components/Search/AdvancedSearch'
import { CheckBoxWithLabel } from '../../Components/CheckBox'
import * as AppRoutes from '../Routes'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { AddChildModal } from '../Referral/AddChildModal/AddChildModal'
import { toast } from 'react-toastify'
import { DatePickerWithLabel } from '../../Components/DatePicker'
import { AccountType } from '../../core/types'

export interface CareNetworkSearchCriteria {
  isd: string
  inactive: boolean | undefined
  name: string
  caregiverName: string
  caregiverPhone: string
  caregiverEmail: string
  currentProvider: string
  intakeProvider: string
  service: string
  onlyNew: boolean | undefined
  noProvider: boolean | undefined
  showInformationalOnly: boolean | undefined
  birthDate: Date | null
  showStep4Only: boolean | undefined
}

export function CareNetworkSearch (props: { current: CareNetworkSearchCriteria, onChange: (newSearch: CareNetworkSearchCriteria) => void }): JSX.Element {
  const nav = useNavigate()
  const account = useAccountId()
  const [showAdvanced, setShowAdvanced] = useState(false)

  const onClear = (): void => {
    props.onChange({ ...props.current, isd: '', caregiverName: '', caregiverPhone: '', caregiverEmail: '', currentProvider: '', intakeProvider: '', noProvider: false, showInformationalOnly: false, birthDate: null, showStep4Only: false })
  }

  const advCount = (): number => {
    let count = 0
    if (props.current.caregiverName !== '') count++
    if (props.current.caregiverPhone !== '') count++
    if (props.current.caregiverEmail !== '') count++
    if (props.current.isd !== '') count++
    if (props.current.currentProvider !== '') count++
    if (props.current.intakeProvider !== '') count++
    if (props.current.noProvider === true) count++
    if (props.current.service !== '') count++
    return count
  }

  const advancedSearchCount = advCount()

  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }

  const handleDateChange = (value: Date | null): void => {
    props.onChange({ ...props.current, birthDate: value })
  }

  const acUpdate = (source: string, e: any): void => {
    if ((e.currentTarget?.value?.toString() ?? '') !== '') {
      props.onChange({ ...props.current, [source]: e.currentTarget.value?.toString() ?? '' })
    } else {
      setConfirmation(true)
    }
  }

  const handleClose = (): void => {
    setConfirmation(false)
  }

  const handleConfirm = (): void => {
    props.onChange({ ...props.current, isd: '' })
    setConfirmation(false)
  }

  const dropdownChange = (field: string, value: {
    id: string | number
    name: string
  } | null): void => {
    props.onChange({ ...props.current, [field]: value?.id?.toString() ?? '' })
  }

  const handleCheckChanged = (newVal: boolean, field: string): void => {
    props.onChange({ ...props.current, [field]: newVal })
  }

  const addClick = (): void => {
    setOpenAddChild(true)
  }

  const [isds, setIsds] = useState<Array<{ id: number, name: string }>>([])
  const [serviceProviders, setServiceProviders] = useState<Array<{ id: number, name: string }>>([])
  const [intakeProviders, setIntakeProviders] = useState<Array<{ id: number, name: string }>>([])
  const [services, setServices] = useState<Array<{ id: number, name: string }>>([])
  const [loaded, setLoaded] = useState(false)
  const [openConfirmation, setConfirmation] = useState(false)
  const [openAddChild, setOpenAddChild] = useState(false)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/ISD/GetLookup', {})
      if (error[0] === '' && rsp !== null) { setIsds(rsp) }

      let isdID = 0
      if (account?.type === AccountType.ISD) {
        isdID = account.id
      } else {
        const { response: isdRsp } = await sendGet('/ChildDetails/GetISDFromUser', {})
        isdID = isdRsp
      }

      const { response: apRsp, error: apError } = await sendGet(`/CareGiverSearch/GetAllProviders?isdid=${isdID}`, {})
      if (apError[0] === '' && apRsp !== null) {
        setServiceProviders(apRsp)
      }

      const { response: ipRsp, error: ipError } = await sendGet('/CareGiverSearch/GetIntakeProviders', {})
      if (ipError[0] === '' && ipRsp !== null) {
        setIntakeProviders(ipRsp)
      }

      const { response: serviceResp, error: serviceError } = await sendGet(`/CareGiverSearch/GetServices?isdID=${isdID}`, {})
      if (serviceError[0] === '' && serviceResp !== null) {
        setServices(serviceResp)
      }

      setLoaded(true)
    }
    void loadData()
  }, [])

  if (!loaded) return <CircularProgress role='loading' />

  const advancedSearch = <Box sx={{ alignItems: 'center', p: '4px 0px' }}>
    <Grid container columns={12} spacing={1} rowSpacing={-1}>
      <Grid item sm={12} md={6} lg={4}>
        <TextFieldWithLabel
          label='Caregiver Name'
          onChange={handleChange}
          name='caregiverName'
          value={props.current.caregiverName}
          data-test-caregiver-name-search
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <TextFieldWithLabel
          label='Caregiver Phone'
          onChange={handleChange}
          name='caregiverPhone'
          value={props.current.caregiverPhone}
          data-test-caregiver-contact-search
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <TextFieldWithLabel
          label='Caregiver Email'
          onChange={handleChange}
          name='caregiverEmail'
          value={props.current.caregiverEmail}
          data-test-caregiver-contact-search
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Autocomplete
          width='100%'
          label='ISD'
          name='isdSearch'
          onChange={(e: any) => { acUpdate('isd', e) }}
          options={isds}
          value={props.current.isd}
          data-test-isd-search
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Autocomplete
          width='100%'
          label='Current Provider'
          name='currentProvider'
          onChange={(e, value) => { dropdownChange('currentProvider', value) }}
          options={serviceProviders}
          value={props.current.currentProvider}
          data-test-current-provider-search
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Autocomplete
          label='Intake Provider'
          width='100%'
          name='intakeProvider'
          onChange={(e, value) => { dropdownChange('intakeProvider', value) }}
          options={intakeProviders}
          value={props.current.intakeProvider}
          data-test-intake-provider-search
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Autocomplete
          label='Service'
          width='100%'
          name='service'
          onChange={(e, value) => { dropdownChange('service', value) }}
          options={services}
          value={props.current.service}
          data-test-service-search
        />
      </Grid>

      <Grid item sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
        <Box>
          <DatePickerWithLabel
            value={props.current.birthDate === null ? null as any : props.current.birthDate }
            onChange={(date: Date | null) => { handleDateChange(date) }}
            name={'birthDate'}
            label={'Date of Birth'}
            required={false}
            FormControlProps={{ className: 'width-175px' }}
          />
        </Box>
        <Box sx={{ alignSelf: 'center' }}>
          <CheckBoxWithLabel
            edge='start'
            label='Show Step 4 Only'
            name='showStep4Only'
            setValue={(val) => { handleCheckChanged(val, 'showStep4Only') }}
            value={props.current.showStep4Only ?? false}
          />
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={{ alignItems: 'center', display: 'flex' }}>
        <CheckBoxWithLabel
          edge='start'
          label='No Provider Assigned'
          name='noProvider'
          setValue={(val) => { handleCheckChanged(val, 'noProvider') }}
          value={props.current.noProvider ?? false}
        />
        <CheckBoxWithLabel
          edge='start'
          label='Show Informational Only Children'
          name='showInformationalOnly'
          setValue={(val) => { handleCheckChanged(val, 'showInformationalOnly') }}
          value={props.current.showInformationalOnly ?? false}
        />
      </Grid>
    </Grid>
  </Box>

  const mainSearch = (<Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
    <TextFieldWithLabel
      label='Child Name'
      onChange={handleChange}
      name='name'
      value={props.current.name}
      data-test-name-search
    />
    <CheckBoxWithLabel
      edge='start'
      label='Show Only New'
      name='onlyNew'
      setValue={(val) => { handleCheckChanged(val, 'onlyNew') }}
      value={props.current.onlyNew ?? false}
    />
  </Box>
  )
  const content = <AdvancedSearch
    advancedSearchCount={advancedSearchCount}
    advancedSearchItems={advancedSearch}
    displayAdvancedSearch={showAdvanced}
    mainSearchItems={mainSearch}
    toggleSearch={setShowAdvanced}
    onClear={onClear}
    extraItems={<Box>
      <Box >
        <Button color='secondary' variant='contained' onClick={addClick}>Add Child</Button>
      </Box>

      <Dialog
        open={openConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Performing a state-wide search can result in long load times. Continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <AddChildModal open={openAddChild} onClose={() => { setOpenAddChild(false) } }
        endpoint='ChildDetails'
        serviceProviderEndpoint='ChildDetails'
        canSkipService={true}
        onSubmit={(childID: number, referralID: number) => {
          if (childID > 0) {
            toast.success('Child record created.')
            setOpenAddChild(false)
            const redirect = generatePath(AppRoutes.CARENETWORK_MANAGEMENT_DETAILS, { id: childID.toString() })
            nav(redirect)
          } else {
            toast.error('An error occurred while creating the child.')
          }
        }}
      />
    </Box>}
  />

  return content
}
