import { Box, Button } from '@mui/material'
import { RemovableSelect } from '.'
import { LabelWithToolTip } from '../Label/Index'
import { ErrorDisplay } from '../../ContextProviders/ErrorProvider'
import { CheckBoxWithLabel } from '../CheckBox'
import { useState } from 'react'

interface MultiselectProps {
  itemList: Array<{ id: number, value: number | null }>
  setItemList: (newValues: Array<{ id: number, value: number | null }>) => void
  itemIndex: number
  setItemIndex: (newCount: number) => void
  options: Array<{ id: number, name: string }>
  label: string
  toolTip?: string
  minLength?: number
  name?: string
  hasDefaultOption?: boolean
  defaultValue?: number | null
  setDefaultValue?: (value: number | null) => void
}

export function Multiselect (props: MultiselectProps): JSX.Element {
  const minlen = props.minLength ?? 1

  const onDeleteClick = (id: number): void => {
    const tempArray = [...props.itemList]
    tempArray.splice(tempArray.findIndex(v => v.id === id), 1)
    props.setItemList([...tempArray])
  }

  const updateSelection = (e: any, newval: any, id: number): void => {
    const tempArray = [...props.itemList]
    const item = tempArray.find(i => i.id === id)
    if (item !== undefined) {
      item.value = newval?.id
      props.setItemList(tempArray)
    }
  }

  const addSection = (): void => {
    props.setItemList([...props.itemList, { id: props.itemIndex, value: null }])
    props.setItemIndex(props.itemIndex + 1)
  }

  const [selectedDefault, setSelectedDefault] = useState<number | null>(props.defaultValue ?? null)

  return (
    <Box>
      <ErrorDisplay fieldName={props.name ?? ''} />

      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          labelText={props.label}
          toolTipText={props.toolTip}
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />

        {
          props.itemList.map((val) => {
            return <Box key={val.id?.toString() + (val.value?.toString() ?? '')}>
              <RemovableSelect
                onChange={(e: any, newVal: any) => { updateSelection(e, newVal, val.id) }}
                name={(props.name ?? '') + val.id?.toString()}
                options={props.options}
                value={val.value}
                hideDeleteBtn={props.itemList.length === minlen}
                onDeleteBtnClick={e => { onDeleteClick(val.id) }}
              />
              {props.hasDefaultOption != null && props.hasDefaultOption && props.itemList.length > 1 && <div className={'default-check'}>
                <CheckBoxWithLabel label={'Default'} value={selectedDefault !== null && selectedDefault === val.value} name={''} dataTestId={'defaultCheck'} setValue={function (newVal: boolean): void {
                  if (newVal) {
                    setSelectedDefault(val.value)
                    if (props.setDefaultValue !== undefined) {
                      props.setDefaultValue(val.value)
                    }
                  } else {
                    setSelectedDefault(null)
                    if (props.setDefaultValue !== undefined) {
                      props.setDefaultValue(null)
                    }
                  }
                }} edge={'start'}
                />
              </div>}
            </Box>
          })
        }
      </Box>
      <div className="d-flex f-justify-content-center">
        <Button size="small" variant="text" sx={{ color: '#595959', textTransform: 'none' }}
          data-testid={(props.name ?? '') + 'AddButton'}
          onClick={e => { addSection() }}
        >
          Add {props.label} +
        </Button>
      </div>
    </Box>
  )
}
