import { Box, Typography } from '@mui/material'
import { type location } from '../../../core/types'
import { CheckBoxWithLabel } from '../../CheckBox'

interface LocationDisplayProps {
  location: location
  isPrimary?: boolean | undefined
  isHomeless?: boolean | undefined
}

export function LocationDisplay (props: LocationDisplayProps): JSX.Element {
  return <Box>
    <Typography variant='body1' fontWeight={'bold'} data-testid='addressLine1'>
      {props.location.address} {props.location.apartment}
    </Typography>
    <Typography variant='body2' data-testid='addressLine2'>
      {props.location?.city} {props.location?.state}
    </Typography>
    <Typography variant='body2' data-testid='zipCode'>
      {props.location?.zipCode}
    </Typography>
    {(props.isPrimary ?? false) && (<Box>
      <CheckBoxWithLabel disabled edge='start' label='Is Primary' name='primary' setValue={() => {}} value={props.isPrimary ?? false} />
    </Box>
    )}
    {(props.isHomeless ?? false) && (<Box>
      <CheckBoxWithLabel disabled edge='start' label='Currently Experiencing Homelessness' name='homeless' setValue={() => { }} value={props.isHomeless ?? false} />
    </Box>
    )}
  </Box>
}
