import { type EnrollmentDetails } from '../../../../core/types'
import { ServiceDateTypeSelection } from './ServiceDateTypeSelection'
import { ServiceSearchTypeSelection } from './ServiceSearchTypeSelection'
import { ServiceSelection } from './ServiceSelection'

export interface ServiceSelectionStepProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function ServiceSelectionStep (props: ServiceSelectionStepProps): JSX.Element {
  const caregiverForm = props.caregiverForm

  if (caregiverForm.currentSubStep === 1) {
    return <ServiceSelection caregiverForm={caregiverForm} handleStep={props.handleStep} />
  } else if (caregiverForm.currentSubStep === 2) {
    return <ServiceDateTypeSelection caregiverForm={caregiverForm} />
  } else if (caregiverForm.currentSubStep === 3) {
    return <ServiceSearchTypeSelection caregiverForm={caregiverForm} handleStep={props.handleStep} />
  }

  return <></>
}
