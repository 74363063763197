import { Box, Typography } from '@mui/material'
import { BlockButton } from '../../../Components/Button/BlockButton'
import { sendGet } from '../../../hooks/use-fetch'
import { type service } from '../../../core/types'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { ENROLLMENT_MANAGEMENT_DETAILS } from '../../Routes'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import S3Image from '../../../Components/S3Image'
export function EnrollmentSplashPage (): JSX.Element {
  const activeAccount = useAccountId()

  const [services, setServices] = useState<service[]>([])
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response } = await sendGet(`/ServiceProviderDetails/GetProviderServices?id=${activeAccount?.id ?? 0}`, {})
      if (response !== undefined && response.length > 0) {
        setServices(response)
      }
    }
    void fetchData()
  }, [])

  const serviceClick = (serviceId: number): void => {
    const editEnrollment = generatePath(ENROLLMENT_MANAGEMENT_DETAILS, {
      serviceId: serviceId.toString()
    })
    nav(editEnrollment)
  }

  return (<>
    <Typography variant="h3" data-test-page-header>Enrollments</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', marginTop: '20px' }} data-testid='buttonBox'>
      {services.map((s) => (
        <Box sx={{ marginRight: '20px' }} key={s.id}>
          <BlockButton key={s.id}
            data-testid="raw"
            text={`${s.name}`}
            onClick={() => { serviceClick(s.id) }}
            icon={s.s3Key !== null ? <S3Image imageKey={s.s3Key} /> : <></>}
            backgroundColor='rgba(241, 235, 248, 1)'
          />
        </Box>
      ))}
      {services.length === 0 && <Typography variant="h5">No services are configured</Typography>}
    </Box></>
  )
}
