import { Box, Grid } from '@mui/material'
import { OnOffSwitch } from '../Switch/OnOffSwitch'
import { TimePickerWithLabel } from '../TimePicker'
import { type businessHours } from '../../core/types'

export interface BusinessHourProps {
  date: string
  facilityHours: businessHours | undefined
  dayOfWeek: string
  onEnabledChange: (date: string, enabled: boolean) => void
  onTimeChange: (date: string, field: string, value: Date | null) => void
  onTimeAccept: (date: string, field: string, value: Date | null) => void
}

export function BusinessHourPicker (props: BusinessHourProps): JSX.Element {
  const startDate = new Date()
  const endDate = new Date()

  if (props.facilityHours !== undefined) {
    if (props.facilityHours.openingTime !== '') {
      const parts = props.facilityHours.openingTime?.split(':') ?? []
      if (parts.length === 3) { startDate.setHours(parseInt(parts[0]), parseInt(parts[1]), parseInt(parts[2])) }
    }
    if (props.facilityHours.closingTime !== '') {
      const parts = props.facilityHours.closingTime?.split(':') ?? []
      if (parts.length === 3) { endDate.setHours(parseInt(parts[0]), parseInt(parts[1]), parseInt(parts[2])) }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    props.onEnabledChange(props.dayOfWeek, checked)
  }

  const handleTimeChange = (field: string, newVal: Date | null): void => {
    props.onTimeChange(props.dayOfWeek, field, newVal)
  }

  const handleTimeAccept = (field: string, newVal: Date | null): void => {
    props.onTimeAccept(props.dayOfWeek, field, newVal)
  }

  const isDefined = props.facilityHours !== undefined

  return <Box>
    <Grid container alignItems={'center'} height={'56px'}>
      <Grid item xs={2}>{props.date}</Grid>
      <Grid item xs={2}><OnOffSwitch value={isDefined} checked={isDefined} onChange={handleChange}/></Grid>

      {isDefined && (<>
        <Grid item xs={4}>
          <TimePickerWithLabel
            dataTestId={`${props.date}Start`}
            label=''
            name='openingTime'
            onChange={(d) => { handleTimeChange('openingTime', d) }}
            onAccept={(d) => { handleTimeAccept('openingTime', d) }}
            value={startDate}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePickerWithLabel
            dataTestId={`${props.date}End`}
            label=''
            name='closingTime'
            onChange={(d) => { handleTimeChange('closingTime', d) }}
            onAccept={(d) => { handleTimeAccept('closingTime', d) }}
            value={endDate}
          />
        </Grid>
      </>)}
    </Grid>
  </Box>
}
