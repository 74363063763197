import { Container, Typography } from '@mui/material'
import Input from '../../../Components/TextArea/input'

export interface InterestFormEditableTextProps {
  header: string
  labelKey: string
  defaultValue: string
  newValue: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function InterestFormEditableText (props: InterestFormEditableTextProps): JSX.Element {
  return <>
    <Typography variant='h6' sx={{ mt: '10px' }}>{props.header}</Typography>
    <Container>
      <Typography variant='body1' sx={{ mt: '10px' }}>{props.defaultValue}</Typography>
      <Typography variant='body1' sx={{ fontWeight: '600', mt: '10px' }}>Replace with Text</Typography>
      <Input
        multiline
        name={props.labelKey}
        value={props.newValue}
        onChange={props.onChange}
      />
    </Container>
  </>
}
