import { type ChangeEvent, useEffect, useState, useRef } from 'react'
import { type postError, type lookup } from '../../../core/types'
import { Box, type SelectChangeEvent } from '@mui/material'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { Modal } from '../../../Components/Modal'
import { type CaregiverIncome } from './CaregiverIncomeList'

export interface IncomeEditProps {
  open: boolean
  onClose: () => void
  onSubmit: (incomeData: CaregiverIncome, isNew: boolean) => void
  errors?: postError[]
  income: CaregiverIncome | null
}

export function IncomeEdit (props: IncomeEditProps): JSX.Element {
  const blankIncome: CaregiverIncome = {
    id: null,
    caregiverID: 0,
    year: 0,
    annualIncome: null,
    memberCount: null
  }
  const isNew = useRef(props.income == null)
  const [income, setIncome] = useState<CaregiverIncome>(props.income ?? blankIncome)
  const [dateRanges, setDateRanges] = useState<lookup[]>([])

  useEffect(() => {
    const yearOptions: lookup[] = []
    const currentYear = new Date().getFullYear()
    for (let i = currentYear - 5; i <= currentYear + 1; i++) {
      yearOptions.push({ id: i, name: i.toString() })
    }
    setDateRanges(yearOptions)
  }, [])

  const handleNumberChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    let value = e.target.value
    value = value.replace(',', '').replace('$', '')
    if (!isNaN(Number(value))) {
      setIncome({ ...income, [e.target.name]: value })
    }
  }

  const handleSelectChange = async (e: SelectChangeEvent<number>): Promise<void> => {
    setIncome({ ...income, [e.target.name]: e.target.value })
  }

  const handleClose = (): void => {
    props.onClose()
  }

  const handleSubmit = (): void => {
    props.onSubmit(income, isNew.current)
  }

  const content =
    <>
      {props.errors !== undefined && props.errors.length > 0 &&
        <ul>
          {props.errors.map((err) => (
            <li key={err.field} style={{ color: 'red', padding: '10px 0 0' }}>
              {err.error}
            </li>
          ))}
        </ul>
      }
      <Box>
        <TextFieldWithLabel
          label='Taxable Income'
          name='annualIncome'
          onChange={handleNumberChange}
          value={income.annualIncome}
          pattern='[0-9]*'
          dataTestId='annualIncome'
        />
      </Box>
      <Box>
        <SelectWithLabel
          width='100%'
          label='Tax Year'
          name='year'
          onChange={handleSelectChange}
          options={dateRanges}
          value={income.year}
        />
      </Box>
      <Box>
        <TextFieldWithLabel
          label='Number of Household Members'
          name='memberCount'
          onChange={handleNumberChange}
          value={income.memberCount}
          dataTestId='memberCount'
        />
      </Box>
    </>

  return <Modal
    open={props.open}
    titleContent={<div>{isNew.current ? 'New Income' : 'Edit Income'}  </div>}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
