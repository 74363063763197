import { Box, Grid } from '@mui/material'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { ChildEditLayout } from '../../CareNetworkManagement/CareNetworkDetail/ChildEditLayout'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { type ChildSearchResult, DuplicateList } from '../../../Components/ChildDetails/DuplicateList'
import { type lookup, type ChildEditValues } from '../../../core/types'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

export function AddChildBasicInfo (): JSX.Element {
  const account = useAccountId()
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()
  const [file, setFile] = useState<File | null>(null)

  const handleChange = (child: ChildEditValues): void => {
    dispatch({ type: 'child', child })
    const shouldCheck = child.firstName !== '' && child.lastName !== '' && child.dateOfBirth !== null &&
      child.uic !== null && child.birthGender !== ''
    setShowDuplicateWindow(shouldCheck)
  }

  const [languages, setLanguages] = useState<lookup[]>([])
  const [authStatuses, setAuthStatuses] = useState<lookup[]>([])
  const [showDuplicateWindow, setShowDuplicateWindow] = useState(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet('/Language/GetLookup?sort=true', {})
      if (error[0] === '') {
        setLanguages(response)
      }
      const { response: response2, error: error2 } = await sendGet('/AuthorizationStatus/GetLookup', {})
      if (error2[0] === '') {
        setAuthStatuses(response2)
      }
    }
    void fetchData()
  }, [])

  const onDuplicateSelected = async (data: ChildSearchResult): Promise<void> => {
    const { response, success } = await sendGet(`/ChildDetails/Find?id=${data.id}&accountId=${account?.id ?? 0}&accountType=${account?.type ?? ''}`, {})
    if (success) {
      const details = response.response
      const child = { ...details, image: details.imageKey ?? '' }
      dispatch({ type: 'loadDuplicate', child, data })
    } else {
      const errors: string[] = response.errors
      errors.forEach(e => toast.error(e))
    }
  }

  return <Box width={'100%'}>
    <ChildEditLayout
      child={data.child}
      isUnborn={data.child.isUnborn}
      handleChange={handleChange}
      languages={languages}
      authStatuses={authStatuses}
      errors={data.errors.filter(e => e.group === 'Child')}
      file={file}
      setFile={setFile}
    />

    {showDuplicateWindow && (<Grid>
      <DuplicateList child={data.child} onLoadChildData={onDuplicateSelected} />
    </Grid>)}
  </Box>
}
