import { Modal } from '../../../Components/Modal'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'
import { SelectWithLabel, type SelectOption } from '../../../Components/SelectWithLabel'
import { type facilityRoom, FacilityStatus, type ProviderProgram } from '../../../core/types'
import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { ErrorDisplay } from '../../../ContextProviders/ErrorProvider'
import { toast } from 'react-toastify'

export interface FacilityRoomDetailProps {
  open: boolean
  selectedFacilityRoom: facilityRoom
  programs: ProviderProgram[]
  isNew: boolean
  onClose: () => void
  onSubmit: (facilityRoom: facilityRoom) => void
}

const statusOptions: Array<SelectOption<FacilityStatus>> = [
  { id: FacilityStatus.ACTIVE, name: FacilityStatus.ACTIVE },
  { id: FacilityStatus.INACTIVE, name: FacilityStatus.INACTIVE }
]

export function FacilityRoomDetail (props: FacilityRoomDetailProps): JSX.Element {
  const [facilityRoom, setFacilityRoom] = useState<facilityRoom>(props.selectedFacilityRoom)
  const [programs, setPrograms] = useState<ProviderProgram[]>(props.programs)

  useEffect(() => {
    if (facilityRoom.hasAttachedSessions) {
      toast.warning('This facility room has sessions associated with it. Changing any details regarding this room can have adverse effects.')
    }
    if (facilityRoom.noCapacityLimit) {
      setPrograms(programs.filter(p => p.noCapacityLimit))
    }
  }, [props.selectedFacilityRoom])

  const handleChange = (e: any): void => {
    setFacilityRoom({ ...facilityRoom, [e.target.name]: e.target.value })
  }

  const handleNoCapacityChange = (checked: boolean): void => {
    const tmpFacilityRoom = { ...facilityRoom, noCapacityLimit: checked }

    if (checked) {
      tmpFacilityRoom.capacity = null
      tmpFacilityRoom.systemPrograms = tmpFacilityRoom.systemPrograms.filter(p => p.noCapacityLimit)
      setPrograms(programs.filter(p => p.noCapacityLimit))
    } else {
      setPrograms(props.programs)
    }

    setFacilityRoom(tmpFacilityRoom)
  }

  const handleStatusChange = (e: any): void => {
    setFacilityRoom({ ...facilityRoom, inactive: e.target.value === FacilityStatus.INACTIVE })
  }

  const handleCheckBoxChange = (isChecked: boolean, program: ProviderProgram): void => {
    const programList = [...facilityRoom.systemPrograms]
    const programdetail = programList.findIndex(x => x.id === program.id)
    if (isChecked) {
      if (programdetail === -1) {
        programList.push(program)
      }
    } else {
      if (programdetail > -1) {
        programList.splice(programdetail, 1)
      }
    }
    setFacilityRoom({ ...facilityRoom, systemPrograms: programList })
  }

  const handleSubmit = (): void => {
    props.onSubmit(facilityRoom)
  }

  const content = <Box>
    <ErrorDisplay fieldName='' />

    <Box sx={{ display: 'flex' }}>
      <TextFieldWithLabel
        label='Name'
        name='name'
        onChange={handleChange}
        onBlur={handleChange}
        value={facilityRoom.name}
      />
      <SelectWithLabel
        label='Status'
        name='status'
        onChange={handleStatusChange}
        options={statusOptions}
        width='200px'
        leftMargin='15px'
        value={facilityRoom.inactive ? FacilityStatus.INACTIVE : FacilityStatus.ACTIVE}
      />
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextFieldWithLabel
        label='Capacity'
        name='capacity'
        onChange={handleChange}
        onBlur={handleChange}
        value={facilityRoom.capacity ?? ''}
        disabled={facilityRoom.noCapacityLimit}
      />
      {props.programs.some(p => p.noCapacityLimit) && <CheckBoxWithLabel
        name='noCapacityLimit'
        setValue={(isChecked: boolean) => { handleNoCapacityChange(isChecked) }}
        edge='start'
        label='No Capacity Limit'
        value={facilityRoom.noCapacityLimit}
      />}
    </Box>
    <Grid container columns={12}>
      {programs.map((p: ProviderProgram) => (
        <Grid item sm={6} key={p.id}>
          <CheckBoxWithLabel
            name='programs'
            setValue={(isChecked: boolean) => { handleCheckBoxChange(isChecked, p) }}
            edge='start'
            label={p.name}
            value={facilityRoom.systemPrograms.findIndex(x => x.id === p.id) > -1}
          />
        </Grid>
      ))}
    </Grid>
  </Box>

  const titleContent = <div className="d-flex f-align-items-center">
    <span style={{ marginRight: '15px' }}>Facility Room</span>
  </div>

  return <Modal
    open={props.open}
    titleContent={titleContent}
    confirmationContent={content}
    maxWidth='sm'
    onClose={props.onClose}
    onConfirm={handleSubmit}
  />
}
