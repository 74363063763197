import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { LabelWithToolTip } from '../Label/Index'

const RoundEdgeLinearProgress = styled(LinearProgress, { shouldForwardProp: prop => prop !== 'height' })<{ height: number }>(({ theme, height }) => ({
  height,
  borderRadius: height / 2,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: height / 2,
    backgroundColor: '#722a82'
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e7e7e7'
  }
}))

interface Props {
  totalSteps: number
  currentStep: number
  height?: number
}

export default function ProgressBar (props: Props): JSX.Element {
  const height = props?.height != null ? props.height : 10
  return (
    <div>
      <LabelWithToolTip
        labelText={`Step ${props.currentStep} of ${props.totalSteps}`}
      />
      <RoundEdgeLinearProgress
        aria-label='progress bar'
        height={height}
        variant="determinate"
        value={props.currentStep * 100 / props.totalSteps}
      />
    </div>
  )
}
