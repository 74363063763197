import { Slide } from '@mui/material'
import Box from '@mui/material/Box'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  backwards?: boolean
}

export const SlideTab = (props: TabPanelProps): JSX.Element => {
  const { children, value, index } = props
  const isIn = value === index
  let direction: 'left' | 'right' = 'left'
  if (props.backwards ?? false) {
    direction = isIn ? 'right' : 'left'
  } else {
    direction = isIn ? 'left' : 'right'
  }
  return (<Slide direction={direction} in={value === index} mountOnEnter unmountOnExit>
    <Box width={ '100%' } >{children}</Box>
  </Slide>
  )
}
