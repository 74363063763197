import { Box, CircularProgress } from '@mui/material'
import DataTable from '../../Components/Table/DataTable'
import { type SearchProps, useSearch } from '../../hooks/use-search'
import { Column, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useState } from 'react'
import { sendPut } from '../../hooks/use-fetch'
import { ExportPeriodDetails } from './MsdsExportPeriodDetails'
import { type exportPeriod } from '../../core/types'

const nameColumn = new Column('name', 'Name')
const codeColumn = new Column('code', 'Code')
const periodStartColumn = new Column('periodStart', 'Start')
const periodEndColumn = new Column('periodEnd', 'End')
const collectionColumn = new Column('collectionID', 'Collection ID')
const majorColumn = new Column('majorVersion', 'Major Version')
const minorColumn = new Column('minorVersion', 'Minor Version')
const columns = [nameColumn, codeColumn, periodStartColumn, periodEndColumn, collectionColumn, majorColumn, minorColumn]

export function ExportPeriodList (): JSX.Element {
  const [searchData] = useState({ })
  const [refreshTime, setRefreshTime] = useState(new Date())
  const searchType: SearchProps = { endPoint: 'ExportPeriod', searchData, refreshTime, autoUpdateOnSearchDataChange: true }
  const { rows, totalRecords, loading, onPageChange, onRowsPerPageChange, onSortChange } = useSearch(searchType)
  const [open, setOpen] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [page, setPage] = useState(0)
  const initialExportPeriod: exportPeriod = {
    code: '',
    collectionID: 0,
    id: 0,
    majorVersion: '',
    minorVersion: '',
    name: '',
    periodEnd: '',
    periodStart: ''
  }
  const [selectedPeriod, setSelectedPeriod] = useState(initialExportPeriod)
  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setOpen(true)
    const period: exportPeriod = {
      id: row.id,
      code: row.code,
      collectionID: row.collectionID,
      majorVersion: row.majorVersion,
      minorVersion: row.minorVersion,
      name: row.name,
      periodEnd: row.periodEnd,
      periodStart: row.periodStart
    }

    setSelectedPeriod(period)
  }

  const handleSubmit = async (periodDetails: exportPeriod): Promise<void> => {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      setErrors(undefined)
      let err: string[] | null = null
      let suc = false
      const url = '/ExportPeriod/Update'

      const { error, success } = await sendPut(url, periodDetails)
      err = error
      suc = success
      let errors: string = ''
      if (suc) {
        setOpen(false)
        setRefreshTime(new Date())
      } else {
        errors = err?.toString() ?? ''
        setErrors([errors])
      }
      setIsRequestLoading(false)
    }
  }

  if (loading) return <CircularProgress />
  return <Box>
    {/* <Box justifyContent='flex-end' display='flex' width='100%' >
      <Button color='secondary' variant='contained' onClick={addClick}>Add School Year</Button>
    </Box> */}

    <DataTable
      page={page}

      data-test-table
      name='exportYearTable'
      hasCheckbox={false}
      columns={columns}
      rows={rows ?? []}
      totalRecords={totalRecords}
      loading={loading}
      onSortChange={onSortChange}
      onPageChange={(e, p) => {
        onPageChange(e, p)
        setPage(p)
      }}
      onRowsPerPageChange={onRowsPerPageChange}
      onRowClick={rowClick}
    />
    {open && (
      <ExportPeriodDetails
        onClose={() => { setOpen(false) }}
        onSubmit={handleSubmit}
        errors={errors}
        open={open}
        exportPeriod={selectedPeriod}
      />
    )}
  </Box>
}
