import { Autocomplete, type AutocompleteChangeReason, TextField, type AutocompleteRenderInputParams, type AutocompleteChangeDetails, type SxProps } from '@mui/material'
import { LabelWithToolTip } from '../Label/Index'

export function GroupedAutoComplete (props: {
  value: any
  groupBy: ((option: any) => string) | undefined
  getOptionLabel: ((option: any) => string) | undefined
  onChange: ((event: React.SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void) | undefined
  disabled: boolean | undefined
  sx: SxProps
  label: string
  name: string
  options: any[]
  id: string
}): JSX.Element {
  return <Autocomplete
    id={props.id}
    data-testid={props.id}
    options={props.options}
    value={props.value}
    groupBy={props.groupBy}
    getOptionLabel={props.getOptionLabel}
    onChange={props.onChange}
    sx={props.sx}
    disabled={props.disabled}
    renderInput={(params) => <InternalAutoCompleteTextField params={params} label={props.label} name={props.name} />}
  />
}
function InternalAutoCompleteTextField (props: { params: AutocompleteRenderInputParams, label: string, toolTip?: string, name: string, maxWidth?: string }): JSX.Element {
  return <>
    <LabelWithToolTip
      isInline={false}
      required={false}
      maxWidth={props.maxWidth}
      name={props.name}
      labelText={props.label}
      toolTipText={props.toolTip}
    />
    <TextField {...props.params} hiddenLabel={true} />
  </>
}
/*

*/
