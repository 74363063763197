import { Grid, Box, Link } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { SwitchWithLabel } from '../../Components/Switch/SwitchWithLabel'
import { type ISD } from '../../core/types'
import { useState } from 'react'
import { InterestFormLanguageModal } from './InterestFormLanguageCustomization/InterestFormLanguageModal'
import { TextFieldWithLabel } from '../../Components/TextField'

interface ConfigurationEditProps {
  onClose: () => void
  show: boolean
  isdSettings: ISD
  onConfirm: (newIsdSettings: ISD) => void
}

export function ConfigurationEdit (props: ConfigurationEditProps): JSX.Element {
  const [isdSettings, setIsdSettings] = useState<ISD>(props.isdSettings)
  const [interestFormModalVisible, setInterestFormModalVisible] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setIsdSettings({ ...isdSettings, [event.target.name]: checked })
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsdSettings({ ...isdSettings, [event.target.name]: event.target.value })
  }

  const display = <>
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Box>
            <TextWithLabel label='Language Customization' value='' name='languageCustomization' toolTip='Customize interest form language' />
            <Link
              style={{ cursor: 'pointer' }}
              data-testid="languageLink"
              key={'languageLink'}
              underline="hover"
              onClick={() => { setInterestFormModalVisible(true) }}
            >
              Click here to customize the interest form language
            </Link>
          </Box>
          <SwitchWithLabel
            onChange={handleChange}
            label='Capture IFSP'
            name='captureIFSP'
            checked={isdSettings.captureIFSP}
            toolTip='Capture ISFP'
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Capture IEP'
            name='captureIEP'
            checked={isdSettings.captureIEP}
            toolTip='Capture IEP'
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Capture EOIS'
            name='captureEOIS'
            checked={isdSettings.captureEOIS}
            toolTip='Capture EOIS'
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Show results from other counties than the resident ISD'
            name='showResultsFromOtherCounties'
            checked={isdSettings.showResultsFromOtherCounties}
            toolTip='Show results from other counties than the resident ISD'
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Show Services for My Child'
            name='showServicesForMyChild'
            checked={isdSettings.showServicesForMyChild}
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Show Services for Myself'
            name='showServicesForMyself'
            checked={isdSettings.showServicesForMyself}
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Show Create a Referral'
            name='showCreateReferral'
            checked={isdSettings.showCreateReferral}
          />
          <TextFieldWithLabel
            onChange={handleTextChange}
            label='Adult Services Name'
            name='adultServicesName'
            value={isdSettings.adultServicesName ?? ''}
          />
          <TextFieldWithLabel
            onChange={handleTextChange}
            label='Adult Services Link'
            name='adultServicesLink'
            value={isdSettings.adultServicesLink ?? ''}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <SwitchWithLabel
            onChange={handleChange}
            label='Auto refer to Intake Provider regardless of selected providers'
            name='referAllToIntakeProvider'
            checked={isdSettings.referAllToIntakeProvider}
            toolTip='Auto refer to Intake Provider regardless of selected providers'
          />
          <SwitchWithLabel
            onChange={handleChange}
            label='Allow provider to select new provider on referral decline'
            name='selectProviderOnReferralDecline'
            checked={isdSettings.selectProviderOnReferralDecline}
            toolTip='Allow provider to select new provider on referral decline'
          />
        </Box>
      </Grid>
    </Grid>

    <InterestFormLanguageModal
      isdid={props.isdSettings.id}
      open={interestFormModalVisible}
      onClose={() => { setInterestFormModalVisible(false) }}
    />
  </>

  return (
    <Modal
      onClose={props.onClose}
      onConfirm={() => { props.onConfirm(isdSettings) }}
      open={props.show}
      confirmationContent={display}
    />
  )
}
