import { IconButton, MenuItem, Select, TextField } from '@mui/material'
import { type ageSetting } from '../../core/types'
import { OnOffSwitch } from '../../Components/Switch/OnOffSwitch'
import DeleteIcon from '@mui/icons-material/Delete'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { useState } from 'react'

interface ProgramAgeSettingProps {
  record: ageSetting
  showCalculationOptions: boolean
  onDelete: () => void
  onChange: (record: ageSetting) => void
}

export function ProgramAgeSetting (props: ProgramAgeSettingProps): JSX.Element {
  const selectOptions = Array<{ id: number, name: string }>({ id: 0, name: '0' }, { id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4+' })
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleChange = (e: any): void => {
    const values = { ...props.record, [e.target.name]: e.target.value }
    props.onChange(values)
  }

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const values = { ...props.record, [event.target.name]: checked }
    props.onChange(values)
  }

  const handleDelete = (): void => {
    setShowConfirmation(false)
    props.onDelete()
  }

  return <>
    <tr>
      <td>
        <Select
          name='allowForAge'
          value={props.record.allowForAge}
          sx={{ width: '100%' }}
          onChange={handleChange}
          required={true}
          variant="outlined"
        >
          {selectOptions.map((opt) => (
            <MenuItem key={opt.id.toString()} value={opt.id.toString()} role="option">
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </td>
      {props.showCalculationOptions && <>
        <td>
          <TextField
            name='fplIncomeThreshhold'
            type='number'
            sx={{ width: '100px' }}
            value={props.record.fplIncomeThreshhold}
            required={true}
            onChange={handleChange}
          />
        </td>
        <td>
          <OnOffSwitch
            name='requiresApproval'
            checked={props.record.requiresApproval ?? false}
            onChange={handleCheckChange}
          />
        </td>
        <td>
          <TextField
            name='maxEnrollmentAllocationPercent'
            type='number'
            sx={{ width: '100px' }}
            value={props.record.maxEnrollmentAllocationPercent ?? ''}
            required={false}
            onChange={handleChange}
          />
        </td>
      </>}
      <td>
        <IconButton onClick={() => { setShowConfirmation(true) }} >
          <DeleteIcon />
        </IconButton>
      </td>
    </tr >
    <BannerModal
      open={showConfirmation}
      onConfirm={handleDelete}
      cancelButtonText='Cancel'
      confirmButtonText='OK'
      onClose={() => { setShowConfirmation(false) }}
      title={'Confirmation'}
      hideXtoClose={true}
      content={<div>Are you sure you want to delete this record?</div>}
    />
  </>
}
