
import { Box, Button, CircularProgress } from '@mui/material'
import DataTable from '../../../Components/Table/DataTable'
import { Column, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { usePagination, type PaginationProps } from '../../../hooks/use-pagination'
import { toast } from 'react-toastify'
import { IncomeEdit } from './IncomeEdit'
import { useEffect, useState } from 'react'
import { sendGet, sendPost, sendPut } from '../../../hooks/use-fetch'
import { type postError } from '../../../core/types'

export interface CaregiverIncome {
  id: number | null
  caregiverID: number
  year: number
  annualIncome: number | null
  memberCount: number | null
}

export interface CaregiverIncomeListProps {
  caregiverID: number
}

export function CaregiverIncomeList (props: CaregiverIncomeListProps): JSX.Element {
  const [showEditModal, setShowEditModal] = useState(false)
  const [incomeRecords, setIncomeRecords] = useState<CaregiverIncome[]>([])
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [editRecord, setEditRecord] = useState<CaregiverIncome | null>(null)
  const [errors, setErrors] = useState<postError[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: incomeResponse } = await sendGet(`/CaregiverPortal/GetCaregiverIncome?caregiverID=${props.caregiverID}`, {})
      if (incomeResponse !== null) {
        setIncomeRecords(incomeResponse)
      }
      setLoading(false)
    }
    void loadData()
  }, [refreshTime])

  const paginationProps: PaginationProps<RowData> = {
    rows: incomeRecords
  }

  const pagination = usePagination(paginationProps)

  const yearColumn = new Column('year', 'Year')
  const incomeColumn = new Column('annualIncome', 'Income')
  const memberCountColumn = new Column('memberCount', 'Number of Household Members')
  const columns = [yearColumn, incomeColumn, memberCountColumn]

  const addClick = (): void => {
    setEditRecord(null)
    setShowEditModal(true)
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    if (row.incomeVerified as boolean) {
      toast.info('This income record has already been verified and cannot be edited.')
    } else {
      setEditRecord(row as CaregiverIncome)
      setShowEditModal(true)
    }
  }

  const handleAddressSubmit = async (incomeData: CaregiverIncome, isNew: boolean): Promise<void> => {
    setErrors([])
    let rsp: { errors: postError[], success: boolean, response: any }
    const url = `/CaregiverPortal/${isNew ? 'InsertIncome' : 'UpdateIncome'}`

    if (isNew) {
      incomeData.caregiverID = props.caregiverID
      const { response } = await sendPost(url, incomeData)
      rsp = response
    } else {
      const { response } = await sendPut(url, incomeData)
      rsp = response
    }

    if (rsp.success) {
      setShowEditModal(false)
      setRefreshTime(new Date())
      toast.success('Income record has been saved')
    } else {
      setErrors(rsp.errors)
    }
  }

  if (loading) return <CircularProgress />
  return <Box>
    <Box sx={{ marginTop: '10px' }}>
      <Button color='secondary' variant='contained' onClick={addClick} data-test-add-income>Add Annual Income Record</Button>
    </Box>

    <DataTable
      page={pagination.page}
      data-test-table
      name='dateRangeTable'
      hasCheckbox={false}
      columns={columns}
      loading={loading}
      rows={pagination.internalRows ?? []}
      totalRecords={pagination.recordCount}
      sortable={false}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      onRowClick={rowClick}
    />

    {showEditModal && (
      <IncomeEdit
        open={showEditModal}
        onSubmit={handleAddressSubmit}
        onClose={() => { setShowEditModal(false) }}
        income={editRecord}
        errors={errors}
      />
    )
    }
  </Box>
}
