import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface AlertMessageProps {
  message: string
  icon?: React.ReactElement<any, string>
  type: string
  dismissible?: boolean
}

const AlertMessage: React.FC<AlertMessageProps> = ({ message, icon, type, dismissible }) => {
  const [visible, setVisible] = React.useState(true)

  let backgroundColor = ''

  switch (type) {
    case 'warning':
      backgroundColor = '#DEBB32'
      break
    case 'error':
      backgroundColor = '#BC3656'
      break
    case 'success':
      backgroundColor = 'green'
      break
    default:
      backgroundColor = 'transparent'
      break
  }

  if (!visible) {
    return null
  }
  const handleClose = (): void => {
    setVisible(false)
  }

  return (
    visible && (
      <div data-testid={'alert-message'} style={{ position: 'relative', boxSizing: 'border-box', backgroundColor, padding: '10px', borderRadius: '8px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '22px' }}>
        <span>{icon}</span>
        <span style={{ fontSize: '12px', lineHeight: '15 px', color: '#FFFFFF', fontWeight: 600 }}>{message}</span>
        {dismissible === true && (
          <button onClick={handleClose} style={{ marginLeft: '10px', border: '0', outline: 'none', backgroundColor: 'inherit', cursor: 'pointer' }}>
            <CloseIcon />
          </button>
        )}
      </div>
    )
  )
}

export default AlertMessage
