import Button from '@mui/material/Button'
import { useAuth } from '../../hooks/use-auth'
import { Typography } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TermsAndConditions } from './TermsAndConditions'
import { useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../hooks/use-fetch'

export function Login (): JSX.Element {
  window.history.replaceState({}, document.title)

  const { user, loading, login, logout } = useAuth()
  const [showDialog, setShowDialog] = useState(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, success } = await sendGet('/Account/ShowTermsAndConditions', {})
      if (success) {
        setShowDialog(response)
      }
    }
    void fetchData()
  }, [])

  if (loading) return <></>

  let redirectFunction = login
  let buttonText = 'Login'

  if (user != null) {
    redirectFunction = logout
    buttonText = 'Logout'
  }

  const AgreedToTermsAndConditions = async (): Promise<void> => {
    const { success } = await sendPost('/Account/AgreeToTermsAndConditions', {})
    if (success) {
      setShowDialog(false)
    }
  }

  return (
    <>
      <h2>Home</h2>
      {(user?.noAccount === true || user?.inactive === true) && (
        <Typography sx={{ color: 'red' }}>This account is not authorized for MiECC.</Typography>
      )}
      <Button
        sx={{ textTransform: 'none' }}
        onClick={redirectFunction}
        variant="contained"
        autoCapitalize="false"
      >
        {buttonText}
      </Button>

      <Modal
        open={showDialog}
        title='Terms and Conditions'
        confirmationContent={<TermsAndConditions />}
        hideCancelButton={true}
        confirmButtonText='I Agree'
        onConfirm={AgreedToTermsAndConditions}
        onClose={function (): void {
          setShowDialog(false)
        }}
      />
    </>
  )
}
