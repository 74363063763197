import { Container, Box } from '@mui/material'
import type React from 'react'
import Header from '../Header'
import { Footer } from '../Footer'

export const Layout = (props: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Header />

      <Container
        key='displayContainer'
        maxWidth={false}
        sx={{
          '&&': {
            pl: { sm: '0px', md: '300px' }
          },
          px: 0,
          flexGrow: 1,
          width: '100%',
          pb: '40px',
          flexWrap: '',
          justifyContent: 'start'
        }}
      >
        <Box width={'100%'}>
          {props.children}
        </Box>
      </Container>

      <Footer />
    </Box>
  )
}
