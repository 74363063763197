import { Box } from '@mui/material'
import { ChildAddLocationList } from '../../../Components/Cards/MultiCardList/ChildAddLocationList'

export function AddChildAddress (): JSX.Element {
  return <Box width={'100%'}>
    <ChildAddLocationList
      label={'Addresses'}
    />
  </Box>
}
