import { Badge, Box, Button, Grid } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { LabelWithToolTip } from '../Label/Index'

export interface AdvancedSearchProps {
  mainSearchItems: JSX.Element
  extraItems: JSX.Element
  advancedSearchItems: JSX.Element
  displayAdvancedSearch: boolean
  advancedSearchCount: number
  toggleSearch: (display: boolean) => void
  onClear: () => void
}

export function AdvancedSearch (props: AdvancedSearchProps): JSX.Element {
  return <Box>
    <Box id="mainSearch">
      <Grid container>
        <Grid item sm={7} md={6} lg={4} >
          {props.mainSearchItems}
        </Grid>
        <Grid item xs={1} display={'flex'} alignItems={'center'} >
          <Button onClick={() => { props.toggleSearch(!props.displayAdvancedSearch) }} data-testid='advanced-search'>
            <Badge color="secondary" badgeContent={props.advancedSearchCount} >
              <FilterAltOutlinedIcon />
            </Badge>
            <LabelWithToolTip labelText={props.displayAdvancedSearch ? 'Hide Filters' : 'Show Filters' } />
          </Button>
        </Grid>
        <Grid item xs={4} justifyContent={'center'} sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          {props.extraItems}
        </Grid>
      </Grid>
    </Box>
    {props.displayAdvancedSearch && (
      <Box id="advancedSearch" sx={{ paddingLeft: 2 }}>
        {props.advancedSearchItems}
        <Button variant='outlined' onClick={props.onClear}>Clear Filters</Button>
      </Box>
    )}
  </Box>
}
