import { useEffect, useState } from 'react'
import './bingMap.css'

interface MapLocation {
  address: string
}

interface InputMapLocation {
  address?: string | null
  city?: string | null
  state?: string | null
  zipCode?: string | null
  latitude?: number | null
  longitude?: number | null
}

export enum MapModule {
  AutoSuggest = 'Microsoft.Maps.AutoSuggest',
  SpatialMath = 'Microsoft.Maps.SpatialMath'
}

interface MapProps {
  selectFunction?: (result: Microsoft.Maps.ISuggestionResult) => void
  inputID?: string
  onResultVerified?: (valid: boolean) => void
  modules: MapModule[]
  hasRendered?: boolean
}

interface ReturnProps {
  validateLocation: (loc: MapLocation) => void
  getLocationFromAddress: (inputLocation: InputMapLocation) => Promise<Microsoft.Maps.Location | null>
  distanceBetweenPoints: (point1: Microsoft.Maps.Location, point2: Microsoft.Maps.Location) => number
  isLoaded: boolean
  getMap: (location: Microsoft.Maps.Location) => Microsoft.Maps.Map
}

let searchManager: Microsoft.Maps.Search.SearchManager
export function useBingMaps (props: MapProps): ReturnProps {
  const apiKey = process.env.REACT_APP_BING_KEY ?? '' // TODO : Research how to hide this better.
  const scriptUrl = `https://www.bing.com/api/maps/mapcontrol?callback=initMap&key=${apiKey}`
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (props.hasRendered === false) {
      return
    }

    try {
      (window as any).initMap = () => { initMap() }

      const scriptElements = document.querySelectorAll(`script[src*="${scriptUrl}"]`)
      const script = document.createElement('script')
      script.src = scriptUrl
      script.async = true
      if (scriptElements.length === 0) {
        document.body.appendChild(script)
      }
      initMap()
    } catch (e) {}
  }, [props.hasRendered])

  function doNothing (): void {

  }

  function initMap (): void {
    try {
      if (Microsoft.Maps !== undefined) {
        Microsoft.Maps.loadModule(props.modules, {
          callback: () => {
            if (props.modules.includes(MapModule.AutoSuggest)) {
              const manager = new Microsoft.Maps.AutosuggestManager({
                placeSuggestions: false
              })
              manager.attachAutosuggest(`#${props.inputID ?? ''}`, '#searchBoxContainer', props.selectFunction ?? doNothing)
            }
            setIsLoaded(true)
          },
          credentials: apiKey
        })
      }
    } catch (e) {}
  }

  const validateLocation = (loc: MapLocation): void => {
    const map = new Microsoft.Maps.Map('', { credentials: apiKey })

    if (searchManager === undefined) {
      // Create an instance of the search manager and perform the search.
      Microsoft.Maps.loadModule(['Microsoft.Maps.Search', 'Microsoft.Maps.AutoSuggest'], function () {
        searchManager = new Microsoft.Maps.Search.SearchManager(map)
        validateLocation(loc)
      })
    } else {
      // Get the users query and geocode it.
      const searchRequest: Microsoft.Maps.Search.IGeocodeRequestOptions = {
        where: `${loc.address}`,
        callback: function (r: Microsoft.Maps.Search.IGeocodeResult) {
          const isValid = (Boolean((r?.results))) && r.results.length > 0
          if (props.onResultVerified != null) {
            props.onResultVerified(isValid)
          }
        },
        errorCallback: function (e) {
          if (props.onResultVerified != null) {
            props.onResultVerified(false)
          }
        }
      }
      searchManager.geocode(searchRequest)
    }
  }

  const getLocationFromAddress = async (inputLocation: InputMapLocation): Promise<Microsoft.Maps.Location | null> => {
    if (inputLocation.latitude != null && inputLocation.longitude != null) {
      return new Microsoft.Maps.Location(
        inputLocation.latitude,
        inputLocation.longitude
      )
    }
    if ((inputLocation.address != null || inputLocation.city != null || inputLocation.state != null || inputLocation.zipCode != null) &&
      (inputLocation.address !== '' || inputLocation.city !== '' || inputLocation.state !== '' || inputLocation.zipCode !== '')) {
      let searchUrl = 'https://dev.virtualearth.net/REST/v1/Locations?CountryRegion=US'
      if (inputLocation.state != null && inputLocation.state !== '') {
        searchUrl = searchUrl.concat(`&adminDistrict=${inputLocation.state}`)
      }
      if (inputLocation.city != null && inputLocation.city !== '') {
        searchUrl = searchUrl.concat(`&locality=${inputLocation.city}`)
      }
      if (inputLocation.zipCode != null && inputLocation.zipCode !== '') {
        searchUrl = searchUrl.concat(`&postalCode=${inputLocation.zipCode}`)
      }
      if (inputLocation.address != null && inputLocation.address !== '') {
        searchUrl = searchUrl.concat(`&addressLine=${inputLocation.address}`)
      }
      searchUrl = searchUrl.concat(`&key=${apiKey}`)

      const response = await fetch(searchUrl)
      if (response.ok) {
        const results = await response.json()
        const coordinates = results.resourceSets[0].resources[0].point.coordinates
        return new Microsoft.Maps.Location(
          coordinates[0],
          coordinates[1]
        )
      }
    }

    return null
  }

  const getMap = (location: Microsoft.Maps.Location): Microsoft.Maps.Map => {
    const map = new Microsoft.Maps.Map('#map-container', {
      credentials: apiKey,
      showLogo: false,
      showMapTypeSelector: false,
      showScalebar: false,
      showTermsLink: false,
      showLocateMeButton: true,
      center: location,
      zoom: 11,
      maxZoom: 18
    })

    return map
  }

  const distanceBetweenPoints = (point1: Microsoft.Maps.Location, point2: Microsoft.Maps.Location): number => {
    const distance = Microsoft.Maps.SpatialMath.getDistanceTo(point1, point2, Microsoft.Maps.SpatialMath.DistanceUnits.Miles)
    return distance
  }

  return { validateLocation, getLocationFromAddress, distanceBetweenPoints, getMap, isLoaded }
}
