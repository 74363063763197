import { Box, Tab, Tabs, Typography } from '@mui/material'
import { BannerModal } from '../../../../Components/Modal/BannerModal'
import { type ServiceProviderOption } from './ServiceProviderMap'
import { useState } from 'react'
import { TabDisplay } from '../../../../Components/TabDisplay'
import { FormatBoolean, FormatTimeAs12Hour } from '../../../../core/Utilities'
import PlaceIcon from '@mui/icons-material/Place'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PhoneIcon from '@mui/icons-material/Phone'
import LanguageIcon from '@mui/icons-material/Language'
import S3Image from '../../../../Components/S3Image'

interface ProviderDetailsModalProps {
  serviceProvider: ServiceProviderOption
  open: boolean
  setShowModal: (show: boolean) => void
  onSelect: () => void
}

export function ProviderDetailsModal (props: ProviderDetailsModalProps): JSX.Element {
  const [value, valueChange] = useState(0)

  const provider = props.serviceProvider

  const handleRedirect = (): void => {
    if (provider.website == null) return

    let url = provider.website
    // Redirects in React only work with absolute URLs
    if (!url.startsWith('http')) {
      url = 'https://' + url
    }
    window.location.href = url
  }

  const content = <>
    <Typography variant='h5' sx={{ fontWeight: 700 }}>
      {provider.name}
    </Typography>
    <Typography variant='body1' sx={{ mt: '10px', mb: '10px' }} component='div'>
      {provider.distance}
    </Typography>

    <Tabs value={value} onChange={(e, val) => { valueChange(val) }}>
      <Tab label="Details" value={0} data-testid='details-tab' sx={{ textTransform: 'none', fontSize: '16px', flexGrow: 1 }} />
      <Tab label="Programs" value={1} data-testid='programs-tab' sx={{ textTransform: 'none', fontSize: '16px', flexGrow: 1 }} />
      <Tab label="Qualifications" value={2} data-testid='qualifications-tab' sx={{ textTransform: 'none', fontSize: '16px', flexGrow: 1 }} />
    </Tabs>

    <TabDisplay index={0} value={value}>
      <Box sx={{ mt: '30px' }}>
        <Box data-testid="address-line">
          <PlaceIcon color='info' sx={{ mr: '15px', verticalAlign: 'middle' }} />
          {provider.addressLine}
        </Box>
        <Box sx={{ mt: '20px', display: 'flex' }}>
          <AccessTimeIcon color='info' sx={{ mr: '15px', verticalAlign: 'middle' }} />
          <Box>
            {provider.times.map((time) => {
              return <Box key={time.dayOfWeek} data-testid="time-display">
                <Typography variant='body1' component='div' sx={{ display: 'flex', justifyContent: 'space-between', width: '250px' }}>
                  <Box>{time.dayOfWeek}</Box>
                  <Box sx={{ width: '135px' }}>{time.openTime !== '' ? `${FormatTimeAs12Hour(time.openTime)} - ${FormatTimeAs12Hour(time.closeTime)}` : 'Closed'}</Box>
                </Typography>
              </Box>
            })}
          </Box>
        </Box>
        <Box sx={{ mt: '20px' }} data-testid="phone-number">
          <PhoneIcon color='info' sx={{ mr: '15px', verticalAlign: 'middle' }} />
          {provider.phoneNumber}
        </Box>
        {provider.website != null &&
          <Box sx={{ mt: '20px', cursor: 'pointer' }} onClick={handleRedirect} data-testid="website">
            <LanguageIcon color='info' sx={{ mr: '15px', verticalAlign: 'middle' }} />
            {provider.website}
          </Box>
        }
      </Box>
    </TabDisplay>

    <TabDisplay index={1} value={value}>
      <Box>
        Provides Before / After school care: {FormatBoolean(provider.providesBeforeAfterSchoolCare)}
      </Box>
      {provider.programs.map((program) => {
        return <Box key={program.name} sx={{ mt: '20px', display: 'flex' }}>
          {program.s3Key !== null && (
            <Box sx={{ mr: '10px' }}>
              <S3Image imageKey={program.s3Key} alt={program.name} style={{ width: '50px', height: '50px' }} />
            </Box>
          )}
          <Box>
            <Typography variant='h6' sx={{ fontWeight: 700 }} data-testid="program-name">
              {program.name}
            </Typography>
            <Typography variant='body1' sx={{ mt: '10px' }} component='div' data-testid="program-description">
              {program.description}
            </Typography>
          </Box>
        </Box>
      })}
    </TabDisplay>

    <TabDisplay index={2} value={value}>
      <>
        <Box sx={{ mt: '20px' }} data-testid="quality-rating">
          <Typography variant='body1' sx={{ fontWeight: 700 }}>
            Quality Rating
          </Typography>
          {provider.qualityRating}
        </Box>
        {provider.offeringNarrative !== '' &&
          <Box sx={{ mt: '20px' }} data-testid="offering-narrative">
            <Typography variant='body1' sx={{ fontWeight: 700 }}>
              Offering Narrative
            </Typography>
            {provider.offeringNarrative}
          </Box>
        }
      </>
    </TabDisplay>
  </>

  return <BannerModal
    maxWidth='xs'
    open={props.open}
    title='Information'
    content={content}
    cancelButtonText='Close'
    confirmButtonText='Select'
    confirmButtonColor='info'
    onClose={() => { props.setShowModal(false) }}
    onConfirm={props.onSelect}
    className='banner-modal'
    buttonClassName='banner-modal-button'
    dataTestId='provider-details-modal'
  />
}
