import { Box, Button, Grid, Link, Typography } from '@mui/material'
import { initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { type PortalFacilityHours, type EnrollmentDetails, type PortalServiceProviderSearchResult, type PortalProgram, type PortalLocation } from '../../../../core/types'
import { useParams } from 'react-router'
import { sendGet } from '../../../../hooks/use-fetch'
import { SelectableRow } from '../SelectableRow'
import { MapModule, useBingMaps } from '../../../../hooks/use-bingMaps'
import { ProviderDetailsModal } from './ProviderDetailsModal'
import { FormatTimeAs12Hour } from '../../../../core/Utilities'
import { ChangeAddressModal } from './ChangeAddressModal'
import { getDayOfWeek } from '../../PortalHelpers'
import { CustomLabelWithToolTip } from '../../../../Components/CustomLabel/Index'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

export interface ServiceProviderOption {
  id: number
  name: string
  subtext: string
  distance: string
  milesTo: number
  addressLine: string
  times: PortalFacilityHours[]
  todayHours: string
  phoneNumber: string | null
  website: string | null
  qualityRating: string
  offeringNarrative: string
  providesBeforeAfterSchoolCare: boolean
  programs: PortalProgram[]
  latitude?: number
  longitude?: number
  zipCode: string | null
  pinIndex?: number
}

interface ServiceProviderMapProps {
  caregiverForm: EnrollmentDetails
  handleStep: (backwards: boolean) => void
}

const homeIconSvgString = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
</svg>`

export function ServiceProviderMap (props: ServiceProviderMapProps): JSX.Element {
  const { isLoaded, getLocationFromAddress, distanceBetweenPoints, getMap } = useBingMaps({
    modules: [MapModule.SpatialMath]
  })
  const dispatch = useCaregiverWizardUpdateDispatch()
  const { isdId, isdServiceProviderId } = useParams()
  const [providers, setProviders] = useState<ServiceProviderOption[]>([])
  const [currentAddress, setCurrentAddress] = useState<string | null>(null)
  const selectedZipCode = useRef<string | null>(null)
  const [selectedProviders, _setSelectedProviders] = useState<ServiceProviderOption[]>([])
  const [maybeSelectedProvider, setMaybeSelectedProvider] = useState<ServiceProviderOption | null>(null)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [map, setMap] = useState<Microsoft.Maps.Map | null>(null)
  const selectedProvidersRef = useRef(selectedProviders)

  useScrollTop()

  // The ref is needed to access the updated value from the event handler
  const setSelectedProviders = (providers: ServiceProviderOption[]): void => {
    selectedProvidersRef.current = providers
    _setSelectedProviders(providers)
  }

  const handleProviderSelect = (provider: ServiceProviderOption): void => {
    const tempProviders = [...selectedProviders]
    const existingProviderIndex = tempProviders.findIndex(p => p.id === provider.id)
    if (existingProviderIndex === -1) {
      tempProviders.push(provider)
    } else {
      tempProviders.splice(existingProviderIndex, 1)
    }
    setSelectedProviders(tempProviders)
  }

  const caregiverForm = props.caregiverForm

  useEffect(() => {
    void fetchData()
  }, [isdId, isLoaded, caregiverForm.currentChild.requestedServices, caregiverForm.currentChild.location])

  const fetchData = async (): Promise<void> => {
    const currentService = caregiverForm.currentChild.requestedServices.find(s => s.isCurrentlyUpdating)
    const systemServiceId = currentService?.id
    if (systemServiceId == null || window.Microsoft === undefined || !isLoaded) {
      return
    }

    const { response, success }: { response: PortalServiceProviderSearchResult[], success: boolean } =
      await sendGet(`/CaregiverPortal/GetAllProviders/${isdId ?? 0}/ForService/${systemServiceId}/ISDServiceProvider/${isdServiceProviderId ?? 0}`, {})

    if (!success) {
      return
    }

    const child = caregiverForm.currentChild
    let childLocation = child.location
    if (child.livesWithFirstChild) {
      childLocation = caregiverForm.children[0].location
    }
    let address = `${childLocation.address}, ${childLocation.city}, ${childLocation.state} ${childLocation.zipCode}`
    if (childLocation.address == null || childLocation.address === '') {
      address = ''
    }
    setCurrentAddress(address)

    const childMapLocation = await convertChildToLocation(childLocation)
    const providerOptions = await convertResponseToProviders(response, childMapLocation)

    const tempProviders = [] as ServiceProviderOption[]
    if (currentService != null) {
      for (const provider of providerOptions) {
        if (currentService.facilityIds.includes(provider.id)) {
          tempProviders.push(provider)
        }
      }
    }
    setSelectedProviders(tempProviders)

    createMap(childMapLocation, providerOptions)
  }

  const convertResponseToProviders = async (
    response: PortalServiceProviderSearchResult[],
    childLocation: Microsoft.Maps.Location | null
  ): Promise<ServiceProviderOption[]> => {
    const today = getDayOfWeek()

    const providerOptions = [] as ServiceProviderOption[]
    for (const provider of response) {
      const providerLocation = await convertProviderToLocation(provider)
      let distance = 'Unknown distance'
      let milesTo = 0
      if (childLocation != null && providerLocation != null) {
        milesTo = distanceBetweenPoints(childLocation, providerLocation)
        distance = milesTo.toFixed(1) + ' miles away'
      }
      const times = populateBusinessHours(provider.facilityHours)
      const todayHours = provider.facilityHours.find(h => h.dayOfWeek === today)

      providerOptions.push({
        id: provider.facilityID,
        name: `${provider.serviceProviderName} - ${provider.facilityName}`,
        subtext: distance,
        distance,
        milesTo,
        addressLine: `${provider.address ?? ''}, ${provider.city ?? ''}, ${provider.state ?? ''} ${provider.zipCode ?? ''}`,
        times,
        todayHours: todayHours != null
          ? `${FormatTimeAs12Hour(todayHours.openTime)} - ${FormatTimeAs12Hour(todayHours.closeTime)}`
          : 'Closed',
        phoneNumber: provider.phoneNumber,
        website: provider.website,
        qualityRating: provider.qualityRating,
        offeringNarrative: provider.offeringNarrative,
        programs: provider.programs,
        latitude: providerLocation?.latitude,
        longitude: providerLocation?.longitude,
        providesBeforeAfterSchoolCare: provider.providesBeforeAfterSchoolCare,
        zipCode: provider.zipCode
      })
    }

    return providerOptions
  }

  const populateBusinessHours = (facilityHours: PortalFacilityHours[]): PortalFacilityHours[] => {
    const sundayHours = facilityHours.find(h => h.dayOfWeek === 'Sunday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Sunday' }
    const mondayHours = facilityHours.find(h => h.dayOfWeek === 'Monday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Monday' }
    const tuesdayHours = facilityHours.find(h => h.dayOfWeek === 'Tuesday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Tuesday' }
    const wednesdayHours = facilityHours.find(h => h.dayOfWeek === 'Wednesday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Wednesday' }
    const thursdayHours = facilityHours.find(h => h.dayOfWeek === 'Thursday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Thursday' }
    const fridayHours = facilityHours.find(h => h.dayOfWeek === 'Friday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Friday' }
    const saturdayHours = facilityHours.find(h => h.dayOfWeek === 'Saturday') ?? { openTime: '', closeTime: '', dayOfWeek: 'Saturday' }

    return [sundayHours, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours] as PortalFacilityHours[]
  }

  const convertChildToLocation = async (childLocation: PortalLocation): Promise<Microsoft.Maps.Location | null> => {
    return await getLocationFromAddress({
      address: childLocation?.address,
      city: childLocation?.city,
      state: childLocation?.state,
      zipCode: childLocation?.zipCode
    })
  }

  const convertProviderToLocation = async (provider: PortalServiceProviderSearchResult): Promise<Microsoft.Maps.Location | null> => {
    return await getLocationFromAddress({
      latitude: provider.latitude,
      longitude: provider.longitude,
      address: provider.address,
      city: provider.city,
      state: provider.state,
      zipCode: provider.zipCode
    })
  }

  const createMap = (centerPoint: Microsoft.Maps.Location | null, providerOptions: ServiceProviderOption[]): void => {
    let center = centerPoint ?? new Microsoft.Maps.Location(0, 0)
    let noChildAddress = false
    if (centerPoint?.latitude == null || centerPoint.longitude == null) {
      noChildAddress = true
      if (providerOptions.length > 0) {
        center = new Microsoft.Maps.Location(providerOptions[0].latitude ?? 0, providerOptions[0].longitude ?? 0)
      }
    }

    const newMap = getMap(center)
    let index = 0
    providerOptions.forEach(provider => {
      if (provider.latitude == null || provider.longitude == null) {
        return
      }
      const pinLocation = new Microsoft.Maps.Location(provider.latitude, provider.longitude)
      const pin = new Microsoft.Maps.Pushpin(pinLocation, {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px">
          <path d="M12,3L1,9L12,15L21,10.09V17H23V9M5,13.18V17.18L12,21L19,17.18V13.18L12,17L5,13.18Z" fill="black"></path>
        </svg>`,
        anchor: new Microsoft.Maps.Point(15, 15)
      })
      newMap.entities.push(pin)
      provider.pinIndex = index++

      Microsoft.Maps.Events.addHandler(pin, 'click', function () { handleProviderClick(provider) })
    })
    const homePin = new Microsoft.Maps.Pushpin(center, {
      icon: homeIconSvgString,
      anchor: new Microsoft.Maps.Point(15, 15),
      visible: !noChildAddress
    })
    newMap.entities.push(homePin)

    Microsoft.Maps.Events.addHandler(newMap, 'viewchangeend', function (e) { resetVisibleProviders(e, providerOptions) })

    setMap(newMap)
  }

  const resetVisibleProviders = (e: Microsoft.Maps.IMouseEventArgs | Microsoft.Maps.IMapTypeChangeEventArgs | undefined,
    providers: ServiceProviderOption[]
  ): void => {
    const map = e?.target as Microsoft.Maps.Map
    const visibleProviders = [] as ServiceProviderOption[]
    const bounds = map.getBounds()

    providers.forEach(provider => {
      if (provider.pinIndex == null) {
        return
      }

      const pin = map?.entities.get(provider.pinIndex) as Microsoft.Maps.Pushpin
      if (pin != null) {
        if (selectedZipCode.current !== null) {
          if (provider.zipCode === selectedZipCode.current) {
            visibleProviders.push(provider)
            pin.setOptions({ visible: true })
          } else {
            pin.setOptions({ visible: false })
          }
        } else if ((bounds.contains(pin.getLocation())) || selectedProvidersRef.current.some(p => p.id === provider.id)) {
          visibleProviders.push(provider)
          pin.setOptions({ visible: true })
        }
      }
    })

    setProviders(visibleProviders.sort((a, b) => a.milesTo - b.milesTo))
  }

  const updateCurrentAddress = (address: string | null, locationRect: Microsoft.Maps.LocationRect | null, zipCode: string | null): void => {
    setCurrentAddress(address)
    selectedZipCode.current = zipCode
    const zoom = map?.getZoom() ?? 11

    if (locationRect !== null) {
      map?.entities.pop()
      map?.setView({ bounds: locationRect })
      map?.setView({ zoom })

      const homePin = new Microsoft.Maps.Pushpin(locationRect.center, {
        icon: homeIconSvgString,
        anchor: new Microsoft.Maps.Point(15, 15)
      })
      map?.entities.push(homePin)
    } else if (zipCode !== null) {
      Microsoft.Maps.loadModule('Microsoft.Maps.GeoJson', function () {
        const fetchData = async (): Promise<void> => {
          const { response, success, status } = await sendGet(`/FacilityDetails/ZipCodeGeoJSON?zipCode=${zipCode}`, {})
          if (status === 204) {
            toast.error('You entered an invalid Michigan ZIP code')
          } else if (success) {
            map?.entities.pop()

            const shape = Microsoft.Maps.GeoJson.read(response, {
              polygonOptions: {
                fillColor: 'rgba(255,0,0,0.3)',
                strokeColor: 'black',
                strokeThickness: 1
              }
            })
            map?.entities.push(shape)

            let polygon: Microsoft.Maps.Polygon
            if (Array.isArray(shape)) {
              polygon = shape[0] as Microsoft.Maps.Polygon
            } else {
              polygon = shape as Microsoft.Maps.Polygon
            }

            const boundaries = Microsoft.Maps.LocationRect.fromLocations(polygon.getLocations())
            map?.setView({
              bounds: boundaries
            })
            map?.setView({ zoom })
          }
        }
        void fetchData()
      })
    }
  }

  const handleProviderClick = (provider: ServiceProviderOption): void => {
    setMaybeSelectedProvider(provider)
    setShowDetailsModal(true)
  }

  const handleMaybeProviderSelect = (): void => {
    const tempProviders = [...selectedProviders]
    const existingProviderIndex = tempProviders.findIndex(p => p.id === maybeSelectedProvider?.id)
    if (existingProviderIndex === -1 && maybeSelectedProvider != null) {
      tempProviders.push(maybeSelectedProvider)
      setSelectedProviders(tempProviders)
    }
    setShowDetailsModal(false)
  }

  const handleSpecificProviderClick = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentSubStep: 1 }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleBack = async (): Promise<void> => {
    const tempForm = { ...caregiverForm, currentStep: 3, currentSubStep: 3 }
    await saveSession(tempForm)
    props.handleStep(true)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    if (selectedProviders.length === 0) {
      toast.error('Please select a provider to continue')
      return
    }

    const tempForm = { ...caregiverForm, currentSubStep: 3 }
    const tempChild = { ...tempForm.currentChild }
    const currentService = tempChild.requestedServices.find(s => s.isCurrentlyUpdating)
    if (currentService != null) {
      currentService.facilityIds = selectedProviders.map(p => p.id)
    }
    const existingChildIndex = tempForm.children.findIndex(c => c.isCurrentlyUpdating)
    if (existingChildIndex !== -1) {
      tempChild.isCurrentlyUpdating = false
      const service = tempChild.requestedServices.find(s => s.isCurrentlyUpdating)
      if (service != null) {
        service.isCurrentlyUpdating = false
      }
      tempForm.children[existingChildIndex] = tempChild
    }
    const initialForm = initialEnrollmentDetails()
    tempForm.currentChild = initialForm.currentChild

    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' sx={{ mb: '30px' }} component='div'>
          <CustomLabelWithToolTip
            name='IF_Service_Browse_Intro'
            isdId={isdId ?? 0}
            defaultLabelText='Awesome! Here is a list of providers in your area. Select any that you may be interested in learning more about.'
          />
        </Typography>

        <Typography variant='subtitle1' sx={{ mb: '10px' }} component='div'>
          Have a specific provider in mind? Go <Link sx={{ cursor: 'pointer' }} onClick={handleSpecificProviderClick} data-testid='specific-provider'>here</Link> instead.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='subtitle1'
          component='div'
          sx={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}
          className='d-flex f-justify-content-space-between'
        >
          <Box className='d-flex'>
            {currentAddress !== null && <>
              <Box sx={{ fontWeight: 'bold', mr: '5px' }}>Current Address</Box>
              {currentAddress}
            </>}
            {selectedZipCode.current !== null && <>
              <Box sx={{ fontWeight: 'bold', mr: '5px' }}>ZIP Code</Box>
              {selectedZipCode.current}
            </>}
          </Box>
          <Link sx={{ cursor: 'pointer', textDecoration: 'none', ml: '5px' }} onClick={() => { setShowAddressModal(true) }} data-testid='change-address'>
            Change
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ pt: '0 !important' }}>
        <Box id='map-container' sx={{ height: '400px' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h5' component='div'>
          Nearby Providers
        </Typography>
        {providers.length === 0 &&
          <Typography variant='body1' sx={{ mt: '10px' }} component='div'>
            No providers found
          </Typography>
        }
        {providers.map((provider) => {
          return <SelectableRow
            key={provider.id}
            content={
              <Box
                sx={{ width: '100%', cursor: 'pointer' }}
                onClick={() => { handleProviderClick(provider) }}
                data-testid='facility-row'
              >
                {provider.name}
                <Typography variant='body1' sx={{ mt: '10px' }} component='div'>
                  {provider.todayHours}
                </Typography>
                <Typography variant='body1' sx={{ mt: '10px' }} component='div'>
                  {provider.distance}
                </Typography>
              </Box>
            }
            id={provider.id}
            selectedId={selectedProviders.some(p => p.id === provider.id) ? provider.id : null}
            setSelected={() => { handleProviderSelect(provider) }}
          />
        })}
      </Grid>

      <Grid item xs={6} sx={{ mb: '20px', mt: '20px' }}>
        <Button
          name='backButton'
          className='back-button'
          data-testid='back-button'
          onClick={handleBack}
          variant='outlined'
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ mb: '20px', mt: '20px' }}>
        <Button
          name='continueButton'
          className='footer-button'
          data-testid='continue-button'
          onClick={handleContinue}
          variant='contained'
        >
          Continue
        </Button>
      </Grid>
    </Grid>

    {maybeSelectedProvider != null &&
      <ProviderDetailsModal
        serviceProvider={maybeSelectedProvider}
        open={showDetailsModal}
        setShowModal={setShowDetailsModal}
        onSelect={handleMaybeProviderSelect}
      />
    }

    {showAddressModal && <ChangeAddressModal
      open={showAddressModal}
      setShowModal={setShowAddressModal}
      updateCurrentAddress={updateCurrentAddress}
    />}
  </>
}
