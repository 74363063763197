import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { LargeCard } from '../../../Components/Cards/Large'
import { type lookup, type facility } from '../../../core/types'
import { TextWithLabel } from '../../../Components/TextWithLabel'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { FacilityDetailsEdit } from './FacilityDetailsEdit'
import EditIcon from '@mui/icons-material/Edit'
import { LabelWithToolTip } from '../../../Components/Label/Index'
import { FormatBoolean, FormatTimeAs12Hour } from '../../../core/Utilities'

export function FacilityDetailsView (): JSX.Element {
  const { id } = useParams()
  const paramID = id ?? ''

  const [errors, setErrors] = useState<string[] | undefined>()
  const [isds, setIsds] = useState<lookup[]>([])

  const [facility, setFacility] = useState<facility>({
    facilityHours: [],
    serviceProviderID: 0,
    id: 0,
    location: {
      id: 0,
      apartment: '',
      note: '',
      address: '',
      city: '',
      state: '',
      zipCode: ''
    },
    inactive: false,
    licenseExpirationDate: null,
    licenseNumber: '',
    name: '',
    facilityCode: '',
    lastModified: new Date(),
    phoneNumber: '',
    website: '',
    isdid: 0,
    isdName: '',
    locationISDFound: false,
    offeringNarrative: '',
    qualityRating: 0,
    services: [],
    programs: [],
    providesBeforeAfterSchoolCare: false
  })
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [loaded, setLoaded] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const onSave = (): void => {
    setRefreshTime(new Date())
    setShowEdit(false)
  }

  const onClose = (): void => {
    setShowEdit(false)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (id !== null) {
        const { response: facilityRsp, success } = await sendGet(`/FacilityDetails/Get?id=${paramID}`, {})
        if (!success) {
          setErrors(['Facility not found'])
        } else if (facilityRsp != null) {
          setFacility(facilityRsp)
          const resp = await sendGet(`/FacilityDetails/GetSpIsds?spID=${facilityRsp.serviceProviderID as number}`, {})
          setIsds(resp.response)
        }
        setLoaded(true)
      }
    }
    void loadData()
  }, [id, refreshTime])

  const nameContent = <Box>
    <TextWithLabel
      label='Name'
      name='name'
      value={facility.name ?? ''}
    />
    <TextWithLabel
      label='Facility Code'
      name='facilityCode'
      value={facility.facilityCode ?? ''}
    />
    <TextWithLabel
      label='Address'
      name='address'
      value={facility.location.address ?? ''}
    />
    <TextWithLabel
      label='City'
      name='city'
      value={facility.location.city ?? ''}
    />
    <TextWithLabel
      label='State'
      name='state'
      value={facility.location.state ?? ''}
    />
    <TextWithLabel
      label='Zip Code'
      name='zipCode'
      value={facility.location.zipCode ?? ''}
    />
    <TextWithLabel
      label='Operating ISD'
      name='operatingISD'
      value={facility.isdName ?? ''}
    />
    <TextWithLabel
      label='Offering Narrative'
      name='offeringNarrative'
      value={facility.offeringNarrative ?? ''}
    />
  </Box>

  const grids = <>
    {(errors != null) && (
      <ul>
        {errors?.map(e => {
          return <li>{e}</li>
        })}
      </ul>
    )}

    <Paper sx={{ pt: 2, borderRadius: '32px', marginTop: '20px' }}>
      <Grid container columns={12}>
        <Grid item xs={11} sm={11}>
          <Grid container columns={12} spacing={2}>
            <Grid item sm={12} md={6}>
              <LargeCard
                fullWidth
                className='Borderless'
                content={nameContent}
                header='Facility Details'
              />
              <LargeCard fullWidth
                data-testid='status-card'
                header='Status'
                className='Borderless'
                content={
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <TextWithLabel
                        label='License Number'
                        name='licenseNumber'
                        value={facility.licenseNumber ?? ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextWithLabel
                        testId='expirationDate'
                        label='Expiration Date'
                        name='expirationDate'
                        value={(facility.licenseExpirationDate != null) ? new Date(facility.licenseExpirationDate ?? 0).toLocaleDateString() : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextWithLabel
                        label='Status'
                        name='status'
                        value={facility.inactive ? 'Deactivated' : 'Active'}
                      />
                    </Grid>
                  </Grid>}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <LargeCard fullWidth
                className='Borderless'
                data-testid='hours-card'
                content={<>
                  <Box
                    sx={{
                      pt: '8px',
                      flexDirection: 'column',
                      display: 'flex',
                      margin: '5px 5px 5px 0'
                    }}
                  >
                    <LabelWithToolTip
                      sx={{ fontWeight: '600' }}
                      isInline={true}
                      required={false}
                      name='OperatingHours'
                      labelText='Operating Hours'
                    />
                    <Box aria-label='Operating Hours' sx={{ marginLeft: '10px', minHeight: '20px' } }>
                      {facility.facilityHours?.map((h, i) => {
                        return <Typography key={i} >{h.dayOfWeek} : {FormatTimeAs12Hour(h.openingTime)} - {FormatTimeAs12Hour(h.closingTime)}</Typography>
                      })}
                    </Box>
                  </Box>
                </>}
                header='Operating Details'
              />
              <LargeCard fullWidth content={
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <LabelWithToolTip
                      sx={{ fontWeight: '600' }}
                      isInline={true}
                      required={false}
                      name='servicesLabel'
                      labelText='Services'
                    />
                    <Box aria-label='Services' sx={{ marginLeft: '10px', minHeight: '20px' } }>
                      {facility.services?.map((s, i) => {
                        return <Typography key={i}>{s.name}</Typography>
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LabelWithToolTip
                      sx={{ fontWeight: '600' }}
                      isInline={true}
                      required={false}
                      name='programsLabel'
                      labelText='Programs'
                    />
                    <Box aria-label='Programs' sx={{ marginLeft: '10px', minHeight: '20px' } }>
                      {facility?.programs?.map((s, i) => {
                        return <Typography key={i}>{s.name}</Typography>
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextWithLabel
                      label='Great Start To Quality Rating'
                      name='greatStartToQualityRating'
                      value={facility.qualityRatingName ?? ''}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextWithLabel
                      label='Provides Before / After School Care'
                      name='providesBeforeAfterSchoolCare'
                      value={FormatBoolean(facility.providesBeforeAfterSchoolCare)}
                    />
                  </Grid>
                </Grid>}
                className='Borderless'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs= {1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
          <IconButton onClick={() => { setShowEdit(true) }} >
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
    <FacilityDetailsEdit
      isds={isds}
      isNew={false}
      onClose={onClose}
      show={showEdit}
      facility={facility}
      onSave={onSave}
    />
  </>

  if (!loaded) {
    return <CircularProgress />
  }

  return grids
}
