import { AcceptDenyModal } from '../../Components/Modal/AcceptDenyModal'
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { FormatCurrency, FormatDateTimeValue } from '../../core/Utilities'
import { TextAreaWithLabel } from '../../Components/TextArea'
import { useState, useEffect, type ChangeEvent } from 'react'
import { sendGet, sendPut } from '../../hooks/use-fetch'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { toast } from 'react-toastify'
import { type postError } from '../../core/types'
import { Modal } from '../../Components/Modal'
import { LargeCard } from '../../Components/Cards/Large'
import { BannerModal } from '../../Components/Modal/BannerModal'
import checkmark from '../../Assets/Icons/checkmark.png'
import redslashedcircle from '../../Assets/Icons/redslashedcircle.png'
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp'
import { ReferralDocuments } from '../Referral/ReferralDocuments'

export interface IncomeApprovalDetailProps {
  incomeApprovalID: number
  isWaiver: boolean
  open: boolean
  onClose: () => void
  setRefreshTime: () => void
}

export interface IAData {
  id: number
  childID: number
  childName: string
  isdid: number
  referralID: number
  householdIncome: number | null
  incomeVerified: boolean
  povertyPercent: number | null
  povertyPercentExceedsCutoff: boolean
  dateRangeName: string
  programName: string | null
  serviceProviderName: string
  serviceProviderLogoS3Key: string | null
  facilityName: string | null
  eligibilityFactors: string[]
  requestingUserName: string
  requestingUserEmail: string
  requestDate: Date
  requestDetails: string | null
  isApproved: boolean | null
  responseNotes: string | null
  documentsSubmittedAndVerified: boolean | null
  YearList: number[]
  reasonId: number | null
}

export function IncomeApprovalDetail (props: IncomeApprovalDetailProps): JSX.Element {
  const [iaRecord, setIARecord] = useState<IAData>()
  const [loaded, setLoaded] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [responseNotes, setResponseNotes] = useState('')
  const [errors, setErrors] = useState<postError[] | undefined>(undefined)
  const [readOnly, setReadOnly] = useState(false)
  const [showDocuments, setShowDocuments] = useState(false)
  const [reasonId, setReasonId] = useState<number | null>(null)

  useEffect(() => {
    async function getData (): Promise<void> {
      const request = await sendGet(`/IncomeApproval/GetItem?id=${props.incomeApprovalID}`, {})
      const data: IAData = request.response
      setIARecord(data)
      setReasonId(data.reasonId)
      setReadOnly(data.isApproved !== null)
      setLoaded(true)
    }
    void getData()
  }, [])

  const handleResponseNotesChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (iaRecord !== undefined) {
      setResponseNotes(e.target.value)
    }
  }

  const handleClose = (): void => {
    props.onClose()
  }

  const handleApprove = async (): Promise<void> => {
    await ApplyStatus(true)
  }

  const handleReject = async (): Promise<void> => {
    await ApplyStatus(false)
  }

  const viewDocuments = (): void => {
    setShowDocuments(true)
  }

  async function ApplyStatus (status: boolean): Promise<void> {
    if (!isRequestLoading) {
      setIsRequestLoading(true)

      setErrors(undefined)
      const submitData: { id: number, status: boolean, responseNotes: string, isWaiver: boolean, reasonId: number | null } = {
        id: props.incomeApprovalID,
        status,
        responseNotes,
        isWaiver: props.isWaiver,
        reasonId
      }
      const result = await sendPut('/IncomeApproval/SetApprovalStatus', submitData)
      const rsp: { newRecordID: number, success: boolean, errors: postError[] } = result.response

      if (rsp.errors.length > 0) {
        setErrors(rsp.errors)
      } else if (rsp.success) {
        if (status) {
          toast.success('Income Approval was Approved')
        } else {
          toast.success('Income Approval was Denied')
        }
        props.onClose()
      }
      setIsRequestLoading(false)
    }
  }
  const handleReasonChange = (e: any, newReason: string): void => {
    const result = parseInt(newReason)
    setReasonId(Number.isNaN(result) ? null : result)
  }
  const reasons = ['The Head Start Program which serves this child\'s area of residence does not have space for this child.',
    'The Head Start Program does not offer programming within the child\'s resident school district.',
    'There is space in Head Start for this child, but another Great Start Readiness Program is requested.',
    'The family has chosen to enroll in Head Start.'
  ]
  if (!loaded) return <CircularProgress role='loading' />
  else if (iaRecord == null) {
    return <div>Record not found</div>
  } else {
    const content = <Box >
      {errors?.map((err) => (
        <li key={err.field} style={{ color: 'red', padding: '10px 0 0' }}>
          {err.error}
        </li>
      ))}
      <Typography component="div" variant="h4">{iaRecord?.childName}</Typography>

      <LargeCard
        fullWidth={true}
        content={<Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography component="div" variant="h5">Request Details</Typography>
            <table role='presentation' className='padded-table'>
              <tr>
                <td>Documents</td>
                <td>
                  <Button onClick={viewDocuments}><FileCopySharpIcon /></Button>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className={iaRecord.documentsSubmittedAndVerified == null || iaRecord.documentsSubmittedAndVerified ? 'valid-status' : 'error-status'}>
                    {iaRecord.documentsSubmittedAndVerified == null ? 'No Documents Required' : (iaRecord.documentsSubmittedAndVerified ? 'Documents are Verified' : 'Missing / Unverified Documents')}
                  </div>
                </td>
              </tr>
              <tr data-testid='householdIncomeHeader'>
                <td>Household Income</td>
                <td data-testid='householdIncome' align={'right'} className={'pr-20'}>{iaRecord.householdIncome === null ? 'N/A' : FormatCurrency(iaRecord.householdIncome)}</td>
                {iaRecord.householdIncome !== null && <td className={iaRecord.incomeVerified ? 'valid-status' : 'error-status'} >
                  {iaRecord.incomeVerified
                    ? <img src={checkmark} alt={'Household Income has been Verified'} title={'Household Income has been Verified'} />
                    : <img src={redslashedcircle} alt={'Household Income has not been Verified'} title={'Household Income has not been Verified'} />}
                </td>
                }
              </tr>
              <tr data-testid='povertyLevelHeader'>
                <td>Poverty Level</td>
                <td data-testid='povertyLevel' align={'right'} className={'pr-20 ' + (iaRecord.povertyPercentExceedsCutoff ? 'error-status' : 'valid-status')}>{iaRecord.povertyPercent}{iaRecord.povertyPercent !== null ? '%' : ''}</td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div" variant="h5">Eligibility Factors</Typography>
            {iaRecord.eligibilityFactors.length === 0
              ? <div>(none)</div>
              : <ul className='tight-ul'>
                {iaRecord.eligibilityFactors.map((factor) => (
                  <li key={factor}>{factor}</li>
                ))}
              </ul>
            }
          </Grid>
          <Grid item xs={6} data-testid='requestedProviderCard'>
            <Typography component="div" variant="h5">Requesting Provider</Typography>
            <div>
              <span className='pr-20'>{iaRecord.dateRangeName}</span>
              {iaRecord.programName}
            </div>
            {iaRecord.serviceProviderLogoS3Key !== null && <img
              src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${iaRecord.serviceProviderLogoS3Key}`}
              alt='logo'
              style={{ maxWidth: '100px' }}
            />
            }
            <div>{iaRecord.serviceProviderName}</div>
            <div>{iaRecord.facilityName}</div>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div" variant="h5">Requested By</Typography>
            <div>{iaRecord.requestingUserName}</div>
            <div>{iaRecord.requestingUserEmail}</div>
            <div>On {FormatDateTimeValue(iaRecord.requestDate)}</div>
          </Grid>
        </Grid>
        }

      />

      <TextWithLabel testId='requestDetails' label='Request Notes' value={iaRecord.requestDetails} name={'requestDetails'} />
      {props.isWaiver && (readOnly
        ? <TextWithLabel value={iaRecord.reasonId != null ? reasons[iaRecord.reasonId] : ''} label={'Reason'} name={'reasonId'}/>
        : <FormControl>
          <FormLabel id="reasonId">Reason</FormLabel>
          <RadioGroup
            aria-labelledby="reasonId"
            name="reasonId"
            value={reasonId}
            onChange={handleReasonChange}
          >
            <FormControlLabel value="0" control={<Radio />} label={reasons[0]} />
            <FormControlLabel value="1" control={<Radio />} label={reasons[1]} />
            <FormControlLabel value="2" control={<Radio />} label={reasons[2]} />
            <FormControlLabel value="3" control={<Radio />} label={reasons[3]} />
          </RadioGroup>
        </FormControl>
      )
        }

      {!readOnly && <TextAreaWithLabel
        name="responseNotes"
        label="Response Notes"
        dataTestId="responseNotes"
        value={responseNotes}
        onChange={handleResponseNotesChange}
      />
      }
      {readOnly && <TextWithLabel label='Approval Status' value={(iaRecord.isApproved === true) ? 'Approved' : 'Denied'} name={'responseNotes'} />}
      {readOnly && <TextWithLabel label='Response Notes' value={iaRecord.responseNotes} name={'responseNotes'} />}

      <BannerModal
        open={showDocuments}
        onClose={() => { setShowDocuments(false) }}
        onConfirm={() => { setShowDocuments(false) }}
        title='Child Documents'
        hideXtoClose={false}
        hideCancelButton={true}
        confirmButtonText='Close'
        content={<div>
          <ReferralDocuments referralID={iaRecord.referralID}
            childID={iaRecord.childID}
            showChildDocsIfNoneOnReferral={false}
            readOnly={true}
            years={iaRecord.YearList}
            dataChanged={props.setRefreshTime}
          />
        </div>}
      />
    </Box>

    if (readOnly) {
      return <Modal
        onClose={handleClose}
        open={props.open}
        hideCancelButton={true}
        title={'Income Approval'}
        confirmationContent={content}
        confirmButtonText={'Close'}
        maxWidth={'sm'}
        data-testid='referral-details-modal'
        onConfirm={handleClose}
      />
    } else {
      return <AcceptDenyModal
        open={props.open}
        title={'Income Approval'}
        dialogContent={content}
        acceptButtonText={'Approve'}
        denyButtonText={'Deny'}
        onClose={handleClose}
        onAccept={handleApprove}
        onDeny={handleReject}
        maxWidth={'sm'}
        data-testid='referral-details-modal'
      />
    }
  }
}
