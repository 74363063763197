import { Box, Grid, Typography } from '@mui/material'
import { type documentEditModel, type documentTypeModel } from '.'
import SimpleAccordion from '../Accordian'
import FilePicker from '../FilePicker'
import S3Image from '../S3Image'
import { DocumentDisplay } from './DocumentDisplay'
import { type income } from '../../core/types'
import { FormatCurrency } from '../../core/Utilities'
import { RadiusCard } from '../Cards/Radius/RadiusCard'
import { WarningCircle } from '../../Assets/SVGs/WarningCircle'

interface DocumentAccordionProps {
  documentType: documentTypeModel
  editable: boolean
  canVerify: boolean
  downloadDocument?: (id: number) => void
  uploadDocument: (file: File, year: number | null) => void
  onVerifiedChanged: (index: number, verified: boolean) => void
  onEdit?: (record: documentEditModel) => void
  onDelete: (id: number, index: number) => void
  incomeRecords: income[] | null
}

export function DocumentAccordion (props: DocumentAccordionProps): JSX.Element {
  let displayClass = ''
  if (props.documentType.required && props.documentType.documents.length === 0) {
    displayClass = 'error-fill'
  } else if (props.documentType.required) {
    displayClass = 'warning-fill'
  }
  const image = props.documentType.imageFile !== null ? <S3Image imageKey={props.documentType.imageFile} /> : <></>

  const documentSection = (income: income | null | undefined): JSX.Element => {
    // undefined means income is not applicable, null means filter for documents where no income is assigned, if there is a value for income then show documents tied to it
    let documents = null
    if (income === undefined) {
      documents = props.documentType.documents
    } else if (income === null) {
      documents = props.documentType.documents.filter(d => d.incomeYear === null)
    } else {
      documents = props.documentType.documents.filter(d => d.incomeYear === income.year)
    }
    return <Box>
      {income !== null && income !== undefined && <table>
        <tr className='padless-table'>
          <td>
            <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }}>Taxable Income: {FormatCurrency(income.annualIncome)}</Typography>
          </td>
          {documents.length === 0 &&
            <>
              <td rowSpan={2} style={{ verticalAlign: 'top' }} >
                <WarningCircle fill='red' size={20} />
              </td>
              <td rowSpan={2} >
                No enrollments will be allowed in sessions that require income for this tax year until these documents have been uploaded.
              </td>
            </>
          }
        </tr>
        <tr>
          <td>
            <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }} >Household Member Count: {income.memberCount}</Typography>
          </td>
        </tr>
      </table>}
      {props.editable && income !== null && <Box className='mt-5'>
        <FilePicker
          label='Upload a file'
          name='file'
          onSelect={(e: any) => {
            props.uploadDocument(e.target.files[0], income?.year ?? null)
          }}
        />
      </Box>
      }
      {documents.map((d, i) => {
        return <DocumentDisplay
          key={i}
          document={d}
          canVerify={props.canVerify}
          canEdit={props.editable && income !== undefined}
          onDelete={() => { props.onDelete(d.id, i) }}
          onEdit={props.onEdit === undefined ? undefined : () => { if (props.onEdit !== undefined) props.onEdit(d) }} // the React compiler complains if you don't have the extra if in here
          onVerifyChanged={(verified: boolean) => { props.onVerifiedChanged(i, verified) }}
          onDownload={props.downloadDocument}
        />
      })

      }
    </Box>
  }

  return <SimpleAccordion
    dataTestid={`documentType${props.documentType.documentTypeID}Accordian`}
    title={props.documentType.name} key={props.documentType.documentTypeID.toString() + 'accordian'}
    width={'100%'}
    icon={image}
    summary={props.documentType.documentDescription ?? ''}
    className={displayClass}
    sx={{
      borderRadius: '32px !important',
      border: '1px solid lightgray',
      mb: '15px',
      ':first-of-type': {
        borderRadius: '16px'
      },
      ':last-of-type': {
        borderRadius: '16px'
      },
      '&.Mui-expanded:last-of-type': {
        mb: '25px'
      }
    }}

    body={
      <Box sx={{ fontWeight: 400, fontSize: '0.75em' }} data-testid={displayClass}>
        <Typography variant='body1' fontWeight={'bold'}>{props.documentType.name}</Typography>
        {props.editable && <Box>{'Please upload your most recent documentation supporting '} {props.documentType.name} {'.'}
          {(props.documentType.documentDescription ?? '') !== '' ? ` For example ${props.documentType.documentDescription ?? ''}` : ''}</Box>}
        <Typography variant='body1' className='mt-5'>Uploaded Documents</Typography>
        {props.incomeRecords === null && documentSection(undefined)}
        {props.documentType.incomeRelated && (props.incomeRecords?.length ?? 0) === 0 &&
          <Grid container>
            <Grid item xs={1}>
              <WarningCircle fill='red' size={20} />
            </Grid>
            <Grid item xs={11}>You need to add an income record for this caregiver before you can upload income documents.</Grid>
          </Grid>
        }
        {props.documentType.incomeRelated && props.documentType.documents.find(d => d.incomeYear === null) !== undefined &&
          <RadiusCard content={documentSection(null)} header={'No Tax Year Assigned'} fullWidth={true} />
        }
        {props.incomeRecords?.map((r, i) => { return <RadiusCard content={documentSection(r)} fullWidth={true} header={`Tax Year: ${r.year}`} /> })}
      </Box>
    }
    tag={props.documentType.documentStatus}
  />
}
