import { Box } from '@mui/material'
import { type postError } from '../../core/types'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { FormatDateValue } from '../../core/Utilities'
import { TextAreaWithLabel } from '../../Components/TextArea'
import { type AccessRequestData } from './AccessRequestReview'

export interface AccessRequestReviewProps {
  accessRequest: AccessRequestData
  setAccessRequest: (e: any) => void
  errors: postError[] | undefined
}

export function AccessRequestEdit (props: AccessRequestReviewProps): JSX.Element {
  const accessRequest = props.accessRequest

  if (accessRequest == null) return <div>Record not found</div>

  return <Box >
    {props.errors?.map((err) => (
      <li key={err.field} style={{ color: 'red', padding: '10px 0 0' }}>
        {err.error}
      </li>
    ))}

    <table role='presentation' className='padded-table'>
      <tbody>
        <tr>
          <td>
            <TextWithLabel label={'Service'} value={accessRequest.requestedServiceName} name={'service'} testId='service' />
          </td>
          <td>
            <TextWithLabel label={'Program'} value={accessRequest.requestedProgramName} name={'program'} testId='program' />
          </td>
        </tr>
        {accessRequest.isApproved !== null &&
          <tr>
            <td colSpan={2}>
              <TextWithLabel label={'Request Status'} value={accessRequest.requestStatus} name={'requestStatus'} testId='requestStatus' />
            </td>
          </tr>
        }
        <tr>
          <td>
            <TextWithLabel label={'Requested By'} value={accessRequest.requestingUserName} name={'rb'} testId='requestedBy' />
          </td>
          <td>
            <TextWithLabel label={'Regarding'} value={accessRequest.childName} name={'cn'} testId='regarding' />
          </td>
        </tr>
        <tr>
          <td>
            <TextWithLabel label={'Request Date'} value={FormatDateValue(accessRequest.requestDate)} name={'rd'} testId='requestDate' />
          </td>
          <td>
            <TextWithLabel label={'Requesting Organization'} value={accessRequest.requestingOrganizationName} name={'fromName'} testId='requestingOrganization' />
          </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <div className={'ml-10'}>{accessRequest.organizationPhone}</div>
            <div className={'ml-10'}>{accessRequest.organizationAddress}</div>
            <div className={'ml-10'}>{accessRequest.organizationCity} {accessRequest.organizationState} {accessRequest.organizationZipCode}</div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <TextWithLabel label={'Request Details'} value={accessRequest.explanation} name={'rd'} testId='requestDetails' />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <TextWithLabel label={'Request to'} value={accessRequest.requestedOrganizationName} name={'toName'} testId='requestTo' />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {accessRequest.isApproved === null &&
              <TextAreaWithLabel
                name="responseNotes"
                label="Response Notes"
                value={accessRequest.responseNotes}
                onChange={props.setAccessRequest}
                dataTestId='responseNotes'
              />
            }
            {accessRequest.isApproved !== null &&
              <TextWithLabel label={'Response Notes'} value={accessRequest.responseNotes} name={'responseNotes'} testId='responseNotes' />
            }
          </td>
        </tr>
      </tbody>
    </table>
  </Box>
}
