import { Box, Chip } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { LargeCard } from '../Cards/Large'
import { Dropdown } from '../Dropdown'
import { type carenetworkService } from '../../core/types'
import EllipsisIcon from '@mui/icons-material/MoreHoriz'
import { FormatDateAsTimeAgo } from '../../core/Utilities'
import './ServiceCard.css'

interface ServiceCardProps {
  service: carenetworkService
  childId: number
  onClick?: (service: carenetworkService) => void
  onCancel?: (service: carenetworkService) => void
  onRelease?: (service: carenetworkService) => void
  onTransfer?: (service: carenetworkService) => void
}

export function ServiceCard (props: ServiceCardProps): JSX.Element {
  const programIconColor = '#4DA4A4 !important'

  let statusLabel = props.service.status
  let iconColor = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  if (props.service.status === 'Waiting') {
    statusLabel += ' - ' + (props.service.queueOrder + 1).toString()
    iconColor = '#FFA500 !important'
    backgroundColor = 'rgba(255, 165, 0, 0.1)'
  } else if (props.service.status === 'Waiver') {
    iconColor = '#9D25C8 !important'
    backgroundColor = 'rgba(157, 37, 200, 0.1)'
  } else if (props.service.status === 'Exited' || props.service.status === 'Cancelled' || props.service.status === 'Denied' || props.service.status === 'Transferred') {
    iconColor = '#D4316C !important'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }

  const cardClick = (): void => {
    if (props.onClick != null) { props.onClick(props.service) }
  }

  const editService = (): void => {
    if (props.onClick != null) { props.onClick(props.service) }
  }

  const cancelService = (): void => {
    if (props.onCancel != null) { props.onCancel(props.service) }
  }

  const releaseService = (): void => {
    if (props.onRelease != null) { props.onRelease(props.service) }
  }

  const TransferService = (): void => {
    if (props.onTransfer != null) { props.onTransfer(props.service) }
  }

  const getDropdownOptions = (status: string): any => {
    const dropdownOptions = []
    dropdownOptions.push({ name: 'Edit', onClick: editService })
    if (status === 'Pending' || status === 'Waiting') {
      dropdownOptions.push({ name: 'Cancel Request', onClick: cancelService })
    }
    if (status === 'Waiting') {
      dropdownOptions.push({ name: 'Release to Provider', onClick: releaseService })
    }
    if (props.service.status === 'Pending' || props.service.status === 'Waiting' || props.service.status === 'Waiver') {
      dropdownOptions.push({ name: 'Transfer', onClick: TransferService })
    }
    return dropdownOptions
  }

  const actions = <Box className='d-flex f-justify-content-space-between' data-testid='cardfooter'>
    <Dropdown
      data-testid='service-card-setting'
      selectComponent={<EllipsisIcon />}
      options={getDropdownOptions(props.service.status)}
      onOpenMenu={() => { }}
    />
  </Box>

  const content = <>
    <Box className='service-card-content' data-testid='service-card' onClick={cardClick}>
      <Box>
        {props.service.type === 'referral' ? props.service.referralTimeFrame : props.service.sessionBlockName}
        <Chip
          icon={<FiberManualRecordIcon sx={{ color: programIconColor }} />}
          label={props.service.systemProgramName ?? 'Missing Program'}
          size="small"
          sx={{ color: programIconColor, backgroundColor: 'rgba(77, 164, 164, 0.1)' }}
        />
      </Box>
      <span>{props.service.systemServiceProviderName}</span>
      <Chip icon={<FiberManualRecordIcon sx={{ color: iconColor }} />} label={statusLabel} size="small" sx={{ color: iconColor, backgroundColor }} />
      <span>{props.service.lastModified !== null && FormatDateAsTimeAgo(props.service.lastModified)}</span>
    </Box>
  </>

  return <LargeCard
    key={props.service.systemServiceID}
    header={props.service.systemServiceName}
    content={content}
    data-testid={`service-card-${props.service.systemServiceID}`}
    className='service-card'
    contentClassName='service-card-content-wrapper'
    headerClassName='service-card-header'
    actions={actions}
    onClick={props.onClick !== undefined ? cardClick : undefined}
  />
}
