import { Button } from '@mui/material'
import { buttonClasses } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import type * as React from 'react'

export const RoundButton = styled(Button)<{ component: string }>(({ theme }) => ({
  [`&.${buttonClasses.outlined}`]: {
    borderRadius: 100,
    color: '#484b4a',
    borderColor: '#484b4a'
  }
}))

interface Props {
  label?: string
  name?: string
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function FilePicker (props: Props): JSX.Element {
  const label = props?.label != null ? props.label : 'Choose File'
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.onSelect != null) {
      props.onSelect(event)
    }
  }
  return (
    <RoundButton variant="outlined" component="label" data-testid='FilePicker'>
      {label}
      <input hidden type="file" name={props.name} onChange={handleFileSelect} />
    </RoundButton>
  )
}
