import { Button, Grid, Typography } from '@mui/material'
import { type PortalChildDetails, type EnrollmentDetails } from '../../../../core/types'
import { deleteSession, initialEnrollmentDetails, saveSession, useCaregiverWizardUpdateDispatch } from '../../../../ContextProviders/CaregiverWizard'
import { useEffect, useState } from 'react'
import { Modal } from '../../../../Components/Modal'
import { generatePath, useNavigate, useParams } from 'react-router'
import { CAREGIVER_PORTAL } from '../../../Routes'
import { childAge, childName } from '../../PortalHelpers'
import { useScrollTop } from '../../../../hooks/use-scrollTop'

export interface ChildrenSummaryProps {
  handleStep: (backwards: boolean) => void
  caregiverForm: EnrollmentDetails
}

export function ChildrenSummary (props: ChildrenSummaryProps): JSX.Element {
  const dispatch = useCaregiverWizardUpdateDispatch()
  const nav = useNavigate()
  const { isdId, isdServiceProviderId } = useParams()
  const caregiverForm = props.caregiverForm
  const children = caregiverForm.children
  const [otherChildren, setOtherChildren] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)

  useScrollTop()

  useEffect(() => {
    // If the user has some old updates that they haven't saved, clear them out and reset the flag
    const resetUpdateFlag = async (): Promise<void> => {
      const child = children.find(c => c.isCurrentlyUpdating)
      if (child != null) {
        child.isCurrentlyUpdating = false
        const initialForm = initialEnrollmentDetails()
        const tempForm = { ...caregiverForm, currentChild: initialForm.currentChild }
        await saveSession(tempForm)
        dispatch({ type: 'form', form: tempForm })
      }
    }
    // If the user has no children, redirect them to the add child page
    const redirectIfNecessary = async (): Promise<void> => {
      if (children.length === 0) {
        const tempForm = { ...caregiverForm, currentSubStep: 2 }
        await saveSession(tempForm)
        dispatch({ type: 'form', form: tempForm })
      }
    }

    if (caregiverForm.currentStep === 1) {
      void resetUpdateFlag()
      void redirectIfNecessary()
    }
  }, [])

  const handleChildClick = async (child: PortalChildDetails): Promise<void> => {
    // Put the selected child in currentChild and track which one we are updating
    child.isCurrentlyUpdating = true
    const tempForm = { ...caregiverForm, currentSubStep: 2, currentChild: child, unbornChild: child.unborn }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleUnbornClick = async (unbornChild: boolean): Promise<void> => {
    const tempForm = { ...caregiverForm, unbornChild, currentSubStep: 2 }
    await saveSession(tempForm)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleContinue = async (): Promise<void> => {
    let subStep = 1
    if (caregiverForm.caregivers.length > 0) {
      subStep = 2
    }
    const tempForm = { ...caregiverForm, currentStep: 2, currentSubStep: subStep }
    await saveSession(tempForm)
    props.handleStep(false)
    dispatch({ type: 'form', form: tempForm })
  }

  const handleConfirm = (): void => {
    deleteSession()
    dispatch({ type: 'form', form: null })
    setIsConfirmModalOpen(false)

    const portalRoute = generatePath(CAREGIVER_PORTAL, { isdId: isdId ?? '', isdServiceProviderId: isdServiceProviderId ?? '' })
    nav(portalRoute)
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' sx={{ fontWeight: 600 }} component='div'>
          Children
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children.map(child => {
          return <Typography
            key={childName(child) + (child.dateOfBirth?.toString() ?? '') + (child.dueDate?.toString() ?? '')}
            variant='h6'
            component='div'
            className='clickable-row'
            data-testid='clickable-row'
            onClick={async () => { await handleChildClick(child) }}
          >
            {childName(child)} {childAge(child)}
          </Typography>
        })}
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' sx={{ mb: '10px', mt: '30px' }} component='div'>
          Do you have other children that need early childhood services at this time?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Button variant="outlined" className={'wide-button' + (otherChildren ? ' selected' : '')} data-testid='other-children-yes' onClick={() => { setOtherChildren(true) }}>Yes</Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="outlined" className={'wide-button' + (otherChildren ? '' : ' selected')} data-testid='other-children-no' onClick={() => { setOtherChildren(false) }}>No</Button>
      </Grid>

      {otherChildren && <>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ mb: '10px', mt: '30px' }} component='div'>
            Is this an unborn child?
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" className='wide-button' data-testid='handle-unborn-yes' onClick={async () => { await handleUnbornClick(true) }}>Yes</Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" className='wide-button' data-testid='handle-unborn-no' onClick={async () => { await handleUnbornClick(false) }}>No</Button>
        </Grid>
      </>}

      <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
        <Button
          name='backButton'
          className='back-button'
          data-testid='back-button'
          onClick={() => { setIsConfirmModalOpen(true) }}
          variant='outlined'
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ mb: '20px', mt: '40px' }}>
        <Button
          name='continueButton'
          className='footer-button'
          data-testid='continue-button'
          onClick={handleContinue}
          variant='contained'
        >
          Continue
        </Button>
      </Grid>
    </Grid>

    <Modal
      open={isConfirmModalOpen}
      onClose={() => { setIsConfirmModalOpen(false) }}
      title='Discard Changes'
      confirmationContent='Going back from here will result in loss of data. Do you wish to continue?'
      confirmButtonText='Yes, take me back'
      cancelButtonText='No, stay here'
      onConfirm={handleConfirm}
      data-testid='discard-changes-modal'
    />
  </>
}
