import { Box } from '@mui/material'
import { BannerModal } from '../../../../Components/Modal/BannerModal'
import { TextFieldWithLabel } from '../../../../Components/TextField'
import { useEffect, useState } from 'react'
import { MapModule, useBingMaps } from '../../../../hooks/use-bingMaps'
import { toast } from 'react-toastify'

interface ChangeAddressModalProps {
  open: boolean
  setShowModal: (show: boolean) => void
  updateCurrentAddress: (address: string | null, LocationRect: Microsoft.Maps.LocationRect | null, zipCode: string | null) => void
}

export function ChangeAddressModal (props: ChangeAddressModalProps): JSX.Element {
  const [address, setAddress] = useState<string | null>(null)
  const [zipCode, setZipCode] = useState<string | null>(null)
  const [locationRect, setLocationRect] = useState<Microsoft.Maps.LocationRect | null>(null)
  const [hasRendered, setHasRendered] = useState(false)

  const handlePlaceSelected = (place: Microsoft.Maps.ISuggestionResult): void => {
    setAddress(place.formattedSuggestion)
    setLocationRect(place.bestView)
  }

  useEffect(() => {
    // This prevents the bing maps hook from running before the search box is rendered
    setHasRendered(true)
  }, [])

  useBingMaps({
    modules: [MapModule.AutoSuggest],
    selectFunction: handlePlaceSelected,
    inputID: 'address',
    hasRendered
  })

  const handleConfirm = (): void => {
    if (locationRect == null && zipCode === null) {
      toast.error('Please either select a valid address or enter a Zip Code')
      return
    } else if (locationRect !== null && zipCode !== null) {
      setZipCode(null)
    }

    props.updateCurrentAddress(address, locationRect, zipCode)
    props.setShowModal(false)
  }

  const content = <>
    <div>Enter either a ZIP Code or an Address</div>
    <TextFieldWithLabel
      label='ZIP Code'
      name='zipCode'
      onChange={(e) => { setZipCode(e.target.value) }}
      value={zipCode}
      maxLength={5}
    />
    <TextFieldWithLabel
      ariaRole='combobox'
      ariaExpanded={false}
      ariaControls='as_containerSearch_address'
      name='address'
      label='Address'
      autoComplete='off'
      data-testid='address'
      value={address}
      onChange={(e) => { setAddress(e.target.value) }}
      className='pt-0'
      textFieldClassName='pb-0'
    />
    <Box id='searchBoxContainer'></Box>
  </>

  return <BannerModal
    maxWidth='xs'
    open={props.open}
    title='Change Current Address'
    content={content}
    cancelButtonText='Cancel'
    confirmButtonText='Confirm'
    confirmButtonColor='info'
    onClose={() => { props.setShowModal(false) }}
    onConfirm={handleConfirm}
    className='banner-modal address-modal'
    buttonClassName='banner-modal-button'
    dataTestId='change-address-modal'
  />
}
