import { Box, Grid, IconButton, Paper } from '@mui/material'
import { LargeCard } from '../../Components/Cards/Large'
import AlertMessage from '../../Components/AlertMessage'
import S3Image from '../../Components/S3Image'
import { TextWithLabel } from '../../Components/TextWithLabel'
import { type ISDDetail } from '.'
import EditIcon from '@mui/icons-material/Edit'
import { useState } from 'react'
import { ISDDetailsEdit } from './ISDDetailsEdit'
import { CheckBoxWithLabel } from '../../Components/CheckBox'
import { ErrorProvider } from '../../ContextProviders/ErrorProvider'

export interface ISDDetailsProps {
  isd: ISDDetail | undefined
  onRefresh: () => void
}

export function ISDDetails (props: ISDDetailsProps): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false)
  const [showKeyDetails, setShowKeyDetails] = useState(false)
  const [showSecretDetails, setShowSecretDetails] = useState(false)

  const isd = props.isd

  if (isd === undefined || isd.id === undefined) {
    return <></>
  }

  const showModal = (): void => {
    setModalVisible(true)
  }

  const hideModal = (): void => {
    setModalVisible(false)
  }

  const detailsUpdated = (): void => {
    setModalVisible(false)
    props.onRefresh()
  }

  const identityContent = <Box>
    <TextWithLabel label='Name' testId='isd-name' name='Name' value={isd.name} />
    <TextWithLabel label='EEM Code' testId='eem-code' name='EEM Code' value={isd.id.toString() + '000'} />
  </Box>

  const infoContent = <Box>
    <TextWithLabel label='Website' testId='website' name='website' value={isd.website ?? ''} />
    {isd.logoKey != null &&
      <Box>
        <S3Image alt='logo' imageKey={isd.logoKey} />
      </Box>
    }
  </Box>

  const locationContent = <Box>
    <TextWithLabel label='Street Address' testId='address' name='address' value={isd.address} />
    <Box className="d-flex">
      <TextWithLabel label='City' testId='city' name='city' value={isd.city} className='mr-5' />
      <TextWithLabel label='State' testId='state' name='state' value={isd.state} className='mr-5 width-60px' />
      <TextWithLabel label='Zip' testId='zip-code' name='zipCode' value={isd.zipCode} />
    </Box>
  </Box>

  const contactContent = <Box className="d-flex">
    <TextWithLabel testId='contact-email' label='Email' name='contactEmail' value={isd.contactEmail ?? ''} className='mr-5' />
    <TextWithLabel label='Phone' testId='phone' name='contactPhone' value={isd.contactPhone ?? ''} />
  </Box>

  const providerContent = <Box>
    {isd.intakeProviderName == null &&
      <AlertMessage message={'Without an intake provider, all incoming care networks will not be directly assigned to anyone for review'} type={'error'} />
    }
    <TextWithLabel label='Provider Name' value={isd.intakeProviderName} name={'intakeProvider'} />
  </Box>

  const connectionContent = <Box>
    {isd.uicapiKey == null && isd.uicapiSecret == null
      ? <div>(not entered)</div>
      : <>
        <Box className="d-flex">
          <Box className='mt-10'>
            <CheckBoxWithLabel label='Show Value' name='showKeyDetails' value={showKeyDetails} edge='end' setValue={() => { setShowKeyDetails(!showKeyDetails) }} />
          </Box>
          <Box>
            <TextWithLabel label='UIC API Key' value={isd.uicapiKey} name={'uICAPIKey'} className={showKeyDetails ? '' : 'blur-value'} />
          </Box>
        </Box>
        <Box className="d-flex">
          <Box className='mt-10'>
            <CheckBoxWithLabel label='Show Value' name='showSecretDetails' value={showSecretDetails} edge='end' setValue={() => { setShowSecretDetails(!showSecretDetails) }} />
          </Box>
          <Box>
            <TextWithLabel label='UIC API Secret' value={isd.uicapiSecret} name={'uicapiSecret'} className={showSecretDetails ? '' : 'blur-value'} />
          </Box>
        </Box>
      </>
    }
  </Box>

  return <Box sx={{ width: '100%;' }} data-testid='isd-details-container'>
    {modalVisible && (
      <ErrorProvider>
        <ISDDetailsEdit show={modalVisible} onClose={hideModal} isd={isd} onConfirm={detailsUpdated} />
      </ErrorProvider>
    )}
    <Paper sx={{ pt: 2, borderRadius: '32px', marginTop: '20px' }}>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <IconButton data-testid="editButton" onClick={showModal} >
          <EditIcon />
        </IconButton>
      </Grid>

      <Box className="d-flex f-justify-content-space-between">
        <Box className="d-flex f-direction-column width-100">
          <LargeCard header='Name & Identity' content={identityContent} className='Borderless' fullWidth={true} />
          <LargeCard header='Information' content={infoContent} className='Borderless' fullWidth={true} />
          <LargeCard header='API Connection' content={connectionContent} className='Borderless' fullWidth={true} />
        </Box>
        <Box className="d-flex f-direction-column ml-10 width-100">
          <LargeCard header='Location' content={locationContent} className='Borderless' fullWidth={true} />
          <LargeCard header='Contact' content={contactContent} className='Borderless' fullWidth={true} />
          <LargeCard header='Intake Provider' content={providerContent} className='Borderless' fullWidth={true} />
        </Box>
      </Box>
    </Paper>
  </Box>
}
