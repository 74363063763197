/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { type location, type systemServiceProvider } from '../../core/types'
import { sendPostFormData } from '../../hooks/use-fetch'
import { Box, Button, Grid, Typography } from '@mui/material'
import { TextFieldWithLabel } from '../../Components/TextField'
import { LabelWithToolTip } from '../../Components/Label/Index'
import S3Image from '../../Components/S3Image'
import DragDropFiles from '../../Components/DragAndDrop'
import { LargeCard } from '../../Components/Cards/Large'
import { Modal } from '../../Components/Modal'
import CancelIcon from '@mui/icons-material/Cancel'
import { LocationDetails } from '../../Components/Cards/LocationCard/LocationDetails'
import { ErrorDisplay } from '../../ContextProviders/ErrorProvider'
import { MapStateName } from '../../Components/Cards/LocationCard/mapStateName'

interface SystemServiceProviderEditProps {
  details: systemServiceProvider
  onSave: (data: systemServiceProvider, file: File | null) => Promise<void>
  onClose: () => void
  show: boolean
}

export function SystemServiceProviderEdit (props: SystemServiceProviderEditProps): JSX.Element {
  const [data, setData] = useState<systemServiceProvider>(props.details)

  useEffect(() => {
    setData(props.details)
  }, [props.show])

  const [files, setFiles] = useState<FileList | null>(null)

  const handleSubmit = async (): Promise<void> => {
    await props.onSave(data, files?.[0] ?? null)
  }

  const handleChange = (e: any): void => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleLocChange = (e: any): void => {
    const loc = { ...data.location } as location
    setData({ ...data, location: { ...loc, [e.target.name]: e.target.value } })
  }
  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: location = { ...data.location } as location
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode

    setData({ ...data, location: newplace })
  }

  const identityDisplay = <Box width={'100%'}>
    <TextFieldWithLabel
      label='Name'
      data-testid='sp-name'
      onChange={handleChange}
      value={data?.name ?? ''}
      disabled={props.details.id !== 0}
      name='name'
    />
    <TextFieldWithLabel
      label='EEM Code'
      data-testid='sp-eem'
      onChange={handleChange}
      value={data?.eemCode ?? ''}
      disabled={props.details.id !== 0}
      name='eemCode'
    />
  </Box>

  const addressDisplay = <Grid container sx={{ display: 'flex', flexDirection: 'row' }} spacing={1} columns={12}>
    <Box display={'block'}>
      <ErrorDisplay fieldName='Address' />
      <ErrorDisplay fieldName='City' />
      <ErrorDisplay fieldName='State' />
      <ErrorDisplay fieldName='ZipCode' />
    </Box>
    <Grid item sm={12} md={12}>
      <LocationDetails
        onPlaceSelected={handlePlaceSelected}
        editData={data.location ?? {} as location}
        onChange={handleLocChange}
        showHomelessOption={false}
        hidePrimary
      />
    </Grid>
  </Grid>

  const removeLogo = async (): Promise<void> => {
    const updatedIsd = { ...data, imageKey: null }
    setData(updatedIsd)
    setFiles(null)
    const fileData = new FormData()
    fileData.append('id', data.id.toString())
    await sendPostFormData('/ServiceProviderDetails/UploadLogo', fileData)
  }

  const informationDisplay = <Box width={'100%'}>
    <TextFieldWithLabel label='Website' onChange={handleChange} value={data?.website ?? ''} name='website' dataTestId='website' />
    <LabelWithToolTip labelText='Logo' toolTipText='Upload Icon.' />
    {data.imageKey != null && <Box>
      <S3Image style={{ maxWidth: '100px', maxHeight: '100px' }} imageKey={data.imageKey ?? ''} alt='logo' />
      <Button onClick={removeLogo} data-testid='remove-logo' sx={{ paddingLeft: 0, verticalAlign: 'top', minWidth: '45px' }}><CancelIcon sx={{ color: 'black' }} /></Button>
    </Box>
    }
    {files !== null && files !== undefined && (files?.length ?? 0) > 0 && (<>
      Uploading {files[0].name}
    </>)}
    <DragDropFiles files={files} setFiles={setFiles} instructions='+ Add Image' fileType='image/*' removable={false} />
  </Box>

  const content = <Box width={'100%'}>
    <Typography>Settings - {data?.name}</Typography>
    <Grid container sx={{ display: 'flex', flexDirection: 'row' }} columns={12} rowSpacing={1}>
      <Grid item sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <LargeCard
          fullWidth
          header='Name & Identity'
          content={identityDisplay}
        />
      </Grid>
      <Grid item sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <LargeCard
          fullWidth
          header='Information'
          content={informationDisplay}
        />
      </Grid>
      <Grid item sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <LargeCard
          fullWidth
          header='Address'
          content={addressDisplay}
        />
      </Grid>
    </Grid>
  </Box>

  return <Modal
    onClose={props.onClose}
    open={props.show}
    onConfirm={handleSubmit }
    confirmationContent={content}
  />
}
