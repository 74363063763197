import { Box } from '@mui/material'
import { SiblingAddList } from '../../../Components/Cards/MultiCardList/SiblingAddList'
import { CaregiverAddList } from '../../../Components/Cards/MultiCardList/CaregiverAddList'

export function AddChildCareNetwork (): JSX.Element {
  return <Box width={'100%'}>
    <CaregiverAddList
      label='Caregivers'
      hideDocumentLink={true}
    />
    <SiblingAddList
      label='Siblings'
    />
  </Box>
}
