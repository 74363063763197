import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Modal } from '../../Components/Modal'
import { SelectWithLabel, type SelectOption } from '../../Components/SelectWithLabel'
import { TextFieldWithLabel } from '../../Components/TextField'
import { type ISDStaff } from './StaffList'
import FilePicker from '../../Components/FilePicker'
import { HierarchyCheckList, type functionType } from '../../Components/CheckList/HierarchyCheckList'
import { sendGet } from '../../hooks/use-fetch'
import { LabelWithToolTip } from '../../Components/Label/Index'

export interface StaffDetailProps {
  item: ISDStaff
  open: boolean
  isNew: boolean
  onClose: () => void
  onSubmit: (newRowData: ISDStaff, file: File | null) => void
  errors?: string[] | undefined
}

export enum ISDStaffRole {
  Full = 'Full',
  Advanced = 'Advanced',
  Basic = 'Basic',
  ReadOnly = 'ReadOnly',
  Custom = 'Custom'
}

export function StaffDetail (props: StaffDetailProps): JSX.Element {
  const [user, setUser] = useState(props.item)
  const [file, setFile] = useState<File>()
  const [functionList, setFunctionList] = useState<functionType[]>([])
  const [checkedValues, setCheckedValues] = useState<string[]>(user.functionList)

  const handleChange = (e: any): void => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  async function getAllFunctions (firstLoad: boolean, accessLevel?: string): Promise<void> {
    const access = accessLevel == null ? user.accessLevel : accessLevel
    const request = await sendGet(`/User/AllUserFunctionsByAccessAndSecurity?userSecurityLevel=${user.userType}&accessLevel=${access}`, {})
    const functions: functionType[] = request.response
    setFunctionList(functions)
    if (firstLoad) {
      setCheckedValues(user.functionList)
    } else {
      let checkedList: string[] = []
      checkedList = getCheckedValues(functions, checkedList)
      setCheckedValues(checkedList)
    }
  }

  function getCheckedValues (functions: functionType[], updatedFunctionValues: string[]): string[] {
    functions.forEach(f => {
      if (f.readPermissionName !== null && f.readPermissionName !== undefined) {
        updatedFunctionValues.push(f.readPermissionName)
      }
      if (f.writePermissionName !== null && f.writePermissionName !== undefined) {
        updatedFunctionValues.push(f.writePermissionName)
      }
      if (f.subPermissions !== null && f.subPermissions.length > 0) {
        getCheckedValues(f.subPermissions, updatedFunctionValues)
      }
    })
    return updatedFunctionValues
  }

  useEffect(() => {
    void getAllFunctions(true, user.accessLevel.toString())
  }, [])

  const title = (props.isNew ? 'Add Staff' : 'Edit Staff')

  const roleOptions: Array<SelectOption<ISDStaffRole>> = [
    { id: ISDStaffRole.ReadOnly, name: 'Read Only' },
    { id: ISDStaffRole.Basic, name: 'Basic' },
    { id: ISDStaffRole.Advanced, name: 'Advanced' },
    { id: ISDStaffRole.Full, name: 'Full' },
    { id: ISDStaffRole.Custom, name: 'Custom' }
  ]
  const statusOptions: Array<{ id: string, name: string }> = [
    { id: 'false', name: 'Active' },
    { id: 'true', name: 'Inactive' }
  ]

  const handleClose = (): void => {
    props.onClose()
  }

  const handleSubmit = (): void => {
    user.functionList = checkedValues
    if (file !== undefined) {
      props.onSubmit(user, file)
    } else {
      props.onSubmit(user, null)
    }
  }

  const content = <Box>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}

    <table role='presentation' className='display-grid'>
      <tr>
        <td>
          <TextFieldWithLabel
            name="firstName"
            label="First Name"
            data-testid="firstName"
            value={user.firstName}
            onChange={handleChange}
          />
        </td>
        <td>
          <TextFieldWithLabel
            name="lastName"
            label="Last Name"
            data-testid="lastName"
            value={user.lastName}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr>
        <td>
          <TextFieldWithLabel
            name="email"
            label="Email"
            type="email" // this renders the input as type=email, but it doesn't actually do anything if an invalid email address is entered (currently at least)
            data-testid="email"
            value={user.email}
            onChange={handleChange}
          />
        </td>
        <td>
          <SelectWithLabel
            name="inactive"
            label="Status"
            width="100%"
            data-testid="status"
            value={user.inactive.toString()}
            options={statusOptions}
            onChange={(e) => {
              setUser({ ...user, inactive: e.target.value === 'true' })
            }}
          />
        </td>
      </tr>
      <tr>
        <td>
          <SelectWithLabel
            name="securityLevel"
            label="Access Level"
            width="100%"
            data-testid="securityLevel"
            value={user.accessLevel}
            options={roleOptions}
            onChange={function (e) {
              setUser({ ...user, accessLevel: e.target.value as ISDStaffRole })
              void getAllFunctions(false, e.target.value.toString())
            }}
          />
          <Box><Typography>{user.userType}</Typography></Box>
        </td>
        <td style={{ paddingTop: '30px' }}>
          <Box>
            <FilePicker
              label='Choose an image'
              name='image'
              onSelect={(e: any) => {
                setFile(e.target.files[0])
              }}
            />
          </Box>
          <Box>
            <TextFieldWithLabel
              disabled
              label='File Name'
              name='fileName'
              onChange={() => { }}
              value={file?.name}
            />
          </Box>
        </td>
      </tr>
    </table>
    <HierarchyCheckList forceReadOnly={user.accessLevel !== ISDStaffRole.Custom} optionsList={functionList} selectedValues={checkedValues} headers={[
      <LabelWithToolTip
        labelText={'Authorized Functions'}
        sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
      />,

      <LabelWithToolTip
        labelText={'Read'}
        sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
      />,

      <LabelWithToolTip
        labelText={'Read/Write'}
        sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word' }}
      />]}
      setSelectedValues={setCheckedValues}
    ></HierarchyCheckList>
  </Box>

  return <Modal
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
