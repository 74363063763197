import { Box, CircularProgress } from '@mui/material'
import { TextFieldWithLabel } from '../../Components/TextField'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { Autocomplete } from '../../Components/AutoComplete'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { type lookup } from '../../core/types'

export interface IncomeApprovalSearchCriteria {
  approvalStatus: string
  childName: string
  userName: string
  serviceProviderID: number
}

export function IncomeApprovalSearchOptions (props: { current: IncomeApprovalSearchCriteria, onChange: (newSearch: IncomeApprovalSearchCriteria) => void }): JSX.Element {
  const [serviceProviders, setServiceProviders] = useState<lookup[]>([])
  const [loaded, setLoaded] = useState(false)
  const account = useAccountId()

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response, error } = await sendGet('/IncomeApproval/GetAllProviders?isdid=' + String(account?.id ?? 0), {})
      if (error[0] === '' && response !== null) {
        setServiceProviders(response)
      }

      setLoaded(true)
    }
    void loadData()
  }, [account])

  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }

  const acUpdate = (source: string, value: number): void => {
    props.onChange({ ...props.current, [source]: value })
  }

  const statusOptions: Array<{ id: string, name: string }> = [
    { id: 'P', name: 'Pending' },
    { id: 'Y', name: 'Approved' },
    { id: 'N', name: 'Denied' }
  ]

  if (!loaded) return <CircularProgress role='loading' />
  return (<Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
    <SelectWithLabel
      name="approvalStatus"
      label="Status"
      width='125px'
      value={props.current.approvalStatus}
      options={statusOptions}
      onChange={handleChange}
    />
    <TextFieldWithLabel
      label='Child Name'
      onChange={handleChange}
      name='childName'
      maxWidth='205px'
      value={props.current.childName}
      dataTestId='childName'
    />
    <TextFieldWithLabel
      label='Requested By'
      onChange={handleChange}
      name='userName'
      maxWidth='205px'
      value={props.current.userName}
      dataTestId='requestedBy'
    />
    { account?.type === 'ISD' && <Autocomplete
      label='Provider'
      name='provider'
      width='200px'
      onChange={(e: any, val) => { acUpdate('serviceProviderID', Number(val?.id ?? -1)) }}
      options={serviceProviders}
      value={props.current.serviceProviderID}
      dataTestId="spsearch"
    />}
  </Box>
  )
}
