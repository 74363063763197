import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocationData } from './useLocationData'
import { MapStateName } from './mapStateName'
import { type location } from '../../../core/types'
import './LocationCard.css'
import { LocationCardDisplay } from './LocationCardDisplay'

interface LocationCardProps {
  locationId: number
  entityId: number
  isPrimary: boolean
  deleteDisabled?: boolean
  onDelete?: () => void
  controller: string
  lookupEndPoint: string
  updateEndPoint: string
  createEndPoint: string
  deleteEndPoint?: string
  setRefreshTime: React.Dispatch<React.SetStateAction<Date>>
}

export function LocationCard (props: LocationCardProps): JSX.Element {
  const { data, errors, loaded, handleUpdate, handleDelete } = useLocationData(props)
  const [edit, setEdit] = useState(props.locationId === 0)
  const initialData = { ...data, isPrimary: props.isPrimary }
  const [editData, setEditData] = useState<location>(initialData)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    setEditData(initialData)
  }, [loaded])

  if (!loaded) return <CircularProgress />

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: location = { ...editData }
    newplace.address = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zipCode = place.address.postalCode
    newplace.isPrimary = false
    setEditData(newplace)
  }

  const handleEditChange = (e: any): void => {
    setEditData({ ...editData, [e.target.name]: e.target.value })
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const openEdit = (): void => {
    setEditData(editData)
    setEdit(true)
    setAnchorEl(null)
  }

  const handleCancel = (): void => {
    setEditData(initialData)
    setEdit(false)
    if (props.locationId === 0 && props.onDelete != null) {
      props.onDelete()
    }
    if (errors !== undefined) {
      errors.length = 0
    }
  }

  const onDelete = async (): Promise<void> => {
    await handleDelete(data.id)
    if (props.onDelete != null) { props.onDelete() }
  }

  const onUpdate = async (): Promise<void> => {
    const success = await handleUpdate(editData)
    if (success === 1) {
      setEdit(false)
      props.setRefreshTime(new Date())
    }
  }

  return (
    <LocationCardDisplay
      errors={errors}
      isPrimary={props.isPrimary}
      showHomelessOption={true}
      data={editData}
      edit={edit}
      anchorEl={anchorEl}
      onEditChange={handleEditChange} onClick={handleClick} onClose={handleClose}
      onEdit={openEdit} onDelete={onDelete} onUpdate={onUpdate} onCancel={handleCancel}
      onPlaceSelected={handlePlaceSelected}
    />
  )
}
