import { CircularProgress, Grid } from '@mui/material'
import { TextFieldWithLabel } from '../../Components/TextField'
import { Autocomplete } from '../../Components/AutoComplete'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'

export interface UserSearchCriteria {
  isds: string
  serviceProviders: string
  inactive: string | undefined
  name: string
  email: string
  securityLevel: null | number
  showCaregiver: boolean
}

export function UserManagementSearch (props: { current: UserSearchCriteria, onChange: (newSearch: UserSearchCriteria) => void }): JSX.Element {
  const handleChange = (e: any): void => {
    props.onChange({ ...props.current, [e.target.name]: e.target.value })
  }
  const acUpdate = (source: string, value: string): void => {
    props.onChange({ ...props.current, [source]: value })
  }

  const [isds, setIsds] = useState<Array<{ id: string, name: string }>>([])
  const [services, setServices] = useState<Array<{ id: string, name: string }>>([])
  const status = [{ id: '', name: 'All' }, { id: 'false', name: 'Active' }, { id: 'true', name: 'Deactivated' }]
  const isCaregiver = [{ id: 'false', name: 'No' }, { id: 'true', name: 'Yes' }]
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/ISD/GetLookup', {})
      if (error[0] === '' && rsp !== null) { setIsds(rsp) }
      const { response: rsp2, error: error2 } = await sendGet('/SystemProviderMaintenance/GetAll', {})
      if (error2[0] === '') { setServices(rsp2) }
      setLoaded(true)
    }
    void loadData()
  }, [])
  if (!loaded) return <CircularProgress role='loading' />

  return (<>
    <Grid item>
      <Autocomplete
        label='ISD'
        name='isdSearch'
        onChange={(e: any, val) => { acUpdate('isds', val?.id.toString() ?? '') }}
        options={isds}
        width='300px'
        value={props.current.isds}
        dataTestId="isdsearch"
      />
    </Grid>
    <Grid item>
      <Autocomplete
        label='Service Provider'
        name='spSearch'
        width='250px'
        onChange={(e: any, val) => { acUpdate('serviceProviders', val?.id.toString() ?? '') }}
        options={services}
        value={props.current.serviceProviders}
        dataTestId="spSearch"
      />
    </Grid>
    <Grid item>
      <Autocomplete
        label='Status'
        name='inactive'
        width='135px'
        onChange={(e: any, val) => {
          acUpdate('inactive', val?.id.toString() ?? '')
        }}
        options={status}
        value={props.current.inactive?.toString() ?? ''}
        dataTestId="statusSearch"
      />
    </Grid>
    <Grid item>
      <TextFieldWithLabel
        label='Name'
        onChange={handleChange}
        name='name'
        maxWidth='205px'
        value={props.current.name}
        data-test-name-search
      />
    </Grid>
    <Grid item>
      <TextFieldWithLabel
        label='Email'
        maxWidth='160px'
        onChange={handleChange}
        name='email'
        disableError={true}
        value={props.current.email}
        data-test-email-search
      />
    </Grid>
    <Grid item>
      <Autocomplete
        label='Include Caregivers'
        name='showCaregiver'
        width='135px'
        onChange={(e: any, val) => {
          acUpdate('showCaregiver', val?.id.toString() ?? '')
        }}
        options={isCaregiver}
        value={props.current.showCaregiver?.toString() ?? 'false'}
        data-test-caregiver-search
      />
    </Grid>
  </ >

  )
}
