import { Box } from '@mui/material'
import { LabelWithToolTip } from '../Label/Index'
import { OnOffSwitch } from './OnOffSwitch'

interface selectProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  label: string
  toolTip?: string
  name: string
  disabled?: boolean
  dataTestId?: string
  isInline?: boolean
}

export function SwitchWithLabel (props: selectProps): JSX.Element {
  return (
    <Box key={props.name} data-testid={props.dataTestId}>
      <Box sx={{ display: (props.isInline ?? false ? 'inline-block' : null) }}>
        <LabelWithToolTip labelText={props.label} toolTipText={props.toolTip} />
      </Box>
      <Box sx={{ display: (props.isInline ?? false ? 'inline-block' : null) }}>
        <OnOffSwitch
          data-testid={props.name + 'Switch'}
          name={props.name}
          value={props.checked}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </Box>
    </Box>
  )
}
