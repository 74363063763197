import { Card, CardContent, CardActions, Typography, Box, IconButton, Grid } from '@mui/material'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { BannerModal } from '../../Modal/BannerModal'

interface RadiusCardProps {
  header: string
  content: JSX.Element
  className?: string
  contentClassName?: string
  headerClassName?: string
  fullWidth?: boolean
  icon?: JSX.Element
  showMenu?: boolean
  confirmationMessage?: string
  viewProfileconfirmationMessage?: string
  showViewProfile?: boolean
  marginTop?: string
  dataTestId?: string
  onModalConfirm?: () => void
  onEditMenu?: () => void
  onViewMenu?: () => void
}

export const RadiusCard = (props: RadiusCardProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openConfirmation, setConfirmation] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [menuType, setMenuType] = useState('DELETE')

  const handleClick = (event: any): void => {
    setAnchorEl(event.target)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleModalClose = (): void => {
    setConfirmation(false)
  }

  const handleEdit = (): void => {
    if (props.onEditMenu !== undefined) { props.onEditMenu() }
    setAnchorEl(null)
  }

  const handleDelete = (): void => {
    if (props.confirmationMessage !== undefined) {
      setConfirmationMessage(props.confirmationMessage)
    }
    setMenuType('DELETE')
    setConfirmation(true)
    setAnchorEl(null)
  }

  const handleConfirm = (): void => {
    if (menuType === 'DELETE') {
      if (props.onModalConfirm !== undefined) { props.onModalConfirm() }
    } else {
      if (props.onViewMenu !== undefined) { props.onViewMenu() }
    }
    setConfirmation(false)
  }

  const handleViewFullProfile = (): void => {
    if (props.viewProfileconfirmationMessage !== undefined) {
      setConfirmationMessage(props.viewProfileconfirmationMessage)
    }
    setMenuType('VIEW')
    setConfirmation(true)
  }

  return (
    <>
      <Card
        sx={{
          maxWidth: props.fullWidth === true ? '100%' : 420,
          borderRadius: 3,
          marginTop: props.marginTop ?? 2,
          border: '1px solid',
          backgroundColor: 'white',
          borderColor: 'lightslategrey'
        }}
        className={props.className}
        data-testid={props.dataTestId ?? 'radius-card'}
      >
        <CardContent className={props.contentClassName}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
            <Box> { props.icon }</Box>
            <Grid container columns={12}>
              <Grid item xs={12}>
                <Typography component="div" variant="h6" className={'d-flex f-align-items-center ' + (props.headerClassName ?? '')}>{props.header}</Typography>
              </Grid>
              {props.content}
            </Grid>
          </Box>

        </CardContent>
        {(props.showMenu === true) && <CardActions disableSpacing>
          <IconButton aria-label="settings" onClick={handleClick} >
            <MoreHoriz data-testid="settingbutton" />
          </IconButton>
        </CardActions>
        }
      </Card>
      <Menu open={open} data-testid="setting-menue" anchorEl={anchorEl} onClose={handleMenuClose}>
        <MenuItem data-testid="edit-menu" onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        {(props.showViewProfile === true) && <MenuItem onClick={handleViewFullProfile}>View Full Profile</MenuItem>}
      </Menu>

      <BannerModal open={openConfirmation} onConfirm={handleConfirm}
        confirmButtonText='Yes, Continue'
        onClose={handleModalClose} title={'Confirmation'} hideXtoClose={true} content={<div>{confirmationMessage}</div>}
      />
    </>
  )
}
