import { Box, RadioGroup, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { TabDisplay } from '../TabDisplay'
import { NotificationTemplate } from './NotificationTemplate'
import { type notificationEvent } from '../../Pages/CareNetworkManagement/CareNetworkDetail/ChildHistoryDisplay'
import { sendPost } from '../../hooks/use-fetch'
export interface NotificationProps {
  events: notificationEvent[]
}

export function NotificationCard (props: NotificationProps): JSX.Element {
  const [events, setEvents] = useState(props.events.sort((a, b) => new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf()))
  const [value, valueChange] = useState(0)
  const [radioVal1, setRadioVal1] = useState('')
  const [radioVal2, setRadioVal2] = useState('')

  const SetNotificationViewed = async (eventId: string): Promise<void> => {
    try {
      await sendPost(`/User/MarkEventViewed?eventId=${eventId}`, {})
    } catch (e) {
    }
  }

  const handleNewMessageViewed = async (e: any, value: string): Promise<void> => {
    setRadioVal1(e.target.value)
    const newEvents = [...events]
    const updatedEvent = newEvents.find(i => i.id.toString() === value)
    if (updatedEvent !== undefined) {
      if (!updatedEvent.viewed) {
        await SetNotificationViewed(value)
      }

      updatedEvent.viewed = true
    }
    newEvents.sort((a, b) => new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf())
    setEvents(newEvents)
  }
  const handleMessageViewed = async (e: any, value: string): Promise<void> => {
    setRadioVal2(e.target.value)
    const newEvents = [...events]
    const updatedEvent = newEvents.find(i => i.id.toString() === value)
    if (updatedEvent !== undefined) {
      if (!updatedEvent.viewed) {
        await SetNotificationViewed(value)
      }

      updatedEvent.viewed = true
    }
    setEvents(newEvents)
  }
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={(e, val) => { valueChange(val) }} aria-label="basic tabs example" data-testid="tabsHeading">
          <Tab label="Unread" value={0} data-testid='unread' />
          <Tab label="All" value={1} data-testid='all' />
        </Tabs>
      </Box>
      <TabDisplay index={0} value={value}>
        <RadioGroup value={radioVal1} onChange={handleNewMessageViewed} data-testid='unread-notifications'>
          {events?.filter(e => e.isNew).sort((a, b) => new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf()).map(e => {
            return <NotificationTemplate key={e.id.toString() + 'newView'} event={e} />
          })}
        </ RadioGroup>
      </TabDisplay>
      <TabDisplay index={1} value={value}>
        <RadioGroup value={radioVal2} onChange={handleMessageViewed} data-testid='all-notifications'>
          {events?.sort((a, b) => new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf()).map(e => {
            return <NotificationTemplate key={e.id.toString() + 'allView'} event={e} />
          })}
        </RadioGroup>
      </TabDisplay>
    </Box>
  )
}
