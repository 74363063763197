import { Grid } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TextFieldWithLabel } from '../../Components/TextField'
import { DatePickerWithLabel } from '../../Components/DatePicker'
import { type dateRange } from '../../core/types'
import { useState } from 'react'
import { Multiselect } from '../../Components/MultiSelect/MultiSelect'
import { BannerModal } from '../../Components/Modal/BannerModal'

export interface DateRangeDetailsProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: dateRange) => void
  dateRange: dateRange
  errors?: string[] | undefined
}

export function DateRangeDetails (props: DateRangeDetailsProps): JSX.Element {
  const [dateRange, setDateRange] = useState(props.dateRange)
  const [yearCount, setYearCount] = useState(0)
  const [selectedYears, setSelectedYears] = useState<Array<{ id: number, value: number | null }>>(dateRange.years.map((s: number) => { return { id: s, value: s } }) ?? [])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const yearOptions: Array<{ id: number, name: string }> = []

  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 5; i <= currentYear + 1; i++) {
    yearOptions.push({ id: i, name: i.toString() })
  }

  const handleClose = (): void => {
    props.onClose()
  }

  const handleChange = (e: any): void => {
    setDateRange({ ...dateRange, [e.target.name]: e.target.value })
  }

  const handleSubmit = (): void => {
    if (selectedYears.length === 0) {
      setShowConfirmation(true)
    } else {
      saveData()
    }
  }

  function saveData (): void {
    dateRange.years = selectedYears.map((s) => s.value ?? 0)
    props.onSubmit(dateRange)
  }

  const dateChanged = (field: string, newDate: Date | null): void => {
    setDateRange({ ...dateRange, [field]: newDate })
  }
  const content = <Grid container>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    <Grid item xs={12} >
      <TextFieldWithLabel
        data-test-school-year
        label='Time Span'
        onChange={handleChange}
        name='name'
        value={dateRange.name ?? ''}
      />
    </Grid>
    <Grid item xs={6}>
      <DatePickerWithLabel
        cypressMarker='data-test-start-date'
        label='Start Date'
        name='startDate'
        onChange={(date: Date | null) => { dateChanged('startDate', date) }}
        value={dateRange.startDate}
      />
    </Grid>
    <Grid item xs={6}>
      <DatePickerWithLabel
        cypressMarker='data-test-end-date'
        label='End Date'
        name='endDate'
        onChange={(date: Date | null) => { dateChanged('endDate', date) }}
        value={dateRange.endDate}
      />
    </Grid>
    <Grid item xs={6}>
      <Multiselect
        itemIndex={yearCount}
        setItemIndex={setYearCount}
        itemList={selectedYears}
        setItemList={setSelectedYears}
        options={yearOptions}
        label={'Income Calendar Year'}
        minLength={0}
        name='years'
      />
    </Grid>
  </Grid>
  return <><Modal
    data-test-modal
    open={props.open}
    title={'Time Span Details'}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
    <BannerModal open={showConfirmation} onConfirm={saveData} cancelButtonText='Cancel' confirmButtonText='Continue' onClose={() => { setShowConfirmation(false) }} title={'Confirmation'} hideXtoClose={true} content={<div>You have not added any income years to this timespan.  Are you sure you wish to continue?</div>} />
  </>
}
