import { useEffect, useState } from 'react'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { type lookup, type DenyReferralRequest } from '../../core/types'
import { sendGet } from '../../hooks/use-fetch'
import { ReferralDenyEdit } from './ReferralDenyEdit'
import { type ReferralData } from './ReferralDetail'
import { Box, Typography } from '@mui/material'

export interface ReferralDenyModalProps {
  in: boolean
  close: () => void
  confirm: (DenyReferral: DenyReferralRequest) => void
  manualReferEnabled: boolean
  referral: ReferralData
  errors: string[]
}

export function ReferralDenyModal (props: ReferralDenyModalProps): JSX.Element {
  const [denyRequest, setDenyRequest] = useState<DenyReferralRequest>({ manualReferEnabled: props.manualReferEnabled, note: '', referralId: props.referral.id, denyReasonId: null, serviceProviderId: null })
  const [serviceProviders, setServiceProviders] = useState<lookup[]>([])
  const [denyReasons, setDenyReasons] = useState<lookup[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response, error } = await sendGet(`/ReferralSearch/GetProvidersByReferralISD?referralId=${props.referral.id}&serviceId=${props.referral.systemServiceId}`, {})
      if (error[0] === '') {
        setServiceProviders(response)
      }
      const { response: res, error: err } = await sendGet('/ReferralSearch/GetReferralDenyReasons', {})
      if (err[0] === '') {
        setDenyReasons(res)
      }
      const { response: hasWaitingRefs }: { response: boolean } = await sendGet(`/ReferralSearch/HasWaitingReferrals?childID=${props.referral.childID}&serviceID=${props.referral.serviceId}`, {})
      if (hasWaitingRefs) {
        const manualRefer = props.manualReferEnabled && !hasWaitingRefs
        setDenyRequest({ ...denyRequest, manualReferEnabled: manualRefer })
      }
      setLoading(false)
    }
    void fetchData()
  }, [props.in, props.referral.id])

  if (loading) {
    return <></>
  } else if (serviceProviders.length === 0) {
    const display = <Box sx={{ p: '0 24px' }}>
      <Typography data-testid="ReferralDenyHeader" sx={{ fontWeight: 'bold', fontSize: '1em' }}>
        There are no service providers available to refer to.
      </Typography>
    </Box>
    return <BannerModal
      className='Nested Referral'
      maxWidth='sm'
      open={props.in}
      content={display}
      onClose={props.close}
      onConfirm={props.close}
      title='Deny Referral'
      hideCancelButton={true}
      confirmButtonText='Close'
    />
  } else {
    const display = <ReferralDenyEdit
      denyReasons={denyReasons}
      denyRequest={denyRequest}
      onChange={setDenyRequest}
      serviceProviders={serviceProviders}
      denialErrors={props.errors}
    />

    const onConfirm = (): void => {
      props.confirm(denyRequest)
    }

    return <BannerModal
      className='Nested Referral'
      maxWidth='sm'
      open={props.in}
      content={display}
      onClose={props.close}
      onConfirm={onConfirm}
      title='Deny Referral'
      cancelButtonText='No, Cancel'
      confirmButtonText='Yes, Continue'
    />
  }
}
