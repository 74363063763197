/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { type ReferralData } from './ReferralDetail'
import { BannerModal } from '../../Components/Modal/BannerModal'
import { ChildServiceSelection } from './AddChildModal/ChildServiceSelection'
import { type childServiceSettings } from '../../ContextProviders/AddChildProvider'
import { type program, type lookup, type service, AccountType } from '../../core/types'
import { Box, CircularProgress } from '@mui/material'
import { sendGet } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { useAccountId } from '../../ContextProviders/CurrentAccount'

interface ReferralEditModalProps {
  open: boolean
  referral: ReferralData
  onSubmit: (newSettings: childServiceSettings) => Promise<boolean>
  onClose: () => void
  status: string
  isNew: boolean
  existingReferrals?: Array<{ systemProgramID: number, systemServiceID: number }>
}

export function ReferralEditModal (props: ReferralEditModalProps): JSX.Element {
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false)
  const [showDuplicateWarning, setShowDuplicateWarning] = useState(false)
  const load = (): childServiceSettings => {
    const prog = programList.find(p => p.id === props.referral.programId)

    return {
      facilityId: props.referral.facilityId,
      facilityName: props.referral.facilityName,
      systemServiceProviderId: props.isNew ? 0 : props.referral.systemServiceProviderID,
      systemService: { id: props.referral.systemServiceId, name: props.referral.systemServiceName },
      systemProgram: { id: props.referral.programId, name: props.referral.programName, requiresIncomeCalculation: prog?.requiresIncomeCalculation },
      desiredSchedule: props.referral?.desiredSchedule?.replaceAll(' ', ''),
      referralId: props.referral.id,
      timeFrame: props.referral?.timePeriod?.replaceAll(' ', ''),
      income: props.referral.statedIncome,
      povertyPercent: props.referral.povertyPercent,
      notes: props.referral.notes
    } as childServiceSettings
  }

  const account = useAccountId()

  const [serviceList, setServiceList] = useState<Array<{ id: number, name: string, s3Key: string, inactive: boolean, serviceId: number }>>([])
  const [programList, setProgramList] = useState<Array<{ id: number, name: string, allowConcurrentEnrollment: boolean, requiresIncomeCalculation: boolean, programId: number }>>([])
  const [facilityList, setFacilityList] = useState<lookup[]>([])
  const [serviceSetting, setServiceSetting] = useState<childServiceSettings>(load())
  const [providerList, setProviderList] = useState<Array<{ id: number, name: string, isIntake: boolean }> | null>(null)
  useEffect(() => {
    const loadDropdownData = async (): Promise<void> => {
      let serviceCallResponse: any

      if (account?.type === AccountType.SERVICE_PROVIDER || props.isNew) {
        serviceCallResponse = await sendGet(`/ServiceProviderDetails/GetProviderServices?id=${serviceSetting.systemServiceProviderId ?? 0}`, {})
      } else if (account?.type === AccountType.ISD) {
        serviceCallResponse = await sendGet(`/ServiceProviderDetails/GetProviderServicesByISD?id=${account?.id ?? 0}`, {})
      }
      const { response: serviceResponse } = serviceCallResponse
      if (serviceResponse != null && serviceResponse.length > 0) {
        const rsp: Array<{ id: number, name: string, s3Key: string, inactive: boolean, serviceId: number }> = serviceResponse
        if (props.referral.id !== undefined) {
          const thisservice = rsp.find(s => s.id === props.referral.systemServiceId) ?? ({ id: props.referral.systemServiceId, name: props.referral.systemServiceName } as { id: number, name: string, s3Key: string, inactive: boolean, serviceId: number })
          setServiceList([thisservice])
        } else {
          setServiceList(rsp)
        }
      } else {
        setServiceList([])
      }
      let programCallResponse: any
      if (account?.type === AccountType.SERVICE_PROVIDER || props.isNew) {
        programCallResponse = await sendGet(`/ServiceProviderDetails/GetProviderPrograms?id=${serviceSetting.systemServiceProviderId ?? 0}`, {})
      } else if (account?.type === AccountType.ISD) {
        programCallResponse = await sendGet(`/ServiceProviderDetails/GetProgramsByISD?isdid=${account?.id ?? 0}`, {})
      }
      const { response: rsp } = programCallResponse
      if (rsp != null && rsp.length > 0) {
        setProgramList(rsp)
      } else {
        setProgramList([])
      }
      const { response } = await sendGet(`/ServiceProviderDetails/GetProviderFacilities?isdid=${props.referral.isdid}&spId=${serviceSetting.systemServiceProviderId ?? 0}&pId=${props.referral.programId ?? 0}`, {})
      if (response != null && response.length > 0) {
        setFacilityList(response)
      } else {
        setFacilityList([])
      }

      if (props.isNew) {
        const { response: providersResponse } = await sendGet(`/ChildDetails/GetAllProviders?isdId=${props.referral.isdid}`, {})
        if (providersResponse != null) {
          const newProviders = providersResponse
            .map((p: { systemServiceProviderID: number, name: string, isIntake: boolean }) => ({ id: p.systemServiceProviderID, name: p.name, isIntake: p.isIntake }))
          setProviderList(newProviders)
        } else {
          setProviderList([])
        }
      }
    }
    void loadDropdownData()
  }, [serviceSetting.systemServiceProviderId])

  useEffect(() => {
    const loadFacilityData = async (): Promise<void> => {
      const { response } = await sendGet(`/ServiceProviderDetails/GetProviderFacilities?isdid=${props.referral.isdid}&spId=${account?.id ?? 0}&pId=${(serviceSetting.systemProgram?.id ?? 0).toString()}`, {})
      if (response !== undefined && response.length > 0) {
        setFacilityList(response)
      } else {
        setFacilityList([])
      }
    }
    void loadFacilityData()
  }, [serviceSetting.systemProgram?.id])

  useEffect(() => {
    setServiceSetting(load())
  }, [props.open])

  const handleProviderChange = (spId: string): void => {
    setServiceSetting({
      ...serviceSetting,
      systemService: null,
      systemProgram: null,
      systemServiceProviderId: parseInt(spId)
    })
  }
  const handleServiceChange = async (settings: childServiceSettings): Promise<void> => {
    const newSettings = { ...settings }
    if (props.status === 'Pending' && settings.systemServiceProviderId != null &&
      (settings.systemProgram?.requiresIncomeCalculation ?? false)) {
      const dateString = settings.customDate?.toString() ?? ''
      const date = new Date(dateString)
      const { response: incomeResponse } = await sendGet(`/ChildDetails/GetIncomeFPL?childId=${props.referral.childID}` +
        `&systemProgramID=${settings.systemProgram?.id ?? 0}&systemServiceProviderID=${settings.systemServiceProviderId ?? 0}` +
        `${settings.timeFrame != null && settings.timeFrame !== undefined ? `&timeFrame=${settings.timeFrame}` : ''}` +
        `${!isNaN(date.getTime()) ? `&customDate=${new Date(settings.customDate?.toString() ?? '')?.toDateString()}` : ''}` +
        `&isdid=${settings.isdId ?? 0}`, {})
      newSettings.income = incomeResponse.income
      newSettings.povertyLevel = incomeResponse.householdFPL
      newSettings.povertyPercent = incomeResponse.incomePercent
      newSettings.povertyPercentCutOff = incomeResponse.povertyPercentCutOff
      newSettings.memberCount = incomeResponse.memberCount

      if (incomeResponse.incomePercent > incomeResponse.povertyPercentCutOff) {
        toast.warning('The income for this household is above the poverty percent threshold that has been set for this year. ' +
          'Creating this referral will result in an income approval taking place prior to being sent to the provider.')
      }
    }
    setServiceSetting(newSettings)
  }

  const content = <Box sx={{ p: '0 20px' }}>
    <ChildServiceSelection
      facilityList={facilityList}
      showDuplicateWarning={showDuplicateWarning}
      onProgramChange={(value: string) => {
        const intVal = parseInt(value)
        const p = programList.find(p => p.id === parseInt(value))
        const systmeProg: program | null = p != null ? (p as unknown as program) : null
        setServiceSetting({ ...serviceSetting, systemProgram: systmeProg })
        setIsUpdateDisabled(false)
        setShowDuplicateWarning(false)
        if (props.isNew) {
          const existingService = props.existingReferrals?.find(s => s.systemProgramID === intVal && s.systemServiceID === serviceSetting.systemService?.id)
          setShowDuplicateWarning(existingService !== undefined)
          if (p?.allowConcurrentEnrollment === false && existingService !== undefined) {
            toast.error('You are not allowed to create multiple enrollments for this program. You will need to initiate a transfer based on the current settings.')
            setIsUpdateDisabled(true)
          }
        }
      }}
      onServiceChange={(value: string) => {
        const p = serviceList.find(p => p.id === parseInt(value))
        const systmeProg: service | null = p != null ? (p as unknown as service) : null
        setServiceSetting({ ...serviceSetting, systemService: systmeProg })
      }}
      serviceSettings={serviceSetting}
      setServiceSettings={handleServiceChange}
      serviceList={serviceList}
      programList={programList}
      providerList={providerList}
      onServiceProviderChange={handleProviderChange}
      status={props.status}
      showIncome={serviceSetting.systemProgram?.requiresIncomeCalculation ?? false}
    />
  </Box>

  const handleSubmit = async (): Promise<void> => {
    await props.onSubmit(serviceSetting)
  }
  const title = `${(props.isNew ? 'Create' : 'Edit')} Referral`
  return <BannerModal
    className='Nested Referral'
    maxWidth='sm'
    open={props.open}
    content={(props.isNew && providerList == null) ? <CircularProgress /> : content}
    onClose={props.onClose}
    onConfirm={handleSubmit}
    title={title}
    isUpdateDisabled={isUpdateDisabled}
    cancelButtonText='Discard Changes'
    confirmButtonText={props.isNew ? 'Submit' : 'Update'}
  />
}
