import { Box, Button } from '@mui/material'
import { LabelWithToolTip } from '../../Label/Index'
import { AccountType, type ChildCaregiver, type ChildEditValues } from '../../../core/types'
import { useChildContext, useChildUpdateDispatch } from '../../../ContextProviders/AddChildProvider'
import { sendGet } from '../../../hooks/use-fetch'
import { CaregiverCardDisplay } from '../CaregiverCard/CaregiverCardDisplay'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'

interface MultiCardListProps {
  label: string
  toolTip?: string
  hideDocumentLink?: boolean
}

export function CaregiverAddList (props: MultiCardListProps): JSX.Element {
  const data = useChildContext()
  const dispatch = useChildUpdateDispatch()
  const account = useAccountId()

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const newCaregiver: ChildCaregiver = { isLivingWithStudent: true, isFinancialResponsible: true } as ChildCaregiver

  const getSiblings = async (ids: number[]): Promise<void> => {
    let isdId = 0
    if (account?.type === AccountType.ISD) {
      isdId = account.id
    } else {
      const { response: isdRsp } = await sendGet('/ChildDetails/GetISDFromUser', {})
      isdId = isdRsp
    }
    const url = `/ReferralEdit/GetSiblings?isdid=${isdId}&ids=${ids.join(',')}`
    const { response } = await sendGet(url, {})
    if (response != null) {
      const newSiblings: ChildEditValues[] = response
      dispatch({ type: 'matchSiblings', siblings: newSiblings })
    }
  }

  const onDeleteClick = (index: number): void => {
    const tempArray = [...data.caregivers]
    tempArray.splice(tempArray.findIndex(v => v.index === index), 1)
    dispatch({ type: 'caregivers', caregivers: tempArray })
  }

  const addSection = (): void => {
    const tempArray = [...data.caregivers, { index: data.caregiverIndex, caregiver: newCaregiver, edit: true, isNew: true, isLoadedRecord: false }]
    dispatch({ type: 'index', field: 'caregivers' })
    dispatch({ type: 'caregivers', caregivers: tempArray })
  }

  const handleEditChange = (index: number, edit: boolean): void => {
    const tempArray = [...data.caregivers]
    const caregiverData = tempArray.find(v => v.index === index)
    if (!edit) {
      if (caregiverData?.isNew ?? false) {
        tempArray.splice(tempArray.findIndex(v => v.index === index), 1)
        dispatch({ type: 'caregivers', caregivers: tempArray })
        return
      }
    }

    if (caregiverData != null) {
      caregiverData.edit = edit
      caregiverData.isNew = false
      dispatch({ type: 'caregivers', caregivers: tempArray })
    }
  }

  const handleUpdate = async (index: number, caregiver: ChildCaregiver, match: boolean): Promise<string[]> => {
    const tempArray = [...data.caregivers]
    const caregiverData = tempArray.find(v => v.index === index)
    if (!(caregiverData?.isLoadedRecord ?? false) && match) {
      const newSiblings = caregiver.newChildSiblings ?? []
      await getSiblings(newSiblings)
    }
    if (caregiverData != null) {
      caregiverData.caregiver = caregiver
      caregiverData.edit = false
      caregiverData.isNew = false
      caregiverData.isLoadedRecord = match
      const errs: string[] = []
      if ((caregiver.firstName ?? '') === '') {
        toast.error('First Name is required')
        errs.push('firstName')
      }
      if ((caregiver.lastName ?? '') === '') {
        toast.error('Last Name is required')
        errs.push('lastName')
      }
      if ((caregiver.relationshipID ?? 0) === 0) {
        toast.error('Relationship is required')
        errs.push('relationshipID')
      }

      if (!caregiver.isLivingWithStudent) {
        if ((caregiver.address ?? '') === '') {
          toast.error('Address is required')
          errs.push('address')
        }
        if ((caregiver.city ?? '') === '') {
          toast.error('City is required')
          errs.push('city')
        }
        if ((caregiver.state ?? '') === '') {
          toast.error('State is required')
          errs.push('state')
        }
        if ((caregiver.zipCode ?? '') === '') {
          toast.error('Zip Code is required')
          errs.push('zip')
        }
      }

      if (caregiver.isFinancialResponsible && caregiver.incomes !== undefined && (caregiver.incomes.filter(i => i.memberCount === 0).length > 0)) {
        toast.error('Member Count is required for financially responsible caregivers')
        errs.push('isFinanciallyReponsible')
      }

      if (errs.length > 0) { return errs }

      dispatch({ type: 'caregivers', caregivers: tempArray })
    }
    return []
  }

  return (
    <Box>
      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          isInline={false}
          required={false}
          name={''}
          labelText={props.label}
          toolTipText={props.toolTip}
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />

        {data.caregivers.map((val, index) => {
          const card = <CaregiverCardDisplay
            key={index}
            childId={0}
            caregiver={val.caregiver}
            childName={`${data.child.firstName} ${data.child.lastName}`}
            index={val.index}
            edit={val.edit}
            isLoadedRecord={val.isLoadedRecord}
            setEdit={handleEditChange}
            onSubmit={handleUpdate}
            onDelete={onDeleteClick}
            hideDocumentLink={props.hideDocumentLink ?? false}
          />
          return card
        })}
      </Box>
      <div className="d-flex f-justify-content-center">
        <Button size="small" variant="text" sx={{ color: '#595959', textTransform: 'none' }}
          onClick={addSection} data-testid='addCaregiver'
        >
          Add {props.label} +
        </Button>
      </div>
    </Box>
  )
}
