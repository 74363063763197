import { Box, Drawer, List } from '@mui/material'
import { styled } from '@mui/system'
import HomeIcon from '@mui/icons-material/Home'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import DonutSmallIcon from '@mui/icons-material/DonutSmall'
import { NavBarItem } from './NavBarLink'
import * as AppRoutes from '../../Pages/Routes'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import TaskIcon from '@mui/icons-material/Task'
import mieccLogo from '../../Assets/Logos/MiECC.png'
import incomeApprovals from '../../Assets/Icons/incomeapprovals-icon.png'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import { usePermissions } from '../../hooks/use-permissions'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useAuth } from '../../hooks/use-auth'
import { useEffect, useState } from 'react'
import { generatePath } from 'react-router'

export const NavBar = styled(internalNavBar)(({ theme }) => ({
}))

interface Props {
  current: string
  onClick: (selection: string) => void
  visible: boolean
  setVisible: (showBurgerMenu: boolean) => void
}

function internalNavBar (props: Props): JSX.Element {
  function clickDrawer (selection: string): void {
    props.onClick(selection)
  }

  const perms = usePermissions()
  const { user } = useAuth()
  const theme = useTheme()
  const largeWindow = useMediaQuery(theme.breakpoints.up('md'))
  const [caregiverPortalRoute, setCaregiverPortalRoute] = useState<string>('')
  const [caregiverEnrollmentsRoute, setCaregiverEnrollmentsRoute] = useState<string>('')
  const [isInCaregiverPortal, setIsInCaregiverPortal] = useState<boolean>(false)
  const canAccessCaregiverPortal = user == null || perms.CaregiverPortal

  useEffect(() => {
    const isInPortal = props.current.includes('CaregiverPortal')
    setIsInCaregiverPortal(isInPortal)

    if (isInPortal) {
      // Need to pass along the isdId to the CaregiverPortal routes.
      // Can't use useParams here because it's not a route component.
      const startIndex = location.pathname.indexOf('CaregiverPortal/') + 16

      const substr = location.pathname.substring(startIndex)
      const params = substr.split('/')

      let isdId = ''
      let isdServiceProviderId = ''

      if (params.length > 0) {
        isdId = params[0]
        if (params.length > 2) {
          isdServiceProviderId = params[2]
        }
      }

      if (!isNaN(parseInt(isdId))) {
        setCaregiverPortalRoute(generatePath(AppRoutes.CAREGIVER_PORTAL, { isdId: isdId?.toString() ?? '', isdServiceProviderId: isdServiceProviderId?.toString() ?? '' }))
        setCaregiverEnrollmentsRoute(generatePath(AppRoutes.CAREGIVER_ENROLLMENTS, { isdId: isdId?.toString() ?? '', isdServiceProviderId: isdServiceProviderId?.toString() ?? '' }))
      }
    }
  }, [location])

  const caregiverItems = <>
    {(isInCaregiverPortal && (user == null || perms.CaregiverPortal_Enrollments) && caregiverEnrollmentsRoute !== '') &&
      <NavBarItem current={props.current} value={caregiverEnrollmentsRoute} label='Enrollments' onClick={clickDrawer} icon={<CalendarMonthIcon />} />
    }
    {(isInCaregiverPortal && perms.CaregiverPortal) &&
      <>
        <NavBarItem current={props.current} value={AppRoutes.CAREGIVER_PORTAL_MEMBERS} label='Members' onClick={clickDrawer} icon={<PeopleAltIcon />} />
        <NavBarItem current={props.current} value={AppRoutes.CAREGIVER_PORTAL_SERVICE_FINDER} label='Service Finder' onClick={clickDrawer} icon={<CalendarMonthIcon />} />
      </>
    }
  </>

  const careNetworkItems = <>
    <NavBarItem current={props.current} value={AppRoutes.CARENETWORK_MANAGEMENT} label='Care Network' onClick={clickDrawer} icon={<PeopleAltIcon />} />
  </>
  const enrollmentItems = <>
    <NavBarItem current={props.current} value={AppRoutes.REFERRAL_MANAGEMENT} label='Referrals' onClick={clickDrawer} icon={<MoveToInboxIcon />}/>
    <NavBarItem current={props.current} value={AppRoutes.ENROLLMENT_MANAGEMENT} label='Enrollments' onClick={clickDrawer} icon={<GroupAddIcon />}/>
    {perms.WaiverApprovals &&
      <NavBarItem current={props.current} value={AppRoutes.WAIVER_APPROVALS} label='Waiver Approval' onClick={clickDrawer} icon={<img src={ incomeApprovals } color='inherit' alt='over-income-approvals' />}/>
    }
  </>

  const accessRequestItems = <>
    <NavBarItem current={props.current} value={AppRoutes.ACCESS_REQUESTS} label='Access Requests' onClick={clickDrawer} icon={<TaskIcon />} />
  </>

  const reportsItems = <>
    <NavBarItem current={props.current} value={'reports'} label='Reports' onClick={clickDrawer} icon={<DonutSmallIcon />}/>
  </>
  const spAdminItems = <>
    <NavBarItem current={props.current} value={AppRoutes.SERVICE_PROVIDER_ADMIN_SERVICES} label='Services' onClick={clickDrawer} icon={<CalendarMonthIcon />} />
    <NavBarItem current={props.current} value={AppRoutes.SERVICE_PROVIDER_ADMIN_SETTINGS} label='Service Provider Settings' onClick={clickDrawer} icon={<SettingsIcon />} />
  </>

  const isdAdminItems = <>
    <NavBarItem current={props.current} value={AppRoutes.ISD_SETTINGS} label='ISD Settings' onClick={clickDrawer} icon={<SettingsIcon />} />
    <NavBarItem current={props.current} value={AppRoutes.SERVICE_PROVIDER_MANAGEMENT} label='Provider Management' onClick={clickDrawer} icon={<GroupWorkIcon />} />
    {perms.ISDs_Approvals && (<>
      <NavBarItem current={props.current} value={AppRoutes.INCOME_APPROVAL} label='Over Income Approval' onClick={clickDrawer} icon={<img src={ incomeApprovals } color='inherit' alt='over-income-approvals' />}/>
    </>
    )}
  </>
  const miEccAdminItems = <>
    <NavBarItem current={props.current} value={AppRoutes.ISD_MANAGEMENT} label='ISD Management' onClick={clickDrawer} icon={<GroupWorkIcon />} />
    <NavBarItem current={props.current} value={AppRoutes.APPLICATION_SETTINGS} label='Application Settings' onClick={clickDrawer} icon={<SettingsIcon />} />
  </>

  let homeRoute = AppRoutes.LANDING_PATH
  if (canAccessCaregiverPortal) {
    if (user != null) {
      homeRoute = AppRoutes.CAREGIVER_PORTAL_DASHBOARD
    } else if (isInCaregiverPortal) {
      homeRoute = caregiverPortalRoute
    }
  }

  const drawerContent =
    <div>
      <img src={mieccLogo} height={40} alt='logo' data-testid="mieccLogo" style={{
        marginTop: '20px',
        marginLeft: '20px'
      }}
      />
      <List>
        <NavBarItem current={props.current} value={homeRoute} label='Home' onClick={clickDrawer} icon={<HomeIcon />}/>
        {(isInCaregiverPortal && canAccessCaregiverPortal) && (caregiverItems)}
        {perms.CareNetwork && (careNetworkItems)}
        {perms.Enrollment && (enrollmentItems)}
        {perms.ISDs_Approvals && (accessRequestItems) }
        {perms.Reports && (reportsItems)}
        {perms.Users && (
          <NavBarItem current={props.current} value={AppRoutes.USER_MANAGEMENT} label='User Management' onClick={clickDrawer} icon={<PeopleAltIcon />} />
        )}
        {perms.ServiceProviders && (spAdminItems)}
        {perms.ISDs && (isdAdminItems)}
        {perms.MiECCAdmin && miEccAdminItems}
      </List>
    </div>

  return (
    <Box>
      <Drawer
        PaperProps={{ sx: { border: 'none' } }}
        color="primary"
        variant={largeWindow ? 'permanent' : 'temporary' }
        open={largeWindow || props.visible}
        anchor="left"
        onClose={() => { props.setVisible(false) }}
      >
        {drawerContent}
      </Drawer>
    </Box>

  )
}
