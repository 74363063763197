import { Box, Container, Grid, RadioGroup, Typography } from '@mui/material'
import { useAuth } from '../../../hooks/use-auth'
import { NotificationTemplate } from '../../../Components/Notifications/NotificationTemplate'
import { useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { PendingInquiryCard, type InquiryView } from './PendingInquiryCard'
import { LargeCard } from '../../../Components/Cards/Large'
import FamilyIcon from '@mui/icons-material/FamilyRestroom'
import { SearchIcon } from '../../../Assets/SVGs/SearchIcon'
import { useNavigate } from 'react-router'
import { CAREGIVER_PORTAL_MEMBERS, CAREGIVER_PORTAL_SERVICE_FINDER } from '../../Routes'
import { useScrollTop } from '../../../hooks/use-scrollTop'

export function CaregiverDashboard (): JSX.Element {
  const nav = useNavigate()
  const { user } = useAuth()
  const [radioVal, setRadioVal] = useState('')
  const [pendingInquiries, setPendingInquiries] = useState<InquiryView[]>([])

  useScrollTop()

  const loadData = async (): Promise<void> => {
    const { response: inquiries } = await sendGet('/CaregiverPortal/GetPendingInquiries', {})

    if (inquiries != null) {
      setPendingInquiries(inquiries)
    }
  }

  useEffect(() => {
    if (user != null) {
      void loadData()
    }
  }, [user])

  const handleMessageViewed = async (e: any, value: string): Promise<void> => {
    setRadioVal(e.target.value)
    const updatedEvent = user?.notifications.find(n => n.id.toString() === value)
    if (updatedEvent !== undefined && !updatedEvent.viewed) {
      await sendPost(`/User/MarkEventViewed?eventId=${value}`, {})
      updatedEvent.viewed = true
    }
  }

  const goToMembers = (): void => {
    nav(CAREGIVER_PORTAL_MEMBERS)
  }

  const goToServices = (): void => {
    nav(CAREGIVER_PORTAL_SERVICE_FINDER)
  }

  if (user == null) {
    return <Typography variant='body1' sx={{ fontWeight: 600, mt: '20px' }} component='div'>
      Please log in to view your dashboard.
    </Typography>
  }

  return <Container>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' sx={{ fontWeight: 600, mt: '20px' }} component='div'>
          Welcome back, {user.firstName}.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 600 }} component='div'>
          Unread Notifications
        </Typography>
        <RadioGroup value={radioVal} onChange={handleMessageViewed}>
          {user.notifications.filter(n => n.isNew).map(n => {
            return <NotificationTemplate event={n} key={n.id} />
          })}
        </RadioGroup>
        {user.notifications.filter(n => n.isNew).length === 0 &&
          <Typography variant='body1' component='div'>
            You have no unread notifications.
          </Typography>
        }
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 600 }} component='div'>
          Pending Inquiries
        </Typography>
        {pendingInquiries.map(inquiry => {
          return <Box key={inquiry.referralID} sx={{ mt: '10px' }}>
            <PendingInquiryCard inquiry={inquiry} />
          </Box>
        })}
        {pendingInquiries.length === 0 &&
          <Typography variant='body1' component='div'>
            You have no pending inquiries.
          </Typography>
        }
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 600 }} component='div'>
          Quick Links
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ pt: '0 !important', mb: '30px' }}>
        <LargeCard
          content={<Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5" className='card-subheader'>Members</Typography>
            <FamilyIcon className='card-icon' />
          </Box>}
          data-testid={'members-card'}
          customContentWidth={true}
          onClick={goToMembers}
        />
      </Grid>
      <Grid item xs={6} sx={{ pt: '0 !important', mb: '30px' }}>
        <LargeCard
          content={<Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5" className='card-subheader'>Find Services</Typography>
            <SearchIcon />
          </Box>}
          data-testid={'find-services-card'}
          customContentWidth={true}
          onClick={goToServices}
        />
      </Grid>
    </Grid>
  </Container>
}
