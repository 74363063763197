import {
  FormControl,
  type FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SelectProps,
  Box
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ErrorDisplay } from '../../ContextProviders/ErrorProvider'

export interface SelectOption<T> { id: T, name: string }

interface Props<T extends string | number | boolean> {
  options: Array<SelectOption<T>>
  value: T | undefined
  onChange: (event: SelectChangeEvent<T>) => void
  name: string
  label?: string
  labelContent?: JSX.Element
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  includeNoneOption?: boolean
  width?: string
  maxWidth?: string
  SelectProps?: Omit<SelectProps<T>, 'onChange' | 'value' | 'name' | 'required'>
  noTopPadding?: boolean
  leftMargin?: string
}

export function SelectWithLabel<T extends string | number | boolean> (props: Props<T>): JSX.Element {
  const { value, name, options, label, labelContent, onChange } = props
  const currentValue = value
  let boxStyle
  if (props.leftMargin !== undefined && props.leftMargin !== null) {
    boxStyle = { pt: props.noTopPadding === true ? 0 : 1, marginLeft: props.leftMargin }
  } else {
    boxStyle = { pt: props.noTopPadding === true ? 0 : 1 }
  }
  return (
    <Box sx={ boxStyle }>
      <ErrorDisplay fieldName={props.name} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <InputLabel
          sx={{ color: 'black' }}
          id={name}
          required={props.required === true && props.showRequiredText !== true}
          disableAnimation
          variant="standard"
        >
          {labelContent ?? label}
        </InputLabel>
        {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
        {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
      </Box>
      <FormControl
        variant="filled"
        {...props.FormControlProps}
        disabled={props.disabled}
        sx={{ pt: 0, pb: 2, width: props.width, maxWidth: props.maxWidth }}
      >
        <Select
          data-testid={name}
          {...props.SelectProps}
          labelId={name}
          id={`${name}-select`}
          name={name}
          value={currentValue}
          onChange={onChange}
          required={props.required}
          variant="outlined"
          IconComponent={KeyboardArrowDownIcon}
          error={props.error}
        >
          {props.includeNoneOption != null && props.includeNoneOption &&
            <MenuItem data-testid='select-item-none' key={''} value={''}>(none)</MenuItem>
          }
          {options?.map((opt) => (
            <MenuItem key={opt.id.toString()} value={opt.id.toString()} role="option" data-testid={'select-item-' + opt.id.toString()}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
