import {
  type FormControlProps,
  InputLabel,
  TextField
} from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { type Dayjs } from 'dayjs'

interface Props {
  value: Date | null
  onChange: (newValue: Date | null) => void
  onAccept?: (newValue: Date | null) => void
  name: string
  label: string
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  dataTestId?: string
}

export function TimePickerWithLabel (props: Props): JSX.Element {
  const { value, name, label, onChange, onAccept } = props
  function handleChange (newValue: Dayjs | null): void {
    if (newValue != null) {
      onChange(newValue.toDate())
    }
  }
  function handleAccept (newValue: Dayjs | null): void {
    if (newValue != null && (onAccept != null)) {
      onAccept(newValue.toDate())
    }
  }
  const testid = props.dataTestId
  return (
    <>
      <InputLabel sx={{ color: 'black' }} id={name} required={props.required}>
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          disabled={props.disabled}
          value={value}
          onChange={handleChange}
          onAccept={handleAccept}
          renderInput={(props) => (
            <TextField required={props.required} {...props} data-testid={testid} />
          )}
        />
      </LocalizationProvider>
    </>
  )
}
