import { FormControl, List } from '@mui/material'
import { CheckListItem } from './CheckListItem'
import { type SelectOption } from '../SelectWithLabel'
import { useState } from 'react'
import { LabelWithToolTip } from '../Label/Index'

type Props = {
  label?: string
  disabled?: boolean
  allOption?: boolean
  items: Array<SelectOption<number>>
  selectedItems: Array<SelectOption<number>>
  fullWidth?: boolean
  flex?: boolean
  toolTip?: string
  setSelectedItems?: (arg: Array<SelectOption<number>>) => void
} & (
  | { otherOption?: false }
  | {
    otherOption: true
    otherValue: string
    setOtherValue: (arg: string) => void
  }
)
export function CheckList (props: Props): JSX.Element {
  const otherOption = props.otherOption ?? false
  let otherValue = ''
  if (props.otherOption === true) { otherValue = props.otherValue }
  const [
    otherItemEnabled,
    setOtherItemEnabled
  ] = useState(otherOption ? otherValue !== '' : false)

  function selectOption (value: SelectOption<number>): void {
    if (props.setSelectedItems == null) return
    if (!selectedItems.includes(value.id)) {
      props.setSelectedItems([
        ...props.selectedItems,
        value
      ])
    } else {
      const newArray = props.selectedItems.filter((a) => a.id !== value.id)
      props.setSelectedItems([...newArray])
    }
  }
  function toggleAll (): void {
    const selectAll = props.selectedItems?.length !== props.items.length
    if (props.setSelectedItems == null) return
    if (selectAll) props.setSelectedItems(props.items)
    else props.setSelectedItems([])
  }

  const selectedItems = props.selectedItems.map((i) => i.id)
  const flex = props.flex ?? false
  const allOption = props.allOption ?? false
  let setOtherValue = (arg: string): void => {}
  if (props.otherOption === true) {
    setOtherValue = props.setOtherValue
  }
  return (
    <FormControl variant="filled" fullWidth={props.fullWidth} sx={{ mt: 1 }}>
      <LabelWithToolTip
        isInline={false}
        required={false}
        name={''}
        labelText={props.label ?? ''}
        toolTipText={props.toolTip}
      />

      <List
        sx={{
          width: '100%',
          maxWidth: '100%',
          bgcolor: 'transparent',
          display: flex ? 'inline-flex' : 'block',
          flexWrap: flex ? 'wrap' : 'nowrap'
        }}
      >
        {allOption && (
          <CheckListItem
            edge="start"
            key={'All'}
            flex={props.flex}
            disabled={props.disabled}
            name="All"
            checked={props.selectedItems?.length === props.items.length}
            onClick={toggleAll}
            text="All"
          />
        )}
        {props.items.map((value) => (
          <CheckListItem
            edge="start"
            key={value.name}
            flex={props.flex}
            disabled={props.disabled}
            name={`checkbox-list-label-${value.name}`}
            onClick={() => { selectOption(value) }}
            checked={selectedItems.includes(value.id) ?? false}
            text={value.name}
          />
        ))}

        {otherOption && (
          <CheckListItem
            key={'Other'}
            edge="start"
            flex={props.flex}
            disabled={props.disabled}
            name="Other"
            checked={otherItemEnabled}
            onClick={() => { setOtherItemEnabled(!otherItemEnabled) }}
            text="Other"
            hasTextArea={true}
            textAreaValue={otherValue}
            setTextAreaValue={setOtherValue}
          />
        )}
      </List>
    </FormControl>
  )
}
