import { Box, Button, CircularProgress } from '@mui/material'
import { LabelWithToolTip } from '../Label/Index'
import { type ChildSiblings, type ChildCaregiver, type ChildEditValues, type location, type postError } from '../../core/types'
import { sendGet } from '../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { CaregiverCardDisplay } from '../Cards/CaregiverCard/CaregiverCardDisplay'
import { useEffect, useState } from 'react'
import { SiblingCardDisplay } from '../Cards/SiblingCard/SiblingCardDisplay'

interface CaregiverData {
  index: number
  caregiver: ChildCaregiver
  edit: boolean
  isNew: boolean
  isLoadedRecord: boolean
}

interface SiblingData {
  index: number
  sibling: ChildSiblings
  edit: boolean
  isNew: boolean
  isLoadedRecord: boolean
}

interface EditCareNetworkMembersProps {
  child: ChildEditValues
  isdid: number | null
  errors: postError[]
  initialCaregivers: ChildCaregiver[]
  initialSiblings: ChildSiblings[]
  handleUpdate: (caregivers: ChildCaregiver[], siblings: ChildSiblings[]) => void
}

export function EditCareNetworkMembers (props: EditCareNetworkMembersProps): JSX.Element {
  const [caregivers, setCaregivers] = useState<CaregiverData[]>(props.initialCaregivers.map((v, i) => {
    return { index: i, caregiver: v, edit: false, isNew: false, isLoadedRecord: true }
  }))
  const [siblings, setSiblings] = useState<SiblingData[]>(props.initialSiblings.map((v, i) => {
    return { index: i, sibling: v, edit: false, isNew: false, isLoadedRecord: true }
  }))
  const [caregiverIndex, setCaregiverIndex] = useState<number>(props.initialCaregivers.length)
  const [siblingIndex, setSiblingIndex] = useState<number>(props.initialSiblings.length)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (props.initialSiblings.length > 0 && props.initialSiblings[0].id > 0) {
        const ids = props.initialSiblings.map(v => v.id)
        await getSiblings(ids)
      }
      setIsLoading(false)
    }

    void loadData()
  }, [])

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const newCaregiver: ChildCaregiver = { isLivingWithStudent: true, isFinancialResponsible: true } as ChildCaregiver

  const getSiblings = async (ids: number[]): Promise<void> => {
    const { response } = await sendGet(`/ReferralEdit/GetSiblings?isdid=${props.isdid ?? 0}&ids=${ids.join(',')}`, {})
    if (response != null) {
      const newSiblings: ChildEditValues[] = response
      const siblingsToAdd: SiblingData[] = []
      let index = 0
      newSiblings.forEach(child => {
        const locations: location[] = child.childLocations ?? []
        if (locations.length === 0) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          locations.push({} as location)
        }
        const newSibling = {
          index,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          sibling: {
            ...child,
            relationshipName: '',
            childLocations: locations,
            siblingChildId: props.child.id
          } as ChildSiblings,
          edit: false,
          isNew: false,
          isLoadedRecord: true
        }
        siblingsToAdd.push(newSibling)
        index++
      })

      setSiblings(siblingsToAdd)
      props.handleUpdate(caregivers.map(v => v.caregiver), siblingsToAdd.map(v => v.sibling))
      setSiblingIndex(index)
    }
  }

  const onDeleteCaregiverClick = (index: number): void => {
    const tempArray = [...caregivers]
    tempArray.splice(tempArray.findIndex(v => v.index === index), 1)
    setCaregivers(tempArray)
    props.handleUpdate(tempArray.map(v => v.caregiver), siblings.map(v => v.sibling))
  }

  const addCaregiverSection = (): void => {
    const tempArray = [...caregivers, { index: caregiverIndex, caregiver: newCaregiver, edit: true, isNew: true, isLoadedRecord: false }]
    setCaregiverIndex(caregiverIndex + 1)
    setCaregivers(tempArray)
  }

  const handleEditCaregiverChange = (index: number, edit: boolean): void => {
    const tempArray = [...caregivers]
    const caregiverData = tempArray.find(v => v.index === index)
    if (!edit) {
      if (caregiverData?.isNew ?? false) {
        tempArray.splice(tempArray.findIndex(v => v.index === index), 1)
        setCaregivers(tempArray)
        props.handleUpdate(tempArray.map(v => v.caregiver), siblings.map(v => v.sibling))
        return
      }
    }

    if (caregiverData != null) {
      caregiverData.edit = edit
      caregiverData.isNew = false
      setCaregivers(tempArray)
      props.handleUpdate(tempArray.map(v => v.caregiver), siblings.map(v => v.sibling))
    }
  }

  const handleCaregiverUpdate = async (index: number, caregiver: ChildCaregiver, match: boolean): Promise<string[]> => {
    const tempArray = [...caregivers]
    const caregiverData = tempArray.find(v => v.index === index)
    if (!(caregiverData?.isLoadedRecord ?? false) && match) {
      const newSiblings = caregiver.newChildSiblings ?? []
      await getSiblings(newSiblings)
    }
    if (caregiverData != null) {
      caregiverData.caregiver = caregiver
      caregiverData.edit = false
      caregiverData.isNew = false
      caregiverData.isLoadedRecord = match
      const errs: string[] = []
      if ((caregiver.firstName ?? '') === '') {
        toast.error('First Name is required')
        errs.push('firstName')
      }
      if ((caregiver.lastName ?? '') === '') {
        toast.error('Last Name is required')
        errs.push('lastName')
      }
      if ((caregiver.relationshipID ?? 0) === 0) {
        toast.error('Relationship is required')
        errs.push('relationshipID')
      }

      if (!caregiver.isLivingWithStudent) {
        if ((caregiver.address ?? '') === '') {
          toast.error('Address is required')
          errs.push('address')
        }
        if ((caregiver.city ?? '') === '') {
          toast.error('City is required')
          errs.push('city')
        }
        if ((caregiver.state ?? '') === '') {
          toast.error('State is required')
          errs.push('state')
        }
        if ((caregiver.zipCode ?? '') === '') {
          toast.error('Zip Code is required')
          errs.push('zip')
        }
      }

      if (caregiver.isFinancialResponsible && caregiver.incomes !== undefined && (caregiver.incomes.filter(i => i.memberCount === 0).length > 0)) {
        toast.error('Member Count is required for financially responsible caregivers')
        errs.push('isFinanciallyReponsible')
      }

      if (errs.length > 0) { return errs }

      setCaregivers(tempArray)
      props.handleUpdate(tempArray.map(v => v.caregiver), siblings.map(v => v.sibling))
    }
    return []
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const newSibling: ChildSiblings = { id: 0, isLivingWithStudent: true, childLocations: [{}], siblingChildId: props.child.id } as ChildSiblings

  const onDeleteSiblingClick = (index: number): void => {
    const tempArray = [...siblings]
    tempArray.splice(tempArray.findIndex(v => v.index === index), 1)
    setSiblings(tempArray)
    props.handleUpdate(caregivers.map(v => v.caregiver), tempArray.map(v => v.sibling))
  }

  const addSiblingSection = (): void => {
    const newRecord = {
      index: siblingIndex,
      sibling: newSibling,
      edit: true,
      isNew: true,
      isLoadedRecord: false
    }
    const tempArray = [...siblings, newRecord]
    setSiblingIndex(siblingIndex + 1)
    setSiblings(tempArray)
  }

  const handleEditSiblingChange = (index: number, edit: boolean): void => {
    const tempArray = [...siblings]
    const siblingData = tempArray.find(v => v.index === index)
    if (siblingData != null) {
      siblingData.edit = edit
      siblingData.isNew = false
      setSiblings(tempArray)
      props.handleUpdate(caregivers.map(v => v.caregiver), tempArray.map(v => v.sibling))
    }
  }

  const handleSiblingUpdate = (index: number, sibling: ChildEditValues): void => {
    const tempArray = [...siblings]
    const siblingData = tempArray.find(v => v.index === index)
    if (siblingData != null) {
      siblingData.sibling = sibling as ChildSiblings
      siblingData.edit = false
      siblingData.isNew = false
      setSiblings(tempArray)
      props.handleUpdate(caregivers.map(v => v.caregiver), tempArray.map(v => v.sibling))
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Box>
      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          isInline={false}
          required={false}
          name={''}
          labelText='Caregivers'
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />

        {props.errors.filter(e => e.group === 'Caregiver').map((val) => {
          const loc = <Box key={val.field} sx={{ color: 'red' }}>{val.error}</Box>
          return loc
        })}

        {caregivers.map((caregiver) => {
          const card = <CaregiverCardDisplay
            key={caregiver.index}
            caregiver={caregiver.caregiver}
            childId={props.child.id}
            childName={`${props.child.firstName} ${props.child.lastName}`}
            index={caregiver.index}
            edit={caregiver.edit}
            isLoadedRecord={caregiver.isLoadedRecord}
            setEdit={handleEditCaregiverChange}
            onSubmit={handleCaregiverUpdate}
            onDelete={onDeleteCaregiverClick}
          />
          return card
        })}
      </Box>
      <div className="d-flex f-justify-content-center">
        <Button size="small" variant="text" sx={{ color: '#595959', textTransform: 'none' }}
          onClick={addCaregiverSection} data-testid='addCaregiver'
        >
          Add Caregivers +
        </Button>
      </div>

      <Box sx={{ pt: '8px', flexDirection: 'column', display: 'flex', width: '100%' }}>
        <LabelWithToolTip
          isInline={false}
          required={false}
          name={''}
          labelText='Siblings'
          sx={{
            whiteSpace: 'break-spaces',
            color: 'black',
            overflowWrap: 'break-word'
          }}
        />

        {props.errors.filter(e => e.group === 'Child').map((val) => {
          const loc = <Box key={val.field} sx={{ color: 'red' }}>{val.error}</Box>
          return loc
        })}

        {siblings.map((sibling) => {
          const card = <SiblingCardDisplay
            key={sibling.index}
            originalChildName={`${props.child.firstName} ${props.child.lastName}`}
            index={sibling.index}
            sibling={sibling.sibling}
            edit={sibling.edit}
            setEdit={handleEditSiblingChange}
            onSubmit={handleSiblingUpdate}
            onDelete={onDeleteSiblingClick}
          />
          return card
        })}
      </Box>
      <div className="d-flex f-justify-content-center">
        <Button size="small" variant="text" sx={{ color: '#595959', textTransform: 'none' }}
          onClick={addSiblingSection}
        >
          Add Siblings +
        </Button>
      </div>
    </Box>
  )
}
