import { Grid } from '@mui/material'
import { AllocationCard } from './AllocationCard'
import { EnrollmentCard } from './EnrollmentCard'

export interface listCardsProps {
  facilityCapacity: number
  allocations: Array<{ key: string, count: number }>
}

export function ListCards (props: listCardsProps): JSX.Element {
  return (<Grid sx={{ width: '100%', display: 'flex', gap: 2, flexDirection: 'row' }}>
    <AllocationCard allocationType={props.allocations} />
    <EnrollmentCard maxCapacity={props.facilityCapacity} enrollments={props.allocations} />
  </Grid>)
}
