import { Table, TableContainer, TablePagination } from '@mui/material'
import * as React from 'react'
import { ColumnSort, SortOrder, type Column, type RowData, DEFAULT_CHECKBOX_ID_COLUMN_NAME } from './DataTable.model'
import DataTableBody from './DataTableBody'
import DataTableHead from './DataTableHead'

export interface DataTableProps {
  columns: Column[]
  rows: RowData[]
  name: string
  loading?: boolean
  sortable?: boolean
  initialColumnSorts?: ColumnSort[]
  hasCheckbox?: boolean
  checkboxIdColumnName?: string
  initialCheckedAll?: boolean
  initialCheckedItems?: any[]
  hoverShading?: boolean
  rowClickable?: boolean
  totalRecords?: number
  page: number

  onSortChange?: (column: Column, columnSorts: ColumnSort[]) => void
  onHeaderCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  onRowCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: RowData) => void
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData) => void
  onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, rowsPerPage: number) => void
}

export default function DataTable (props: DataTableProps): JSX.Element {
  const {
    columns, rows, loading, sortable, initialColumnSorts, hasCheckbox, checkboxIdColumnName, initialCheckedAll, initialCheckedItems,
    hoverShading, rowClickable, totalRecords, page,
    onSortChange, onHeaderCheckboxChange, onRowCheckboxChange, onRowClick, onPageChange, onRowsPerPageChange,
    ...rest
  } = props

  const [allChecked, setAllChecked] = React.useState<boolean>(initialCheckedAll ?? false)
  const [checkedItems, setCheckedItems] = React.useState<any[]>(initialCheckedItems ?? [])
  const [columnSorts, setColumnSorts] = React.useState<ColumnSort[]>(initialColumnSorts ?? [])
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5)

  const _onSortHeaderClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: Column): void => {
    let columnSort = columnSorts.find(v => v.key === column.key)
    if (columnSort == null) {
      columnSort = new ColumnSort(column.key)
    } else {
      columnSort = { ...columnSort, order: columnSort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }
    }
    setColumnSorts([columnSort])
    if (onSortChange != null) {
      onSortChange(column, columnSorts)
    }
  }

  const _onHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setCheckedItems(rows.map(row => row[checkboxIdColumnName ?? DEFAULT_CHECKBOX_ID_COLUMN_NAME]))
    } else {
      setCheckedItems([])
    }
    setAllChecked(event.target.checked)
    if (onHeaderCheckboxChange != null) {
      onHeaderCheckboxChange(event, event.target.checked)
    }
  }

  const _onRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: RowData): void => {
    const tempCheckedItems = checkedItems.filter(v => v !== row[checkboxIdColumnName ?? DEFAULT_CHECKBOX_ID_COLUMN_NAME])
    if (event.target.checked) {
      tempCheckedItems.push(row[checkboxIdColumnName ?? DEFAULT_CHECKBOX_ID_COLUMN_NAME])
    }
    setAllChecked(tempCheckedItems.length === rows.length)
    setCheckedItems(tempCheckedItems)
    if (onRowCheckboxChange != null) {
      onRowCheckboxChange(event, event.target.checked, row)
    }
  }

  const _onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    if (onRowClick != null) {
      onRowClick(event, row)
    }
  }

  const _onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number): void => {
    if (onPageChange != null) {
      onPageChange(event, page)
    }
  }

  const _onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const newRowsPerPage = parseInt(event.target.value)
    setRowsPerPage(newRowsPerPage)
    if (onRowsPerPageChange != null) {
      onRowsPerPageChange(event, newRowsPerPage)
    }
  }

  return (
    <div>
      <TableContainer sx={{ width: 'fit-content' }}>
        <Table size='medium' {...rest}>
          <DataTableHead
            key={props.name + 'head'}
            columns={columns}
            loading={loading}
            sortable={sortable}
            columnSorts={columnSorts}
            hasCheckbox={hasCheckbox}
            allChecked={allChecked}
            onHeaderCheckboxChange={_onHeaderCheckboxChange}
            onSortHeaderClick={_onSortHeaderClick}
          />
          <DataTableBody
            key={props.name + 'body'}
            columns={columns}
            rows={rows}
            loading={loading}
            hasCheckbox={hasCheckbox}
            checkboxIdColumnName={checkboxIdColumnName}
            checkedItems={checkedItems}
            hoverShading={hoverShading}
            rowClickable={rowClickable}
            onRowCheckboxChange={_onRowCheckboxChange}
            onRowClick={_onRowClick}
          />
        </Table>
        {
        loading !== false
          ? null
          : <div>
            <TablePagination
              component='div'
              count={totalRecords ?? 0}
              rowsPerPageOptions={[5, 10, 25, 50]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={_onPageChange}
              onRowsPerPageChange={_onRowsPerPageChange}
            />
          </div>
      }
      </TableContainer>
    </div>
  )
}
