/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { type Dispatch, createContext, useEffect, useReducer, useContext } from 'react'
import { type PortalChildDetails, type EnrollmentDetails, type PortalCaregiverDetails } from '../core/types'
import { sendGet, sendPost } from '../hooks/use-fetch'

type RecordUpdate = UnbornUpdate | ChildUpdate | CaregiverUpdate | FormUpdate | OtherUpdate
interface FormUpdate {
  type: 'form'
  form: EnrollmentDetails | null
}
interface UnbornUpdate {
  type: 'unbornChild'
  unbornChild: boolean
}
interface ChildUpdate {
  type: 'child'
  child: PortalChildDetails
}
interface CaregiverUpdate {
  type: 'caregiver'
  caregiver: PortalCaregiverDetails
}
interface OtherUpdate {
  type: 'other'
  other: string
}

export const CaregiverWizardContext = createContext<EnrollmentDetails | null>(null)
export const CaregiverWizardDispatchContext = createContext<Dispatch<RecordUpdate>>(() => null)

export function useCaregiverWizardContext (): EnrollmentDetails | null {
  const ctx = useContext(CaregiverWizardContext)
  return ctx
}

export function useCaregiverWizardUpdateDispatch (): Dispatch<RecordUpdate> {
  return useContext(CaregiverWizardDispatchContext)
}

const caregiverWizardKey = 'caregiverWizardKey'

export function CaregiverWizard (props: { children: JSX.Element }): JSX.Element {
  const [enrollmentDetails, dispatch] = useReducer(enrollmentDetailsReducer, null)
  useEffect(() => {
    const fetchSession = async (): Promise<void> => {
      const wizardKey = window.localStorage.getItem(caregiverWizardKey)
      if (wizardKey !== null) {
        const { response, success } = await sendGet(`/CaregiverPortal/GetSession?sessionKey=${wizardKey}`, {})
        const isNewSession = success && response == null
        if (isNewSession) {
          deleteSession()
        }

        dispatch({ form: { ...response, isNewSession }, type: 'form' })
      } else {
        dispatch({ form: { isNewSession: true } as EnrollmentDetails, type: 'form' })
      }
    }

    void fetchSession()
  }, [])

  return (
    <CaregiverWizardContext.Provider value={enrollmentDetails}>
      <CaregiverWizardDispatchContext.Provider value={dispatch}>
        {props.children}
      </CaregiverWizardDispatchContext.Provider>
    </CaregiverWizardContext.Provider>
  )
}

export async function saveSession (form: EnrollmentDetails | null): Promise<void> {
  let wizardKey = window.localStorage.getItem(caregiverWizardKey)
  if (wizardKey === null) {
    wizardKey = crypto.randomUUID()
    window.localStorage.setItem(caregiverWizardKey, wizardKey)
  }

  if (form != null) {
    await sendPost(`/CaregiverPortal/SaveSession?sessionKey=${wizardKey}`, { sessionJsonString: JSON.stringify(form) })
  }
}

export async function submitForm (isdId: string): Promise<void> {
  const wizardKey = window.localStorage.getItem(caregiverWizardKey)
  if (wizardKey != null) {
    const { success } = await sendPost(`/CaregiverPortal/SubmitForm?sessionKey=${wizardKey}&isdID=${isdId}`, {})
    if (success) {
      window.localStorage.removeItem(caregiverWizardKey)
    }
  }
}

export function deleteSession (): void {
  const wizardKey = window.localStorage.getItem(caregiverWizardKey)
  if (wizardKey != null) {
    window.localStorage.removeItem(caregiverWizardKey)
  }
}

function enrollmentDetailsReducer (form: EnrollmentDetails | null, action: RecordUpdate): EnrollmentDetails | null {
  if (form === null) {
    form = initialEnrollmentDetails()
  }

  switch (action.type) {
    case 'form': {
      return action.form
    }
    case 'child': {
      return { ...form, currentChild: action.child }
    }
    case 'caregiver': {
      return { ...form, currentCaregiver: action.caregiver }
    }
    case 'unbornChild': {
      return { ...form, unbornChild: action.unbornChild }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

export function initialEnrollmentDetails (): EnrollmentDetails {
  return {
    isNewSession: true,
    unbornChild: false,
    isCaregiverOfChild: false,
    userServiceProviderId: null,
    hasStartedCreatingAccount: false,
    note: '',
    currentChild: {
      firstName: '',
      lastName: '',
      middleName: '',
      nickname: '',
      suffix: '',
      dateOfBirth: null,
      birthGender: '',
      races: [],
      hasIEP: false,
      hasIFSP: false,
      hasEOIS: false,
      fosterChild: false,
      hasSNAPBenefits: false,
      isOnCashAssistance: false,
      isOnSSI: false,
      isHomeless: false,
      dueDate: null,
      languageID: null,
      unborn: false,
      livesWithFirstChild: false,
      isCurrentlyUpdating: false,
      location: {
        address: '',
        apartment: '',
        city: '',
        state: '',
        zipCode: ''
      },
      requestedServices: []
    },
    currentCaregiver: {
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      relationshipId: null,
      legalGuardian: true,
      primaryPhone: '',
      secondaryPhone: '',
      email: '',
      doesNotHaveEmail: false,
      preferredContactMethod: null,
      educationLevelID: null,
      isLivingWithStudent: true,
      location: {
        address: '',
        apartment: '',
        city: '',
        state: '',
        zipCode: ''
      },
      totalNumberOfHouseholdMembers: null,
      income: null,
      taxYear: null,
      isCurrentlyUpdating: false
    },
    currentStep: 1,
    currentSubStep: 1,
    children: [],
    caregivers: []
  }
}
