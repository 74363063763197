/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import { Autocomplete } from '../../../Components/AutoComplete'
import { TextFieldWithLabel } from '../../../Components/TextField'
import { type program, type sessionBlock } from '../../../core/types'
import { useState } from 'react'
import { MenuButton, type MenuButtonOption } from '../../../Components/Menu/MenuButton'
import { ErrorDisplay } from '../../../ContextProviders/ErrorProvider'

interface EnrollmentSearchProps {
  sessionBlockList: sessionBlock[]
  sessionList: Array<{ id: number, name: string }>
  programs: program[]
  facilityList: Array<{ id: number, name: string }>
  exitReasons?: Array<{ id: number, name: string }>
  setSearchString: (criteria: string) => void
  baseSearchString: string
  menuButtonOptions?: MenuButtonOption[]
  warningForm?: JSX.Element
}

export interface EnrollmentSearchCriteria {
  sessionBlockId: number
  sessionId: number
  programId: number
  facilityId: number
  exitReasonId: number
  childName: string
}

export function EnrollmentSearch (props: EnrollmentSearchProps): JSX.Element {
  const [searchCriteria, setSearchCriteria] = useState<EnrollmentSearchCriteria>({} as EnrollmentSearchCriteria)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined)
  const [warning, setWarning] = useState<string>('')

  const handleContinueClick = (index: number): void => {
    setShowConfirmation(true)
    setActiveItem(index)
    const str = props.menuButtonOptions?.[index].warning ?? ''
    setWarning(str)
  }

  const handleConfirm = async (): Promise<boolean | undefined> => {
    const item = props.menuButtonOptions?.[activeItem ?? 0]
    if (item?.onConfirm !== undefined) {
      const success = await item.onConfirm() ?? false
      if (success) {
        setShowConfirmation(false)
        setActiveItem(undefined)
      }
      return success
    }
  }

  const handleChange = (value: number | string | undefined, name: string): void => {
    const dat = { ...searchCriteria, [name]: value }
    setSearchCriteria(dat)
    let url = props.baseSearchString
    if (dat.sessionBlockId !== undefined && dat.sessionBlockId !== 0) { url += `&sessionBlockId=${dat.sessionBlockId}` }
    if (dat.sessionId !== undefined && dat.sessionId !== 0) { url += `&sessionId=${dat.sessionId}` }
    if (dat.programId !== undefined && dat.programId !== 0) { url += `&programId=${dat.programId}` }
    if (dat.facilityId !== undefined && dat.facilityId !== 0) { url += `&facilityId=${dat.facilityId}` }
    if (dat.exitReasonId !== undefined && dat.exitReasonId !== 0) { url += `&exitReasonId=${dat.exitReasonId}` }
    if (dat.childName !== undefined && dat.childName !== '') { url += `&childname=${dat.childName}` }
    props.setSearchString(url)
  }

  function onDialogClose (event: any, reason: string): void {
    if (reason !== 'backdropClick') {
      setShowConfirmation(false)
    }
  }

  return <><Grid item sm={8} md={10} lg={11} container spacing={1} rowGap={0} rowSpacing={-1} >
    <Grid item>
      <Autocomplete
        label='Session Blocks'
        name='sessionblocks'
        onChange={(e, value) => { handleChange(value?.id, 'sessionBlockId') }}
        options={props.sessionBlockList}
        width='180px'
        value={searchCriteria.sessionBlockId}
        dataTestId='sessionblocks'
      />
    </Grid>
    <Grid item>
      <Autocomplete
        label='Sessions'
        name='sessions'
        onChange={(e, value) => { handleChange(value?.id, 'sessionId') }}
        options={props.sessionList}
        width='180px'
        value={searchCriteria.sessionId}
        dataTestId='sessions'
      />
    </Grid>
    <Grid item>
      <Autocomplete
        label='Programs'
        name='programs'
        onChange={(e, value) => { handleChange(value?.id, 'programId') }}
        options={props.programs}
        width='180px'
        value={searchCriteria.programId}
        dataTestId='programs'
      />
    </Grid>
    <Grid item>
      <Autocomplete
        label='Facilities'
        name='facilities'
        onChange={(e, value) => { handleChange(value?.id, 'facilityId') }}
        options={props.facilityList}
        width='180px'
        value={searchCriteria.facilityId}
        dataTestId='facilities'
      />
    </Grid>
    {(props.exitReasons != null && props.exitReasons !== undefined && props.exitReasons.length > 0) && (
      <Grid item>
        <Autocomplete
          label='Exit Reasons'
          name='exitreason'
          onChange={(e, value) => { handleChange(value?.id, 'exitReasonId') }}
          options={props.exitReasons}
          width='180px'
          value={searchCriteria.exitReasonId}
          dataTestId='exitreasons'
        />
      </Grid>
    )}
    <Grid item>
      <TextFieldWithLabel
        label='Child'
        data-testid="child"
        name='child'
        value={searchCriteria.childName}
        onChange={(e) => { handleChange(e.target.value, 'childName') }}
        textFieldClassName='width-175px'
      />
    </Grid>
    <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
      <Dialog
        open={showConfirmation}
        data-testid="confirmationModal"
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle data-testid="modal-title" id="alert-dialog-title">
          {'Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ErrorDisplay fieldName='Mass Exit' />
            {warning}
            {props.menuButtonOptions?.[activeItem ?? 0]?.name === 'Mass Exit' ? props.warningForm : <></>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setShowConfirmation(false) }}>Cancel</Button>
          <Button data-testid="confirmation" onClick={handleConfirm} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {
        props.menuButtonOptions !== undefined && ((props.menuButtonOptions?.length ?? 0) > 0) && (
          <Box justifyContent='flex-end' display='flex' width='100%' >
            <MenuButton
              onContinue={handleContinueClick}
              options={props.menuButtonOptions}
              activeSelection={activeItem}
              setActiveSelection={setActiveItem}
            />
          </Box>
        )
      }

    </Grid>
  </Grid>

  </>
}
