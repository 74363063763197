import { Grid, IconButton, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { type DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import './Modal.css'

export type StepsModalProps = DialogProps & {
  id?: string
  title?: string
  titleContent?: JSX.Element
  dialogContent: React.ReactNode
  open: boolean
  maxWidth?: string
  nextButtonText?: string
  isNextDisabled?: boolean
  backButtonText?: string
  cancelButtonText?: string
  hideBackButton?: boolean
  className?: string
  nextButtonColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  onClose: () => void
  onBack: () => void
  onNext: () => void
}

export const StepsModal = (props: StepsModalProps): JSX.Element => {
  const {
    onClose, onBack, onNext, title, open, titleContent, maxWidth, className, dialogContent, backButtonText, nextButtonText, cancelButtonText,
    nextButtonColor, hideBackButton, isNextDisabled,
    ...other
  } =
    props
  const confirmationTitle = props.title ?? 'Confirm'

  function onDialogClose (event: any, reason: string): void {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  return (
    <Dialog
      maxWidth={maxWidth ?? 'xs'}
      PaperProps={{ className: className ?? 'Modal', sx: { overflow: 'hidden', '&&': { maxWidth } } }}
      open={open}
      data-testid='stepsModal'
      onClose={onDialogClose}
      {...other}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }} data-testid='modal-title'>
        {
          titleContent != null
            ? titleContent
            : <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>{confirmationTitle}</Typography>
        }
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            width: 40,
            height: 40,
            color: (theme) => theme.palette.grey[900]
          }}
        >
          <CloseIcon sx={{ width: '40px', height: '40px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Grid container columns={12} flexDirection={'row'}>

          <Grid item xs={4} sx={{ justifyContent: 'flex-start', display: 'flex' }}>
            {!(hideBackButton ?? false) && (
              <Button
                name='backButton'
                color='error'
                onClick={props.onBack}
                variant='contained'
              >
                {backButtonText ?? 'Back'}
              </Button>
            ) }
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button name='cancelButton'
              onClick={props.onClose}
              color='inherit'
              variant='outlined'
            >
              {cancelButtonText ?? 'Cancel'}
            </Button>
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button disabled={isNextDisabled ?? false} name='backButton' color={props.nextButtonColor} onClick={props.onNext} data-testid="nextButton" variant='contained'>
              {nextButtonText ?? 'Next'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
