import { Button, Container, Grid, Typography } from '@mui/material'
import { CustomLabelWithToolTip } from '../../../Components/CustomLabel/Index'
import { useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { CAREGIVER_PORTAL_DASHBOARD } from '../../Routes'
import { useScrollTop } from '../../../hooks/use-scrollTop'

export function CaregiverPostSubmit (): JSX.Element {
  const { isdId } = useParams()
  const nav = useNavigate()

  useScrollTop()

  const [requestedProviders, setRequestedProviders] = useState<string[]>([])

  const loadData = async (): Promise<void> => {
    const { response: providers } = await sendGet('/CaregiverPortal/GetRequestedProviders', {})
    if (providers != null) {
      setRequestedProviders(providers)
    }
  }

  useEffect(() => {
    void loadData()
  }, [])

  const getProvidersText = (): string => {
    if (requestedProviders.length === 0) {
      return ''
    } else if (requestedProviders.length === 1) {
      return ` to ${requestedProviders[0]}`
    } else if (requestedProviders.length === 2) {
      return ` to ${requestedProviders[0]} and ${requestedProviders[1]}`
    } else {
      return ' to ' + requestedProviders.slice(0, requestedProviders.length - 1).join(', ') + ', and ' + requestedProviders[requestedProviders.length - 1]
    }
  }

  const goToProfile = (): void => {
    nav(CAREGIVER_PORTAL_DASHBOARD)
  }

  return <Container>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mt: '20px' }}>
        <Typography variant='h5' sx={{ fontWeight: 600 }} component='div'>
          Thank you! Your interest form has been submitted.
        </Typography>
        <Typography variant='body1' component='div' sx={{ mt: '20px' }}>
          An interest form has been successfully submitted{getProvidersText()}. It will be evaluated by a coordinator and they will reach out.
        </Typography>
        <Typography variant='h6' component='div' sx={{ mt: '20px' }}>
          What do I do next?
        </Typography>
        <Typography variant='body1' component='div' sx={{ mt: '10px' }}>
          <CustomLabelWithToolTip
            name='IF_Post_Submit_Greeting'
            isdId={isdId ?? 0}
            insertNewLines={true}
            defaultLabelText={`While you wait to hear back, you can complete the rest of your MiECC profile by adding additional dependents, other guardians who can act on their behalf, or any missing information and documentation not included in this interest form.

            Having an up-to-date profile can help us identify missing services or resources that you may find helpful in the future.

            Thank you again!`}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: '30px' }}>
        <Button
          name='profileButton'
          className='footer-button'
          data-testid='profile-button'
          onClick={goToProfile}
          variant='contained'
        >
          Go to Profile
        </Button>
      </Grid>
    </Grid>
  </Container>
}
