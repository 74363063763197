import { useEffect, useState } from 'react'
import { type lookup, type postError } from '../../../core/types'
import { sendGet } from '../../../hooks/use-fetch'
import { type ChildRelationship } from './ChildRelationships'
import { SelectWithLabel } from '../../../Components/SelectWithLabel'
import { Box, type SelectChangeEvent } from '@mui/material'
import { Modal } from '../../../Components/Modal'
import { CheckBoxWithLabel } from '../../../Components/CheckBox'

export interface RelationshipEditProps {
  open: boolean
  onClose: () => void
  onSubmit: (relationship: ChildRelationship) => void
  errors?: postError[]
  relationship: ChildRelationship
}

export function RelationshipEdit (props: RelationshipEditProps): JSX.Element {
  const [relationship, setRelationship] = useState<ChildRelationship>(props.relationship)
  const [relationshipTypes, setRelationshipTypes] = useState<lookup[]>([])
  const [showRelationValues, setShowRelationValues] = useState(props.relationship.relationshipID !== null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: relations, error: err1 } = await sendGet('/Relationship/GetLookup', {})
      if (err1[0] === '') {
        setRelationshipTypes(relations)
      }
    }
    void fetchData()
  }, [])

  const handleTypeChange = async (e: SelectChangeEvent<number | null>): Promise<void> => {
    setRelationship({ ...relationship, [e.target.name]: e.target.value })
    setShowRelationValues(e.target.value !== '')
  }

  const handleValueChange = (name: string, newDate: any): void => {
    setRelationship({ ...relationship, [name]: newDate })
  }

  const handleClose = (): void => {
    props.onClose()
  }

  const handleSubmit = (): void => {
    props.onSubmit(relationship)
  }

  const content = <>
    {props.errors !== undefined && props.errors.length > 0 &&
      <ul>
        {props.errors.map((err) => (
          <li key={err.field} style={{ color: 'red', padding: '10px 0 0' }}>
            {err.error}
          </li>
        ))}
      </ul>
    }

    <Box>
      <SelectWithLabel
        width='100%'
        label='Relationship'
        name='relationshipID'
        includeNoneOption={true}
        onChange={handleTypeChange}
        options={relationshipTypes}
        value={relationship.relationshipID ?? undefined}
      />
    </Box>
    {showRelationValues && <Box>
      <CheckBoxWithLabel label='Financially Responsible For' edge='start' name='financiallyResponsibleFor'
        value={relationship.financiallyResponsibleFor ?? false}
        setValue={(val) => { handleValueChange('financiallyResponsibleFor', val) }}
      />
    </Box>
    }
  </>

  return <Modal
    open={props.open}
    titleContent={<div>{'Edit Relationship'} </div>}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
