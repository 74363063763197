import { Box, Button, Chip, CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import { FacilityStatus, type ProviderProgram, type facilityRoom } from '../../../core/types'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { Column, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import DataTable from '../../../Components/Table/DataTable'
import { Autocomplete } from '../../../Components/AutoComplete'
import { SelectWithLabel, type SelectOption } from '../../../Components/SelectWithLabel'
import { useParams } from 'react-router'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { FacilityRoomDetail } from './FacilityRoomDetails'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { useErrorDispatch } from '../../../ContextProviders/ErrorProvider'

const nameColumn = new Column('name', 'Facility Room Name')
const capacityColumn = new Column('capacity', 'Capacity', ColumnType.CUSTOM)
capacityColumn.customCellGenerator = row => {
  return <div>
    {row.noCapacityLimit === true ? 'No Limit' : row.capacity}
  </div>
}
const fundedColumn = new Column('systemProgram', 'Programs', ColumnType.CUSTOM)
fundedColumn.customCellGenerator = row => {
  return <div>
    {row.systemPrograms.map((p: ProviderProgram) => {
      const colorItem = p.id % 3
      let indicatorClass = 'cyan-'
      if (colorItem === 1) {
        indicatorClass = 'purple-'
      } else if (colorItem === 2) {
        indicatorClass = 'red-'
      }
      return <Chip key={p.id} icon={<FiberManualRecordIcon className={indicatorClass + 'icon'} />} label={p.name} size='small' className={indicatorClass + 'indicator'} />
    })}
  </div>
}
const statusColumn = new Column('inactive', 'Status', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  return <div className="d-flex f-align-items-center">
    <span>{(row.inactive === true) ? 'Inactive' : 'Active'}</span>
  </div>
}

const columns = [nameColumn, capacityColumn, fundedColumn, statusColumn]

export function FacilityRooms (): JSX.Element {
  const { id } = useParams()
  const accountID = useAccountId()?.id ?? 0
  const dispatch = useErrorDispatch()
  const [refreshTime, setRefreshTime] = useState(new Date())
  const initialFacilityRoomState: facilityRoom = { capacity: null, noCapacityLimit: false, hasAttachedSessions: false, facilityID: Number(id ?? 0), federallyFunded: false, id: 0, inactive: false, name: '', systemPrograms: [] }
  const [selectedFacilityRoom, setSelectedFacilityRoom] = useState<facilityRoom>(initialFacilityRoomState)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(true)
  const [loaded, setLoaded] = useState(false)
  const [facilityRooms, setFacilityRooms] = useState<facilityRoom[]>([])
  const [selectedProgram, setSelectedProgram] = useState<number>(0)
  const [programs, setPrograms] = useState<ProviderProgram[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string>('All')
  const [page, setPage] = useState(0)

  const statusOptions: Array<SelectOption<string>> = [
    { id: 'All', name: 'All' },
    { id: FacilityStatus.ACTIVE, name: FacilityStatus.ACTIVE },
    { id: FacilityStatus.INACTIVE, name: FacilityStatus.INACTIVE }
  ]

  const handleStatusChange = (e: any): void => {
    setSelectedStatus(e.target.value)
    void fetchData(e.target.value, null)
  }

  async function fetchData (inactive: string, programId: number | null): Promise<void> {
    const facilityId = id ?? 0
    let url: string = `/Facility/GetFacilityRooms?facilityId=${facilityId}`
    if (inactive !== 'All') { url += `&inactive=${(inactive === FacilityStatus.INACTIVE) ? 'true' : 'false'}` }
    if (programId !== null) { url += `&systemProgramId=${programId}` }
    const { response } = await sendGet(url, {})
    if (response !== null) {
      setFacilityRooms(response)
    }
    const url2: string = `/FacilityDetails/GetISD?facilityId=${facilityId}`
    const { response: rsp } = await sendGet(url2, {})
    const isdid: string = rsp
    if (rsp !== null) {
      const { response } = await sendGet('/ServiceProviderDetails/GetProgramsByISD?isdId=' + isdid + '&spId=' + accountID.toString(), {})
      setLoaded(true)
      if (response !== null) { setPrograms(response) }
    }
  }

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    setSelectedFacilityRoom({ ...row as facilityRoom, systemPrograms: [...row.systemPrograms] })
    setOpenModal(true)
    setIsNew(false)
  }

  const addClick = (): void => {
    setSelectedFacilityRoom(initialFacilityRoomState)
    setOpenModal(true)
    setIsNew(true)
  }

  const onProgramChange = (e: any): void => {
    if (e.target.value !== undefined) {
      setSelectedProgram(e.target.value)
      void fetchData(selectedStatus, e.target.value)
    } else {
      setSelectedProgram(0)
      void fetchData(selectedStatus, null)
    }
  }

  useEffect(() => {
    void fetchData(selectedStatus, null)
  }, [refreshTime])

  const handleSubmit = async (facilityRoom: facilityRoom): Promise<void> => {
    if (!facilityRoom.noCapacityLimit && (facilityRoom.capacity == null || String(facilityRoom.capacity).trim().length === 0 || isNaN(facilityRoom.capacity))) {
      toast.warning('Capacity must be a number')
    } else {
      let url = '/FacilityRoom/Create'
      if (facilityRoom.id > 0) {
        url = '/FacilityRoom/Update'
      }
      const { response } = await sendPost(url, facilityRoom)
      if (response.errors.length === 0) {
        if (facilityRoom.id === 0) {
          const _facilityrooms = [...facilityRooms]
          _facilityrooms.push(facilityRoom)
          setFacilityRooms(_facilityrooms)
        }
        dispatch({ type: 'clear', errors: [] })
        setOpenModal(false)
        setRefreshTime(new Date())
        toast.success(`Room ${facilityRoom.name} was updated`)
      } else {
        dispatch({ type: 'update', errors: response.errors })
        toast.error('Please fill out all required fields')
      }
    }
  }

  if (!loaded) return <CircularProgress />

  return (<>
    <Box sx={{ boxSizing: 'border-box', alignItems: 'center', p: '4px 0px', gap: '10px', display: 'flex', flexDirection: 'row' }}>
      <SelectWithLabel
        label='Status'
        name='status'
        onChange={handleStatusChange}
        options={statusOptions}
        width='200px'
        value={selectedStatus}
      />
      <Autocomplete
        label='Program'
        name='status'
        onChange={onProgramChange}
        options={programs}
        width='200px'
        value={selectedProgram}
      />
      <Box justifyContent='flex-end' display='flex' width='100%' >
        <Button color='secondary' variant='contained' onClick={addClick}>Add New Room</Button>
      </Box>
    </Box>
    <div>
      <Box sx={{ width: '100%', padding: '30px 20px', borderRadius: '16px', background: '#fff' }}>
        <DataTable
          hasCheckbox={false}
          name='facilityRooms'
          page={page}
          onPageChange={(e, p) => { setPage(p) }}
          columns={columns}
          rows={facilityRooms}
          onRowClick={rowClick}
        />
      </Box>
    </div>
    {openModal && (
      <FacilityRoomDetail
        onClose={() => { setOpenModal(false) }}
        open={openModal}
        isNew={isNew}
        selectedFacilityRoom={selectedFacilityRoom}
        onSubmit={handleSubmit}
        programs={programs}
      />
    )}
  </>)
}
