import { Grid, Typography } from '@mui/material'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { type lookup, type AcceptReferralRequest } from '../../core/types'
import { DatePickerWithLabel } from '../../Components/DatePicker'
import { TextAreaWithLabel } from '../../Components/TextArea'
import { CheckBoxWithLabel } from '../../Components/CheckBox'

export interface ReferralAcceptEditProps {
  acceptRequest: AcceptReferralRequest
  onChange: (acceptRequest: AcceptReferralRequest) => void
  showPriority: boolean
  priorityProviders: lookup[]
  facilityList: lookup[]
  blocks: lookup[]
  sessions: lookup[]
  errors: string[]
}

export function ReferralAcceptEdit (props: ReferralAcceptEditProps): JSX.Element {
  const handleChange = (e: any): void => {
    const newVal = { ...props.acceptRequest, [e.target.name]: e.target.value }
    props.onChange(newVal)
  }

  const handleDateChange = (field: string, date: Date | null): void => {
    const newVal = { ...props.acceptRequest, [field]: date }
    props.onChange(newVal)
  }

  const handleStatusChange = async (e: boolean): Promise<void> => {
    const newVal: AcceptReferralRequest = { ...props.acceptRequest, enrollNow: e }
    if (!e) {
      newVal.startDate = null
    }
    props.onChange(newVal)
  }

  return <>
    <Typography data-testid="ReferralAcceptHeader" sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em', padding: '20px' }}>
      Please confirm you would like to enroll this child in your services</Typography>
    <Grid style={{ width: '100%', padding: '20px' }} container alignItems={'center'} >
      <Grid style={{ width: '100%' }} item width={'100%'}>
        <SelectWithLabel
          name="facilityId"
          label="Facility"
          width="100%"
          value={props.acceptRequest.facilityId ?? ''}
          options={props.facilityList}
          onChange={handleChange}
          required={true}
          showRequiredText={props.errors.includes('facility')}
          error={props.errors.includes('facility')}
        />
      </Grid>
      <Grid style={{ width: '100%' }} item>
        <SelectWithLabel
          name="sessionBlockId"
          label="Session Block"
          width="100%"
          value={props.acceptRequest.sessionBlockId ?? ''}
          options={props.blocks}
          onChange={handleChange}
          required={true}
          showRequiredText={props.errors.includes('block')}
          error={props.errors.includes('block')}
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <SelectWithLabel
          name="sessionId"
          label="Session"
          width="100%"
          value={props.acceptRequest.sessionId ?? ''}
          options={props.sessions}
          onChange={handleChange}
          includeNoneOption={true}
          required={props.acceptRequest.enrollNow}
          showRequiredText={true}
          error={props.errors.includes('session')}
        />
        <Grid item style={{ width: '50%' }}>
          <CheckBoxWithLabel label='Enroll Immediately' edge='start' name='enrollNow' setValue={handleStatusChange} value={props.acceptRequest.enrollNow} />
        </Grid>
        {props.acceptRequest.enrollNow && <>
          <Grid item>
            <DatePickerWithLabel
              dataTestId='startDate'
              cypressMarker='data-test-period-starting'
              label='Start Date'
              name='startDate'
              onChange={(newValue: Date | null) => { handleDateChange('startDate', newValue) }}
              value={props.acceptRequest.startDate ?? null}
              required={true}
              showRequiredText={props.errors.includes('startDate')}
              error={props.errors.includes('startDate')}
            />
          </Grid>
        </>
        }
        {props.showPriority && (<>
          <Grid item xs={6}>
            <SelectWithLabel
              width='100%'
              label='Priority Provider'
              name='priorityProviderId'
              onChange={handleChange}
              options={props.priorityProviders}
              value={props.acceptRequest.priorityProviderId ?? ''}
              showRequiredText={props.errors.includes('priorityProviderId')}
              error={props.errors.includes('priorityProviderId')}
              required={true}

            />
          </Grid>
          <Grid item xs={11}>
            <TextAreaWithLabel
              label='Priority Notes'
              name='priorityNotes'
              onChange={handleChange}
              value={props.acceptRequest.priorityNotes ?? ''}
            />
          </Grid>
        </ >
        )}
      </Grid>
    </Grid>
  </>
}
