interface jpgProps {
  fill?: string
  className?: string
}

export function HandshakeIcon (props: jpgProps): JSX.Element {
  const stroke = props.fill ?? '#000000'
  const fill = 'clear'
  return <>
    <svg className={props.className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1438_2784)">
        <path fill={stroke} d="M36.6 17.9999H25.2V23.2499C25.2 26.9718 22.7775 29.9999 19.8 29.9999C16.8225 29.9999 14.4 26.9718 14.4 23.2499V11.8499L9.5325 15.5062C8.085 16.5843 7.2 18.5437 7.2 20.6437V25.078L1.2 29.4093C0.0525017 30.2343 -0.344998 32.0718 0.322502 33.5062L6.3225 46.4999C6.9825 47.9343 8.4525 48.4218 9.6 47.5968L17.355 41.9999H27.6C30.2475 41.9999 32.4 39.3093 32.4 35.9999H33.6C34.9275 35.9999 36 34.6593 36 32.9999V26.9999H36.6C37.5975 26.9999 38.4 25.9968 38.4 24.7499V20.2499C38.4 19.003 37.5975 17.9999 36.6 17.9999ZM47.6775 14.4937L41.6775 1.49993C41.0175 0.0655501 39.5475 -0.42195 38.4 0.40305L30.645 5.99993H22.98C22.08 5.99993 21.2025 6.31868 20.4375 6.9093L17.925 8.86868C17.22 9.41243 16.8 10.378 16.8 11.4093V23.2499C16.8 25.3218 18.1425 26.9999 19.8 26.9999C21.4575 26.9999 22.8 25.3218 22.8 23.2499V14.9999H36.6C38.9175 14.9999 40.8 17.353 40.8 20.2499V22.9218L46.8 18.5905C47.9475 17.7562 48.3375 15.9281 47.6775 14.4937Z" />
      </g>
      <defs>
        <clipPath id="clip0_1438_2784">
          <rect width="48" height="48" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  </>
}
