import { Box, CircularProgress, Container, Tab, Tabs, Typography } from '@mui/material'
import { BackArrow } from '../../../Assets/SVGs/back-arrow'
import { CAREGIVER_PORTAL_MEMBERS } from '../../Routes'
import { useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import { type PortalLocation } from '../../../core/types'
import { toast } from 'react-toastify'
import { TabDisplay } from '../../../Components/TabDisplay'
import { DocumentList } from '../../../Components/DocumentList'
import { CaregiverIncomeList } from './CaregiverIncomeList'
import { CaregiverDetails } from './CaregiverDetails'

export interface CaregiverMemberDetails {
  id: number
  isdid: number
  firstName: string
  lastName: string
  dateOfBirth: Date | null
  primaryPhone: string | null
  secondaryPhone: string | null
  email: string | null
  preferredContactMethodID: number | null
  preferredContactMethod: string | null
  educationLevelID: number | null
  highestEducationLevel: string | null
  languageID: number | null
  primaryLanguage: string | null
  locationID: number | null
  location: PortalLocation | null
  livesWithCaregiverID?: number | null
}

export function CaregiverDisplay (): JSX.Element {
  const nav = useNavigate()
  const { caregiverId } = useParams()
  const [refreshTime, setRefreshTime] = useState(new Date())
  const [caregiver, setCaregiver] = useState<CaregiverMemberDetails | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState(0)
  const yearOptions: number[] = []

  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 6; i <= currentYear + 1; i++) {
    yearOptions.push(i)
  }

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const { response, success } = await sendGet(`/CaregiverPortal/GetCaregiverDetails/${caregiverId ?? 0}`, {})
      if (success) {
        setCaregiver(response)
      } else {
        toast.error('Failed to load caregiver details')
      }
      setIsLoading(false)
    }

    void getData()
  }, [caregiverId, refreshTime])

  if (isLoading || caregiver === null) {
    return <CircularProgress />
  }

  return <Container sx={{ mb: '30px' }}>
    <Box sx={{ mt: '30px', mb: '30px', display: 'flex' }}>
      <Box onClick={() => { nav(CAREGIVER_PORTAL_MEMBERS) }} sx={{ cursor: 'pointer' }}>
        <BackArrow />
      </Box>
      <Typography variant='h5' sx={{ fontWeight: 600, ml: '5px' }} component='div'>
        {caregiver.firstName + ' ' + caregiver.lastName}
      </Typography>
    </Box>

    <Tabs value={currentTab} onChange={(e, val) => { setCurrentTab(val) }}>
      <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid="details" label='Details' value={0} />
      <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid='income' label='Income' value={1} />
      <Tab sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '16px', flexGrow: 1 }} data-testid='documents' label='Documents' value={2} />
    </Tabs>

    <TabDisplay
      index={0}
      value={currentTab}
    >
      <CaregiverDetails caregiver={caregiver} updateData={() => { setRefreshTime(new Date()) }} />
    </TabDisplay>
    <TabDisplay
      index={1}
      value={currentTab}
    >
      <CaregiverIncomeList caregiverID={caregiver.id} />
    </TabDisplay>
    <TabDisplay
      index={2}
      value={currentTab}
    >

      {caregiver.isdid > 0 && <DocumentList
        childID={null}
        caregiverID={caregiver.id}
        editable={true}
        canVerify={false}
        showDisclaimer={true}
        isdId={caregiver.isdid}
        years={yearOptions}
      />}
    </TabDisplay>
  </Container>
}
