import { Box, Typography } from '@mui/material'
import { BlockButton } from '../../Components/Button/BlockButton'
import { useNavigate } from 'react-router'
import { SERVICE_PROVIDER_ADMIN_FACILITIES, SERVICE_PROVIDER_ADMIN_STAFF } from '../Routes'
import { DetailsIcon } from '../../Assets/SVGs/details'
import { SchoolIcon } from '../../Assets/SVGs/school'
import { PersonIcon } from '../../Assets/SVGs/person'

export function SettingsSplashPage (): JSX.Element {
  const nav = useNavigate()

  return (
    <Box>
      <Typography data-test-page-header variant="h3">Service Provider Settings</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <BlockButton testId='sp-details' text='Details' marginOverride='20px 20px 20px 0px' onClick={() => { nav('/ServiceProviderAdministration/Settings/Details/') }} icon={<DetailsIcon fill='rgba(113, 54, 188, 1)'/>} backgroundColor='rgba(241, 235, 248, 1)' />
        <BlockButton testId='sp-facility' text='Facilities' marginOverride='20px 20px 20px 0px' onClick={() => { nav(SERVICE_PROVIDER_ADMIN_FACILITIES) }} icon={<SchoolIcon fill='rgba(113, 54, 188, 1)'/>} backgroundColor='rgba(241, 235, 248, 1)'/>
        <BlockButton text='Staff' testId='sp-staff' marginOverride='20px 20px 20px 0px' onClick={() => { nav(SERVICE_PROVIDER_ADMIN_STAFF) }} icon={<PersonIcon fill='rgba(113, 54, 188, 1)' />} backgroundColor='rgba(241, 235, 248, 1)'/>
      </Box>
    </Box>
  )
}
