import { Modal } from '../../Components/Modal'
import { TextFieldWithLabel } from '../../Components/TextField'
import { type federalPovertyLevel } from '../../core/types'
import { useState } from 'react'
import { SelectWithLabel } from '../../Components/SelectWithLabel'

export interface FederalPovertyLevelDetailsProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: federalPovertyLevel) => void
  fpl: federalPovertyLevel
  errors?: string[] | undefined
}

export function FederalPovertyLevelDetails (props: FederalPovertyLevelDetailsProps): JSX.Element {
  const [fpl, setFPL] = useState(props.fpl)
  const title = 'Federal Poverty Level'
  const handleClose = (): void => {
    props.onClose()
  }
  const handleChange = (e: any): void => {
    setFPL({ ...fpl, [e.target.name]: e.target.value })
  }
  const handleSubmit = (): void => {
    props.onSubmit(fpl)
  }

  const yearOptions: Array<{ id: number, name: string }> = []
  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 5; i <= currentYear + 1; i++) {
    yearOptions.push({ id: i, name: i.toString() })
  }

  const content = <>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}
    <SelectWithLabel
      label='Year'
      name='year'
      width='100%'
      value={fpl.year}
      onChange={handleChange}
      options={yearOptions}
    />
    <TextFieldWithLabel
      label='FPL Guideline Baseline'
      name='baselineAmount'
      type='number'
      onChange={handleChange}
      value={fpl.baselineAmount}
    />
    <TextFieldWithLabel
      label='Increase Per Person'
      name='increasePerPerson'
      type='number'
      onChange={handleChange}
      value={fpl.increasePerPerson}
    />
  </>
  return <Modal
    data-test-modal
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
