interface BasicChild {
  firstName: string | null
  lastName: string
  unborn: boolean
  dateOfBirth: Date | null
}

export const childName = (child: BasicChild): string => {
  const firstName = child.firstName != null && child.firstName !== '' ? child.firstName : '(Unnamed)'
  return `${firstName} ${child.lastName}`
}

export const childAge = (child: BasicChild): string => {
  if (child.unborn) {
    return '(Unborn)'
  } else if (child.dateOfBirth != null) {
    const today = new Date()
    const birthDate = new Date(child.dateOfBirth)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    m = (m + 12) % 12
    if (today.getDate() < birthDate.getDate()) {
      m--
    }
    return `(${age}y ${m}m)`
  } else {
    return ''
  }
}

export const getDayOfWeek = (): string => {
  const dayOfWeekNumber = new Date().getDay()
  if (dayOfWeekNumber === 0) {
    return 'Sunday'
  } else if (dayOfWeekNumber === 1) {
    return 'Monday'
  } else if (dayOfWeekNumber === 2) {
    return 'Tuesday'
  } else if (dayOfWeekNumber === 3) {
    return 'Wednesday'
  } else if (dayOfWeekNumber === 4) {
    return 'Thursday'
  } else if (dayOfWeekNumber === 5) {
    return 'Friday'
  } else if (dayOfWeekNumber === 6) {
    return 'Saturday'
  }

  return ''
}
