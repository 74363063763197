import { useEffect, useState } from 'react'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { RadiusCard } from '../../../Components/Cards/Radius/RadiusCard'
import { RadiusCardClickable } from '../../../Components/Cards/Radius/RadiusCardClickable'
import { type postError, type CareNetworkCommunication, NoteSource, AccountType } from '../../../core/types'
import { FormatDateTimeValue, FormatDateValue, SpaceBeforeCapitals } from '../../../core/Utilities'
import { LabelWithToolTip } from '../../../Components/Label/Index'
import { Box, Button, IconButton } from '@mui/material'
import { AccessAlarms, DescriptionOutlined, ChatOutlined } from '@mui/icons-material'
import { CommunicationDetail } from './CommunicationDetail'
import { toast } from 'react-toastify'
import { useAccountId } from '../../../ContextProviders/CurrentAccount'
import { usePermissions } from '../../../hooks/use-permissions'
import { useErrorDispatch } from '../../../ContextProviders/ErrorProvider'
import { useAuth } from '../../../hooks/use-auth'
import { OtherNoteDisplay } from './OtherNoteDisplay'
import CardIcon from '@mui/icons-material/ArticleOutlined'
import TableIcon from '@mui/icons-material/TableRowsOutlined'
import { Column, ColumnSort, ColumnType, type RowData } from '../../../Components/Table/DataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import DataTable from '../../../Components/Table/DataTable'
import { CommunicationSearch, type CommunicationSearchCriteria } from './CommunicationSearch'

export interface CommunicationProps {
  childId: number
}

export interface CommunicationSummary {
  id: number | null
  type: NoteSource
  enteredOn: Date
  note: string
  userName: string | null
  service: string | null
  serviceProvider: string | null
  timeFrame: string | null
  program: string | null
}

export function CommunicationList (props: CommunicationProps): JSX.Element {
  const account = useAccountId()
  const auth = useAuth()
  const [refresh, setRefresh] = useState(new Date())
  const permissions = usePermissions()
  const dispatch = useErrorDispatch()
  const [communications, setCommunications] = useState<CommunicationSummary[]>([])
  const [selectedCommunication, setSelectedCommunication] = useState<CareNetworkCommunication | null>(null)
  const [selectedOtherNote, setSelectedOtherNote] = useState<CommunicationSummary | null>(null)
  const [users, setUsers] = useState<Array<{ id: number, name: string }>>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(false)
  const [isCardModeSelected, setIsCardModeSelected] = useState(true)
  const [searchData, setSearchData] = useState<CommunicationSearchCriteria>({ startDate: null, endDate: null, noteType: null })

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      let url = `/Communication/ChildCommunicationNotes?childid=${props.childId}`
      if (searchData.startDate !== null) {
        url += `&startDate=${searchData.startDate.toLocaleDateString()}`
      }
      if (searchData.endDate !== null) {
        url += `&endDate=${searchData.endDate.toLocaleDateString()}`
      }
      if (searchData.noteType !== null) {
        url += `&noteType=${searchData.noteType}`
      }
      const { response } = await sendGet(url, {})
      setCommunications(response)
    }
    void loadData()
  }, [refresh])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      let isdID = 0
      if (account?.type === AccountType.ISD) {
        isdID = account.id
      } else {
        const { response: rsp } = await sendGet(`/CareNetworkEdit/GetISDFromChild?childID=${props.childId}`, {})
        isdID = rsp
      }
      const { response } = await sendGet(`/ISDStaff/GetStaffList?isdID=${isdID}`, {})
      if (response !== null) {
        setUsers(response)
      }
    }
    void loadData()
  }, [])

  const handleSearch = (newdata: CommunicationSearchCriteria): void => {
    setSearchData(newdata)
    setRefresh(new Date())
  }

  const handleSubmit = (com: CareNetworkCommunication): void => {
    const updateCommunication = async (): Promise<void> => {
      if (!isRequestLoading) {
        setIsRequestLoading(true)

        dispatch({ type: 'clear', errors: [] })

        let result: { response: any, error: string[], success: boolean } | null = null
        const isNew = com.id === 0
        const endpoint = `/Communication/${isNew ? 'Create' : 'Update'}Communication`
        result = await sendPost(endpoint, com)
        const rsp: { newRecordID: number, success: boolean, errors: postError[] } = result.response
        if (rsp.success) {
          setOpenModal(false)
          if (com.type === NoteSource.Reminder) {
            const userindex = users.findIndex(x => x.id === com.userID)
            toast.success(`Communication record was updated. A reminder will be sent on ${FormatDateValue(com.reminderDate ?? new Date())} to ${users[userindex].name}`, { autoClose: false })
          } else {
            toast.success('Communication record was updated')
          }
          setRefresh(new Date())
        } else {
          dispatch({ type: 'update', errors: rsp.errors })
        }
        setIsRequestLoading(false)
      }
    }
    void updateCommunication()
  }
  const getContent = (communication: CommunicationSummary): any => {
    return <Box>
      <LabelWithToolTip labelText={communication.note} />
      <Box sx={{ marginTop: 1 }}>
        <LabelWithToolTip labelText={`${communication.type === NoteSource.Reminder ? 'Send Reminder to' : 'Logged by'} ${communication.userName ?? ''}`} />
      </Box>
    </Box>
  }

  const handleNewClick = (type: NoteSource): void => {
    dispatch({ type: 'clear', errors: [] })
    const date = new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' }))

    const initialCommunicationState: CareNetworkCommunication = { userID: auth.user?.id ?? 0, addressedWhatMatteredMost: null, caregiverID: null, contactMethodID: null, enteredOn: date, id: 0, type, childID: props.childId, needMet: null, note: '', reasonNotMet: '', reminderDate: null, referralID: null }

    setSelectedCommunication(initialCommunicationState)
    setIsNew(true)
    setOpenModal(true)
  }

  const handleClick = (communication: CommunicationSummary): void => {
    dispatch({ type: 'clear', errors: [] })
    if (communication.type === NoteSource.ContactLog || communication.type === NoteSource.Note || communication.type === NoteSource.Reminder ||
        communication.type === NoteSource.ReferralReminder || communication.type === NoteSource.EnrollmentReminder) {
      const loadData = async (): Promise<void> => {
        const { response } = await sendGet(`/Communication/GetCommunication?id=${String(communication.id)}`, {})
        if (response !== null) {
          setSelectedCommunication(response)
          setSelectedOtherNote(null)
          setIsNew(false)
          setOpenModal(true)
        }
      }
      void loadData()
    } else {
      const loadData = async (): Promise<void> => {
        const { response } = await sendGet(`/Communication/GetOtherNote?id=${String(communication.id)}&type=${communication.type}`, {})
        if (response !== null) {
          setSelectedOtherNote(response)
          setSelectedCommunication(null)
          setOpenModal(true)
        }
      }
      void loadData()
    }
  }

  const typeIcon = (type: NoteSource): JSX.Element => {
    if (type === NoteSource.ContactLog) {
      return <ChatOutlined sx={{ width: 60, height: 60 }} />
    } else if (type === NoteSource.Reminder || type === NoteSource.EnrollmentReminder || type === NoteSource.ReferralReminder) {
      return <AccessAlarms sx={{ width: 60, height: 60 }} />
    } else {
      return <DescriptionOutlined sx={{ width: 60, height: 60 }} />
    }
  }

  const typeName = (type: NoteSource): string => {
    return SpaceBeforeCapitals(type)
  }

  const cardContent = <>
    {(permissions.CareNetwork_Communication && !permissions.CareNetwork_Communication_Edit) && communications != null &&
      <Box>
        {communications.map((communication: CommunicationSummary) => (
          <Box sx={{ marginTop: 2 }}>
            <RadiusCard
              key={communication.id}
              icon={typeIcon(communication.type)}
              header={`${FormatDateValue(communication.enteredOn ?? new Date())} ${typeName(communication.type)}`}
              content={getContent(communication)}
              dataTestId={`communication-card-${communication.id ?? 0}`}
            />
          </Box>
        ))}
      </Box>
    }
    {(permissions.CareNetwork_Communication && permissions.CareNetwork_Communication_Edit) && communications != null &&
      <Box>
        {communications.map((communication: CommunicationSummary) => (
          <Box sx={{ marginTop: 2 }} key={communication.id}>
            <RadiusCardClickable
              onClick={() => { handleClick(communication) }}
              icon={typeIcon(communication.type)}
              header={`${FormatDateValue(communication.enteredOn ?? new Date())} ${typeName(communication.type)}`}
              content={getContent(communication)}
              dataTestId={`communication-card-${communication.id ?? 0}`}
            />
          </Box>
        ))}
      </Box>
    }
  </>

  const paginationProps: PaginationProps<RowData> = {
    rows: communications
  }
  const pagination = usePagination(paginationProps)

  const typeColumn = new Column('type', 'Type', ColumnType.CUSTOM)
  typeColumn.customCellGenerator = row => {
    return <>{SpaceBeforeCapitals(row.type)}</>
  }

  const noteDateColumn = new Column('enteredOn', 'Entered On', ColumnType.CUSTOM)
  noteDateColumn.customCellGenerator = row => {
    return <>{FormatDateTimeValue(row.enteredOn)}</>
  }
  const noteColumn = new Column('note', 'Message')
  const columns = [typeColumn, noteDateColumn, noteColumn]

  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    handleClick(row as CommunicationSummary)
  }

  const tableContent = <DataTable
    page={pagination.page}
    data-testid='session-blocks-table'
    name='sessionBlocksTable'
    hasCheckbox={false}
    columns={columns}
    rows={pagination.internalRows ?? []}
    totalRecords={pagination.recordCount}
    loading={false}
    hoverShading={false}
    rowClickable={permissions.CareNetwork_Communication_Edit}
    initialColumnSorts={[new ColumnSort('name')]}
    onSortChange={(col, sorts) => {
      pagination.onSortChange(col, sorts[0].order)
    }}
    onRowClick={rowClick}
    onPageChange={pagination.handleChangePage}
    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
  />

  const handleModeChange = (isCardMode: boolean): void => {
    if (isCardMode) {
      setIsCardModeSelected(true)
    } else {
      setIsCardModeSelected(false)
    }
  }

  return (<>
    <CommunicationSearch current={searchData} onChange={handleSearch} />
    <Box className='d-flex f-direction-column'>
      <Box>
        {permissions.CareNetwork_Communication_Edit &&
          <>
            <Button variant='contained' color='secondary' className='mt-10' onClick={() => { handleNewClick(NoteSource.ContactLog) }} data-testid='add-contact-log'>Add Contact Log</Button>
            <Button variant='contained' color='secondary' className='mt-10 ml-10' onClick={() => { handleNewClick(NoteSource.Reminder) }} data-testid='add-reminder'>Add Reminder</Button>
            <Button variant='contained' color='secondary' className='mt-10 ml-10' onClick={() => { handleNewClick(NoteSource.Note) }} data-testid='add-note'>Add Note</Button>
          </>
        }
        <IconButton onClick={() => { handleModeChange(true) }} className={'selectable-icon-button ' + (isCardModeSelected ? 'selected-icon' : '')}>
          <CardIcon className='selectable-icon' />
        </IconButton>
        <IconButton onClick={() => { handleModeChange(false) }} className={'selectable-icon-button ' + (!isCardModeSelected ? 'selected-icon' : '')}>
          <TableIcon className='selectable-icon' />
        </IconButton>
      </Box>
    </Box>

    {isCardModeSelected && cardContent}
    {!isCardModeSelected && tableContent}

    {openModal && selectedCommunication !== null && (
      <CommunicationDetail
        onClose={() => { setOpenModal(false) }}
        open={openModal}
        isNew={isNew}
        selectedCommunication={selectedCommunication}
        onSubmit={handleSubmit}
        userOptions={users}
      />
    )}
    {openModal && selectedOtherNote !== null && (
      <OtherNoteDisplay
        openModal={openModal}
        summary={selectedOtherNote}
        onClose={() => { setOpenModal(false) }}
      />
    )}
  </>)
}
