import { Box, Button, CircularProgress, Chip, Grid } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import DataTable from '../../Components/Table/DataTable'
import { Column, ColumnSort, ColumnType, type RowData } from '../../Components/Table/DataTable/DataTable.model'
import { useEffect, useState } from 'react'
import { ServiceDetails } from './ServiceDetails'
import { type service } from '../../core/types'
import { type PaginationProps, usePagination, type SearchCriteria } from '../../hooks/use-pagination'
import { sendGet, sendPost, sendPostFormData, sendPut } from '../../hooks/use-fetch'
import { TextFieldWithLabel } from '../../Components/TextField'

const nameColumn = new Column('name', 'Name')
const statusColumn = new Column('inactive', 'In Use', ColumnType.CUSTOM)
statusColumn.customCellGenerator = row => {
  let color = '#4DA4A4 !important'
  let backgroundColor = 'rgba(77, 164, 164, 0.1)'
  const isInactive = row?.inactive as boolean
  if (isInactive) {
    color = '#D4316C !important'
    backgroundColor = 'rgba(212, 49, 108, 0.1)'
  }
  return <Chip icon={<FiberManualRecordIcon sx={{ color }} />} label={isInactive ? 'Inactive' : 'Active'} size="small" sx={{ color, backgroundColor }} />
}
const iconColumn = new Column('image', 'Icon', ColumnType.CUSTOM)
iconColumn.customCellGenerator = row => {
  if (row.s3Key != null) {
    const imagePath = row.s3Key as string
    return <img
      src={`${process.env.REACT_APP_SERVER_URL ?? ''}/File/${imagePath}`}
      alt='logo'
      data-testid={'logo-' + imagePath ?? ''}
      style={{ maxWidth: '100px' }}
    />
  } else {
    return <div></div>
  }
}
const columns = [nameColumn, statusColumn, iconColumn]

export function SystemServicesList (): JSX.Element {
  const initialService: service = { id: 0, name: '', description: '', inactive: false, s3Key: null }
  const [errors, setErrors] = useState<string[] | undefined>(undefined)
  const [services, setServices] = useState<service[]>([])
  const [selectedService, setSelectedService] = useState<service>(initialService)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [updateDate, setUpdateDate] = useState(new Date())
  const [searchValue, setSearchValue] = useState(null)

  const paginationProps: PaginationProps<RowData> = {
    rows: services
  }
  const pagination = usePagination(paginationProps)

  const addClick = (): void => {
    setSelectedService(initialService)
    setOpen(true)
    setIsNew(true)
  }
  const rowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData): void => {
    const selectedService: service = {
      id: row.id,
      name: row.name,
      description: row.description,
      inactive: row.inactive,
      s3Key: row.s3Key
    }
    setSelectedService(selectedService)
    setOpen(true)
    setIsNew(false)
  }

  useEffect(() => {
    const fieldArray: SearchCriteria[] = []
    if (searchValue !== null) {
      fieldArray.push({
        field: 'name',
        value: searchValue
      })
    }
    pagination.setSearchFields(fieldArray)
  }, [searchValue])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: serviceRsp } = await sendGet('/SystemServiceMaintenance/GetAll', {})
      if (serviceRsp !== null) {
        setServices(serviceRsp)
      }
      setLoading(false)
    }
    void loadData()
  }, [updateDate])

  const handleSubmit = async (serviceData: service, file: File | null): Promise<void> => {
    if (!loading) {
      setLoading(true)

      setErrors(undefined)
      let recordID
      let err: string[]
      let suc = false
      const url = `/SystemServiceMaintenance/${isNew ? 'Insert' : 'Update'}`

      if (isNew) {
        const { response, error, success } = await sendPost(url, serviceData)

        recordID = response
        err = error
        suc = success
      } else {
        const { error, success } = await sendPut(url, serviceData)
        recordID = serviceData.id
        err = error
        suc = success
      }

      if (suc && file != null) {
        const fileUrl = '/SystemServiceMaintenance/UploadFile'
        const fileData = new FormData()
        fileData.append('id', recordID)
        fileData.append('file', file)
        const { error, success } = await sendPostFormData(fileUrl, fileData)
        err = error
        suc = success
      }
      if (suc) {
        setOpen(false)
        setUpdateDate(new Date())
      } else {
        if (err[0] !== '') { setErrors(err) }
      }
      setLoading(false)
    }
  }

  if (loading) {
    return <CircularProgress />
  } else {
    return <Box>
      <Grid container columns={2} display='flex' width='100%'>
        <Grid item>
          <TextFieldWithLabel
            label='Name'
            onChange={(e) => { setSearchValue(e.target.value) }}
            name='name'
            maxWidth='200px'
            value={searchValue}
            data-test-name-search
          />
        </Grid>
        <Grid item marginLeft='auto'>
          <Button data-test-add-service color='secondary' variant='contained' onClick={addClick}>Add Service</Button>
        </Grid>
      </Grid>

      <DataTable
        page={pagination.page}

        data-testid='serviceTable'
        name='serviceTable'
        hasCheckbox={false}
        columns={columns}
        rows={pagination.internalRows ?? []}
        totalRecords={pagination.recordCount}
        loading={loading}
        initialColumnSorts={[new ColumnSort('name')]}
        onSortChange={(col, sorts) => {
          pagination.onSortChange(col, sorts[0].order)
        }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRowClick={rowClick}
      />
      {open && (
        <ServiceDetails
          onClose={() => { setOpen(false) }}
          onSubmit={handleSubmit}
          open={open}
          service={selectedService}
          errors={errors}
        />
      )}
    </Box>
  }
}
