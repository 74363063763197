import { Card, CardContent, CardActions, Popover, MenuList, MenuItem, ListItemIcon, ListItemText, Button, IconButton } from '@mui/material'
import { LocationDisplay } from './LocationDisplay'
import { LocationDetails } from './LocationDetails'
import { type location } from '../../../core/types'
import ContentCut from '@mui/icons-material/ContentCut'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useEffect, useState } from 'react'
import { sendGet } from '../../../hooks/use-fetch'
import AlertMessage from '../../AlertMessage'

export interface LocationCardDisplayProps {
  errors?: string[]
  isPrimary: boolean
  data: location
  edit: boolean
  anchorEl: HTMLButtonElement | null
  onEditChange: (e: any) => void
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
  onUpdate: () => void
  onCancel: () => void
  onPlaceSelected: (place: Microsoft.Maps.ISuggestionResult) => Promise<void>
  showHomelessOption: boolean
  hideActions?: boolean
}

export function LocationCardDisplay (props: LocationCardDisplayProps): JSX.Element {
  const [showFlintMessage, setShowFlintMessage] = useState(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.edit) {
        setShowFlintMessage(false)
      } else if (props.data.districtID !== undefined) {
        if (!(props.data.isPrimary ?? true)) {
          setShowFlintMessage(false)
        } else {
          const { response } = await sendGet(`/CareNetworkEdit/ShowFlintWaterDisclaimer?districtID=${String(props.data.districtID)}`, {})
          setShowFlintMessage(response === true)
        }
      }
    }
    void fetchData()
  }, [props.data, props.edit])

  const hideActions = props.hideActions ?? false

  const displayContent = <LocationDisplay location={props.data} isPrimary={props.isPrimary} isHomeless={props.data.isHomeless} />

  const editContent = <LocationDetails
    editData={props.data}
    onChange={props.onEditChange}
    onPlaceSelected={props.onPlaceSelected}
    errors={props.errors}
    hidePrimary={hideActions}
    showHomelessOption={props.showHomelessOption}
  />

  const displayActions = <IconButton onClick={props.onClick} >
    <MoreHorizIcon />
  </IconButton>

  const open = props.anchorEl !== null
  const id = open ? 'simple-popover' : undefined
  const className = props.isPrimary ? 'primary locationCard' : 'locationCard'

  const editActions = <>
    <Button data-testid="update" onClick={props.onUpdate}>{props.data.id === 0 ? 'Save' : 'Update'}</Button>
    <Button data-testid="cancel" onClick={props.onCancel}>Cancel</Button>
  </>

  return (<Card className={className} data-testid={'locationCard-' + props.data.id?.toString()}>
    <CardContent>
      {props.edit && editContent}
      {!props.edit && displayContent}
    </CardContent>
    <CardActions sx={{ justifyContent: 'flex-end' }}>
      {!hideActions && (
        props.edit ? editActions : displayActions
      )}
    </CardActions>
    <Popover
      id={id}
      open={open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <MenuList>
        <MenuItem onClick={props.onEdit}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={props.onDelete}>
          <ListItemIcon sx={{ marginLeft: 'auto' }}>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

      </MenuList>
    </Popover>
    {showFlintMessage && <AlertMessage type='warning' message='This family&apos;s address looks like it could be eligible for the Flint Waterline Program. Please provide them more information.' /> }
  </Card>)
}
