import { BlockButton } from '../../Components/Button/BlockButton'
import { Box, Typography } from '@mui/material'
import { useAccountId } from '../../ContextProviders/CurrentAccount'
import { useEffect, useState } from 'react'
import { sendGet } from '../../hooks/use-fetch'
import { AccountType, type ActiveAccount, type service } from '../../core/types'
import { generatePath, useNavigate } from 'react-router'
import * as AppRoutes from '../Routes'
import S3Image from '../../Components/S3Image'

export function ReferralSplashScreen (): JSX.Element {
  const accountId: ActiveAccount | undefined = useAccountId()
  const [services, setServices] = useState<service[]>([])
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      let url = ''
      if (accountId === undefined) {
        throw new Error('No account ID')
      } else if (accountId.type === AccountType.SERVICE_PROVIDER || accountId.type === AccountType.INTAKE_USER) {
        url = `/ServiceProviderDetails/GetProviderServices?id=${accountId.id.toString()}`
      } else if (accountId.type === AccountType.ISD) {
        url = `/ISDServices/GetCurrentServices?ISDID=${accountId.id.toString()}`
      } else {
        throw new Error('Invalid account type')
      }
      const { response } = await sendGet(url, {})
      if (response !== undefined && response.length > 0) {
        setServices(response)
      }
    }
    void fetchData()
  }, [])

  const serviceClick = (serviceId: number): void => {
    const referrals = generatePath(AppRoutes.REFERRALS, { serviceId: serviceId.toString() })
    nav(referrals)
  }

  return <div>
    <Typography variant="h3">Referrals</Typography>

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', marginTop: '20px' }}>
      {services.map((s) => (
        <Box sx={{ marginRight: '20px' }} key={s.id}>
          <BlockButton key={s.id} data-testid={s.name} text={`${s.name}`}
            onClick={() => { serviceClick(s.id) }}
            icon={ s.s3Key !== null ? <S3Image style={{ maxWidth: '100px' }} imageKey={s.s3Key} /> : <></>} backgroundColor='rgba(241, 235, 248, 1)'
          />
        </Box>
      ))}
    </Box>
  </div>
}
