import { Box, Button } from '@mui/material'
import { sendGet, sendPost } from '../../hooks/use-fetch'
import { useEffect, useState } from 'react'
import { CheckList } from '../../Components/CheckList'
import { DatePickerWithLabel } from '../../Components/DatePicker'
import { type SelectOption } from '../../Components/SelectWithLabel'

export function AgencyStatsOptions (): JSX.Element {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [serviceProviders, setServiceProviders] = useState<Array<SelectOption<number>>>([])
  const [selectedServiceProviders, setSelectedServiceProviders] = useState<Array<SelectOption<number>>>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { response: response2 } = await sendGet('/Reporting/ISDServiceProviders', {})
      setServiceProviders(response2)
    }

    void fetchData()
  }, [])

  const openReport = async (): Promise<void> => {
    const idList = selectedServiceProviders.map(s => s.id)
    const { response, success } = await sendPost('/Reporting/AgencyStatsReportURL', { startDate, endDate, isdServiceProviderIDs: idList })
    if (success) {
      window.open(response, '_blank')?.focus()
    }
  }

  return <>
    <Box className='mt-10'>
      <DatePickerWithLabel
        required={true}
        label='Start Date'
        name='startDate'
        onChange={setStartDate}
        value={startDate}
      />
      <DatePickerWithLabel
        required={true}
        label='End Date'
        name='endDate'
        onChange={setEndDate}
        value={endDate}
      />

      <CheckList label='Service Providers' items={serviceProviders} selectedItems={selectedServiceProviders} setSelectedItems={setSelectedServiceProviders} allOption={true} />
    </Box>

    <Box className='mt-10'>
      <Button color='secondary' variant='contained' onClick={openReport}>Preview</Button>
    </Box>
  </>
}
