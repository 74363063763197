/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { AcceptDenyModal } from '../../../Components/Modal/AcceptDenyModal'
import { Box, CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import { sendGet, sendPost } from '../../../hooks/use-fetch'
import { type ChildEditValues, type DocumentTypeRequirement } from '../../../core/types'
import { ReferralView } from '../../../Components/ChildDetails/ReferralView'
import { type ReferralData } from '../../Referral/ReferralDetail'
import { ReferralEditModal } from '../../Referral/ReferralEditModal'
import { toast } from 'react-toastify'
import { type childServiceSettings } from '../../../ContextProviders/AddChildProvider'

export interface EnrollmentDetailProps {
  hideIncomeReapply?: boolean
  enrollmentID: number
  childID: number
  open: boolean
  onClose: () => void
  onEnroll?: () => void
  onExit?: () => void
  onReapplyIncome?: () => void
}

export function EnrollmentDetails (props: EnrollmentDetailProps): JSX.Element {
  const [enrollment, setEnrollment] = useState<ReferralData>()
  const [loaded, setLoaded] = useState(false)
  const [docTypes, setDocTypes] = useState<DocumentTypeRequirement[]>([])
  const [refresh, setRefresh] = useState(new Date())
  const [showCreateNewReferral, setShowCreateNewReferral] = useState(false)
  const [existingReferrals, setExistingReferrals] = useState<Array<{ systemProgramID: number, systemServiceID: number }>>([])

  useEffect(() => {
    async function getData (): Promise<void> {
      const request = await sendGet(`/Enrollment/GetEnrollment?id=${props.enrollmentID}`, {})
      const referral: ReferralData = request.response
      referral.id = referral.referralID ?? 0
      setEnrollment(referral)

      const { response: rsp, success: suc } = await sendGet(`/Enrollment/GetEnrollmentDocumentTypes?enrollmentId=${props.enrollmentID}`, {})
      if (suc) {
        setDocTypes(rsp as DocumentTypeRequirement[])
      }

      const { response, success } = await sendGet(`/ChildDetails/GetAllServices?id=${props.childID}`, {})
      if (success) {
        setExistingReferrals(response.response)
      }
      setLoaded(true)
    }
    void getData()
  }, [props.enrollmentID, refresh])

  const handleClose = (): void => {
    props.onClose()
  }

  const handleRefresh = (): void => {
    setRefresh(new Date())
    if (props.onReapplyIncome != null) {
      props.onReapplyIncome()
    }
  }

  const printDetails = (): void => {
    window.open(enrollment?.reportURL, '_blank')?.focus()
  }

  const handleSubmitNew = async (settings: childServiceSettings): Promise<boolean> => {
    const newReferral = {
      child: { id: enrollment?.childID } as ChildEditValues,
      serviceSettings: settings,
      districtSettings: { isdid: enrollment?.isdid }
    }

    const { response, success, error } = await sendPost('/ReferralEdit/CreateServiceReferral', newReferral)
    if (response != null) {
      if (success) {
        toast.success('New referral created')
        setShowCreateNewReferral(false)
        return true
      } else {
        toast.error(error.join(','))
      }
    }

    return false
  }
  if (!loaded) return <CircularProgress role='loading' />
  else if (enrollment == null) return <div>Record not found</div>
  else {
    const content = <Box >
      <ReferralView
        hideIncomeReapply={props.hideIncomeReapply}
        onPrint={printDetails}
        referral={enrollment}
        enrollmentId={props.enrollmentID}
        type='enrollment'
        docTypes={docTypes}
        hideEditButtons={true}
        onRefresh={handleRefresh}
        onEdit={() => { }}
        onCreateNew={setShowCreateNewReferral}
        onEditHouseholdMembers={() => { }}
      />
      {showCreateNewReferral && <ReferralEditModal
        open={true} referral={{ childID: enrollment.childID, isdid: enrollment.isdid } as ReferralData}
        onSubmit={handleSubmitNew}
        onClose={() => { setShowCreateNewReferral(false) }}
        status={'Pending'}
        isNew={true}
        existingReferrals={existingReferrals}
      />}

    </Box>

    return <AcceptDenyModal
      className='Modal Referral'
      open={props.open}
      title={'Student Information'}
      dialogContent={content}
      onClose={handleClose}
      onAccept={props.onEnroll}
      onDeny={props.onExit}
      maxWidth={'sm'}
      denyButtonText='Exit'
      data-testid='enrollment-details-modal'
    />
  }
}
