import { Box, CircularProgress } from '@mui/material'
import { Modal } from '../../Components/Modal'
import { TextFieldWithLabel } from '../../Components/TextField'
import { type program } from '../../core/types'
import { useEffect, useState } from 'react'
import { SelectWithLabel } from '../../Components/SelectWithLabel'
import { TextAreaWithLabel } from '../../Components/TextArea'
import { sendGet } from '../../hooks/use-fetch'
import { CheckBoxWithLabel } from '../../Components/CheckBox'

export interface ProgramDetailsProps {
  open: boolean
  onClose: () => void
  onSubmit: (newValue: program) => void
  program: program
  errors?: string[] | undefined
}

export function ProgramDetails (props: ProgramDetailsProps): JSX.Element {
  const [fundingSources, setFundingSources] = useState<Array<{ id: string, name: string }>>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const { response: rsp, error } = await sendGet('/FundingSource/GetLookup', {})
      if (error[0] === '' && rsp !== null) {
        setFundingSources(rsp)
      }
      setLoading(false)
    }
    void loadData()
  }, [])
  const [program, setProgram] = useState(props.program)
  if (loading) return <CircularProgress/>
  const title = 'Program Details'
  const handleClose = (): void => {
    props.onClose()
  }
  const fundingChange = (e: any): void => {
    const newVal = parseInt(e.target.value)
    setProgram({ ...program, [e.target.name]: newVal })
  }
  const handleChange = (e: any): void => {
    setProgram({ ...program, [e.target.name]: e.target.value })
  }
  const handleSubmit = (): void => {
    props.onSubmit(program)
  }
  const content = <Box>
    {props.errors?.map((err) => (
      <li key={err} style={{ color: 'red', padding: '10px 0 0' }}>
        {err}
      </li>
    ))}

    <Box>
      <TextFieldWithLabel
        data-test-name
        label='Program Name'
        name='name'
        onChange={handleChange}
        value={program.name}
      />
      <SelectWithLabel
        data-test-funding-source
        label='Funding Source'
        name='fundingSourceID'
        onChange={fundingChange}
        value={program.fundingSourceID.toString() === '0' ? '' : program.fundingSourceID.toString()}
        options={fundingSources}
        width='200px'
      />
      <TextAreaWithLabel
        dataTestId='program-description'
        label='Program Description'
        name='description'
        onChange={handleChange}
        value={program.description}
      />
      <CheckBoxWithLabel
        edge='start'
        label='Requires Income Calculation'
        name='requiresIncomeCalculation'
        setValue={(newValue) => { setProgram({ ...program, requiresIncomeCalculation: newValue }) }}
        value={program.requiresIncomeCalculation ?? false}
      />
    </Box>
  </Box>
  return <Modal
    open={props.open}
    title={title}
    confirmationContent={content}
    onClose={handleClose}
    onConfirm={handleSubmit}
  />
}
